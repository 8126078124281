import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-299da1bf"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Nav = _resolveComponent("Nav")!
  const _component_ContactListDialog = _resolveComponent("ContactListDialog")!
  const _component_Main = _resolveComponent("Main")!
  const _component_Layout = _resolveComponent("Layout")!

  return (_openBlock(), _createBlock(_component_Layout, null, {
    default: _withCtx(() => [
      _createVNode(_component_Nav),
      _createVNode(_component_Main, null, {
        default: _withCtx(() => [
          _createElementVNode("iframe", {
            id: "formviewframe",
            title: "Form",
            scrolling: "auto",
            src: _ctx.formUrl
          }, null, 8, _hoisted_1),
          (_ctx.contactDialog.visible)
            ? (_openBlock(), _createBlock(_component_ContactListDialog, {
                key: 0,
                contacts: _ctx.contacts,
                onCloseModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.contactDialog.visible = false)),
                onSelected: _ctx.selectContact
              }, null, 8, ["contacts", "onSelected"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}