import { ApiResponse, parseLocalError, parseRemoteError } from "../error_parser";
import { Verified } from "../verified_plugin";

export const getFormFile = async (formId: string, fileId: string): Promise<{ file: Blob | null, responseInfo: ApiResponse }> => {
    try {
        // todo: fix this and remove
        fileId = fileId.replace(".pdf", "");

        const urlResponse = await fetch(`/api/aml/v1/forms/${formId}/files/${fileId}`, {
            method: "GET",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
        });

        const body = await urlResponse.json();

        console.log("body", body);

        if (urlResponse.ok) {
            const fileResponse = await fetch(body.url, {
                method: "GET",
            });

            const blob = await fileResponse.blob();

            console.log("fileResponse", fileResponse);

            return {
                file: blob,
                responseInfo: { isError: false, parsedError: null },
            };
        } else {
            return {
                file: null,
                responseInfo: { isError: true, parsedError: parseRemoteError(body) },
            };
        }
    } catch (error) {
        return {
            file: null,
            responseInfo: { isError: true, parsedError: parseLocalError(error) },
        };
    }
};
