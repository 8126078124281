<template>
    <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 py-6">
        <!--Header-->
        <div class="flex justify-between mb-5">
            <label class="text-3xl">{{ $t('common.person_info.address') }}</label>
            <Icon
                class="h-10 w-10 hover:bg-background p-3 cursor-pointer rounded-full "
                icon="cross"
                @click="$emit('closed')"
                aria-hidden="true"
            />
        </div>
        <!--Body-->
        <div class="flex flex-col gap-y-5">
            <ul class="p-0 space-y-3">
                <li
                    v-for="(address, index) in addressList"
                    :key="index"
                    class="flex gap-x-2 items-center min-h-[6rem] border border-light border-solid p-4 rounded-lg hover:bg-primary-hover-blue"
                >
                    <div>
                        <input
                            name="notification-method"
                            type="radio"
                            :checked="isSameAsCurrentAddress(address.value)"
                            class="h-6 w-6 border-light"
                            @click="onChange(address.value)"
                        />
                    </div>
                    <div>
                        <div>
                            <label>{{ addessObjectToString(address.value) }}</label>
                            <div
                                v-for="src in address.sources"
                                class="text-sm text-light-gray flex gap-2"
                                :key="src.source"
                            >
                                <span>{{ src.source }}</span>
                                <Icon class="w-4 h-4" :icon="`aml:src-${src.source.toLowerCase()}`" />
                                <Icon icon="dot-single" aria-hidden="true" />
                                <span>{{ $d(new Date(src.updatedAt), "long") }}</span>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>

            <!--Start Add new address-->
            <div v-if="addAddress" class="space-y-3 bg-background rounded-lg p-4">
                <div class="w-full relative">
                    <label class="block">{{ addOrUpdateText }}</label>
                    <div class="absolute right-1 top-0">
                        <Icon
                            icon="cross"
                            class=" w-3 h-3 cursor-pointer"
                            @click="addAddress = false"
                            aria-hidden="true"
                        />
                    </div>

                </div>
                <Icon
                    icon="horizontal-line"
                    class=""
                    aria-hidden="true"
                />
                <div class="w-full grid grid-cols-2 relative">
                    <InputGroup :width="12">
                        <InputLabel text="Street" unique-id="street" />
                        <InputText
                            v-model="street"
                            class="basis-1/2"
                            placeholder="Add street and no"
                            unique-id="street"
                        />
                    </InputGroup>
                    <InputGroup :width="12">
                        <InputLabel text="Zip" unique-id="zip" />
                        <InputText
                            v-model="zip"
                            class="basis-1/2"
                            placeholder="Zip code"
                            unique-id="zip"
                        />
                    </InputGroup>
                    <InputGroup :width="12">
                        <InputLabel text="City" unique-id="city" />
                        <InputText
                            v-model="city"
                            class="basis-1/2"
                            placeholder="Add city"
                            unique-id="city"
                        />
                    </InputGroup>
                    <InputGroup :width="12">
                        <CountryDropdown
                            v-model="country"
                            label="Country"
                            placeholder="Select country"
                        />
                    </InputGroup>
                </div>
                <CustomButton
                    :text="addOrUpdateText"
                    @click="addNewAddress"
                    round-style="3xl"
                    class="border border-primary-default font-medium text-base box-border ml-auto px-2"
                    bg-color="white"
                />
            </div>
            <!--End Add new address-->
            <div v-if="!addAddress">
                <a class="text-primary-default" @click="addAddress = true">{{ addOrUpdateText }}</a>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import CustomButton from "@/components/CustomButton.vue";
import Icon from "@/components/Icon.vue";
import Input from "@/components/Input.vue";
import { valueWithsources } from "@/lib/common/common";
import { Address, Person } from "@/lib/persons/person";
import { updatePerson, updatePersonInfo } from "@/lib/persons/update_person";
import InputGroup from "@/ui/inputs/InputGroup.vue";
import InputLabel from "@/ui/inputs/InputLabel.vue";
import InputText from "@/ui/inputs/InputText.vue";
import { PropType, defineComponent } from "vue";
import CountryDropdown from "@/ui/inputs/CountryDropdown.vue";
import countryDetails from "../../../public/assets/geography/countries.json";

export default defineComponent({
    name: "EditAddress",
    components: {
        Icon,
        Input,
        CustomButton,
        InputText,
        InputLabel,
        InputGroup,
        CountryDropdown,
    },
    props: {
        person: {
            type: Object as PropType<Person>,
            required: true,
        },
    },
    data() {
        return {
            addAddress: false,
            newAddress: "",
            street: "",
            zip: "",
            city: "",
            country: "",
            countryDetails,
        };
    },
    computed: {
        addressList(): any[] | null {
            const personInfoDataBlocks = this.person.blocks.filter((b: any) => b._block === "person_info")?.filter((b: any) => b.addresses);
            if (personInfoDataBlocks && personInfoDataBlocks.length > 0) {
                const addresses = personInfoDataBlocks.reduce((acc, block) => {
                    if (block.addresses) {
                        const existingAddress = acc.find(
                            (address: any) => block?.addresses && JSON.stringify(address.value) === JSON.stringify(block?.addresses[0]),
                        );
                        if (existingAddress) {
                            existingAddress.sources?.push({ source: block._source, updatedAt: block._updatedAt });
                        } else {
                            acc.push({
                                value: block?.addresses[0],
                                sources: [{ source: block._source, updatedAt: block._updatedAt }],
                            });
                        }
                    }

                    return acc;
                }, [] as valueWithsources[]);
                return addresses;
            }

            return null;
        },
        addOrUpdateText(): string {
            return (
                (this.addressList && this.addressList?.length > 0 ? this.$t("common.actions.update") : this.$t("common.actions.add")) +
                " " +
                this.$t("common.person_info.address")
            );
        },
    },
    methods: {
        addNewAddress() {
            console.log("selectedCountry", this.country);
            updatePerson(this.person.id, {
                addresses: [
                    {
                        type: "Postal",
                        country: this.country.toUpperCase(),
                        street: this.street,
                        town: this.city,
                        zip: this.zip,
                    },
                ],
            });
            this.$emit("refresh");
            this.addAddress = false;
            this.country = "";
            this.street = "";
            this.zip = "";
            this.city = "";
        },

        onChange(address: Address) {
            updatePersonInfo(this.person.id, {
                addresses: [
                    {
                        ...address,
                    },
                ],
            });
            this.$emit("refresh");
        },

        addessObjectToString(address: Address) {
            return (
                address.street +
                ", " +
                address.zip +
                " " +
                address.town +
                ", " +
                this.$ct(Object.entries(this.countryDetails.countries).find(([key, value]) => key === address.country)?.[1])
            );
        },
        isSameAsCurrentAddress(address: Address) {
            if (this.person.data?.person_info?.addresses) {
                return JSON.stringify(this.person.data.person_info?.addresses[0]) === JSON.stringify(address);
            }
            return false;
        },
    },
    emits: ["closed", "refresh"],
});
</script>
