<template>
    <SelectBox
        :options="countryList"
        :show-flag="true"
        v-model="selectedValue"
        :label="label"
        :placeholder="placeholder"
        bg-color="bg-white"
        border-color="border-light"
        border-radius="rounded-[4px]"
        :required="required"
    />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import countryDetails from "../../../public/assets/geography/countries.json";
import SelectBox from "@/components/SelectBox.vue";

export default defineComponent({
    name: "CountryDropdown",
    components: {
        SelectBox,
    },
    props: {
        modelValue: {
            type: String,
            default: "",
        },
        label: {
            type: String,
            default: "",
        },
        placeholder: {
            type: String,
            default: "",
        },
        required: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            countryDetails,
            selectedValue: this.modelValue,
        };
    },

    methods: {

    },
    watch: {
        selectedValue() {
            this.$emit("update:modelValue", this.selectedValue);
        },
        modelValue() {
            this.selectedValue = this.modelValue;
        },
    },
    emits: ["update:modelValue"],
    computed: {
        countryList(): { value: string; label: string }[] {
            const topCountries = ["SE", "NO", "FI", "DK", "DE", "ES"]; // Add your desired country codes here
            const entries = Object.entries(countryDetails.countries);
            const sortedEntries = entries.sort(([keyA], [keyB]) => {
                const indexA = topCountries.indexOf(keyA.toUpperCase());
                const indexB = topCountries.indexOf(keyB.toUpperCase());
                if (indexA !== -1 && indexB !== -1) {
                    return indexA - indexB; // Sort top countries among themselves
                } else if (indexA !== -1) {
                    return -1; // Place top country A before B
                } else if (indexB !== -1) {
                    return 1; // Place top country B before A
                } else {
                    return keyA.localeCompare(keyB); // Sort other countries alphabetically
                }
            });
            return sortedEntries.map(([key, value]) => ({
                value: key.toUpperCase(),
                label: this.$ct(value),
            }));
        },
    },
    async created() {

    },
});

</script>
