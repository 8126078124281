import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex mb-2" }
const _hoisted_2 = { class: "flex-1 uppercase text-xs font-bold" }
const _hoisted_3 = {
  key: 0,
  class: "text-xs"
}
const _hoisted_4 = { class: "flex-1 flex" }
const _hoisted_5 = { class: "flex-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass('bg-background outline-1 text-[#555] rounded-lg p-3 pb-2 flex flex-col min-h-[80px]' + _ctx.editableClass)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.icons, (icon) => {
        return (_openBlock(), _createBlock(_component_Icon, {
          class: "w-4 h-4 ml-2",
          icon: icon,
          key: icon
        }, null, 8, ["icon"]))
      }), 128)),
      (_ctx.valueCount)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("a", {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('openSidebarDialog', _ctx.title))),
              class: "group-hover:text-primary-default"
            }, _toDisplayString(_ctx.$t('common.otersCount', _ctx.valueCount)), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ])
  ], 2))
}