import { PersonScreeningHit, PersonScreeningResult } from "../persons/personScreeningHit";
import { Verified } from "../verified_plugin";

export type screenPersonOptions = {
    personId: string;
    reference?: string | null;
    givenName: string;
    familyName: string;
    dateOfBirth?: string | null;
};

export const screenPersonWithId = async (options: screenPersonOptions): Promise<PersonScreeningResult> => {
    try {
        console.log(options.personId);
        const response = await fetch(`/api/aml/v1/screening/person/${options.personId}`, {
            method: "PUT",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
            body: JSON.stringify({
                ...options,
            }),
        });

        const body = await response.json();

        if (!response.ok) {
            console.log(response);
        }

        return body as { personId: string; namespace: string; screeningHits: PersonScreeningHit[] };
    } catch (err) {
        console.error("Failed to create project", err);
        throw err;
    }
};
export const updateMonitoringStatus = async (status: boolean, personId: string) => {
    const baseUrl = "/api/aml/v1/persons";
    try {
        const response = await fetch(`${baseUrl}/${personId}/monitor`, {
            method: "PUT",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
            body: JSON.stringify({
                monitored: status,
            }),
        });
        const res = await response.json();

        if (!response.ok) {
            console.log(response);
        }
        return res;
    } catch (error) {
        console.log(error);
        throw (error);
    }
};

export const deletePersonScreening = async(personId: string) => {
    try {
        const result = await fetch(`/api/aml/v1/screening/person/${personId}`, {
            method: "delete",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
        });
        console.log(result);
    } catch (error) {
        throw new Error(`${error}`);
    }
};

export const getPersonScreeningHits = async(personId: string): Promise<{ hits: any[], total: number }> => {
    try {
        const result = await fetch(`/api/aml/v1/persons/${personId}/hits`, {
            method: "get",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
        });
        const screening = await result.json();
        console.log(screening, "screeing hit");
        return screening;
    } catch (error) {
        throw new Error(`${error}`);
    }
};

export const updatePersonScreeningFalsePositives = async (personId: string, payload: {[key: string]: boolean}) => {
    try {
        const result = await fetch(`/api/aml/v1/persons/${personId}/mark-false-positives`, {
            method: "put",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
            body: JSON.stringify(payload),
        });
        const screening = await result.json();
        console.log(screening, "screeing hit");
        return screening;
    } catch (error) {
        throw new Error(`${error}`);
    }
};
