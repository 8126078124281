<template>
    <div>

        <!-- <div>
            {{ 'Indicators' }}
        </div> -->

        <div v-if="!assessment.locked" class="rskv3-il-switch">
            <div>
                <SwitchInput
                    v-model="showMissingOnTop"
                    @update:model-value="onSwitchChanged"
                />
            </div>
            <div
                class="rskv3-il-switch-text"
                @click="onSwitchChanged(!showMissingOnTop)"
            >
                {{ $t('assessment.indicators.list.moveToTop') }}
            </div>
        </div>

        <!-- <div>
            {{ sortedList.length - missingCount + ' of ' + sortedList.length + ' indicators completed' }}
        </div> -->

        <IndicatorItem
            v-for="(indicator, i) in sortedList"
            :key="indicator.key"
            :indicator="indicator"
            :is-first="i === 0"
            :is-last="i === sortedList.length - 1"
            :is-selected="selectedKey === indicator.key"
            @clicked="onSelectIndicator(indicator)"
        />

        <!-- <pre>
            {{ assessment }}
        </pre> -->

    </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

import { Assessment, RiskIndicator } from "@/lib/assessments/assessments";
import { RiskValueSource } from "@/lib/risk/risk_matrix";
import SwitchInput from "../SwitchInput.vue";
import IndicatorItem from "./IndicatorItem.vue";

export default defineComponent({
    name: "IndicatorList",
    components: {
        IndicatorItem,
        SwitchInput,
    },
    emits: ["selected"],
    props: {
        assessment: { type: Object as PropType<Assessment>, required: true },
        selectedKey: { type: String as PropType<string>, required: true },
    },
    data() {
        return {
            missingCount: 0,
            showMissingOnTop: true,
            sortedList: new Array<RiskIndicator>(),
        };
    },
    watch: {
        assessment: {
            deep: true,
            handler() {
                this.updateSortedList();
            },
        },
    },
    methods: {

        onSelectIndicator(indicator: RiskIndicator) {
            let selectedKey = "";
            if (this.selectedKey === indicator.key) {
                selectedKey = "";
            } else {
                selectedKey = indicator.key;
            }
            this.$emit("selected", selectedKey);
        },

        onSwitchChanged(newValue: boolean) {
            this.showMissingOnTop = newValue;
            this.updateSortedList();
        },

        updateSortedList() {
            this.sortedList = [];
            if (this.showMissingOnTop) {
                this.assessment.indicators.forEach((indicator) => {
                    if (indicator.activeValue.source.source === RiskValueSource.Empty) {
                        this.sortedList.push(indicator);
                    }
                });
            }
            this.assessment.indicators.forEach((indicator) => {
                if (indicator.activeValue.source.source !== RiskValueSource.Empty || !this.showMissingOnTop) {
                    this.sortedList.push(indicator);
                }
            });
            this.updateMissingCount();
        },

        updateMissingCount() {
            let count = 0;
            this.assessment.indicators.forEach((indicator) => {
                if (indicator.activeValue.source.source === RiskValueSource.Empty) {
                    count += 1;
                }
            });
            this.missingCount = count;
        },

    },
    mounted() {
        this.updateSortedList();
    },
});
</script>
<style scoped>
.rskv3-il-switch {
    display: flex;
    margin-bottom: 10px;
}

.rskv3-il-switch-text {
    padding-top: 1px;
    padding-left: 8px;
    font-size: 17px;
}
</style>
