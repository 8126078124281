import { Verified } from "../verified_plugin";
import { Setting } from "./settings";

export const querySettings = async (): Promise<Setting> => {
    try {
        const response = await fetch("/api/aml/v1/settings", {
            method: "GET",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
        });

        const body = await response.json();

        if (!response.ok) {
            throw new Error(body);
        }

        return body as Setting;
    } catch (err) {
        console.error("Failed to get setting", err);
        throw err;
    }
}
