import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex gap-4 items-center bg-white py-2 px-4 border border-muted h-20 w-1/3 shadow-sm" }
const _hoisted_2 = { class: "text-lg" }
const _hoisted_3 = { class: "" }
const _hoisted_4 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Icon, {
      icon: "branch",
      class: "h-16 w-16"
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, "Name: " + _toDisplayString(_ctx.branch.name), 1),
      _createElementVNode("p", _hoisted_4, "Key: " + _toDisplayString(_ctx.branch.key), 1)
    ])
  ]))
}