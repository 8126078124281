import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3a9e29f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex items-center gap-8" }
const _hoisted_2 = { class: "text-body" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, [
      (_ctx.isVisible)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.text), 1))
        : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.hiddenText), 1))
    ]),
    _createVNode(_component_Icon, {
      class: "w-4 h-4",
      onClick: _withModifiers(_ctx.toggleVisibility, ["stop"]),
      icon: "eye"
    }, null, 8, ["onClick"])
  ]))
}