<template>
    <div>
        <PopupDialog
            :is-visible="dialog.visible"
            :progress="dialog"
            :header="$t('project.forms.kyc.title')"
            :icon="'list-check'"
            :show-cancel-button="true"
            :show-action-button="true"
            :action-text="checkModel.send ? $t('project.forms.kyc.sendAction') : $t('project.forms.kyc.openAction')"
            :action-icon="checkModel.send ? 'share' : 'arrow-up-right-from-square'"
            @action="startForm"
            @close-modal="onClose"
        >

            <PopupDialogSplit
                limit-width
                :prompt-text="$t('project.forms.kyc.promptText')"
                :prompt-hint="$t('project.forms.kyc.promptHint')"
            >

                <PopupDialogInfoIconAndText :text="$t('project.forms.kyc.hint')" />

                <!-- Hide this form MVP -->
                <!-- <div style="padding: 0px 5px; padding-bottom: 10px;">
                    <InputCheckbox2
                        v-model="checkModel"
                        :items="checkItems"
                        :unique-id="'check'"
                    />
                </div> -->

                <InputRow v-if="checkModel.send">
                    <InputGroup :width="6">
                        <InputLabel
                            :text="$t('common.person_info.firstName')"
                            :unique-id="'kyc-first-name'"
                            required
                        />
                        <InputText
                            v-model="editorModel.firstName"
                            :unique-id="'kyc-first-name'"
                            @input="validateDialog"
                        />
                    </InputGroup>
                    <InputGroup :width="6">
                        <InputLabel
                            :text="$t('common.person_info.lastName')"
                            :unique-id="'kyc-last-name'"
                            required
                        />
                        <InputText
                            v-model="editorModel.lastName"
                            :unique-id="'kyc-last-name'"
                            @input="validateDialog"
                        />
                    </InputGroup>
                    <InputGroup :width="12">
                        <InputLabel
                            :text="$t('common.person_info.email')"
                            :unique-id="'kyc-email'"
                            required
                        />
                        <InputText
                            v-model="editorModel.email"
                            :unique-id="'kyc-email'"
                            @input="validateDialog"
                        />
                    </InputGroup>
                </InputRow>

                <ButtonRow
                    v-if="checkModel.send"
                    style="padding-top: 5px; padding-right: 10px;"
                    :alignment="ButtonAlignment.Right"
                >
                    <ButtonSingle
                        is-link
                        :text="$t('project.forms.kyc.instruction')"
                        @clicked="onShowContactDialog"
                    />
                </ButtonRow>

            </PopupDialogSplit>
        </PopupDialog>

        <ContactListDialog
            v-if="contactDialog.visible"
            :contacts="contacts"
            @close-modal="onCloseContactDialog"
            @selected="onSelectContact"
        />

    </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

import { CreateFormOptions, createProjectForm, FormStartMode } from "@/lib/forms/create_form";
import { FormTemplate } from "@/lib/forms/forms";
import { Project } from "@/lib/projects/projects";
import { FlowAction } from "@/types/flow_types";
import PopupDialog from "@/ui/PopupDialog.vue";
import PopupDialogSplit from "@/ui/dialog/PopupDialogSplit.vue";
import PopupDialogInfoIconAndText from "@/ui/dialog/PopupDialogInfoIconAndText.vue";
/* import InputCheckbox2 from "@/ui/inputs/InputCheckbox2.vue"; */
import InputGroup from "@/ui/inputs/InputGroup.vue";
import InputLabel from "@/ui/inputs/InputLabel.vue";
import InputRow from "@/ui/inputs/InputRow.vue";
import InputText from "@/ui/inputs/InputText.vue";
import { CheckboxItem, CheckboxModel } from "@/ui/inputs/input_helper";
import { ButtonAlignment } from "@/types/ui_types";
import ButtonRow from "@/ui/ButtonRow.vue";
import ButtonSingle from "@/ui/ButtonSingle.vue";
import ContactListDialog from "../contact_list/ContactListDialog.vue";
import { Contact, getContactsFromProject } from "../contact_list/contact_list";

export default defineComponent({
    name: "StartKycFormDialog",
    components: {
        PopupDialog,
        PopupDialogSplit,
        PopupDialogInfoIconAndText,
        /* InputCheckbox2, */
        InputGroup,
        InputRow,
        InputLabel,
        InputText,
        ButtonRow,
        ButtonSingle,
        ContactListDialog,
    },
    emits: ["request-project-refresh", "close-modal"],
    props: {
        action: { type: Object as PropType<FlowAction>, required: true },
        project: { type: Object as PropType<Project>, required: true },
    },
    data() {
        return {
            ButtonAlignment,

            dialog: { visible: true, valid: false, isWorking: false, statusText: "", isError: false, errorText: "" },

            template: null as FormTemplate | null,

            editorModel: {
                firstName: "",
                lastName: "",
                email: "",
            },

            checkItems: [
                {
                    key: "send",
                    text: { en_EN: "Send form directly without inspecting the data" },
                },
            ] as CheckboxItem[],
            checkModel: { send: false } as CheckboxModel,

            contactDialog: { visible: false },
            contacts: new Array<Contact>(),
        };
    },
    methods: {

        onClose() {
            if (this.contactDialog.visible) return;
            this.$emit("close-modal");
        },

        onShowContactDialog() {
            this.contacts = getContactsFromProject(this.project);
            this.contactDialog.visible = true;
        },

        onCloseContactDialog() {
            this.contactDialog.visible = false;
        },

        onSelectContact(contact: Contact) {
            this.editorModel.firstName = contact.givenName;
            this.editorModel.lastName = contact.familyName;
            this.editorModel.email = contact.email;
            this.validateDialog();
        },

        validateDialog() {
            const valid = true;
            this.dialog.valid = valid;
        },

        async startForm() {
            if (!this.template) return;
            this.dialog.isWorking = true;
            this.dialog.statusText = this.$t("project.forms.working.creating");
            console.log("Start Form:", this.template);

            const createOptions: CreateFormOptions = {
                startMode: FormStartMode.Create,
                contextId: this.project.id,
                templateSlug: this.template.slug,
                templateKey: this.template.key,
                scopes: this.template.scopes,
                model: {},
            };

            if (this.checkModel.send) {
                createOptions.startMode = FormStartMode.Send;
                createOptions.model = {
                    editorDetails: {
                        firstName: this.editorModel.firstName,
                        lastName: this.editorModel.lastName,
                        email: this.editorModel.email,
                    },
                };
            }

            console.log("createOptions", createOptions);

            const response = await createProjectForm(createOptions);
            console.log("response", response);

            if (response.responseInfo.isError) {
                this.dialog.isError = true;
                this.dialog.errorText = response.responseInfo.parsedError?.message || "Unknown error";
            } else {
                const formViewData = {
                    formUrl: response.formLink,
                    redirectRoute: { path: "/aml/customers/" + this.$route.params.id },
                    contacts: getContactsFromProject(this.project),
                };
                sessionStorage.setItem("amlFormView", JSON.stringify(formViewData));

                this.$router.push({ path: "/aml/form" });
                this.dialog.visible = false;
            }
            this.dialog.isWorking = false;
        },

    },
    mounted() {
        console.log("accc", this.action);

        const kycKey = this.action.params.formKey || "";

        if (!kycKey) {
            this.dialog.errorText = `Invalid form key "${kycKey}"`;
            this.dialog.isError = true;
            return;
        }

        console.log("kycKey", kycKey);

        const formTemplates = this.$config.settings.formTemplates || [];

        formTemplates.forEach((template) => {
            if (template.key === kycKey) {
                this.template = template;
            }
        });

        if (!this.template) {
            this.dialog.errorText = this.$t("project.forms.formTemplateNotExists", { templateKey: kycKey });
            this.dialog.isError = true;
            return;
        }

        console.log("template", this.template);
    },
});
</script>
<style scoped></style>
