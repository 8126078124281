<template>
    <PopupDialog
        :is-visible="dialog.visible"
        :header="$t('assessment.indicators.addManualValue')"
        :icon="'wand-magic-sparkles'"
        :show-cancel-button="true"
        :show-action-button="true"
        :action-text="$t('common.actions.save')"
        :action-icon="'floppy-disk'"
        :is-action-disabled="!isValid"
        :progress="dialog"
        @action="onSave"
        @close-modal="onClose"
    >
        <div>

            <IndicatorFreeText
                v-if="inputValueType === RiskValueType.FreeText"
                :indicator="indicator"
                :definition="definition"
                @updated="onValueUpdated"
            />

            <IndicatorNumberInput
                v-if="inputValueType === RiskValueType.Number"
                :indicator="indicator"
                :definition="definition"
                @updated="onValueUpdated"
            />

            <IndicatorYesNo
                v-if="inputValueType === RiskValueType.YesNo"
                :indicator="indicator"
                :definition="definition"
                @updated="onValueUpdated"
            />

            <IndicatorMultipleChoice
                v-if="inputValueType === RiskValueType.MultipleChoice"
                :indicator="indicator"
                :definition="definition"
                @updated="onValueUpdated"
            />

            <IndicatorNestedList
                v-if="isSpecialValueType()"
                :assets="assets"
                :input-value-type="inputValueType"
                @updated="onValueUpdated"
            />

        </div>
    </PopupDialog>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

import { Project } from "@/lib/projects/projects";
import PopupDialog from "@/ui/PopupDialog.vue";
import { isSpecialType } from "@/views/risk/special_types/special_types";
import { RiskAssets } from "@/views/risk/risk_assets";
import { Assessment, RiskIndicator } from "@/lib/assessments/assessments";
import { RiskIndicatorDefinition, RiskMatrixDefinition, RiskValueType } from "@/lib/risk/risk_matrix";
import { addAssessmentValues, LocalAssessmentValue } from "@/lib/assessments/update_assessment";
import IndicatorFreeText from "./indicator_inputs/IndicatorFreeText.vue";
import IndicatorNumberInput from "./indicator_inputs/IndicatorNumberInput.vue";
import IndicatorMultipleChoice from "./indicator_inputs/IndicatorMultipleChoice.vue";
import IndicatorNestedList from "./indicator_inputs/IndicatorNestedList.vue";
import { getProjectProcess } from "@/lib/projects/get_project";
import IndicatorYesNo from "./indicator_inputs/IndicatorYesNo.vue";
import { getInputValueType } from "@/views/risk/source_wizard/risk_sources";

export default defineComponent({
    name: "RiskManualValueDialog",
    components: {
        PopupDialog,
        IndicatorFreeText,
        IndicatorNumberInput,
        IndicatorMultipleChoice,
        IndicatorNestedList,
        IndicatorYesNo,
    },
    emits: ["request-project-refresh", "close-modal"],
    props: {
        assets: { type: Object as PropType<RiskAssets>, required: true },
        indicator: { type: Object as PropType<RiskIndicator>, required: true },
        definition: { type: Object as PropType<RiskIndicatorDefinition>, required: true },
        matrix: { type: Object as PropType<RiskMatrixDefinition>, required: true },
        project: { type: Object as PropType<Project>, required: true },
        assessment: { type: Object as PropType<Assessment>, required: true },
    },
    data() {
        return {
            RiskValueType,

            dialog: { visible: true, isWorking: false, statusText: "", isError: false, errorText: "" },

            isValid: false,
            valueModel: {} as LocalAssessmentValue,

            inputValueType: RiskValueType.FreeText,
        };
    },
    methods: {

        onClose() {
            this.$emit("close-modal");
        },

        isSpecialValueType() {
            return isSpecialType(this.inputValueType);
        },

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onValueUpdated(value: any, isValid: boolean) {
            this.valueModel = {
                indicatorKey: this.indicator.key,
                value: value,
                override: true,
            };
            this.isValid = isValid;
        },

        async onSave() {
            this.dialog.isError = false;
            this.dialog.isWorking = true;
            if (!this.isValid) {
                this.dialog.errorText = "Invalid input!";
                this.dialog.isError = true;
                this.dialog.isWorking = false;
                return;
            }
            const values: LocalAssessmentValue[] = [
                this.valueModel,
            ];
            const response = await addAssessmentValues(this.project.id, this.assessment.id, values);
            if (response.responseInfo.isError || !response.assessment) {
                this.dialog.errorText = response.responseInfo.parsedError?.message || "Unknown Error";
                this.dialog.isError = true;
            } else {
                const projectResponse = await getProjectProcess(this.project.id);
                this.$emit("request-project-refresh", projectResponse);
                this.onClose();
            }
            this.dialog.isWorking = false;
        },

    },
    mounted() {
        this.inputValueType = getInputValueType(this.definition.valueType);
        console.log("this.inputValueType", this.inputValueType);
    },
});
</script>
<style scoped></style>
