import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e62aabb2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fbav3-input-color" }
const _hoisted_2 = ["id"]
const _hoisted_3 = { class: "fbav3-input-color-c" }
const _hoisted_4 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      class: "fbav3-input-color-text",
      id: _ctx.uniqueId,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue) = $event)),
      onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onHexInput && _ctx.onHexInput(...args))),
      onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onHexBlur && _ctx.onHexBlur(...args)))
    }, null, 40, _hoisted_2), [
      [_vModelText, _ctx.localValue]
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("input", {
        type: "color",
        class: "fbav3-input-color-color",
        style: _normalizeStyle('background-color: #' + _ctx.modelValue + ';'),
        value: _ctx.modelValue,
        onInput: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onPickerInput && _ctx.onPickerInput(...args)))
      }, null, 44, _hoisted_4)
    ])
  ]))
}