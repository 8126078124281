import { RiskScores } from "@/lib/risk/risk_matrix";
import { RiskAssets } from "../risk_assets";
import { RiskNestedItem, RiskNestedListData } from "../risk_nested_list";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getHighRiskCountries = (assets: RiskAssets): RiskNestedListData => {
    const items: RiskNestedItem[] = [];

    items.push({
        level: 0,
        scoreKey: "hit",
        text: { key: "risk.default.highRiskCountry.hit" },
        expanded: false,
        code: "hit",
        showCode: false,
        codeWidth: 0,
    });

    items.push({
        level: 0,
        scoreKey: "noHit",
        text: { key: "risk.default.highRiskCountry.noHit" },
        expanded: false,
        code: "noHit",
        showCode: false,
        codeWidth: 0,
    });

    return {
        items,
        options: {
            limit: 0,
        },
    };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getHighRiskCountryDefaultScores = (assets: RiskAssets): RiskScores => {
    const scores: RiskScores = {};

    scores.hit = 5;
    scores.noHit = 5;

    return scores;
};
