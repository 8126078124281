export interface idControl {
    status: idControlStatus | screeningStatus;
    date: Date;
    name: string;
    source: string;
}

export enum idControlStatus {
    notStarted = "Not Started",
    inProgress = "In-Progress",
    success = "Match",
    fail = "Failed",
}

export enum screeningStatus {
    notStarted = "Not Started",
    hitFound = "Hit Found",
    noHitFound = "No Hit Found",
    fail = "Failed",
}
