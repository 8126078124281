<template>
    <div>

        <InputCheckbox2
            v-model="value"
            :items="items"
            :unique-id="'test'"
            @update:model-value="handleUpdate"
        />

    </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

import { AssessmentValue, RiskIndicator } from "@/lib/assessments/assessments";
import { RiskIndicatorDefinition } from "@/lib/risk/risk_matrix";
import InputCheckbox2 from "@/ui/inputs/InputCheckbox2.vue";
import { CheckboxItem, CheckboxModel, getCheckboxModel } from "@/ui/inputs/input_helper";

export default defineComponent({
    name: "IndicatorMultipleChoice",
    components: {
        InputCheckbox2,
    },
    emits: ["updated"],
    props: {
        indicator: { type: Object as PropType<RiskIndicator>, required: true },
        definition: { type: Object as PropType<RiskIndicatorDefinition>, required: true },
        existingValue: { type: Object as PropType<AssessmentValue | null>, default: null },
    },
    data() {
        return {
            value: {} as CheckboxModel,
            isValid: true,

            items: new Array<CheckboxItem>(),
        };
    },
    methods: {

        handleUpdate() {
            this.$emit("updated", this.value, this.isValid);
        },

    },
    mounted() {
        this.definition.values.forEach((value) => {
            this.items.push({
                key: value.key,
                text: value.name || { value: value.key },
            });
        });
        if (this.existingValue !== null) {
            this.value = this.existingValue.value;
        } else {
            this.value = getCheckboxModel(this.items);
        }
    },
});
</script>
<style scoped></style>
