import { RiskScores, RiskValueType } from "@/lib/risk/risk_matrix";
import { RiskAssets } from "../risk_assets";
import { getCountryDefaultScores } from "./country";
import { getNaceDefaultScores } from "./nace_code";
import { getHighRiskCountryDefaultScores } from "./high_risk_country";
import { getPepHitDefaultScores } from "./pep_hit";

export const isSpecialType = (valueType: RiskValueType): boolean => {
    if (valueType === RiskValueType.NaceCode ||
        valueType === RiskValueType.Country ||
        valueType === RiskValueType.HighRiskCountry ||
        valueType === RiskValueType.PepHit
    ) {
        return true;
    }
    return false;
};

export const getDefaultScores = (valueType: RiskValueType, assets: RiskAssets): RiskScores => {
    switch (valueType) {
    case RiskValueType.Country: return getCountryDefaultScores(assets);
    case RiskValueType.NaceCode: return getNaceDefaultScores(assets);
    case RiskValueType.HighRiskCountry: return getHighRiskCountryDefaultScores(assets);
    case RiskValueType.PepHit: return getPepHitDefaultScores(assets);
    }
    return {};
};
