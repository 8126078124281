export interface ParsedError {
    code: number;
    codeText: string;
    message: string;
}

export type ApiResponse = {
    isError: boolean;
    parsedError: ParsedError | null;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseRemoteError = (error: any): ParsedError => {
    const parsedError: ParsedError = {
        code: 0,
        codeText: "Unknown error",
        message: "Unknown error",
    };
    try {
        if (error.error) {
            const errorData = error.error;

            if (errorData.message) parsedError.message = errorData.message;

            // todo: parse validationErrors
        } else if (error.message) {
            if (error.status) {
                parsedError.message = error.status + " - " + error.message;
            } else {
                parsedError.message = error.message;
            }
        }
    } catch (ex) {
        parsedError.code = 0;
        parsedError.codeText = "Unknown error";
        parsedError.message = "Unknown error";
    }
    // console.log('parsedError', parsedError);
    return parsedError;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseLocalError = (error: any): ParsedError => {
    const parsedError: ParsedError = {
        code: 0,
        codeText: "Unknown error",
        message: "Unknown error",
    };
    try {
        if (error.error) {
            const errorData = error.error;

            if (errorData.message) parsedError.message = errorData.message;

            // todo: parse validationErrors
        }
        if (error.name === "TimeoutError") {
            parsedError.message = "Network request timed out, check your internet connection";
        }
    } catch (ex) {
        parsedError.code = 0;
        parsedError.codeText = "Unknown error";
        parsedError.message = "Unknown error";
    }
    // console.log('parsedError', parsedError);
    return parsedError;
};
