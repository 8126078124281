<template>
    <img :src="`/aml-spa/flags/${country}.svg`" />
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    name: "Flag",
    props: {
        country: String,
    },
});
</script>
