<template>
    <span class="fbav3-input-textlocked">
        {{ getText() }}
    </span>
</template>
<script lang="ts">
import { LocalizedString } from "@/lib/common/common";
import { defineComponent, type PropType } from "vue";

export default defineComponent({
    name: "InputLocked",
    props: {
        modelValue: { type: [String, Number, Object] as PropType<string | number | LocalizedString>, required: true },
        uniqueId: { type: String as PropType<string>, required: true },
    },
    emits: ["update:modelValue"],
    methods: {

        getText() {
            if (typeof this.modelValue === "object") return this.$ct(this.modelValue);
            return this.modelValue.toString();
        },

    },
});
</script>
<style scoped>
.fbav3-input-textlocked {
    width: 100%;
    height: 42px;
    padding: 0px 8px;
    font-size: 14px;
    font-weight: 500;
    color: #444444;
    box-sizing: border-box;
}
</style>
