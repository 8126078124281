<template>
    <Popup
        v-if="openScreeningDetails"
        @closed="shouldCloseScreeningDetailsModal"
        :ignore-click-outside="true"
        width="550px"
        title="Screening Details"
    >
        <div class="border rounded border-light border-solid text-sm">
            <div class="m-2">
                <div class="text-sm bg-gray-200 w-full font-bold">
                    {{ $t("screening.screeningSummary") }}
                </div>
                <div class="my-2 w-full h-px bg-[#E1E1E1]"></div>
                <div class="text-xs font-bold flex gap-1 justify-start items-center">
                    <!-- <div>{{ $t('screening.result') }}</div> -->
                    <!-- <div class="border rounded-lg font-bold border-solid px-1 bg-[#F2CA61]">
                        PEP:2
                    </div> -->
                </div>
                <div class="text-xs flex pt-1 font-bold">
                    <div>Source:</div>
                    <div class="pl-1">
                        Pliance Monitor
                    </div>
                </div>
                <div class="my-2 w-full h-px bg-[#E1E1E1]"></div>

                <!-- Information Paragraph with Icon -->
                <div class="w-full flex items-start gap-2 mt-2 bg-gray-200">
                    <Icon icon="info" class="w-4 h-4 mt-0.5" />
                    <div class="text-xs text-gray-600 w-1/2">
                        <i>
                            Hits from PEP/Sanction lists will usually be in English, but
                            sometimes it might be in the original language, depending on
                            the list being used.
                        </i>
                    </div>
                </div>
            </div>
        </div>
        <div class="border rounded border-light border-solid text-sm mt-2">
            <div class="m-2">
                <div class="border-t border-gray-300 pt-2">
                    <div class="text-sm font-bold bg-gray-200  w-full">
                        Search Parameters
                    </div>
                    <div class="my-2 w-full h-px bg-[#E1E1E1]"></div>
                    <div class="text-xs">
                        <div class="grid grid-cols-2 gap-4">
                            <div>
                                <div class="font-bold">Name:</div>
                                <div>
                                    {{ person.name }}
                                </div>
                            </div>
                            <div>
                                <div class="font-bold">Date of Birth:</div>
                                <div>
                                    {{ person.dob }}
                                </div>
                            </div>
                        </div>
                        <div class="mt-2">
                            <div
                                class="font-bold"
                            >
                                Searched Watchlist:
                            </div>
                            <div class="">
                                Pliance
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="border rounded border-light border-solid text-sm mt-2">
            <div class="m-2">
                <ScreeningHit
                    v-for="(hit, index) in screeningHits"
                    :key="index"
                    :title="hit.title"
                    :active="hit.active"
                    :match-against="hit.matchAgainst"
                    :match-indicator-score="hit.matchIndicatorScore"
                    :name="hit.name"
                    :alias="hit.alias"
                    :dob="hit.dob"
                    :country="hit.country"
                    :notes="hit.notes"
                    :false-positive="hit.falsePositive"
                    @update-false-positive="markFalsePositive(index, $event)"
                />
            </div>
        </div>
        <div class="w-full flex justify-end gap-4 mt-5">
            <ActionButton
                theme="transparent"
                :loading="false"
                :disabled="false"
                @btn-clicked="shouldCloseScreeningDetailsModal"
            >
                Cancel
            </ActionButton>

            <ActionButton
                theme="primary"
                :loading="false"
                :disabled="false"
                @btn-clicked="saveFalsePositive"
            >
                Save False Positive
            </ActionButton>
        </div>
    </Popup>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import Popup from "../Popup.vue";
import Icon from "../Icon.vue";
import ScreeningHit from "./ScreeningHit.vue";
import ActionButton from "../ActionButton.vue";

export default defineComponent({
    name: "ScreeningDetailsComponent",
    components: {
        Popup,
        Icon,
        ScreeningHit,
        ActionButton,
    },
    props: {
        openScreeningDetails: {
            type: Boolean,
            required: true,
        },
        person: {
            type: Object as PropType<{ name: string; dob: string }>,
            required: true,
        },
    },
    emits: ["closeScreeningDetailsModal"],
    data() {
        return {
            selectedFalsePositives: [] as Array<string>,
            openModal: false,
            screeningHits: [
                {
                    _id: "1a2b3c4d5e6f",
                    title: "Politically Exposed Person",
                    active: true,
                    matchAgainst: "EL_GLOBAL",
                    matchIndicatorScore: 0.97,
                    name: "Saddam von Putin",
                    alias: "Saddam Ivan Putin",
                    dob: "24-12-1957",
                    country: "Republic of Country",
                    notes: "Suspected leader of invasion on Earth.",
                    falsePositive: false,
                },
                {
                    _id: "7g8h9i0j1k2l",
                    title: "Politically Exposed Person",
                    active: true,
                    matchAgainst: "EL_GLOBAL",
                    matchIndicatorScore: 0.23,
                    name: "Saddam von Putin",
                    alias: "Vladimir Saddam von Putin",
                    dob: "24-12-1971",
                    country: "Supreme Leader of Warning Planet",
                    notes: "Thought to be leading invasion on Earth.",
                    falsePositive: false,
                },
            ],
        };
    },
    methods: {
        shouldCloseScreeningDetailsModal() {
            this.openModal = false;
            this.$emit("closeScreeningDetailsModal");
        },
        toggleScreeningModal() {
            this.openModal = !this.openModal;
            console.log(this.openModal, "Heree Pat");
        },
        markFalsePositive(index: number, value: boolean) {
            this.screeningHits[index].falsePositive = value;
            const hitId = this.screeningHits[index]._id;
            const alreadySelectedHitId = this.selectedFalsePositives.includes(hitId);
            if (value) {
                if (!alreadySelectedHitId) {
                    this.selectedFalsePositives.push(hitId);
                } else {
                    this.selectedFalsePositives == this.selectedFalsePositives.filter(id => id !== hitId);
                }
            }
        },
        saveFalsePositive() {
        // implement the API call here
        },
    },
});
</script>
