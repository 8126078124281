<template>
    <div>

        <MenuSwitch
            v-model="filterMode"
            :items="filterItems"
            :has-button="!project.assessment.locked && !(dependency && dependency.hasDependency)"
            :button-icon="'link'"
            :button-text="'Link dependency'"
            @clicked="newDialog.visible = true"
        />

        <div v-if="dependency && dependency.hasDependency" class="amlv3-dependency-item">

            <div class="amlv3-dependency-item-name">
                {{ dependency.data.name }}
            </div>

            <div class="amlv3-dependency-item-action" @click="deleteDialog.visible = true">
                <FontIcon :icon="'trash-can'" :size="18" />
            </div>

            <div class="amlv3-dependency-item-action amlv3-dependency-item-action-last">
                <FontIcon :icon="'arrow-up-right-from-square'" :size="18" />
            </div>

        </div>

        <LinkDependencyDialog
            v-if="newDialog.visible"
            :action="action"
            :project="project"
            @close-modal="newDialog.visible = false"
            @request-project-refresh="$emit('request-project-refresh', $event)"
        />

        <PopupDialog
            :is-visible="deleteDialog.visible"
            :header="$t('common.actions.delete')"
            :icon="'trash-can'"
            :show-action-button="true"
            :show-cancel-button="true"
            :action-text="$t('common.actions.delete')"
            :action-icon="'trash-can'"
            :progress="deleteDialog"
            :is-warning="true"
            @close-modal="deleteDialog.visible = false"
            @action="deleteDependency"
        >
            <PopupDialogPrompt
                :is-warning="true"
                :prompt-text="$t('common.actions.areYouSure')"
            />
        </PopupDialog>

    </div>
</template>
<script lang="ts">
import { Project } from "@/lib/projects/projects";
import { CheckboxItem } from "@/ui/inputs/input_helper";
import { defineComponent, type PropType } from "vue";
import MenuSwitch from "./menu/MenuSwitch.vue";
import FontIcon from "@/ui/FontIcon.vue";
import PopupDialog from "@/ui/PopupDialog.vue";
import PopupDialogPrompt from "@/ui/dialog/PopupDialogPrompt.vue";
import { removeDependency } from "@/lib/projects/add_dependency";
import { getProjectProcess } from "@/lib/projects/get_project";
import LinkDependencyDialog from "./LinkDependencyDialog.vue";
import { FlowAction } from "@/types/flow_types";

export default defineComponent({
    name: "DependencyControl",
    components: {
        MenuSwitch,
        FontIcon,
        PopupDialog,
        PopupDialogPrompt,
        LinkDependencyDialog,
    },
    emits: ["request-project-refresh"],
    props: {
        project: { type: Object as PropType<Project>, required: true },
        dependency: { type: Object as PropType<any>, required: true },
    },
    data() {
        return {
            action: { action: "link", params: { sameIdentity: true } } as FlowAction,
            newDialog: { visible: false, isWorking: false, statusText: "", isError: false, errorText: "" },
            deleteDialog: { visible: false, isWorking: false, statusText: "", isError: false, errorText: "" },
            filterItems: [
                {
                    key: "selected",
                    textKey: "project.forms.filters.active",
                },
            ] as CheckboxItem[],
            filterMode: "selected",
        };
    },
    methods: {

        async deleteDependency() {
            this.deleteDialog.isError = false;
            this.deleteDialog.isWorking = true;
            this.deleteDialog.statusText = "Removing dependency";

            const response = await removeDependency(this.project.id);
            if (response.responseInfo.isError) {
                this.deleteDialog.errorText = response.responseInfo.parsedError?.message || "Unknown error";
                this.deleteDialog.isError = true;
            } else {
                const projectResponse = await getProjectProcess(this.project.id);
                this.$emit("request-project-refresh", projectResponse);
                this.deleteDialog.visible = false;
            }

            this.deleteDialog.isWorking = false;
        },

    },
});
</script>
<style scoped>

.amlv3-dependency-item {
    display: flex;
}

.amlv3-dependency-item-name {
    flex-grow: 1;
    padding: 3px 10px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border: solid 1px lightgray;
}

.amlv3-dependency-item-action {
    width: 34px;
    padding-top: 2px;
    text-align: center;
    border: solid 1px lightgray;
    border-left: none;
    cursor: pointer;
}
.amlv3-dependency-item-action:hover {
    background-color: whitesmoke;
}

.amlv3-dependency-item-action-last {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

</style>
