import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "amlv3-pop-warning-hint" }
const _hoisted_2 = { class: "amlv3-pop-warning-hint-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["amlv3-pop-warning-hint-icon", [
                { 'amlv3-pop-warning-hint-icon-warning': _ctx.isWarning },
            ]])
    }, _toDisplayString('!'), 2),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.text), 1)
  ]))
}