import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputLabel = _resolveComponent("InputLabel")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_InputGroup = _resolveComponent("InputGroup")!
  const _component_InputTextLocalized = _resolveComponent("InputTextLocalized")!
  const _component_PopupDialogSplit = _resolveComponent("PopupDialogSplit")!
  const _component_PopupDialog = _resolveComponent("PopupDialog")!

  return (_openBlock(), _createBlock(_component_PopupDialog, {
    "is-visible": true,
    header: 'Approval Configuration',
    icon: 'wand-magic-sparkles',
    "show-cancel-button": true,
    "show-action-button": true,
    "action-text": 'Save',
    "action-icon": 'floppy-disk',
    "is-action-disabled": !_ctx.dialog.valid,
    onCloseModal: _ctx.onClose,
    onAction: _ctx.onSave
  }, {
    default: _withCtx(() => [
      _createVNode(_component_PopupDialogSplit, {
        "is-form": "",
        "prompt-text": _ctx.promptText,
        "prompt-hint": _ctx.promptHint
      }, {
        default: _withCtx(() => [
          _createVNode(_component_InputGroup, { width: 12 }, {
            default: _withCtx(() => [
              _createVNode(_component_InputLabel, {
                text: 'Key',
                "unique-id": 'end-key',
                required: ""
              }),
              _createVNode(_component_InputText, {
                modelValue: _ctx.model.key,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.key) = $event)),
                "unique-id": 'end-key',
                disabled: _ctx.isEditMode,
                onInput: _ctx.validateDialog
              }, null, 8, ["modelValue", "disabled", "onInput"])
            ]),
            _: 1
          }),
          _createVNode(_component_InputGroup, { width: 12 }, {
            default: _withCtx(() => [
              _createVNode(_component_InputLabel, {
                text: 'Name',
                "unique-id": 'end-name',
                required: ""
              }),
              _createVNode(_component_InputTextLocalized, {
                modelValue: _ctx.model.name,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.name) = $event)),
                "unique-id": 'end-name',
                onInput: _ctx.validateDialog,
                onEnter: _ctx.onSave
              }, null, 8, ["modelValue", "onInput", "onEnter"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["prompt-text", "prompt-hint"])
    ]),
    _: 1
  }, 8, ["is-action-disabled", "onCloseModal", "onAction"]))
}