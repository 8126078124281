<template>
    <Switch
        :model-value="modelValue"
        class="group relative inline-flex h-5 w-9 flex-shrink-0 cursor-pointer items-center justify-center rounded-full"
        @update:model-value="(value: boolean) => $emit('update:modelValue', value)"
        v-slot="{ checked }"
    >
        <span class="sr-only">Use setting</span>
        <span
            aria-hidden="true"
            class="pointer-events-none absolute h-full w-full mx-auto rounded-full transition-colors duration-200 ease-in-out"
            :class="[checked ? 'bg-primary-default' : 'bg-secondary']"
        />
        <span
            aria-hidden="true"
            :class="[
                checked ? 'translate-x-4' : 'translate-x-1',
                'pointer-events-none absolute left-0 inline-block p-1 h-4 w-4 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-500 ease-in-out',
            ]"
        />
    </Switch>
</template>

<script lang="ts">
import { Switch } from "@headlessui/vue";

export default {
    name: "Toggle",
    props: {
        modelValue: Boolean,
    },
    data() {
        return {
            enabled: false,
        };
    },
    emits: ["update:modelValue"],
    components: { Switch },
};
</script>
