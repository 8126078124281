import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-df877ab0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "amlv3-overviewtab-topbar" }
const _hoisted_2 = { class: "amlv3-overviewtab-status" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomerWorkflowSelector = _resolveComponent("CustomerWorkflowSelector")!
  const _component_AssigneeSelector = _resolveComponent("AssigneeSelector")!
  const _component_ManagerSelector = _resolveComponent("ManagerSelector")!
  const _component_CustomerTopAction = _resolveComponent("CustomerTopAction")!
  const _component_StatusAndText = _resolveComponent("StatusAndText")!
  const _component_StepRenderer = _resolveComponent("StepRenderer")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_CustomerWorkflowSelector, {
        project: _ctx.project,
        assessment: _ctx.currentAssessment,
        onRequestProjectRefresh: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('request-project-refresh', $event)))
      }, null, 8, ["project", "assessment"]),
      _createVNode(_component_AssigneeSelector, {
        project: _ctx.project,
        onRequestProjectRefresh: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('request-project-refresh', $event)))
      }, null, 8, ["project"]),
      _createVNode(_component_ManagerSelector, {
        project: _ctx.project,
        onRequestProjectRefresh: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('request-project-refresh', $event)))
      }, null, 8, ["project"]),
      (_ctx.project.externalId)
        ? (_openBlock(), _createBlock(_component_CustomerTopAction, {
            key: 0,
            icon: 'key',
            text: _ctx.project.externalId,
            "is-clickable": true,
            tooltip: _ctx.$t('project.external.title')
          }, null, 8, ["text", "tooltip"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_StatusAndText, {
        text: _ctx.getStatusText(),
        "is-completed": _ctx.project.assessment.sealed
      }, null, 8, ["text", "is-completed"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_StepRenderer, {
        "render-data": _ctx.flowRenderData,
        onAction: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('action', $event))),
        onRequestProjectRefresh: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('request-project-refresh', $event)))
      }, null, 8, ["render-data"])
    ])
  ]))
}