<template>
    <div class="flex items-center">
        <h2 class="flex-1 text-lg font-bold">{{ text }}</h2>
        <Icon v-if="!hideIcon" @click="$emit('clicked')" :class="'w-6 cursor-pointer text-primary-default hover:text-primary-dark' +
            (working ? ' animate-spin' : '')
            " :icon="working ? 'tabler:loader-2' : 'aml:round'"></Icon>
    </div>
</template>
<script lang="ts">
import { defineComponent, type PropType } from 'vue';

import Icon from '../Icon.vue';

export default defineComponent({
    name: 'HeaderWithAddIcon',
    components: {
        Icon,
    },
    emits: ['clicked'],
    props: {
        text: { type: String as PropType<string>, required: false },
        working: { type: Boolean as PropType<boolean>, default: false },
        hideIcon: { type: Boolean as PropType<boolean>, default: false },
    },
});
</script>
