<template>
    <PopupDialog
        :is-visible="dialog.visible"
        :header="$t('project.forms.title')"
        :icon="'self-declaration'"
        :progress="dialog"
        :show-cancel-button="true"
        :show-action-button="true"
        :action-text="$t('common.actions.submit')"
        :is-action-disabled="!isValid"
        @close-modal="onClose"
        @action="onSubmitClicked"
    >
        <div>
            <iframe
                id="formdialogframe"
                title="Form"
                scrolling="auto"
                :src="formUrl"
            />
        </div>
    </PopupDialog>
</template>
<script lang="ts">
import { defineComponent } from "vue";

import PopupDialog from "@/ui/PopupDialog.vue";

export default defineComponent({
    name: "FormDialog",
    components: {
        PopupDialog,
    },
    emits: ["close-modal"],
    data() {
        return {
            formUrl: "",
            redirectRoute: null as any,
            dialog: { visible: true, isWorking: false, statusText: "", isError: false, errorText: "" },
            isValid: false,
        };
    },
    methods: {

        onClose() {
            this.$emit("close-modal");
        },

        onSubmitClicked() {

        },

        onMessageReceived(event: MessageEvent) {
            try {
                const message = JSON.parse(event.data);
                console.log("Message received:", message);

                if (message.action === "submitted") this.onSubmitted();
            } catch (ex) {
                console.error("Failed to parse message", ex);
            }
        },

        onSubmitted() {
            console.log("Form submitted, redirecting to", this.redirectRoute);
            this.$router.push(this.redirectRoute);
        },

    },
    async mounted() {
        window.addEventListener("message", this.onMessageReceived);

        if (sessionStorage.getItem("amlFormView")) {
            const data = sessionStorage.getItem("amlFormView") || "";
            try {
                const parsedData = JSON.parse(data);
                console.log("parsedData", parsedData);

                this.formUrl = parsedData.formUrl;
                this.formUrl = this.formUrl.replace("&token=", "&frameMode=dialog&token=");

                this.redirectRoute = parsedData.redirectRoute;
            } catch (error) {
                console.error(error);
            }
        }
        console.log("formUrl", this.formUrl);
    },
    unmounted() {
        window.removeEventListener("message", this.onMessageReceived);
    },
});
</script>
<style scoped>
#formdialogframe {
    width: 100%;
    min-height: 265px;
    border: none;
}
</style>
