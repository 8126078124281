import { ApiResponse, parseLocalError, parseRemoteError } from "../error_parser";
import { Verified } from "../verified_plugin";

export type UpdateProjectOptions = {
    name?: string;
    assignee?: string;
    manager?: string;
    workflow?: string;
    department?: string;
};

export const updateProject = async (projectId: string, options: UpdateProjectOptions): Promise<{ ok: boolean, responseInfo: ApiResponse }> => {
    try {
        const response = await fetch(`/api/aml/v1/projects/${projectId}`, {
            method: "PATCH",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
            body: JSON.stringify({
                ...options,
            }),
        });
        const body = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                responseInfo: { isError: true, parsedError: parseRemoteError(body) },
            };
        }
        return {
            ok: true,
            responseInfo: { isError: false, parsedError: null },
        };
    } catch (error) {
        return {
            ok: false,
            responseInfo: { isError: true, parsedError: parseLocalError(error) },
        };
    }
};
