import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IndicatorFreeText = _resolveComponent("IndicatorFreeText")!
  const _component_IndicatorNumberInput = _resolveComponent("IndicatorNumberInput")!
  const _component_IndicatorYesNo = _resolveComponent("IndicatorYesNo")!
  const _component_IndicatorMultipleChoice = _resolveComponent("IndicatorMultipleChoice")!
  const _component_IndicatorNestedList = _resolveComponent("IndicatorNestedList")!
  const _component_PopupDialog = _resolveComponent("PopupDialog")!

  return (_openBlock(), _createBlock(_component_PopupDialog, {
    "is-visible": _ctx.dialog.visible,
    header: _ctx.$t('assessment.indicators.addManualValue'),
    icon: 'wand-magic-sparkles',
    "show-cancel-button": true,
    "show-action-button": true,
    "action-text": _ctx.$t('common.actions.save'),
    "action-icon": 'floppy-disk',
    "is-action-disabled": !_ctx.isValid,
    progress: _ctx.dialog,
    onAction: _ctx.onSave,
    onCloseModal: _ctx.onClose
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        (_ctx.inputValueType === _ctx.RiskValueType.FreeText)
          ? (_openBlock(), _createBlock(_component_IndicatorFreeText, {
              key: 0,
              indicator: _ctx.indicator,
              definition: _ctx.definition,
              onUpdated: _ctx.onValueUpdated
            }, null, 8, ["indicator", "definition", "onUpdated"]))
          : _createCommentVNode("", true),
        (_ctx.inputValueType === _ctx.RiskValueType.Number)
          ? (_openBlock(), _createBlock(_component_IndicatorNumberInput, {
              key: 1,
              indicator: _ctx.indicator,
              definition: _ctx.definition,
              onUpdated: _ctx.onValueUpdated
            }, null, 8, ["indicator", "definition", "onUpdated"]))
          : _createCommentVNode("", true),
        (_ctx.inputValueType === _ctx.RiskValueType.YesNo)
          ? (_openBlock(), _createBlock(_component_IndicatorYesNo, {
              key: 2,
              indicator: _ctx.indicator,
              definition: _ctx.definition,
              onUpdated: _ctx.onValueUpdated
            }, null, 8, ["indicator", "definition", "onUpdated"]))
          : _createCommentVNode("", true),
        (_ctx.inputValueType === _ctx.RiskValueType.MultipleChoice)
          ? (_openBlock(), _createBlock(_component_IndicatorMultipleChoice, {
              key: 3,
              indicator: _ctx.indicator,
              definition: _ctx.definition,
              onUpdated: _ctx.onValueUpdated
            }, null, 8, ["indicator", "definition", "onUpdated"]))
          : _createCommentVNode("", true),
        (_ctx.isSpecialValueType())
          ? (_openBlock(), _createBlock(_component_IndicatorNestedList, {
              key: 4,
              assets: _ctx.assets,
              "input-value-type": _ctx.inputValueType,
              onUpdated: _ctx.onValueUpdated
            }, null, 8, ["assets", "input-value-type", "onUpdated"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["is-visible", "header", "action-text", "is-action-disabled", "progress", "onAction", "onCloseModal"]))
}