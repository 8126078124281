<template>
    <div
        class="aml-pdfview"
        @click="$emit('close')"
    >

        <div
            class="aml-pdfview-pages"
            :style="`width: ${pageWidth}px;`"
            @click.stop
        >

            <div
                class="aml-pdfview-page"
                v-for="(page, i) in pages"
                :key="`p${i}`"
                :style="`width: ${pageWidth + 12}px;`"
            >
                <canvas
                    class="pp"
                    :class="{ ppv: page.visible }"
                    :id="`pc${i}`"
                    :width="pageWidth"
                    v-observe-visibility="visibilityChanged"
                />
            </div>

        </div>

    </div>
</template>
<script>
import * as pdfjsLib from "pdfjs-dist";

export default {
    name: "PdfViewer",
    emits: ["close"],
    props: {
        pageWidth: { type: Number, default: 900 },
        pageHeight: { type: Number, default: 1300 },
        file: { type: [File, Blob], default: null },
    },
    data() {
        return {
            pages: [],

            pageCount: 0,
            currentPage: 0,
        };
    },
    methods: {

        visibilityChanged(isVisible, entry) {
            if (!this.pdfDocument) return;
            // console.log('visible = ' + isVisible, entry);
            const index = Number(entry.target.id.replace("pc", ""));
            // console.log('index', index);

            this.pages[index].visible = isVisible;

            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const self = this;
            setTimeout(() => {
                self.onChanged(index);
            }, 500);
        },

        onChanged(index) {
            this.goToPage(this.pages[index]);
        },

        loadInitialPages() {
            const initalPageCount = 2;
            let count = 0;
            for (const i in this.pages) {
                count += 1;
                if (count > initalPageCount) break;
                this.pages[i].visible = true;
                this.goToPage(this.pages[i]);
            }
        },

        readRawData(data) {
            // console.log(data);
            this.showPdf = true;
            this.loadPdf({ data: window.atob(data) });
        },

        readFile(file) {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const self = this;
            const reader = new FileReader();
            if (file.type === "application/pdf") {
                // eslint-disable-next-line func-names
                reader.onload = function () {
                    try {
                        const typedarray = new Uint8Array(this.result);
                        self.showPdf = true;
                        self.loadPdf(typedarray);
                    } catch (e) {
                        self.showError = true;
                    }
                };
                reader.readAsArrayBuffer(file);
            } else {
                // eslint-disable-next-line func-names
                reader.onload = function () {
                    try {
                        self.showImage = true;
                        self.loadImage(this.result);
                    } catch (e) {
                        self.showError = true;
                    }
                };
                reader.readAsDataURL(file);
            }
        },

        loadPdf(data) {
            this.invalidFile = false;
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const self = this;

            pdfjsLib.GlobalWorkerOptions.workerSrc = "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.7.76/pdf.worker.min.mjs";

            // Loading a document.
            const loadingTask = pdfjsLib.getDocument(data);
            loadingTask.promise.then((pdfDocument) => {
                self.pdfDocument = pdfDocument;
                self.pageCount = pdfDocument.numPages || 1;

                while (self.pages.length > 1) {
                    self.pages.pop();
                }
                for (let i = 1; i < self.pageCount; i += 1) {
                    self.pages.push({
                        index: i + 1,
                        visible: false,
                    });
                }

                // Request a first page
                self.loadInitialPages();
            }).catch((reason) => {
                // eslint-disable-next-line no-console
                console.error(`Error: ${reason}`);
                self.invalidFile = true;
            });
        },

        goToPage(page) {
            // console.log('going to page ', page);
            const width = this.pageWidth;
            return this.pdfDocument.getPage(page.index).then((pdfPage) => {
                // Display page on the existing canvas with 100% scale.
                const canvas = document.getElementById(`pc${page.index - 1}`);
                canvas.width = width;

                const scale = canvas.width / pdfPage.getViewport({ scale: 0.5 }).width;
                const viewport = pdfPage.getViewport({ scale });
                canvas.width = viewport.width;
                canvas.height = viewport.height;

                canvas.style.width = "100%";
                /* canvas.style.height = '100%'; */

                // console.log('w = ' + canvas.width + ' h= ' + canvas.height);
                const ctx = canvas.getContext("2d");
                const renderTask = pdfPage.render({
                    canvasContext: ctx,
                    viewport,
                });
                return renderTask.promise;
            });
        },

        loadEmptyPages() {
            this.pages = [];
            for (let i = 0; i < 1; i += 1) {
                this.pages.push({
                    index: i + 1,
                    visible: false,
                });
            }
        },

    },
    mounted() {
        // Declare this here so that it's not reactive ?
        this.pdfDocument = null;
    },
    created() {
        this.loadEmptyPages();
        this.readFile(this.file);
    },
};
</script>
<style scoped>
.aml-pdfview {
    padding-top: 20px;
}

.aml-pdfview-pages {
    margin: 0 auto;
    padding-bottom: 100px;
}

.aml-pdfview-page {
    margin-bottom: 20px;
    padding: 6px;
    border-radius: 12px;
    background-color: white;
    -webkit-box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.75);
}
</style>
