import { LocalizedString } from "@/lib/common/common";
import { ProjectStatus } from "@/lib/projects/projects";

export interface FlowParams {
    [key: string]: boolean | string | number;
}

export enum StatusType {
    Default = "default",
    InProgress = "progress",
    Completed = "completed",
    Warning = "warning",
    Error = "error",
}

export interface PillRenderData {
    text: LocalizedString;
    type: StatusType;
}

export interface ActionRenderData {
    text: LocalizedString;
    isImportant: boolean;
    action: string;
}

export interface StepRenderData {
    name: LocalizedString;
    statusType: StatusType;
    pills: PillRenderData[];
    actions: ActionRenderData[];
    params: FlowParams;
}

export interface FlowRenderRequirement {
    completed: boolean;
    text: LocalizedString;
}

export interface FlowRenderData {
    name: LocalizedString;
    statusText: LocalizedString;
    steps: StepRenderData[];
    requirements: FlowRenderRequirement[];
    statusKey: string;
    projectStatus: ProjectStatus;
}

export interface FlowConditions {
    type: string;
    countries: string[];
    flags: string[];
}

export interface FlowAction {
    action: string;
    params: FlowParams;
}

export interface Workflow {
    key: string;
    namespace: string;

    name: LocalizedString;
    conditions: FlowConditions;

    inheritFlow: string;
    params: FlowParams;

    /* tabs: FlowTab[];
    steps: FlowStepConfig[];
    statuses: FlowStatus[];
    requirements: FlowRequirement[]; */
}

export interface WorkflowConfig {
    key: string;
    baseKey: string;
    name: LocalizedString;
    enabled: boolean;
    order: number;
    conditions: FlowConditions;
    params: FlowParams;
}
