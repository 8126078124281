import { LocalizedString } from "../common/common";

export enum FormContextType {
    Project = "project",
    Person = "person",
}

export const enum FormFlowStatus {
    Created = "created",
    Pending = "pending",
    Completed = "completed",
}

export enum FormScope {
    KYC = "kyc",
    IDControl = "idcontrol",
}

export const enum FormRole {
    Sender = "sender",
    Editor = "editor",
    Signer = "signer",
}

export interface FormActor {
    id: string;
    role: FormRole;
    uid: string;
    firstName: string;
    lastName: string;
    email: string;
    completed: boolean;
    rejected: boolean;
    rejectedMessage?: string;
}

export interface FormAttachment {
    id: string;
    path: string;
    name: string;
}

export interface FormDocument {
    id: string;
    path: string;
    name: string;
    attachments: FormAttachment[];
}

export interface Form {
    id: string;
    namespace: string;
    formId: string;
    templateSlug: string;
    templateKey: string;
    contextType: FormContextType;
    contextId: string;
    scopes: FormScope[];
    name: string;
    isSelected: boolean;
    isArchived: boolean;
    formStatus: FormFlowStatus;
    actors: FormActor[];
    currentActorId: string;
    createdAt: number;
    updatedAt: number;
    documents: FormDocument[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    model: any;
}

export interface FormTemplate {
    id: string;
    namespace: string;
    slug: string;
    key: string;
    name: LocalizedString;
    scopes: FormScope[];
}

export interface PossibleInputValue {
    modelKey: string;
    text: LocalizedString;
}

export interface PossibleInput {
    format: string;
    modelPath: string;
    text: LocalizedString;
    isFixed: boolean;
    values: PossibleInputValue[];
}
