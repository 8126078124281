import { type App } from "vue";
import { Setting } from "./lib/settings/settings";
import { RouteDefinition } from "./components/Nav.vue";
import { Policy } from "./lib/platform";

export class GlobalConfig {
    private _setting: Setting;

    constructor() {
        this._setting = {
            settings: {},
            backOffice: {},
        };
    }

    update(settings: Setting) {
        this._setting = settings;
    }

    get versionText() {
        return "%RELEASE_VERSION_STRING%";
    }

    get rawSettings() {
        return JSON.parse(JSON.stringify(this._setting));
    }

    get settings() {
        return this._setting.settings;
    }

    get backOffice() {
        return this._setting.backOffice;
    }

    getNavRoutes(policies: Policy[], ignorePolicies?: boolean): RouteDefinition[] {
        /* console.log("getNavRoutes with policies:", policies); */

        const routes: RouteDefinition[] = [];

        routes.push({
            name: "overview",
            text: "core.menu.overview",
            path: "/aml/overview",
            active: ["/aml/overview"],
            expanded: false,
            children: [],
        });

        routes.push({
            name: "customers",
            text: "core.menu.customers",
            path: "/aml/customers",
            active: ["/aml/customers*"],
            expanded: false,
            children: [],
        });

        if (policies.includes(Policy.PersonsReadAny) || ignorePolicies) {
            routes.push({
                name: "persons",
                text: "core.menu.persons",
                path: "/aml/persons",
                active: ["/aml/persons*"],
                expanded: false,
                children: [],
            });
        }

        if (policies.includes(Policy.SettingsWrite) || ignorePolicies) {
            routes.push({
                name: "settings",
                text: "core.menu.settings",
                path: "/aml/settings/flows/workflows",
                active: ["settings"],
                expanded: false,
                children: [
                    {
                        name: "flows",
                        text: "settings.menu.riskFlowSettings",
                        path: "/aml/settings/flows/workflows",
                        active: [],
                        expanded: false,
                        children: [
                            {
                                name: "workflows",
                                text: "settings.menu.workflows",
                                path: "/aml/settings/flows/workflows",
                                active: ["/aml/settings/flows/workflows"],
                                expanded: false,
                                children: [],
                            },
                            {
                                name: "matrices",
                                text: "settings.menu.matrices",
                                path: "/aml/settings/flows/matrices",
                                active: ["/aml/settings/flows/matrices"],
                                expanded: false,
                                children: [],
                            },
                            {
                                name: "formTemplates",
                                text: "settings.menu.formTemplates",
                                path: "/aml/settings/flows/formTemplates",
                                active: ["/aml/settings/flows/formTemplates"],
                                expanded: false,
                                children: [],
                            },
                            {
                                name: "approvals",
                                text: "settings.menu.approvals",
                                path: "/aml/settings/flows/approvals",
                                active: ["/aml/settings/flows/approvals"],
                                expanded: false,
                                children: [],
                            },
                        ],
                    },
                    {
                        name: "organization",
                        text: "settings.menu.organization",
                        path: "/aml/settings/organization/policyGroups",
                        active: [],
                        expanded: false,
                        children: [
                            /* {
                                name: "departments",
                                text: "settings.menu.departments",
                                path: "/aml/settings/organization/departments",
                                active: ["/aml/settings/organization/departments"],
                            },
                            {
                                name: "partners",
                                text: "settings.menu.partners",
                                path: "/aml/settings/organization/partners",
                                active: ["/aml/settings/organization/partners"],
                            }, */
                            {
                                name: "policyGroups",
                                text: "settings.menu.policyGroups",
                                path: "/aml/settings/organization/policyGroups",
                                active: ["/aml/settings/organization/policyGroups"],
                                expanded: false,
                                children: [],
                            },
                        ],
                    },
                    /* {
                        name: "screening",
                        text: "settings.menu.screening",
                        path: "/aml/settings/screening/monitors",
                        children: [
                            {
                                name: "monitors",
                                text: "settings.menu.monitors",
                                path: "/aml/settings/screening/monitors",
                                active: ["/aml/settings/screening/monitors"],
                            },
                            {
                                name: "screeningParams",
                                text: "settings.menu.screeningParams",
                                path: "/aml/settings/screening/screeningParams",
                                active: ["/aml/settings/screening/screeningParams"],
                            },
                            {
                                name: "lookups",
                                text: "settings.menu.lookups",
                                path: "/aml/settings/screening/lookups",
                                active: ["/aml/settings/screening/lookups"],
                            },
                        ],
                    }, */
                ],
            });
        }

        return routes;
    }
}

export default () => ({

    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
    install(app: App, options?: any) {
        app.config.globalProperties.$config = new GlobalConfig();
    },

});

declare module "@vue/runtime-core" {
    interface ComponentCustomProperties {
        $config: GlobalConfig;
    }
}
