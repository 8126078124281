<template>
    <div class="riskv3-txtbtn" @click="$emit('clicked')">

        {{ text }}

    </div>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";

export default defineComponent({
    name: "RiskTextButton",
    emits: ["clicked"],
    props: {
        text: { type: String as PropType<string>, required: true },
    },
});
</script>
<style scoped>
.riskv3-txtbtn {
    width: fit-content;
    height: 28px;
    padding: 3px 10px;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    border-radius: 4px;
    border: solid 1px lightgray;
    background-color: whitesmoke;
    cursor: pointer;
}

.riskv3-txtbtn:hover {
    background-color: lightgray;
}
</style>
