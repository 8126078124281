import { ApiResponse, parseLocalError, parseRemoteError } from "../error_parser";
import { Verified } from "../verified_plugin";

export const setManualScore = async (
    projectId: string,
    assessmentId: string,
    isOverride: boolean,
    score: number,
): Promise<{ wasSuccess: boolean, responseInfo: ApiResponse }> => {
    try {
        const response = await fetch(`/api/aml/v1/projects/${projectId}/assessments/${assessmentId}/score`, {
            method: "POST",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
            body: JSON.stringify({
                isOverride,
                score,
            }),
        });

        const body = await response.json();

        if (!response.ok) {
            return {
                wasSuccess: false,
                responseInfo: { isError: true, parsedError: parseRemoteError(body) },
            };
        }

        return {
            wasSuccess: true,
            responseInfo: { isError: false, parsedError: null },
        };
    } catch (error) {
        return {
            wasSuccess: false,
            responseInfo: { isError: true, parsedError: parseLocalError(error) },
        };
    }
};

export const removeManualScore = async (
    projectId: string,
    assessmentId: string,
): Promise<{ wasSuccess: boolean, responseInfo: ApiResponse }> => {
    try {
        const response = await fetch(`/api/aml/v1/projects/${projectId}/assessments/${assessmentId}/score`, {
            method: "DELETE",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
        });

        const body = await response.json();

        if (!response.ok) {
            return {
                wasSuccess: false,
                responseInfo: { isError: true, parsedError: parseRemoteError(body) },
            };
        }

        return {
            wasSuccess: true,
            responseInfo: { isError: false, parsedError: null },
        };
    } catch (error) {
        return {
            wasSuccess: false,
            responseInfo: { isError: true, parsedError: parseLocalError(error) },
        };
    }
};
