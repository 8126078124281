<template>
    <div
        class="amlv3-simplelist"
        @click="onClicked"
        :class="[
            { 'amlv3-simplelist-first': isFirst },
            { 'amlv3-simplelist-last': isLast },
            { 'amlv3-simplelist-selected': isSelected },
            { 'amlv3-simplelist-disabled': isDisabled },
            { 'amlv3-simplelist-separate': isSeparated },
        ]"
    >

        <div>

        </div>

        <div>
            <div class="amlv3-simplelist-text">
                {{ text }}
            </div>
            <div class="amlv3-simplelist-subtext">
                {{ subText }}
            </div>
        </div>

    </div>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";

export default defineComponent({
    name: "SimpleListItem",
    components: {

    },
    emits: ["clicked"],
    props: {
        text: { type: String as PropType<string>, required: true },
        subText: { type: String as PropType<string>, default: "" },
        isSelected: { type: Boolean as PropType<boolean>, default: false },
        isFirst: { type: Boolean as PropType<boolean>, default: false },
        isLast: { type: Boolean as PropType<boolean>, default: false },
        isDisabled: { type: Boolean as PropType<boolean>, default: false },
        isSeparated: { type: Boolean as PropType<boolean>, default: false },
    },
    methods: {

        onClicked() {
            if (this.isDisabled) return;
            this.$emit("clicked");
        },

    },
});
</script>
<style scoped>
.amlv3-simplelist {
    padding: 5px 15px;
    border: solid 1px lightgray;
    border-bottom: none;
    background-color: white;
    cursor: pointer;
}

.amlv3-simplelist:hover {
    background-color: whitesmoke;
}

.amlv3-simplelist-first {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.amlv3-simplelist-last {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom: solid 1px lightgray;
}

.amlv3-simplelist-selected {
    background-color: lightgray;
}

.amlv3-simplelist-selected:hover {
    background-color: lightgray;
}

.amlv3-simplelist-text {
    font-weight: 500;
}

.amlv3-simplelist-subtext {
    font-size: 14px;
    color: dimgray;
}

/* Disabled */
.amlv3-simplelist-disabled {
    cursor: initial;
}

.amlv3-simplelist-disabled:hover {
    background-color: white;
}

.amlv3-simplelist-disabled .amlv3-simplelist-text {
    color: lightgray;
}

.amlv3-simplelist-disabled .amlv3-simplelist-subtext {
    color: lightgray;
}

/* Separated */
.amlv3-simplelist-separate {
    margin-bottom: 10px;
    border-radius: 4px;
    border: solid 1px lightgray;
}
</style>
