<template>
    <div
        class="amlv3-standtx-status"
        :class="[
            {'amlv3-standtx-status-completed': isCompleted },
            {'amlv3-standtx-status-warning': isWarning },
            {'amlv3-standtx-status-error': isError },
        ]"
    >
        <div class="amlv3-standtx-status-ball" />
        <div class="amlv3-standtx-status-text">
            {{ text }}
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
    name: "StatusAndText",
    props: {
        text: { type: String as PropType<string>, default: "" },
        isCompleted: { type: Boolean as PropType<boolean>, default: false },
        isWarning: { type: Boolean as PropType<boolean>, default: false },
        isError: { type: Boolean as PropType<boolean>, default: false },
    },
});
</script>
<style>
.amlv3-standtx-status {
    display: flex;
}

.amlv3-standtx-status-ball {
    width: 14px;
    height: 14px;
    margin-top: 1px;
    border-radius: 50%;
    border: solid 2px #218BCB;
}
.amlv3-standtx-status-completed .amlv3-standtx-status-ball {
    background-color: #218BCB;
}
.amlv3-standtx-status-warning .amlv3-standtx-status-ball {
    border: solid 2px #F2CA61;
    background-color: #F2CA61;
}
.amlv3-standtx-status-error .amlv3-standtx-status-ball {
    border: solid 2px #B62537;
    background-color: #B62537;
}

.amlv3-standtx-status-text {
    margin-top: -2px;
    margin-left: 8px;
    font-size: 14px;
    color: dimgray;
}
</style>
