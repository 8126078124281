import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "space-y-2" }
const _hoisted_2 = { class: "overflow-y-auto h-[400px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomerTopAction = _resolveComponent("CustomerTopAction")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_SimpleListItem = _resolveComponent("SimpleListItem")!
  const _component_PopupDialog = _resolveComponent("PopupDialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_CustomerTopAction, {
      icon: 'user-tie',
      text: _ctx.managerName,
      tooltip: _ctx.$t('project.manager.title'),
      "is-clickable": true,
      onClicked: _ctx.showDialog
    }, null, 8, ["text", "tooltip", "onClicked"]),
    _createVNode(_component_PopupDialog, {
      "is-visible": _ctx.dialog.visible,
      header: _ctx.$t('project.manager.changeAction'),
      icon: 'user-tie',
      progress: _ctx.dialog,
      "show-cancel-button": true,
      "show-action-button": true,
      "action-icon": 'repeat',
      "is-action-disabled": _ctx.selectedManager?.uid == _ctx.project.manager,
      "action-text": _ctx.$t('project.manager.changeAction'),
      "is-warning": false,
      onCloseModal: _cache[1] || (_cache[1] = ($event: any) => (_ctx.dialog.visible = false)),
      onAction: _ctx.updateManager
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", null, [
            _createVNode(_component_InputText, {
              modelValue: _ctx.searchText,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchText) = $event)),
              "unique-id": "managerSearchText",
              placeholder: _ctx.$t('common.searchEmployee')
            }, null, 8, ["modelValue", "placeholder"])
          ]),
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredEmployees, (employee, i) => {
              return (_openBlock(), _createBlock(_component_SimpleListItem, {
                key: employee.uid,
                text: employee.name,
                "sub-text": employee.email,
                "is-selected": employee.uid === _ctx.selectedManager?.uid,
                "is-first": i === 0,
                "is-last": i === _ctx.employees.length - 1,
                onClicked: ($event: any) => (_ctx.selectManager(employee.uid))
              }, null, 8, ["text", "sub-text", "is-selected", "is-first", "is-last", "onClicked"]))
            }), 128))
          ])
        ])
      ]),
      _: 1
    }, 8, ["is-visible", "header", "progress", "is-action-disabled", "action-text", "onAction"])
  ]))
}