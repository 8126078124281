<template>
    <div class="rskv3-rsi">

        <div class="rskv3-rsi-left" @click="onSubClick">
            {{ '-' }}
        </div>

        <div class="rskv3-rsi-value">
            {{ value.toFixed(decimals) }}
        </div>

        <div class="rskv3-rsi-right" @click="onAddClick">
            {{ '+' }}
        </div>

    </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
    name: "RiskSettingsIncrementor",
    emits: ["change"],
    props: {
        value: { type: Number as PropType<number>, required: true },
        decimals: { type: Number as PropType<number>, default: 0 },
    },
    methods: {

        onSubClick(event: MouseEvent) {
            if (event.ctrlKey) {
                this.$emit("change", -0.1);
            } else if (event.shiftKey) {
                this.$emit("change", -10);
            } else {
                this.$emit("change", -1);
            }
        },

        onAddClick(event: MouseEvent) {
            if (event.ctrlKey) {
                this.$emit("change", 0.1);
            } else if (event.shiftKey) {
                this.$emit("change", 10);
            } else {
                this.$emit("change", 1);
            }
        },

    },
});
</script>
<style>
.rskv3-rsi {
    display: flex;
}

.rskv3-rsi-left,
.rskv3-rsi-right {
    width: 34px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    border: solid 1px lightgray;
    background-color: whitesmoke;
    cursor: pointer;
}

.rskv3-rsi-left:hover,
.rskv3-rsi-right:hover {
    background-color: lightgray;
}

.rskv3-rsi-left {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.rskv3-rsi-right {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.rskv3-rsi-value {
    width: 50px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    border-top: solid 1px lightgray;
    border-bottom: solid 1px lightgray;
    background-color: whitesmoke;
}
</style>
