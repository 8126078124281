import { ApiResponse, parseLocalError, parseRemoteError } from "../error_parser";
import { Verified } from "../verified_plugin";

export const getWorkflowProjectCount = async (flowKey: string): Promise<
    { count: number | null, responseInfo: ApiResponse }
> => {
    try {
        const response = await fetch(`/api/aml/v1/workflows/configs/${flowKey}/projectcount`, {
            method: "GET",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
        });
        const body = await response.json();
        if (!response.ok) {
            return {
                count: null,
                responseInfo: { isError: true, parsedError: parseRemoteError(body) },
            };
        }
        return {
            count: body.projectCount as number,
            responseInfo: { isError: false, parsedError: null },
        };
    } catch (error) {
        return {
            count: null,
            responseInfo: { isError: true, parsedError: parseLocalError(error) },
        };
    }
};
