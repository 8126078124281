import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-56ae91a5"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "amlv3-nav-routes"
}
const _hoisted_2 = {
  key: 0,
  class: "amlv3-nav-route-bullet"
}
const _hoisted_3 = { class: "amlv3-nav-route-text" }
const _hoisted_4 = { class: "amlv3-nav-route-arrow" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  key: 0,
  class: "amlv3-nav-route-bullet"
}
const _hoisted_7 = { class: "amlv3-nav-route-text" }
const _hoisted_8 = { class: "amlv3-nav-route-arrow" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = {
  key: 0,
  class: "amlv3-nav-route-bullet"
}
const _hoisted_11 = { class: "amlv3-nav-route-text" }
const _hoisted_12 = { class: "amlv3-nav-route-arrow" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontIcon = _resolveComponent("FontIcon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (!_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.routes, (level0, i) => {
          return (_openBlock(), _createElementBlock("div", {
            key: 'nav' + i
          }, [
            _createVNode(_component_router_link, {
              to: level0.path,
              class: _normalizeClass(["amlv3-nav-route", [
                    {'amlv3-nav-route-active': _ctx.isRouteActive(level0) },
                    {'amlv3-nav-route-expanded': level0.expanded },
                ]])
            }, {
              default: _withCtx(() => [
                (_ctx.isRouteActive(level0))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString('•')))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t(level0.text)), 1),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_FontIcon, {
                    icon: level0.expanded ? 'chevron-down' : 'chevron-right',
                    size: 14
                  }, null, 8, ["icon"])
                ])
              ]),
              _: 2
            }, 1032, ["to", "class"]),
            (level0.expanded)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(level0.children, (level1, j) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: 'nav' + i + j,
                      class: "amlv3-nav-route-level1"
                    }, [
                      _createVNode(_component_router_link, {
                        to: level1.path,
                        class: _normalizeClass(["amlv3-nav-route", [
                            {'amlv3-nav-route-active': _ctx.isRouteActive(level1) },
                            {'amlv3-nav-route-expanded': level1.expanded },
                        ]])
                      }, {
                        default: _withCtx(() => [
                          (_ctx.isRouteActive(level1))
                            ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString('•')))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t(level1.text)), 1),
                          _createElementVNode("div", _hoisted_8, [
                            _createVNode(_component_FontIcon, {
                              icon: level1.expanded ? 'chevron-down' : 'chevron-right',
                              size: 14
                            }, null, 8, ["icon"])
                          ])
                        ]),
                        _: 2
                      }, 1032, ["to", "class"]),
                      (level1.expanded)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(level1.children, (level2, k) => {
                              return (_openBlock(), _createElementBlock("div", {
                                key: 'nav' + i + j + k,
                                class: "amlv3-nav-route-level2"
                              }, [
                                _createVNode(_component_router_link, {
                                  to: level2.path,
                                  class: _normalizeClass(["amlv3-nav-route", [
                                    {'amlv3-nav-route-active': _ctx.isRouteActive(level2) },
                                    {'amlv3-nav-route-expanded': level2.expanded },
                                ]])
                                }, {
                                  default: _withCtx(() => [
                                    (_ctx.isRouteActive(level2))
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString('•')))
                                      : _createCommentVNode("", true),
                                    _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t(level2.text)), 1),
                                    _createElementVNode("div", _hoisted_12, [
                                      _createVNode(_component_FontIcon, {
                                        icon: level2.expanded ? 'chevron-down' : 'chevron-right',
                                        size: 14
                                      }, null, 8, ["icon"])
                                    ])
                                  ]),
                                  _: 2
                                }, 1032, ["to", "class"])
                              ]))
                            }), 128))
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}