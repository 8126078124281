<template>
    <Doughnut ref="my-chart-id" :options="chartOptions" :data="chartData" />
</template>

<script lang="ts">
import { Doughnut } from "vue-chartjs";

import { ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from "chart.js";

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement);

export default {
    name: "HalfDoughnutChart",
    components: { Doughnut },
    props: {
        chartData: {
            type: Object,
            required: true,
        },
        chartOptions: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {};
    },
    computed: {
        myStyles() {
            return {
                height: `300px`,
                position: "relative",
            };
        },
    },
};
</script>
