import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RiskNestedList = _resolveComponent("RiskNestedList")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_RiskNestedList, {
      items: _ctx.nestedData.items,
      options: _ctx.nestedData.options,
      "selected-code": _ctx.value,
      "selectable-level": _ctx.selectableLevel,
      onItemSelected: _ctx.onItemSelected
    }, null, 8, ["items", "options", "selected-code", "selectable-level", "onItemSelected"])
  ]))
}