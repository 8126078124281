<template>
    <div class="text-xs space-y-2">
        <div class="w-full h-[1px] bg-light"></div>
        <div class="flex gap-2">
            <Icon icon="id-control-card" class="w-4 h-3 self-start"></Icon>
            <div class="space-y-1">
                <p class="font-medium">ID Control Method</p>
                <p>BankID-SE</p>
                <div class="flex items-center gap-2">
                    <Icon icon="user-check" class="w-3 h-3"></Icon>
                    <span>Self-Declaration From</span>
                </div>
            </div>
        </div>
        <div class="w-full h-[1px] bg-light"></div>
        <div class="flex gap-2">
            <Icon icon="calender" class="w-4 h-3 self-start"></Icon>
            <div class="space-y-1">
                <p class="font-medium">ID Control Details</p>
                <p>{{ "09 June 2024 15:15" }}</p>
            </div>
        </div>
        <div class="w-full h-[1px] bg-light"></div>
        <div class="flex gap-2">
            <Icon icon="person-blue" class="w-3 h-3"></Icon>
            <div class="">
                <div>
                    <span class="font-medium">Name:</span>
                    <span>Lebron James</span>
                </div>
                <div>
                    <span class="font-medium">DOB:</span>
                    <span>21.02.1981</span>
                </div>
                <div>
                    <span class="font-medium">SSN:</span>
                    <span>1234567890</span>
                </div>
                <div>
                    <span class="font-medium">Email:</span>
                    <span>lb@cool.com</span>
                </div>
            </div>
        </div>
        <div class="w-full h-[1px] bg-light"></div>
        <div class="flex gap-2">
            <Icon icon="report" class="w-3 h-4 self-start"></Icon>
            <div>
                <p class="font-medium">ID Control Report</p>
                <a href="" class="underline">View Report</a>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import Icon from "@/components/Icon.vue";
import { defineComponent } from "vue";

export default defineComponent({
    name: "ScreeningInfo",
    components: {
        Icon,
    },
    props: {
        screeningData: {
            type: Object,
            default: () => ({}),
        },
    },
});
</script>
