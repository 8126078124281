import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-548053e6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "spinner" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("button", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clicked && _ctx.clicked(...args))),
    class: _normalizeClass({
            ..._ctx.color,
            clickable: !_ctx.disabled && !_ctx.loading,
            loading: _ctx.loading,
            disabled: _ctx.disabled,
        })
  }, [
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Icon, {
        class: "w-6 h-6 opacity-70",
        icon: "tabler:loader-2"
      })
    ], 512), [
      [_vShow, _ctx.loading]
    ]),
    _createElementVNode("span", {
      class: _normalizeClass({
                'opacity-0': _ctx.loading,
            })
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 2)
  ], 2))
}