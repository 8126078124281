<template>
    <button
        class="fbl-button"
        :class="[
            { 'fbl-button-outline': outline || isWorking },
            { 'fbl-button-disabled': disabled },
            { 'fbl-button-warning': isWarning },
            { 'fbl-button-working': isWorking },
            { 'fbl-button-rounded': isRounded },
            { 'fbl-button-link': isLink },
        ]"
        :style="minWidth !== 0 ? `min-width: ${minWidth}px;` : ''"
        @click="handleClick()"
    >

        <div class="fbl-button-left" v-if="iconLeft || (!iconRight && isWorking)">
            <FontIcon
                :icon="iconLeft"
                :size="15"
                v-if="iconLeft && !isWorking"
                style="margin-top: 5px;"
            />
            <SpinnerVerified
                :size="18"
                v-if="isWorking"
                style="margin-top: 4px;"
            />
        </div>

        <div class="fbl-button-text" v-if="text">
            {{ text }}
        </div>

        <div class="fbl-button-right" v-if="iconRight">
            <FontIcon
                :icon="iconRight"
                :size="15"
                v-if="iconRight && !isWorking"
                style="margin-top: 5px;"
            />
            <SpinnerVerified
                :size="18"
                v-if="isWorking"
                style="margin-top: 4px;"
            />
        </div>

    </button>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";

import FontIcon from "@/ui/FontIcon.vue";
import SpinnerVerified from "./SpinnerVerified.vue";

export default defineComponent({
    name: "ButtonSingle",
    components: {
        FontIcon,
        SpinnerVerified,
    },
    emits: ["clicked"],
    props: {
        text: { type: String as PropType<string>, required: true },
        outline: { type: Boolean as PropType<boolean>, default: false },
        disabled: { type: Boolean as PropType<boolean>, default: false },
        isWarning: { type: Boolean as PropType<boolean>, default: false },
        isWorking: { type: Boolean as PropType<boolean>, default: false },
        iconLeft: { type: String as PropType<string>, default: "" },
        iconRight: { type: String as PropType<string>, default: "" },
        isRounded: { type: Boolean as PropType<boolean>, default: false },
        isLink: { type: Boolean as PropType<boolean>, default: false },
        minWidth: { type: Number as PropType<number>, default: 0 },
    },
    methods: {

        handleClick() {
            if (this.disabled || this.isWorking) return;
            this.$emit("clicked");
        },

    },
});
</script>
<style scoped lang="scss">
.fbl-button {
    border: none;
    padding: 0px 20px;
    display: flex;
    height: 40px;
    color: white;
    border-radius: 6px;
    border: solid 1px theme('colors.primary.default');
    background-color: theme('colors.primary.default');
    box-sizing: border-box;
    cursor: pointer;
}

.fbl-button:hover {
    opacity: 0.8;
}

.fbl-button-outline {
    color: theme('colors.primary.default');
    background-color: transparent;
}

.fbl-button-left {
    width: 24px;
    margin-right: 10px;
    margin-left: -8px;
    padding-top: 4px;
}

.fbl-button-text {
    flex-grow: 1;
    padding: 9px 0px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
}

.fbl-button-right {
    width: 24px;
    margin-left: 10px;
    margin-right: -8px;
    padding-top: 4px;
}

.fbl-button-rounded {
    border-radius: 20px;
}

.fbl-button-warning {
    color: white;
    border-color: #B62537;
    background-color: #B62537;
}

/* Link */

.fbl-button-link {
    height: 16px;
    padding: 0px;
    border: none;
    color: theme('colors.primary.default');
    background-color: transparent;
}

.fbl-button-link .fbl-button-text {
    padding: 0px;
    font-size: 15px;
}

/* Disabled */

.fbl-button-disabled {
    color: gray;
    border-color: lightgray;
    background-color: lightgray;
    cursor: initial;
}

.fbl-button-disabled:hover {
    opacity: 1;
}

.fbl-button-disabled.fbl-button-link {
    border: none;
    color: lightgray;
    background-color: transparent;
}

</style>
