import { FlowAction } from "@/types/flow_types";
import { Form } from "./forms";

export const getFormFromAction = (forms: Form[], action: FlowAction): Form | null => {
    const formKey = action.params.formKey || "";
    for (const form of forms) {
        if (form.templateKey === formKey && form.isSelected) {
            return form;
        }
    }
    return null;
};

export const getFormMainPdfFileId = (form: Form): string => {
    console.log("form", form);

    return "";
};
