<template>
    <div style="position: relative;">

        <div
            :id="'approval-' + approval.id"
            class="amlv3-approvalitem-top"
            :class="[
                {'amlv3-approvalitem-top-collapsed': !expanded },
            ]"
            @click="expanded = !expanded"
        >

            <div class="amlv3-approvalitem-active-icon" v-if="approval.selected">
                <FontIcon :icon="'check-double'" :size="18" />
            </div>

            <div class="amlv3-approvalitem-top-left">
                <div class="amlv3-approvalitem-name">
                    {{ getNameText() }}
                </div>
                <StatusAndText
                    :text="getStatusText()"
                    :is-completed="approval.status === ApprovalStatus.Approved"
                    :is-warning="approval.status === ApprovalStatus.Aborted"
                    :is-error="approval.status === ApprovalStatus.Denied"
                />
            </div>

            <div
                v-if="!sealed"
                class="amlv3-approvalitem-top-menu-btn"
                @click.stop="menuOpen = !menuOpen"
            >
                <FontIcon :icon="'ellipsis'" style="margin-top: -4px;" />
                <div
                    v-if="menuOpen"
                    class="amlv3-approvalitem-menu"
                    v-click-away="hideMenu"
                >
                    <div
                        v-if="approval.status === ApprovalStatus.Open"
                        class="amlv3-approvalitem-menu-item"
                        @click="abortDialog.visible = true"
                    >
                        {{ $t('assessment.approval.abortAction') }}
                    </div>
                    <div
                        v-if="approval.status !== ApprovalStatus.Open"
                        class="amlv3-approvalitem-menu-item"
                        @click="deleteDialog.visible = true"
                    >
                        {{ $t('assessment.approval.deleteAction') }}
                    </div>
                </div>
            </div>

        </div>

        <div class="amlv3-approvalitem-content" v-if="expanded">

            <!-- <div class="amlv3-approvalitem-info">
                {{ 'Date: ' + new Date(approval.createdAt).toLocaleDateString() }}
            </div> -->

            <div class="amlv3-approvalitem-actors-header">
                {{ $t('assessment.approval.approvers.label') }}
            </div>

            <div class="amlv3-approvalitem-actors">
                <div
                    v-for="(approver, i) in approval.approvers"
                    :key="'fae' + i"
                    class="amlv3-approvalitem-actor"
                    :class="[
                        {'amlv3-approvalitem-actor-first': i === 0 },
                        {'amlv3-approvalitem-actor-last': i === approval.approvers.length - 1 },
                    ]"
                >

                    <div class="amlv3-approvalitem-actor-name">
                        {{ approver.givenName + ' ' + approver.familyName }}
                    </div>

                    <div class="amlv3-approvalitem-actor-status">
                        <div
                            class="amlv3-approvalitem-actor-status-icon"
                            :class="[
                                {'amlv3-approvalitem-actor-status-icon-completed': approver.decision === ApprovalStatus.Approved },
                                {'amlv3-approvalitem-actor-status-icon-error': approver.decision === ApprovalStatus.Denied },
                            ]"
                        >
                            <FontIcon
                                v-if="approver.decision === ApprovalStatus.Open"
                                :icon="'hourglass-half'"
                                :size="14"
                            />
                            <FontIcon
                                v-if="approver.decision === ApprovalStatus.Approved"
                                :icon="'check'"
                                :size="14"
                            />
                            <FontIcon
                                v-if="approver.decision === ApprovalStatus.Denied"
                                :icon="'triangle-exclamation'"
                                :size="14"
                            />
                        </div>
                        <div class="amlv3-approvalitem-actor-status-text">
                            {{ getApproverStatusText(approver) }}
                        </div>
                    </div>

                    <div class="amlv3-approvalitem-actor-role">
                        {{ getApproverDateText(approver) }}
                    </div>

                    <div class="amlv3-approvalitem-actor-actions">
                        <div
                            v-if="approver.uid === userUid && approver.decision === ApprovalStatus.Open"
                            class="amlv3-approvalitem-actor-action"
                            @click="showDecisionDialog()"
                        >
                            <FontIcon :icon="'file-import'" :size="14" />
                        </div>
                        <div
                            v-if="approver.decision === ApprovalStatus.Open"
                            class="amlv3-approvalitem-actor-action"
                            @click="showReminderDialog(approver)"
                        >
                            <FontIcon :icon="'bell'" :size="14" />
                        </div>
                        <div class="amlv3-approvalitem-actor-action" @click="showInfoDialog(approver)">
                            <FontIcon :icon="'circle-info'" :size="14" />
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div
            v-if="initiallyOpen"
            class="amlv3-approvalitem-highlight"
            :style="`opacity: ${highlightOpacity ? '1' : '0'};`"
        >
        </div>

        <ApproverInfoDialog
            v-if="infoDialog.visible"
            :approver="infoDialog.approver"
            @close-modal="infoDialog.visible = false"
        />

        <!-- Abort Dialog -->
        <PopupDialog
            :is-visible="abortDialog.visible"
            :header="$t('assessment.approval.abortAction')"
            :icon="'xmark'"
            :show-action-button="true"
            :show-cancel-button="true"
            :action-text="$t('assessment.approval.abortAction')"
            :action-icon="'xmark'"
            :is-warning="true"
            :progress="abortDialog"
            @close-modal="abortDialog.visible = false"
            @action="onAbort"
        >
            <PopupDialogPrompt
                :prompt-text="$t('assessment.approval.abortAction')"
                :info-text="$t('common.actions.notReversableHint')"
            />
        </PopupDialog>

        <!-- Delete Dialog -->
        <PopupDialog
            :is-visible="deleteDialog.visible"
            :header="$t('assessment.approval.deleteAction')"
            :icon="'trash-can'"
            :show-action-button="true"
            :show-cancel-button="true"
            :action-text="$t('assessment.approval.deleteAction')"
            :action-icon="'trash-can'"
            :is-warning="true"
            :progress="deleteDialog"
            @close-modal="deleteDialog.visible = false"
            @action="onDelete"
        >
            <PopupDialogPrompt
                :prompt-text="$t('assessment.approval.deleteAction')"
                :info-text="$t('common.actions.notReversableHint')"
            />
        </PopupDialog>

    </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import { mixin as VueClickAway } from "vue3-click-away";

import FontIcon from "@/ui/FontIcon.vue";
import { Approval, ApprovalStatus, Approver } from "@/lib/assessments/approval_model";
import PopupDialog from "@/ui/PopupDialog.vue";
import PopupDialogPrompt from "@/ui/dialog/PopupDialogPrompt.vue";
import { getProjectProcess } from "@/lib/projects/get_project";
import { abortApproval, deleteApproval } from "@/lib/assessments/add_approval";
import ApproverInfoDialog from "./ApproverInfoDialog.vue";
import StatusAndText from "@/ui/StatusAndText.vue";

export default defineComponent({
    name: "ApprovalListItem",
    mixins: [VueClickAway],
    components: {
        FontIcon,
        StatusAndText,
        PopupDialog,
        PopupDialogPrompt,
        ApproverInfoDialog,
    },
    emits: ["request-project-refresh", "decide"],
    props: {
        approval: { type: Object as PropType<Approval>, required: true },
        projectId: { type: String as PropType<string>, required: true },
        assessmentId: { type: String as PropType<string>, required: true },
        sealed: { type: Boolean as PropType<boolean>, required: true },
        userUid: { type: String as PropType<string>, required: true },
        initiallyOpen: { type: Boolean as PropType<boolean>, default: false },
    },
    data() {
        return {
            ApprovalStatus,

            expanded: false,
            menuOpen: false,
            highlightOpacity: false,

            infoDialog: { visible: false, approver: {} as Approver },

            abortDialog: { visible: false, isWorking: false, statusText: "", isError: false, errorText: "" },
            deleteDialog: { visible: false, isWorking: false, statusText: "", isError: false, errorText: "" },
        };
    },
    methods: {

        hideMenu() {
            this.menuOpen = false;
        },

        getNameText(): string {
            let name = this.approval.key;
            const configs = this.$config.settings.approvalConfigs || [];
            for (const config of configs) {
                if (config.key === this.approval.key) name = this.$ct(config.name);
            }

            return name + " - " + new Date(this.approval.createdAt).toLocaleDateString();
        },

        getStatusText(): string {
            switch (this.approval.status) {
            case ApprovalStatus.Approved: return this.$t("assessment.approval.decision.approved");
            case ApprovalStatus.Denied: return this.$t("assessment.approval.decision.denied");
            case ApprovalStatus.Aborted: return this.$t("assessment.approval.decision.aborted");
            }
            return this.$t("assessment.approval.pendingApproval");
        },

        getApproverStatusText(approver: Approver): string {
            switch (approver.decision) {
            case ApprovalStatus.Approved: return this.$t("assessment.approval.decision.approved");
            case ApprovalStatus.Denied: return this.$t("assessment.approval.decision.denied");
            }
            return this.$t("assessment.approval.pendingApproval");
        },

        getApproverDateText(approver: Approver): string {
            if (!approver.decidedAt) return "";
            return new Date(approver.decidedAt).toLocaleDateString();
        },

        showDecisionDialog() {
            this.$emit("decide", this.approval.id);
        },

        showReminderDialog(approver: Approver) {
            console.error("NOT IMPLEMENTED");
        },

        showInfoDialog(approver: Approver) {
            this.infoDialog.approver = approver;
            this.infoDialog.visible = true;
        },

        async onAbort() {
            this.abortDialog.isError = false;
            this.abortDialog.isWorking = true;
            this.abortDialog.statusText = "Aborting approval process";

            const response = await abortApproval(
                this.projectId,
                this.assessmentId,
                this.approval.id,
            );
            if (response.responseInfo.isError) {
                this.abortDialog.isError = true;
                this.abortDialog.errorText = response.responseInfo.parsedError?.message || "Unknown Error";
            } else {
                const projectResponse = await getProjectProcess(this.projectId);
                this.$emit("request-project-refresh", projectResponse);
                this.abortDialog.visible = false;
            }
            this.abortDialog.isWorking = false;
        },

        async onDelete() {
            this.deleteDialog.isError = false;
            this.deleteDialog.isWorking = true;
            this.deleteDialog.statusText = "Deleting approval";

            const response = await deleteApproval(
                this.projectId,
                this.assessmentId,
                this.approval.id,
            );
            if (response.responseInfo.isError) {
                this.deleteDialog.isError = true;
                this.deleteDialog.errorText = response.responseInfo.parsedError?.message || "Unknown Error";
            } else {
                const projectResponse = await getProjectProcess(this.projectId);
                this.$emit("request-project-refresh", projectResponse);
                this.deleteDialog.visible = false;
            }
            this.deleteDialog.isWorking = false;
        },

        scrollToItem() {
            this.$nextTick(() => {
                this.highlightOpacity = true;
                const id = "approval-" + this.approval.id;
                const element = document.getElementById(id);
                if (element) element.scrollIntoView({ behavior: "smooth", block: "end" });
            });
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const self = this;
            setTimeout(function() {
                self.highlightOpacity = false;
            }, 1000);
        },

    },
    mounted() {
        if (this.initiallyOpen) {
            this.expanded = true;
            this.scrollToItem();
        }
    },
});
</script>
<style scoped>
.amlv3-approvalitem-highlight {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    border-radius: 4px;
    pointer-events: none;
    -webkit-box-shadow: inset 0px 0px 9px 0px rgba(63,167,242,1);
    -moz-box-shadow: inset 0px 0px 9px 0px rgba(63,167,242,1);
    box-shadow: inset 0px 0px 9px 0px rgba(63,167,242,1);
    opacity: 0;
    transition: opacity 1.0s;
}

.amlv3-approvalitem-top {
    display: flex;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border: solid 1px lightgray;
    background-color: white;
    cursor: pointer;
}

.amlv3-approvalitem-top:hover {
    background-color: whitesmoke;
}

.amlv3-approvalitem-top-collapsed {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.amlv3-approvalitem-active-icon {
    margin-top: 13px;
    margin-left: 15px;
    color: #218BCB;
}

.amlv3-approvalitem-top-left {
    flex-grow: 1;
    padding: 5px 15px;
}

.amlv3-approvalitem-name {
    font-size: 15px;
    color: #444444;
}

.amlv3-approvalitem-top-menu-btn {
    position: relative;
    flex-shrink: 0;
    width: 50px;
    height: 20px;
    margin-top: 14px;
    margin-right: 15px;
    text-align: center;
    border-radius: 4px;
    border: solid 1px lightgray;
    background-color: white;
    cursor: pointer;
}

.amlv3-approvalitem-top-menu-btn:hover {
    background-color: whitesmoke;
}

.amlv3-approvalitem-menu {
    position: absolute;
    top: 20px;
    right: 0px;
    width: 300px;
    border-top: solid 1px lightgray;
}

.amlv3-approvalitem-menu-item {
    padding: 3px 10px;
    text-align: left;
    border: solid 1px lightgray;
    border-top: none;
    background-color: white;
    cursor: pointer;
}
.amlv3-approvalitem-menu-item:hover {
    background-color: whitesmoke;
}

/* Content */

.amlv3-approvalitem-content {
    padding: 15px;
    padding-top: 10px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border: solid 1px lightgray;
    border-top: none;
    background-color: white;
}

.amlv3-approvalitem-info {
    font-size: 14px;
    color: dimgray;
}

.amlv3-approvalitem-actors-header {
    margin-top: 5px;
    padding-left: 5px;
    font-size: 13px;
    color: dimgray;
}

.amlv3-approvalitem-actors {
    margin-top: 2px;
}

.amlv3-approvalitem-actor {
    display: flex;
    font-size: 14px;
    border: solid 1px lightgray;
    border-top: none;
}

.amlv3-approvalitem-actor-first {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-top: solid 1px lightgray;
}
.amlv3-approvalitem-actor-last {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.amlv3-approvalitem-actor-name {
    flex-shrink: 0;
    width: 140px;
    padding: 2px 5px;
}

.amlv3-approvalitem-actor-status {
    flex-shrink: 0;
    width: 100px;
    display: flex;
    padding-top: 2px;
}

.amlv3-approvalitem-actor-status-icon {
    color: dimgray;
}
.amlv3-approvalitem-actor-status-icon-completed {
    color: #218BCB;
}
.amlv3-approvalitem-actor-status-icon-error {
    color: #B62537;
}

.amlv3-approvalitem-actor-status-text {
    margin-left: 4px;
}

.amlv3-approvalitem-actor-role {
    flex-grow: 1;
    padding: 2px 5px;
}

.amlv3-approvalitem-actor-actions {
    flex-shrink: 0;
    display: flex;
}

.amlv3-approvalitem-actor-action {
    width: 22px;
    padding-top: 3px;
    text-align: center;
    border-left: solid 1px lightgray;
    cursor: pointer;
}

.amlv3-approvalitem-actor-action:hover {
    background-color: whitesmoke;
}

</style>
