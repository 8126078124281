import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RiskSourceItem = _resolveComponent("RiskSourceItem")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
      return (_openBlock(), _createBlock(_component_RiskSourceItem, {
        key: 'rsi' + i,
        item: item,
        "is-selected": item.key === _ctx.selectedKey,
        "is-first": i === 0,
        "is-last": i === _ctx.items.length - 1,
        "value-type": _ctx.valueType,
        onClicked: ($event: any) => (_ctx.selectItem(item.key))
      }, null, 8, ["item", "is-selected", "is-first", "is-last", "value-type", "onClicked"]))
    }), 128))
  ]))
}