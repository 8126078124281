
export type AsyncProgress = {
    isWorking: boolean;
    statusText?: string;
    isError?: boolean;
    errorText?: string;
};

export type TabControlTab = {
    key: string;
    text?: string;
    rawText?: string;
};

export enum ButtonAlignment {
    Left = "overview",
    Center = "company",
    Right = "persons",
}
