<template>
    <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 py-6">
        <!--Header-->
        <div class="flex justify-between mb-5">
            <label class="text-3xl">{{ $t('common.person_info.dateOfBirth') }}</label>
            <Icon
                class="h-10 w-10 hover:bg-background p-3 cursor-pointer rounded-full "
                icon="cross"
                @click="$emit('closed')"
                aria-hidden="true"
            />
        </div>
        <!--Body-->
        <div class="flex flex-col gap-y-5">
            <ul v-if="dateOfBirthList && dateOfBirthList.length > 0" class="p-0 space-y-3">
                <li
                    v-for="(dob, index) in dateOfBirthList"
                    :key="index"
                    class="flex gap-x-2 items-center min-h-[6rem] border border-light border-solid p-4 rounded-lg hover:bg-primary-hover-blue"
                >
                    <div>
                        <input
                            name="dateOfBirth"
                            type="radio"
                            :value="dob.value"
                            v-model="person.data.person_info.dateOfBirth"
                            class="h-6 w-6 border-light"
                            @input="onChange(dob.value)"
                        />
                    </div>
                    <div>
                        <div>
                            <label>{{ formatDateInput(dob.value) }}</label>
                            <div
                                v-for="src in dob.sources"
                                class="text-sm text-light-gray flex gap-2"
                                :key="src.source"
                            >
                                <Icon class="w-4 h-4" :icon="`aml:src-${src.source.toLowerCase()}`" />
                                <Icon icon="dot-single" aria-hidden="true" />
                                <span>{{ formatDateInput(src.updatedAt) }}</span>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
            <!--Start Add new DOB-->
            <div v-if="addDOB" class="space-y-3 bg-background rounded-lg p-4">
                <div class="w-full relative">
                    <label class="block">{{ addOrUpdateText }}</label>
                    <div class="absolute right-1 top-0">
                        <Icon
                            icon="cross"
                            class=" w-3 h-3 cursor-pointer"
                            @click="addDOB = false"
                            aria-hidden="true"
                        />
                    </div>
                </div>
                <Icon
                    icon="horizontal-line"
                    class=""
                    aria-hidden="true"
                />
                <div class="space-y-2">
                    <InputLabel
                        unique-id="person-email"
                        :text="$t('common.person_info.dateOfBirth')"
                        class="block"
                    />
                    <DatePicker v-model="newDOB" />
                </div>
                <CustomButton
                    :text="addOrUpdateText"
                    class="border border-primary-default font-medium text-base box-border ml-auto px-2"
                    text-color="primary-default"
                    round-style="3xl"
                    bg-color="white"
                    @click="addNewDOB"
                />
            </div>
            <!--End Add new DOB-->
            <div v-if="!addDOB">
                <a class="text-primary-default" @click="addDOB = true">{{ addOrUpdateText }}</a>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import CustomButton from "@/components/CustomButton.vue";
import DatePicker from "@/components/DatePicker.vue";
import Icon from "@/components/Icon.vue";
import { valueWithsources } from "@/lib/common/common";
import { formatDateObject } from "@/lib/date_object_formatter";
import { DateObject, Person } from "@/lib/persons/person";
import { updatePerson, updatePersonInfo } from "@/lib/persons/update_person";
import InputLabel from "@/ui/inputs/InputLabel.vue";
import InputText from "@/ui/inputs/InputText.vue";
import { defineComponent, PropType } from "vue";

export default defineComponent({
    name: "EditDOB",
    components: {
        Icon,
        CustomButton,
        InputText,
        InputLabel,
        DatePicker,
    },
    props: {
        person: {
            type: Object as PropType<Person>,
            required: true,
        },
    },
    data() {
        return {
            addDOB: false,
            newDOB: "",
        };
    },
    emits: ["closed", "refresh"],
    computed: {
        dateOfBirthList(): any[] | null {
            const personInfoDataBlocks = this.person.blocks
                .filter((b: any) => b._block === "person_info")
                ?.filter((b: any) => b.dateOfBirth);
            if (personInfoDataBlocks && personInfoDataBlocks.length > 0) {
                const dobs = personInfoDataBlocks.reduce((acc, block) => {
                    const existingDOB = acc.find((dob: any) => JSON.stringify(dob.value) === JSON.stringify(block.dateOfBirth));
                    if (existingDOB) {
                        existingDOB.sources.push({ source: block._source, updatedAt: block._updatedAt });
                    } else {
                        acc.push({
                            value: block.dateOfBirth ?? {},
                            sources: [{ source: block._source, updatedAt: block._updatedAt }],
                        });
                    }
                    return acc;
                }, [] as valueWithsources[]);
                dobs.sort((a: any, b: any) => {
                    // Prioritize the name matching person.data.person_info.givenName
                    if (JSON.stringify(a.value) === JSON.stringify(this.person.data.person_info.dateOfBirth)) {
                        return -1; // a comes before b
                    } else if (JSON.stringify(b.value) === JSON.stringify(this.person.data.person_info.dateOfBirth)) {
                        return 1; // b comes before a
                    }

                    // Get the latest 'updatedAt' value for each name
                    const latestUpdateA = Math.max(...a.sources.map((source: any) => new Date(source.updatedAt)));
                    const latestUpdateB = Math.max(...b.sources.map((source: any) => new Date(source.updatedAt)));

                    // Compare the latest update dates
                    return latestUpdateB - latestUpdateA;
                });
                console.log("dobs", dobs);
                return dobs;
            }

            return null;
        },
        addOrUpdateText(): string {
            return (
                (this.dateOfBirthList && this.dateOfBirthList?.length > 0
                    ? this.$t("common.actions.update")
                    : this.$t("common.actions.add")) +
                " " +
                this.$t("common.person_info.dateOfBirth")
            );
        },
    },
    methods: {
        async addNewDOB() {
            const dob = new Date(this.newDOB);
            await updatePerson(this.person.id, {
                dateOfBirth: { day: dob.getDate(), month: dob.getMonth() + 1, year: dob.getFullYear() },
            });
            this.$emit("refresh");
            this.addDOB = false;
            this.newDOB = "";
        },
        formatDateInput (dateInput: any) {
            return formatDateObject(dateInput);
        },
        async onChange(dob: DateObject) {
            await updatePersonInfo(this.person.id, {
                dateOfBirth: dob,
            });
            this.$emit("refresh");
        },
    },
    created() {

    },
});
</script>
