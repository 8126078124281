import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "group/name flex relative" }
const _hoisted_2 = {
  key: 0,
  class: "group-hover/name:opacity-100 transition-opacity bg-body p-1 text-sm text-white rounded-md absolute opacity-0 m-4 mx-auto",
  style: {"inset":"auto auto 0px 0px","margin":"0px","transform":"translate3d(0px, -25px, 0px)"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      ref: "overflowText",
      class: "text-xl font-semibold group-hover:text-primary-default truncate",
      style: _normalizeStyle({ 'max-width': `${_ctx.maxLength}ch` })
    }, _toDisplayString(_ctx.text), 5),
    (_ctx.overflow)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.text), 1))
      : _createCommentVNode("", true)
  ]))
}