<template>
    <div class="amlv3-otherprojects-link-info">
        <div class="text-gray-500 text-xs">
            {{ info1 }}
        </div>
        <div class="amlv3-otherprojects-link-infodot"></div>
        <div class="text-gray-500 text-xs">
            {{ info2 }}
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";

export default defineComponent({
    name: "MenuInfoLine",
    emits: ["tab"],
    props: {
        info1: { type: String as PropType<string>, default: "" },
        info2: { type: String as PropType<string>, default: "" },
    },
});
</script>
<style scoped>
.amlv3-otherprojects-link-info {
    display: flex;
    margin-top: -2px;
}

.amlv3-otherprojects-link-infodot {
    margin: 0px 8px;
    margin-top: 7px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: lightgray;
}
</style>
