import { WebappServices } from "./lib/services";

interface Store {
    services: WebappServices | undefined;

    accessToken: () => string;
    companyId: () => string;
}

const store: Store = {
    services: undefined,

    accessToken() {
        return this.services?.IdentityService.getInstance().getRawToken() || "";
    },
    companyId() {
        let companyId = this.services?.CompanyService.getInstance().getCurrent()?.id;
        if (!companyId) {
            const qs = location.href.split("?");
            const urlParams = new URLSearchParams(qs[qs.length - 1]);
            companyId = urlParams.get("companyId") || "";
        }
        return companyId || "";
    },
};

export default store;
