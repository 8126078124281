<template>
    <div class="riskv3-mc">

        <div class="riskv3-mc-lrm">

            <div class="riskv3-mc-btn riskv3-mc-btn-first riskv3-mc-nrb" @click="onChangeLRM(1)">
                <FontIcon :icon="'plus'" :size="18" />
            </div>

            <div class="riskv3-mc-steps riskv3-mc-steps-lrm">
                <div
                    v-for="(isColored, i) in lrmSteps"
                    :key="'mchs' + i"
                    class="riskv3-mc-step"
                    :class="[
                        { 'riskv3-mc-step-lrm': isColored },
                    ]"
                />
            </div>

            <div class="riskv3-mc-btn">
                <FontIcon
                    :icon="'minus'"
                    :size="18"
                    @click="onChangeLRM(-1)"
                />
            </div>

        </div>

        <div class="riskv3-mc-hrm">

            <div class="riskv3-mc-btn riskv3-mc-nrb" @click="onChangeHRM(-1)">
                <FontIcon :icon="'minus'" :size="18" />
            </div>

            <div class="riskv3-mc-steps">
                <div
                    v-for="(isColored, i) in hrmSteps"
                    :key="'mchs' + i"
                    class="riskv3-mc-step"
                    :class="[
                        { 'riskv3-mc-step-hrm': isColored },
                    ]"
                />
            </div>

            <div class="riskv3-mc-btn riskv3-mc-btn-last" @click="onChangeHRM(1)">
                <FontIcon :icon="'plus'" :size="18" />
            </div>

        </div>

    </div>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";

import { RiskIndicatorDefinition } from "@/lib/risk/risk_matrix";
import FontIcon from "@/ui/FontIcon.vue";

export default defineComponent({
    name: "RiskMultiplierControl",
    components: {
        FontIcon,
    },
    emits: ["change-hrm", "change-lrm"],
    props: {
        indicator: { type: Object as PropType<RiskIndicatorDefinition>, required: true },
    },
    data() {
        return {
            lrmSteps: new Array<boolean>(),
            hrmSteps: new Array<boolean>(),
        };
    },
    watch: {
        "indicator.lrm": {
            deep: false,
            handler() {
                this.renderSteps();
            },
        },
        "indicator.hrm": {
            deep: false,
            handler() {
                this.renderSteps();
            },
        },
    },
    methods: {

        onChangeLRM(changeValue: number) {
            const newValue = this.indicator.lrm + changeValue;
            if (newValue > 5 || newValue < 0) return;
            this.$emit("change-lrm", newValue);
        },

        onChangeHRM(changeValue: number) {
            const newValue = this.indicator.hrm + changeValue;
            if (newValue > 5 || newValue < 0) return;
            this.$emit("change-hrm", newValue);
        },

        renderSteps() {
            this.lrmSteps = [];
            this.hrmSteps = [];
            for (let i = 0; i < 5; i += 1) {
                this.lrmSteps.push((this.indicator.lrm > i));
            }
            for (let i = 0; i < 5; i += 1) {
                this.hrmSteps.push((this.indicator.hrm > i));
            }
        },

    },
    mounted() {
        this.renderSteps();
    },
});
</script>
<style scoped>
.riskv3-mc {
    flex-grow: 1;
    display: flex;
    padding-left: 2px;
    padding-right: 3px;
}

.riskv3-mc-lrm {
    width: 50%;
    display: flex;
}

.riskv3-mc-hrm {
    width: 50%;
    display: flex;
}

.riskv3-mc-btn {
    flex-shrink: 0;
    width: 32px;
    height: 30px;
    padding-top: 2px;
    text-align: center;
    border: solid 1px lightgray;
    border-left: none;
    background-color: whitesmoke;
    cursor: pointer;
}

.riskv3-mc-btn:hover {
    background-color: lightgray;
}

.riskv3-mc-steps {
    flex-grow: 1;
    height: 30px;
    display: flex;
    border: solid 1px lightgray;
    border-left: none;
}

.riskv3-mc-steps-lrm {
    flex-direction: row-reverse;
}

.riskv3-mc-step {
    width: 20%;
    border-left: solid 1px lightgray;
    background-color: white;
}

.riskv3-mc-step-lrm {
    background-color: #9EACB5;
}

.riskv3-mc-step-hrm {
    background-color: #B6A9A8;
}

.riskv3-mc-btn-first {
    border-left: solid 1px lightgray;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.riskv3-mc-btn-last {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.riskv3-mc-nrb {
    border-right: none;
}
</style>
