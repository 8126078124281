<template>
    <InputRow>
        <InputGroup :width="12">
            <InputLabel
                :text="$t('newCustomer.inputs.company.label')"
                :required="true"
                unique-id="company-name"
            />
            <InputText
                v-model="company.name"
                unique-id="company-name"
                :placeholder="$t('newCustomer.inputs.company.placeholder')"
            />
        </InputGroup>

    </InputRow>

    <InputRow>
        <InputGroup :width="6">
            <InputLabel
                :text="$t('newCustomer.inputs.orgNumber.label')"
                :required="true"
                unique-id="company-org-nr"
            />
            <InputText
                v-model="company.registrationNumber"
                unique-id="company-org-nr"
                :placeholder="$t('newCustomer.inputs.company.orgNumber')"
            />
        </InputGroup>
    </InputRow>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { CompanyData } from "./CompanyCustomerCreator.vue";
import InputGroup from "@/ui/inputs/InputGroup.vue";
import InputText from "@/ui/inputs/InputText.vue";
import InputLabel from "@/ui/inputs/InputLabel.vue";
import InputRow from "@/ui/inputs/InputRow.vue";

export default defineComponent({
    name: "OtherCompanySearch",
    components: {
        InputGroup,
        InputText,
        InputLabel,
        InputRow,
    },
    props: {
        country: {
            type: String,
            required: true,
        },
    },
    emits: ["companyDataUpdated"],
    data() {
        return {
            company: {
                name: "",
                registrationNumber: "",
                manual: true,
            } as CompanyData,
        };
    },
    watch: {
        company: {
            handler(old, val) {
                this.$emit("companyDataUpdated", val);
            },
            deep: true,
        },
    },
    mounted() {
        this.$emit("companyDataUpdated", this.company);
    },
});
</script>
