<template>
    <div class="py-3">
        <InputLabel :text="$t( 'newCustomer.selectWorkflow' )" unique-id="workflow" />
        <div class=" pt-3">
            <InputRadio
                v-model="flowModel"
                :items="workflows"
                :unique-id="'flowradio'"
                @input="onInput"
            />
        </div>

        <div class="amlv3-workflow-selector-error" v-if="workflows.length === 0">
            {{ $t('newCustomer.noAvailableWorkflow') }}
        </div>

    </div>
</template>
<script lang="ts">
import { getValidWorkflowConfigs } from "@/lib/workflows/workflow_helper";
import { FlowConditions } from "@/types/flow_types";
import InputLabel from "@/ui/inputs/InputLabel.vue";
import InputRadio from "@/ui/inputs/InputRadio.vue";
import { CheckboxItem } from "@/ui/inputs/input_helper";
import { defineComponent, type PropType } from "vue";

export default defineComponent({
    name: "WorkflowSelector",
    components: {
        InputRadio,
        InputLabel,
    },
    emits: ["update:modelValue"],
    props: {
        modelValue: { type: String as PropType<string>, required: true },
        conditions: { type: Object as PropType<FlowConditions>, required: true },
    },
    data() {
        return {
            workflows: new Array<CheckboxItem>(),
            flowModel: "",
        };
    },
    watch: {
        conditions: {
            deep: true,
            handler() {
                this.loadWorkflows();
            },
        },
    },
    methods: {

        onInput() {
            this.$emit("update:modelValue", this.flowModel);
        },

        loadWorkflows() {
            this.workflows = [];
            const workflows = getValidWorkflowConfigs(this.conditions, this.$config.rawSettings);
            workflows.forEach((workflow) => {
                this.workflows.push({
                    key: workflow.key,
                    text: workflow.name,
                });
            });
            if (workflows.length > 0) {
                this.flowModel = workflows[0].key;
            }
            this.$emit("update:modelValue", this.flowModel);
        },

    },
    mounted() {
        this.loadWorkflows();
    },
});
</script>
<style scoped>
.amlv3-workflow-selector {
    padding-top: 10px;
    padding-left: 10px;
}

.amlv3-workflow-selector-error {
    text-align: right;
    color: red;
    font-size: 15px;
}
</style>
