import { type App } from "vue";
import { loadRiskAssets, RiskAssets } from "./views/risk/risk_assets";
import { Employee } from "./lib/common/identity";
import { getEmployees, Policy } from "./lib/platform";
import { getWorkflowTexts } from "./lib/workflows/get_available_workflows";
import { LocalizedStringMap } from "./lib/common/common";
import { RegularSettings, Setting } from "./lib/settings/settings";
import { querySettings } from "./lib/settings/get_settings";

/*
    This file should be used to cache common data that would
    otherwise be unnecessary fetched every time from the server.
*/

export interface GlobalAssetsFetchResult {
    // This will be fetched when the app loads
    // Don't add too much in here as we don't want it to be slow

    employees: Employee[],
    employeesDate: number,
}

export class GlobalAssets {
    private _settings: Setting;
    private _policies: string[];
    private _settingsDate: number;

    private _employees: Employee[];
    private _employeesDate: number;

    private _riskAssets: RiskAssets;
    private _riskAssetsDate: number;

    private _workflowTexts: LocalizedStringMap;
    private _workflowTextsDate: number;

    constructor() {
        this._settings = {
            settings: {},
        };
        this._policies = [];
        this._settingsDate = 0;

        this._employees = [];
        this._employeesDate = 0;

        this._riskAssets = {};
        this._riskAssetsDate = 0;

        this._workflowTexts = {};
        this._workflowTextsDate = 0;
    }

    updateGlobal(fetchResult: GlobalAssetsFetchResult) {
        this._employees = fetchResult.employees;
        this._employeesDate = fetchResult.employeesDate;

        /* console.log("global assets updates", fetchResult); */
    }

    async getSettings(): Promise<Setting> {
        if (isOutdatedInMinutes(this._settingsDate, 10)) {
            /* console.log("settings outdated"); */
            const response = await querySettings();
            this._settings = response;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            this._policies = (response as any).policies;
            this._settingsDate = Date.now();
        } else {
            /* console.log("returning cached settings"); */
        }
        return this._settings;
    }

    async getRegularSettings(): Promise<RegularSettings> {
        return (await this.getSettings()).settings;
    }

    async getPolicies(): Promise<Policy[]> {
        if (isOutdatedInMinutes(this._settingsDate, 10)) {
            const response = await querySettings();
            this._settings = response;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            this._policies = (response as any).policies;
            this._settingsDate = Date.now();
        }
        return this._policies as Policy[];
    }

    async hasPolicy(policy: Policy): Promise<boolean> {
        const policies = await this.getPolicies();
        if (policies.includes(policy)) return true;
        return false;
    }

    async getRiskAssets(): Promise<RiskAssets> {
        if (isOutdatedInMinutes(this._riskAssetsDate, 60)) {
            /* console.log("FETCHING risk assets"); */
            this._riskAssets = await loadRiskAssets();
            this._riskAssetsDate = Date.now();
        } else {
            /* console.log("returning cached risk assets"); */
        }
        return this._riskAssets;
    }

    async getEmployees(): Promise<Employee[]> {
        if (isOutdatedInMinutes(this._employeesDate, 10)) {
            /* console.log("employees outdated"); */
            const employeesResponse = await getEmployees();
            if (employeesResponse.responseInfo.isError || !employeesResponse.employees) {
                // todo: handle errors in here
            } else {
                this._employees = employeesResponse.employees;
                this._employeesDate = Date.now();
            }
        } else {
            /* console.log("returning cached employees"); */
        }
        return this._employees;
    }

    async getWorkflowTexts(): Promise<LocalizedStringMap> {
        if (isOutdatedInMinutes(this._workflowTextsDate, 10)) {
            /* console.log("workflow texts outdated"); */
            const response = await getWorkflowTexts();
            if (response.responseInfo.isError || !response.texts) {
                // todo: handle errors in here
            } else {
                this._workflowTexts = response.texts;
                this._workflowTextsDate = Date.now();
            }
        } else {
            /* console.log("returning cached workflow texts"); */
        }
        return this._workflowTexts;
    }
}

export const fetchGlobalAssets = async (): Promise<GlobalAssetsFetchResult> => {
    const result: GlobalAssetsFetchResult = {
        employees: [],
        employeesDate: 0,
    };

    const employeesResponse = await getEmployees();
    if (employeesResponse.responseInfo.isError || !employeesResponse.employees) {
        // todo: handle errors in here
    } else {
        result.employees = employeesResponse.employees;
        result.employeesDate = Date.now();
    }

    return result;
};

const isOutdatedInMinutes = (fetchDate: number, maxAgeMinutes: number): boolean => {
    const MINUTE = 1000 * 60;
    const difference = Date.now() - fetchDate;
    if ((difference / MINUTE) > maxAgeMinutes) return true;
    return false;
};

export default () => ({

    install(app: App) {
        app.config.globalProperties.$assets = new GlobalAssets();
    },

});

declare module "@vue/runtime-core" {
    interface ComponentCustomProperties {
        $assets: GlobalAssets;
    }
}
