import Customer from "@/views/customer/Customer.vue";
import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import Customers from "./views/Customers.vue";
import FormView from "./views/FormView.vue";
import NewCustomer from "./views/NewCustomer.vue";
import Overview from "./views/Overview.vue";
import PersonProfile from "./views/person/PersonProfile.vue";
import Persons from "./views/Persons.vue";
import RiskMatrixEditor from "./views/risk/RiskMatrixEditor.vue";
import Settings from "./views/Settings.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/aml",
        name: "index",
        component: Overview,
    },
    {
        path: "/aml/overview",
        name: "overview",
        component: Overview,
    },
    {
        path: "/aml/customers",
        name: "customers",
        component: Customers,
    },
    {
        path: "/aml/customers/new",
        name: "new-customer",
        component: NewCustomer,
    },
    {
        path: "/aml/persons",
        name: "persons",
        component: Persons,
    },
    {
        path: "/aml/settings/:section/:tab",
        name: "Settings",
        component: Settings,
    },
    {
        path: "/aml/customers/:id/:tab",
        name: "customer",
        component: Customer,
    },
    {
        path: "/aml/persons/:id",
        name: "person-profile",
        component: PersonProfile,
    },
    {
        path: "/aml/form",
        name: "form",
        component: FormView,
        meta: {
            hidenav: true,
        },
    },
    {
        path: "/aml/matrix",
        name: "matrix",
        component: RiskMatrixEditor,
    },
    // Lazy load example
    // {
    //   path: "/aml/customers",
    //   name: "customers",
    //   component: () =>
    //     import(/* webpackChunkName: "customers" */ "./views/Customers.vue"),
    // },
];

const router = createRouter({
    history: createWebHashHistory("/#"),
    routes,
});

export default router;
