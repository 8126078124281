<template>
    <div
        class="rsci"
        v-click-away="hideDropdown"
    >
        <div class="rsci-top">
            <div class="rsci-content">
                <div class="rsci-name">
                    {{ config.name }}
                </div>

                <div class="rsci-score">
                    <span>{{ config.minScore }}</span>
                    <span>{{ ' - ' }}</span>
                    <span>{{ config.maxScore }}</span>
                </div>
            </div>

            <div class="rsci-actions">
                <div
                    class="rsci-action"
                    @click="$emit('edit')"
                >
                    <FontIcon :icon="'pen'" />
                </div>
                <div
                    class="rsci-action rsci-action-end"
                    @click="open = !open"
                >
                    <FontIcon :icon="'ellipsis-vertical'" />
                </div>
            </div>

            <div
                class="rsci-menu"
                v-if="open"
            >
                <div
                    class="rsci-menu-item"
                    @click="onDeleteClicked"
                >
                    {{ 'Delete Risk Configuration' }}
                </div>
            </div>
        </div>

        <div class="rsci-thresholds">
            <div
                class="rsci-threshold"
                v-for="(threshold) in config.thresholds"
                :key="threshold.key"
            >
                <div
                    class="rsci-threshold-color"
                    :style="'background-color: ' + threshold.color + ';'"
                />
                <div class="rsci-threshold-text">
                    {{ $ct(threshold.text) }}
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { mixin as VueClickAway } from "vue3-click-away";

import { RiskConfig } from "@/lib/settings/settings";

import FontIcon from "@/ui/FontIcon.vue";

export default defineComponent({
    name: "RiskSettingsConfigItem",
    mixins: [VueClickAway],
    components: {
        FontIcon,
    },
    emits: ["edit", "delete"],
    props: {
        config: { type: Object as PropType<RiskConfig>, required: true },
    },
    data() {
        return {
            open: false,
        };
    },
    methods: {

        hideDropdown() {
            this.open = false;
        },

        onDeleteClicked() {
            this.hideDropdown();
            this.$emit("delete");
        },

    },
});
</script>
<style scoped>
.rsci {
    margin-top: 5px;
    margin-bottom: 10px;
}

.rsci-top {
    display: flex;
    position: relative;
}

.rsci-content {
    flex-grow: 1;
    padding: 2px 10px;
    border-top-left-radius: 4px;
    border: solid 1px lightgray;
    border-right: none;
    background-color: white;
}

.rsci-name {
    font-size: 16;
}

.rsci-score {
    margin-top: -3px;
    font-family: monospace;
    font-size: 15px;
}

.rsci-actions {
    flex-shrink: 0;
    display: flex;
}

.rsci-action {
    width: 40px;
    padding-top: 7px;
    text-align: center;
    border: solid 1px lightgray;
    border-right: none;
    background-color: white;
    cursor: pointer;
}

.rsci-action:hover {
    background-color: whitesmoke;
}

.rsci-action-end {
    width: 24px;
    border-top-right-radius: 4px;
    border-right: solid 1px lightgray;
}

.rsci-menu {
    position: absolute;
    top: 46px;
    right: 0px;
    border: solid 1px lightgray;
    border-bottom: none;
    background-color: white;
    z-index: 9;
}

.rsci-menu-item {
    min-width: 120px;
    padding: 5px 10px;
    color: black;
    border-bottom: solid 1px lightgray;
    cursor: pointer;
}

.rsci-menu-item:hover {
    background-color: whitesmoke;
}

.rsci-thresholds {
    display: flex;
    padding: 5px 10px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border: solid 1px lightgray;
    border-top: none;
    background-color: white;
}

.rsci-threshold {
    margin-right: 10px;
    display: flex;
}

.rsci-threshold-color {
    width: 26px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border: solid 1px lightgray;
}

.rsci-threshold-text {
    padding: 0px 10px;
    font-size: 15px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border: solid 1px lightgray;
    border-left: none;
}
</style>
