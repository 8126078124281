<template>
    <textarea
        v-if="isMultiline"
        class="fbav3-input-text"
        :id="uniqueId"
        :value="modelValue"
        :placeholder="placeholder"
        @input="onInput"
        @keyup.enter="onEnterClicked"
        :rows="5"
    />
    <input
        v-else
        class="fbav3-input-text"
        :id="uniqueId"
        :type="isPassword ? 'password' : ''"
        :value="modelValue"
        :placeholder="placeholder"
        @input="onInput"
        @keyup.enter="onEnterClicked"
    />
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";

export default defineComponent({
    name: "InputText",
    props: {
        modelValue: { type: [String, Number] as PropType<string | number>, required: true },
        uniqueId: { type: String as PropType<string>, required: true },
        isPassword: { type: Boolean as PropType<boolean>, default: false },
        placeholder: { type: String as PropType<string>, default: "" },
        isMultiline: { type: Boolean as PropType<boolean>, default: false },
    },
    emits: ["update:modelValue", "enter"],
    methods: {
        onInput(event: Event) {
            const value = (event.target as HTMLInputElement).value;
            this.$emit("update:modelValue", value);
        },

        onEnterClicked() {
            this.$emit("enter");
        },
    },
});
</script>
<style scoped>
.fbav3-input-text {
    width: 100%;
    min-height: 42px;
    padding: 0px 8px;
    font-size: 16px;
    font-weight: 400;
    color: #444444;
    border-radius: 4px;
    border: solid 1px #d5d5d5;
    box-sizing: border-box;
}
</style>
