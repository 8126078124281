import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-78c3aa45"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-full mx-auto bg-white rounded-lg py-5" }
const _hoisted_2 = {
  key: 0,
  class: "flex items-center text-blue-500 mb-4"
}
const _hoisted_3 = { class: "ml-2 font-semibold text-lg" }
const _hoisted_4 = { class: "text-body" }
const _hoisted_5 = { class: "text-light-gray text-nowrap" }
const _hoisted_6 = { class: "font-normal text-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showHeader)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_Icon, {
            class: "w-4 h-4",
            icon: "events"
          }),
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t("project.overviewMenu.lastEvents.title")), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.events, (event) => {
        return (_openBlock(), _createElementBlock("div", {
          key: event.id,
          class: "mb-2 flex gap-2"
        }, [
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$d(event.timestamp, "longDateTime")) + " -", 1),
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.getDescription(event)), 1)
        ]))
      }), 128))
    ])
  ]))
}