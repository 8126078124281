import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputRadio = _resolveComponent("InputRadio")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_InputRadio, {
      modelValue: _ctx.value,
      "onUpdate:modelValue": [
        _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
        _ctx.handleUpdate
      ],
      items: _ctx.items,
      "unique-id": 'test',
      "is-inline": true
    }, null, 8, ["modelValue", "items", "onUpdate:modelValue"])
  ]))
}