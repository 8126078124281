<template>
    <div class="flex px-6 py-4 border border-light rounded-md bg-white items-center gap-4">
        <!-- <Icon icon="smily" class="w-8 h-8" /> -->
        <label class="text-xl font-medium p-2">{{ text }}</label>
    </div>
</template>

<script lang="ts">
import Icon from "./Icon.vue";
import { defineComponent } from "vue";

export default defineComponent({
    name: "NoItem",
    components: {
        Icon,
    },
    props: {
        text: {
            type: String,
            required: true,
        },
    },
});
</script>
