import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-326efd22"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "amlsetv3-approval-item" }
const _hoisted_2 = { class: "amlsetv3-approval-item-text" }
const _hoisted_3 = { class: "amlsetv3-approval-item-name" }
const _hoisted_4 = { class: "amlsetv3-approval-item-key" }
const _hoisted_5 = { class: "amlsetv3-approval-item-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontIcon = _resolveComponent("FontIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$ct(_ctx.config.name)), 1),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.config.key), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", {
        class: "amlsetv3-approval-item-action",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('edit')))
      }, [
        _createVNode(_component_FontIcon, { icon: 'pen' })
      ]),
      _createElementVNode("div", {
        class: "amlsetv3-approval-item-action amlsetv3-approval-item-action-end",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('delete')))
      }, [
        _createVNode(_component_FontIcon, { icon: 'trash-can' })
      ])
    ])
  ]))
}