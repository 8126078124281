<template>
    <div
        class="fbl-button-row"
        :class="[
            { 'fbl-button-row-l': alignment === ButtonAlignment.Left },
            { 'fbl-button-row-r': alignment === ButtonAlignment.Right },
            { 'fbl-button-row-np': noPadding },
        ]"
    >

        <div class="fbl-button-row-left" v-if="alignment !== ButtonAlignment.Left">
            <slot name="left" />
        </div>

        <div class="fbl-button-row-con">
            <slot />
        </div>

        <div class="fbl-button-row-right" v-if="alignment !== ButtonAlignment.Right">
            <slot name="right" />
        </div>

    </div>
</template>
<script lang="ts">
import { ButtonAlignment } from "@/types/ui_types";
import { defineComponent, type PropType } from "vue";

export default defineComponent({
    name: "ButtonRow",
    props: {
        alignment: { type: String as PropType<ButtonAlignment>, default: "center" },
        noPadding: { type: Boolean as PropType<boolean>, default: false },
    },
    data() {
        return {
            ButtonAlignment,
        };
    },
});
</script>
<style>
.fbl-button-row {
    display: flex;
    width: 100%;
}

.fbl-button-row-left {
    flex-grow: 100;
}

.fbl-button-row-con {
    flex-shrink: 0;
    display: flex;
}

.fbl-button-row-right {
    flex-grow: 100;
}

.fbl-button-row .fbl-button {
    margin: 0px 5px;
}

.fbl-button-row-l .fbl-button {
    margin: 0px;
    margin-right: 10px;
}

.fbl-button-row-r .fbl-button {
    margin: 0px;
    margin-left: 10px;
}

.fbl-button-row-np .fbl-button {
    margin: 0px;
}

.fbl-button-row-np .fbl-button-combine-l {
    margin-left: 1px;
}
</style>
