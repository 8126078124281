import { Assessment } from "./assessments";

export interface LoadAssessmentResult {
    currentAssessment: Assessment;
    previousAssessments: Assessment[];
    sealedAssessments: Assessment[];
}

export const loadAssessments = (assessments: Assessment[]): LoadAssessmentResult => {
    const sealedAssessments: Assessment[] = [];
    const previousAssessments: Assessment[] = [];
    let currentAssessment: Assessment | null = null;

    for (const i in assessments) {
        const assessment = assessments[i];
        if (Number(i) === assessments.length - 1) {
            currentAssessment = assessment;
            if (assessment.sealed) {
                sealedAssessments.push(assessment);
            }
        } else {
            previousAssessments.push(assessment);
            sealedAssessments.push(assessment);
        }
    }

    if (!currentAssessment) {
        throw new Error("This should never happen!!!");
    }

    return {
        currentAssessment,
        previousAssessments,
        sealedAssessments,
    };
};
