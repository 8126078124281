<template>
    <div>

        <CustomerTopAction
            :icon="'user'"
            :text="assigneeName"
            :tooltip="$t('project.assignee.title')"
            :is-clickable="true"
            @clicked="showDialog"
        />

        <PopupDialog
            :is-visible="dialog.visible"
            :header="$t('project.assignee.changeAction')"
            :icon="'user'"
            :progress="dialog"
            :show-cancel-button="true"
            :show-action-button="true"
            :action-icon="'repeat'"
            :is-action-disabled="selectedAssignee == project.assignee"
            :action-text="$t('project.assignee.changeAction')"
            :is-warning="false"
            @close-modal="dialog.visible = false"
            @action="updateAssignee"
        >

            <div>
                <SimpleListItem
                    v-for="(employee, i) in employees"
                    :key="employee.uid"
                    :text="employee.name"
                    :sub-text="employee.email"
                    :is-selected="employee.uid === selectedAssignee"
                    :is-first="i === 0"
                    :is-last="i === employees.length - 1"
                    @clicked="selectAssignee(employee.uid)"
                />
            </div>

        </PopupDialog>

    </div>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";

import { Project } from "@/lib/projects/projects";
import { UpdateProjectOptions, updateProject } from "@/lib/projects/update_project";
import CustomerTopAction from "./CustomerTopAction.vue";
import PopupDialog from "@/ui/PopupDialog.vue";
import PopupDialogPrompt from "@/ui/dialog/PopupDialogPrompt.vue";
import SimpleListItem from "@/ui/SimpleListItem.vue";
import { getProjectProcess } from "@/lib/projects/get_project";

export default defineComponent({
    name: "AssigneeSelector",
    components: {
        CustomerTopAction,
        PopupDialog,
        PopupDialogPrompt,
        SimpleListItem,
    },
    emits: ["request-project-refresh"],
    props: {
        project: { type: Object as PropType<Project>, required: true },
    },
    computed: {
        assigneeName(): string {
            const currentAssignee = this.employees.find(x => x.uid === this.project.assignee);
            return currentAssignee ? currentAssignee.name : this.$t("project.assignee.unknown");
        },
    },
    data() {
        return {
            dialog: { visible: false, isWorking: false, statusText: "", isError: false, errorText: "" },
            selectedAssignee: this.project.assignee,
            employees: [] as { name: string, email: string, uid: string }[],
        };
    },
    methods: {
        showDialog() {
            this.selectedAssignee = this.project.assignee;
            this.dialog.visible = true;
        },

        selectAssignee(assignee: string) {
            this.selectedAssignee = assignee;
        },

        async updateAssignee() {
            this.dialog.isError = false;
            this.dialog.isWorking = true;
            this.dialog.statusText = this.$t("project.assignee.working");
            const options: UpdateProjectOptions = {
                assignee: this.selectedAssignee,
            };
            const response = await updateProject(this.project.id, options);
            if (response.responseInfo.isError) {
                this.dialog.errorText = response.responseInfo.parsedError?.message || "Unknown error";
                this.dialog.isError = true;
            } else {
                const projectResponse = await getProjectProcess(this.project.id);
                this.$emit("request-project-refresh", projectResponse);
                this.dialog.visible = false;
            }
            this.dialog.isWorking = false;
        },
    },
    async mounted() {
        const employees = await this.$assets.getEmployees();
        this.employees = employees.map(x => ({ name: `${x.givenName} ${x.familyName}`, email: x.email, uid: x.uid }));
    },
});
</script>
