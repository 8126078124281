<template>
    <label :for="id" class="cursor-pointer m-0">
        <div class="relative">
            <input
                v-model="value"
                type="checkbox"
                :id="id"
                class="sr-only"
            />
            <div class="dot-container block bg-light-gray w-10 h-6 rounded-full"></div>
            <div class="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition"></div>
        </div>
    </label>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    name: "SwitchInput",
    props: {
        modelValue: {
            type: Boolean,
            required: true,
        },
    },
    emits: ["update:modelValue"],
    computed: {
        id(): string {
            return `switch-input-${this.$.uid}`;
        },
        value: {
            get(): boolean {
                return this.modelValue;
            },
            set(value: boolean) {
                this.$emit("update:modelValue", value);
            },
        },
    },
});
</script>

<style lang="scss" scoped>
input:checked ~ .dot {
    @apply translate-x-full bg-white;
}

input:checked ~ .dot-container {
    @apply bg-primary-default;
}
</style>
