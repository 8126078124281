import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 py-6" }
const _hoisted_2 = { class: "flex justify-between mb-5" }
const _hoisted_3 = { class: "text-3xl" }
const _hoisted_4 = { class: "flex flex-col gap-y-5" }
const _hoisted_5 = { class: "p-0 space-y-3" }
const _hoisted_6 = ["value", "onInput"]
const _hoisted_7 = { class: "space-y-2" }
const _hoisted_8 = {
  key: 0,
  class: "space-y-3 bg-background rounded-lg p-4"
}
const _hoisted_9 = { class: "w-full relative" }
const _hoisted_10 = { class: "block" }
const _hoisted_11 = { class: "absolute right-1 top-0" }
const _hoisted_12 = { class: "space-y-2" }
const _hoisted_13 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_InputLabel = _resolveComponent("InputLabel")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_CustomButton = _resolveComponent("CustomButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.title), 1),
      _createVNode(_component_Icon, {
        class: "h-10 w-10 bg-background hover:bg-light-gray p-3 rounded-full cursor-pointer",
        icon: "cross",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closed'))),
        "aria-hidden": "true"
      })
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("ul", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nameList, (name, i) => {
          return (_openBlock(), _createElementBlock("li", {
            class: "flex gap-x-2 items-center min-h-[6rem] border border-light border-solid p-4 rounded-lg hover:bg-primary-hover-blue",
            key: `name-${i}`
          }, [
            _createElementVNode("div", null, [
              _withDirectives(_createElementVNode("input", {
                name: "name",
                type: "radio",
                value: name.value,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentValue) = $event)),
                onInput: ($event: any) => (_ctx.onChange(name.value)),
                class: "h-6 w-6 border-light"
              }, null, 40, _hoisted_6), [
                [_vModelRadio, _ctx.currentValue]
              ])
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("label", null, _toDisplayString(name.value), 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(name.sources, (src) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "text-sm text-light-gray flex gap-2",
                    key: `src-${src.source}`
                  }, [
                    _createVNode(_component_Icon, {
                      class: "w-4 h-4",
                      icon: `aml:src-${src.source.toLowerCase()}`
                    }, null, 8, ["icon"]),
                    _createVNode(_component_Icon, {
                      icon: "dot-single",
                      "aria-hidden": "true"
                    }),
                    _createElementVNode("span", null, _toDisplayString(_ctx.$d(new Date(src.updatedAt), "long")), 1)
                  ]))
                }), 128))
              ])
            ])
          ]))
        }), 128))
      ]),
      (_ctx.addName)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.addOrUpdateText), 1),
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_Icon, {
                  icon: "cross",
                  class: "w-3 h-3 cursor-pointer",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.addName = false)),
                  "aria-hidden": "true"
                })
              ])
            ]),
            _createVNode(_component_Icon, {
              icon: "horizontal-line",
              class: "",
              "aria-hidden": "true"
            }),
            _createElementVNode("div", _hoisted_12, [
              _createVNode(_component_InputLabel, {
                "unique-id": "person-name",
                text: _ctx.propertyName === 'givenName' ? _ctx.$t('common.person_info.firstName') : _ctx.$t('common.person_info.lastName'),
                class: "block"
              }, null, 8, ["text"]),
              _createVNode(_component_InputText, {
                modelValue: _ctx.newName,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.newName) = $event)),
                "unique-id": "addName"
              }, null, 8, ["modelValue"])
            ]),
            _createVNode(_component_CustomButton, {
              text: _ctx.addOrUpdateText,
              class: "border border-primary-default font-medium text-base box-border ml-auto",
              "text-color": "primary-default",
              "round-style": "3xl",
              "bg-color": "white",
              onClick: _ctx.addNewName
            }, null, 8, ["text", "onClick"])
          ]))
        : _createCommentVNode("", true),
      (!_ctx.addName)
        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
            _createElementVNode("a", {
              class: "text-primary-default",
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.addName = true))
            }, _toDisplayString(_ctx.addOrUpdateText), 1)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}