import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-61ef34ea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"padding":"5px 30px","padding-bottom":"10px","font-size":"14px","color":"gray"} }
const _hoisted_2 = { class: "amlv3-otherprojects-links" }
const _hoisted_3 = { class: "amlv3-otherprojects-link-left" }
const _hoisted_4 = { class: "amlv3-otherprojects-link-top" }
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuInfoLine = _resolveComponent("MenuInfoLine")!
  const _component_FontIcon = _resolveComponent("FontIcon")!
  const _component_ExpandableListLayout = _resolveComponent("ExpandableListLayout")!

  return (_openBlock(), _createBlock(_component_ExpandableListLayout, {
    "can-expand": false,
    expanded: _ctx.links.length === 0,
    onClicked: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('menu-tab', 'events')))
  }, {
    top: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('project.otherProjects.title')), 1)
    ]),
    expanded: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('project.otherProjects.empty')), 1)
    ]),
    collapsed: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links, (link, i) => {
          return (_openBlock(), _createElementBlock("div", {
            key: 'opp' + i,
            class: _normalizeClass(["amlv3-otherprojects-link", [
                        {'amlv3-otherprojects-link-last': i === _ctx.links.length - 1 },
                    ]])
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$ct(link.workflow)), 1),
              _createVNode(_component_MenuInfoLine, {
                info1: link.name,
                info2: link.assignee
              }, null, 8, ["info1", "info2"])
            ]),
            _createElementVNode("div", {
              class: "amlv3-otherprojects-link-arrow",
              onClick: ($event: any) => (_ctx.$emit('project-link', link.id))
            }, [
              _createVNode(_component_FontIcon, { icon: 'right-long' })
            ], 8, _hoisted_5)
          ], 2))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["expanded"]))
}