import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderWithAddIcon = _resolveComponent("HeaderWithAddIcon")!
  const _component_LoadAnimationBar = _resolveComponent("LoadAnimationBar")!
  const _component_ApprovalSettingsItem = _resolveComponent("ApprovalSettingsItem")!
  const _component_ApprovalSettingsDialog = _resolveComponent("ApprovalSettingsDialog")!
  const _component_PopupDialogPrompt = _resolveComponent("PopupDialogPrompt")!
  const _component_PopupDialog = _resolveComponent("PopupDialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_HeaderWithAddIcon, {
            text: 'Approvals',
            "hide-icon": true
          }),
          _createVNode(_component_LoadAnimationBar, { height: 60 }),
          _createVNode(_component_LoadAnimationBar)
        ]))
      : _createCommentVNode("", true),
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_HeaderWithAddIcon, {
            text: 'Approvals',
            onClicked: _ctx.showAddDialog
          }, null, 8, ["onClicked"]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.approvals, (config, i) => {
            return (_openBlock(), _createBlock(_component_ApprovalSettingsItem, {
              key: 'ac' + i,
              config: config,
              onEdit: ($event: any) => (_ctx.showEditDialog(config)),
              onDelete: ($event: any) => (_ctx.showDeleteDialog(config))
            }, null, 8, ["config", "onEdit", "onDelete"]))
          }), 128)),
          (_ctx.approvals.length === 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString('No approval configurations')))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.dialog.visible)
      ? (_openBlock(), _createBlock(_component_ApprovalSettingsDialog, {
          key: 2,
          config: _ctx.dialog.config,
          onCloseModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dialog.visible = false)),
          onAdd: _ctx.onAdd,
          onEdit: _ctx.onEdit
        }, null, 8, ["config", "onAdd", "onEdit"]))
      : _createCommentVNode("", true),
    _createVNode(_component_PopupDialog, {
      "is-visible": _ctx.deleteDialog.visible,
      header: _ctx.$t('common.actions.delete'),
      icon: 'trash-can',
      "show-action-button": true,
      "show-cancel-button": true,
      "action-text": _ctx.$t('common.actions.delete'),
      "action-icon": 'trash-can',
      "is-warning": true,
      onCloseModal: _cache[1] || (_cache[1] = ($event: any) => (_ctx.deleteDialog.visible = false)),
      onAction: _ctx.onDelete
    }, {
      default: _withCtx(() => [
        _createVNode(_component_PopupDialogPrompt, {
          "is-warning": true,
          "prompt-text": 'Are you sure?'
        })
      ]),
      _: 1
    }, 8, ["is-visible", "header", "action-text", "onAction"])
  ]))
}