<template>
    <div class="srv3-step-actions">
        <div class="srv3-step-main-action" v-if="mainAction">

            <ButtonSingle
                :text="$ct(mainAction.text)"
                :tooltip="mainAction.disabledText ? $ct(mainAction.disabledText) : ''"
                :min-width="170"
                :disabled="mainAction.isDisabled"
                @clicked="emitAction(mainAction)"
            />

        </div>
        <div
            v-if="menuActions.length > 0"
            class="srv3-step-actions-menu-icon"
            @click="open = true"
        >
            <FontIcon :icon="'ellipsis-vertical'" :size="34" />
        </div>
        <div v-if="menuActions.length === 0" class="srv3-step-actions-menu-icon-empty" />

        <div
            v-if="open"
            class="srv3-step-actions-menu"
            v-click-away="hideDropdown"
        >
            <div
                v-for="(action, i) in menuActions"
                :key="'sai' + i"
                class="srv3-step-actions-menu-item"
                :class="[
                    {'srv3-step-actions-menu-item-first': i === 0 },
                    {'srv3-step-actions-menu-item-last': i === menuActions.length - 1 },
                ]"
                @click="emitAction(action)"
            >
                {{ $ct(action.text) }}
            </div>
        </div>

    </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import { mixin as VueClickAway } from "vue3-click-away";

import { ActionRenderData, FlowAction, StatusType, StepRenderData } from "@/types/flow_types";
import ButtonSingle from "@/ui/ButtonSingle.vue";
import FontIcon from "@/ui/FontIcon.vue";

export default defineComponent({
    name: "StepActions",
    mixins: [VueClickAway],
    components: {
        FontIcon,
        ButtonSingle,
    },
    emits: ["action"],
    props: {
        step: { type: Object as PropType<StepRenderData>, required: true },
    },
    data() {
        return {
            StatusType,

            open: false,

            mainAction: null as ActionRenderData | null,
            menuActions: new Array<ActionRenderData>(),
        };
    },
    methods: {

        hideDropdown() {
            this.open = false;
        },

        emitAction(action: ActionRenderData) {
            console.log("stepParams", this.step.params);
            console.log("actionParams", action.params);

            const params = { ...action.params, ...this.step.params };

            const emitData: FlowAction = {
                action: action.action,
                params: params,
            };
            this.$emit("action", emitData);
            this.open = false;
        },

    },
    mounted() {
        this.step.actions.forEach((action) => {
            if (action.isImportant && !this.mainAction) {
                this.mainAction = action;
            } else {
                this.menuActions.push(action);
            }
        });
    },
});
</script>
<style scoped>
.srv3-step-actions {
    position: relative;
    display: flex;
    margin-top: 6px;
}

.srv3-step-main-action {
    padding-top: 4px;
}

.srv3-step-actions-menu-icon {
    width: 40px;
    text-align: center;
    color: #C4C4C4;
    cursor: pointer;
}

.srv3-step-actions-menu-icon:hover {
    color: #707070;
}

.srv3-step-actions-menu-icon-empty {
    width: 40px;
}

.srv3-step-actions-menu {
    position: absolute;
    top: 40px;
    right: 10px;
    z-index: 2;
    border-radius: 3px;
    -webkit-box-shadow: -1px 1px 3px 0px rgba(0,0,0,0.36);
    -moz-box-shadow: -1px 1px 3px 0px rgba(0,0,0,0.36);
    box-shadow: -1px 1px 3px 0px rgba(0,0,0,0.36);
}

.srv3-step-actions-menu-item {
    min-width: 260px;
    padding: 3px 10px;
    border: solid 1px lightgray;
    background-color: white;
    border-top: none;
    cursor: pointer;
}

.srv3-step-actions-menu-item-first {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border: solid 1px lightgray;
}

.srv3-step-actions-menu-item-last {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.srv3-step-actions-menu-item:hover {
    background-color: whitesmoke;
}
</style>
