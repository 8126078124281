import { downloadFormFile } from "./types/form";
import { downloadAssessmentFile } from "./types/assessment";

export enum PdfViewType {
    Form = "form",
    Assessment = "assessment",
}

export enum PdfViewFileType {
    Form = "form",
    Assessment = "assessment",
}

export interface PdfViewFile {
    id: string;
    type: PdfViewFileType;
    name: string;
    param1?: string;
    param2?: string;
    file?: File | Blob;
    attachments: PdfViewFile[];
}

export interface PdfViewParams {
    type: PdfViewType;
    files: PdfViewFile[];
    initialId?: string;
}

export const getFile = async (file: PdfViewFile): Promise<Blob | null> => {
    switch (file.type) {
    case PdfViewFileType.Form: return await downloadFormFile(file);
    case PdfViewFileType.Assessment: return await downloadAssessmentFile(file);
    }
    return null;
};
