<template>
    <div class="amlv3-atab" v-if="!isLoading">

        <div
            class="amlv3-atab-left"
            :class="[
                { 'amlv3-atab-left-scrolled': scrolled },
            ]"
        >

            <IndicatorList
                :assessment="currentAssessment"
                :selected-key="selectedIndicator ? selectedIndicator.key : ''"
                @selected="onSelectIndicator"
            />

        </div>

        <div
            v-if="matrixDefinition"
            class="amlv3-atab-right"
            :class="[
                { 'amlv3-atab-right-scrolled': scrolled },
            ]"
            :style="scrolled ? '' : `height: calc(100vh - ${170 - scrollTop}px);`"
        >

            <div v-if="!selectedIndicator">

                <div>
                    <RiskSliderBox
                        v-if="riskConfig"
                        :project="project"
                        :assessment="currentAssessment"
                        :risk-config="riskConfig"
                        :matrix-definition="matrixDefinition"
                        @request-project-refresh="$emit('request-project-refresh', $event)"
                        @new-assessment="newDialog.visible = true"
                    />
                </div>
                <div class="mt-4">
                    <RiskComments
                        :project="project"
                        :assessment="currentAssessment"
                        :matrix-definition="matrixDefinition"
                        @request-project-refresh="$emit('request-project-refresh', $event)"
                    />
                </div>
                <div v-if="!currentAssessment.locked" class="mt-4">
                    <FlowRequirements :requirements="flowRenderData.requirements" />
                </div>

                <ButtonRow
                    class="mt-4"
                    :alignment="ButtonAlignment.Right"
                >
                    <ButtonSingle
                        v-if="!currentAssessment.locked"
                        is-rounded
                        :text="$t('assessment.lock.lockAction')"
                        :disabled="!currentAssessment.matrixOutput.completed"
                        @clicked="lockDialog.visible = true"
                    />
                </ButtonRow>

            </div>

            <div style="height: 100%;" v-if="selectedIndicator && selectedDefinition">

                <IndicatorDetails
                    :key="selectedDefinition.key"
                    :assets="riskAssets"
                    :indicator="selectedIndicator"
                    :matrix="matrixDefinition"
                    :definition="selectedDefinition"
                    :project="project"
                    :assessment="currentAssessment"
                    @close-modal="onSelectIndicator('')"
                    @request-project-refresh="$emit('request-project-refresh', $event)"
                />

            </div>

        </div>

        <LockAssessmentDialog
            v-if="lockDialog.visible"
            :project="project"
            :assessment="currentAssessment"
            @close-modal="lockDialog.visible = false"
            @request-project-refresh="$emit('request-project-refresh', $event)"
        />

        <NewAssessmentDialog
            v-if="newDialog.visible"
            :project="project"
            @close-modal="newDialog.visible = false"
            @request-project-refresh="$emit('request-project-refresh', $event)"
        />

    </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

import IndicatorDetails from "@/components/assessment/IndicatorDetails.vue";
import IndicatorList from "@/components/assessment/IndicatorList.vue";
import NewAssessmentDialog from "@/components/assessment/NewAssessmentDialog.vue";
import RiskComments from "@/components/assessment/RiskComments.vue";
import RiskSliderBox from "@/components/assessment/RiskSliderBox.vue";
import LockAssessmentDialog from "@/components/assessment/LockAssessmentDialog.vue";
import { Assessment, RiskIndicator } from "@/lib/assessments/assessments";
import { Project } from "@/lib/projects/projects";
import { getRiskMatrix } from "@/lib/risk/get_matrices";
import { RiskIndicatorDefinition, RiskMatrixDefinition } from "@/lib/risk/risk_matrix";
import { RiskConfig } from "@/lib/settings/settings";
import { FlowRenderData } from "@/types/flow_types";
import { ButtonAlignment } from "@/types/ui_types";
import ButtonRow from "@/ui/ButtonRow.vue";
import ButtonSingle from "@/ui/ButtonSingle.vue";
import { RiskAssets } from "../risk/risk_assets";
import FlowRequirements from "@/components/assessment/FlowRequirements.vue";
import { loadAssessments } from "@/lib/assessments/assessment_helper";

export default defineComponent({
    name: "AssessmentTab",
    components: {
        IndicatorList,
        RiskSliderBox,
        IndicatorDetails,
        RiskComments,
        ButtonRow,
        ButtonSingle,
        LockAssessmentDialog,
        NewAssessmentDialog,
        FlowRequirements,
    },
    emits: ["request-project-refresh"],
    props: {
        project: { type: Object as PropType<Project>, required: true },
        assessments: { type: Array as PropType<Assessment[]>, required: true },
        flowRenderData: { type: Object as PropType<FlowRenderData>, required: true },
    },
    watch: {
        assessments: {
            deep: true,
            handler() {
                console.log("change");
                this.loadAssessments();
                this.onSelectIndicator(this.selectedIndicator ? this.selectedIndicator.key : "");
            },
        },
    },
    data() {
        return {
            ButtonAlignment,

            scrollThreshold: 130,
            scrollTop: 0,
            scrolled: false,

            isLoading: true,

            riskAssets: {} as RiskAssets,

            currentAssessment: {} as Assessment,
            previousAssessments: new Array<Assessment>(),

            matrixDefinition: null as RiskMatrixDefinition | null,
            riskConfig: null as RiskConfig | null,

            selectedIndicator: null as RiskIndicator | null,
            selectedDefinition: null as RiskIndicatorDefinition | null,

            lockDialog: { visible: false },
            newDialog: { visible: false },

            isOverriding: false,
        };
    },
    methods: {

        handleScroll() {
            const element = document.getElementById("aml-main");
            if (element) {
                if (element.scrollTop > this.scrollThreshold && !this.scrolled) this.scrolled = true;
                if (element.scrollTop <= this.scrollThreshold && this.scrolled) this.scrolled = false;
                this.scrollTop = element.scrollTop;
            }
        },

        onSelectIndicator(key: string) {
            if (!this.matrixDefinition) {
                this.selectedIndicator = null;
                return;
            }
            for (const i in this.currentAssessment.indicators) {
                if (this.currentAssessment.indicators[i].key === key) {
                    this.selectedIndicator = this.currentAssessment.indicators[i];
                    for (const j in this.matrixDefinition.indicators) {
                        if (this.matrixDefinition.indicators[j].key === this.selectedIndicator.key) {
                            this.selectedDefinition = this.matrixDefinition.indicators[j];
                            return;
                        }
                    }
                }
            }
            this.selectedDefinition = null;
            this.selectedIndicator = null;
        },

        loadAssessments() {
            const loadResult = loadAssessments(this.assessments);
            this.currentAssessment = loadResult.currentAssessment;
            this.previousAssessments = loadResult.previousAssessments;
        },

        async fetchRiskMatrix() {
            this.isLoading = true;
            const response = await getRiskMatrix(this.currentAssessment.matrixKey);
            if (response.responseInfo.isError || !response.matrix) {
                /* this.promoteDialog.isError = true;
                this.promoteDialog.errorText = response.responseInfo.parsedError?.message || 'Unknown error'; */
            } else {
                console.log("matrix", response.matrix);
                this.matrixDefinition = response.matrix;
                this.riskAssets = await this.$assets.getRiskAssets();
            }
            this.isLoading = false;
        },

    },
    created() {
        this.loadAssessments();
        this.fetchRiskMatrix();
    },
    mounted() {
        const element = document.getElementById("aml-main");
        if (element) element.addEventListener("scroll", this.handleScroll);

        if (this.$config.settings.riskConfigs) {
            this.riskConfig = this.$config.settings.riskConfigs[0];
        }
    },
    unmounted() {
        const element = document.getElementById("aml-main");
        if (element) element.removeEventListener("scroll", this.handleScroll);
    },
});
</script>
<style>
.amlv3-atab {
    position: relative;
    display: flex;
    margin-top: 30px;
}

.amlv3-atab-left {
    flex-grow: 1;
    min-height: 1200px;
    margin-right: 20px;
    padding: 20px;
    border-radius: 8px;
    background-color: white;
}

.amlv3-atab-left-scrolled {
    margin-right: 520px;
}

.amlv3-atab-right {
    position: relative;
    flex-shrink: 0;
    width: 500px;
    height: calc(100vh - 170px);
    padding: 20px;
    border-radius: 8px;
    background-color: white;
}

.amlv3-atab-right-scrolled {
    position: fixed;
    top: 20px;
    right: 0px;
    height: calc(100vh - 40px);
    margin-right: 67px;
}
</style>
