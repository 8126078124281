<template>
    <div>

        <div
            class="srv3-step"
            v-for="(step, i) in steps"
            :key="'s' + i"
            :class="[
                { 'srv3-step-first': i === 0 },
                { 'srv3-step-last': i === renderData.steps.length - 1 },
            ]"
        >
            <div class="border-b border-gray-300 mx-5" v-if="i > 0" />

            <div class="srv3-step-content">

                <div
                    class="srv3-step-icon"
                    :class="[
                        { 'srv3-step-icon-completed': step.statusType === StatusType.Completed },
                        { 'srv3-step-icon-error': step.statusType === StatusType.Error },
                        { 'srv3-step-icon-warning': step.statusType === StatusType.Warning },
                    ]"
                >
                    <div
                        class="srv3-step-icon-text"
                        v-if="step.statusType === StatusType.Default || step.statusType === StatusType.InProgress"
                    >
                        {{ i + 1 }}
                    </div>
                    <div
                        class="srv3-step-icon-icon"
                        v-if="step.statusType === StatusType.Completed"
                    >
                        <FontIcon :icon="'check'" :size="20" />
                    </div>
                    <div
                        class="srv3-step-icon-icon"
                        v-if="step.statusType === StatusType.Error || step.statusType === StatusType.Warning"
                    >
                        <FontIcon :icon="'circle-exclamation'" :size="20" />
                    </div>
                </div>

                <div style="flex-grow: 1;">

                    <div>
                        <h4 class="text-base font-bold py-1">
                            {{ $ct(step.name) }}
                        </h4>
                    </div>

                    <div class="srv3-step-pills">

                        <div
                            class="srv3-step-pill"
                            v-for="(status, j) in step.pills"
                            :key="'p' + j"
                            :class="[
                                { 'srv3-step-pill-progress': status.type === StatusType.InProgress },
                                { 'srv3-step-pill-completed': status.type === StatusType.Completed },
                                { 'srv3-step-pill-error': status.type === StatusType.Error },
                                { 'srv3-step-pill-warning': status.type === StatusType.Warning },
                            ]"
                        >
                            <div
                                class="srv3-step-pill-dot"
                                v-if="status.type !== StatusType.Default"
                            />

                            <div class="srv3-step-pill-text">
                                {{ $ct(status.text) }}
                            </div>
                        </div>

                    </div>

                </div>

                <StepActions
                    :key="'sa' + i"
                    :step="step"
                    @action="$emit('action', $event)"
                />

            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

import { FlowRenderData, StatusType, StepRenderData } from "@/types/flow_types";
import FontIcon from "@/ui/FontIcon.vue";
import StepActions from "./StepActions.vue";

export default defineComponent({
    name: "StepRenderer",
    components: {
        FontIcon,
        StepActions,
    },
    emits: ["action"],
    props: {
        renderData: { type: Object as PropType<FlowRenderData>, required: true },
    },
    watch: {
        renderData: {
            deep: true,
            handler() {
                this.loadSteps();
            },
        },
    },
    data() {
        return {
            StatusType,

            steps: new Array<StepRenderData>(),
        };
    },
    methods: {

        loadSteps() {
            this.steps = [];
            this.$nextTick(() => {
                this.renderData.steps.forEach((step) => {
                    this.steps.push(step);
                });
            });
        },

    },
    mounted() {
        this.loadSteps();
    },
});
</script>
<style scoped>
.srv3-step {
    background-color: white;
}

.srv3-step-first {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.srv3-step-last {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.srv3-step-content {
    display: flex;
    padding-top: 15px;
    padding-left: 30px;
    padding-right: 15px;
    padding-bottom: 22px;
}

/* Icon */

.srv3-step-icon {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    margin-top: 12px;
    margin-right: 12px;
    border-radius: 50%;
    background-color: #F4F4F4;
}

.srv3-step-icon-text {
    padding-top: 10px;
    text-align: center;
    font-family: "Roboto Slab";
    font-size: 12px;
    font-weight: 600;
}

.srv3-step-icon-icon {
    padding-top: 6px;
    text-align: center;
}

.srv3-step-icon-completed {
    background-color: #E6F5FF;
}

.srv3-step-icon-completed .srv3-step-icon-icon {
    color: #218BCB;
}
.srv3-step-icon-error .srv3-step-icon-icon {
    color: #B62537;
}
.srv3-step-icon-warning .srv3-step-icon-icon {
    color: #F2CA61;
}

/* Statuses */

.srv3-step-pills {
    display: flex;
}

.srv3-step-pill {
    display: flex;
    height: 26px;
    margin-right: 10px;
    padding: 2px 10px;
    border-radius: 13px;
    background-color: #F4F4F4;
}

.srv3-step-pill-dot {
    width: 7px;
    height: 7px;
    margin-top: 7px;
    margin-right: 7px;
    border-radius: 50px;
    background-color: #C4C4C4;
}

.srv3-step-pill-text {
    font-size: 14px;
    color: #444444;
}

.srv3-step-pill-progress {
    border: solid 1px #D5D5D5;
    background-color: #FFFFFF;
}

.srv3-step-pill-completed {
    border: solid 1px #218BCB;
    background-color: #B1D8F0;
}
.srv3-step-pill-error {
    border: solid 1px #FFE2E5;
    background-color: #FFE2E5;
}
.srv3-step-pill-warning {
    border: solid 1px #F2CA61;
    background-color: #FFEDBC;
}

.srv3-step-pill-completed .srv3-step-pill-dot {
    background-color: #218BCB;
}
.srv3-step-pill-error .srv3-step-pill-dot {
    background-color: #B62537;
}
.srv3-step-pill-warning .srv3-step-pill-dot {
    background-color: #F2CA61;
}
</style>
