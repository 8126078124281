<template>
    <div class="fbav3-input-checkbox">
        <div
            class="flex items-center mb-2"
            v-for="(item, i) in items"
            :key="uniqueId + i"
        >

            <input
                :id="uniqueId + item.key"
                type="checkbox"
                value=""
                :name="uniqueId"
                :modelValue="modelValue[item.key]"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                @input="onChange($event, item)"
            >

            <label :for="uniqueId + item.key" class="ms-2 text-sm font-medium text-gray-900">{{ $ct(item.text)
            }}</label>

        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { CheckboxItem, CheckboxModel } from "./input_helper";

export default defineComponent({
    name: "InputCheckbox2",
    props: {
        modelValue: { type: Object as PropType<CheckboxModel>, required: true },
        items: { type: Array as PropType<CheckboxItem[]>, default: [] },
        uniqueId: { type: String as PropType<string>, required: true },
    },
    emits: ["update:modelValue"],
    data() {
        return {
            picked: "",
        };
    },
    watch: {
        modelValue: {
            deep: false,
            handler() {
                /* this.picked = this.modelValue; */
            },
        },
    },
    methods: {

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onChange(event: any, item: CheckboxItem) {
            const value = {} as CheckboxModel;
            for (const key in this.modelValue) {
                value[key] = this.modelValue[key];
                if (key === item.key) {
                    value[key] = event.target.checked;
                }
            }
            this.$emit("update:modelValue", value);
        },

    },
    mounted() {
        /* this.picked = this.modelValue; */
    },
});
</script>
<style scoped>
.fbav3-input-checkbox input[type='checkbox'] {
    transform: scale(1.4);
    margin-left: 3px;
}
</style>
