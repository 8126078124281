import i18n from "@/i18n";
import { Address, Person } from "@/lib/persons/person";
import { RoleCode, RoleDescription } from "@/lib/role/Role";

export const getRoleSortOrder = (roleCode: RoleCode): number => {
    switch (roleCode) {
    case RoleCode.Owner: return 150;
    case RoleCode.Boardmember: return 50;
    case RoleCode.DeputyBoardmember: return 51;
    case RoleCode.Ceo: return 20;
    case RoleCode.DeputyCeo: return 21;
    case RoleCode.Proprietor: return 25;
    case RoleCode.Contact: return 90;
    case RoleCode.Chairman: return 30;
    case RoleCode.DeputyChairman: return 31;
    case RoleCode.Accountant: return 70;
    case RoleCode.Auditor: return 80;
    case RoleCode.Shareholder: return 160;
    case RoleCode.BeneficialOwner: return 40;
    case RoleCode.Custom: return 100;
    case RoleCode.Other: return 500;
    case RoleCode.Unknown: return 600;
    }
    return 1000;
};

export const getRoleDisplayText = (role: RoleDescription): string => {
    const { t } = i18n.global;
    switch (role.key) {
    case RoleCode.Owner: return t("core.roles.owner");
    case RoleCode.Boardmember: return t("core.roles.boardmember");
    case RoleCode.DeputyBoardmember: return t("core.roles.deputy_boardmember");
    case RoleCode.Ceo: return t("core.roles.ceo");
    case RoleCode.DeputyCeo: return t("core.roles.deputy_ceo");
    case RoleCode.Proprietor: return t("core.roles.proprietor");
    case RoleCode.Contact: return t("core.roles.contact");
    case RoleCode.Chairman: return t("core.roles.chairman");
    case RoleCode.DeputyChairman: return t("core.roles.deputy_chairman");
    case RoleCode.Accountant: return t("core.roles.accountant");
    case RoleCode.Auditor: return t("core.roles.auditor");
    case RoleCode.Shareholder: return t("core.roles.shareholder");
    case RoleCode.BeneficialOwner: return t("core.roles.beneficial_owner");
    case RoleCode.Custom: return role.name || t("core.roles.unknown");
    case RoleCode.Other: return t("core.roles.other");
    case RoleCode.Unknown: return t("core.roles.unknown");
    }
    return t("core.roles.unknown");
};

export const getAddressDisplayText = (address: Address): string => {
    const { t } = i18n.global;
    if (address.street && address.zip && address.town && address.country) {
        return `${address.street}, ${address.zip} ${address.town} - ${t("countries." + address.country)}`;
    } else if (address.street && address.zip && address.town) {
        return `${address.street}, ${address.zip} ${address.town}`;
    } else if (address.street && address.town && address.country) {
        return `${address.street}, ${address.town} - ${t("countries." + address.country)}`;
    } else if (address.country) {
        return t("countries." + address.country);
    }
    return `${address.street}, ${address.zip} ${address.town} - ${t("countries." + address.country)}`;
};

export const getCountryDisplayText = (country: string): string => {
    const { t } = i18n.global;
    return t("countries." + country.toUpperCase());
};

/* export const sourcesAndCountForPersonField = (person: Person, path: string): string[] => {
    const sources: string[] = [];
    // ["person_info", "name"]
    const nodes = path.split(".");

    // find the *selected* value of the specified property
    const val = nodes.reduce((prev, next) => (prev ? prev[next] : undefined), person.data as any);

    if (val) {
        // we only care about blocks of same type as the block name in the path
        const blocks = (person.blocks || []).filter((x) => x._block === nodes[0]);

        // remove block name since the blocks themselves don't include that as a property
        nodes.shift();

        for (const block of blocks) {
            // find the value of the property in this specific block
            const blockVal = nodes.reduce((prev, next) => (prev ? prev[next] : undefined), block as any);

            // values are the same so the selected value is considered to be derived from this block
            if (blockVal) {
                sources.push(block._source);
            }
        }
    }
    return sources;
}; */
