export enum ApprovalStatus {
    Open = "open",
    Approved = "approved",
    Denied = "denied",
    Aborted = "aborted",
}

export interface Approver {
    uid: string;
    givenName: string;
    familyName: string;
    email: string;
    decision: ApprovalStatus;
    decidedAt?: number;
    comment?: string;
}

export interface Approval {
    id: string;
    key: string;
    selected: boolean;
    status: ApprovalStatus;
    approvers: Approver[];
    createdAt: number;
}
