<template>
    <button
        @click="clicked"
        :class="{
            ...color,
            clickable: !disabled && !loading,
            loading,
            disabled,
        }"
    >
        <div v-show="loading" class="spinner">
            <Icon class="w-6 h-6 opacity-70" icon="tabler:loader-2" />
        </div>
        <span
            :class="{
                'opacity-0': loading,
            }"
        ><slot></slot></span>
    </button>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue";
import Icon from "./Icon.vue";

export default defineComponent({
    name: "ActionButton",
    components: {
        Icon,
    },
    props: {
        disabled: Boolean,
        loading: Boolean,
        theme: String as PropType<"primary" | "danger" | "transparent">,
    },
    computed: {
        interactable(): boolean {
            return !this.disabled && !this.loading;
        },
        color() {
            switch (this.theme) {
            case "primary":
                return { "bg-primary-default": true, "hover:bg-primary-dark": this.interactable };
            case "danger":
                return { "bg-red-600": true, "hover:bg-red-700": this.interactable };
            case "transparent":
                return { "bg-gray-300": true, "hover:bg-gray-300": this.interactable };
            default:
                return { "bg-primary-default": true, "hover:bg-primary-dark": this.interactable };
            }
        },
    },
    methods: {
        clicked(event: MouseEvent) {
            if (this.disabled || this.loading) {
                return;
            }
            this.$emit("btnClicked", event);
        },
    },
});
</script>

<style lang="scss" scoped>
button {
    @apply py-3 px-8 rounded-full text-white relative border-none;
}
.clickable {
    @apply hover:cursor-pointer;
}

.loading {
    @apply hover:cursor-default;
}

.disabled {
    @apply opacity-40 hover:cursor-not-allowed;
}

.spinner {
    @apply absolute left-1/2 top-1/2;
    transform: translateX(-50%) translateY(-50%);
}
</style>
