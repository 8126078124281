<template>
    <div>

        <div class="riskv3-list-content">

            <div
                class="riskv3-list-empty"
                v-if="indicator.values.length === 0"
            >
                {{ 'No values for this indicator' }}
            </div>

            <div
                class="riskv3-list-value"
                v-for="(value, i) in indicator.values"
                :key="value.key"
                :class="[
                    { 'riskv3-list-value-first': i === 0 },
                ]"
            >

                <div class="riskv3-list-name">

                    <div
                        class="riskv3-list-value-type"
                        v-if="value.mode === RiskValueMode.Match"
                    >

                        <div class="riskv3-list-value-type-name">
                            {{ 'Free Text Match' }}
                        </div>

                        <div class="riskv3-list-value-type-range">
                            <div
                                class="riskv3-list-value-type-match-op"
                                @click="showMatchDialog(value.key)"
                            >
                                {{ getMatchOperatorText(value) }}
                            </div>
                            <div
                                class="riskv3-list-value-type-match-list"
                                @click="showMatchListDialog(value)"
                            >
                                {{ getMatchListText(value) }}
                            </div>
                        </div>

                    </div>

                    <div v-if="value.mode === RiskValueMode.Option">

                        {{ $ct(value.name) }}

                    </div>

                    <div
                        class="riskv3-list-value-type"
                        v-if="value.mode === RiskValueMode.Range"
                    >

                        <div class="riskv3-list-value-type-name">
                            {{ 'Number Range' }}
                        </div>

                        <div
                            class="riskv3-list-value-type-range"
                            v-if="value.from != undefined"
                        >
                            <div
                                class="riskv3-list-value-type-range-op"
                                @click="showRangeDialog(value.key, false)"
                            >
                                {{ getRangeOperatorText(value, false) }}
                            </div>
                            <input v-model="value.from" />
                        </div>
                        <div
                            class="riskv3-list-value-type-range-empty"
                            v-if="value.from == undefined"
                            @click="showRangeDialog(value.key, false)"
                        >
                            {{ '- - -' }}
                        </div>

                        <div class="riskv3-list-value-type-range-sep">
                            {{ '-' }}
                        </div>

                        <div
                            class="riskv3-list-value-type-range"
                            v-if="value.to != undefined"
                        >
                            <div
                                class="riskv3-list-value-type-range-op"
                                @click="showRangeDialog(value.key, true)"
                            >
                                {{ getRangeOperatorText(value, true) }}
                            </div>
                            <input v-model="value.to" />
                        </div>
                        <div
                            class="riskv3-list-value-type-range-empty"
                            v-if="value.to == undefined"
                            @click="showRangeDialog(value.key, true)"
                        >
                            {{ '- - -' }}
                        </div>

                    </div>

                    <div class="riskv3-list-value-actions">
                        <div class="riskv3-list-value-action riskv3-list-value-action-first">
                            <FontIcon
                                :icon="'circle-arrow-up'"
                                :size="15"
                            />
                        </div>
                        <div class="riskv3-list-value-action">
                            <FontIcon
                                :icon="'circle-arrow-down'"
                                :size="15"
                            />
                        </div>
                        <div
                            class="riskv3-list-value-action"
                            @click="onDeleteValue(value)"
                        >
                            <FontIcon
                                :icon="'trash-can'"
                                :size="15"
                            />
                        </div>
                        <div
                            class="riskv3-list-value-action riskv3-list-value-action-last"
                            @click="showEditNameDialog(value)"
                        >
                            <FontIcon
                                :icon="'pen'"
                                :size="15"
                            />
                        </div>
                    </div>

                </div>

                <div class="riskv3-list-score">
                    <RiskScoreControl
                        :score="getScore(value)"
                        :score-key="value.key"
                        @score-changed="onScoreChanged"
                    />
                </div>

            </div>

            <div class="riskv3-list-actions">
                <RiskTextButton
                    v-if="!indicator.isFixed"
                    :text="'Add Value'"
                    @clicked="showAddValueDialog"
                />
            </div>

        </div>

        <EditNameDialog
            v-if="nameDialog.visible"
            :has-key="true"
            :existing-key="nameDialog.key"
            :existing-name="nameDialog.name"
            @close-modal="nameDialog.visible = false"
            @save-modal="onAddValue"
        />

        <PopupDialog
            :is-visible="rangeDialog.visible"
            :header="'Select Operator'"
            :icon="'plus'"
            :show-cancel-button="true"
            @close-modal="rangeDialog.visible = false"
        >
            <div style="max-width: 500px;">
                <SimpleListItem
                    :text="'None'"
                    is-first
                    @clicked="onChangeRangeOperator('none')"
                />
                <SimpleListItem
                    :text="'Greater Than'"
                    v-if="!rangeDialog.isTo"
                    @clicked="onChangeRangeOperator('gt')"
                />
                <SimpleListItem
                    :text="'Greater Than or Equal'"
                    v-if="!rangeDialog.isTo"
                    is-last
                    @clicked="onChangeRangeOperator('gte')"
                />
                <SimpleListItem
                    :text="'Less Than'"
                    v-if="rangeDialog.isTo"
                    @clicked="onChangeRangeOperator('lt')"
                />
                <SimpleListItem
                    :text="'Less Than or Equal'"
                    v-if="rangeDialog.isTo"
                    is-last
                    @clicked="onChangeRangeOperator('lte')"
                />
            </div>
        </PopupDialog>

        <PopupDialog
            :is-visible="matchDialog.visible"
            :header="'Select Operator'"
            :icon="'plus'"
            :show-cancel-button="true"
            @close-modal="matchDialog.visible = false"
        >
            <div style="max-width: 500px;">
                <SimpleListItem
                    :text="'Matching'"
                    is-first
                    @clicked="onChangeMatchOperator('matches')"
                />
                <SimpleListItem
                    :text="'Includes'"
                    is-last
                    @clicked="onChangeMatchOperator('includes')"
                />
            </div>
        </PopupDialog>

        <PopupDialog
            :is-visible="matchListDialog.visible"
            :header="'Free Text Values'"
            :icon="'plus'"
            :show-cancel-button="true"
            :show-action-button="true"
            :action-text="'Save'"
            @close-modal="matchListDialog.visible = false"
            @action="onSaveMatchList"
        >
            <div style="max-width: 500px;">
                <div
                    class="riskv3-list-match-value"
                    v-for="(value, i) in matchListDialog.values"
                    :key="'rmv' + i"
                >
                    <input v-model="matchListDialog.values[i]" />
                    <div class="riskv3-list-match-value-delete">
                        <FontIcon
                            :icon="'trash-can'"
                            :size="15"
                        />
                    </div>
                </div>
                <div>
                    <ButtonSingle
                        :is-link="true"
                        :text="'Add value'"
                        @clicked="addMatchListValue"
                    />
                </div>
            </div>
        </PopupDialog>

    </div>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";

import { RiskIndicatorDefinition, RiskValue, RiskValueMatchType, RiskValueMode, RiskValueType } from "@/lib/risk/risk_matrix";

import { LocalizedString } from "@/lib/common/common";
import ButtonSingle from "@/ui/ButtonSingle.vue";
import FontIcon from "@/ui/FontIcon.vue";
import PopupDialog from "@/ui/PopupDialog.vue";
import SimpleListItem from "@/ui/SimpleListItem.vue";
import EditNameDialog, { EditNameResult } from "../EditNameDialog.vue";
import { getRandomString, RiskScoreResult } from "../risk_helper";
import RiskScoreControl from "./RiskScoreControl.vue";
import RiskTextButton from "./RiskTextButton.vue";

export default defineComponent({
    name: "RiskValueList",
    components: {
        RiskTextButton,
        RiskScoreControl,
        EditNameDialog,
        FontIcon,
        PopupDialog,
        SimpleListItem,
        ButtonSingle,
    },
    emits: ["changed", "score-changed"],
    props: {
        indicator: { type: Object as PropType<RiskIndicatorDefinition>, required: true },
    },
    data() {
        return {
            RiskValueMode,

            nameDialog: { visible: false, key: "", name: null as LocalizedString | null },
            rangeDialog: { visible: false, key: "", isTo: false },
            matchDialog: { visible: false, key: "" },
            matchListDialog: { visible: false, key: "", values: new Array<string>() },
        };
    },
    methods: {

        showEditNameDialog(value: RiskValue) {
            this.nameDialog.key = value.key;
            this.nameDialog.name = value.name || null;
            this.nameDialog.visible = true;
        },

        onDeleteValue(value: RiskValue) {
            for (const i in this.indicator.values) {
                if (this.indicator.values[i].key === value.key) {
                    // eslint-disable-next-line vue/no-mutating-props
                    this.indicator.values.splice(Number(i), 1);
                }
            }
        },

        showAddValueDialog() {
            if (this.indicator.valueType === RiskValueType.MultipleChoice) {
                this.nameDialog.key = getRandomString(8);
                this.nameDialog.visible = true;
            } else if (this.indicator.valueType === RiskValueType.FreeText) {
                const riskValue: RiskValue = {
                    key: getRandomString(8),
                    mode: RiskValueMode.Match,
                    matchType: RiskValueMatchType.Matches,
                    matchList: ["value"],
                };
                // eslint-disable-next-line vue/no-mutating-props
                this.indicator.values.push(riskValue);
            } else {
                const riskValue: RiskValue = {
                    key: getRandomString(8),
                    mode: RiskValueMode.Range,
                };
                // eslint-disable-next-line vue/no-mutating-props
                this.indicator.values.push(riskValue);
            }
        },

        onAddValue(result: EditNameResult) {
            const riskValue: RiskValue = {
                key: result.key,
                mode: RiskValueMode.Option,
                name: result.name,
            };
            // eslint-disable-next-line vue/no-mutating-props
            this.indicator.values.push(riskValue);
            this.$emit("changed");
        },

        showRangeDialog(key: string, isTo: boolean) {
            this.rangeDialog.key = key;
            this.rangeDialog.isTo = isTo;
            this.rangeDialog.visible = true;
        },

        onChangeRangeOperator(operator: string) {
            this.rangeDialog.visible = false;
            let value = null;
            for (const i in this.indicator.values) {
                if (this.indicator.values[i].key === this.rangeDialog.key) {
                    value = this.indicator.values[i];
                }
            }
            if (!value) return;
            if (operator === "none") {
                if (this.rangeDialog.isTo) {
                    value.to = undefined;
                    value.toEqual = undefined;
                } else {
                    value.from = undefined;
                    value.fromEqual = undefined;
                }
            }
            if (operator === "lt") {
                value.toEqual = false;
                if (value.to === undefined) value.to = 0;
            }
            if (operator === "lte") {
                value.toEqual = true;
                if (value.to === undefined) value.to = 0;
            }
            if (operator === "gt") {
                value.fromEqual = false;
                if (value.from === undefined) value.from = 0;
            }
            if (operator === "gte") {
                value.fromEqual = true;
                if (value.from === undefined) value.from = 0;
            }
        },

        getRangeOperatorText(value: RiskValue, isTo: boolean) {
            if (isTo) {
                return value.toEqual ? "<=" : "<";
            } else {
                return value.toEqual ? ">=" : ">";
            }
        },

        showMatchDialog(key: string) {
            this.matchDialog.key = key;
            this.matchDialog.visible = true;
        },

        onChangeMatchOperator(operator: string) {
            this.matchDialog.visible = false;
            let value = null;
            for (const i in this.indicator.values) {
                if (this.indicator.values[i].key === this.matchDialog.key) {
                    value = this.indicator.values[i];
                }
            }
            if (!value) return;
            value.matchType = operator as RiskValueMatchType;
        },

        getMatchOperatorText(value: RiskValue) {
            return value.matchType === RiskValueMatchType.Includes ? "Includes" : "Matches";
        },

        showMatchListDialog(value: RiskValue) {
            this.matchListDialog.key = value.key;
            this.matchListDialog.values = value.matchList || [];
            this.matchListDialog.visible = true;
        },

        addMatchListValue() {
            this.matchListDialog.values.push("");
        },

        onSaveMatchList() {
            this.matchListDialog.visible = false;
            let value = null;
            for (const i in this.indicator.values) {
                if (this.indicator.values[i].key === this.matchListDialog.key) {
                    value = this.indicator.values[i];
                }
            }
            if (!value) return;
            value.matchList = this.matchListDialog.values;
        },

        getMatchListText(value: RiskValue) {
            let str = "";
            for (const i in value.matchList) {
                if (Number(i) > 0) str += ", ";
                str += value.matchList[Number(i)];
            }
            return str;
        },

        onScoreChanged(scoreKey: string, newScore: number) {
            this.$emit("score-changed", scoreKey, newScore);
        },

        getScore(value: RiskValue): RiskScoreResult {
            if (this.indicator.scores[value.key] !== undefined) {
                return {
                    score: this.indicator.scores[value.key],
                    isIndirect: false,
                };
            }
            return {
                score: -1,
                isIndirect: true,
            };
        },

    },
});
</script>
<style scoped>
.riskv3-list-content {
    min-height: 150px;
}

.riskv3-list-empty {
    padding: 20px 30px;
    font-size: 15px;
    font-style: italic;
    color: gray
}

.riskv3-list-value {
    display: flex;
    border-top: solid 1px lightgray;
}

.riskv3-list-value-first {
    border-top: none;
}

.riskv3-list-value:hover {
    background-color: whitesmoke;
}

.riskv3-list-name {
    position: relative;
    flex-grow: 1;
    padding: 3px 10px;
    padding-top: 4px;
}

.riskv3-list-score {
    padding-top: 4px;
    padding-right: 8px;
}

.riskv3-list-value-actions {
    position: absolute;
    top: 4px;
    left: 8px;
    display: none;
}

.riskv3-list-value:hover .riskv3-list-value-actions {
    display: flex;
}

.riskv3-list-value-type {
    display: flex;
}

.riskv3-list-value-type-name {
    flex-grow: 1;
    font-size: 15px;
}

.riskv3-list-value-type-match-op {
    height: 22px;
    padding: 3px 10px;
    font-size: 14px;
    line-height: 14px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border: solid 1px lightgray;
    border-right: none;
    background-color: whitesmoke;
    cursor: pointer;
}

.riskv3-list-value-type-match-op:hover {
    background-color: lightgray;
}

.riskv3-list-value-type-match-list {
    max-width: 160px;
    height: 22px;
    padding: 3px 10px;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    color: #b13707;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border: solid 1px lightgray;
    background-color: whitesmoke;
    cursor: pointer;
}

.riskv3-list-value-type-match-list:hover {
    background-color: lightgray;
}

.riskv3-list-value-type-range {
    display: flex
}

.riskv3-list-value-type-range-op {
    width: 30px;
    height: 22px;
    padding-top: 3px;
    text-align: center;
    font-size: 14px;
    line-height: 14px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border: solid 1px lightgray;
    border-right: none;
    background-color: whitesmoke;
    cursor: pointer;
}

.riskv3-list-value-type-range-op:hover {
    background-color: lightgray;
}

.riskv3-list-value-type-range input {
    width: 90px;
    height: 22px;
    padding: 0px 5px;
    font-size: 14px;
    line-height: 14px;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
    color: #007910;
    text-align: right;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border: solid 1px lightgray;
}

.riskv3-list-value-type-range-empty {
    width: 120px;
    height: 22px;
    line-height: 20px;
    color: lightgray;
    text-align: center;
    border-radius: 4px;
    border: solid 1px lightgray;
    background-color: whitesmoke;
    cursor: pointer;
}

.riskv3-list-value-type-range-empty:hover {
    background-color: lightgray;
}

.riskv3-list-value-type-range-sep {
    padding: 0px 10px;
}

/* Value Actions */

.riskv3-list-value-action {
    width: 26px;
    height: 22px;
    text-align: center;
    border: solid 1px lightgray;
    border-left: none;
    background-color: whitesmoke;
    cursor: pointer;
}

.riskv3-list-value-action:hover {
    background-color: lightgrey;
}

.riskv3-list-value-action-first {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-left: solid 1px lightgray;
}

.riskv3-list-value-action-last {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

/* Bottom Actions */

.riskv3-list-actions {
    padding: 8px 8px;
    border-top: solid 1px lightgray;
}

/* Dialogs */

.riskv3-list-match-value {
    width: 100%;
    margin-bottom: 5px;
    display: flex;
}

.riskv3-list-match-value input {
    flex-grow: 1;
    width: 100%;
    padding: 5px 10px;
    font-size: 16px;
    font-weight: 500;
    color: #b13707;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border: solid 1px lightgray;
}

.riskv3-list-match-value-delete {
    width: 34px;
    padding-top: 6px;
    text-align: center;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border: solid 1px lightgray;
    border-left: none;
    background-color: whitesmoke;
    cursor: pointer;
}

.riskv3-list-match-value-delete:hover {
    background-color: lightgray;
}
</style>
