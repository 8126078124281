<template>
    <div>
        <CustomerTopAction
            :icon="'user'"
            :text="assigneeName"
            :tooltip="$t('project.assignee.title')"
            :is-clickable="true"
            @clicked="showDialog"
        />

        <PopupDialog
            :is-visible="dialog.visible"
            :header="$t('project.assignee.changeAction')"
            :icon="'user'"
            :progress="dialog"
            :show-cancel-button="true"
            :show-action-button="true"
            :action-icon="'repeat'"
            :is-action-disabled="selectedAssignee?.uid === project.assignee"
            :action-text="$t('project.assignee.changeAction')"
            :is-warning="false"
            @close-modal="dialog.visible = false"
            @action="updateAssignee"
        >
            <div class="space-y-2">
                <div>
                    <InputText
                        v-model="searchText"
                        unique-id="assigneeSearchText"
                        :placeholder="$t('common.searchEmployee')"
                    />
                </div>
                <div class="overflow-y-auto h-[400px]">
                    <SimpleListItem
                        v-for="(employee, i) in filteredEmployees"
                        :key="employee.uid"
                        :text="employee.name"
                        :sub-text="employee.email"
                        :is-selected="employee.uid === selectedAssignee?.uid"
                        :is-first="i === 0"
                        :is-last="i === employees.length - 1"
                        @clicked="selectAssignee(employee.uid)"
                    />
                </div>
            </div>
        </popupdialog>
    </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { Project } from "@/lib/projects/projects";
import { UpdateProjectOptions, updateProject } from "@/lib/projects/update_project";
import CustomerTopAction from "./CustomerTopAction.vue";
import PopupDialog from "@/ui/PopupDialog.vue";
import { getProjectProcess } from "@/lib/projects/get_project";
import SimpleListItem from "@/ui/SimpleListItem.vue";
import InputText from "@/ui/inputs/InputText.vue";

interface Employee {
    name: string;
    email: string;
    uid: string;
}

export default defineComponent({
    name: "AssigneeSelector",
    components: {
        CustomerTopAction,
        PopupDialog,
        SimpleListItem,
        InputText,
    },
    emits: ["request-project-refresh"],
    props: {
        project: { type: Object as PropType<Project>, required: true },
    },
    data() {
        return {
            dialog: { visible: false, isWorking: false, statusText: "", isError: false, errorText: "" },
            employees: [] as Employee[],
            selectedAssignee: {} as Employee | null,
            filteredEmployees: [] as Employee[],
            searchText: "",
        };
    },
    computed: {
        assigneeName(): string {
            const currentAssignee = this.employees.find(x => x.uid === this.project.assignee);
            return currentAssignee ? currentAssignee.name : this.$t("project.assignee.unknown");
        },
    },
    watch: {
        searchText(searchText: string) {
            this.filterEmployees(searchText);
        },
    },
    methods: {
        selectAssignee(assignee: string) {
            this.selectedAssignee = this.filteredEmployees.find((x:Employee) => x.uid === assignee) ?? null;
        },
        showDialog() {
            this.selectedAssignee = this.project.assignee ? this.employees.find((x:Employee) => x.uid === this.project.assignee) ?? null : null;
            this.dialog.visible = true;
        },

        filterEmployees(searchText: string) {
            if (!this.employees) {
                return;
            }
            if (searchText === "") {
                this.filteredEmployees = this.employees;
            } else {
                this.filteredEmployees = this.employees.filter(employee => employee.name.toLowerCase().includes(searchText.toLowerCase()) ||
                employee.email.toLowerCase().includes(searchText.toLowerCase()));
            }
        },

        async updateAssignee() {
            if (!this.selectedAssignee) {
                return;
            }
            this.dialog.isError = false;
            this.dialog.isWorking = true;
            this.dialog.statusText = this.$t("project.assignee.working");
            const options: UpdateProjectOptions = {
                assignee: this.selectedAssignee.uid,
            };
            const response = await updateProject(this.project.id, options);
            if (response.responseInfo.isError) {
                this.dialog.errorText = response.responseInfo.parsedError?.message || "Unknown error";
                this.dialog.isError = true;
            } else {
                const projectResponse = await getProjectProcess(this.project.id);
                this.$emit("request-project-refresh", projectResponse);
                this.dialog.visible = false;
            }
            this.dialog.isWorking = false;
        },
    },
    async mounted() {
        const employeesData = await this.$assets.getEmployees();
        this.employees = employeesData.map(x => ({ name: `${x.givenName} ${x.familyName}`, email: x.email, uid: x.uid }));
        this.filteredEmployees = this.employees;
        this.selectedAssignee = this.project.assignee && this.employees ? this.employees.find((x: Employee) => x.uid === this.project.assignee) ?? null : null;
    },
});
</script>
