<template>
    <div class="riskv3-sidebar">

        <div class="riskv3-sidebar-top" />

        <div
            class="riskv3-sidebar-customer"
            v-for="(customer, i) in customers"
            :key="'rsb' + i"
        >
            <!-- {{ customer.key }} -->
            <FontIcon :icon="'building'" :size="22" />
        </div>

        <div class="riskv3-sidebar-btn" @click="$emit('add')">
            <FontIcon :icon="'plus'" :size="22" />
        </div>

        <!-- <div class="riskv3-sidebar-btn">
            <FontIcon :icon="'paste'" :size="22" />
        </div> -->

        <div
            class="riskv3-sidebar-btn"
            :class="[
                {'riskv3-sidebar-btn-warning': isOutdated },
            ]"
            @click="$emit('promote')"
        >
            <FontIcon :icon="'code-pull-request'" :size="22" />
        </div>

        <div
            class="riskv3-sidebar-btn riskv3-sidebar-btn-corner"
            :class="[
                {'riskv3-sidebar-btn-warning': isEdited },
            ]"
            @click="$emit('save')"
        >
            <FontIcon :icon="'floppy-disk'" :size="22" />
        </div>

    </div>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";

import FontIcon from "@/ui/FontIcon.vue";

interface TestCustomer {
    key: string;
}

export default defineComponent({
    name: "RiskSidebar",
    components: {
        FontIcon,
    },
    emits: ["add", "save", "promote"],
    props: {
        isEdited: { type: Boolean as PropType<boolean>, required: true },
        isOutdated: { type: Boolean as PropType<boolean>, required: true },
    },
    data() {
        return {
            customers: new Array<TestCustomer>(),
        };
    },
    mounted() {
        for (let i = 0; i < 5; i += 1) {
            this.customers.push({
                key: i.toString(),
            });
        }
    },
});
</script>
<style scoped>
.riskv3-sidebar {
    position: fixed;
    top: 40px;
    right: 16px;
}

.riskv3-sidebar-top {
    width: 60px;
    height: 20px;
    border-top-left-radius: 8px;
    background-color: #218BCB;
}

.riskv3-sidebar-customer {
    height: 70px;
    padding-top: 20px;
    text-align: center;
    color: lightgray;
    border-left: solid 1px lightgray;
    border-top: solid 1px lightgray;
}

.riskv3-sidebar-btn {
    height: 48px;
    padding-top: 7px;
    text-align: center;
    color: white;
    border-bottom: solid 1px white;
    background-color: #218BCB;
    cursor: pointer;
}

.riskv3-sidebar-btn:hover {
    opacity: 0.7;
}

.riskv3-sidebar-btn-corner {
    border-bottom-left-radius: 8px;
    border-bottom: none;
}

.riskv3-sidebar-btn-warning {
    background-color: #d8b52b;
}
</style>
