<template>
    <router-view v-if="!loading" />
    <div v-if="loading" class="mt-[50vh] translate-y-[-50%]">
        <!-- 45 is same size as the one used to load the web-app -->
        <SpinnerVerified :size="45" />
    </div>
</template>
<script lang="ts">
/* eslint-disable vue/require-default-prop */

import { defineComponent } from "vue";
import { querySettings } from "./lib/settings/get_settings";
import store from "./store";

import SpinnerVerified from "./ui/SpinnerVerified.vue";
import { fetchGlobalAssets } from "./assets";

export default defineComponent({
    name: "App",
    components: {
        SpinnerVerified,
    },
    props: {
        path: String,
        resourcesPath: String,
        data: null,
        element: String,
        theme: String,
        services: null,
    },
    data() {
        return {
            loading: true,
            store,
        };
    },
    methods: {
        // It was simply too annoying to deal with this in the web-app itself so we just do it here...
        toggleNavbarActive(state: boolean) {
            document.querySelectorAll<HTMLElement>(".nav-aml").forEach((el) => {
                state ? el.classList.add("active") : el.classList.remove("active");
            });
        },

        async loadSettings() {
            // todo: move into assets
            const rawSettings = await querySettings();
            this.$config.update(rawSettings);

            const globalFetchResult = await fetchGlobalAssets();
            this.$assets.updateGlobal(globalFetchResult);

            this.loading = false;
        },
    },
    computed: {
        hideNav(): boolean {
            return this.$route.meta?.hidenav === true;
        },
    },
    mounted() {
        /* console.log("aml-spa mounted");
        console.log("props", this.$props.path, this.$props.resourcesPath, this.$props.data, this.$props.element, this.$props.theme); */

        this.toggleNavbarActive(true);
    },
    unmounted() {
        /* console.log("aml-spa unmounted"); */
        this.toggleNavbarActive(false);
    },
    created() {
        store.services = this.services;
        this.loadSettings();

        this.$router.beforeResolve((to, from, next) => {
            if (to.path === "/aml") {
                to.path = "/aml/overview";
                next({ path: to.path, query: to.query });
                return;
            }

            let companyId = store.services?.CompanyService.getInstance().getCurrent()?.id;

            if (!companyId) {
                // try to get from url if CompanyService does not have it
                const qs = location.href.split("?");
                /* console.log("qs", qs); */
                const urlParams = new URLSearchParams(qs[qs.length - 1]);
                /* console.log("urlParams", urlParams); */
                const id = urlParams.get("companyId");

                if (id) {
                    companyId = id;
                }
            }

            if (!companyId) {
                // if we still don't know the company id, send the user to the select company page
                location.href = "/#/companies";
                return;
            }

            if (!to.query.companyId) {
                to.query.companyId = companyId;
                next({ path: to.path, query: to.query });
            } else {
                next();
            }
        });
    },
});
</script>
