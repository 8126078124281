import { withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-491701cc"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "value", "placeholder"]
const _hoisted_2 = ["id", "type", "value", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isMultiline)
    ? (_openBlock(), _createElementBlock("textarea", {
        key: 0,
        class: "fbav3-input-text",
        id: _ctx.uniqueId,
        value: _ctx.modelValue,
        placeholder: _ctx.placeholder,
        onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
        onKeyup: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onEnterClicked && _ctx.onEnterClicked(...args)), ["enter"])),
        rows: 5
      }, null, 40, _hoisted_1))
    : (_openBlock(), _createElementBlock("input", {
        key: 1,
        class: "fbav3-input-text",
        id: _ctx.uniqueId,
        type: _ctx.isPassword ? 'password' : '',
        value: _ctx.modelValue,
        placeholder: _ctx.placeholder,
        onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
        onKeyup: _cache[3] || (_cache[3] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onEnterClicked && _ctx.onEnterClicked(...args)), ["enter"]))
      }, null, 40, _hoisted_2))
}