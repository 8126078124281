import { withKeys as _withKeys, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-22ec6bd9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fbav3-input-text-loc" }
const _hoisted_2 = ["id", "value"]
const _hoisted_3 = { class: "fbav3-input-text-loc-locales" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Flag = _resolveComponent("Flag")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      id: _ctx.uniqueId,
      value: _ctx.activeValue,
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
      onKeyup: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onEnterClicked && _ctx.onEnterClicked(...args)), ["enter"]))
    }, null, 40, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.locales, (locale, i) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["fbav3-input-text-loc-locale", [
                    { 'fbav3-input-text-loc-last': i === _ctx.locales.length - 1 },
                    { 'fbav3-input-text-loc-active': locale.key === _ctx.localeKey },
                ]]),
          key: _ctx.uniqueId + locale.alpha2,
          onClick: ($event: any) => (_ctx.onLocaleClicked(locale.key))
        }, [
          _createVNode(_component_Flag, {
            country: locale.alpha2,
            class: "w-[22px]"
          }, null, 8, ["country"])
        ], 10, _hoisted_4))
      }), 128))
    ])
  ]))
}