import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = { class: "flex-1 text-lg font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.text), 1),
    (!_ctx.hideIcon)
      ? (_openBlock(), _createBlock(_component_Icon, {
          key: 0,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('clicked'))),
          class: _normalizeClass('w-6 cursor-pointer text-primary-default hover:text-primary-dark' +
            (_ctx.working ? ' animate-spin' : '')
            ),
          icon: _ctx.working ? 'tabler:loader-2' : 'aml:round'
        }, null, 8, ["class", "icon"]))
      : _createCommentVNode("", true)
  ]))
}