<template>
    <div class="h-[13rem] w-full bg-white py-5 px-12">
        <div class="h-full">
            <div class="flex items-center justify-between h-1/2">
                <div class="flex items-center gap-2">
                    <Icon
                        icon="leftArrow"
                        @click="$router.back()"
                        class="cursor-pointer w-8 h-8 p-1.5 mr-2 fill-primary-default"
                    />
                    <Icon icon="person-blue" class="cursor-pointer w-8 h-8" />
                    <label class="text-3xl font-roboto-slab font-bold">{{ person.data?.person_info?.givenName }} {{ person.data?.person_info?.familyName }}</label>
                    <!-- <Badge class="bg-primary-hover-blue ml-4" fillColor="fill-primary-default" text="4 updates"></Badge>-->
                </div>
                <!--
                <div class="flex items-center gap-3">
                    <div v-if="headerExtraOptions" class="flex gap-2 transition-all duration-900 ease-in-out">
                        <Icon icon="edit-note" class="cursor-pointer w-4 h-5" />
                        <Icon icon="tag" class="cursor-pointer w-4 h-5" />
                        <Icon icon="attachment" class="cursor-pointer w-4 h-5" />
                    </div>
                    <Icon
                        :icon="headerExtraOptions ? 'cross-round' : 'plus-round'"
                        @click="headerExtraOptions = !headerExtraOptions"
                        class="cursor-pointer w-10 h-10"
                    />

                    <CustomButton
                        text="Generate Report"
                        class="border border-primary-default font-medium text-base box-border"
                        :icon="'download'"
                        textColor="primary-default"
                        roundStyle="3xl"
                    ></CustomButton>
                    <DropdownMenu
                        :options="[
                            { label: 'Edit', value: 'edit', icon: '' },
                            { label: 'Share', value: 'share', icon: '' },
                        ]"
                    >
                        <Icon icon="dots-horizontal" class="cursor-pointer w-8 h-8" />
                    </DropdownMenu>
                </div>
                -->
            </div>
            <div class="h-[1px] w-full bg-light"></div>
            <div class="h-1/2 w-full">
                <div class="flex h-full">
                    <div class="flex flex-col justify-between pt-4 px-3 border-r border-light">
                        <label class="text-sm text-secondary">{{ $t("common.linkToCustomer", companyCount) }}</label>
                        <div class="flex gap-2 items-center">
                            <span>{{ companyCount }}</span>
                            <Icon class="w-5 h-5" icon="company" />
                        </div>
                    </div>
                    <div class="flex flex-col justify-between pt-4 px-3 border-r border-light text-pretty max-w-[30%]">
                        <label class="text-sm text-secondary">{{ $t("common.idControl") }}</label>
                        <p>{{ matchIndicatorText }}</p>
                    </div>
                    <div class="flex flex-col justify-between pt-4 px-3 border-r border-light">
                        <label class="text-sm text-secondary">{{ $t("common.screening") }}</label>
                        <div v-if="screeningState === HitStatus.NotStarted" class="flex gap-2 items-center">
                            <div class="w-2 h-2 bg-light-gray rounded-full"></div>
                            <span>{{ $t('screening.noScreening') }}</span>
                        </div>
                        <div v-else class="flex gap-2 items-center">
                            <div v-if="screeningState === HitStatus.HitFound" class="w-2 h-2 bg-danger-dark rounded-full"></div>
                            <div v-if="screeningState === HitStatus.NoHit" class="w-2 h-2 bg-success-default rounded-full"></div>

                            <span
                                v-if="screeningState === HitStatus.HitFound"
                                class="ml-2 text-sm"
                            >{{ person?.screeningStatus?.pep === HitStatus.HitFound ? $t('screening.pep') : "" }}
                                {{ person?.screeningStatus?.sanction === HitStatus.HitFound ? $t('screening.sanction') : "" }} {{ $t('screening.found') }}</span>
                            <span v-if="screeningState === HitStatus.NoHit" class="ml-2 text-sm">{{ $t('screening.noHit') }} {{ $t('screening.found') }}</span>
                        </div>
                    </div>
                    <div class="flex flex-col justify-between pt-4 px-3">
                        <label class="text-sm text-secondary">{{ $t('screening.title') }}</label>
                        <span>{{ person.screeningStatus?.monitored ? $t('common.states.active') : $t('common.states.disabled') }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import Icon from "@/components/Icon.vue";
import { HitStatus, IDControlState, Person } from "@/lib/persons/person";
import { defineComponent, PropType } from "vue";

export default defineComponent({
    name: "PersonProfileHeader",
    components: {
        Icon,
    },
    props: {
        person: {
            type: Object as PropType<Person>,
            required: true,
        },
        companyCount: {
            type: [Number, String],
            required: true,
        },
    },
    data() {
        return {
            headerExtraOptions: false,
            lastPath: null,
            HitStatus,
        };
    },

    computed: {
        screeningState() {
            const screeningResult = this.person.screeningStatus;
            if (!screeningResult) return HitStatus.NotStarted;

            if (
                screeningResult.pep === HitStatus.HitFound ||
                screeningResult.sanction === HitStatus.HitFound ||
                screeningResult.sip === HitStatus.HitFound
            ) {
                return HitStatus.HitFound;
            }
            return HitStatus.NoHit;
        },
        matchIndicatorText(): string {
            if (!this.person.idControlDetails) {
                return this.$t("common.idControlState.not_started");
            }
            if (this.person.idControlDetails?.status === IDControlState.InProgress) {
                return this.$t("common.idControlState.sent_to_recepient");
            }
            switch (this.person.idControlDetails?.matchIndicator) {
            case 7: return this.$t("common.idControlMatchIndicator.weak_match_name");
            case 6: return this.$t("common.idControlMatchIndicator.partial_match_name");
            case 5: return this.$t("common.idControlMatchIndicator.full_match_name");
            case 4: return this.$t("common.idControlMatchIndicator.match_dob");
            case 3: return this.$t("common.idControlMatchIndicator.weak_match_name_and_dob");
            case 2: return this.$t("common.idControlMatchIndicator.partial_match_name_and_dob");
            case 1: return this.$t("common.idControlMatchIndicator.full_match_name_and_dob");
            default:
                return this.$t("common.idControlMatchIndicator.no_match");
            }
        },
    },
});
</script>
