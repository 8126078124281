import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabControl = _resolveComponent("TabControl")!
  const _component_RiskSourceWizardCompany = _resolveComponent("RiskSourceWizardCompany")!
  const _component_RiskSourceRoleWizard = _resolveComponent("RiskSourceRoleWizard")!
  const _component_RiskSourceWizardForm = _resolveComponent("RiskSourceWizardForm")!
  const _component_PopupDialog = _resolveComponent("PopupDialog")!

  return (_openBlock(), _createBlock(_component_PopupDialog, {
    "is-visible": _ctx.dialog.visible,
    header: _ctx.$t('risk.sources.add'),
    icon: 'plus',
    progress: _ctx.dialog,
    "show-cancel-button": true,
    "show-action-button": true,
    "action-text": _ctx.$t('risk.sources.add'),
    onCloseModal: _ctx.onClose,
    onAction: _ctx.addSource
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_TabControl, {
          tabs: _ctx.tabs,
          "selected-tab-key": _ctx.selectedTabKey,
          onTabChanged: _ctx.onTabChanged
        }, null, 8, ["tabs", "selected-tab-key", "onTabChanged"]),
        (_ctx.selectedTabKey === 'company')
          ? (_openBlock(), _createBlock(_component_RiskSourceWizardCompany, {
              key: 0,
              "value-type": _ctx.indicator.valueType,
              onSelected: _ctx.onIndicatorSelected
            }, null, 8, ["value-type", "onSelected"]))
          : _createCommentVNode("", true),
        (_ctx.selectedTabKey === 'roles')
          ? (_openBlock(), _createBlock(_component_RiskSourceRoleWizard, {
              key: 1,
              "value-type": _ctx.indicator.valueType,
              onSelected: _ctx.onIndicatorSelected
            }, null, 8, ["value-type", "onSelected"]))
          : _createCommentVNode("", true),
        (_ctx.selectedTabKey === 'form')
          ? (_openBlock(), _createBlock(_component_RiskSourceWizardForm, {
              key: 2,
              "value-type": _ctx.indicator.valueType,
              onSelected: _ctx.onIndicatorSelected
            }, null, 8, ["value-type", "onSelected"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["is-visible", "header", "progress", "action-text", "onCloseModal", "onAction"]))
}