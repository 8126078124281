<template>
    <div class="text-right">
        <Menu
            as="div"
            class="relative inline-block text-left"
            role="menu"
        >
            <div>
                <MenuButton class="inline-flex w-full justify-center rounded-md text-sm font-medium">
                    <slot></slot>
                </MenuButton>
            </div>

            <transition
                enter-active-class="transition duration-100 ease-out"
                enter-from-class="transform scale-95 opacity-0"
                enter-to-class="transform scale-100 opacity-100"
                leave-active-class="transition duration-75 ease-in"
                leave-from-class="transform scale-100 opacity-100"
                leave-to-class="transform scale-95 opacity-0"
            >
                <MenuItems
                    as="ul"
                    class="absolute z-10 mt-1 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
                    :class="[itemsAlign === 'left' ? 'right-0 origin-top-right' : 'left-0 origin-top-left' ]"
                >
                    <div class="px-1 py-1">
                        <MenuItem
                            as="li"
                            class="flex w-full items-center p-1 hover:bg-primary-selected"
                            v-for="(option, index) in options"
                            :key="'_' + index"
                            v-slot="{ active }"
                        >
                            <Icon
                                v-if="option.icon"
                                :icon="option.icon"
                                class="mr-2 h-5 w-5"
                            />
                            <button
                                :class="[
                                    active ? 'bg-default-selected' : '',
                                    'group flex w-full items-center  rounded-md px-2 py-2 text-sm whitespace-nowrap',
                                ]"
                                @click="$emit('menuItemSelected', option)"
                            >
                                {{ option.label }}
                            </button>
                        </MenuItem>
                    </div>
                </MenuItems>
            </transition>
        </Menu>
    </div>
</template>

<script lang="ts">
import Icon from "@/components/Icon.vue";
import { DropdownMenuOption } from "@/lib/common/MenuOption";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { PropType, defineComponent } from "vue";

export default defineComponent({
    name: "DropdownMenu",
    components: {
        Menu,
        MenuButton,
        MenuItems,
        MenuItem,
        Icon,
    },
    props: {
        options: {
            type: Array as PropType<DropdownMenuOption[]>,
            default: () => [],
        },
        itemsAlign: {
            type: String,
            default: "right",
        },
    },
    emits: ["menuItemSelected"],
    data() {
        return {};
    },
    methods: {},
    created() {},
    mounted() {},
});
</script>
