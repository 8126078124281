import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomerTopAction = _resolveComponent("CustomerTopAction")!
  const _component_SimpleListItem = _resolveComponent("SimpleListItem")!
  const _component_PopupDialogPrompt = _resolveComponent("PopupDialogPrompt")!
  const _component_PopupDialog = _resolveComponent("PopupDialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_CustomerTopAction, {
      icon: 'list-check',
      text: _ctx.getWorkflowName(),
      tooltip: _ctx.$t('project.workflow.title'),
      "is-clickable": true,
      onClicked: _ctx.showDialog
    }, null, 8, ["text", "tooltip", "onClicked"]),
    _createVNode(_component_PopupDialog, {
      "is-visible": _ctx.dialog.visible,
      header: _ctx.$t('project.workflow.changeAction'),
      icon: 'repeat',
      progress: _ctx.dialog,
      "show-cancel-button": true,
      "show-action-button": !_ctx.assessment.locked,
      "action-icon": 'repeat',
      "is-action-disabled": !(_ctx.selectedKey !== '' && _ctx.selectedKey !== _ctx.project.workflow),
      "action-text": _ctx.$t('project.workflow.changeAction'),
      "is-warning": _ctx.assessment.locked,
      onCloseModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dialog.visible = false)),
      onAction: _ctx.changeWorkflow
    }, {
      default: _withCtx(() => [
        (!_ctx.assessment.locked)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.workflows, (flow, i) => {
                return (_openBlock(), _createBlock(_component_SimpleListItem, {
                  key: 'cwf' + i,
                  text: _ctx.$ct(flow.name),
                  "sub-text": flow.key,
                  "is-selected": flow.key === _ctx.selectedKey,
                  "is-first": i === 0,
                  "is-last": i === _ctx.workflows.length - 1,
                  onClicked: ($event: any) => (_ctx.selectWorkflow(flow))
                }, null, 8, ["text", "sub-text", "is-selected", "is-first", "is-last", "onClicked"]))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (_ctx.assessment.locked)
          ? (_openBlock(), _createBlock(_component_PopupDialogPrompt, {
              key: 1,
              "is-warning": true,
              "prompt-text": _ctx.$t('project.workflow.lockedPrompt'),
              "info-text": _ctx.$t('project.workflow.lockedHint')
            }, null, 8, ["prompt-text", "info-text"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["is-visible", "header", "progress", "show-action-button", "is-action-disabled", "action-text", "is-warning", "onAction"])
  ]))
}