<template>
    <div>

        <div v-if="loading">
            <HeaderWithAddIcon :text="'Enabled Workflows'" :hide-icon="true" />
            <LoadAnimationBar :height="60" />
            <LoadAnimationBar />
        </div>

        <div v-if="!loading">
            <HeaderWithAddIcon :text="'Enabled Workflows'" @clicked="showAddDialog" />
            <WorkflowSettingsItem
                v-for="(flowConfig, i) in enabledFlows"
                :key="'ft' + i"
                :flow-config="flowConfig"
                :matrices="availableMatrices"
                @disable="enableDisableWorkflow(flowConfig, false)"
                @edit="showAddDialog(flowConfig)"
                @delete="showDeleteDialog(flowConfig)"
            />
            <div v-if="enabledFlows.length === 0">
                {{ 'No enabled workflows' }}
            </div>
        </div>

        <div v-if="!loading && disabledFlows.length > 0">
            <div class="my-8 border-b border-gray-300"></div>
            <HeaderWithAddIcon :text="'Disabled Workflows'" :hide-icon="true" />
            <WorkflowSettingsItem
                v-for="(flowConfig, i) in disabledFlows"
                :key="'ft' + i"
                :flow-config="flowConfig"
                :matrices="availableMatrices"
                @enable="enableDisableWorkflow(flowConfig, true)"
                @delete="showDeleteDialog(flowConfig)"
            />
        </div>

        <WorkflowSettingsDialog
            v-if="addDialog.visible"
            :config="addDialog.config"
            :existing-workflows="originalList"
            :available-matrices="availableMatrices"
            @add="onAddConfig"
            @edit="onEditConfig"
            @close-modal="addDialog.visible = false"
        />

        <!-- Delete Dialog -->
        <PopupDialog
            :is-visible="deleteDialog.visible"
            :header="'Delete Workflow'"
            :icon="'trash-can'"
            :is-warning="true"
            :show-action-button="true"
            :show-cancel-button="true"
            :action-text="'Delete Workflow'"
            :action-icon="'trash-can'"
            :is-action-disabled="!deleteDialog.valid"
            @close-modal="deleteDialog.visible = false"
            @action="onDelete"
        >
            <PopupDialogPrompt
                :is-warning="true"
                prompt-text="'Are you sure you want to delete this workflow?'"
                :can-proceed="deleteDialog.count === 0"
                :error-text="'This workflow can not be deleted!'"
                :error-reason="`There are ${deleteDialog.count} projects using this workflow`"
            />
        </PopupDialog>

    </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

import LoadAnimationBar from "@/ui/LoadAnimationBar.vue";
import PopupDialog from "@/ui/PopupDialog.vue";

import { DropdownItem } from "@/ui/inputs/input_helper";

import { getRiskMatrices } from "@/lib/risk/get_matrices";
import { getWorkflowProjectCount } from "@/lib/workflows/count_workflow_projects";
import { WorkflowConfig } from "@/types/flow_types";
import PopupDialogPrompt from "@/ui/dialog/PopupDialogPrompt.vue";
import HeaderWithAddIcon from "./HeaderWithAddIcon.vue";
import WorkflowSettingsDialog from "./WorkflowSettingsDialog.vue";
import WorkflowSettingsItem from "./WorkflowSettingsItem.vue";
import { updateSettings } from "@/lib/settings/update_settings";
import { querySettings } from "@/lib/settings/get_settings";

export default defineComponent({
    name: "WorkflowSettings",
    components: {
        PopupDialog,
        LoadAnimationBar,
        HeaderWithAddIcon,
        WorkflowSettingsItem,
        PopupDialogPrompt,
        WorkflowSettingsDialog,
    },
    data() {
        return {
            loading: true,

            originalList: new Array<WorkflowConfig>(),
            enabledFlows: new Array<WorkflowConfig>(),
            disabledFlows: new Array<WorkflowConfig>(),

            addDialog: { visible: false, config: null as WorkflowConfig | null },
            availableMatrices: new Array<DropdownItem>(),

            deleteDialog: { visible: false, valid: false, isWorking: false, statusText: "", isError: false, errorText: "", key: "", count: -1 },
            enableDialog: { visible: false, isDisabling: false },
        };
    },
    methods: {

        async onSomethingChanged() {
            const data = {
                workflows: this.originalList,
            };
            await updateSettings(data);
            const settings = await querySettings();
            this.$config.update(settings);
            this.loadWorkflows();
        },

        showAddDialog(flowConfig: WorkflowConfig) {
            this.addDialog.config = flowConfig;
            this.addDialog.visible = true;
        },

        onAddConfig(flowConfig: WorkflowConfig) {
            this.originalList.push(flowConfig);
            this.onSomethingChanged();
        },

        onEditConfig(flowConfig: WorkflowConfig) {
            const list = this.originalList;
            for (const i in list) {
                if (list[i].key === flowConfig.key) {
                    const targetConfig = list[i];
                    targetConfig.name = flowConfig.name;
                    targetConfig.params = flowConfig.params;
                    break;
                }
            }
            this.onSomethingChanged();
        },

        enableDisableWorkflow(flowConfig: WorkflowConfig, isEnabling: boolean) {
            const list = this.originalList;
            for (const i in list) {
                if (list[i].key === flowConfig.key) {
                    list[i].enabled = isEnabling;
                    break;
                }
            }
            this.onSomethingChanged();
        },

        async showDeleteDialog(flowConfig: WorkflowConfig) {
            this.deleteDialog.key = flowConfig.key;
            this.deleteDialog.isWorking = true;
            this.deleteDialog.visible = true;

            const countResponse = await getWorkflowProjectCount(flowConfig.key);
            if (countResponse.responseInfo.isError || countResponse.count == null) {
                this.deleteDialog.isError = true;
                this.deleteDialog.errorText = countResponse.responseInfo.parsedError?.message || "";
            } else {
                this.deleteDialog.count = countResponse.count;
            }
            this.deleteDialog.valid = (this.deleteDialog.count === 0);
            this.deleteDialog.isWorking = false;
        },

        onDelete() {
            this.deleteDialog.visible = false;
            const list = this.originalList;
            for (const i in list) {
                if (list[i].key === this.deleteDialog.key) {
                    list.splice(Number(i), 1);
                    break;
                }
            }
            this.onSomethingChanged();
        },

        async loadRiskMatrices() {
            this.availableMatrices = [];
            const matrixResponse = await getRiskMatrices();
            if (matrixResponse.responseInfo.isError || !matrixResponse.matrices) {
                /* this.addDialog.isError = true;
                this.addDialog.errorText = matrixResponse.responseInfo.parsedError?.message || ''; */
            } else {
                this.availableMatrices.push({
                    value: "none",
                    text: "None",
                });
                matrixResponse.matrices.forEach((matrix) => {
                    this.availableMatrices.push({
                        value: matrix.key,
                        text: matrix.name.en_EN || "",
                    });
                });
            }
        },

        loadWorkflows() {
            this.originalList = this.$config.settings.workflows || [];

            this.enabledFlows = [];
            this.disabledFlows = [];
            this.originalList.forEach((flowConfig) => {
                if (flowConfig.enabled) {
                    this.enabledFlows.push(flowConfig);
                } else {
                    this.disabledFlows.push(flowConfig);
                }
            });
        },

    },
    async mounted() {
        await this.loadRiskMatrices();
        this.loadWorkflows();
        this.loading = false;
    },
});
</script>
