import { Verified } from "../verified_plugin";
import { Role, RoleDescriptionWithoutSource } from "./Role";

export async function createRole(
    projectId: string,
    personId: string,
    roles: RoleDescriptionWithoutSource[],
): Promise<Role> {
    try {
        const response = await fetch("/api/aml/v1/roles/add", {
            method: "PUT",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
            body: JSON.stringify({
                projectId: projectId,
                personId: personId,
                roles: roles,
            }),
        });

        const body = await response.json();

        if (!response.ok) {
            throw new Error(body);
        }

        return body as Role;
    } catch (err) {
        console.error("Failed to get project with", err);
        throw err;
    }
}
