import { ApiResponse, parseLocalError, parseRemoteError } from "../error_parser";
import { Verified } from "../verified_plugin";

export const promoteRiskMatrix = async (matrixKey: string): Promise<
    { wasPromoted: boolean | null, responseInfo: ApiResponse }
> => {
    try {
        const response = await fetch(`/api/aml/v1/matrices/${matrixKey}/promote`, {
            method: "PUT",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
            body: JSON.stringify({}),
        });

        const body = await response.json();

        if (!response.ok) {
            return {
                wasPromoted: null,
                responseInfo: { isError: true, parsedError: parseRemoteError(body) },
            };
        }

        return {
            wasPromoted: true,
            responseInfo: { isError: false, parsedError: null },
        };
    } catch (error) {
        return {
            wasPromoted: null,
            responseInfo: { isError: true, parsedError: parseLocalError(error) },
        };
    }
};
