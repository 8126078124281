import { Workflow } from "@/types/flow_types";
import { ApiResponse, parseLocalError, parseRemoteError } from "../error_parser";
import { Verified } from "../verified_plugin";
import { LocalizedStringMap } from "../common/common";

export const getAvailableWorkflows = async (): Promise<{ workflows: Workflow[] | null, responseInfo: ApiResponse }> => {
    try {
        const response = await fetch("/api/aml/v1/workflows/baseflows", {
            method: "GET",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
        });
        const body = await response.json();
        if (!response.ok) {
            return {
                workflows: null,
                responseInfo: { isError: true, parsedError: parseRemoteError(body) },
            };
        }
        return {
            workflows: body as Workflow[],
            responseInfo: { isError: false, parsedError: null },
        };
    } catch (error) {
        return {
            workflows: null,
            responseInfo: { isError: true, parsedError: parseLocalError(error) },
        };
    }
};

export const getWorkflowTexts = async (): Promise<{ texts: LocalizedStringMap | null, responseInfo: ApiResponse }> => {
    try {
        const response = await fetch("/api/aml/v1/workflows/baseflows/texts", {
            method: "GET",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
        });
        const body = await response.json();
        if (!response.ok) {
            return {
                texts: null,
                responseInfo: { isError: true, parsedError: parseRemoteError(body) },
            };
        }
        return {
            texts: body as LocalizedStringMap,
            responseInfo: { isError: false, parsedError: null },
        };
    } catch (error) {
        return {
            texts: null,
            responseInfo: { isError: true, parsedError: parseLocalError(error) },
        };
    }
};
