<template>
    <div class="amlv3-indicator-empty">
        <div class="amlv3-indicator-empty-icon">
            {{ '!' }}
        </div>
        <div class="amlv3-indicator-empty-text">
            {{ text }}
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";

export default defineComponent({
    name: "MissingIconAndText",
    props: {
        text: { type: String as PropType<string>, required: true },
    },
});
</script>
<style scoped>
.amlv3-indicator-empty {
    display: flex;
}

.amlv3-indicator-empty-icon {
    width: 16px;
    height: 16px;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    color: white;
    border-radius: 50%;
    background-color: #B62537;
}

.amlv3-indicator-empty-text {
    margin-left: 6px;
    margin-top: -3px;
    color: #B62537;
}
</style>
