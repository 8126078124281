<template>
    <div class="flex-1 bg-background rounded-lg p-10 flex flex-col items-center justify-center gap-4">
        <svg
            width="32"
            height="33"
            viewBox="0 0 32 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.4795 3.1582H21.5195L29.3329 10.9715V22.0115L21.5195 29.8249H10.4795L2.6662 22.0115V10.9715L10.4795 3.1582Z"
                fill="#EDC250"
            />
            <path
                d="M15.9995 11.1582L15.9995 17.7334"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M15.9995 21.8242H16.0128"
                stroke="black"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>

        <slot></slot>

        <p @click="$emit('retry')" class="text-primary-default cursor-pointer hover:text-primary-dark">
            {{ $t("project.tabs.company.blocks.general.retryFetch") }}
        </p>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    components: {},
    props: {
        retryable: Boolean,
    },
    emits: ["retry"],
});
</script>
