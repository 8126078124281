import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"font-weight":"bold"} }
const _hoisted_2 = {
  key: 0,
  style: {"font-style":"italic","color":"dimgray"}
}
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputLabel = _resolveComponent("InputLabel")!
  const _component_InputLocked = _resolveComponent("InputLocked")!
  const _component_InputGroup = _resolveComponent("InputGroup")!
  const _component_InputRow = _resolveComponent("InputRow")!
  const _component_PopupDialogSplit = _resolveComponent("PopupDialogSplit")!
  const _component_PopupDialog = _resolveComponent("PopupDialog")!

  return (_openBlock(), _createBlock(_component_PopupDialog, {
    "is-visible": _ctx.dialog.visible,
    header: _ctx.$t('assessment.approval.approvers.label'),
    icon: 'user',
    "show-cancel-button": true,
    progress: _ctx.dialog,
    onCloseModal: _ctx.onClose
  }, {
    default: _withCtx(() => [
      _createVNode(_component_PopupDialogSplit, { style: {"min-width":"500px","max-width":"560px"} }, {
        top: _withCtx(() => [
          _createVNode(_component_InputRow, null, {
            default: _withCtx(() => [
              _createVNode(_component_InputGroup, { width: 6 }, {
                default: _withCtx(() => [
                  _createVNode(_component_InputLabel, {
                    text: _ctx.$t('common.person_info.firstName'),
                    "unique-id": 'approver-givenname'
                  }, null, 8, ["text"]),
                  _createVNode(_component_InputLocked, {
                    "model-value": _ctx.approver.givenName,
                    "unique-id": 'approver-givenname'
                  }, null, 8, ["model-value"])
                ]),
                _: 1
              }),
              _createVNode(_component_InputGroup, { width: 6 }, {
                default: _withCtx(() => [
                  _createVNode(_component_InputLabel, {
                    text: _ctx.$t('common.person_info.lastName'),
                    "unique-id": 'approver-familyname'
                  }, null, 8, ["text"]),
                  _createVNode(_component_InputLocked, {
                    "model-value": _ctx.approver.familyName,
                    "unique-id": 'approver-familyname'
                  }, null, 8, ["model-value"])
                ]),
                _: 1
              }),
              _createVNode(_component_InputGroup, { width: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_InputLabel, {
                    text: _ctx.$t('common.person_info.email'),
                    "unique-id": 'approver-email'
                  }, null, 8, ["text"]),
                  _createVNode(_component_InputLocked, {
                    "model-value": _ctx.approver.email,
                    "unique-id": 'approver-email'
                  }, null, 8, ["model-value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createElementVNode("div", null, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('assessment.approval.decision.decisionPrompt') + ': '), 1),
              _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.approver.decision === 'approved' ? _ctx.$t('assessment.approval.decision.approved') : _ctx.$t('assessment.approval.decision.denied')), 1)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('assessment.approval.decision.comment') + ': '), 1)
            ]),
            (!_ctx.approver.comment)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t('assessment.approval.decision.noComment')), 1))
              : _createCommentVNode("", true),
            (_ctx.approver.comment)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.approver.comment), 1))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["is-visible", "header", "progress", "onCloseModal"]))
}