import { ApiResponse, parseLocalError, parseRemoteError } from "../error_parser";
import { Verified } from "../verified_plugin";

export const getFormLink = async (formId: string, actorId: string): Promise<{ formLink: string, responseInfo: ApiResponse }> => {
    try {
        const response = await fetch(`/api/aml/v1/forms/${formId}/actors/${actorId}/formlink`, {
            method: "GET",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
        });

        const body = await response.json();

        if (!response.ok) {
            return {
                formLink: '',
                responseInfo: { isError: true, parsedError: parseRemoteError(body) },
            };
        }

        return {
            formLink: body.formLink,
            responseInfo: { isError: false, parsedError: null },
        };
    } catch (error) {
        return {
            formLink: '',
            responseInfo: { isError: true, parsedError: parseLocalError(error) },
        };
    }
};
