<template>
    <div class="fbav3-input-checkbox font-roboto">
        <div
            class="flex items-center mb-1 text-sm"
            v-for="(item) in items"
            :key="item.key"
        >
            <input
                type="checkbox"
                :id="`${uniqueId}-${item.key}`"
                :value="item.key"
                :checked="isPicked(item.key)"
                @change="onchange(item)"
            />
            <label :for="`${uniqueId}-${item.key}`" class="ml-2 mb-0 text-sm">{{ item.text }}</label>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { CheckboxItem } from "./input_helper";

export default defineComponent({
    name: "InputCheckbox",
    props: {
        modelValue: { type: Array as PropType<string[]>, required: true },
        items: { type: Array as PropType<CheckboxItem[]>, default: [] },
        uniqueId: { type: String as PropType<string>, required: true },
    },
    emits: ["update:modelValue"],
    data() {
        return {
            picked: "",
        };
    },
    methods: {
        isPicked(key: string): boolean {
            return this.modelValue.includes(key);
        },
        onchange(item: CheckboxItem) {
            const updatedModelValue = this.isPicked(item.key)
                ? this.modelValue.filter((k) => k !== item.key)
                : [...this.modelValue, item.key];
            this.$emit("update:modelValue", Object.values(updatedModelValue));
        },
    },
});
</script>

<style scoped>
.fbav3-input-checkbox input[type="checkbox"] {
    transform: scale(1);
}
</style>
