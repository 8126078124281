<template>
    <PopupDialog
        :is-visible="dialog.visible"
        :header="isUnlocking ? $t('assessment.lock.unlockAction') : $t('assessment.lock.lockAction')"
        :icon="isUnlocking ? 'lock-open' : 'lock'"
        :show-cancel-button="true"
        :show-action-button="true"
        :action-text="isUnlocking ? $t('assessment.lock.unlockActionShort') : $t('assessment.lock.lockAction')"
        :action-icon="isUnlocking ? 'lock-open' : 'lock'"
        :progress="dialog"
        @action="lockCurrentAssessment"
        @close-modal="onClose"
    >
        <PopupDialogSplit
            :prompt-text="isUnlocking ? $t('assessment.lock.unlockAction') : $t('assessment.lock.lockAction')"
            :prompt-hint="isUnlocking ? $t('assessment.lock.unlockHint1') : $t('assessment.lock.lockHint1')"
        >
            <PopupDialogInfoIconAndText
                :text="isUnlocking ? $t('assessment.lock.unlockHint2') : $t('assessment.lock.lockHint2')"
            />
        </PopupDialogSplit>
    </PopupDialog>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

import { Assessment } from "@/lib/assessments/assessments";
import { lockAssessment } from "@/lib/assessments/seal_assessment";
import { Project } from "@/lib/projects/projects";
import PopupDialog from "@/ui/PopupDialog.vue";
import PopupDialogSplit from "@/ui/dialog/PopupDialogSplit.vue";
import PopupDialogInfoIconAndText from "@/ui/dialog/PopupDialogInfoIconAndText.vue";
import { getProjectProcess } from "@/lib/projects/get_project";

export default defineComponent({
    name: "LockAssessmentDialog",
    components: {
        PopupDialog,
        PopupDialogSplit,
        PopupDialogInfoIconAndText,
    },
    emits: ["request-project-refresh", "close-modal"],
    props: {
        project: { type: Object as PropType<Project>, required: true },
        assessment: { type: Object as PropType<Assessment>, required: true },
        isUnlocking: { type: Boolean as PropType<boolean>, default: false },
    },
    data() {
        return {
            dialog: { visible: true, isWorking: false, statusText: "", isError: false, errorText: "" },
        };
    },
    methods: {

        async lockCurrentAssessment() {
            this.dialog.isError = false;
            this.dialog.isWorking = true;
            this.dialog.statusText = this.$t(
                this.isUnlocking ? "assessment.lock.unlockProgress" : "assessment.lock.lockProgress",
            );

            const response = await lockAssessment(this.project.id, this.assessment.id, !this.isUnlocking);
            if (response.responseInfo.isError) {
                this.dialog.errorText = response.responseInfo.parsedError?.message || "Unknown error";
                this.dialog.isError = true;
            } else {
                const projectResponse = await getProjectProcess(this.project.id);
                this.$emit("request-project-refresh", projectResponse);
                this.onClose();
            }

            this.dialog.isWorking = false;
        },

        onClose() {
            this.$emit("close-modal");
        },

    },
});
</script>
