import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-21d42a7a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "border-b border-gray-300 mx-5"
}
const _hoisted_2 = { class: "srv3-step-content" }
const _hoisted_3 = {
  key: 0,
  class: "srv3-step-icon-text"
}
const _hoisted_4 = {
  key: 1,
  class: "srv3-step-icon-icon"
}
const _hoisted_5 = {
  key: 2,
  class: "srv3-step-icon-icon"
}
const _hoisted_6 = { style: {"flex-grow":"1"} }
const _hoisted_7 = { class: "text-base font-bold py-1" }
const _hoisted_8 = { class: "srv3-step-pills" }
const _hoisted_9 = {
  key: 0,
  class: "srv3-step-pill-dot"
}
const _hoisted_10 = { class: "srv3-step-pill-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontIcon = _resolveComponent("FontIcon")!
  const _component_StepActions = _resolveComponent("StepActions")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, (step, i) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["srv3-step", [
                { 'srv3-step-first': i === 0 },
                { 'srv3-step-last': i === _ctx.renderData.steps.length - 1 },
            ]]),
        key: 's' + i
      }, [
        (i > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: _normalizeClass(["srv3-step-icon", [
                        { 'srv3-step-icon-completed': step.statusType === _ctx.StatusType.Completed },
                        { 'srv3-step-icon-error': step.statusType === _ctx.StatusType.Error },
                        { 'srv3-step-icon-warning': step.statusType === _ctx.StatusType.Warning },
                    ]])
          }, [
            (step.statusType === _ctx.StatusType.Default || step.statusType === _ctx.StatusType.InProgress)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(i + 1), 1))
              : _createCommentVNode("", true),
            (step.statusType === _ctx.StatusType.Completed)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(_component_FontIcon, {
                    icon: 'check',
                    size: 20
                  })
                ]))
              : _createCommentVNode("", true),
            (step.statusType === _ctx.StatusType.Error || step.statusType === _ctx.StatusType.Warning)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(_component_FontIcon, {
                    icon: 'circle-exclamation',
                    size: 20
                  })
                ]))
              : _createCommentVNode("", true)
          ], 2),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", null, [
              _createElementVNode("h4", _hoisted_7, _toDisplayString(_ctx.$ct(step.name)), 1)
            ]),
            _createElementVNode("div", _hoisted_8, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(step.pills, (status, j) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: _normalizeClass(["srv3-step-pill", [
                                { 'srv3-step-pill-progress': status.type === _ctx.StatusType.InProgress },
                                { 'srv3-step-pill-completed': status.type === _ctx.StatusType.Completed },
                                { 'srv3-step-pill-error': status.type === _ctx.StatusType.Error },
                                { 'srv3-step-pill-warning': status.type === _ctx.StatusType.Warning },
                            ]]),
                  key: 'p' + j
                }, [
                  (status.type !== _ctx.StatusType.Default)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$ct(status.text)), 1)
                ], 2))
              }), 128))
            ])
          ]),
          (_openBlock(), _createBlock(_component_StepActions, {
            key: 'sa' + i,
            step: step,
            onAction: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('action', $event)))
          }, null, 8, ["step"]))
        ])
      ], 2))
    }), 128))
  ]))
}