import singleSpaVue from "single-spa-vue";
import { createApp, h } from "vue";

import App from "./App.vue";
import router from "./router";

import "./assets/style.scss";
import "./assets/tailwind.scss";
import i18n, { DEFAULT_LOCALE, extractLanguage } from "./i18n";
import { WebappServices } from "./lib/services";
import verified from "./lib/verified_plugin";

import config from "./config";
import locale from "./locale";
import assets from "./assets";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import VueObserveVisibility from "vue3-observe-visibility";
import FloatingVue from "floating-vue";
import "floating-vue/dist/style.css";

// Props provided by the webapp
export interface WebappAppAdaptorProps {
    path: string;
    resourcesPath: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
    element: string;
    theme: string;
    services: WebappServices;
}

let services: WebappServices;

const translationChanged = () => {
    /* console.log("translation changed in aml spa", services.TranslationService.getInstance().currentLanguage); */
    const locale = services.TranslationService.getInstance().currentLanguage;
    if (locale) {
        i18n.global.locale.value = extractLanguage(locale);
    } else {
        i18n.global.locale.value = DEFAULT_LOCALE;
    }
};

const vueLifecycles = singleSpaVue({
    createApp,
    async appOptions(props: WebappAppAdaptorProps) {
        services = props.services;
        services.EventBus.on("translation.changed", translationChanged);
        return {
            el: "#aml-spa",
            render() {
                return h(App, {
                    path: props.path,
                    resourcesPath: props.resourcesPath,
                    data: props.data,
                    element: props.element,
                    theme: props.theme,
                    services: props.services,
                });
            },
        };
    },
    handleInstance(app) {
        app.use(verified(services));
        // console.log("Verified", Verified.instance.currentLanguage());
        app.use(router);
        app.use(i18n);

        app.use(locale());
        app.use(config());
        app.use(assets());

        app.use(VueObserveVisibility);
        app.use(FloatingVue);

        library.add(fas);
        app.component("FontAwesomeIcon", FontAwesomeIcon);
    },
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
