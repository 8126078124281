import { Verified } from "../verified_plugin";
import { Person, PersonInfoBlock } from "./person";

export async function createPerson(options: PersonInfoBlock): Promise<Person> {
    try {
        const response = await fetch("/api/aml/v1/persons", {
            method: "POST",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },

            body: JSON.stringify({
                data: {
                    person_info: {
                        givenName: options.givenName,
                        familyName: options.familyName,
                        dateOfBirth: options.dateOfBirth,
                        email: options.email,
                        phone: options.phone,
                        nationality: options.nationality,
                    },
                },
            }),
        });

        const body = await response.json();

        if (!response.ok) {
            throw new Error(body);
        }

        return body as Person;
    } catch (err) {
        console.error("Failed to get project with", err);
        throw err;
    }
}
