<template>
    <div class="flex gap-4 items-center bg-white py-2 px-4 border border-muted h-20 w-1/3 shadow-sm">
        <Icon icon="branch" class="h-16 w-16"></Icon>
        <div class="text-lg">
            <p class="">Name: {{ branch.name }}</p>
            <p class="">Key: {{ branch.key }}</p>
        </div>
    </div>
</template>
<script lang="ts">
import { Branch } from "@/lib/common/companyBranch";
import { defineComponent, PropType } from "vue";
import Icon from "../../Icon.vue";

export default defineComponent({
    name: "BranchCard",
    components: {
        Icon,
    },
    props: {
        branch: {
            type: {} as PropType<Branch>,
            required: true,
        },
    },
});
</script>
