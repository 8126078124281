<template>
    <div
        ref="datePickerRef"
        class=""
    >
        <InputLabel
            v-if="label"
            :text="label"
            unique-id="date-picker"
        />
        <div
            class="relative flex rounded-md"
            :class="[isRange ? ' mt-0' : ' mt-0 w-full']"
        >
            <input type="hidden" name="date" />
            <InputText
                v-model="selected_Date"
                :class="[bgColor]"
                unique-id="date-picker"
                :placeholder=" isRange ? $t('common.placeholders.dateFormat.range') : $t('common.placeholders.dateFormat.default')"
                @click="toggleDatepicker()"
                @input="onInput"
                class="bg-white"
                :readonly="isRange ? true : false"
            />
            <div class="absolute top-0 right-0 mt-1 mr-1 cursor-pointer" @click="toggleDatepicker()">
                <svg
                    class="h-6 w-6 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="{2}"
                        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                    />
                </svg>
            </div>
            <Transition name="fade">
                <div
                    v-if="showDatepicker"
                    ref="datePickerCalenderRef"
                    class="absolute top-0 left-0 z-50 mt-12 rounded-md bg-white p-4 shadow w-[17rem] overflow-visible"
                >
                    <div class="mb-2 flex items-center justify-between">
                        <div>
                            <button
                                type="button"
                                class="inline-flex cursor-pointer rounded-full border border-solid border-light p-1 transition duration-100 ease-in-out hover:bg-gray-200"
                                @click="decrement"
                            >
                                <svg
                                    class="inline-flex h-6 w-6 text-gray-500"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="{2}"
                                        d="M15 19l-7-7 7-7"
                                    />
                                </svg>
                            </button>
                        </div>
                        <div
                            v-if="type === 'date'"
                            @click="showMonthPicker()"
                            class="flex-grow cursor-pointer rounded-lg p-1 text-lg font-bold text-gray-800 hover:bg-gray-200"
                        >
                            <p class="text-center">
                                {{ format(datepickerHeaderDate, "MMMM") }}
                            </p>
                        </div>
                        <div
                            @click="type === 'year' ? showMonthPicker() : showYearPicker()"
                            class="flex-grow cursor-pointer rounded-lg p-1 text-lg font-bold text-gray-800 hover:bg-gray-200"
                        >
                            <p class="text-center">
                                {{ format(datepickerHeaderDate, "yyyy") }}
                            </p>
                        </div>
                        <div>
                            <button
                                type="button"
                                class="inline-flex cursor-pointer rounded-full p-1 border border-solid border-light transition duration-100 ease-in-out hover:bg-gray-200"
                                @click="increment"
                            >
                                <svg
                                    class="inline-flex h-6 w-6 text-gray-500"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="{2}"
                                        d="M9 5l7 7-7 7"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div v-if="type === 'date'">
                        <div class="-mx-1 mb-3 flex flex-wrap">
                            <div
                                v-for="(day, i) in DAYS"
                                :key="i"
                                class="px-1 w-[14.26%]"
                            >
                                <div class="text-center text-xs font-medium text-gray-800">
                                    {{ day }}
                                </div>
                            </div>
                        </div>
                        <div class="-mx-1 flex flex-wrap">
                            <div
                                v-for="(_, i) in blankDays"
                                :key="i"
                                class="border border-transparent p-1 text-center text-sm w-[14.26%]"
                            ></div>

                            <div
                                v-for="(d, i) in dayCount"
                                :key="i"
                                class="mb-1 px-1 w-[14.26%]"
                            >
                                <div
                                    @click="setDateValue(d)"
                                    class="cursor-pointer rounded-full text-center text-sm leading-loose transition duration-100 ease-in-out"
                                    :class="[
                                        isToday(d) ? 'bg-blue-500 text-white' : 'text-gray-700 hover:bg-blue-200',
                                        highlightDate(d) ? 'bg-blue-200' : '',
                                        isInputDate(d) ? 'bg-blue-200' : '',
                                    ]"
                                    @mouseover="hoverDate(d)"
                                >
                                    {{ d }}
                                </div>
                            </div>
                        </div>
                        <div
                            class="flex justify-center rounded-sm border border-solid border-light shadow-sm cursor-pointer"
                            @click="clearDate"
                        >
                            <span class="">Clear date</span>
                        </div>
                    </div>
                    <div v-if="type === 'month'" class="-mx-1 flex flex-wrap">
                        <div
                            v-for="(_, i) in Array(12).fill(null)"
                            :key="i"
                            @click="setMonthValue(i)"
                            class="w-[25%]"
                        >
                            <div
                                class="cursor-pointer rounded-lg p-5 text-center text-sm font-semibold hover:bg-gray-200"
                                :class="[isSelectedMonth(i) ? 'bg-blue-500 text-white' : 'text-gray-700 hover:bg-blue-200']"
                            >
                                {{ format(new Date(datepickerHeaderDate.getFullYear(), i,
                                                   datepickerHeaderDate.getDate()), "MMM") }}
                            </div>
                        </div>
                    </div>
                    <div v-if="type === 'year'" class="-mx-1 flex flex-wrap">
                        <div
                            v-for="(_, i) in Array(12).fill(null)"
                            :key="i"
                            @click="setYearValue(currentYear - (12 - i))"
                            class="w-[25%]"
                        >
                            <div
                                class="cursor-pointer rounded-lg p-5 text-center text-sm font-semibold hover:bg-gray-200"
                                :class="[isSelectedMonth(i) ? 'bg-blue-500 text-white' : 'text-gray-700 hover:bg-blue-200']"
                            >
                                {{
                                    format(
                                        new Date(currentYear - (12 - i), datepickerHeaderDate.getMonth(),
                                                 datepickerHeaderDate.getDate()),
                                        "yyyy"
                                    )
                                }}
                            </div>
                        </div>
                    </div>
                </div>
            </Transition>
        </div>
    </div>
</template>
<script lang="ts">
import {
    addMonths,
    addYears,
    format,
    getDay,
    getDaysInMonth,
    isAfter,
    isEqual,
    isWithinInterval,
    parse,
    subMonths,
    subYears,
} from "date-fns";
import type { Ref } from "vue";
import { computed, defineComponent, onBeforeUnmount, onMounted, ref, watch } from "vue";
import InputText from "@/ui/inputs/InputText.vue";
import InputLabel from "@/ui/inputs/InputLabel.vue";

interface selectedDateRange {
    start: Date | string;
    end: Date | string;
}
export default defineComponent({
    props: {
        modelValue: {
            type: [String, Object],
            default: null,
        },
        isRange: {
            type: Boolean,
            default: false,
        },
        bgColor: {
            type: String,
            default: "bg-transparent",
        },
        placeholderText: {
            type: String,
            default: "",
        },
        label: {
            type: String,
            default: "",
        },
    },
    components: {
        InputText,
        InputLabel,
    },
    emits: ["update:modelValue"],
    setup(props, emits) {
        const DAYS = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
        const type = ref("date");
        const showDatepicker = ref(false);
        const dayCount = ref([] as number[]);
        const blankDays = ref([] as number[]);
        const datepickerHeaderDate = ref(new Date());
        const selectedDate: Ref<Date | string> = ref("");
        const currentYear: Ref<number> = ref(new Date().getFullYear());
        const startDate = ref(new Date());
        const currentDdate = ref(new Date());
        const selected: Ref<selectedDateRange> = ref({ start: "", end: "" });
        const start_date_selected = ref(false);
        const toggleDatepicker = () => {
            showDatepicker.value = !showDatepicker.value;
        };
        const showMonthPicker = () => (type.value = "month");
        const showYearPicker = () => (type.value = "year");
        const datePickerRef : Ref<HTMLDivElement | null> = ref(null);
        const datePickerCalendarRef: Ref<HTMLDivElement | null> = ref(null);
        watch(datepickerHeaderDate, async (newValue, oldValue) => {
            getDayCount(newValue);
        });

        watch(() => props.modelValue, (newValue, oldValue) => {
            if (newValue !== oldValue) {
                if (newValue === "") {
                    clearDate();
                }
            }
        });

        const decrement = () => {
            switch (type.value) {
            case "date":
                datepickerHeaderDate.value = subMonths(datepickerHeaderDate.value, 1);
                break;
            case "month":
                datepickerHeaderDate.value = subYears(datepickerHeaderDate.value, 1);
                break;
            case "year":
                currentYear.value = currentYear.value - 12;
                break;
            }
        };

        const clearDate = () => {
            selected.value = { start: "", end: "" };
            showDatepicker.value = false;
        };

        const increment = () => {
            switch (type.value) {
            case "date":
                datepickerHeaderDate.value = addMonths(datepickerHeaderDate.value, 1);
                break;
            case "month":
                datepickerHeaderDate.value = addYears(datepickerHeaderDate.value, 1);
                break;
            case "year":
                currentYear.value = currentYear.value + 12;
                break;
            }
        };

        const isToday = (date: number) => {
            return isEqual(
                new Date(datepickerHeaderDate.value.getFullYear(), datepickerHeaderDate.value.getMonth(), date),
                new Date(),
            );
        };

        const getDayCount = (date: Date) => {
            const daysInMonth = getDaysInMonth(date);

            // find where to start calendar day of week
            const dayOfWeek = getDay(new Date(date.getFullYear(), date.getMonth(), 0));
            const blankdaysArray: number[] = [];
            for (let i = 1; i <= dayOfWeek; i++) {
                blankdaysArray.push(i);
            }

            const daysArray: number[] = [];
            for (let i = 1; i <= daysInMonth; i++) {
                daysArray.push(i);
            }

            blankDays.value = blankdaysArray;
            dayCount.value = daysArray;
        };

        const isSelectedMonth = (month: number) => {
            if (selectedDate.value !== "") {
                const dateSelected = new Date(selectedDate.value);
                return isEqual(new Date(dateSelected.getFullYear(), month, dateSelected.getDate()), dateSelected);
            }
        };

        const setMonthValue = (month: number) => {
            datepickerHeaderDate.value = new Date(datepickerHeaderDate.value.getFullYear(), month, datepickerHeaderDate.value.getDate());
            type.value = "date";
        };

        const setYearValue = (year: number) => {
            datepickerHeaderDate.value = new Date(year, datepickerHeaderDate.value.getMonth(), datepickerHeaderDate.value.getDate());
            type.value = "month";
        };
        const setDateValue = (dt: number) => {
            const selectedDateValue = new Date(datepickerHeaderDate.value.getFullYear(), datepickerHeaderDate.value.getMonth(), dt);

            if (!selectedDateValue || isNaN(selectedDateValue.getTime())) {
                console.error("Invalid date value:", selectedDateValue);
                return;
            }

            try {
                if (props.isRange) {
                    if (!start_date_selected.value) {
                        start_date_selected.value = true;
                        selected.value.start = selectedDateValue;
                        selected.value.end = "";
                    } else {
                        start_date_selected.value = false;

                        if (isAfter(selected.value.start, selectedDateValue)) {
                            selected.value.end = selected.value.start;
                            selected.value.start = selectedDateValue;
                        } else {
                            selected.value.end = selectedDateValue;
                        }

                        showDatepicker.value = false;
                    }
                } else {
                    selected.value.start = selectedDateValue;
                    showDatepicker.value = false;
                }
                emits.emit(
                    "update:modelValue",
                    props.isRange
                        ? { start: selected.value.start, end: selected.value.end }
                        : format(selected.value.start, "yyyy-MM-dd"),
                );
            } catch (error) {
                console.log("Error setting date value:", error);
            }
        };

        const onInput = (event: Event) => {
            const inputValue = (event.target as HTMLInputElement).value;
            if (inputValue.length > 10) {
                (event.target as HTMLInputElement).value = inputValue.slice(0, inputValue.length - 1);
                return false;
            }
            const formattedDate = formatDate(inputValue);
            if (formattedDate) {
                selected.value.start = formattedDate;
                emits.emit("update:modelValue", format(formattedDate, "yyyy-MM-dd"));
                datepickerHeaderDate.value = new Date(formattedDate);
            }
        };

        const isInputDate = (day: number): boolean => {
            if (selected.value.start === "") {
                return false;
            }
            const inputDate = new Date(selected.value.start);
            const currentDate = new Date(datepickerHeaderDate.value.getFullYear(), datepickerHeaderDate.value.getMonth(), day);
            return (
            // Compare date components for accuracy
            // (avoids issues with timezones potentially causing mismatches)
                inputDate.getDate() === currentDate.getDate() &&
                inputDate.getMonth() === currentDate.getMonth() &&
                inputDate.getFullYear() === currentDate.getFullYear()
            );
        };

        // Watch for changes in showDatepicker (when the calendar opens/closes)
        watch(showDatepicker, (newValue) => {
            if (newValue) {
                // Calendar is opening, update datepickerHeaderDate to match the input
                if (selected.value.start !== "") {
                    datepickerHeaderDate.value = new Date(selected.value.start);
                }
            }
        });

        const formatDate = (input: string): string | null => {
            // Remove non-digit characters
            const digitsOnly = input.replace(/[^\d.-]/g, "");

            // Check if the input matches the desired format
            const dateRegex = /^((?:19|20)\d\d)-(0?[1-9]|1[0-2])-(0?[1-9]|[12][0-9]|3[01])$/;

            const match = digitsOnly.match(dateRegex);

            if (match) {
                const [, year, month, day] = match;
                const formattedDate = `${year}.${month.padStart(2, "0")}.${day.padStart(2, "0")}`;
                // Validate the date
                const parsedDate = new Date(formattedDate);
                if (parsedDate instanceof Date && !isNaN(parsedDate.getTime())) {
                    return formattedDate;
                }
                return null;
            }

            return null;
        };

        const selected_Date = computed(() => {
            if (selected.value.start === "") {
                return "";
            }
            if (props.isRange) {
                return (
                    format(new Date(selected.value.start), "yyyy-MM-dd") +
                    (selected.value.end !== "" ? " - " + format(new Date(selected.value.end), "yyyy-MM-dd") : "")
                );
            }
            return format(new Date(selected.value.start), "yyyy-MM-dd");
        });

        const hoverDate = (dt: number) => {
            if (start_date_selected.value) {
                selected.value.end = new Date(datepickerHeaderDate.value.getFullYear(), datepickerHeaderDate.value.getMonth(), dt);
            }
        };

        const handleClickOutside = (event: MouseEvent) => {
            if (datePickerRef.value && !datePickerRef.value.contains(event.target as Node)) {
                showDatepicker.value = false;
            }
        };

        const highlightDate = (dt: number) => {
            if (start_date_selected.value) {
                return isWithinInterval(new Date(datepickerHeaderDate.value.getFullYear(), datepickerHeaderDate.value.getMonth(), dt), {
                    start: selected.value.start,
                    end: selected.value.end,
                });
            }
        };

        onMounted(() => {
            getDayCount(datepickerHeaderDate.value);
            document.addEventListener("mousedown", handleClickOutside);
            if (props.modelValue === "") {
                clearDate();
            }
        });
        onBeforeUnmount(() => {
            document.removeEventListener("mousedown", handleClickOutside);
        });
        return {
            setMonthValue,
            setYearValue,
            isSelectedMonth,
            getDayCount,
            setDateValue,
            isToday,
            increment,
            decrement,
            type,
            showDatepicker,
            dayCount,
            blankDays,
            datepickerHeaderDate,
            selectedDate,
            toggleDatepicker,
            showMonthPicker,
            showYearPicker,
            format,
            addMonths,
            addYears,
            getDay,
            getDaysInMonth,
            isEqual,
            parse,
            subMonths,
            subYears,
            DAYS,
            clearDate,
            isAfter,
            selected_Date,
            hoverDate,
            isWithinInterval,
            highlightDate,
            selected,
            currentYear,
            datePickerRef,
            datePickerCalendarRef,
            onInput,
            formatDate,
            isInputDate,
        };
    },
});
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
<style scoped>
.fbav3-input-select {
    width: 100%;
    height: 42px;
    padding: 0px 8px;
    font-size: 14px;
    font-weight: 500;
    color: #444444;
    border-radius: 4px;
    border: solid 1px #D5D5D5;
}
</style>
