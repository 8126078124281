<template>
    <div class="flex flex-col gap-4 pt-10">
        <div class="flex gap-4 items-center bg-white py-2 px-4 border border-muted h-20 w-1/3 shadow-sm">
            <Icon icon="plus-round" class="h-14 w-14"></Icon>
            <div class="text-lg">
                <p class="">ADD BRANCH</p>
                <p class="text-sm">Add a Branch to Organizational Structure</p>
            </div>
        </div>
        <BranchCard v-for="branch in branches" :branch="branch" :key="branch.key" />
    </div>
</template>
<script lang="ts">
import Icon from "@/components/Icon.vue";
import { Branch } from "@/lib/common/companyBranch";
import { defineComponent } from "vue";
import BranchCard from "./BranchCard.vue";

export default defineComponent({
    name: "Branches",
    components: {
        BranchCard,
        Icon,
    },
    data() {
        return {
            branches: [
                {
                    name: "Branch 1",
                    key: "1",
                },
                {
                    name: "Branch 2",
                    key: "2",
                },
                {
                    name: "Branch 3",
                    key: "3",
                },
            ] as Branch[],
        };
    },
});
</script>
