<template>
    <div
        class="fba-tli"
        v-click-away="hideDropdown"
    >

        <div class="fba-tli-top">

            <div
                class="fba-tli-text"
                @click="$emit('open')"
            >
                <div class="fba-tli-text-name">
                    {{ $ct(matrix.name) }}
                </div>
                <div class="fba-tli-text-info">
                    {{ configName }}
                </div>
            </div>

            <div
                class="fba-tli-btn"
                @click="$emit('edit')"
            >
                <FontIcon :icon="'pen'" />
            </div>

            <div
                class="fba-tli-btn fba-tli-btn-slim"
                @click="open = !open"
            >
                <FontIcon :icon="'ellipsis-vertical'" />
            </div>

            <div
                class="fba-tli-menu"
                v-if="open"
            >
                <!-- <div class="fba-tli-menu-item">
                {{ 'Clear Changes' }}
            </div>
            <div class="fba-tli-menu-item">
                {{ 'Roll Back Version' }}
            </div> -->
                <div
                    class="fba-tli-menu-item"
                    @click="onDeleteClicked"
                >
                    {{ 'Delete Risk Matrix' }}
                </div>
            </div>

        </div>

        <div class="fba-tli-bottom">

            <div class="fba-tli-versions">
                <div
                    class="fba-tli-version"
                    :class="[
                        { 'fba-tli-version-warning': matrix.prodVersion === 0 },
                    ]"
                >
                    <div class="fba-tli-version-text">
                        {{ 'Production version:' }}
                    </div>
                    <div class="fba-tli-version-value">
                        {{ matrix.prodVersion }}
                    </div>
                </div>
                <div
                    class="fba-tli-version fba-tli-version-border"
                    :class="[
                        { 'fba-tli-version-warning': matrix.devVersion > matrix.prodVersion },
                    ]"
                >
                    <div class="fba-tli-version-text">
                        {{ 'Development version:' }}
                    </div>
                    <div class="fba-tli-version-value">
                        {{ matrix.devVersion }}
                    </div>
                </div>
            </div>

            <div
                class="fba-tli-promote"
                @click="onPromote"
                :class="[
                    { 'fba-tli-promote-disabled': matrix.devVersion === matrix.prodVersion },
                ]"
            >
                {{ 'PROMOTE' }}
            </div>

        </div>

    </div>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";

import { RiskMatrixDefinition } from "@/lib/risk/risk_matrix";
import { mixin as VueClickAway } from "vue3-click-away";

import FontIcon from "@/ui/FontIcon.vue";

export default defineComponent({
    name: "RiskSettingsMatrixItem",
    mixins: [VueClickAway],
    components: {
        FontIcon,
    },
    emits: ["edit", "open", "promote", "delete"],
    props: {
        matrix: { type: Object as PropType<RiskMatrixDefinition>, required: true },
        configName: { type: String as PropType<string>, required: true },
    },
    data() {
        return {
            open: false,
        };
    },
    methods: {

        hideDropdown() {
            this.open = false;
        },

        onPromote() {
            if (this.matrix.devVersion === this.matrix.prodVersion) return;
            this.$emit("promote");
        },

        onDeleteClicked() {
            this.$emit("delete");
            this.open = false;
        },

    },
});
</script>
<style scoped>
.fba-tli {
    margin-bottom: 10px;
    border-radius: 4px;
    border: solid 1px lightgray;
    background-color: white;
    box-sizing: border-box;
}

.fba-tli-top {
    position: relative;
    display: flex;
}

.fba-tli-text {
    flex-grow: 100;
    padding: 5px 10px;
    cursor: pointer;
}

.fba-tli-text:hover {
    background-color: whitesmoke;
}

.fba-tli-text-name {
    color: black;
}

.fba-tli-text-info {
    font-size: 14px;
    color: dimgray;
}

.fba-tli-btn {
    flex-shrink: 0;
    width: 46px;
    padding-top: 12px;
    text-align: center;
    color: black;
    border-left: solid 1px lightgray;
    cursor: pointer;
}

.fba-tli-btn:hover {
    background-color: whitesmoke;
}

.fba-tli-btn-slim {
    width: 26px;
}

.fba-tli-menu {
    position: absolute;
    top: 46px;
    right: 0px;
    border: solid 1px lightgray;
    border-bottom: none;
    background-color: white;
    z-index: 9;
}

.fba-tli-menu-item {
    min-width: 120px;
    padding: 5px 10px;
    color: black;
    border-bottom: solid 1px lightgray;
    cursor: pointer;
}

.fba-tli-menu-item:hover {
    background-color: whitesmoke;
}

.fba-tli-bottom {
    display: flex;
    min-height: 20px;
    border-top: solid 1px lightgray;
}

.fba-tli-versions {
    flex-grow: 100;
    display: flex;
}

.fba-tli-version {
    width: 50%;
    display: flex;
    padding: 5px 10px;
    color: black;
}

.fba-tli-version-warning {
    color: #b97b00;
}

.fba-tli-version-border {
    border-left: solid 1px lightgray;
}

.fba-tli-version-text {
    flex-grow: 100;
    font-size: 14px;
}

.fba-tli-version-value {
    padding-right: 10px;
    font-size: 15px;
    font-weight: bold;
}

.fba-tli-promote {
    flex-shrink: 0;
    width: 120px;
    padding-top: 5px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: black;
    border-left: solid 1px lightgray;
    cursor: pointer;
}

.fba-tli-promote:hover {
    background-color: whitesmoke;
}

.fba-tli-promote-disabled {
    color: dimgray;
    background-color: white;
    cursor: initial;
}

.fba-tli-promote-disabled:hover {
    background-color: white;
}
</style>
