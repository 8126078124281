import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6818d456"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["tooltip"]
const _hoisted_2 = { class: "amlv3-ctopaction-icon" }
const _hoisted_3 = { class: "amlv3-ctopaction-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontIcon = _resolveComponent("FontIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "amlv3-ctopaction",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args))),
    tooltip: _ctx.tooltip
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_FontIcon, {
        icon: _ctx.icon,
        size: 14
      }, null, 8, ["icon"])
    ]),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.text), 1)
  ], 8, _hoisted_1))
}