<template>
    <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 py-6">
        <!--Header-->
        <div class="flex justify-between mb-5">
            <label class="text-3xl">{{ title }}</label>
            <Icon
                class="h-10 w-10 bg-background hover:bg-light-gray p-3 rounded-full cursor-pointer"
                icon="cross"
                @click="$emit('closed')"
                aria-hidden="true"
            />
        </div>
        <!--Body-->
        <div class="flex flex-col gap-y-5">
            <ul class="p-0 space-y-3">
                <li
                    v-for="(name, i) in nameList"
                    class="flex gap-x-2 items-center min-h-[6rem] border border-light border-solid p-4 rounded-lg hover:bg-primary-hover-blue"
                    :key="`name-${i}`"
                >
                    <div>
                        <input
                            name="name"
                            type="radio"
                            :value="name.value"
                            v-model="currentValue"
                            @input="onChange(name.value)"
                            class="h-6 w-6 border-light"
                        />
                    </div>
                    <div>
                        <div class="space-y-2">
                            <label>{{ name.value }}</label>
                            <div
                                v-for="src in name.sources"
                                class="text-sm text-light-gray flex gap-2"
                                :key="`src-${src.source}`"
                            >
                                <Icon class="w-4 h-4" :icon="`aml:src-${src.source.toLowerCase()}`" />
                                <Icon icon="dot-single" aria-hidden="true" />
                                <span>{{ $d(new Date(src.updatedAt), "long") }}</span>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>

            <!--Start Add new name-->
            <div v-if="addName" class="space-y-3 bg-background rounded-lg p-4">
                <div class="w-full relative">
                    <label class="block">{{ addOrUpdateText }}</label>

                    <div class="absolute right-1 top-0">
                        <Icon
                            icon="cross"
                            class=" w-3 h-3 cursor-pointer"
                            @click="addName = false"
                            aria-hidden="true"
                        />
                    </div>
                </div>
                <Icon
                    icon="horizontal-line"
                    class=""
                    aria-hidden="true"
                />
                <div class="space-y-2">
                    <InputLabel
                        unique-id="person-name"
                        :text="propertyName === 'givenName' ? $t('common.person_info.firstName') : $t('common.person_info.lastName')"
                        class="block"
                    />
                    <InputText v-model="newName" unique-id="addName" />
                </div>

                <CustomButton
                    :text="addOrUpdateText"
                    class="border border-primary-default font-medium text-base box-border ml-auto"
                    text-color="primary-default"
                    round-style="3xl"
                    bg-color="white"
                    @click="addNewName"
                />
            </div>
            <!--End Add new name-->
            <div v-if="!addName">
                <a class="text-primary-default" @click="addName = true">{{ addOrUpdateText }}</a>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import CustomButton from "@/components/CustomButton.vue";
import FlagIcon from "@/components/FlagIcon.vue";
import Icon from "@/components/Icon.vue";
import { valueWithsources } from "@/lib/common/common";
import { Person, PersonInfoBlock } from "@/lib/persons/person";
import { updatePerson, updatePersonInfo } from "@/lib/persons/update_person";
import InputLabel from "@/ui/inputs/InputLabel.vue";
import InputText from "@/ui/inputs/InputText.vue";
import { defineComponent, PropType } from "vue";

export default defineComponent({
    name: "EditName",
    props: {
        person: {
            type: Object as PropType<Person>,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        propertyName: {
            type: String,
            default: "",
        },
    },
    components: {
        Icon,
        CustomButton,
        FlagIcon,
        InputText,
        InputLabel,
    },
    emits: ["closed", "refresh"],
    data() {
        return {
            addName: false,
            newName: "",
        };
    },
    computed: {
        nameList(): any[] {
            const personInfo = this.person.data.person_info as PersonInfoBlock;
            console.log("personInfo", personInfo);
            const personInfoDataBlocks = this.person.blocks
                .filter((b: any) => b._block === "person_info")
                ?.filter((b: any) => b[this.propertyName as keyof PersonInfoBlock]);
            const names = personInfoDataBlocks.reduce((acc, block) => {
                const existingName = acc.find((name: any) => name.value === block[this.propertyName as keyof PersonInfoBlock]);
                if (existingName) {
                    existingName.sources.push({ source: block._source, updatedAt: block._updatedAt });
                } else {
                    acc.push({
                        value: block[this.propertyName as keyof PersonInfoBlock] ?? "",
                        sources: [{ source: block._source, updatedAt: block._updatedAt }],
                    });
                }
                return acc;
            }, [] as valueWithsources[]);

            names.sort((a: any, b: any) => {
                // Prioritize the name matching person.data.person_info.givenName
                if (a.value === personInfo[this.propertyName as keyof PersonInfoBlock]) {
                    return -1; // a comes before b
                } else if (b.value === personInfo[this.propertyName as keyof PersonInfoBlock]) {
                    return 1; // b comes before a
                }

                // Get the latest 'updatedAt' value for each name
                const latestUpdateA = Math.max(...a.sources.map((source: any) => new Date(source.updatedAt)));
                const latestUpdateB = Math.max(...b.sources.map((source: any) => new Date(source.updatedAt)));

                // Compare the latest update dates
                return latestUpdateB - latestUpdateA;
            });
            console.log("names", names);
            return names;
        },
        currentValue(): string | undefined {
            const name =
                this.propertyName === "givenName"
                    ? (this.person.data.person_info as PersonInfoBlock).givenName
                    : (this.person.data.person_info as PersonInfoBlock).familyName ?? "";
            console.log("name", name);
            return name;
        },
        addOrUpdateText(): string {
            return (
                (this.nameList && this.nameList?.length > 0 ? this.$t("common.actions.update") : this.$t("common.actions.add")) +
                " " +
                (this.propertyName === "givenName" ? this.$t("common.person_info.firstName") : this.$t("common.person_info.lastName"))
            );
        },
    },
    methods: {
        async addNewName() {
            await updatePerson(this.person.id, {
                [this.propertyName]: this.newName,
            });

            this.$emit("refresh");
            this.addName = false;
            this.newName = "";
        },

        async onChange(name: string) {
            await updatePersonInfo(this.person.id, {
                [this.propertyName]: name,
            });
            this.$emit("refresh");
        },
    },
    created() {
        console.log("created");
        // this.propertyName = this.title.toLowerCase() === "first name" ? "givenName" : "familyName";
    },
});
</script>
