import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "rskv3-slider" }
const _hoisted_2 = { class: "rskv3-slider-control" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueSlider = _resolveComponent("VueSlider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["rskv3-slider-bg", [
                {'rskv3-slider-bg-grayscale': _ctx.grayscale },
            ]])
    }, null, 2),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_VueSlider, {
        modelValue: _ctx.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
        "dot-size": 28,
        "rail-style": _ctx.railStyle,
        "process-style": _ctx.processStyle,
        "step-style": _ctx.stepStyle,
        marks: _ctx.marks,
        included: true,
        disabled: _ctx.isDisabled,
        tooltip: "none",
        onClick: _ctx.onClickSlider,
        onDragging: _ctx.onDrag,
        onDragEnd: _ctx.onDragEnd
      }, null, 8, ["modelValue", "rail-style", "process-style", "step-style", "marks", "disabled", "onClick", "onDragging", "onDragEnd"])
    ])
  ]))
}