import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputLabel = _resolveComponent("InputLabel")!
  const _component_InputRadio = _resolveComponent("InputRadio")!
  const _component_InputGroup = _resolveComponent("InputGroup")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_PopupDialogSplit = _resolveComponent("PopupDialogSplit")!
  const _component_PopupDialog = _resolveComponent("PopupDialog")!

  return (_openBlock(), _createBlock(_component_PopupDialog, {
    "is-visible": _ctx.dialog.visible,
    header: _ctx.$t('assessment.approval.decideTitle'),
    icon: 'thumbs-up',
    "show-cancel-button": true,
    "show-action-button": true,
    "action-text": _ctx.actionText,
    "action-icon": _ctx.actionIcon,
    "is-action-disabled": !_ctx.dialog.valid,
    "is-warning": _ctx.checkModel === 'deny',
    progress: _ctx.dialog,
    onAction: _ctx.onMakeDecision,
    onCloseModal: _ctx.onClose
  }, {
    default: _withCtx(() => [
      _createVNode(_component_PopupDialogSplit, {
        style: {"min-width":"500px","max-width":"560px"},
        "prompt-text": _ctx.$t('assessment.approval.decision.promptText'),
        "prompt-hint": _ctx.$t('assessment.approval.decision.promptHint'),
        "is-form": true
      }, {
        default: _withCtx(() => [
          _createVNode(_component_InputGroup, { width: 12 }, {
            default: _withCtx(() => [
              _createVNode(_component_InputLabel, {
                text: _ctx.$t('assessment.approval.decision.decisionPrompt'),
                "unique-id": 'approve-decision',
                required: ""
              }, null, 8, ["text"]),
              _createVNode(_component_InputRadio, {
                modelValue: _ctx.checkModel,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checkModel) = $event)),
                items: _ctx.checkItems,
                "unique-id": 'approve-decision',
                "is-inline": true,
                onInput: _ctx.validateDialog
              }, null, 8, ["modelValue", "items", "onInput"])
            ]),
            _: 1
          }),
          _createVNode(_component_InputGroup, { width: 12 }, {
            default: _withCtx(() => [
              _createVNode(_component_InputLabel, {
                text: _ctx.$t('assessment.approval.decision.comment'),
                "unique-id": 'approve-comment',
                required: _ctx.checkModel === 'deny'
              }, null, 8, ["text", "required"]),
              _createVNode(_component_InputText, {
                modelValue: _ctx.commentModel,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.commentModel) = $event)),
                "unique-id": 'approve-comment',
                onInput: _ctx.validateDialog
              }, null, 8, ["modelValue", "onInput"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["prompt-text", "prompt-hint"])
    ]),
    _: 1
  }, 8, ["is-visible", "header", "action-text", "action-icon", "is-action-disabled", "is-warning", "progress", "onAction", "onCloseModal"]))
}