<template>
    <div class="flex items-center justify-between bg-white py-3 rounded-lg mt-0 px-5">
        <div class="flex flex-1 justify-between sm:hidden">
            <a
                @click="prevPage"
                :class="['relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium', prevDisabled ? 'text-gray-400 bg-gray-100 cursor-not-allowed' : 'text-gray-700 hover:bg-gray-50']"
            >{{ $t('common.pagination.previous') }}</a>
            <a
                @click="nextPage"
                :class="['relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium', nextDisabled ? 'text-gray-400 bg-gray-100 cursor-not-allowed' : 'text-gray-700 hover:bg-gray-50']"
            >{{ $t('common.pagination.next') }}</a>
        </div>
        <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
            <div>
                <p class="text-sm">
                    {{ $t('common.page') }}
                    {{ " " }}
                    <span class="font-medium">{{ currentPage }}</span>
                    {{ " " }}
                    of
                    {{ " " }}
                    <span class="font-medium">{{ pages.length }}</span>
                    {{ " " }}
                </p>
            </div>
            <div>
                <nav class="isolate inline-flex -space-x-px rounded-md" aria-label="Pagination">
                    <div
                        @click="prevPage"
                        :class="['relative inline-flex items-center px-2', prevDisabled ? 'text-gray-400 cursor-not-allowed' : 'hover:cursor-pointer text-primary-default']"
                    >
                        <span>{{ $t('common.pagination.previous') }}</span>
                    </div>
                    <Icon icon="vertical-line" class="" />
                    <div
                        @click="nextPage"
                        :class="['relative inline-flex items-center px-2', nextDisabled ? 'text-gray-400 cursor-not-allowed' : 'hover:cursor-pointer text-primary-default']"
                    >
                        <span>{{ $t('common.pagination.next') }}</span>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, watch } from "vue";
import Icon from "../components/Icon.vue";

export default defineComponent({
    name: "Pagination",
    components: { Icon },
    props: {
        itemsCount: {
            default: 0,
            type: Number,
        },
        itemsPerPage: {
            default: 5,
            type: Number,
        },
        paginationStyle: {
            default: "long",
            type: String,
        },
        items: {
            default: [],
            type: Array,
        },
        currentPage: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            localCurrentPage: this.currentPage,
        };
    },
    emits: ["nextPage", "prevPage", "changePage"],
    methods: {
        changePage(page: number) {
            this.localCurrentPage = page;
            this.$emit("changePage", page);
        },
        nextPage() {
            if (this.localCurrentPage >= this.pages.length) return;
            this.changePage(this.localCurrentPage + 1);
        },
        prevPage() {
            if (this.localCurrentPage <= 1) return;
            this.changePage(this.localCurrentPage - 1);
        },
    },
    computed: {
        pages(): number[] {
            const pageCount = Math.ceil(this.itemsCount / this.itemsPerPage);
            return Array.from({ length: pageCount }, (_, i) => i + 1);
        },
        prevDisabled(): boolean {
            return this.localCurrentPage <= 1;
        },
        nextDisabled(): boolean {
            return this.localCurrentPage >= this.pages.length;
        },
    },
    watch: {
        currentPage(newPage: number) {
            this.localCurrentPage = newPage;
        },
    },
});
</script>
