import { Verified } from "../verified_plugin";

export interface PolicyGroup {
    id: string;
    namespace: string;
    name: string;
    description: string;
    policies: string[];
    members: string[];
}

export const getPolicyGroups = async () => {
    try {
        const response = await fetch("/api/aml/v1/policy/groups", {
            method: "GET",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
        });

        const body = await response.json();

        if (!response.ok) {
            throw new Error(body);
        }

        return body as { total: number; policyGroups: PolicyGroup[] };
    } catch (err) {
        console.error(`Failed to query for policy groups`, err);
        throw err;
    }
};

export const updatePolicyGroup = async (
    policyGroupId: string,
    options: {
        name: string;
        description: string;
    }
): Promise<PolicyGroup> => {
    try {
        const response = await fetch(`/api/aml/v1/policy/groups/${policyGroupId}`, {
            method: "PUT",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
            body: JSON.stringify({
                ...options,
            }),
        });

        const body = await response.json();

        if (!response.ok) {
            throw new Error(body);
        }

        return body as PolicyGroup;
    } catch (err) {
        console.error(`Failed to update policy group`, err);
        throw err;
    }
};

export const createPolicyGroup = async (options: { name: string; description?: string }): Promise<PolicyGroup> => {
    try {
        const response = await fetch(`/api/aml/v1/policy/groups`, {
            method: "POST",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
            body: JSON.stringify({
                ...options,
            }),
        });

        const body = await response.json();

        if (!response.ok) {
            throw new Error(body);
        }

        return body as PolicyGroup;
    } catch (err) {
        console.error(`Failed to create policy group`, err);
        throw err;
    }
};

export const addMembersToPolicyGroup = async (policyGroupId: string, members: string[]): Promise<PolicyGroup> => {
    try {
        const response = await fetch(`/api/aml/v1/policy/groups/${policyGroupId}/members/add`, {
            method: "PUT",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
            body: JSON.stringify(members),
        });

        const body = await response.json();

        if (!response.ok) {
            throw new Error(body);
        }

        return body as PolicyGroup;
    } catch (err) {
        console.error(`Failed to add members to policy group`, err);
        throw err;
    }
};

export const removeMembersFromPolicyGroup = async (policyGroupId: string, members: string[]): Promise<PolicyGroup> => {
    try {
        const response = await fetch(`/api/aml/v1/policy/groups/${policyGroupId}/members/remove`, {
            method: "PUT",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
            body: JSON.stringify(members),
        });

        const body = await response.json();

        if (!response.ok) {
            throw new Error(body);
        }

        return body as PolicyGroup;
    } catch (err) {
        console.error(`Failed to remove members from policy group`, err);
        throw err;
    }
};

export const addPolicyToGroup = async (policyGroupId: string, policies: string[]): Promise<PolicyGroup> => {
    try {
        const response = await fetch(`/api/aml/v1/policy/groups/${policyGroupId}/policies/add`, {
            method: "PUT",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
            body: JSON.stringify(policies),
        });

        const body = await response.json();

        if (!response.ok) {
            throw new Error(body);
        }

        return body as PolicyGroup;
    } catch (err) {
        console.error(`Failed to add policy to policy group`, err);
        throw err;
    }
};

export const removePolicyFromGroup = async (policyGroupId: string, policies: string[]): Promise<PolicyGroup> => {
    try {
        const response = await fetch(`/api/aml/v1/policy/groups/${policyGroupId}/policies/remove`, {
            method: "PUT",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
            body: JSON.stringify(policies),
        });

        const body = await response.json();

        if (!response.ok) {
            throw new Error(body);
        }

        return body as PolicyGroup;
    } catch (err) {
        console.error(`Failed to remove policies from policy group`, err);
        throw err;
    }
};

export const deletePolicyGroup = async (policyGroupId: string) => {
    try {
        const response = await fetch(`/api/aml/v1/policy/groups/${policyGroupId}`, {
            method: "DELETE",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
        });

        const body = await response.json();

        if (!response.ok) {
            throw new Error(body);
        }
    } catch (err) {
        console.error(`Failed to delete policy group`, err);
        throw err;
    }
};
