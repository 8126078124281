<template>
    <div>

        <HeaderWithAddIcon :text="'Risk Matrices'" @clicked="showAddMatrixDialog" />

        <div v-if="isLoading">
            <LoadAnimationBar />
            <LoadAnimationBar />
            <LoadAnimationBar />
        </div>

        <div v-if="!isLoading && !isError" class="mt-3">
            <RiskSettingsMatrixItem
                v-for="(matrix, i) in matrices"
                :key="'rm' + i"
                :matrix="matrix"
                :config-name="getConfigName(matrix)"
                @open="onMatrixClicked(matrix)"
                @edit="showEditMatrixDialog(matrix)"
                @promote="showPromoteDialog(matrix)"
                @delete="showDeleteDialog(matrix)"
            />
        </div>

        <NetworkError
            v-if="isError"
            :text="'Something went wrong when fetching risk matrices'"
            @retry="loadMatrices"
        />

        <div class="my-8 border-b border-gray-300"></div>

        <HeaderWithAddIcon :text="'Risk Configurations'" :hide-icon="true" />

        <div class="mt-3">
            <RiskSettingsConfigItem
                v-for="(config, i) in originalList"
                :key="'rc' + i"
                :config="config"
                @edit="showConfigDialog(config)"
                @delete="showDeleteConfigDialog"
            />
        </div>

        <RiskSettingsMatrixDialog
            v-if="matrixDialog.visible"
            :matrix="matrixDialog.matrix"
            @close-modal="matrixDialog.visible = false"
            @reload="loadMatrices"
        />

        <!-- Promote Dialog -->
        <PopupDialog
            :is-visible="promoteDialog.visible"
            :header="'Promote'"
            :icon="'code-pull-request'"
            :show-action-button="true"
            :show-cancel-button="true"
            :is-action-disabled="!promoteDialog.valid"
            :action-text="'Promote'"
            :action-icon="'code-pull-request'"
            :progress="promoteDialog"
            @close-modal="promoteDialog.visible = false"
            @action="promoteMatrix"
        >
            <PopupDialogPrompt
                :prompt-text="'Are you sure you want to promote this risk matrix?'"
                :info-text="`There are ${promoteDialog.count} projects using this risk matrix!`"
                :is-loading="promoteDialog.isWorking"
            />
        </PopupDialog>

        <!-- Delete Matrix Dialog -->
        <PopupDialog
            :is-visible="deleteDialog.visible"
            :is-warning="true"
            :header="'Delete Risk Matrix'"
            :icon="'trash-can'"
            :show-action-button="true"
            :show-cancel-button="true"
            :is-action-disabled="deleteDialog.count !== 0"
            :action-text="'Delete'"
            :action-icon="'trash-can'"
            :progress="deleteDialog"
            @close-modal="deleteDialog.visible = false"
            @action="onDeleteMatrix"
        >
            <PopupDialogPrompt
                :is-warning="true"
                :prompt-text="'Are you sure you want to delete this risk matrix?'"
                :error-text="'This risk matrix can not be deleted!'"
                :error-reason="`There are ${deleteDialog.count} workflows using this risk matrix`"
                :is-loading="deleteDialog.isWorking"
                :can-proceed="deleteDialog.count === 0"
            />
        </PopupDialog>

        <RiskSettingsConfigDialog
            v-if="configDialog.visible"
            :config="configDialog.config"
            @close-modal="configDialog.visible = false"
            @save-modal="onSomethingChanged"
        />

        <!-- Delete Configuration Dialog -->
        <PopupDialog
            :is-visible="deleteConfigDialog.visible"
            :is-warning="true"
            :header="'Delete'"
            :icon="'trash-can'"
            :show-action-button="true"
            :show-cancel-button="true"
            :is-action-disabled="!deleteConfigDialog.valid"
            :action-text="'Delete'"
            :action-icon="'trash-can'"
            @close-modal="deleteConfigDialog.visible = false"
        >
            <PopupDialogPrompt
                :is-warning="true"
                :prompt-text="'Delete?'"
                :error-text="'The default risk configuration can not be deleted!'"
                :error-reason="'The default risk configuration can not be deleted!'"
                :can-proceed="false"
            />
        </PopupDialog>

    </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

import { getRiskMatrices } from "@/lib/risk/get_matrices";
import { RiskMatrixDefinition } from "@/lib/risk/risk_matrix";

import LoadAnimationBar from "@/ui/LoadAnimationBar.vue";
import NetworkError from "@/ui/NetworkError.vue";
import PopupDialog from "@/ui/PopupDialog.vue";

import { RiskConfig } from "@/lib/settings/settings";
import HeaderWithAddIcon from "./HeaderWithAddIcon.vue";
import RiskSettingsConfigItem from "./RiskSettingsConfigItem.vue";
import RiskSettingsMatrixItem from "./RiskSettingsMatrixItem.vue";

import { ApiResponse } from "@/lib/error_parser";
import { deleteRiskMatrix } from "@/lib/risk/delete_matrix";
import { promoteRiskMatrix } from "@/lib/risk/promote_matrix";
import { getWorkflowProjectCount } from "@/lib/workflows/count_workflow_projects";
import PopupDialogPrompt from "@/ui/dialog/PopupDialogPrompt.vue";
import RiskSettingsConfigDialog from "./RiskSettingsConfigDialog.vue";
import RiskSettingsMatrixDialog from "./RiskSettingsMatrixDialog.vue";
import { updateSettings } from "@/lib/settings/update_settings";
import { querySettings } from "@/lib/settings/get_settings";

export default defineComponent({
    name: "RiskMatrices",
    components: {
        LoadAnimationBar,
        PopupDialog,
        NetworkError,
        HeaderWithAddIcon,
        RiskSettingsMatrixItem,
        RiskSettingsConfigItem,
        RiskSettingsMatrixDialog,
        RiskSettingsConfigDialog,
        PopupDialogPrompt,
    },
    data() {
        return {
            isLoading: true,
            isError: false,
            errorText: "",
            matrices: new Array<RiskMatrixDefinition>(),
            matrixDialog: { visible: false, matrix: null as RiskMatrixDefinition | null },
            promoteDialog: { visible: false, valid: false, isWorking: false, statusText: "", isError: false, errorText: "", key: "", count: -1 },
            deleteDialog: { visible: false, valid: false, isWorking: false, statusText: "", isError: false, errorText: "", key: "", count: -1 },
            configDialog: { visible: false, config: {} as RiskConfig },
            deleteConfigDialog: { visible: false, valid: false },

            originalList: new Array<RiskConfig>(),
        };
    },
    methods: {

        async onSomethingChanged() {
            const data = {
                riskConfigs: this.originalList,
            };
            await updateSettings(data);
            const settings = await querySettings();
            this.$config.update(settings);
            this.loadRiskConfigs();
        },

        getConfigName(matrix: RiskMatrixDefinition) {
            for (const i in this.originalList) {
                if (this.originalList[i].key === matrix.configKey) return this.originalList[i].name;
            }
            return "Unknown Risk Configuration";
        },

        showAddMatrixDialog() {
            this.matrixDialog.matrix = null;
            this.matrixDialog.visible = true;
        },

        showEditMatrixDialog(matrix: RiskMatrixDefinition) {
            this.matrixDialog.matrix = matrix;
            this.matrixDialog.visible = true;
        },

        async showPromoteDialog(matrix: RiskMatrixDefinition) {
            this.promoteDialog.count = -1;
            this.promoteDialog.isError = false;

            this.promoteDialog.isWorking = true;
            this.promoteDialog.statusText = "Checking workflows";

            this.promoteDialog.key = matrix.key;
            this.promoteDialog.visible = true;

            const workflows = this.$config.settings.workflows || [];
            const promises: Promise<{ count: number | null, responseInfo: ApiResponse }>[] = [];
            workflows.forEach((workflow) => {
                if (workflow.params.matrixKey === this.promoteDialog.key) {
                    promises.push(getWorkflowProjectCount(workflow.key));
                }
            });
            const responses = await Promise.allSettled(promises);

            let count = 0;
            responses.forEach((response) => {
                if (response.status === "fulfilled") {
                    if (response.value.responseInfo.isError || response.value.count == null) {
                        this.promoteDialog.isError = true;
                        this.promoteDialog.errorText = response.value.responseInfo.parsedError?.message || "Unknown error";
                    } else {
                        count += response.value.count;
                    }
                } else {
                    this.promoteDialog.isError = true;
                    this.promoteDialog.errorText = "Unable to check workflows!";
                }
            });

            this.promoteDialog.valid = true;
            this.promoteDialog.count = count;
            this.promoteDialog.isWorking = false;
        },

        async promoteMatrix() {
            this.promoteDialog.isWorking = true;
            this.promoteDialog.statusText = "Promoting risk matrix";

            const response = await promoteRiskMatrix(this.promoteDialog.key);

            if (response.responseInfo.isError) {
                this.promoteDialog.isError = true;
                this.promoteDialog.errorText = response.responseInfo.parsedError?.message || "Unknown error";
            } else {
                this.loadMatrices();
                this.promoteDialog.visible = false;
            }
            this.promoteDialog.isWorking = false;
        },

        async showDeleteDialog(matrix: RiskMatrixDefinition) {
            let count = 0;
            const workflows = this.$config.settings.workflows || [];
            workflows.forEach((workflow) => {
                if (workflow.params.matrixKey === matrix.key) count += 1;
            });
            this.deleteDialog.valid = (count === 0);
            this.deleteDialog.count = count;
            this.deleteDialog.key = matrix.key;
            this.deleteDialog.visible = true;
        },

        async onDeleteMatrix() {
            this.deleteDialog.isWorking = true;
            this.deleteDialog.statusText = "Deleting risk matrix";

            const response = await deleteRiskMatrix(this.deleteDialog.key);

            if (response.responseInfo.isError) {
                this.deleteDialog.isError = true;
                this.deleteDialog.errorText = response.responseInfo.parsedError?.message || "Unknown error";
            } else {
                this.loadMatrices();
                this.deleteDialog.visible = false;
            }
            this.deleteDialog.isWorking = false;
        },

        showConfigDialog(config: RiskConfig) {
            console.log("config", config);
            this.configDialog.config = config;
            this.configDialog.visible = true;
        },

        showDeleteConfigDialog() {
            this.deleteConfigDialog.visible = true;
        },

        onMatrixClicked(matrix: RiskMatrixDefinition) {
            this.$router.push({ path: "/aml/matrix", query: { key: matrix.key } });
        },

        async loadMatrices() {
            this.isError = false;
            this.isLoading = true;

            const response = await getRiskMatrices();

            if (response.responseInfo.isError || !response.matrices) {
                this.isError = true;
                this.errorText = response.responseInfo.parsedError?.message || "";
            } else {
                this.matrices = response.matrices;
            }
            this.isLoading = false;
        },

        loadRiskConfigs() {
            this.originalList = this.$config.settings.riskConfigs || [];
        },

    },
    created() {
        this.loadRiskConfigs();
        this.loadMatrices();
    },
});
</script>
