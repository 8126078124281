import { Verified } from "../verified_plugin";
import { Attachment } from "../persons/person";
import { parseRemoteError } from "../error_parser";

export const addAttachment = async (attachment: Attachment, projectId: string) => {
    try {
        const response = await fetch(`/api/aml/v1/projects/${projectId}/attachments`, {
            method: "post",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
            body: JSON.stringify({
                ...attachment,
            }),
        });

        const body = await response.json();

        if (!response.ok) {
            return {
                url: "",
                fileId: "",
                responseInfo: { isError: true, parsedError: parseRemoteError(body) },
            };
        }

        return body as {url: string, fileId: string};
    } catch (err) {
        console.error("Failed to query projects", err);
        return {
            url: "",
            fileId: "",
            responseInfo: { isError: true, parsedError: parseRemoteError(err) },
        };
    }
};
