<template>
    <div>
        <PopupDialog
            :is-visible="dialog.visible"
            :header="'Risk Configuration'"
            :icon="'wand-magic-sparkles'"
            :show-cancel-button="true"
            :show-action-button="true"
            :action-text="'Save'"
            :action-icon="'floppy-disk'"
            :is-action-disabled="!dialog.valid"
            @action="onSave"
            @close-modal="onClose"
        >
            <PopupDialogSplit style="min-width: 500px; max-width: 560px;">
                <template #top>

                    <div style="display: flex;">
                        <div style="flex-grow: 1;">
                            <div class="rskv3-config-minmax-desc">
                                {{ 'Minimum Risk Value' }}
                            </div>
                            <div>
                                <RiskSettingsIncrementor
                                    :value="config.minScore"
                                    @change="onRangeClick(true, $event)"
                                />
                            </div>
                        </div>
                        <div>
                            <div class="rskv3-config-minmax-desc">
                                {{ 'Maximum Risk Value' }}
                            </div>
                            <div style="display: flex; flex-direction: row-reverse;">
                                <RiskSettingsIncrementor
                                    :value="config.maxScore"
                                    @change="onRangeClick(false, $event)"
                                />
                            </div>
                        </div>
                    </div>

                </template>

                <div class="amlrsk-sett-visual-container">
                    <div
                        class="amlrsk-sett-visual"
                        v-for="(threshold, i) in config.thresholds"
                        :key="'rsv' + i"
                        :style="'width: ' + widths[i] + '%;'"
                    >
                        {{ $ct(threshold.text) }}
                        <div
                            class="amlrsk-sett-visual-bar"
                            v-if="i > 0"
                        />
                        <div
                            class="amlrsk-sett-visual-value"
                            v-if="i > 0"
                        >
                            {{ threshold.from.toFixed(1) }}
                        </div>
                    </div>
                    <div class="amlrsk-sett-visual-left-bar" />
                    <div class="amlrsk-sett-visual-right-bar" />
                    <div class="amlrsk-sett-visual-left-value">
                        {{ config.minScore.toFixed(1) }}
                    </div>
                    <div class="amlrsk-sett-visual-right-value">
                        {{ config.maxScore.toFixed(1) }}
                    </div>
                </div>

                <div class="rskv3-config-thresholds">
                    <div
                        class="rskv3-config-threshold"
                        v-for="(threshold, i) in config.thresholds"
                        :key="'th' + i"
                    >
                        <div
                            class="rskv3-config-threshold-color"
                            :style="`background-color: ${threshold.color};`"
                        />
                        <div class="rskv3-config-threshold-text">
                            {{ $ct(threshold.text) }}
                        </div>
                        <div v-if="i > 0">
                            <RiskSettingsIncrementor
                                :value="threshold.from"
                                :decimals="1"
                                @change="onItemClick(i, $event)"
                            />
                        </div>
                        <div
                            class="rskv3-config-threshold-edit"
                            @click="showThresholdDialog(threshold)"
                        >
                            <FontIcon
                                :icon="'pen'"
                                :size="16"
                            />
                        </div>
                    </div>
                </div>

                <div>
                    <ButtonSingle
                        is-link
                        :text="'Add Threshold'"
                        @clicked="showThresholdDialog(null)"
                    />
                </div>

            </PopupDialogSplit>

            <div class="rskv3-config-hint">
                {{ 'Hold "ctrl" to increment with 0.1, hold "shift" to increment with 10.' }}
            </div>

        </PopupDialog>

        <PopupDialog
            :is-visible="thresholdDialog.visible"
            :header="'Risk Threshold'"
            :icon="'arrows-left-right'"
            :show-cancel-button="true"
            :show-action-button="true"
            :action-text="'Save'"
            :action-icon="'floppy-disk'"
            :is-action-disabled="!thresholdDialog.valid"
            @action="saveThresholdDialog"
            @close-modal="closeThresholdDialog"
        >
            <PopupDialogSplit
                is-form
                :prompt-text="'Add a new threshold:'"
                :prompt-hint="'A new threshold will be added.'"
            >

                <InputGroup :width="12">
                    <InputLabel
                        :text="'Name'"
                        :unique-id="'end-name'"
                    />
                    <InputTextLocalized
                        v-model="thresholdModel.text"
                        :unique-id="'end-name'"
                        @input="validateThresholdDialog"
                    />
                </InputGroup>

                <InputGroup :width="6">
                    <InputLabel
                        :text="'Key'"
                        :unique-id="'end-key'"
                    />
                    <InputText
                        v-model="thresholdModel.key"
                        :unique-id="'end-key'"
                        :disabled="thresholdDialog.key !== ''"
                        @input="validateThresholdDialog"
                    />
                </InputGroup>

                <InputGroup :width="6">
                    <InputLabel
                        :text="'Color'"
                        :unique-id="'end-color'"
                    />
                    <InputColor
                        v-model="thresholdModel.color"
                        :unique-id="'end-color'"
                        @input="validateThresholdDialog"
                    />
                </InputGroup>

            </PopupDialogSplit>
        </PopupDialog>

    </div>
</template>
<script lang="ts">
/* eslint-disable vue/no-mutating-props */
// todo: fix this so the cancel button works

import { defineComponent, PropType } from "vue";

import { RiskConfig, RiskThreshold } from "@/lib/settings/settings";
import PopupDialog from "@/ui/PopupDialog.vue";

import InputGroup from "@/ui/inputs/InputGroup.vue";
import InputLabel from "@/ui/inputs/InputLabel.vue";
import InputText from "@/ui/inputs/InputText.vue";
import InputTextLocalized from "@/ui/inputs/InputTextLocalized.vue";

import { LocalizedString } from "@/lib/common/common";
import RiskSettingsIncrementor from "./RiskSettingsIncrementor.vue";

import ButtonSingle from "@/ui/ButtonSingle.vue";
import PopupDialogSplit from "@/ui/dialog/PopupDialogSplit.vue";
import FontIcon from "@/ui/FontIcon.vue";
import InputColor from "@/ui/inputs/InputColor.vue";

export default defineComponent({
    name: "RiskSettingsConfigDialog",
    components: {
        PopupDialog,
        InputGroup,
        InputLabel,
        InputText,
        InputTextLocalized,
        InputColor,
        RiskSettingsIncrementor,
        ButtonSingle,
        PopupDialogSplit,
        FontIcon,
    },
    emits: ["save-modal", "close-modal"],
    props: {
        config: { type: Object as PropType<RiskConfig>, required: true },
    },
    data() {
        return {
            dialog: { visible: true, valid: true },
            widths: new Array<number>(),
            thresholdDialog: { visible: false, valid: false, key: "" },
            thresholdModel: {
                key: "",
                color: "#218BCB",
                text: {} as LocalizedString,
            },
        };
    },
    methods: {

        showThresholdDialog(threshold: RiskThreshold | null) {
            if (threshold) {
                this.thresholdModel.key = threshold.key;
                this.thresholdModel.color = threshold.color;
                this.thresholdModel.text = threshold.text;
                this.thresholdDialog.key = threshold.key;
            } else {
                this.thresholdDialog.key = "";
            }
            this.dialog.visible = false;
            this.$nextTick(() => {
                this.thresholdDialog.visible = true;
            });
        },

        closeThresholdDialog() {
            this.thresholdDialog.visible = false;
            this.$nextTick(() => {
                this.dialog.visible = true;
            });
        },

        validateThresholdDialog() {
            this.thresholdDialog.valid = true;
        },

        saveThresholdDialog() {
            if (this.thresholdDialog.key) {
                for (const threshold of this.config.thresholds) {
                    if (threshold.key === this.thresholdDialog.key) {
                        threshold.color = this.thresholdModel.color;
                        threshold.text = this.thresholdModel.text;
                    }
                }
            } else {
                this.config.thresholds.push({
                    key: this.thresholdModel.key,
                    color: this.thresholdModel.color,
                    text: this.thresholdModel.text,
                    from: 1,
                    to: 1,
                });
            }
            this.closeThresholdDialog();
            this.redistributeThresholds();
        },

        onSave() {
            this.$emit("save-modal");
            this.onClose();
        },

        onClose() {
            if (this.thresholdDialog.visible) return;
            this.$emit("close-modal");
        },

        onRangeClick(isMin: boolean, changeValue: number) {
            if (isMin) {
                this.config.minScore = Number((this.config.minScore + changeValue).toFixed(0));
            } else {
                this.config.maxScore = Number((this.config.maxScore + changeValue).toFixed(0));
            }
            this.validateRange(isMin);
            this.redistributeThresholds();
            this.calculateThresholds();
        },

        validateRange(isMin: boolean) {
            if (isMin) {
                if (this.config.minScore < 0) this.config.minScore = 0;
                if (this.config.minScore > this.config.maxScore - 1) this.config.minScore = this.config.maxScore - 1;
            } else {
                if (this.config.maxScore > 100) this.config.maxScore = 100;
                if (this.config.maxScore < this.config.minScore + 1) this.config.maxScore = this.config.minScore + 1;
            }
        },

        onItemClick(index: number, changeValue: number) {
            this.config.thresholds[index].from = Number((this.config.thresholds[index].from + changeValue).toFixed(1));
            this.validateItem(index);
            this.calculateThresholds();
        },

        validateItem(index: number) {
            let minValue = Number(this.config.thresholds[Number(index) - 1].from || 0);
            if (index < 2) minValue = this.config.minScore;
            let maxValue = this.config.maxScore;
            if (index < this.config.thresholds.length - 1) maxValue = Number(this.config.thresholds[Number(index) + 1].from || 0);
            if (Number(this.config.thresholds[index].from) < minValue + 0.5) this.config.thresholds[index].from = minValue + 0.5;
            if (Number(this.config.thresholds[index].from) > maxValue - 0.5) this.config.thresholds[index].from = maxValue - 0.5;
        },

        redistributeThresholds() {
            const fac = (this.config.maxScore - this.config.minScore) / this.config.thresholds.length;
            for (const i in this.config.thresholds) {
                if (i === "0") continue;
                const threshold = this.config.thresholds[i];
                const value = Number(this.config.minScore + (fac * Number(i))).toFixed(1);
                threshold.from = Number(value);
                this.config.thresholds[Number(Number(i) - 1)].to = threshold.from;
            }
            this.config.thresholds[this.config.thresholds.length - 1].to = this.config.maxScore;
            this.calculateThresholds();
        },

        calculateThresholds() {
            this.widths = [];
            const fac = Number(100 / (Number(this.config.maxScore) - Number(this.config.minScore)));
            for (const i in this.config.thresholds) {
                let thisValue = this.config.minScore;
                let nextValue = this.config.maxScore;
                if (Number(i) > 0) thisValue = this.config.thresholds[i].from;
                if (Number(i) < this.config.thresholds.length - 1) nextValue = this.config.thresholds[Number(i) + 1].from;
                const span = Number(nextValue) - Number(thisValue);
                const width = fac * span;
                this.widths.push(width);
            }
        },

    },
    mounted() {
        this.calculateThresholds();
    },
});
</script>
<style scoped>
.rskv3-config-minmax-desc {
    font-size: 14px;
}

/* Visual */

.amlrsk-sett-visual-container {
    position: relative;
    float: left;
    width: 100%;
    padding-top: 26px;
    box-sizing: border-box;
}

.amlrsk-sett-visual {
    position: relative;
    float: left;
    height: 24px;
    padding: 0px 15px;
    text-align: center;
    border: solid 1px lightgray;
    box-sizing: border-box;
    background-color: whitesmoke;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.amlrsk-sett-visual-bar {
    position: absolute;
    width: 3px;
    height: 28px;
    top: -5px;
    left: -1px;
    background-color: black;
}

.amlrsk-sett-visual-value {
    position: absolute;
    width: 101px;
    top: -26px;
    left: -50px;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    color: black;
}

.amlrsk-sett-visual-left-bar {
    position: absolute;
    width: 3px;
    height: 28px;
    top: 22px;
    left: 0px;
    background-color: black;
}

.amlrsk-sett-visual-left-value {
    position: absolute;
    top: 1px;
    left: 0px;
    font-size: 18px;
    font-weight: 500;
    color: black;
}

.amlrsk-sett-visual-right-bar {
    position: absolute;
    width: 3px;
    height: 28px;
    top: 22px;
    right: 0px;
    background-color: black;
}

.amlrsk-sett-visual-right-value {
    position: absolute;
    top: 1px;
    right: 0px;
    font-size: 18px;
    font-weight: 500;
    color: black;
}

/* Thresholds */

.rskv3-config-thresholds {
    float: left;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.rskv3-config-threshold {
    display: flex;
    margin-top: 5px;
}

.rskv3-config-threshold-color {
    width: 26px;
    height: 26px;
    border-radius: 4px;
    border: solid 1px lightgrey;
}

.rskv3-config-threshold-text {
    flex-grow: 1;
    margin-left: 10px;
    font-size: 17px;
}

.rskv3-config-threshold-edit {
    width: 34px;
    height: 31px;
    margin-left: 10px;
    padding-top: 3px;
    text-align: center;
    border-radius: 4px;
    border: solid 1px lightgray;
    background-color: whitesmoke;
    cursor: pointer;
}

.rskv3-config-threshold-edit:hover {
    background-color: lightgray;
}

/* Bottom */

.rskv3-config-hint {
    float: left;
    width: 100%;
    padding-top: 8px;
    font-size: 14px;
    font-style: italic;
    color: dimgray;
}
</style>
