import { FlowConditions, WorkflowConfig } from "@/types/flow_types";
import { Setting } from "../settings/settings";

export const getValidWorkflowConfigs = (conditions: FlowConditions, settings: Setting): WorkflowConfig[] => {
    const list: WorkflowConfig[] = [];

    /* console.log("getValidWorkflowConfigs", conditions); */

    const configs = settings.settings.workflows || [];

    configs.forEach((config) => {
        let fitsConditions = true;

        if (!config.enabled) fitsConditions = false;

        if (config.conditions.type !== conditions.type) {
            fitsConditions = false;
        }

        if (fitsConditions) {
            list.push(config);
        }
    });

    return list;
};
