<template>
    <div>

        <CustomerTopAction
            :icon="'list-check'"
            :text="getWorkflowName()"
            :tooltip="$t('project.workflow.title')"
            :is-clickable="true"
            @clicked="showDialog"
        />

        <PopupDialog
            :is-visible="dialog.visible"
            :header="$t('project.workflow.changeAction')"
            :icon="'repeat'"
            :progress="dialog"
            :show-cancel-button="true"
            :show-action-button="!assessment.locked"
            :action-icon="'repeat'"
            :is-action-disabled="!(selectedKey !== '' && selectedKey !== project.workflow)"
            :action-text="$t('project.workflow.changeAction')"
            :is-warning="assessment.locked"
            @close-modal="dialog.visible = false"
            @action="changeWorkflow"
        >
            <div v-if="!assessment.locked">
                <SimpleListItem
                    v-for="(flow, i) in workflows"
                    :key="'cwf' + i"
                    :text="$ct(flow.name)"
                    :sub-text="flow.key"
                    :is-selected="flow.key === selectedKey"
                    :is-first="i === 0"
                    :is-last="i === workflows.length - 1"
                    @clicked="selectWorkflow(flow)"
                />
            </div>

            <PopupDialogPrompt
                v-if="assessment.locked"
                :is-warning="true"
                :prompt-text="$t('project.workflow.lockedPrompt')"
                :info-text="$t('project.workflow.lockedHint')"
            />

        </PopupDialog>

    </div>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";

import { Project } from "@/lib/projects/projects";
import { UpdateProjectOptions, updateProject } from "@/lib/projects/update_project";

import { getValidWorkflowConfigs } from "@/lib/workflows/workflow_helper";
import { FlowConditions, WorkflowConfig } from "@/types/flow_types";

import PopupDialog from "@/ui/PopupDialog.vue";
import SimpleListItem from "@/ui/SimpleListItem.vue";
import CustomerTopAction from "./CustomerTopAction.vue";
import { Assessment } from "@/lib/assessments/assessments";
import { getProjectProcess } from "@/lib/projects/get_project";
import PopupDialogPrompt from "@/ui/dialog/PopupDialogPrompt.vue";

export default defineComponent({
    name: "CustomerWorkflowSelector",
    components: {
        CustomerTopAction,
        PopupDialog,
        SimpleListItem,
        PopupDialogPrompt,
    },
    emits: ["request-project-refresh"],
    props: {
        project: { type: Object as PropType<Project>, required: true },
        assessment: { type: Object as PropType<Assessment>, required: true },
    },
    data() {
        return {
            dialog: { visible: false, isWorking: false, statusText: "", isError: false, errorText: "" },
            workflows: new Array<WorkflowConfig>(),
            selectedKey: "",
        };
    },
    methods: {

        getWorkflowName(): string {
            for (const i in this.$config.settings.workflows) {
                if (this.$config.settings.workflows[Number(i)].key === this.project.workflow) {
                    return this.$config.settings.workflows[Number(i)].name.en_EN || "";
                }
            }
            return "Unknown Workflow";
        },

        showDialog() {
            this.selectedKey = this.project.workflow;
            this.dialog.visible = true;
            this.loadValidWorkflows();
        },

        loadValidWorkflows() {
            const conditions: FlowConditions = {
                type: this.project.type,
                countries: [],
                flags: [],
            };
            this.workflows = getValidWorkflowConfigs(conditions, this.$config.rawSettings);
        },

        selectWorkflow(workflow: WorkflowConfig) {
            this.selectedKey = workflow.key;
        },

        async changeWorkflow() {
            this.dialog.isError = false;
            this.dialog.isWorking = true;
            this.dialog.statusText = this.$t("project.workflow.working");
            const options: UpdateProjectOptions = {
                workflow: this.selectedKey,
            };
            const response = await updateProject(this.project.id, options);
            if (response.responseInfo.isError) {
                this.dialog.errorText = response.responseInfo.parsedError?.message || "Unknown error";
                this.dialog.isError = true;
            } else {
                const projectResponse = await getProjectProcess(this.project.id);
                this.$emit("request-project-refresh", projectResponse);
                this.dialog.visible = false;
            }
            this.dialog.isWorking = false;
        },

    },
});
</script>
