import { RiskScores } from "@/lib/risk/risk_matrix";
import { RiskAssets } from "../risk_assets";
import { RiskNestedItem, RiskNestedListData } from "../risk_nested_list";
import { LocalizedString } from "@/lib/common/common";

export const getNaceCodes = (assets: RiskAssets): RiskNestedListData => {
    // This will parse the "public/assets/organization/nace_codes.json"
    // file into the NestedList format
    const items: RiskNestedItem[] = [];

    const nace = assets.naceCodes;
    if (!nace) {
        return {
            items,
            options: {
                limit: 0,
            },
        };
    }

    for (const categoryKey in nace.categories) {
        const categoryItem: RiskNestedItem = {
            level: 0,
            scoreKey: "n" + categoryKey,
            code: categoryKey,
            text: getLocalizedStringNace(nace.categories[categoryKey]),
            showCode: true,
            codeWidth: 23,
            expanded: false,
            items: [],
        };
        items.push(categoryItem);
    }

    for (const level2Key in nace.codes) {
        const level2Code = nace.codes[level2Key];

        let category = null;
        for (const i in items) {
            if (items[i].code === level2Code.letter) category = items[i];
        }
        if (!category || !category.items) continue;

        const level2Item: RiskNestedItem = {
            level: 1,
            scoreKey: "n" + level2Code.letter + level2Code.code,
            code: level2Code.code,
            text: getLocalizedStringNace(level2Code.text),
            showCode: true,
            codeWidth: 31,
            expanded: false,
            items: [],
        };
        for (const level3Key in level2Code) {
            if ("1234567890".includes(level3Key)) {
                const level3Code = level2Code[level3Key];
                const level3Item: RiskNestedItem = {
                    level: 2,
                    scoreKey: "n" + level2Code.letter + level2Code.code + level3Key,
                    code: level3Code.code,
                    text: getLocalizedStringNace(level3Code.text),
                    showCode: true,
                    codeWidth: 45,
                    expanded: false,
                    items: [],
                };
                for (const level4Key in level3Code) {
                    if ("1234567890".includes(level4Key)) {
                        const level4Code = level3Code[level4Key];
                        const level4Item: RiskNestedItem = {
                            level: 3,
                            scoreKey: "n" + level2Code.letter + level2Code.code + level3Key + level4Key,
                            code: level4Code.code,
                            text: getLocalizedStringNace(level4Code.text),
                            showCode: true,
                            codeWidth: 55,
                            expanded: false,
                            items: [],
                        };
                        for (const level5Key in level4Code) {
                            if ("1234567890".includes(level5Key)) {
                                const level5Code = level4Code[level5Key];
                                const level5Item: RiskNestedItem = {
                                    level: 4,
                                    scoreKey: "n" + level2Code.letter + level2Code.code + level3Key + level4Key + level5Key,
                                    code: level5Code.code,
                                    text: getLocalizedStringNace(level5Code.text),
                                    showCode: true,
                                    codeWidth: 65,
                                    expanded: false,
                                };
                                level4Item.items?.push(level5Item);
                            }
                        }
                        level3Item.items?.push(level4Item);
                    }
                }
                level2Item.items?.push(level3Item);
            }
        }
        category.items.push(level2Item);
    }
    return {
        items,
        options: {
            limit: 5,
        },
    };
};

export const getNaceDefaultScores = (assets: RiskAssets): RiskScores => {
    const scores: RiskScores = {};

    const asset = assets.naceCodes;

    for (const categoryKey in asset.categories) {
        scores["n" + categoryKey] = 5;
    }

    return scores;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getLocalizedStringNace = (code: any): LocalizedString => {
    const text: LocalizedString = {};

    if (code.en_EN) text.en_EN = code.en_EN;
    if (code.nb_NO) text.nb_NO = code.nb_NO;
    if (code.sv_SE) text.sv_SE = code.sv_SE;
    if (code.fi_FI) text.fi_FI = code.fi_FI;

    return text;
};

interface ParseResult {
    level2: string;
    level3: string;
    level4: string;
    level5?: string;
}

export const getNaceCodeDescription = (
    code: string,
    assets: RiskAssets,
    language: string,
): string => {
    const stringValue = code;
    /* console.log("stringValue", stringValue); */
    const parseResult = parseNaceCode(stringValue);

    /* console.log("parseResult", parseResult); */

    const codes = assets.naceCodes.codes;
    /* const level2 = codes[parseResult.level2];
    const level3 = codes[parseResult.level2][parseResult.level3]; */
    const level4 = codes[parseResult.level2][parseResult.level3][parseResult.level4];
    /* const category = assets.naceCodes.categories[level2.letter]; */

    return level4.text[language];
};

const parseNaceCode = (value: string): ParseResult => {
    const result: ParseResult = {
        level2: "",
        level3: "",
        level4: "",
        level5: "",
    };
    // todo: support for shorter codes
    if (value.includes(".")) {
        if (value.length === 5) {
            result.level2 = value.substring(0, 2);
            result.level3 = value.substring(3, 4);
            result.level4 = value.substring(4, 5);
        } else if (value.length === 6) {
            result.level2 = value.substring(0, 2);
            result.level3 = value.substring(3, 4);
            result.level4 = value.substring(4, 5);
            result.level5 = value.substring(5, 6);
        }
    } else {
        if (value.length === 4) {
            result.level2 = value.substring(0, 2);
            result.level3 = value.substring(2, 3);
            result.level4 = value.substring(3, 4);
        } else if (value.length === 5) {
            result.level2 = value.substring(0, 2);
            result.level3 = value.substring(2, 3);
            result.level4 = value.substring(3, 4);
            result.level5 = value.substring(4, 5);
        }
    }
    return result;
};
