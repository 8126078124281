<template>
    <div style="position: relative;">

        <div
            :id="'form-' + form.id"
            class="amlv3-formitem-top"
            :class="[
                {'amlv3-formitem-top-collapsed': !expanded },
            ]"
            @click="expanded = !expanded"
        >

            <div class="amlv3-formitem-active-icon" v-if="form.isSelected">
                <FontIcon :icon="'check-double'" :size="18" />
            </div>

            <div class="amlv3-formitem-top-left">
                <div class="amlv3-formitem-name">
                    {{ form.name }}
                </div>
                <StatusAndText
                    :text="getStatusText()"
                    :is-completed="form.formStatus === 'completed'"
                />
            </div>

            <div class="amlv3-formitem-top-menu-btn" @click.stop="menuOpen = !menuOpen">
                <FontIcon :icon="'ellipsis'" style="margin-top: -4px;" />
                <div
                    v-if="menuOpen"
                    class="amlv3-formitem-menu"
                    v-click-away="hideMenu"
                >
                    <div
                        v-if="!form.isSelected || true == true"
                        class="amlv3-formitem-menu-item"
                        @click="selectDialog.visible = true"
                    >
                        {{ $t('project.forms.actions.makeActive') }}
                    </div>
                    <div class="amlv3-formitem-menu-item" @click="showArchiveDialog">
                        {{ $t('project.forms.actions.archive') }}
                    </div>
                    <div class="amlv3-formitem-menu-item" @click="$emit('delete')">
                        {{ $t('project.forms.actions.delete') }}
                    </div>
                </div>
            </div>

        </div>

        <div class="amlv3-formitem-content" v-if="expanded">

            <div class="amlv3-formitem-info">
                {{ $t('project.forms.details.date') + ' ' + new Date(form.createdAt).toLocaleDateString() }}
            </div>

            <div class="amlv3-formitem-actors-header">
                {{ $t('project.forms.details.formActors') }}
            </div>

            <div class="amlv3-formitem-actors">
                <div
                    v-for="(actor, i) in form.actors"
                    :key="'fae' + i"
                    class="amlv3-formitem-actor"
                    :class="[
                        {'amlv3-formitem-actor-first': i === 0 },
                        {'amlv3-formitem-actor-last': i === form.actors.length - 1 },
                    ]"
                >

                    <div class="amlv3-formitem-actor-name">
                        {{ actor.firstName + ' ' + actor.lastName }}
                    </div>

                    <div class="amlv3-formitem-actor-status">
                        <div
                            class="amlv3-formitem-actor-status-icon"
                            :class="[
                                {'amlv3-formitem-actor-status-icon-completed': actor.completed },
                            ]"
                        >
                            <FontIcon :icon="actor.completed ? 'check' : 'hourglass-half'" :size="14" />
                        </div>
                        <div class="amlv3-formitem-actor-status-text">
                            {{ getActorStatusText(actor) }}
                        </div>
                    </div>

                    <div class="amlv3-formitem-actor-role">
                        {{ actor.role }}
                    </div>

                    <div class="amlv3-formitem-actor-actions">
                        <div
                            v-if="iAmTheCurrentActor()"
                            class="amlv3-formitem-actor-action"
                            @click="showLinkDialog(actor)"
                        >
                            <FontIcon :icon="'file-import'" :size="14" />
                        </div>
                        <div
                            v-if="!actor.completed && !iAmTheCurrentActor()"
                            class="amlv3-formitem-actor-action"
                            @click="showReminderDialog(actor)"
                        >
                            <FontIcon :icon="'bell'" :size="14" />
                        </div>
                        <div class="amlv3-formitem-actor-action" @click="showInfoDialog(actor)">
                            <FontIcon :icon="actor.completed ? 'circle-info' : 'pen'" :size="14" />
                        </div>
                    </div>

                </div>
            </div>

            <div v-for="(document, i) in form.documents" :key="'flid' + i">

                <div class="amlv3-formitem-actors-header">
                    {{ $t('project.forms.details.document') }}
                </div>

                <div class="amlv3-formitem-document">
                    <div class="amlv3-formitem-document-name">
                        {{ document.name }}
                    </div>
                    <div
                        class="amlv3-formitem-document-action amlv3-formitem-document-action-end"
                        @click="showPdfPopup(document.id)"
                    >
                        <FontIcon :icon="'arrow-up-right-from-square'" :size="14" />
                    </div>
                </div>

                <div class="amlv3-formitem-attachments">
                    <div
                        v-for="(attachment, j) in document.attachments"
                        :key="'flid' + i + j"
                        class="amlv3-formitem-attachment"
                    >
                        <div class="amlv3-formitem-attachment-icon">
                            <FontIcon :icon="'paperclip'" :size="14" />
                        </div>
                        <div
                            class="amlv3-formitem-attachment-name"
                            :class="[
                                {'amlv3-formitem-attachment-name-end': j === document.attachments.length - 1 },
                            ]"
                        >
                            {{ attachment.name }}
                        </div>
                        <div
                            class="amlv3-formitem-attachment-action"
                            :class="[
                                {'amlv3-formitem-attachment-action-end': j === document.attachments.length - 1 },
                            ]"
                            @click="showPdfPopup(attachment.id)"
                        >
                            <FontIcon :icon="'arrow-up-right-from-square'" :size="14" />
                        </div>
                    </div>
                </div>

            </div>

        </div>

        <div
            v-if="initiallyOpen"
            class="amlv3-formitem-highlight"
            :style="`opacity: ${highlightOpacity ? '1' : '0'};`"
        >
        </div>

        <FormLinkDialog
            v-if="linkDialog.visible"
            :action="linkDialog.action"
            :project="project"
            :forms="[form]"
            :form-id="form.id"
            :actor-id="linkDialog.actor.id"
            @close-modal="linkDialog.visible = false"
            @request-project-refresh="$emit('request-project-refresh', $event)"
            @open="$emit('open', $event)"
        />

        <FormArchiveDialog
            v-if="archiveDialog.visible"
            :action="archiveDialog.action"
            :project="project"
            :forms="[form]"
            :form-id="form.id"
            @close-modal="archiveDialog.visible = false"
            @request-project-refresh="$emit('request-project-refresh', $event)"
        />

        <FormReminderDialog
            v-if="reminderDialog.visible"
            :form="form"
            :actor="reminderDialog.actor"
            @close-modal="reminderDialog.visible = false"
            @request-project-refresh="$emit('request-project-refresh', $event)"
        />

        <FormActorInfoDialog
            v-if="infoDialog.visible"
            :form="form"
            :actor="infoDialog.actor"
            @close-modal="infoDialog.visible = false"
            @request-project-refresh="$emit('request-project-refresh', $event)"
        />

        <FormSelectDialog
            v-if="selectDialog.visible"
            :form="form"
            @close-modal="selectDialog.visible = false"
            @request-project-refresh="$emit('request-project-refresh', $event)"
        />

        <PdfViewPopup
            v-if="pdfPopup.visible"
            :params="pdfPopup.params"
            @close-modal="pdfPopup.visible = false"
        />

    </div>
</template>
<script lang="ts">
import { Form, FormActor } from "@/lib/forms/forms";
import { defineComponent, PropType } from "vue";
import { mixin as VueClickAway } from "vue3-click-away";

import FontIcon from "@/ui/FontIcon.vue";
import FormLinkDialog from "./FormLinkDialog.vue";
import FormReminderDialog from "./FormReminderDialog.vue";
import FormActorInfoDialog from "./FormActorInfoDialog.vue";
import FormSelectDialog from "./FormSelectDialog.vue";
import StatusAndText from "@/ui/StatusAndText.vue";
import { FlowAction } from "@/types/flow_types";
import { Project } from "@/lib/projects/projects";
import PdfViewPopup from "../pdf_viewer/PdfViewPopup.vue";
import { PdfViewParams } from "../pdf_viewer/pdf_view_helper";
import { getFormFileViewParams } from "../pdf_viewer/types/form";
import FormArchiveDialog from "./FormArchiveDialog.vue";

export default defineComponent({
    name: "FormListItem",
    mixins: [VueClickAway],
    components: {
        FontIcon,
        StatusAndText,
        FormLinkDialog,
        FormReminderDialog,
        FormActorInfoDialog,
        FormSelectDialog,
        PdfViewPopup,
        FormArchiveDialog,
    },
    emits: ["delete", "request-project-refresh", "open"],
    props: {
        project: { type: Object as PropType<Project>, required: true },
        form: { type: Object as PropType<Form>, required: true },
        userUid: { type: String as PropType<string>, required: true },
        initiallyOpen: { type: Boolean as PropType<boolean>, default: false },
    },
    data() {
        return {
            expanded: false,
            menuOpen: false,
            highlightOpacity: false,

            linkDialog: { visible: false, actor: {} as FormActor, action: {} as FlowAction },
            archiveDialog: { visible: false, action: {} as FlowAction },
            reminderDialog: { visible: false, actor: {} as FormActor },
            infoDialog: { visible: false, actor: {} as FormActor },
            selectDialog: { visible: false },
            pdfPopup: { visible: false, params: {} as PdfViewParams },
        };
    },
    methods: {

        hideMenu() {
            this.menuOpen = false;
        },

        showPdfPopup(fileId: string) {
            this.pdfPopup.params = getFormFileViewParams(this.form, fileId);
            this.pdfPopup.visible = true;
        },

        showLinkDialog(actor: FormActor) {
            this.linkDialog.actor = actor;
            this.linkDialog.visible = true;
        },

        showReminderDialog(actor: FormActor) {
            this.reminderDialog.actor = actor;
            this.reminderDialog.visible = true;
        },

        showInfoDialog(actor: FormActor) {
            this.infoDialog.actor = actor;
            this.infoDialog.visible = true;
        },

        showArchiveDialog() {
            this.archiveDialog.visible = true;
        },

        getStatusText() {
            if (this.form.formStatus === "pending") {
                const currentActor = this.getCurrentActor();
                if (!currentActor) return this.$t("project.forms.status.pending");
                return this.$t("project.forms.status.pendingFor") + " " + currentActor.firstName + " " + currentActor.lastName;
            }
            if (this.form.formStatus === "completed") {
                let hasSigners = false;
                let signerNames = "";
                let signerCount = 0;
                for (const i in this.form.actors) {
                    const actor = this.form.actors[i];
                    if (actor.role === "signer") {
                        if (signerCount > 0) signerNames += ", ";
                        signerNames += actor.firstName + " " + actor.lastName;
                        hasSigners = true;
                        signerCount += 1;
                    }
                }
                if (hasSigners) {
                    return this.$t("project.forms.status.signedBy") + " " + signerNames;
                } else {
                    return this.$t("project.forms.status.completed");
                }
            }
        },

        getActorStatusText(actor: FormActor) {
            if (actor.completed) return this.$t("project.forms.status.completed");
            return this.$t("project.forms.status.pending");
        },

        iAmTheCurrentActor() {
            const currentActor = this.getCurrentActor();
            if (!currentActor) return false;
            if (currentActor.uid === this.userUid) return true;
            return false;
        },

        getCurrentActor(): FormActor | null {
            let currentActor: FormActor | null = null;
            this.form.actors.forEach(actor => {
                if (actor.id === this.form.currentActorId) currentActor = actor;
            });
            return currentActor;
        },

        scrollToItem() {
            this.$nextTick(() => {
                this.highlightOpacity = true;
                const id = "form-" + this.form.id;
                const element = document.getElementById(id);
                if (element) element.scrollIntoView({ behavior: "smooth", block: "end" });
            });
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const self = this;
            setTimeout(function() {
                self.highlightOpacity = false;
            }, 1000);
        },

    },
    mounted() {
        if (this.initiallyOpen) {
            this.expanded = true;
            this.scrollToItem();
        }
    },
});
</script>
<style scoped>
.amlv3-formitem-highlight {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    border-radius: 4px;
    pointer-events: none;
    -webkit-box-shadow: inset 0px 0px 9px 0px rgba(63,167,242,1);
    -moz-box-shadow: inset 0px 0px 9px 0px rgba(63,167,242,1);
    box-shadow: inset 0px 0px 9px 0px rgba(63,167,242,1);
    opacity: 0;
    transition: opacity 1.0s;
}

.amlv3-formitem-top {
    display: flex;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border: solid 1px lightgray;
    background-color: white;
    cursor: pointer;
}

.amlv3-formitem-top:hover {
    background-color: whitesmoke;
}

.amlv3-formitem-top-collapsed {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.amlv3-formitem-active-icon {
    margin-top: 13px;
    margin-left: 15px;
    color: #218BCB;
}

.amlv3-formitem-top-left {
    flex-grow: 1;
    padding: 5px 15px;
}

.amlv3-formitem-name {
    font-size: 15px;
    color: #444444;
}

.amlv3-formitem-top-menu-btn {
    position: relative;
    flex-shrink: 0;
    width: 50px;
    height: 20px;
    margin-top: 14px;
    margin-right: 15px;
    text-align: center;
    border-radius: 4px;
    border: solid 1px lightgray;
    background-color: white;
    cursor: pointer;
}

.amlv3-formitem-top-menu-btn:hover {
    background-color: whitesmoke;
}

.amlv3-formitem-menu {
    position: absolute;
    top: 20px;
    right: 0px;
    width: 300px;
    border-top: solid 1px lightgray;
}

.amlv3-formitem-menu-item {
    padding: 3px 10px;
    text-align: left;
    border: solid 1px lightgray;
    border-top: none;
    background-color: white;
    cursor: pointer;
}
.amlv3-formitem-menu-item:hover {
    background-color: whitesmoke;
}

/* Content */

.amlv3-formitem-content {
    padding: 15px;
    padding-top: 10px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border: solid 1px lightgray;
    border-top: none;
    background-color: white;
}

.amlv3-formitem-info {
    font-size: 14px;
    color: dimgray;
}

.amlv3-formitem-actors-header {
    margin-top: 5px;
    padding-left: 5px;
    font-size: 13px;
    color: dimgray;
}

.amlv3-formitem-actors {
    margin-top: 2px;
}

.amlv3-formitem-actor {
    display: flex;
    font-size: 14px;
    border: solid 1px lightgray;
    border-top: none;
}

.amlv3-formitem-actor-first {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-top: solid 1px lightgray;
}
.amlv3-formitem-actor-last {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.amlv3-formitem-actor-name {
    flex-shrink: 0;
    width: 140px;
    padding: 2px 5px;
}

.amlv3-formitem-actor-status {
    flex-shrink: 0;
    width: 110px;
    display: flex;
    padding-top: 2px;
}

.amlv3-formitem-actor-status-icon {
    color: dimgray;
}
.amlv3-formitem-actor-status-icon-completed {
    color: #218BCB;
}

.amlv3-formitem-actor-status-text {
    margin-left: 4px;
}

.amlv3-formitem-actor-role {
    flex-grow: 1;
    padding: 2px 5px;
}

.amlv3-formitem-actor-actions {
    flex-shrink: 0;
    display: flex;
}

.amlv3-formitem-actor-action {
    width: 22px;
    padding-top: 3px;
    text-align: center;
    border-left: solid 1px lightgray;
    cursor: pointer;
}

.amlv3-formitem-actor-action:hover {
    background-color: whitesmoke;
}

/* Documents */

.amlv3-formitem-document {
    display: flex;
    margin-top: 2px;
}

.amlv3-formitem-document-name {
    flex-grow: 1;
    padding: 2px 5px;
    font-size: 14px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border: solid 1px lightgray;
}

.amlv3-formitem-document-action {
    width: 22px;
    padding-top: 3px;
    text-align: center;
    border: solid 1px lightgray;
    border-left: none;
    cursor: pointer;
}
.amlv3-formitem-document-action:hover {
    background-color: whitesmoke;
}

.amlv3-formitem-document-action-end {
    border-top-right-radius: 4px;
}

.amlv3-formitem-attachments {
    padding-left: 8px;
}

.amlv3-formitem-attachment {
    display: flex;
}

.amlv3-formitem-attachment-icon {
    flex-shrink: 0;
    width: 22px;
    padding-top: 3px;
}

.amlv3-formitem-attachment-name {
    flex-grow: 1;
    padding: 2px 5px;
    font-size: 14px;
    border: solid 1px lightgray;
    border-top: none;
}

.amlv3-formitem-attachment-name-end {
    border-bottom-left-radius: 4px;
}

.amlv3-formitem-attachment-action {
    width: 22px;
    padding-top: 3px;
    text-align: center;
    border: solid 1px lightgray;
    border-top: none;
    border-left: none;
    cursor: pointer;
}
.amlv3-formitem-attachment-action:hover {
    background-color: whitesmoke;
}

.amlv3-formitem-attachment-action-end {
    border-bottom-right-radius: 4px;
}
</style>
