<template>
    <PopupDialog
        :is-visible="dialog.visible"
        :header="$t('risk.comment.add')"
        :icon="'comment'"
        :show-action-button="true"
        :show-cancel-button="true"
        :is-action-disabled="!dialog.valid"
        :action-text="$t('risk.comment.add')"
        :action-icon="'plus'"
        :progress="dialog"
        @close-modal="onClose"
        @action="addComment"
    >
        <PopupDialogSplit
            v-if="isLoaded"
            is-form
            :prompt-text="promptText"
            :prompt-hint="promptHint"
        >

            <InputGroup :width="12">
                <InputLabel
                    :text="'Prompt'"
                    :unique-id="'end-name'"
                    required
                />
                <InputTextLocalized
                    v-model="model.prompt"
                    :unique-id="'end-prompt'"
                    @input="validateDialog"
                />
            </InputGroup>

            <InputGroup :width="4">
                <InputLabel
                    :text="'Key'"
                    :unique-id="'end-key'"
                    required
                />
                <InputText
                    v-model="model.key"
                    :unique-id="'end-key'"
                    @input="validateDialog"
                />
            </InputGroup>

            <InputGroup :width="8">
                <InputLabel
                    :text="'Trigger'"
                    :unique-id="'end-config'"
                    required
                />
                <InputDropdown
                    v-model="model.simpleTrigger"
                    :items="simpleTriggers"
                    :unique-id="'end-trigger'"
                    @input="validateDialog"
                />
            </InputGroup>

        </PopupDialogSplit>
    </PopupDialog>
</template>
<script lang="ts">
import { defineComponent } from "vue";

import PopupDialog from "@/ui/PopupDialog.vue";

import PopupDialogSplit from "@/ui/dialog/PopupDialogSplit.vue";
import { DropdownItem } from "@/ui/inputs/input_helper";
import InputDropdown from "@/ui/inputs/InputDropdown.vue";
import InputGroup from "@/ui/inputs/InputGroup.vue";
import InputLabel from "@/ui/inputs/InputLabel.vue";
import InputText from "@/ui/inputs/InputText.vue";
import InputTextLocalized from "@/ui/inputs/InputTextLocalized.vue";
import { LocalizedString, LocalizedStringValidation, validateLocalizedString } from "@/lib/common/common";
import { getRandomString } from "./risk_helper";
import { RiskCommentDefinition } from "@/lib/risk/risk_matrix";

export default defineComponent({
    name: "AddRiskCommentDialog",
    components: {
        PopupDialog,
        InputGroup,
        InputLabel,
        InputText,
        InputTextLocalized,
        InputDropdown,
        PopupDialogSplit,
    },
    emits: ["add", "close-modal"],
    data() {
        return {
            isLoaded: false,
            dialog: { visible: true, valid: false, isWorking: false, statusText: "", isError: false, errorText: "" },
            model: {
                key: "",
                prompt: {} as LocalizedString,
                simpleTrigger: "",
            },
            simpleTriggers: new Array<DropdownItem>(),
            promptText: "",
            promptHint: "",
        };
    },
    methods: {

        onClose() {
            this.$emit("close-modal");
        },

        validateDialog() {
            let valid = true;

            if (!this.model.key) {
                valid = false;
            }
            if (!validateLocalizedString(this.model.prompt, LocalizedStringValidation.CurrentOnly)) {
                valid = false;
            }
            if (!this.model.simpleTrigger) {
                valid = false;
            }

            this.dialog.valid = valid;
        },

        addComment() {
            const comment: RiskCommentDefinition = {
                key: this.model.key,
                prompt: this.model.prompt,
                required: true,
                simpleTrigger: this.model.simpleTrigger,
            };
            this.$emit("add", comment);
            this.onClose();
        },

    },
    mounted() {
        this.promptText = this.$t("risk.comment.promptText") + ":";
        this.promptHint = this.$t("risk.comment.hintText");

        this.simpleTriggers.push({
            value: "always",
            text: this.$t("risk.simpleTrigger.alwaysRequired"),
        });
        this.simpleTriggers.push({
            value: "override",
            text: this.$t("risk.simpleTrigger.overridden"),
        });

        this.model.key = getRandomString(8);
        this.model.simpleTrigger = "always";

        this.validateDialog();
        this.isLoaded = true;
    },
});
</script>
