import { RiskMatrixDefinition, RiskValueType } from "@/lib/risk/risk_matrix";

export interface RiskAssets {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    countries?: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    naceCodes?: any;
    highRiskCountries?: string[];
}

export const loadRiskAssets = async (matrix?: RiskMatrixDefinition): Promise<RiskAssets> => {
    const possibleValueTypes: RiskValueType[] = [
        RiskValueType.Country,
        RiskValueType.NaceCode,
    ];

    // Loop the matrix indicators to get a list of files we need to fetch
    const usedValueTypes: RiskValueType[] = [];
    if (matrix) {
        matrix.indicators.forEach(indicator => {
            if (possibleValueTypes.includes(indicator.valueType) && !usedValueTypes.includes(indicator.valueType)) {
                usedValueTypes.push(indicator.valueType);
            }
        });
    }

    const result: RiskAssets = {
        // https://finance.ec.europa.eu/financial-crime/anti-money-laundering-and-countering-financing-terrorism-international-level_en
        // Remember to also update in backend
        highRiskCountries: ["AF", "BB", "BF", "CM", "CD", "GI", "HT", "JM", "ML", "MZ", "MM", "NG", "PA", "PH", "SN", "ZA", "SS", "SY", "TZ", "TT", "UG", "AE", "VU", "VN", "YE", "IR", "KP"],
    };

    const promises: Promise<void>[] = [];
    if (matrix) {
        // If a matrix was provided, add only required assets
        usedValueTypes.forEach(valueType => {
            promises.push(getAssetFile(result, valueType));
        });
    } else {
        // Load all assets
        possibleValueTypes.forEach(valueType => {
            promises.push(getAssetFile(result, valueType));
        });
    }
    await Promise.allSettled(promises);

    return result;
};

const getAssetFile = async (result: RiskAssets, type: RiskValueType) => {
    // todo: add base path to env
    const basePath = "aml-spa/assets";

    if (type === RiskValueType.Country) {
        const response = await getOperation(`${basePath}/geography/countries.json`);
        result.countries = response.data;
    }

    if (type === RiskValueType.NaceCode) {
        const response = await getOperation(`${basePath}/organization/nace_codes.json`);
        result.naceCodes = response.data;
    }
};

const getOperation = async (url: string) => {
    try {
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "content-type": "application/json",
            },
        });

        const result = await response.json();

        if (!response.ok) {
            throw new Error(result);
        }

        return {
            data: result,
        };
    } catch (err) {
        console.error("Failed to fetch", err);
        throw err;
    }
};
