<template>
    <PopupDialog
        :is-visible="dialog.visible"
        :header="$t('risk.sources.add')"
        :icon="'plus'"
        :progress="dialog"
        :show-cancel-button="true"
        :show-action-button="true"
        :action-text="$t('risk.sources.add')"
        @close-modal="onClose"
        @action="addSource"
    >
        <div>
            <TabControl
                :tabs="tabs"
                :selected-tab-key="selectedTabKey"
                @tab-changed="onTabChanged"
            />

            <RiskSourceWizardCompany
                v-if="selectedTabKey === 'company'"
                :value-type="indicator.valueType"
                @selected="onIndicatorSelected"
            />

            <RiskSourceRoleWizard
                v-if="selectedTabKey === 'roles'"
                :value-type="indicator.valueType"
                @selected="onIndicatorSelected"
            />

            <RiskSourceWizardForm
                v-if="selectedTabKey === 'form'"
                :value-type="indicator.valueType"
                @selected="onIndicatorSelected"
            />

        </div>
    </PopupDialog>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

import { RiskIndicatorDefinition, RiskValueSource } from "@/lib/risk/risk_matrix";

import { TabControlTab } from "@/types/ui_types";
import PopupDialog from "@/ui/PopupDialog.vue";
import TabControl from "@/ui/TabControl.vue";
import { AvailableIndicator } from "./predefined_indicators";
import { RiskAssets } from "./risk_assets";
import RiskSourceWizardCompany from "./source_wizard/RiskSourceWizardCompany.vue";
import RiskSourceRoleWizard from "./source_wizard/RiskSourceWizardRole.vue";
import RiskSourceWizardForm from "./source_wizard/RiskSourceWizardForm.vue";

export default defineComponent({
    name: "AddRiskSourceDialog",
    components: {
        PopupDialog,
        TabControl,
        RiskSourceWizardCompany,
        RiskSourceRoleWizard,
        RiskSourceWizardForm,
    },
    emits: ["add-source", "close-modal"],
    props: {
        indicator: { type: Object as PropType<RiskIndicatorDefinition>, required: true },
        assets: { type: Object as PropType<RiskAssets>, required: true },
    },
    data() {
        return {
            RiskValueSource,
            dialog: { visible: true, isWorking: false, statusText: "", isError: false, errorText: "" },
            tabs: [
                { key: "company", rawText: "Company" },
                { key: "roles", rawText: "Roles" },
                { key: "form", rawText: "Form Data" },
            ] as TabControlTab[],
            selectedTabKey: "roles",
            commonIndicators: new Array<AvailableIndicator>(),
            selectedKey: "",
            selectedIndicator: null as RiskIndicatorDefinition | null,
        };
    },
    methods: {

        onTabChanged(key: string) {
            this.selectedTabKey = key;
        },

        onIndicatorSelected(indicator: RiskIndicatorDefinition) {
            this.selectedIndicator = indicator;
        },

        async addSource() {
            if (this.selectedIndicator && this.selectedIndicator.sources.length > 0) {
                this.$emit("add-source", this.selectedIndicator.sources[0]);
                this.onClose();
            }
        },

        onClose() {
            this.$emit("close-modal");
        },

    },
});
</script>
