<!-- eslint-disable max-len -->
<template>
    <div>

        <div class="amlv3-persontop-top">

            <div class="amlv3-persontop-left">

                <div class="amlv3-persontop-icon">
                    <svg
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M7 13.5C10.5899 13.5 13.5 10.5899 13.5 7C13.5 3.41015 10.5899 0.5 7 0.5C3.41015 0.5 0.5 3.41015 0.5 7C0.5 10.5899 3.41015 13.5 7 13.5Z"
                            stroke="#218BCB"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <mask
                            id="mask0_1418_21585"
                            style="mask-type:alpha"
                            maskUnits="userSpaceOnUse"
                            x="1"
                            y="1"
                            width="12"
                            height="12"
                        >
                            <path d="M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13Z" fill="#218BCB" />
                        </mask>
                        <g mask="url(#mask0_1418_21585)">
                            <path
                                d="M7 18.5C9.48528 18.5 11.5 16.4853 11.5 14C11.5 11.5147 9.48528 9.5 7 9.5C4.51472 9.5 2.5 11.5147 2.5 14C2.5 16.4853 4.51472 18.5 7 18.5Z"
                                fill="#218BCB"
                                stroke="#218BCB"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </g>
                        <path
                            d="M6.9991 7.923C8.2736 7.923 9.30679 6.88981 9.30679 5.61531C9.30679 4.34081 8.2736 3.30762 6.9991 3.30762C5.7246 3.30762 4.69141 4.34081 4.69141 5.61531C4.69141 6.88981 5.7246 7.923 6.9991 7.923Z"
                            fill="#218BCB"
                            stroke="#218BCB"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </div>

                <div class="amlv3-persontop-name" @click="goToPersonProfile">
                    {{ person.data.person_info.givenName + ' ' + person.data.person_info.familyName }}
                </div>

            </div>

            <div class="amlv3-persontop-right">

                <div
                    class="amlv3-persontop-flag"
                    :tooltip="getResidenceTooltip()"
                    :class="[
                        {'amlv3-persontop-flag-pluspadding': residence && residencePlus },
                    ]"
                    @click="onExpandCollapse"
                >
                    <div v-if="residence && residencePlus" class="amlv3-persontop-flag-plus">
                        {{ '+' }}
                    </div>
                    <div v-if="residence">
                        <FlagIcon :country="residence" :size="'person'" />
                    </div>
                    <div v-if="residence" class="amlv3-persontop-flag-border" />
                    <div v-if="!residence" class="amlv3-persontop-flag-empty">
                        {{ '?' }}
                    </div>
                </div>

                <div
                    class="amlv3-persontop-flag"
                    :tooltip="getNationalityTooltip()"
                    :class="[
                        {'amlv3-persontop-flag-pluspadding': nationality && nationalityPlus },
                    ]"
                    @click="onExpandCollapse"
                >
                    <div v-if="nationality && nationalityPlus" class="amlv3-persontop-flag-plus">
                        {{ '+' }}
                    </div>
                    <div v-if="nationality">
                        <FlagIcon :country="nationality" :size="'person'" />
                    </div>
                    <div v-if="nationality" class="amlv3-persontop-flag-border" />
                    <div v-if="!nationality" class="amlv3-persontop-flag-empty">
                        {{ '?' }}
                    </div>
                </div>

                <div class="amlv3-persontop-dob">
                    <div class="amlv3-persontop-dob-icon">
                        <FontIcon :icon="'calendar-day'" :size="17" />
                    </div>
                    <div class="amlv3-persontop-dob-text">
                        {{ formatDateObject(person.data.person_info.dateOfBirth) }}
                    </div>
                </div>

            </div>

        </div>

        <div class="amlv3-persontop-roles">
            <div
                v-for="role in person.roles"
                :key="person.id + role.key"
                class="amlv3-persontop-role"
                :class="[
                    {'amlv3-persontop-role-confirmed': roleHasKycSource(role) },
                ]"
                @click="onExpandCollapse"
            >
                <div
                    class="amlv3-persontop-role-dot"
                />
                <div class="amlv3-persontop-role-text">
                    {{ getRoleDisplayText(role) }}
                </div>
            </div>
        </div>

        <div v-if="expanded" class="amlv3-persontop-info">

            <div class="amlv3-persontop-info-row">

                <div class="amlv3-persontop-info-header">
                    {{ $t("project.tabs.persons.sources.roles").toUpperCase() }}
                </div>

                <div
                    v-for="role in person.roles"
                    :key="'ir' + person.id + role.key"
                    class="amlv3-persontop-info-item"
                >
                    <div class="amlv3-persontop-info-item-value">
                        {{ getRoleDisplayText(role) }}
                    </div>
                    <div class="amlv3-persontop-info-item-source">
                        {{ getRoleSourceText(role) }}
                    </div>
                </div>

                <div v-if="person.roles.length === 0" class="amlv3-persontop-info-empty">
                    {{ $t("project.tabs.persons.sources.notAvailable") }}
                </div>

            </div>

            <div class="amlv3-persontop-info-row">

                <div class="amlv3-persontop-info-header">
                    {{ $t("project.tabs.persons.sources.address").toUpperCase() }}
                </div>

                <div
                    v-for="(item, i) in addresses"
                    :key="'pia' + i"
                    class="amlv3-persontop-info-item"
                >
                    <div class="amlv3-persontop-info-item-value">
                        {{ item.value }}
                    </div>
                    <div class="amlv3-persontop-info-item-source">
                        {{ item.source }}
                    </div>
                </div>

                <div v-if="addresses.length === 0" class="amlv3-persontop-info-empty">
                    {{ $t("project.tabs.persons.sources.notAvailable") }}
                </div>

                <div class="amlv3-persontop-info-header">
                    {{ $t("project.tabs.persons.sources.nationality").toUpperCase() }}
                </div>

                <div
                    v-for="(item, i) in nationalities"
                    :key="'pin' + i"
                    class="amlv3-persontop-info-item"
                >
                    <div class="amlv3-persontop-info-item-value">
                        {{ getCountryDisplayText(item.value) }}
                    </div>
                    <div class="amlv3-persontop-info-item-source">
                        {{ item.source }}
                    </div>
                </div>

                <div v-if="nationalities.length === 0" class="amlv3-persontop-info-empty">
                    {{ $t("project.tabs.persons.sources.notAvailable") }}
                </div>

            </div>

        </div>

        <!-- <pre>
            {{ person }}
        </pre> -->
    </div>
</template>
<script lang="ts">
import { PersonWithRole } from "@/lib/persons/person";
import { defineComponent, PropType } from "vue";
import FlagIcon from "../FlagIcon.vue";
import FontIcon from "@/ui/FontIcon.vue";
import { formatDateObject } from "@/lib/date_object_formatter";
import { getAddressDisplayText, getCountryDisplayText, getRoleDisplayText } from "./person_display_helper";
import { RoleDescription, RoleSourceKey } from "@/lib/role/Role";

interface DetailItem {
    value: string;
    source: string;
}

export default defineComponent({
    name: "PersonCardTopInfo",
    components: {
        FlagIcon,
        FontIcon,
    },
    props: {
        person: { type: Object as PropType<PersonWithRole>, required: true },
    },
    data() {
        return {
            expanded: false,
            residence: "",
            residencePlus: false,
            nationality: "",
            nationalityPlus: false,

            addresses: new Array<DetailItem>(),
            nationalities: new Array<DetailItem>(),
        };
    },
    methods: {
        formatDateObject,
        getRoleDisplayText,
        getCountryDisplayText,

        onExpandCollapse() {
            this.expanded = !this.expanded;
        },

        goToPersonProfile() {
            this.$router.push({ path: `/aml/persons/${this.person.id}` });
        },

        roleHasKycSource(role: RoleDescription) {
            if (role.sources) {
                for (const source of role.sources) {
                    if (source.key === RoleSourceKey.Kyc) return true;
                }
            }
            return false;
        },

        getRoleSourceText(role: RoleDescription) {
            let str = this.$t("project.tabs.persons.sources.source") + ": ";
            let fromLookup = false;
            let fromManual = false;
            let fromKyc = false;
            if (role.sources) {
                for (const source of role.sources) {
                    if (source.key === RoleSourceKey.Lookup) fromLookup = true;
                    if (source.key === RoleSourceKey.Manual) fromManual = true;
                    if (source.key === RoleSourceKey.Kyc) fromKyc = true;
                }
            } else {
                str += this.$t("project.tabs.persons.sources.unknown");
            }
            let existedBeforeKyc = false;
            if (fromLookup) {
                str += this.$t("project.tabs.persons.sources.lookup");
                existedBeforeKyc = true;
            } else if (fromManual) {
                str += this.$t("project.tabs.persons.sources.addedManuallyBy");
                existedBeforeKyc = true;
            }
            if (fromKyc) {
                if (existedBeforeKyc) {
                    str += ", " + this.$t("project.tabs.persons.sources.confirmedInKyc");
                } else {
                    str += this.$t("project.tabs.persons.sources.kyc");
                }
            }
            if (!fromLookup && !fromManual && !fromKyc) {
                str += this.$t("project.tabs.persons.sources.unknown");
            }
            return str;
        },

        getResidenceTooltip() {
            if (this.residence) {
                return this.$t("project.tabs.persons.sources.residence") + ": " + getCountryDisplayText(this.residence);
            } else {
                return this.$t("project.tabs.persons.sources.residence") + ": " + this.$t("project.tabs.persons.sources.unknown");
            }
        },

        getNationalityTooltip() {
            if (this.nationality) {
                return this.$t("project.tabs.persons.sources.nationality") + ": " + getCountryDisplayText(this.nationality);
            } else {
                return this.$t("project.tabs.persons.sources.nationality") + ": " + this.$t("project.tabs.persons.sources.unknown");
            }
        },

        getBlockSourceName(source: string) {
            switch (source) {
            case "lookup": return this.$t("project.tabs.persons.sources.lookup");
            case "forms": return this.$t("project.tabs.persons.sources.kyc");
            }
            return this.$t("project.tabs.persons.sources.unknown");
        },

    },
    mounted() {
        /* console.log("blocks", this.person.blocks); */

        const residenceList = [];
        const nationalityList = [];

        for (const block of this.person.blocks) {
            if (block._block === "person_info") {
                /* console.log("block", block); */

                if (block.addresses) {
                    for (const address of block.addresses) {
                        if (address.country) residenceList.push(address.country);
                        this.addresses.push({
                            value: getAddressDisplayText(address),
                            source: this.$t("project.tabs.persons.sources.source") + ": " + this.getBlockSourceName(block._source),
                        });
                    }
                }

                if (block.nationality) {
                    if (block.nationality.country) nationalityList.push(block.nationality.country);
                    this.nationalities.push({
                        value: block.nationality.country,
                        source: this.$t("project.tabs.persons.sources.source") + ": " + this.getBlockSourceName(block._source),
                    });
                    if (block.secondaryNationality) {
                        if (block.secondaryNationality.country) nationalityList.push(block.secondaryNationality.country);
                        this.nationalities.push({
                            value: block.secondaryNationality.country,
                            source: this.$t("project.tabs.persons.sources.source") + ": " + this.getBlockSourceName(block._source),
                        });
                    }
                }
            }
        }

        const residences = [...new Set(residenceList)];
        if (residences.length > 0) this.residence = residences[0];
        if (residences.length > 1) this.residencePlus = true;

        const nationalities = [...new Set(nationalityList)];
        if (nationalities.length > 0) this.nationality = nationalities[0];
        if (nationalities.length > 1) this.nationalityPlus = true;
    },
});
</script>
<style scoped>
.amlv3-persontop-top {
    display: flex;
}

.amlv3-persontop-left {
    flex-grow: 1;
    display: flex;
    padding-top: 15px;
    padding-left: 20px;
}

.amlv3-persontop-icon {
    width: 28px;
}

.amlv3-persontop-name {
    padding-top: 2px;
    padding-left: 10px;
    cursor: pointer;
}
.amlv3-persontop-name:hover {
    color: #218BCB;
}

.amlv3-persontop-right {
    flex-shrink: 0;
    display: flex;
    padding-top: 15px;
    padding-right: 20px;
}

.amlv3-persontop-flag {
    position: relative;
    margin-right: 15px;
    cursor: pointer;
}
.amlv3-persontop-flag:hover {
    opacity: 0.8;
}

.amlv3-persontop-flag-pluspadding {
    padding-right: 20px;
}

.amlv3-persontop-flag-border {
    position: absolute;
    top: -0.5px;
    left: -0.5px;
    width: 29.0px;
    height: 29.0px;
    border-radius: 14.5px;
    border: solid 1px rgb(225, 225, 225);
}

.amlv3-persontop-flag-plus {
    position: absolute;
    top: -0.5px;
    left: 4px;
    width: 44px;
    height: 29.0px;
    padding-top: 2.5px;
    padding-right: 5px;
    text-align: right;
    font-size: 20px;
    line-height: 22px;
    border-radius: 14.5px;
    border: solid 1px rgb(225, 225, 225);
    border-left: none;
    background-color: whitesmoke;
}

.amlv3-persontop-flag-empty {
    margin-top: -0.5px;
    width: 29.0px;
    height: 29.0px;
    padding-top: 2px;
    text-align: center;
    border-radius: 14.5px;
    border: solid 1px rgb(225, 225, 225);
    background-color: whitesmoke;
}

.amlv3-persontop-dob {
    display: flex;
    height: 29.0px;
    padding: 0px 10px;
    border-radius: 6px;
    border: solid 1px rgb(225, 225, 225);
}

.amlv3-persontop-dob-icon {
    padding-top: 1px;
    color: rgb(68, 68, 68);
}

.amlv3-persontop-dob-text {
    padding-top: 3.5px;
    padding-left: 6px;
    font-size: 14px;
    color: rgb(68, 68, 68);
}

.amlv3-persontop-roles {
    display: flex;
    padding: 0px 20px;
    padding-top: 10px;
}

.amlv3-persontop-role {
    display: flex;
    height: 26px;
    margin-right: 6px;;
    padding: 2px 10px;
    font-size: 14px;
    border-radius: 13px;
    border: solid 1px rgb(225, 225, 225);
    background-color: white;
    cursor: pointer;
}
.amlv3-persontop-role:hover {
    background-color: whitesmoke;
}

.amlv3-persontop-role-dot {
    width: 7px;
    height: 7px;
    margin-top: 7px;
    margin-right: 7px;
    border-radius: 50px;
    background-color: #C4C4C4;
}

.amlv3-persontop-role-confirmed .amlv3-persontop-role-dot {
    background-color: #218BCB;
}

.amlv3-persontop-info {
    display: flex;
    gap: 20px;
    min-height: 100px;
    margin-top: 10px;
    padding: 0px 20px;
    border-top: solid 1px rgb(225, 225, 225);
    border-bottom: solid 1px rgb(225, 225, 225);
}

.amlv3-persontop-info-row {
    width: 50%;
    padding-bottom: 10px;
}

.amlv3-persontop-info-header {
    margin-top: 10px;
    font-size: 14px;
    color: gray;
}

.amlv3-persontop-info-item {
    margin-bottom: 6px;
}

.amlv3-persontop-info-item-value {
    font-size: 15px;
    line-height: 17px;
}

.amlv3-persontop-info-item-source {
    font-size: 14px;
    line-height: 16px;
    color: gray;
}

.amlv3-persontop-info-empty {
    font-size: 14px;
    color: gray;
}
</style>
