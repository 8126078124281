<template>
    <div>

        <RiskNestedList
            :items="nestedData.items"
            :options="nestedData.options"
            :selected-code="value"
            :selectable-level="selectableLevel"
            @item-selected="onItemSelected"
        />

    </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

import { AssessmentValue } from "@/lib/assessments/assessments";
import { RiskValueType } from "@/lib/risk/risk_matrix";
import RiskNestedList from "@/views/risk/controls/RiskNestedList.vue";
import { RiskAssets } from "@/views/risk/risk_assets";
import { getNestedData, RiskNestedItem, RiskNestedListData } from "@/views/risk/risk_nested_list";

export default defineComponent({
    name: "IndicatorNestedList",
    components: {
        RiskNestedList,
    },
    emits: ["updated"],
    props: {
        assets: { type: Object as PropType<RiskAssets>, required: true },
        inputValueType: { type: String as PropType<RiskValueType>, required: true },
        existingValue: { type: Object as PropType<AssessmentValue | null>, default: null },
    },
    data() {
        return {
            value: "",
            isValid: false,
            selectableLevel: 2,

            nestedData: { items: [], options: { limit: 0 } } as RiskNestedListData,
        };
    },
    methods: {

        onItemSelected(item: RiskNestedItem) {
            if (this.value === item.code) {
                this.value = "";
            } else {
                this.value = item.code;
            }
            this.isValid = (this.value !== "");
            this.handleUpdate();
        },

        handleUpdate() {
            this.$emit("updated", this.value, this.isValid);
        },

    },
    mounted() {
        if (this.inputValueType === RiskValueType.NaceCode) {
            this.selectableLevel = 3;
        }
        this.nestedData = getNestedData(this.inputValueType, this.assets);
        if (this.existingValue !== null) this.value = this.existingValue.value;
    },
});
</script>
<style scoped></style>
