<template>
    <div :class="`mt-5 bg-gray-300 rounded-md animate-pulse`" :style="`height: ${height}px;`" />
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";

export default defineComponent({
    name: "LoadAnimationBar",
    props: {
        height: { type: Number as PropType<number>, default: 50 },
    },
});
</script>
