<template>
    <div>

        <PopupDialog
            :is-visible="dialog.visible"
            :header="$t('project.forms.actions.archive')"
            :icon="'box-archive'"
            :show-action-button="true"
            :show-cancel-button="true"
            :is-action-disabled="!dialog.valid"
            :action-text="$t('project.forms.actions.archive')"
            :action-icon="'box-archive'"
            :progress="dialog"
            @close-modal="onClose"
            @action="onArchiveForm"
        >
            <PopupDialogPrompt
                :prompt-text="$t('project.forms.archive.promptText')"
                :info-text="$t('project.forms.archive.hintText')"
            />
        </PopupDialog>

    </div>
</template>
<script lang="ts">
import { PropType, defineComponent } from "vue";

import { Form } from "@/lib/forms/forms";

import PopupDialog from "@/ui/PopupDialog.vue";
import { FlowAction } from "@/types/flow_types";
import { Project } from "@/lib/projects/projects";
import PopupDialogPrompt from "@/ui/dialog/PopupDialogPrompt.vue";
import { setArchivedForm } from "@/lib/forms/set_selected";
import { getProjectProcess } from "@/lib/projects/get_project";

export default defineComponent({
    name: "FormArchiveDialog",
    components: {
        PopupDialog,
        PopupDialogPrompt,
    },
    emits: ["close-modal", "request-project-refresh"],
    props: {
        action: { type: Object as PropType<FlowAction>, required: true },
        project: { type: Object as PropType<Project>, required: true },
        forms: { type: Object as PropType<Form[]>, required: true },
        formId: { type: String as PropType<string>, default: "" },
    },
    data() {
        return {
            dialog: {
                visible: true,
                valid: false,
                isWorking: false,
                statusText: "",
                isError: false,
                errorText: "",
            },
            actualFormId: "",
        };
    },
    methods: {

        onClose() {
            this.$emit("close-modal");
        },

        async onArchiveForm() {
            this.dialog.isError = false;
            this.dialog.isWorking = true;
            this.dialog.statusText = this.$t("project.forms.archive.working");

            const response = await setArchivedForm(this.project.id, this.actualFormId, true);

            if (response.responseInfo.isError) {
                this.dialog.errorText = response.responseInfo.parsedError?.message || "Unknown error";
                this.dialog.isError = true;
            } else {
                console.log("response.wasSet", response.wasSet);
            }

            const projectResponse = await getProjectProcess(this.project.id);
            this.$emit("request-project-refresh", projectResponse);

            this.dialog.isWorking = false;
        },

    },
    mounted() {
        if (this.formId) {
            this.actualFormId = this.formId;
        } else {
            if (this.action.params && this.action.params.formKey) {
                const formKey = this.action.params.formKey;
                for (const form of this.forms) {
                    if (form.templateKey === formKey && form.isSelected) {
                        this.actualFormId = form.id;
                    }
                }
            }
        }

        if (!this.actualFormId) {
            this.dialog.errorText = "Valid formId not provided!";
            this.dialog.isError = true;
            return;
        }

        this.dialog.valid = true;
    },
});
</script>
