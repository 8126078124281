import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cf61fd2c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "srv3-step-actions" }
const _hoisted_2 = {
  key: 0,
  class: "srv3-step-main-action"
}
const _hoisted_3 = {
  key: 2,
  class: "srv3-step-actions-menu-icon-empty"
}
const _hoisted_4 = {
  key: 3,
  class: "srv3-step-actions-menu"
}
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonSingle = _resolveComponent("ButtonSingle")!
  const _component_FontIcon = _resolveComponent("FontIcon")!
  const _directive_click_away = _resolveDirective("click-away")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.mainAction)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_ButtonSingle, {
            text: _ctx.$ct(_ctx.mainAction.text),
            "min-width": 170,
            onClicked: _cache[0] || (_cache[0] = ($event: any) => (_ctx.emitAction(_ctx.mainAction)))
          }, null, 8, ["text"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.menuActions.length > 0)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "srv3-step-actions-menu-icon",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.open = true))
        }, [
          _createVNode(_component_FontIcon, {
            icon: 'ellipsis-vertical',
            size: 34
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.menuActions.length === 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3))
      : _createCommentVNode("", true),
    (_ctx.open)
      ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuActions, (action, i) => {
            return (_openBlock(), _createElementBlock("div", {
              key: 'sai' + i,
              class: _normalizeClass(["srv3-step-actions-menu-item", [
                    {'srv3-step-actions-menu-item-first': i === 0 },
                    {'srv3-step-actions-menu-item-last': i === _ctx.menuActions.length - 1 },
                ]]),
              onClick: ($event: any) => (_ctx.emitAction(action))
            }, _toDisplayString(_ctx.$ct(action.text)), 11, _hoisted_5))
          }), 128))
        ])), [
          [_directive_click_away, _ctx.hideDropdown]
        ])
      : _createCommentVNode("", true)
  ]))
}