import { LocalizedString } from "@/lib/common/common";
import { RiskIndicatorDefinition, RiskValueSource, RiskValueType } from "@/lib/risk/risk_matrix";
import { RiskAssets } from "./risk_assets";
import { getRandomString } from "./risk_helper";
import { getDefaultScores } from "./special_types/special_types";

export interface AvailableIndicator {
    key: string;
    name: LocalizedString;
    hint: LocalizedString;
}

export const getCommonIndicators = (): AvailableIndicator[] => {
    const list: AvailableIndicator[] = [
        {
            key: "reg-country",
            name: { en_EN: "Registration Country" },
            hint: { en_EN: "The country where the company is registered." },
        },
        {
            key: "nace",
            name: { en_EN: "NACE codes" },
            hint: { en_EN: "Business codes (NACE) for the company." },
        },
        {
            key: "high-risk-country",
            name: { en_EN: "High Risk Countries (EU list)" },
            hint: { en_EN: "Matches a country against EUs list of high risk countries." },
        },
        {
            key: "pep",
            name: { en_EN: "PEP Hits" },
            hint: { en_EN: "Checks for PEP hits on key roles." },
        },
    ];
    return list;
};

export const getTypeIndicators = (): AvailableIndicator[] => {
    const list: AvailableIndicator[] = [
        {
            key: "type-string",
            name: { en_EN: "Free Text" },
            hint: { en_EN: "Input can be anything, matches against text values." },
        },
        {
            key: "type-number",
            name: { en_EN: "Number" },
            hint: { en_EN: "Matches will be based on if the number matches the range provided." },
        },
        {
            key: "type-check",
            name: { en_EN: "Multiple Choice" },
            hint: { en_EN: "Select between one or more predefined values." },
        },
        {
            key: "type-date",
            name: { en_EN: "Date Age" },
            hint: { en_EN: "The input will be a date, matches will be based on the time passed since that date." },
        },
        {
            key: "type-country",
            name: { en_EN: "Country" },
            hint: { en_EN: "Match against country codes." },
        },
    ];
    return list;
};

export const getPredefinedIndicator = (key: string, assets: RiskAssets): RiskIndicatorDefinition => {
    const randomKey = getRandomString(8);
    const indicator: RiskIndicatorDefinition = {
        key: randomKey,
        name: { en_EN: randomKey },
        weight: 100,
        hrm: 0,
        lrm: 0,
        valueType: RiskValueType.MultipleChoice,
        sources: [],
        values: [],
        isFixed: true,
        logic: [],
        scores: {},
        unknownScore: 9,
        hints: {},
        params: {},
    };

    if (key === "reg-country") {
        indicator.name = { en_EN: "Registration Country" };
        indicator.valueType = RiskValueType.Country;
        indicator.sources = [{
            source: RiskValueSource.CompanyInfo,
            key: "reg-country",
        }];
        indicator.scores = getDefaultScores(indicator.valueType, assets);
    }

    if (key === "nace") {
        indicator.name = { en_EN: "Business code" };
        indicator.valueType = RiskValueType.NaceCode;
        indicator.sources = [{
            source: RiskValueSource.CompanyInfo,
            key: "nace-codes",
        }];
        indicator.scores = getDefaultScores(indicator.valueType, assets);
    }

    if (key === "high-risk-country") {
        indicator.name = { en_EN: "High Risk Country" };
        indicator.valueType = RiskValueType.HighRiskCountry;
        indicator.sources = [{
            source: RiskValueSource.CompanyInfo,
            key: "reg-country",
        }];
        indicator.scores = getDefaultScores(indicator.valueType, assets);
    }

    if (key === "pep") {
        indicator.name = { en_EN: "PEP" };
        indicator.valueType = RiskValueType.PepHit;
        indicator.sources = [{
            source: RiskValueSource.Role,
            key: "key.any.pep",
        }];
        indicator.scores = getDefaultScores(indicator.valueType, assets);
    }

    if (key === "type-string") {
        indicator.name = { en_EN: "Free Text Input" };
        indicator.valueType = RiskValueType.FreeText;
    }
    if (key === "type-number") {
        indicator.name = { en_EN: "Number Input" };
        indicator.valueType = RiskValueType.Number;
    }
    if (key === "type-check") {
        indicator.name = { en_EN: "Multiple Choice" };
        indicator.valueType = RiskValueType.MultipleChoice;
    }
    if (key === "type-date") {
        indicator.name = { en_EN: "Date Input" };
        indicator.valueType = RiskValueType.DateAge;
    }
    if (key === "type-country") {
        indicator.name = { en_EN: "Country" };
        indicator.valueType = RiskValueType.Country;
    }

    return indicator;
};
