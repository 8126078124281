<template>
    <div>

        <div class="amlv3-approvallist">

            <MenuSwitch
                v-model="filterMode"
                :items="filterItems"
                :has-button="!project.assessment.locked"
                :button-icon="'plus'"
                :button-text="$t('assessment.approval.newTitle')"
                @clicked="newDialog.visible = true"
            />

            <div style="margin-top: 20px;">

                <div
                    class="amlv3-approvallist-spinner"
                    v-if="loading"
                >
                    <SpinnerVerified :size="50" />
                </div>

                <div
                    v-for="(approval, i) in approvals"
                    :key="`ala${i}`"
                    style="margin-bottom: 10px;"
                >
                    <ApprovalListItem
                        :approval="approval"
                        :project-id="project.id"
                        :assessment-id="assessment.id"
                        :sealed="assessment.sealed"
                        :user-uid="userUid"
                        :initially-open="approval.id === initiallyOpenId"
                        @decide="showDecideDialog"
                        @request-project-refresh="$emit('request-project-refresh', $event)"
                    />
                </div>

            </div>

        </div>

        <ApprovalSelectDialog
            v-if="newDialog.visible"
            :action="newDialog.action"
            :project="project"
            :assessment="assessment"
            @close-modal="newDialog.visible = false"
            @request-project-refresh="$emit('request-project-refresh', $event)"
        />

        <ApprovalDecideDialog
            v-if="decideDialog.visible"
            :approval-id="decideDialog.approvalId"
            :action="decideDialog.action"
            :project="project"
            :assessment="assessment"
            @close-modal="decideDialog.visible = false"
            @request-project-refresh="$emit('request-project-refresh', $event)"
        />

    </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

import { Verified } from "@/lib/verified_plugin";
import { Project } from "@/lib/projects/projects";
import { Assessment } from "@/lib/assessments/assessments";
import { Approval } from "@/lib/assessments/approval_model";

import SpinnerVerified from "@/ui/SpinnerVerified.vue";
import ApprovalListItem from "./ApprovalListItem.vue";
import ApprovalSelectDialog from "./ApprovalSelectDialog.vue";
import { FlowAction } from "@/types/flow_types";
import ApprovalDecideDialog from "./ApprovalDecideDialog.vue";
import MenuSwitch from "../customer/menu/MenuSwitch.vue";
import { CheckboxItem } from "@/ui/inputs/input_helper";

export default defineComponent({
    name: "ApprovalList",
    components: {
        SpinnerVerified,
        ApprovalListItem,
        ApprovalSelectDialog,
        ApprovalDecideDialog,
        MenuSwitch,
    },
    emits: ["request-project-refresh"],
    props: {
        project: { type: Object as PropType<Project>, required: true },
        assessment: { type: Object as PropType<Assessment>, required: true },
        initialKey: { type: String as PropType<string>, default: "" },
    },
    data() {
        return {
            loading: false,
            userUid: "",

            approvals: new Array<Approval>(),
            initialKeyInternal: "",
            initiallyOpenId: "",

            newDialog: { visible: false, action: {} as FlowAction },
            decideDialog: { visible: false, approvalId: "", action: {} as FlowAction },

            filterItems: [
                {
                    key: "selected",
                    text: { en_EN: "Active" },
                },
                {
                    key: "archived",
                    text: { en_EN: "Archived" },
                },
            ] as CheckboxItem[],
            filterMode: "selected",
        };
    },
    watch: {
        assessment: {
            deep: true,
            handler() {
                this.loadApprovals();
            },
        },
    },
    methods: {

        loadApprovals() {
            this.approvals = [];
            if (!this.assessment.approvals) return;
            this.assessment.approvals.forEach((approval) => {
                this.approvals.push(approval);
                if ((approval.key === this.initialKey || approval.key === this.initialKeyInternal) && approval.selected) {
                    this.initiallyOpenId = approval.id;
                }
            });
        },

        highlightItem(itemKey: string) {
            this.initialKeyInternal = itemKey;
            this.approvals = [];
            this.$nextTick(() => {
                this.loadApprovals();
            });
        },

        showDecideDialog(approvalId: string) {
            this.decideDialog.approvalId = approvalId;
            this.decideDialog.visible = true;
        },

    },
    mounted() {
        this.userUid = Verified.instance.uid;
        this.loadApprovals();
    },
});
</script>
<style scoped>
.amlv3-approvallist {
    min-height: 50px;
}

.amlv3-approvallist-spinner {
    padding: 40px 0px;
}
</style>
