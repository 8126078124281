export enum ScreeningMonitor {
  ALL = "all",
  ON = "on",
  OFF = "off"
  }
  
  
  export enum HitType {
    PEP =  'pep',
    SANCTION = 'sanction',
    SIP = 'sip',
    NEW_HITS = "new_hits",
    NO_HITS = "no_hits",
  }