import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SimpleListItem = _resolveComponent("SimpleListItem")!
  const _component_LoadAnimationBar = _resolveComponent("LoadAnimationBar")!
  const _component_RiskSourceItem = _resolveComponent("RiskSourceItem")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.selectedTemplateId)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.templates, (template, i) => {
            return (_openBlock(), _createBlock(_component_SimpleListItem, {
              key: 'aft' + i,
              text: _ctx.$ct(template.name),
              "sub-text": template.slug + '/' + template.key,
              "is-first": i === 0,
              "is-last": i === _ctx.templates.length - 1,
              onClicked: ($event: any) => (_ctx.selectTemplate(template))
            }, null, 8, ["text", "sub-text", "is-first", "is-last", "onClicked"]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.loadingTemplate)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_LoadAnimationBar),
          _createVNode(_component_LoadAnimationBar),
          _createVNode(_component_LoadAnimationBar)
        ]))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
      return (_openBlock(), _createBlock(_component_RiskSourceItem, {
        key: 'rsi' + i,
        item: item,
        "is-selected": item.key === _ctx.selectedKey,
        "is-first": i === 0,
        "is-last": i === _ctx.items.length - 1,
        "value-type": _ctx.valueType,
        onClicked: ($event: any) => (_ctx.selectItem(item.key))
      }, null, 8, ["item", "is-selected", "is-first", "is-last", "value-type", "onClicked"]))
    }), 128))
  ]))
}