import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "rskv3-rsi" }
const _hoisted_2 = { class: "rskv3-rsi-value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "rskv3-rsi-left",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onSubClick && _ctx.onSubClick(...args)))
    }, _toDisplayString('-')),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.value.toFixed(_ctx.decimals)), 1),
    _createElementVNode("div", {
      class: "rskv3-rsi-right",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onAddClick && _ctx.onAddClick(...args)))
    }, _toDisplayString('+'))
  ]))
}