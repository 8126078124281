<template>
    <div class="riskv3-wc">

        <div class="riskv3-wc-values">
            <span class="riskv3-wc-desc">
                {{ 'LRM:' }}
            </span>
            <span
                class="riskv3-wc-value riskv3-wc-weak"
                :class="[
                    { 'riskv3-wc-lrm': indicator.lrm > 0 },
                ]"
            >
                {{ indicator.lrm === 0 ? 'OFF' : indicator.lrm === 5 ? 'MAX' : indicator.lrm }}
            </span>
            <span class="riskv3-wc-desc">
                {{ 'HRM:' }}
            </span>
            <span
                class="riskv3-wc-value riskv3-wc-weak"
                :class="[
                    { 'riskv3-wc-hrm': indicator.hrm > 0 },
                ]"
            >
                {{ indicator.hrm === 0 ? 'OFF' : indicator.hrm === 5 ? 'MAX' : indicator.hrm }}
            </span>
            <span class="riskv3-wc-desc">
                {{ 'WEIGHT:' }}
            </span>
            <span class="riskv3-wc-value">
                {{ indicator.weight }}
            </span>
        </div>

        <div class="riskv3-wc-buttons">
            <RiskIconButton :icon="'minus'" @clicked="onWeightChanged(-10)" />
            <RiskIconButton :icon="'plus'" @clicked="onWeightChanged(10)" />
        </div>

        <div class="riskv3-wc-percent">
            <span class="riskv3-wc-value">
                {{ percentage }}
            </span>
            <span class="riskv3-wc-desc" style="padding-left: 5px;">
                {{ '%' }}
            </span>
        </div>

    </div>
</template>
<script lang="ts">
import { RiskIndicatorDefinition } from "@/lib/risk/risk_matrix";
import { defineComponent, type PropType } from "vue";
import RiskIconButton from "./RiskIconButton.vue";

export default defineComponent({
    name: "RiskWeightControl",
    components: {
        RiskIconButton,
    },
    emits: ["weight-changed"],
    props: {
        indicator: { type: Object as PropType<RiskIndicatorDefinition>, required: true },
        percentage: { type: String as PropType<string>, required: true },
    },
    methods: {

        onWeightChanged(changeValue: number) {
            const newValue = this.indicator.weight + changeValue;
            this.$emit("weight-changed", newValue);
        },

    },
});
</script>
<style scoped>
.riskv3-wc {
    display: flex;
    padding-top: 6px;
    padding-right: 8px;
}

.riskv3-wc-values {
    flex-shrink: 0;
    padding-top: 2px;
}

.riskv3-wc-desc {
    padding-left: 20px;
    font-size: 18px;
    color: dimgray;
}

.riskv3-wc-value {
    padding-left: 5px;
    font-size: 19px;
    font-weight: bold;
    color: black;
}

.riskv3-wc-weak {
    color: lightgray;
}

.riskv3-wc-lrm {
    color: #9EACB5;
}

.riskv3-wc-hrm {
    color: #B6A9A8;
}

.riskv3-wc-buttons {
    flex-shrink: 0;
    display: flex;
    padding: 0px 20px;
}

.riskv3-wc-percent {
    flex-shrink: 0;
    padding-top: 2px;
}
</style>
