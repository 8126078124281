import { ApiResponse, parseLocalError, parseRemoteError } from "../error_parser";
import { Verified } from "../verified_plugin";

export const setSelectedForm = async (
    projectId: string,
    formId: string,
    selected: boolean,
): Promise<{ wasSet: boolean, responseInfo: ApiResponse }> => {
    try {
        const response = await fetch(`/api/aml/v1/projects/${projectId}/forms/${formId}/selected`, {
            method: "POST",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
            body: JSON.stringify({ selected }),
        });

        const body = await response.json();

        if (!response.ok) {
            return {
                wasSet: false,
                responseInfo: { isError: true, parsedError: parseRemoteError(body) },
            };
        }

        return {
            wasSet: body.wasSent,
            responseInfo: { isError: false, parsedError: null },
        };
    } catch (error) {
        return {
            wasSet: false,
            responseInfo: { isError: true, parsedError: parseLocalError(error) },
        };
    }
};

export const setArchivedForm = async (
    projectId: string,
    formId: string,
    archived: boolean,
): Promise<{ wasSet: boolean, responseInfo: ApiResponse }> => {
    try {
        const response = await fetch(`/api/aml/v1/projects/${projectId}/forms/${formId}/archived`, {
            method: "POST",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
            body: JSON.stringify({ archived }),
        });

        const body = await response.json();

        if (!response.ok) {
            return {
                wasSet: false,
                responseInfo: { isError: true, parsedError: parseRemoteError(body) },
            };
        }

        return {
            wasSet: body.wasSent,
            responseInfo: { isError: false, parsedError: null },
        };
    } catch (error) {
        return {
            wasSet: false,
            responseInfo: { isError: true, parsedError: parseLocalError(error) },
        };
    }
};
