<template>
    <Layout>
        <Nav />
        <Main>
            <div style="margin-right: 50px;">
                <div class="mx-20">
                    <h1 class="pt-6 mb-2 text-3xl text-gray-700">
                        {{ $t('risk.matrix.title') + ': ' + $ct(matrix.name) }}
                    </h1>

                    <TabControl
                        :tabs="tabs"
                        :selected-tab-key="selectedTab"
                        @tab-changed="onTabChanged"
                    />

                    <div class="my-1 border-b border-gray-300"></div>

                    <div v-if="isLoading && !isError">
                        <LoadAnimationBar />
                        <LoadAnimationBar />
                    </div>

                    <NetworkError v-if="isError" :text="errorText" />

                    <div
                        v-if="!isLoading && !isError"
                        v-show="selectedTab === 'indicators'"
                        style="margin-top: 30px; padding-bottom: 100px;"
                    >

                        <RiskIndicatorComponent
                            v-for="(indicator, i) in matrix.indicators"
                            :key="'rmi' + indicator.key"
                            :indicator="indicator"
                            :assets="assets"
                            :percentage="percentages[i]"
                            @changed="onSomethingChanged"
                            @delete="onDeleteIndicator(i)"
                            @move="onMoveIndicator(i, $event)"
                        />

                    </div>
                    <div
                        v-if="!isLoading && !isError"
                        v-show="selectedTab === 'comments'"
                        style="margin-top: 30px; padding-bottom: 100px;"
                    >

                        <RiskCommentComponent
                            v-for="(comment, i) in matrix.comments"
                            :key="'rmc' + i"
                            :comment="comment"
                            @changed="onSomethingChanged"
                        />

                    </div>
                </div>
            </div>

            <RiskSidebar
                :is-edited="isEdited"
                :is-outdated="isOutdated"
                @add="showAddDialog"
                @save="saveMatrix"
                @promote="promoteDialog.visible = true"
            />

        </Main>

        <AddRiskIndicatorDialog
            v-if="addDialog.visible"
            :matrix="matrix"
            :assets="assets"
            @close-modal="addDialog.visible = false"
            @add-indicator="onAddIndicator"
        />

        <AddRiskCommentDialog
            v-if="commentDialog.visible"
            @close-modal="commentDialog.visible = false"
            @add="onAddComment"
        />

        <PromoteMatrixDialog
            v-if="promoteDialog.visible"
            :matrix="matrix"
            @close-modal="promoteDialog.visible = false"
            @promoted="onPromoted"
        />

    </Layout>
</template>
<script lang="ts">
import { defineComponent } from "vue";

import { getRiskMatrix } from "@/lib/risk/get_matrices";

import Layout from "@/components/Layout.vue";
import Main from "@/components/Main.vue";
import Nav from "@/components/Nav.vue";

import LoadAnimationBar from "@/ui/LoadAnimationBar.vue";
import NetworkError from "@/ui/NetworkError.vue";

import { RiskCommentDefinition, RiskIndicatorDefinition, RiskMatrixDefinition } from "@/lib/risk/risk_matrix";

import { updateRiskMatrix, UpdateRiskMatrixOptions } from "@/lib/risk/update_matrix";
import { TabControlTab } from "@/types/ui_types";
import TabControl from "@/ui/TabControl.vue";
import AddRiskIndicatorDialog from "./AddRiskIndicatorDialog.vue";
import RiskIndicatorComponent from "./RiskIndicatorComponent.vue";
import RiskSidebar from "./controls/RiskSidebar.vue";
import { RiskAssets } from "./risk_assets";
import PromoteMatrixDialog from "./PromoteMatrixDialog.vue";
import AddRiskCommentDialog from "./AddRiskCommentDialog.vue";
import RiskCommentComponent from "./RiskCommentComponent.vue";

export default defineComponent({
    name: "RiskMatrixEditor",
    components: {
        Nav,
        Layout,
        Main,
        LoadAnimationBar,
        NetworkError,
        RiskIndicatorComponent,
        AddRiskIndicatorDialog,
        RiskSidebar,
        TabControl,
        PromoteMatrixDialog,
        AddRiskCommentDialog,
        RiskCommentComponent,
    },
    data() {
        return {
            isLoading: true,
            isError: false,
            isEdited: false,
            isOutdated: false,
            errorText: "",
            matrix: {} as RiskMatrixDefinition,
            addDialog: { visible: false },
            commentDialog: { visible: false },
            promoteDialog: { visible: false },
            syncWaiting: false,
            syncWaitCount: 0,
            assets: {} as RiskAssets,
            percentages: new Array<string>(),

            tabs: [
                { key: "indicators", rawText: "Risk Indicators" },
                /* { key: "comments", rawText: "Required Comments" }, */
            ] as TabControlTab[],
            selectedTab: "indicators",
        };
    },
    methods: {

        onTabChanged(key: string) {
            this.selectedTab = key;
        },

        showAddDialog() {
            if (this.selectedTab === "comments") {
                this.commentDialog.visible = true;
            } else {
                this.addDialog.visible = true;
            }
        },

        onAddIndicator(indicator: RiskIndicatorDefinition) {
            this.matrix.indicators.push(indicator);
            this.onSomethingChanged();
        },

        onMoveIndicator(index: number, down: boolean) {
            const indicator = this.matrix.indicators[index];
            this.matrix.indicators.splice(index, 1);
            if (down) {
                this.matrix.indicators.splice(index + 1, 0, indicator);
            } else {
                this.matrix.indicators.splice(index - 1, 0, indicator);
            }
            this.onSomethingChanged();
        },

        onDeleteIndicator(index: number) {
            this.matrix.indicators.splice(index, 1);
            this.onSomethingChanged();
        },

        onAddComment(comment: RiskCommentDefinition) {
            if (!this.matrix.comments) this.matrix.comments = [];
            this.matrix.comments.push(comment);
            this.onSomethingChanged();
        },

        calculatePercentages() {
            this.percentages = [];
            const weightSum = this.matrix.indicators.reduce((a, b) => {
                return a + b.weight;
            }, 0);
            this.matrix.indicators.forEach((indicator) => {
                this.percentages.push(((indicator.weight / weightSum) * 100).toFixed(0));
            });
        },

        onSomethingChanged() {
            this.isEdited = true;
            this.calculatePercentages();

            if (this.syncWaiting) {
                console.log("something changed - resetting wait time");
                this.syncWaitCount = 5;
            } else {
                console.log("something changed - starting loop");
                this.startWaitLoop();
            }
        },

        async startWaitLoop() {
            this.syncWaiting = true;
            this.syncWaitCount = 5;
            while (this.syncWaitCount > 0) {
                console.log("waiting for sync", this.syncWaitCount);
                // eslint-disable-next-line promise/param-names
                await new Promise((r) => setTimeout(r, 1000));
                this.syncWaitCount -= 1;
            }
            this.syncWaiting = false;
            // todo: sync test customers
        },

        async saveMatrix() {
            const options: UpdateRiskMatrixOptions = {
                indicators: this.matrix.indicators,
                comments: this.matrix.comments,
            };
            const response = await updateRiskMatrix(this.matrix.key, options);
            if (response.responseInfo.isError || !response.matrix) {
                console.log("save error:", response.responseInfo.parsedError?.message || "Unknown Error");
            } else {
                console.log("saved", response.matrix);
                this.isEdited = false;
                if (response.matrix.prodVersion < response.matrix.devVersion) this.isOutdated = true;
            }
        },

        onPromoted() {
            this.loadMatrix();
        },

        async loadMatrix() {
            this.isError = false;
            this.isLoading = true;

            const matrixKey = this.$route.query.key as string;

            if (!matrixKey) {
                this.isError = true;
                this.errorText = "Missing key!";
                return;
            }

            const response = await getRiskMatrix(matrixKey);

            if (response.responseInfo.isError || !response.matrix) {
                this.isError = true;
                this.errorText = response.responseInfo.parsedError?.message || "Unknown Error";
            } else {
                this.matrix = response.matrix;
                this.isOutdated = (this.matrix.prodVersion < this.matrix.devVersion);
                this.assets = await this.$assets.getRiskAssets();
            }

            this.calculatePercentages();
            this.isLoading = false;
        },

    },
    async created() {
        await this.$router.isReady();
        this.loadMatrix();
    },
});
</script>
