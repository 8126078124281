<template>
    <div class="riskv3-vc" @click.stop>

        <div class="riskv3-vc-params" v-if="!hideParams">
            <FontIcon :icon="'gear'" :size="12" />
        </div>

        <div
            class="riskv3-vc-score"
            :class="[
                { 'riskv3-vc-score-indirect': score.isIndirect },
            ]"
        >

            <div class="riskv3-vc-btn riskv3-vc-l select-none" @click="subScore">
                {{ '-' }}
            </div>

            <div class="riskv3-vc-value select-none">
                {{ score.score }}
            </div>

            <div class="riskv3-vc-btn riskv3-vc-r select-none" @click="addScore">
                {{ '+' }}
            </div>

        </div>

    </div>
</template>
<script lang="ts">
import FontIcon from "@/ui/FontIcon.vue";
import { defineComponent, PropType } from "vue";
import { RiskScoreResult } from "../risk_helper";

export default defineComponent({
    name: "RiskScoreControl",
    components: {
        FontIcon,
    },
    emits: ["score-changed"],
    props: {
        score: { type: Object as PropType<RiskScoreResult>, required: true },
        scoreKey: { type: String as PropType<string>, required: true },
        hideParams: { type: Boolean as PropType<boolean>, default: false },
    },
    methods: {

        subScore() {
            this.$emit("score-changed", this.scoreKey, this.score.score - 1);
        },

        addScore() {
            this.$emit("score-changed", this.scoreKey, this.score.score + 1);
        },

    },
});
</script>
<style scoped>
.riskv3-vc {
    display: flex;
}

.riskv3-vc-params {
    width: 24px;
    margin-right: 10px;
    padding-top: 2px;
    text-align: center;
    color: lightgray;
    border-radius: 4px;
    border: solid 1px lightgray;
    background-color: whitesmoke;
    cursor: pointer;
}

.riskv3-vc-params:hover {
    background-color: lightgray;
}

.riskv3-vc-score {
    display: flex;
}

.riskv3-vc-score-indirect {
    opacity: 0.5;
}

.riskv3-vc-value {
    width: 20px;
    height: 22px;
    line-height: 21px;
    text-align: center;
    border-top: solid 1px lightgray;
    border-bottom: solid 1px lightgray;
    background-color: whitesmoke;
}

.riskv3-vc-btn {
    width: 20px;
    height: 22px;
    line-height: 21px;
    text-align: center;
    border: solid 1px lightgray;
    background-color: whitesmoke;
    cursor: pointer;
}

.riskv3-vc-btn:hover {
    background-color: lightgray;
}

.riskv3-vc-l {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.riskv3-vc-r {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
</style>
