import { FlowRenderData } from "@/types/flow_types";
import { Form } from "../forms/forms";
import { Verified } from "../verified_plugin";
import { Project } from "./projects";
import { DropdownItem } from "@/ui/inputs/input_helper";
import { Assessment } from "../assessments/assessments";

export interface GetProjectResponse {
    project: Project;
    assessments: Assessment[];
    forms: Form[];
    renderData: FlowRenderData;
    dependency: any;
}

export interface Employee {
    uid: string;
    name: string;
}

export const getProject = async (id: string): Promise<Project> => {
    try {
        const response = await fetch(`/api/aml/v1/projects/${id}`, {
            method: "GET",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
        });

        const body = await response.json();

        if (!response.ok) {
            throw new Error(body);
        }

        return body as Project;
    } catch (err) {
        console.error(`Failed to get project with id ${id}`, err);
        throw err;
    }
};

export const getProjectProcess = async (id: string): Promise<GetProjectResponse> => {
    try {
        const response = await fetch(`/api/aml/v1/projects/${id}/process`, {
            method: "GET",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
        });

        const body = await response.json();

        if (!response.ok) {
            throw new Error(body);
        }

        return {
            project: body.project as Project,
            assessments: body.assessments as Assessment[],
            forms: body.forms as Form[],
            renderData: body.renderData as FlowRenderData,
            dependency: body.dependency,
        };
    } catch (err) {
        console.error(`Failed to get project process with id ${id}`, err);
        throw err;
    }
};

export const queryProjects = async (
    skip: number,
    limit: number,
    search: string,
    from?: string,
    to?: string,
    assignee?: string,
    identity?: string,
    workflow?: string,
    risk?: string[],
    type?: string [],
    assessmentStatus?: string,

): Promise<{ total: number; projects: Project[] }> => {
    try {
        const baseUrl = "/api/aml/v1/projects";
        const params = new URLSearchParams({
            skip: skip.toString(),
            limit: limit.toString(),
            search,
            from: from || "",
            to: to || "",
            assignee: assignee || "",
            identity: identity || "",
            workflow: workflow || "",
            assessmentStatus: assessmentStatus || "",
        });

        if (risk && risk.length > 0) {
            risk.forEach(rk => params.append("risk[]", rk));
        }
        if (type && type.length !== 0) {
            type.forEach(tp => params.append("type[]", tp));
        }
        const url = `${baseUrl}?${new URLSearchParams(params).toString()}`;
        const response = await fetch(url, {
            method: "GET",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
        });

        const body = await response.json();

        if (!response.ok) {
            throw new Error(body);
        }

        return body as { total: number; projects: Project[] };
    } catch (err) {
        console.error("Failed to query projects", err);
        throw err;
    }
};
