import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-af83a5bc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "riskv3-logicctrl-source" }
const _hoisted_2 = { class: "riskv3-logicctrl-icon" }
const _hoisted_3 = { class: "riskv3-logicctrl-text" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontIcon = _resolveComponent("FontIcon")!
  const _component_PopupDialog = _resolveComponent("PopupDialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.logicList, (source, i) => {
      return (_openBlock(), _createElementBlock("div", {
        key: 'rsc' + i,
        class: _normalizeClass(["riskv3-logicctrl-item", [
                {'riskv3-logicctrl-item-first': i === 0 },
                {'riskv3-logicctrl-item-last': i === _ctx.logicList.length - 1 },
            ]])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_FontIcon, {
              icon: _ctx.getIcon(source),
              size: 14
            }, null, 8, ["icon"])
          ]),
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.getSourceName(source)), 1)
        ]),
        _createElementVNode("div", {
          class: "riskv3-logicctrl-delete",
          onClick: ($event: any) => (_ctx.showDeleteDialog(source))
        }, [
          _createVNode(_component_FontIcon, {
            icon: 'trash-can',
            size: 14
          })
        ], 8, _hoisted_4)
      ], 2))
    }), 128)),
    _createVNode(_component_PopupDialog, {
      "is-visible": _ctx.deleteDialog.visible,
      header: _ctx.$t('common.actions.delete'),
      icon: 'trash-can',
      prompt: _ctx.$t('common.actions.areYouSure'),
      "show-action-button": true,
      "show-cancel-button": true,
      "action-text": _ctx.$t('common.actions.delete'),
      "is-warning": true,
      onCloseModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.deleteDialog.visible = false)),
      onAction: _ctx.onDelete
    }, null, 8, ["is-visible", "header", "prompt", "action-text", "onAction"])
  ]))
}