<template>
    <div :class="'bg-background outline-1 text-[#555] rounded-lg p-3 pb-2 flex flex-col min-h-[80px]' + editableClass">
        <div class="flex mb-2">
            <div class="flex-1 uppercase text-xs font-bold">
                {{ title }}
            </div>
            <Icon
                v-for="icon in icons"
                class="w-4 h-4 ml-2"
                :icon="icon"
                :key="icon"
            />
            <div v-if="valueCount" class="text-xs">
                <a @click="$emit('openSidebarDialog', title)" class="group-hover:text-primary-default">{{ $t('common.otersCount', valueCount) }}</a>
            </div>
        </div>

        <div class="flex-1 flex">
            <div class=" flex-1">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue";
import Icon from "../Icon.vue";

export default defineComponent({
    components: { Icon },
    props: {
        title: {
            type: String,
            required: true,
            default: "",
        },
        icons: Array as PropType<string[]>,
        editable: Boolean,
        valueCount: Number,
    },
    emits: ["openSidebarDialog"],
    computed: {
        editableClass(): string {
            return this.editable ? " hover:bg-transparent hover:outline hover:text-[#444] cursor-pointer" : "";
        },
    },
});
</script>
