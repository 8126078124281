<template>
    <div class="flex" :style="'height: ' + height + 'px'">
        <slot> </slot>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Layout",
    data() {
        return {
            height: window.innerHeight,
            listener: undefined,
        };
    },
    methods: {
        resize() {
            const navEl = document.getElementById("navbar");

            let h = window.innerHeight;
            if (navEl) {
                h -= navEl.offsetHeight;
            }

            this.height = h;
            /* console.log(`aml resized to ${h}px`); */
        },
    },
    mounted() {
        this.resize();
        window.addEventListener("resize", this.resize);
    },
    unmounted() {
        window.removeEventListener("resize", this.resize);
    },
});
</script>
