import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontIcon = _resolveComponent("FontIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "riskv3-btn",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('clicked')))
  }, [
    _createVNode(_component_FontIcon, {
      icon: _ctx.icon,
      size: 18
    }, null, 8, ["icon"])
  ]))
}