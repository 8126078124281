import { ApiResponse, parseLocalError, parseRemoteError } from "../error_parser";
import { Verified } from "../verified_plugin";
import { Form } from "./forms";

export const getProjectForm = async (projectId: string, formId: string): Promise<{ form: Form | null, responseInfo: ApiResponse }> => {
    try {
        const response = await fetch(`/api/aml/v1/projects/${projectId}/forms/${formId}`, {
            method: "GET",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
        });

        const body = await response.json();

        if (!response.ok) {
            return {
                form: null,
                responseInfo: { isError: true, parsedError: parseRemoteError(body) },
            };
        }

        return {
            form: body.form as Form,
            responseInfo: { isError: false, parsedError: null },
        };
    } catch (error) {
        console.log("error", error);
        return {
            form: null,
            responseInfo: { isError: true, parsedError: parseLocalError(error) },
        };
    }
};
