<template>
    <div>
        <ProjectEventList
            v-if="!loading"
            :project="project"
            :events="events"
        />
    </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

import { Project } from "@/lib/projects/projects";

import { EventScope, queryEvents } from "@/lib/events/events";
import { EventDisplayData, getEventDisplayData } from "@/lib/events/event_helper";
import ProjectEventList from "../ProjectEventList.vue";

export default defineComponent({
    name: "MenuTabEvents",
    components: {
        ProjectEventList,
    },
    props: {
        project: { type: Object as PropType<Project>, required: true },
    },
    data() {
        return {
            loading: false,
            events: new Array<EventDisplayData>(),
        };
    },
    methods: {

        async fetchEvents() {
            this.loading = true;

            const events = await queryEvents({
                limit: 20,
                resource: this.project.id,
                eventScope: EventScope.Project,
            });

            this.events = [];
            events.events.forEach((event) => {
                this.events.push(getEventDisplayData(event));
            });

            console.log("events", this.events);
            this.loading = false;
        },

    },
    created() {
        this.fetchEvents();
    },
});
</script>
<style scoped>

</style>
