import { Verified } from "../verified_plugin";
import { Attachment } from "./person";

export const addAttachment = async (attachment: Attachment, personId: string) => {
    try {
        const response = await fetch(`/api/aml/v1/persons/${personId}/attachments`, {
            method: "post",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
            body: JSON.stringify({
                ...attachment,
            }),
        });

        const body = await response.json();

        if (!response.ok) {
            throw new Error(body);
        }

        return body as {url: string, fileId: string};
    } catch (err) {
        console.error("Failed to query projects", err);
        throw err;
    }
};
