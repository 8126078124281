import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "amlv3-atab"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "mt-4" }
const _hoisted_4 = {
  key: 0,
  class: "mt-4"
}
const _hoisted_5 = {
  key: 1,
  style: {"height":"100%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IndicatorList = _resolveComponent("IndicatorList")!
  const _component_RiskSliderBox = _resolveComponent("RiskSliderBox")!
  const _component_RiskComments = _resolveComponent("RiskComments")!
  const _component_FlowRequirements = _resolveComponent("FlowRequirements")!
  const _component_IndicatorDetails = _resolveComponent("IndicatorDetails")!
  const _component_NewAssessmentDialog = _resolveComponent("NewAssessmentDialog")!

  return (!_ctx.isLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(["amlv3-atab-left", [
                { 'amlv3-atab-left-scrolled': _ctx.scrolled },
            ]])
        }, [
          _createVNode(_component_IndicatorList, {
            assessment: _ctx.currentAssessment,
            "selected-key": _ctx.selectedIndicator ? _ctx.selectedIndicator.key : '',
            onSelected: _ctx.onSelectIndicator
          }, null, 8, ["assessment", "selected-key", "onSelected"])
        ], 2),
        (_ctx.matrixDefinition)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["amlv3-atab-right", [
                { 'amlv3-atab-right-scrolled': _ctx.scrolled },
            ]]),
              style: _normalizeStyle(_ctx.scrolled ? '' : `height: calc(100vh - ${170 - _ctx.scrollTop}px);`)
            }, [
              (!_ctx.selectedIndicator)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createElementVNode("div", null, [
                      (_ctx.riskConfig)
                        ? (_openBlock(), _createBlock(_component_RiskSliderBox, {
                            key: 0,
                            project: _ctx.project,
                            assessment: _ctx.currentAssessment,
                            "risk-config": _ctx.riskConfig,
                            "matrix-definition": _ctx.matrixDefinition,
                            onRequestProjectRefresh: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('request-project-refresh', $event))),
                            onNewAssessment: _cache[1] || (_cache[1] = ($event: any) => (_ctx.newDialog.visible = true))
                          }, null, 8, ["project", "assessment", "risk-config", "matrix-definition"]))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode(_component_RiskComments, {
                        project: _ctx.project,
                        assessment: _ctx.currentAssessment,
                        "matrix-definition": _ctx.matrixDefinition,
                        onRequestProjectRefresh: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('request-project-refresh', $event)))
                      }, null, 8, ["project", "assessment", "matrix-definition"])
                    ]),
                    (!_ctx.currentAssessment.locked)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                          _createVNode(_component_FlowRequirements, {
                            project: _ctx.project,
                            requirements: _ctx.flowRenderData.requirements
                          }, null, 8, ["project", "requirements"])
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.selectedIndicator && _ctx.selectedDefinition)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    (_openBlock(), _createBlock(_component_IndicatorDetails, {
                      key: _ctx.selectedDefinition.key,
                      assets: _ctx.riskAssets,
                      indicator: _ctx.selectedIndicator,
                      matrix: _ctx.matrixDefinition,
                      definition: _ctx.selectedDefinition,
                      project: _ctx.project,
                      assessment: _ctx.currentAssessment,
                      onCloseModal: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onSelectIndicator(''))),
                      onRequestProjectRefresh: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('request-project-refresh', $event)))
                    }, null, 8, ["assets", "indicator", "matrix", "definition", "project", "assessment"]))
                  ]))
                : _createCommentVNode("", true)
            ], 6))
          : _createCommentVNode("", true),
        (_ctx.newDialog.visible)
          ? (_openBlock(), _createBlock(_component_NewAssessmentDialog, {
              key: 1,
              project: _ctx.project,
              onCloseModal: _cache[5] || (_cache[5] = ($event: any) => (_ctx.newDialog.visible = false)),
              onRequestProjectRefresh: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('request-project-refresh', $event)))
            }, null, 8, ["project"]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}