<template>
    <div class="fbav3-input-group" :style="`width: ${computedWidth}%;`">

        <slot />

    </div>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";

export default defineComponent({
    name: "InputGroup",
    props: {
        width: { type: Number as PropType<number>, required: true },
    },
    computed: {

        computedWidth(): number {
            return this.width * 8.333334;
        },

    },
});
</script>
<style scoped>
.fbav3-input-group {
    margin-bottom: 10px;
    padding: 0px 5px;
    box-sizing: border-box;
}
</style>
