import { LocalizedString } from "../common/common";
import { ApiResponse, parseLocalError, parseRemoteError } from "../error_parser";
import { Verified } from "../verified_plugin";
import { Assessment } from "./assessments";

// This should map to the api-spec version without values that are set by the api
export interface LocalAssessmentValue {
    indicatorKey: string;
    value: any;
    override: boolean;
}

export const addAssessmentValues = async (
    projectId: string,
    assessmentId: string,
    values: LocalAssessmentValue[],
): Promise<{ assessment: Assessment | null, responseInfo: ApiResponse }> => {
    try {
        const response = await fetch(`/api/aml/v1/projects/${projectId}/assessments/${assessmentId}/values`, {
            method: "POST",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
            body: JSON.stringify(values),
        });

        const body = await response.json();

        if (!response.ok) {
            return {
                assessment: null,
                responseInfo: { isError: true, parsedError: parseRemoteError(body) },
            };
        }

        return {
            assessment: body as Assessment,
            responseInfo: { isError: false, parsedError: null },
        };
    } catch (error) {
        return {
            assessment: null,
            responseInfo: { isError: true, parsedError: parseLocalError(error) },
        };
    }
};

// This should map to the api-spec version without values that are set by the api
export interface LocalAssessmentComment {
    indicatorKey?: string;
    promptKey?: string;
    prompt?: LocalizedString;
    comment: string;
}

export const addAssessmentComments = async (
    projectId: string,
    assessmentId: string,
    comments: LocalAssessmentComment[],
): Promise<{ assessment: Assessment | null, responseInfo: ApiResponse }> => {
    try {
        const response = await fetch(`/api/aml/v1/projects/${projectId}/assessments/${assessmentId}/comments`, {
            method: "POST",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
            body: JSON.stringify(comments),
        });

        const body = await response.json();

        if (!response.ok) {
            return {
                assessment: null,
                responseInfo: { isError: true, parsedError: parseRemoteError(body) },
            };
        }

        return {
            assessment: body as Assessment,
            responseInfo: { isError: false, parsedError: null },
        };
    } catch (error) {
        return {
            assessment: null,
            responseInfo: { isError: true, parsedError: parseLocalError(error) },
        };
    }
};

export const updateAssessmentValue = async (
    projectId: string,
    assessmentId: string,
    valueId: string,
    value: any,
    override: boolean,
): Promise<{ assessment: Assessment | null, responseInfo: ApiResponse }> => {
    try {
        const response = await fetch(`/api/aml/v1/projects/${projectId}/assessments/${assessmentId}/values/${valueId}`, {
            method: "PUT",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
            body: JSON.stringify({
                value,
                override,
            }),
        });

        const body = await response.json();

        if (!response.ok) {
            return {
                assessment: null,
                responseInfo: { isError: true, parsedError: parseRemoteError(body) },
            };
        }

        return {
            assessment: body as Assessment,
            responseInfo: { isError: false, parsedError: null },
        };
    } catch (error) {
        return {
            assessment: null,
            responseInfo: { isError: true, parsedError: parseLocalError(error) },
        };
    }
};

export const updateAssessmentComment = async (
    projectId: string,
    assessmentId: string,
    commentId: string,
    comment: string,
): Promise<{ assessment: Assessment | null, responseInfo: ApiResponse }> => {
    try {
        const response = await fetch(`/api/aml/v1/projects/${projectId}/assessments/${assessmentId}/comments/${commentId}`, {
            method: "PUT",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
            body: JSON.stringify({
                comment,
            }),
        });

        const body = await response.json();

        if (!response.ok) {
            return {
                assessment: null,
                responseInfo: { isError: true, parsedError: parseRemoteError(body) },
            };
        }

        return {
            assessment: body as Assessment,
            responseInfo: { isError: false, parsedError: null },
        };
    } catch (error) {
        return {
            assessment: null,
            responseInfo: { isError: true, parsedError: parseLocalError(error) },
        };
    }
};

export const deleteAssessmentValue = async (
    projectId: string,
    assessmentId: string,
    valueId: string,
): Promise<{ assessment: Assessment | null, responseInfo: ApiResponse }> => {
    try {
        const response = await fetch(`/api/aml/v1/projects/${projectId}/assessments/${assessmentId}/values/${valueId}`, {
            method: "DELETE",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
        });

        const body = await response.json();

        if (!response.ok) {
            return {
                assessment: null,
                responseInfo: { isError: true, parsedError: parseRemoteError(body) },
            };
        }

        return {
            assessment: body as Assessment,
            responseInfo: { isError: false, parsedError: null },
        };
    } catch (error) {
        return {
            assessment: null,
            responseInfo: { isError: true, parsedError: parseLocalError(error) },
        };
    }
};

export const deleteAssessmentComment = async (
    projectId: string,
    assessmentId: string,
    commentId: string,
): Promise<{ assessment: Assessment | null, responseInfo: ApiResponse }> => {
    try {
        const response = await fetch(`/api/aml/v1/projects/${projectId}/assessments/${assessmentId}/comments/${commentId}`, {
            method: "DELETE",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
        });

        const body = await response.json();

        if (!response.ok) {
            return {
                assessment: null,
                responseInfo: { isError: true, parsedError: parseRemoteError(body) },
            };
        }

        return {
            assessment: body as Assessment,
            responseInfo: { isError: false, parsedError: null },
        };
    } catch (error) {
        return {
            assessment: null,
            responseInfo: { isError: true, parsedError: parseLocalError(error) },
        };
    }
};
