import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Doughnut = _resolveComponent("Doughnut")!

  return (_openBlock(), _createBlock(_component_Doughnut, {
    ref: "my-chart-id",
    options: $props.chartOptions,
    data: $props.chartData
  }, null, 8, ["options", "data"]))
}