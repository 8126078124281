<template>
    <div>
        <label :for="label" class="block text-sm leading-4">{{ label }}</label>
        <span class="block text-xs leading-3"></span>
        <div class="relative mt-2 rounded-md">
            <input
                type="email"
                name="email"
                id="email"
                class="block w-full rounded-md border border-light border-solid py-1.5 pr-10 sm:text-sm sm:leading-6"
                :placeholder="placeholder"
                :value="modelValue"
                aria-invalid="true"
                aria-describedby="email-error"
                @input="updateInputValue"
            />
            <!--<div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <Icon class="h-5 w-5 text-red-500" icon="error" aria-hidden="true" />
            </div>-->
        </div>
        <!--<p class="mt-2 text-sm text-red-600" id="email-error">Not a valid email address.</p>-->
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Icon from "./Icon.vue";

export default defineComponent({
    name: "Input",
    props: {
        modelValue: {
            type: String,
            default: "",
        },
        placeholder: {
            type: String,
            default: "",
        },
        label: {
            type: String,
            default: "",
        },
        info: {
            type: String,
            default: "",
        },
    },
    emits: ["update:modelValue"],
    components: {
        Icon,
    },
    methods: {
        updateInputValue(event: Event) {
            console.log((event.target as HTMLInputElement).value);
            this.$emit("update:modelValue", (event.target as HTMLInputElement).value);
        },
    },
});
</script>
