<template>
    <Dialog
        :open="true"
        @close="onclose"
        class="relative z-50"
    >
        <!-- The backdrop, rendered as a fixed sibling to the panel container -->
        <div class="fixed inset-0 bg-black/85" aria-hidden="true" />

        <!-- Full-screen scrollable container -->
        <div class="fixed inset-0 w-screen overflow-y-auto">
            <!-- Container to center the panel -->
            <div class="flex min-h-full items-center justify-center p-4">
                <!-- The actual dialog panel -->
                <DialogPanel :style="style" class="rounded-2xl p-6 bg-white">
                    <div class="flex items-center gap-2 mb-6">
                        <Icon
                            v-if="titleIcon"
                            :icon="titleIcon"
                            class="w-8 h-8"
                        />
                        <DialogTitle class="text-3xl font-roboto-slab m-0">
                            {{ title }}
                        </DialogTitle>
                        <Icon class="w-5 h-5 ml-3" :icon="smallIcon" />
                        <div
                            @click="forceClose"
                            class="p-3 ml-auto bg-background hover:bg-gray-200 rounded-full w-10 h-10 flex cursor-pointer"
                        >
                            <svg
                                viewBox="0 0 14 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M13 1.5L1 13.5M1 1.5L13 13.5"
                                    stroke="#444"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </div>
                    </div>

                    <slot></slot>
                </DialogPanel>
            </div>
        </div>
    </Dialog>
</template>

<script lang="ts">
import Icon from "@/components/Icon.vue";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/vue";
import { defineComponent } from "vue";

export default defineComponent({
    components: {
        Dialog,
        DialogPanel,
        DialogTitle,
        Icon,
    },
    props: {
        ignoreClickOutside: Boolean,
        title: String,
        width: String,
        titleIcon: String,
        smallIcon: String,
    },
    emits: ["closed"],
    data() {
        return {};
    },
    computed: {
        style(): string {
            return this.width ? `width: ${this.width}` : "width: 500px";
        },
    },
    methods: {
        onclose() {
            if (!this.ignoreClickOutside) {
                this.$emit("closed");
            }
        },
        forceClose() {
            this.$emit("closed");
        },
    },
    mounted() {},
});
</script>
