import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProjectEventList = _resolveComponent("ProjectEventList")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.loading)
      ? (_openBlock(), _createBlock(_component_ProjectEventList, {
          key: 0,
          project: _ctx.project,
          events: _ctx.events
        }, null, 8, ["project", "events"]))
      : _createCommentVNode("", true)
  ]))
}