import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "group flex items-center min-h-[5.5rem] py-5 pr-5 pl-3 gap-2 box-border cursor-pointer bg-white hover:bg-background" }
const _hoisted_2 = { class: "h-full border-r border-background pr-1" }
const _hoisted_3 = { class: "min-w-0 pl-1 sm:pl-2 w-full" }
const _hoisted_4 = { class: "flex items-center truncate" }
const _hoisted_5 = { class: "min-w-0 space-y-3" }
const _hoisted_6 = { class: "leading-5 text-gray-700 text-base" }
const _hoisted_7 = { class: "flex items-center gap-x-1 text-xs truncate min-w-0 leading-4 text-light-gray group-hover:text-gray-700 whitespace-nowrap" }
const _hoisted_8 = { class: "truncate min-w-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_StatusTag = _resolveComponent("StatusTag")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Icon, {
        class: "w-12 h-12 sm:w-12 sm:h-12 rounded-full p-2",
        icon: "blueBacgroundPerson"
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.details?.givenName + " " + _ctx.details?.familyName), 1),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.details?.name), 1),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.statusPills, (statusPill) => {
              return (_openBlock(), _createElementBlock("div", {
                key: statusPill.title
              }, [
                _createVNode(_component_StatusTag, {
                  status: statusPill.title,
                  "status-color": statusPill.statusColor,
                  "show-icon": statusPill.showIcon,
                  icon: statusPill.icon,
                  "border-color": statusPill.borderColor
                }, null, 8, ["status", "status-color", "show-icon", "icon", "border-color"])
              ]))
            }), 128))
          ])
        ])
      ])
    ])
  ]))
}