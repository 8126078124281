<template>
    <div class="flex gap-4 items-center bg-white py-2 px-4 border border-muted h-20 w-1/2 shadow-sm">
        <Icon icon="person" class="w-16 h-16" />
        <div class="text-lg">
            <p class="">Name: {{ partner.name }}</p>
            <p class="flex gap-3">
                <label>Employee#: {{ partner.employee }}</label>
                <label>Email: {{ partner.email }}</label>
            </p>
        </div>
    </div>
</template>

<script lang="ts">
import { Partner } from "@/lib/common/companyPartner";
import { defineComponent, PropType } from "vue";
import Icon from "../../Icon.vue";

export default defineComponent({
    name: "PartnerCard",
    componets: {
        Icon,
    },
    props: {
        partner: {
            type: {} as PropType<Partner>,
            required: true,
        },
    },
});
</script>
