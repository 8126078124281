<template>
    <div class="min-h-[100px] border-slate-300 border rounded-lg p-2 flex flex-col justify-between">
        <div class="text-xl font-medium">
            {{ value }}
        </div>
        <div class="text-sm text-slate-500">
            {{ label }}
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        label: {
            type: String,
            default: "",
        },
        value: {
            type: [String, Number],
            default: "",
        },
    },
});
</script>
