import { Verified } from "../verified_plugin";
import { IDControlDetails, Person, PersonInfoBlock } from "./person";

export const updatePerson = async (personId: string, block: PersonInfoBlock) => {
    try {
        const response = await fetch(`/api/aml/v1/persons/${personId}/data`, {
            method: "PATCH",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
            body: JSON.stringify({
                person_info: block,
            }),
        });

        const body = await response.json();

        if (!response.ok) {
            throw new Error(body);
        }

        return body as Person;
    } catch (err) {
        console.error("Failed to query projects", err);
        throw err;
    }
};

export const updatePersonInfo = async (personId: string, block: PersonInfoBlock) => {
    try {
        const response = await fetch(`/api/aml/v1/persons/${personId}/data/select-active-source`, {
            method: "PUT",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
            body: JSON.stringify({
                person_info: block,
            }),
        });

        const body = await response.json();

        if (!response.ok) {
            throw new Error(body);
        }

        return body as Person;
    } catch (err) {
        console.error("Failed to query projects", err);
        throw err;
    }
};

export const updateIdControlDetails = async (personId: string, data: IDControlDetails) => {
    try {
        const response = await fetch(`/api/aml/v1/persons/${personId}/idcontrol`, {
            method: "put",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
            body: JSON.stringify({
                ...data,
            }),
        });

        const body = await response.json();

        if (!response.ok) {
            throw new Error(body);
        }

        return body as Person;
    } catch (err) {
        console.error("Failed to query projects", err);
        throw err;
    }
};

export const deleteIdControl = async (personId: string) => {
    try {
        const response = await fetch(`/api/aml/v1/persons/${personId}/idcontrol`, {
            method: "delete",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
        });

        const body = await response.json();

        if (!response.ok) {
            throw new Error(body);
        }

        return body as Person;
    } catch (err) {
        console.error("Failed to query projects", err);
        throw err;
    }
};
