import { RiskIndicatorDefinition } from "@/lib/risk/risk_matrix";

export enum FlagKey {
    SingleValue = "single_value",
    NotApplicableIfEmpty = "na_if_empty",
    AllowManualNotApplicable = "allow_manual_na",
}

export const getPossibleFlags = (): string[] => {
    return [
        "single_value",
        "na_if_empty",
        "allow_manual_na",
    ];
};

export const getFlagName = (key: string): string => {
    switch (key) {
    case "single_value": return "Single value output";
    case "na_if_empty": return "N/A if empty";
    case "allow_manual_na": return "Allow manual N/A";
    }
    return "Unknown flag";
};

export const indicatorHasFlag = (flagKey: FlagKey, indicatorDefinition: RiskIndicatorDefinition): boolean => {
    for (const logic of indicatorDefinition.logic) {
        if (logic.key === flagKey) return true;
    }
    return false;
};
