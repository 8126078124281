import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full border border-solid border-light rounded-t-lg p-5" }
const _hoisted_2 = { class: "grid grid-cols-2 gap-3" }
const _hoisted_3 = { class: "" }
const _hoisted_4 = { class: "w-full flex justify-end gap-4 mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputLabel = _resolveComponent("InputLabel")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_InputGroup = _resolveComponent("InputGroup")!
  const _component_DatePicker = _resolveComponent("DatePicker")!
  const _component_SelectBox = _resolveComponent("SelectBox")!
  const _component_ActionButton = _resolveComponent("ActionButton")!
  const _component_Popup = _resolveComponent("Popup")!

  return (_openBlock(), _createBlock(_component_Popup, {
    onClosed: _ctx.closeAddPersonModal,
    "ignore-click-outside": true,
    width: "550px",
    title: _ctx.$t('persons.addPerson'),
    "title-icon": "person-blue"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_InputGroup, { width: 12 }, {
            default: _withCtx(() => [
              _createVNode(_component_InputLabel, {
                text: _ctx.$t('common.person_info.firstName'),
                "unique-id": 'first-name',
                required: ""
              }, null, 8, ["text"]),
              _createVNode(_component_InputText, {
                modelValue: _ctx.givenName,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.givenName) = $event)),
                "unique-id": 'given-name',
                placeholder: 'John'
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_InputGroup, { width: 12 }, {
            default: _withCtx(() => [
              _createVNode(_component_InputLabel, {
                text: _ctx.$t('common.person_info.lastName'),
                "unique-id": 'last-name',
                required: ""
              }, null, 8, ["text"]),
              _createVNode(_component_InputText, {
                modelValue: _ctx.familyName,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.familyName) = $event)),
                "unique-id": 'family-name',
                placeholder: 'Doe'
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_InputGroup, { width: 12 }, {
            default: _withCtx(() => [
              _createVNode(_component_InputLabel, {
                text: _ctx.$t('common.person_info.dateOfBirth'),
                "unique-id": 'date-of-birth',
                required: ""
              }, null, 8, ["text"]),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_DatePicker, {
                  modelValue: _ctx.dateOfBirth,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dateOfBirth) = $event))
                }, null, 8, ["modelValue"])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_InputGroup, { width: 12 }, {
            default: _withCtx(() => [
              _createVNode(_component_SelectBox, {
                modelValue: _ctx.roles,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.roles) = $event)),
                label: _ctx.$t('persons.role'),
                options: _ctx.roleOptions,
                "show-flag": false,
                required: ""
              }, null, 8, ["modelValue", "label", "options"])
            ]),
            _: 1
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_ActionButton, {
          theme: "transparent",
          loading: false,
          disabled: false,
          onBtnClicked: _ctx.closeAddPersonModal
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Cancel ")
          ]),
          _: 1
        }, 8, ["onBtnClicked"]),
        _createVNode(_component_ActionButton, {
          theme: "primary",
          loading: false,
          disabled: false,
          onBtnClicked: _ctx.addPersonWithRole
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Add Person ")
          ]),
          _: 1
        }, 8, ["onBtnClicked"])
      ])
    ]),
    _: 1
  }, 8, ["onClosed", "title"]))
}