<template>
    <PopupDialog
        :is-visible="dialog.visible"
        :header="$t('risk.indicators.add')"
        :icon="'wand-magic-sparkles'"
        :progress="dialog"
        :show-cancel-button="true"
        :show-action-button="true"
        :action-text="$t('risk.indicators.add')"
        @close-modal="onClose"
        @action="addIndicator"
    >

        <div style="max-width: 500px;">
            <TabControl
                :tabs="tabs"
                :selected-tab-key="selectedTabKey"
                @tab-changed="onTabChanged"
            />

            <div v-if="selectedTabKey === 'common'">
                <SimpleListItem
                    v-for="(indicator, i) in commonIndicators"
                    :key="'ci' + i"
                    :text="$ct(indicator.name)"
                    :sub-text="$ct(indicator.hint)"
                    :is-selected="indicator.key === selectedKey"
                    :is-first="i === 0"
                    :is-last="i === commonIndicators.length - 1"
                    @clicked="selectIndicator(indicator)"
                />
            </div>

            <div v-if="selectedTabKey === 'types'">
                <SimpleListItem
                    v-for="(indicator, i) in typeIndicators"
                    :key="'ci' + i"
                    :text="$ct(indicator.name)"
                    :sub-text="$ct(indicator.hint)"
                    :is-selected="indicator.key === selectedKey"
                    :is-first="i === 0"
                    :is-last="i === typeIndicators.length - 1"
                    @clicked="selectIndicator(indicator)"
                />
            </div>

            <RiskSourceFormWizard v-if="selectedTabKey === 'form'" @selected="onSelectFormIndicator" />

        </div>
    </PopupDialog>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

import { RiskIndicatorDefinition, RiskMatrixDefinition } from "@/lib/risk/risk_matrix";

import { TabControlTab } from "@/types/ui_types";
import PopupDialog from "@/ui/PopupDialog.vue";
import SimpleListItem from "@/ui/SimpleListItem.vue";
import TabControl from "@/ui/TabControl.vue";
import { AvailableIndicator, getCommonIndicators, getPredefinedIndicator, getTypeIndicators } from "./predefined_indicators";
import { RiskAssets } from "./risk_assets";
import RiskSourceFormWizard from "./source_wizard/RiskSourceWizardForm.vue";

export default defineComponent({
    name: "AddRiskIndicatorDialog",
    components: {
        PopupDialog,
        TabControl,
        SimpleListItem,
        RiskSourceFormWizard,
    },
    emits: ["add-indicator", "close-modal"],
    props: {
        matrix: { type: Object as PropType<RiskMatrixDefinition>, required: true },
        assets: { type: Object as PropType<RiskAssets>, required: true },
    },
    data() {
        return {
            dialog: { visible: true, isWorking: false, statusText: "", isError: false, errorText: "" },
            tabs: [
                { key: "common", text: "Common" },
                { key: "types", text: "Raw Types" },
                { key: "form", text: "Form Data" },
            ] as TabControlTab[],
            selectedTabKey: "types",
            commonIndicators: new Array<AvailableIndicator>(),
            typeIndicators: new Array<AvailableIndicator>(),
            selectedKey: "",

            formIndicator: {} as RiskIndicatorDefinition,
        };
    },
    methods: {

        onTabChanged(key: string) {
            this.selectedTabKey = key;
        },

        selectIndicator(indicator: AvailableIndicator) {
            if (this.selectedKey === indicator.key) {
                this.selectedKey = "";
            } else {
                this.selectedKey = indicator.key;
            }
        },

        onSelectFormIndicator(indicator: RiskIndicatorDefinition) {
            this.formIndicator = indicator;
        },

        async addIndicator() {
            if (this.selectedTabKey === "form") {
                this.$emit("add-indicator", this.formIndicator);
            } else {
                const indicator = getPredefinedIndicator(this.selectedKey, this.assets);
                this.$emit("add-indicator", indicator);
            }
            this.onClose();
        },

        onClose() {
            this.$emit("close-modal");
        },

    },
    mounted() {
        this.commonIndicators = getCommonIndicators();
        this.typeIndicators = getTypeIndicators();
    },
});
</script>
