import { LocalizedString } from "@/lib/common/common";
import { RiskValue, RiskValueType } from "@/lib/risk/risk_matrix";

export interface RiskSourceKeyItem {
    key: string;
    name: string;
    text: LocalizedString;
    hint: string;
    type: RiskValueType;
    values?: RiskValue[];
}

export const isValidSource = (source: RiskSourceKeyItem, value: RiskValueType): boolean => {
    if (!value) return true;

    const types = [
        {
            type: RiskValueType.HighRiskCountry,
            accepted: [
                RiskValueType.Country,
            ],
        },
    ];

    if (source.type === value) return true;

    for (const type of types) {
        if (type.type === value) {
            if (type.accepted.includes(source.type)) return true;
        }
    }

    return false;
};

export const getInputValueType = (value: RiskValueType): RiskValueType => {
    if (value === RiskValueType.HighRiskCountry) return RiskValueType.Country;
    if (value === RiskValueType.PepHit) return RiskValueType.YesNo;
    return value;
};

export const getCompanySources = (): RiskSourceKeyItem[] => {
    const list: RiskSourceKeyItem[] = [];

    list.push({
        key: "reg-country",
        name: "Country of Registration",
        text: { en_EN: "Country of Registration" },
        hint: "The country in which the company is registered",
        type: RiskValueType.Country,
    });

    list.push({
        key: "nace-codes",
        name: "Business Codes",
        text: { en_EN: "Business Codes" },
        hint: "A list of business codes (NACE/SNI)",
        type: RiskValueType.NaceCode,
    });

    return list;
};

export const getRoleSources = (): RiskSourceKeyItem[] => {
    const list: RiskSourceKeyItem[] = [];

    list.push({
        key: "residency",
        name: "Country of Residence",
        text: { en_EN: "Country of Residence" },
        hint: "Country of Residence",
        type: RiskValueType.Country,
    });

    list.push({
        key: "citizenship",
        name: "Citizenship",
        text: { en_EN: "Citizenship" },
        hint: "Citizenship/Nationality",
        type: RiskValueType.Country,
    });

    list.push({
        key: "pep",
        name: "PEP Hit",
        text: { en_EN: "PEP Hit" },
        hint: "Checks if the role has a PEP hit",
        type: RiskValueType.PepHit,
    });

    return list;
};
