<template>
    <PopupDialog
        :is-visible="true"
        :header="title"
        :icon="'list-check'"
        :show-cancel-button="true"
        :show-action-button="true"
        :action-text="'Save'"
        :action-icon="'floppy-disk'"
        :is-action-disabled="!dialog.valid"
        @close-modal="onClose"
        @action="onSave"
    >
        <PopupDialogSplit
            v-if="!selectedFlowKey"
            :prompt-text="'Select base workflow:'"
            :prompt-hint="'promptHint'"
        >

            <SimpleListItem
                v-for="(flow, i) in availableFlows"
                :key="'aft' + i"
                :text="$ct(flow.name)"
                :sub-text="flow.key"
                :is-first="i === 0"
                :is-last="i === availableFlows.length - 1"
                @clicked="selectFlow(flow)"
            />

        </PopupDialogSplit>
        <PopupDialogSplit
            v-if="selectedFlowKey"
            is-form
            :prompt-text="promptText"
            :prompt-hint="promptHint"
        >

            <InputGroup :width="9">
                <InputLabel :text="'Name'" :unique-id="'end-name'" />
                <InputTextLocalized
                    v-model="addModel.name"
                    :unique-id="'end-name'"
                    @input="validateDialog"
                />
            </InputGroup>

            <InputGroup :width="3">
                <InputLabel :text="'Order'" :unique-id="'end-order'" />
                <InputText
                    v-model="addModel.order"
                    :unique-id="'end-name'"
                    @input="validateDialog"
                />
            </InputGroup>

            <InputGroup :width="6">
                <InputLabel :text="'Key'" :unique-id="'end-key'" />
                <InputText
                    v-model="addModel.key"
                    :disabled="isEditMode"
                    :unique-id="'end-key'"
                    @input="validateDialog"
                />
            </InputGroup>

            <InputGroup :width="6">
                <InputLabel :text="'Risk Matrix'" :unique-id="'end-matrix'" />
                <InputDropdown
                    v-model="addModel.matrixKey"
                    :items="availableMatrices"
                    :unique-id="'end-key'"
                    @input="validateDialog"
                />
            </InputGroup>

        </PopupDialogSplit>
    </PopupDialog>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

import PopupDialog from "@/ui/PopupDialog.vue";

import { LocalizedString } from "@/lib/common/common";
import { getAvailableWorkflows } from "@/lib/workflows/get_available_workflows";
import { Workflow, WorkflowConfig } from "@/types/flow_types";
import PopupDialogSplit from "@/ui/dialog/PopupDialogSplit.vue";
import { DropdownItem, focusInput } from "@/ui/inputs/input_helper";
import InputDropdown from "@/ui/inputs/InputDropdown.vue";
import InputGroup from "@/ui/inputs/InputGroup.vue";
import InputLabel from "@/ui/inputs/InputLabel.vue";
import InputText from "@/ui/inputs/InputText.vue";
import InputTextLocalized from "@/ui/inputs/InputTextLocalized.vue";
import SimpleListItem from "@/ui/SimpleListItem.vue";

export default defineComponent({
    name: "WorkflowSettingsDialog",
    components: {
        PopupDialog,
        InputGroup,
        InputLabel,
        InputText,
        InputTextLocalized,
        InputDropdown,
        PopupDialogSplit,
        SimpleListItem,
    },
    emits: ["add", "edit", "close-modal"],
    props: {
        config: { type: Object as PropType<WorkflowConfig | null>, default: null },
        existingWorkflows: { type: Array as PropType<WorkflowConfig[]>, required: true },
        availableMatrices: { type: Array as PropType<DropdownItem[]>, required: true },
    },
    data() {
        return {
            isEditMode: false,
            dialog: { valid: false, isWorking: false, statusText: "", isError: false, errorText: "" },
            title: "",
            promptText: "",
            promptHint: "",

            availableFlows: new Array<Workflow>(),
            selectedFlowKey: "",

            addModel: {
                key: "",
                baseKey: "",
                name: {} as LocalizedString,
                order: 1,
                matrixKey: "",
                conditions: {
                    type: "",
                },
            },
        };
    },
    methods: {

        onClose() {
            this.$emit("close-modal");
        },

        validateDialog() {
            let valid = true;
            let errorText = "";

            if (!this.isEditMode) {
                const reservedKeywords = [
                    "default",
                ];
                this.existingWorkflows.forEach((flowConfig) => {
                    if (flowConfig.key === this.addModel.key) {
                        errorText = "A workflow already exists with this key!";
                        valid = false;
                    }
                    reservedKeywords.forEach((keyword) => {
                        if (this.addModel.key.includes(keyword)) {
                            errorText = `The key can not contain the reserved keyword "${keyword}"`;
                            valid = false;
                        }
                    });
                });
            }

            this.dialog.errorText = errorText;
            this.dialog.valid = valid;
            this.dialog.isError = !valid;
        },

        onSave() {
            if (this.isEditMode) {
                this.editFlow();
            } else {
                this.addFlow();
            }
        },

        getConfig() {
            const config: WorkflowConfig = {
                key: this.addModel.key,
                baseKey: this.addModel.baseKey,
                name: this.addModel.name,
                enabled: true,
                order: Number(this.addModel.order),
                conditions: {
                    type: this.addModel.conditions.type,
                    countries: [],
                    flags: [],
                },
                params: {
                    matrixKey: this.addModel.matrixKey,
                },
            };
            return config;
        },

        editFlow() {
            this.$emit("edit", this.getConfig());
            this.onClose();
        },

        addFlow() {
            this.$emit("add", this.getConfig());
            this.onClose();
        },

        async loadAvailableFlows() {
            this.dialog.isError = false;
            this.dialog.isWorking = true;
            this.dialog.statusText = "Loading base flows";

            const response = await getAvailableWorkflows();
            if (response.responseInfo.isError || !response.workflows) {
                this.dialog.isError = true;
                this.dialog.errorText = response.responseInfo.parsedError?.message || "Unknown Error";
            } else {
                this.availableFlows = response.workflows;
            }
            this.dialog.isWorking = false;
        },

        selectFlow(flow: Workflow) {
            this.addModel.key = flow.key.replace("default", "custom");
            this.addModel.baseKey = flow.key;
            this.addModel.name = flow.name;
            this.addModel.matrixKey = flow.params.matrixKey as string || "";

            this.addModel.conditions.type = flow.conditions.type;

            this.validateDialog();
            this.selectedFlowKey = flow.key;
            focusInput("end-name");
        },

    },
    mounted() {
        if (this.config) {
            this.isEditMode = true;
            this.title = "Edit Workflow";
            this.promptText = "Edit workflow:";
            this.promptHint = "hint";
            this.selectedFlowKey = "edit";
            this.addModel.key = this.config.key;
            this.addModel.baseKey = this.config.baseKey;
            this.addModel.name = this.config.name;
            this.addModel.order = this.config.order;
            this.addModel.matrixKey = this.config.params.matrixKey as string || "";
            this.addModel.conditions = this.config.conditions;
        } else {
            this.isEditMode = false;
            this.title = "Create Workflow";
            this.promptText = "Create new workflow:";
            this.promptHint = "hint";
            this.selectedFlowKey = "";
            this.loadAvailableFlows();
        }
    },
});
</script>
