<template>
    <select
        class="fbav3-input-select"
        :id="uniqueId"
        :value="modelValue"
        @input="onInput"
    >

        <option
            v-for="item in items"
            :key="uniqueId + item.value"
            :value="item.value"
        >
            {{ item.text }}
        </option>

    </select>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { DropdownItem } from "./input_helper";

export default defineComponent({
    name: "InputDropdown",
    props: {
        modelValue: { type: String as PropType<string>, required: true },
        uniqueId: { type: String as PropType<string>, required: true },
        items: { type: Array as PropType<DropdownItem[]>, required: true },
    },
    emits: ["update:modelValue"],
    methods: {

        onInput(event: Event) {
            const value = (event.target as HTMLInputElement).value;
            this.$emit("update:modelValue", value);
        },

    },
});
</script>
<style scoped>
.fbav3-input-select {
    width: 100%;
    height: 42px;
    padding: 0px 8px;
    font-size: 14px;
    font-weight: 500;
    color: #444444;
    border-radius: 4px;
    border: solid 1px #D5D5D5;
}
</style>
