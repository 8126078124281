import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "text-right" }
const _hoisted_2 = { class: "px-1 py-1" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuButton = _resolveComponent("MenuButton")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_MenuItem = _resolveComponent("MenuItem")!
  const _component_MenuItems = _resolveComponent("MenuItems")!
  const _component_Menu = _resolveComponent("Menu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Menu, {
      as: "div",
      class: "relative inline-block text-left",
      role: "menu"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createVNode(_component_MenuButton, { class: "inline-flex w-full justify-center rounded-md text-sm font-medium" }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default")
            ]),
            _: 3
          })
        ]),
        _createVNode(_Transition, {
          "enter-active-class": "transition duration-100 ease-out",
          "enter-from-class": "transform scale-95 opacity-0",
          "enter-to-class": "transform scale-100 opacity-100",
          "leave-active-class": "transition duration-75 ease-in",
          "leave-from-class": "transform scale-100 opacity-100",
          "leave-to-class": "transform scale-95 opacity-0"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_MenuItems, {
              as: "ul",
              class: _normalizeClass(["absolute z-10 mt-1 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none", [_ctx.itemsAlign === 'left' ? 'right-0 origin-top-right' : 'left-0 origin-top-left' ]])
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
                    return (_openBlock(), _createBlock(_component_MenuItem, {
                      as: "li",
                      class: "flex w-full items-center p-1 hover:bg-primary-selected",
                      key: '_' + index
                    }, {
                      default: _withCtx(({ active }) => [
                        (option.icon)
                          ? (_openBlock(), _createBlock(_component_Icon, {
                              key: 0,
                              icon: option.icon,
                              class: "mr-2 h-5 w-5"
                            }, null, 8, ["icon"]))
                          : _createCommentVNode("", true),
                        _createElementVNode("button", {
                          class: _normalizeClass([
                                    active ? 'bg-default-selected' : '',
                                    'group flex w-full items-center  rounded-md px-2 py-2 text-sm whitespace-nowrap',
                                ]),
                          onClick: ($event: any) => (_ctx.$emit('menuItemSelected', option))
                        }, _toDisplayString(option.label), 11, _hoisted_3)
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ])
              ]),
              _: 1
            }, 8, ["class"])
          ]),
          _: 1
        })
      ]),
      _: 3
    })
  ]))
}