<template>
    <div>
        <TransitionRoot as="template" :show="sidebarOpen">
            <Dialog class="relative z-50" @close="$emit('closed')">
                <TransitionChild
                    as="template"
                    enter="transition-opacity ease-linear duration-300"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="transition-opacity ease-linear duration-300"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <div class="fixed inset-0 bg-light-gray/60" />
                </TransitionChild>

                <div class="fixed inset-y-0 right-0 flex">
                    <TransitionChild
                        as="template"
                        enter="transition ease-in-out duration-300 transform"
                        enter-from="translate-x-full"
                        enter-to="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leave-from="translate-x-0"
                        leave-to="translate-x-full"
                    >
                        <DialogPanel class="relative flex w-[40rem] max-w-lg flex-1">
                            <!-- Sidebar component, swap this element with another sidebar if you like -->
                            <slot></slot>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </Dialog>
        </TransitionRoot>
    </div>
</template>

<script lang="ts">
import Icon from "@/components/Icon.vue";
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from "@headlessui/vue";

export default {
    name: "Sidebar",
    components: { Icon, Dialog, DialogPanel, TransitionChild, TransitionRoot },
    props: {
        sidebarOpen: Boolean,
    },
    emits: ["closed"],
    data() {
        return {};
    },
    methods: {},
};
</script>
