import { type App } from "vue";
import i18n, { i18localeToVerified } from "./i18n";
import { LocalizedString } from "./lib/common/common";

export default () => ({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
    install(app: App, options?: any) {
        app.config.globalProperties.$xct = (textObject: LocalizedString, localeKey: string) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const anyObject = textObject as any;
            if (!anyObject) return "";
            if (anyObject[localeKey]) return anyObject[localeKey];
            if (anyObject.en_EN) return anyObject.en_EN;
            if (anyObject.value) return anyObject.value;
            if (anyObject.key) return app.config.globalProperties.$t(anyObject.key);
            return "";
        };

        app.config.globalProperties.$ct = (textObject: LocalizedString) => {
            const langKey = i18localeToVerified(i18n.global.locale.value);
            return app.config.globalProperties.$xct(textObject, langKey);
        };
    },

});

declare module "@vue/runtime-core" {
    interface ComponentCustomProperties {
        // eslint-disable-next-line @typescript-eslint/ban-types
        $xct: Function;
    }
    interface ComponentCustomProperties {
        // eslint-disable-next-line @typescript-eslint/ban-types
        $ct: Function;
    }
}
