<template>
    <TransitionRoot as="template" :show="true">
        <Dialog class="relative z-10" @close="$emit('close-modal')">
            <TransitionChild
                as="template"
                enter="ease-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in duration-200"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <div class="fixed inset-0 z-10">
                <TransitionChild
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100 translate-y-0 sm:scale-100"
                    leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <DialogPanel
                        class="amlv3-pdfpopup transition-all"
                    >

                        <PdfViewer
                            v-if="base64"
                            :file="base64"
                            @close="$emit('close-modal')"
                        />

                    </DialogPanel>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>
<script lang="ts">
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { PropType, defineComponent } from "vue";
import { getFile, PdfViewFile, PdfViewParams } from "./pdf_view_helper";
import PdfViewer from "./PdfViewer.vue";

export default defineComponent({
    name: "PdfViewPopup",
    components: {
        Dialog,
        DialogPanel,
        TransitionChild,
        TransitionRoot,
        PdfViewer,
    },
    emits: ["close-modal"],
    props: {
        params: { type: Object as PropType<PdfViewParams>, required: true },
    },
    data() {
        return {
            base64: null as Blob | null,
        };
    },
    methods: {

        async getFile(file: PdfViewFile) {
            console.log("GET FILE", file);

            this.base64 = await getFile(file);

            console.log("b64", this.base64);
        },

    },
    mounted() {
        console.log("viewParams", this.params);

        if (this.params.initialId) {
            for (const file of this.params.files) {
                if (file.id === this.params.initialId) {
                    this.getFile(file);
                }
                for (const attachment of file.attachments) {
                    if (attachment.id === this.params.initialId) {
                        this.getFile(attachment);
                    }
                }
            }
        } else {
            this.getFile(this.params.files[0]);
        }
    },
});
</script>
<style scoped>

.amlv3-pdfpopup {
    height: 100vh;
    overflow: auto;
    /* background-color: yellow; */
}

</style>
