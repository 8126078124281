<template>
    <PopupDialog
        :is-visible="true"
        :header="'Risk Matrix'"
        :icon="'wand-magic-sparkles'"
        :show-cancel-button="true"
        :show-action-button="true"
        :action-text="'Save'"
        :action-icon="'floppy-disk'"
        :is-action-disabled="!dialog.valid"
        @close-modal="onClose"
        @action="onSave"
    >
        <PopupDialogSplit
            is-form
            :prompt-text="promptText"
            :prompt-hint="promptHint"
        >

            <InputGroup :width="12">
                <InputLabel
                    :text="'Name'"
                    :unique-id="'end-name'"
                    required
                />
                <InputTextLocalized
                    v-model="model.name"
                    :unique-id="'end-name'"
                    @input="validateDialog"
                    @enter="onSave"
                />
            </InputGroup>

            <InputGroup :width="6">
                <InputLabel
                    :text="'Key'"
                    :unique-id="'end-key'"
                    required
                />
                <InputText
                    v-model="model.key"
                    :unique-id="'end-key'"
                    :disabled="isEditMode"
                    @input="validateDialog"
                />
            </InputGroup>

            <InputGroup :width="6">
                <InputLabel
                    :text="'Risk Configuration'"
                    :unique-id="'end-config'"
                    required
                />
                <InputDropdown
                    v-model="model.configKey"
                    :items="configs"
                    :disabled="isEditMode"
                    :unique-id="'end-config'"
                    @input="validateDialog"
                />
            </InputGroup>

        </PopupDialogSplit>
    </PopupDialog>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

import PopupDialog from "@/ui/PopupDialog.vue";

import { LocalizedString, LocalizedStringValidation, validateLocalizedString } from "@/lib/common/common";
import { createRiskMatrix, CreateRiskMatrixOptions } from "@/lib/risk/create_matrix";
import { RiskMatrixDefinition } from "@/lib/risk/risk_matrix";
import { updateRiskMatrix, UpdateRiskMatrixOptions } from "@/lib/risk/update_matrix";
import PopupDialogSplit from "@/ui/dialog/PopupDialogSplit.vue";
import { DropdownItem } from "@/ui/inputs/input_helper";
import InputDropdown from "@/ui/inputs/InputDropdown.vue";
import InputGroup from "@/ui/inputs/InputGroup.vue";
import InputLabel from "@/ui/inputs/InputLabel.vue";
import InputText from "@/ui/inputs/InputText.vue";
import InputTextLocalized from "@/ui/inputs/InputTextLocalized.vue";

export default defineComponent({
    name: "RiskSettingsMatrixDialog",
    components: {
        PopupDialog,
        InputGroup,
        InputLabel,
        InputText,
        InputTextLocalized,
        InputDropdown,
        PopupDialogSplit,
    },
    emits: ["reload", "close-modal"],
    props: {
        matrix: { type: Object as PropType<RiskMatrixDefinition | null>, default: null },
    },
    data() {
        return {
            isEditMode: false,
            dialog: { valid: false, isWorking: false, statusText: "", isError: false, errorText: "" },
            model: {
                key: "",
                name: {} as LocalizedString,
                configKey: "",
            },
            configs: new Array<DropdownItem>(),
            promptText: "",
            promptHint: "",
        };
    },
    methods: {

        validateDialog() {
            let valid = true;

            if (!this.model.key) {
                valid = false;
            }
            if (!validateLocalizedString(this.model.name, LocalizedStringValidation.CurrentOnly)) {
                valid = false;
            }
            if (!this.model.configKey) {
                valid = false;
            }

            this.dialog.valid = valid;
        },

        onSave() {
            if (this.isEditMode) {
                this.updateRiskMatrix();
            } else {
                this.createRiskMatrix();
            }
        },

        async updateRiskMatrix() {
            if (!this.matrix) return;
            if (!this.dialog.valid) return;

            this.dialog.isWorking = true;
            this.dialog.statusText = "Updating risk matrix";

            const options: UpdateRiskMatrixOptions = {
                name: this.model.name,
            };
            const response = await updateRiskMatrix(this.matrix.key, options);

            if (response.responseInfo.isError) {
                this.dialog.isError = true;
                this.dialog.errorText = response.responseInfo.parsedError?.message || "Unknown error";
            } else {
                this.$emit("reload");
                this.onClose();
            }
            this.dialog.isWorking = false;
        },

        async createRiskMatrix() {
            if (!this.dialog.valid) return;

            this.dialog.isWorking = true;
            this.dialog.statusText = "Creating risk matrix";

            const options: CreateRiskMatrixOptions = {
                key: this.model.key,
                name: this.model.name,
                configKey: this.model.configKey,
            };
            const response = await createRiskMatrix(options);

            if (response.responseInfo.isError) {
                this.dialog.isError = true;
                this.dialog.errorText = response.responseInfo.parsedError?.message || "Unknown error";
            } else {
                this.$emit("reload");
                this.onClose();
            }
            this.dialog.isWorking = false;
        },

        onClose() {
            this.$emit("close-modal");
        },

    },
    mounted() {
        this.$config.settings.riskConfigs?.forEach((config) => {
            this.configs.push({
                value: config.key,
                text: config.name,
            });
        });
        if (this.matrix) {
            this.model.key = this.matrix.key;
            this.model.name = this.matrix.name;
            this.model.configKey = this.matrix.configKey;
            this.promptText = "Edit Risk Matrix:";
            this.promptHint = "Change the name of the risk matrix.";
            this.isEditMode = true;
        } else {
            this.promptText = "Create a new Risk Matrix:";
            this.promptHint = "A new risk matrix will be created.";
            this.isEditMode = false;
        }
    },
});
</script>
