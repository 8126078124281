export const formatDateObject = (dateInput: any) => {
    if (!dateInput) return "none";

    const { year, month, day } = dateInput;

    if (year && month && day) {
        return new Intl.DateTimeFormat("en", { year: "numeric", month: "long", day: "numeric" }).format(
            new Date(year, month - 1, day),
        );
    } else if (year && month) {
        return new Intl.DateTimeFormat("en", { year: "numeric", month: "long" }).format(new Date(year, month - 1));
    } else if (year) {
        return year.toString();
    }

    return "none";
};
