import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 py-6" }
const _hoisted_2 = { class: "flex justify-between mb-5" }
const _hoisted_3 = { class: "text-3xl" }
const _hoisted_4 = { class: "flex flex-col gap-y-5" }
const _hoisted_5 = {
  key: 0,
  class: "p-0 space-y-3"
}
const _hoisted_6 = ["value", "onUpdate:modelValue", "onInput"]
const _hoisted_7 = {
  key: 1,
  class: "space-y-3 bg-background rounded-lg p-4"
}
const _hoisted_8 = { class: "w-full relative" }
const _hoisted_9 = { class: "block" }
const _hoisted_10 = { class: "absolute right-1 top-0" }
const _hoisted_11 = { class: "space-y-2" }
const _hoisted_12 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_InputLabel = _resolveComponent("InputLabel")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_CustomButton = _resolveComponent("CustomButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('common.person_info.email')), 1),
      _createVNode(_component_Icon, {
        class: "h-10 w-10 hover:bg-background p-3 cursor-pointer rounded-full",
        icon: "cross",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closed'))),
        "aria-hidden": "true"
      })
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.emailList && _ctx.emailList.length > 0)
        ? (_openBlock(), _createElementBlock("ul", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.emailList, (email, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: index,
                class: "flex gap-x-2 items-center min-h-[6rem] border border-light border-solid p-4 rounded-lg hover:bg-primary-hover-blue"
              }, [
                _createElementVNode("div", null, [
                  _withDirectives(_createElementVNode("input", {
                    name: "email",
                    type: "radio",
                    value: email.value,
                    "onUpdate:modelValue": ($event: any) => ((_ctx.person.data.person_info.email) = $event),
                    class: "h-6 w-6 border-light",
                    onInput: ($event: any) => (_ctx.onChange(email.value))
                  }, null, 40, _hoisted_6), [
                    [_vModelRadio, _ctx.person.data.person_info.email]
                  ])
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("div", null, [
                    _createElementVNode("label", null, _toDisplayString(email.value), 1),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(email.sources, (src) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "text-sm text-light-gray flex gap-2",
                        key: src.source
                      }, [
                        _createVNode(_component_Icon, {
                          class: "w-4 h-4",
                          icon: `aml:src-${src.source.toLowerCase()}`
                        }, null, 8, ["icon"]),
                        _createVNode(_component_Icon, {
                          icon: "dot-single",
                          "aria-hidden": "true"
                        }),
                        _createElementVNode("span", null, _toDisplayString(_ctx.$d(new Date(src.updatedAt), "long")), 1)
                      ]))
                    }), 128))
                  ])
                ])
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      (_ctx.addEmail)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.addOrUpdateText), 1),
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_Icon, {
                  icon: "cross",
                  class: "w-3 h-3 cursor-pointer",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.addEmail = false)),
                  "aria-hidden": "true"
                })
              ])
            ]),
            _createVNode(_component_Icon, {
              icon: "horizontal-line",
              class: "",
              "aria-hidden": "true"
            }),
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_InputLabel, {
                "unique-id": "person-email",
                text: _ctx.$t('common.person_info.email'),
                class: "block"
              }, null, 8, ["text"]),
              _createVNode(_component_InputText, {
                modelValue: _ctx.newEmail,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newEmail) = $event)),
                "unique-id": "addEmail"
              }, null, 8, ["modelValue"])
            ]),
            _createVNode(_component_CustomButton, {
              text: _ctx.addOrUpdateText,
              class: "border border-primary-default font-medium text-base box-border ml-auto px-2",
              "text-color": "primary-default",
              "round-style": "3xl",
              "bg-color": "white",
              onClick: _ctx.addNewEmail
            }, null, 8, ["text", "onClick"])
          ]))
        : _createCommentVNode("", true),
      (!_ctx.addEmail)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _createElementVNode("a", {
              class: "text-primary-default",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.addEmail = true))
            }, _toDisplayString(_ctx.addOrUpdateText), 1)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}