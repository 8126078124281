<template>
    <div>

        <div v-if="!selectedTemplateId">

            <SimpleListItem
                v-for="(template, i) in templates"
                :key="'aft' + i"
                :text="$ct(template.name)"
                :sub-text="template.slug + '/' + template.key"
                :is-first="i === 0"
                :is-last="i === templates.length - 1"
                @clicked="selectTemplate(template)"
            />

        </div>

        <div v-if="loadingTemplate">
            <LoadAnimationBar />
            <LoadAnimationBar />
            <LoadAnimationBar />
        </div>

        <RiskSourceItem
            v-for="(item, i) in items"
            :key="'rsi' + i"
            :item="item"
            :is-selected="item.key === selectedKey"
            :is-first="i === 0"
            :is-last="i === items.length - 1"
            :value-type="valueType"
            @clicked="selectItem(item.key)"
        />

    </div>
</template>
<script lang="ts">
import { RiskIndicatorDefinition, RiskValue, RiskValueMode, RiskValueSource, RiskValueType } from "@/lib/risk/risk_matrix";
import { defineComponent, type PropType } from "vue";

import { FormTemplate } from "@/lib/forms/forms";
import { getPossibleInputs } from "@/lib/forms/get_possible_inputs";
import LoadAnimationBar from "@/ui/LoadAnimationBar.vue";
import SimpleListItem from "@/ui/SimpleListItem.vue";
import RiskSourceItem from "./RiskSourceItem.vue";
import { RiskSourceKeyItem } from "./risk_sources";
import { getRandomString } from "../risk_helper";

export default defineComponent({
    name: "RiskSourceFormWizard",
    components: {
        RiskSourceItem,
        SimpleListItem,
        LoadAnimationBar,
    },
    emits: ["selected"],
    props: {
        valueType: { type: String as PropType<RiskValueType>, default: "" },
    },
    data() {
        return {
            templates: new Array<FormTemplate>(),
            items: new Array<RiskSourceKeyItem>(),
            selectedKey: "",
            selectedTemplateId: "",
            selectedTemplateKey: "",
            loadingTemplate: false,
        };
    },
    methods: {

        selectTemplate(template: FormTemplate) {
            this.selectedTemplateId = template.id;
            this.selectedTemplateKey = template.key;
            this.loadTemplate();
        },

        async loadTemplate() {
            this.loadingTemplate = true;

            const response = await getPossibleInputs(this.selectedTemplateId);
            if (response.responseInfo.isError || !response.possibleInputs) {
                const errorText = response.responseInfo.parsedError?.message || "Unknown error";
                console.error(errorText);
                // todo: error handling
            } else {
                console.log("response.possibleInputs", response.possibleInputs);

                response.possibleInputs.forEach((source) => {
                    if (source.format === "check") {
                        const item: RiskSourceKeyItem = {
                            key: source.modelPath,
                            type: RiskValueType.MultipleChoice,
                            name: source.text.en_EN || "",
                            text: source.text,
                            hint: source.modelPath,
                            values: [],
                        };

                        source.values.forEach((sourceValue) => {
                            const value: RiskValue = {
                                key: sourceValue.modelKey,
                                mode: RiskValueMode.Option,
                                name: sourceValue.text,
                            };
                            item.values?.push(value);
                        });

                        this.items.push(item);
                    }
                    if (source.format === "yesno") {
                        const item: RiskSourceKeyItem = {
                            key: source.modelPath,
                            type: RiskValueType.YesNo,
                            name: source.text.en_EN || "",
                            text: source.text,
                            hint: source.modelPath,
                        };
                        this.items.push(item);
                    }
                    if (source.format === "string") {
                        const item: RiskSourceKeyItem = {
                            key: source.modelPath,
                            type: RiskValueType.FreeText,
                            name: source.text.en_EN || "",
                            text: source.text,
                            hint: source.modelPath,
                        };
                        this.items.push(item);
                    }
                    if (source.format === "number") {
                        const item: RiskSourceKeyItem = {
                            key: source.modelPath,
                            type: RiskValueType.Number,
                            name: source.text.en_EN || "",
                            text: source.text,
                            hint: source.modelPath,
                        };
                        this.items.push(item);
                    }
                    if (source.format === "country") {
                        const item: RiskSourceKeyItem = {
                            key: source.modelPath,
                            type: RiskValueType.Country,
                            name: source.text.en_EN || "",
                            text: source.text,
                            hint: source.modelPath,
                        };
                        this.items.push(item);
                    }
                });
            }
            this.loadingTemplate = false;
        },

        selectItem(key: string) {
            this.selectedKey = (key === this.selectedKey) ? "" : key;
            this.$emit("selected", this.getIndicator());
        },

        getIndicator() {
            let sourceItem: RiskSourceKeyItem | null = null;
            this.items.forEach((item) => {
                if (item.key === this.selectedKey) sourceItem = item;
            });
            if (!sourceItem) return;
            const source = sourceItem as RiskSourceKeyItem;

            const randomKey = getRandomString(8);
            const indicator: RiskIndicatorDefinition = {
                key: randomKey,
                name: source.text,
                weight: 100,
                hrm: 0,
                lrm: 0,
                valueType: source.type,
                sources: [
                    {
                        source: RiskValueSource.Form,
                        formKey: this.selectedTemplateKey,
                        key: source.key,
                    },
                ],
                values: [],
                isFixed: true,
                logic: [],
                scores: {},
                unknownScore: 9,
                hints: {},
                params: {},
            };

            if (source.type === RiskValueType.YesNo) {
                indicator.values.push({
                    key: "yes",
                    mode: RiskValueMode.Option,
                    name: { en_EN: "Yes" },
                });
                indicator.values.push({
                    key: "no",
                    mode: RiskValueMode.Option,
                    name: { en_EN: "No" },
                });
                indicator.scores.yes = 5;
                indicator.scores.no = 5;
            }

            if (source.type === RiskValueType.MultipleChoice) {
                if (source.values) {
                    source.values.forEach((value) => {
                        indicator.values.push(value);
                        indicator.scores[value.key] = 5;
                    });
                }
            }

            console.log("returning", indicator);
            return indicator;
        },

    },
    mounted() {
        this.templates = this.$config.settings.formTemplates || [];
    },
});
</script>
