<template>
    <div class="flex flex-col gap-4 pt-10">
        <div class="flex gap-4 items-center bg-white py-2 px-4 border border-muted h-20 w-1/2 shadow-sm">
            <Icon icon="plus-round" class="h-14 w-14"></Icon>
            <div class="text-lg">
                <p class="">ADD Partner</p>
                <p class="text-sm">Add a partner to Organizational Structure</p>
            </div>
        </div>
        <PartnerCard v-for="partner in partners" :partner="partner" :key="partner.key" />
    </div>
</template>
<script lang="ts">
import { Partner } from "@/lib/common/companyPartner";
import { defineComponent } from "vue";
import PartnerCard from "./PartnerCard.vue";

export default defineComponent({
    name: "Partners",
    components: {
        PartnerCard,
    },
    data() {
        return {
            partners: [
                {
                    name: "Partner 1",
                    employee: 10,
                    email: "partner1@partner.com",
                },
                {
                    name: "Partner 2",
                    employee: 10,
                    email: "partner2@partner.com",
                },
                {
                    name: "Partner 3",
                    employee: 10,
                    email: "partner3@partner.com",
                },
            ] as Partner[],
        };
    },
});
</script>
