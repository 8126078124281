<template>
    <div class="font-roboto box-border">
        <div class="flex gap-10">
            <div aria-describedby="ID and screening cards" class="basis-[70%] mt-5 space-y-5">
                <div aria-describedby="ID screning header" class="flex gap-2 items-center">
                    <label class="font-medium text-xl m-0">{{ $t('common.keyRoles') }} : {{ $t('common.idcontrol&screening') }}</label>
                    <!-- <Icon class="w-5 h-5" icon="faq" /> -->
                </div>
                <!-- <div class="flex gap-3 justify-start bg-primary-selected py-1.5 px-5">
                    <input
                        type="checkbox"
                        id="selction-checkbox"
                        v-model="selectAll"
                        @change="toggleSelectAll"
                    />
                    <DropdownMenu :options="menuOptions" @menu-item-selected="executeActionForSelected">
                        <FontIcon icon="angle-down" :size="14" />
                    </DropdownMenu>
                    <div v-if="errorMsg" class=" bg-danger-default text-white text-base px-2 rounded-md relative">
                        <span>{{ errorMsg }}</span>
                        <Icon
                            icon="cross"
                            class="absolute right-[-5px] top-[-5px] w-3 h-3 p-0.5 bg-white rounded-full cursor-pointer"
                            @click="errorMsg = ''"
                        />
                    </div>
                </div> -->
                <!-- <div
                    v-for="person in keyRolePersons"
                    :key="person.id"
                    aria-describedby="ID and screening card"
                    class=" min-h-60 rounded-lg flex flex-col gap-x-4 shadow"
                    :class="[person.selected ? 'border border-primary-default bg-primary-selected' : ' bg-white']"
                >
                    <div aria-describedby="ID and screening card header" class="flex h-[30%] py-3 px-5 justify-between">
                        <div class="flex gap-2 items-center">
                            <input
                                type="checkbox"
                                id="selction-checkbox"
                                :value="person.selected"
                                :checked="person.selected"
                                @change="onPersonSelectChange($event, person)"
                            />
                            <Icon class="w-10 h-10 p-2" icon="person-blue" />
                            <div>
                                <a @click="$router.push({ path: `/aml/persons/${person.id}` })">{{ person.data.person_info.givenName }} {{ person.data.person_info.familyName }}</a>
                            </div>
                            <div class="py-1 px-2 border border-light rounded-md text-sm">
                                {{ "DOB:" }}
                                {{
                                    formatDate(person.data.person_info.dateOfBirth)
                                }}
                            </div>

                        </div>
                        <div class="flex pl-3 items-center">
                            <span
                                v-for="role in person.roles"
                                :key="role.key"
                                class="border-light border rounded-3xl px-2.5 py-1.5 text-sm"
                            >{{
                                $t(role.key)
                            }}</span>
                            <Icon class="w-6 h-6" icon="info-transparent" />
                        </div>
                    </div>

                    <div class="pl-5 pb-5 pr-5 flex flex-grow gap-5">
                        <IdControl :person="person" @refresh-persons="getPersonsWithRole" />
                        <Screening :person="person" @refresh-persons="getPersonsWithRole" />
                    </div>
                </div> -->

                <PersonCardLayout
                    v-for="person in keyRolePersons"
                    :key="'pc' + person.id"
                    :selected="person.selected"
                >
                    <!-- <template #check>
                        <input
                            type="checkbox"
                            id="selction-checkbox"
                            :value="person.selected"
                            :checked="person.selected"
                            @change="onPersonSelectChange($event, person)"
                        />
                    </template> -->
                    <template #top>
                        <PersonCardTopInfo
                            :person="person"
                            :selected="person.selected"
                            @selected-changed="onPersonSelectChange($event, person)"
                        />
                    </template>
                    <template #controls>
                        <IdControl
                            :person="person"
                            @refresh-persons="getPersonsWithRole"
                        />
                        <Screening
                            :person="person"
                            @refresh-persons="getPersonsWithRole"
                        />
                    </template>
                </PersonCardLayout>

            </div>

            <div class="basis-[30%] mt-5 space-y-5">
                <div aria-describedby="ID screning header" class="flex gap-2 items-center">
                    <label class="font-medium text-xl m-0">{{ $t('project.tabs.persons.roles.header') }}</label>
                    <!-- <Icon class="w-5 h-5" icon="faq" /> -->
                    <CustomButton
                        class="ml-auto"
                        :text="$t('project.tabs.persons.roles.addPerson')"
                        icon="plus-round-no-fill"
                        text-color="primary-default"
                        round-style="full"
                        bg-color="transparent"
                        border-color="primary-default"
                        :on-click="() => (openAddPersonModal = true)"
                    />
                </div>
                <div class="">
                    <p>
                        {{ $t('project.tabs.persons.roles.description') }}
                    </p>
                </div>
                <div class="bg-light h-[1px] w-full"></div>
                <template
                    v-for="role in roleOrder"
                    :key="role"
                >
                    <div
                        v-if="
                            PersonsByRoles &&
                                PersonsByRoles?.filter((p) => {
                                    return p?.roles.some((r) => r.key === role || r.name === role);
                                })?.length > 0
                        "
                        class="w-full space-y-3"
                    >
                        <label class="">{{ $t("core.roles." + role) }}</label>
                        <div
                            v-for="person in PersonsByRoles?.filter((p) => {
                                return p?.roles.some((r) => r.key === role || r.name === role);
                            })"
                            :key="person.id"
                            class="flex gap-2 items-center py-2 px-3 bg-white rounded-md"
                        >
                            <SwitchInput v-model="person.isKeyRole" @update:model-value="(value: boolean) => switchChanged(value, person.id)" />
                            <span>{{ person.data.person_info.givenName }} {{ person.data.person_info.familyName }}</span>
                            <!-- <div class="ml-auto flex gap-2">
                                <Icon
                                    class="w-4 h-4"
                                    icon="delete"
                                    @click="toggleDeletePersonModal(person)"
                                />
                                <Icon class="w-4 h-4" icon="info-transparent" />
                            </div> -->
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
    <AddPersonPopup
        v-if="openAddPersonModal"
        @refresh-persons="getPersonsWithRole"
        @close-modal="() => (openAddPersonModal = false)"
    />
    <DeletePersonPopup
        :person="selectedPerson"
        v-if="openDeletePersonModal"
        @refresh-persons="getPersonsWithRole"
        @delete-person-modal-closed="() => (openDeletePersonModal = false)"
    />
</template>

<script setup lang="ts">
import CustomButton from "@/components/CustomButton.vue";
import Screening from "@/components/customer/Screening.vue";
import Icon from "@/components/Icon.vue";
import { PersonWithRole } from "@/lib/persons/person";
import { queryPersonWithRoleForCompany } from "@/lib/persons/query_persons";
import type { Ref } from "vue";
import { computed, onMounted, ref, watch } from "vue";
import AddPersonPopup from "@/components/customer/AddPersonPopup.vue";
import DeletePersonPopup from "@/components/customer/DeletePersonPopup.vue";
import IdControl from "@/components/customer/IdControl.vue";
import SwitchInput from "@/components/SwitchInput.vue";
import { updateAsKeyRole } from "@/lib/role/update_role";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { DropdownMenuOption } from "@/lib/common/MenuOption";
import { RoleCode } from "@/lib/role/Role";
import { formatDateObject } from "@/lib/date_object_formatter";
import PersonCardLayout from "@/components/person/PersonCardLayout.vue";
import PersonCardTopInfo from "@/components/person/PersonCardTopInfo.vue";
import { getRoleSortOrder } from "@/components/person/person_display_helper";

const { t } = useI18n();

const route = useRoute();

type keyRolePerson = PersonWithRole & {
    selected: boolean
};

const menuOptions = [
    {
        label: t("project.tabs.persons.menuOptions.selectAll"),
        value: "select",
        icon: "selected-checkbox",
    },
    {
        label: t("project.tabs.persons.menuOptions.deselectAll"),
        value: "deselect",
        icon: "unselected-checkbox",
    },
    {
        label: t("project.tabs.persons.menuOptions.addScreenMonitor"),
        value: "addmonitor",
        icon: "screening-add",
    },
    {
        label: t("project.tabs.persons.menuOptions.removeScreenMonitor"),
        value: "removemonitor",
        icon: "screening-remove",
    },
    {
        label: t("project.tabs.persons.menuOptions.removeKeyRoles"),
        value: "removekeyroles",
        icon: "key",
    },
] as DropdownMenuOption[];
const personsWithRole: Ref<PersonWithRole[] | null> = ref(null);
const selectedPerson: Ref<PersonWithRole | null> = ref(null);
const openAddPersonModal = ref(false);
const openDeletePersonModal = ref(false);
const selectAll = ref(false);
const toggleDeletePersonModal = (person: PersonWithRole) => {
    selectedPerson.value = person;
    openDeletePersonModal.value = !openDeletePersonModal.value;
};
let roleOrder: string[] = [];

const errorMsg = ref("");

const selected = ref<string[]>([]); // Array to store selected person IDs

watch(selected, (newValue, oldValue) => {
    if (newValue.length > 0) {
        errorMsg.value = "";
    }
}, { deep: true });

const onPersonSelectChange = (event: Event, person: keyRolePerson) => {
    // Use the event target to get the checked state
    const isChecked = (event.target as HTMLInputElement).checked;

    // Find the index of the person in the keyRolePersons array
    const personIndex = keyRolePersons.value.findIndex(p => p.id === person.id);

    if (personIndex !== -1) {
        // Create a new object with the updated 'selected' property
        const updatedPerson = { ...keyRolePersons.value[personIndex], selected: isChecked };

        // Use Vue.set to update the person object within the array
        keyRolePersons.value.splice(personIndex, 1, updatedPerson);
    }

    // Update the 'selected' array based on the checkbox state
    if (isChecked) {
        selected.value.push(person.id);
    } else {
        selected.value = selected.value.filter((id) => id !== person.id);
    }
};

const toggleSelectAll = () => {
    if (selectAll.value) {
        selected.value = keyRolePersons.value.map((person) => person.id);
        keyRolePersons.value.forEach((person) => (person.selected = true));
    } else {
        selected.value = [];
        keyRolePersons.value.forEach((person) => (person.selected = false));
    }
};

// Example function to execute an action for all selected persons
// const executeActionForSelected = async(option: DropdownMenuOption) => {
//     // Add your logic here to use the 'selected' array

//     switch (option.value) {
//     case "select":
//         selectAll.value = true;
//         toggleSelectAll();
//         break;
//     case "deselect":
//         selectAll.value = false;
//         toggleSelectAll();
//         break;
//     case "addmonitor":
//         if (selected.value && selected.value.length > 0) {
//             try {
//                 await Promise.all(selected.value.map((personID) => updateMonitoringStatus(true, personID)));
//             } catch (e) {
//                 console.log(e);
//                 errorMsg.value = "Something went wrong. Please try again.";
//             }
//             selectAll.value = false;
//             toggleSelectAll();
//             await getPersonsWithRole();
//         } else {
//             errorMsg.value = "No person selected";
//         }
//         break;
//     case "removemonitor":
//         if (selected.value && selected.value.length > 0) {
//             try {
//                 await Promise.all(selected.value.map((personID) => updateMonitoringStatus(false, personID)));
//             } catch (e) {
//                 console.log(e);
//                 errorMsg.value = "Something went wrong. Please try again.";
//             }
//             selectAll.value = false;
//             toggleSelectAll();
//             await getPersonsWithRole();
//         } else {
//             errorMsg.value = "No person selected";
//         }
//         break;
//     case "removekeyroles":
//         if (selected.value && selected.value.length > 0) {
//             try {
//                 await Promise.all(selected.value.map((personID) => updateAsKeyRole(route.params.id as string, personID, false)));
//             } catch (e) {
//                 console.log(e);
//                 errorMsg.value = "Something went wrong. Please try again.";
//             }
//             selectAll.value = false;
//             toggleSelectAll();
//             await getPersonsWithRole();
//         } else {
//             errorMsg.value = "No person selected";
//         }
//         break;
//     }
// };

const switchChanged = async (event: boolean, personID: string) => {
    const projectID = route.params.id as string;
    const updated = await updateAsKeyRole(projectID, personID, event);
    await getPersonsWithRole();
};

const getPersonsWithRole = async () => {
    const projectID = route.params.id as string;
    const persons = await queryPersonWithRoleForCompany(projectID);
    personsWithRole.value = persons;
};

const getUniqueRoles = (): string[] => {
    const roleList = new Set<string>();

    personsWithRole.value?.forEach((p) => {
        p.roles.forEach((r) => {
            roleList.add(r.name ?? r.key);
        });
    });

    return Array.from(roleList);
};

const none = computed(() => {
    return t("project.tabs.company.blocks.general.valueUndefined");
});
const formatDate = (dateInput: any) => {
    return formatDateObject(dateInput);
};
const PersonsByRoles = computed(() => {
    if (!personsWithRole.value || !roleOrder.length) {
        return;
    }

    const withRoles = personsWithRole.value.slice().sort((a, b) => {
        // Find the index of the first role in each person's roles array based on the roleOrder array
        const roleIndexA = roleOrder.findIndex((roleName) => a.roles.some((role) => role.key === roleName || role.name === roleName));
        const roleIndexB = roleOrder.findIndex((roleName) => b.roles.some((role) => role.key === roleName || role.name === roleName));

        // If both roles are found, compare their indices in the roleOrder array
        if (roleIndexA !== -1 && roleIndexB !== -1) {
            return roleIndexA - roleIndexB;
        }

        // If one role is not found, prioritize the person with the found role
        if (roleIndexA !== -1) {
            return -1;
        }
        if (roleIndexB !== -1) {
            return 1;
        }

        // If both roles are not found, sort alphabetically by person's name
        if (roleIndexA === -1 && roleIndexB === -1 && a.data?.person_info?.givenName && b.data?.person_info?.givenName) {
            return a.data?.person_info?.givenName.localeCompare(b.data?.person_info?.givenName);
        } else { // Add this else block to ensure correct comparison in other cases
            return roleIndexA - roleIndexB;
        }
    });
    return withRoles;
});

const keyRolePersons = computed(() => {
    return PersonsByRoles.value?.filter((p) => p.isKeyRole).map(p => {
        return { ...p, selected: selected.value.includes(p.id) || false };
    }) ?? [];
});

onMounted(async () => {
    const roles = Object.values(RoleCode);
    roles.sort((a, b) => (getRoleSortOrder(a) > getRoleSortOrder(b)) ? 1 : -1);
    roleOrder = roles;
    await getPersonsWithRole();
});
</script>
