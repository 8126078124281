<template>
    <div class="riskv3-comcom" @click="$emit('clicked')">

        {{ comment }}

    </div>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";

import { RiskCommentDefinition } from "@/lib/risk/risk_matrix";

export default defineComponent({
    name: "RiskCommentComponent",
    components: {

    },
    emits: ["clicked"],
    props: {
        comment: { type: Object as PropType<RiskCommentDefinition>, required: true },
    },
});
</script>
<style scoped>
.riskv3-comcom {

}

</style>
