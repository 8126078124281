import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-291f1c8c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "riskv3-mc" }
const _hoisted_2 = { class: "riskv3-mc-lrm" }
const _hoisted_3 = { class: "riskv3-mc-steps riskv3-mc-steps-lrm" }
const _hoisted_4 = { class: "riskv3-mc-btn" }
const _hoisted_5 = { class: "riskv3-mc-hrm" }
const _hoisted_6 = { class: "riskv3-mc-steps" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontIcon = _resolveComponent("FontIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "riskv3-mc-btn riskv3-mc-btn-first riskv3-mc-nrb",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onChangeLRM(1)))
      }, [
        _createVNode(_component_FontIcon, {
          icon: 'plus',
          size: 18
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lrmSteps, (isColored, i) => {
          return (_openBlock(), _createElementBlock("div", {
            key: 'mchs' + i,
            class: _normalizeClass(["riskv3-mc-step", [
                        { 'riskv3-mc-step-lrm': isColored },
                    ]])
          }, null, 2))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_FontIcon, {
          icon: 'minus',
          size: 18,
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onChangeLRM(-1)))
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", {
        class: "riskv3-mc-btn riskv3-mc-nrb",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onChangeHRM(-1)))
      }, [
        _createVNode(_component_FontIcon, {
          icon: 'minus',
          size: 18
        })
      ]),
      _createElementVNode("div", _hoisted_6, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hrmSteps, (isColored, i) => {
          return (_openBlock(), _createElementBlock("div", {
            key: 'mchs' + i,
            class: _normalizeClass(["riskv3-mc-step", [
                        { 'riskv3-mc-step-hrm': isColored },
                    ]])
          }, null, 2))
        }), 128))
      ]),
      _createElementVNode("div", {
        class: "riskv3-mc-btn riskv3-mc-btn-last",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onChangeHRM(1)))
      }, [
        _createVNode(_component_FontIcon, {
          icon: 'plus',
          size: 18
        })
      ])
    ])
  ]))
}