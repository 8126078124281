<template>
    <div>

        <InputRow>
            <InputGroup :width="6">
                <InputDropdown
                    v-model="roleSourceModel"
                    :items="roleSourceOptions"
                    :unique-id="'role-source'"
                />
            </InputGroup>

            <InputGroup :width="6">
                <InputDropdown
                    v-model="roleTypeModel"
                    :items="roleTypeOptions"
                    :unique-id="'role-type'"
                />
            </InputGroup>
        </InputRow>

        <RiskSourceItem
            v-for="(item, i) in items"
            :key="'rsi' + i"
            :item="item"
            :is-selected="item.key === selectedKey"
            :is-first="i === 0"
            :is-last="i === items.length - 1"
            :value-type="valueType"
            @clicked="selectItem(item.key)"
        />

    </div>
</template>
<script lang="ts">
import { RiskIndicatorDefinition, RiskValueSource, RiskValueType } from "@/lib/risk/risk_matrix";
import { defineComponent, type PropType } from "vue";

import RiskSourceItem from "./RiskSourceItem.vue";
import { RiskSourceKeyItem, getRoleSources } from "./risk_sources";
import InputDropdown from "@/ui/inputs/InputDropdown.vue";
import InputGroup from "@/ui/inputs/InputGroup.vue";
import InputRow from "@/ui/inputs/InputRow.vue";
import { DropdownItem } from "@/ui/inputs/input_helper";
import { getRandomString } from "../risk_helper";

export default defineComponent({
    name: "RiskSourceRoleWizard",
    components: {
        RiskSourceItem,
        InputDropdown,
        InputGroup,
        InputRow,
    },
    emits: ["selected"],
    props: {
        valueType: { type: String as PropType<RiskValueType>, default: "" },
    },
    data() {
        return {
            roleSourceModel: "key",
            roleSourceOptions: new Array<DropdownItem>(),
            roleTypeModel: "any",
            roleTypeOptions: new Array<DropdownItem>(),
            items: new Array<RiskSourceKeyItem>(),
            selectedKey: "",
        };
    },
    methods: {

        selectItem(key: string) {
            this.selectedKey = (key === this.selectedKey) ? "" : key;
            this.$emit("selected", this.getIndicator());
        },

        getIndicator() {
            let sourceItem: RiskSourceKeyItem | null = null;
            this.items.forEach((item) => {
                if (item.key === this.selectedKey) sourceItem = item;
            });
            if (!sourceItem) return;
            const source = sourceItem as RiskSourceKeyItem;

            const sourceKey = this.roleSourceModel + "." + this.roleTypeModel + ".";

            const randomKey = getRandomString(8);
            const indicator: RiskIndicatorDefinition = {
                key: randomKey,
                name: source.text,
                weight: 100,
                hrm: 0,
                lrm: 0,
                valueType: source.type,
                sources: [
                    {
                        source: RiskValueSource.Role,
                        key: sourceKey + source.key,
                    },
                ],
                values: [],
                isFixed: true,
                logic: [],
                scores: {},
                unknownScore: 9,
                hints: {},
                params: {},
            };

            console.log("returning", indicator);
            return indicator;
        },

    },
    mounted() {
        this.roleSourceOptions.push({ value: "key", text: "Key Roles" });
        this.roleSourceOptions.push({ value: "all", text: "All Roles" });

        this.roleTypeOptions.push({ value: "any", text: "Any Role ID" });

        // eslint-disable-next-line no-undef
        this.items = getRoleSources();
    },
});
</script>
