import { RiskScores } from "@/lib/risk/risk_matrix";
import { RiskAssets } from "../risk_assets";
import { RiskNestedItem, RiskNestedListData } from "../risk_nested_list";
import { LocalizedString } from "@/lib/common/common";

export const getCountries = (assets: RiskAssets): RiskNestedListData => {
    // This will parse the "public/assets/geography/countries.json"
    // file into the NestedList format
    const items: RiskNestedItem[] = [];

    const asset = assets.countries;
    if (!asset) {
        return {
            items,
            options: {
                limit: 0,
            },
        };
    }

    for (const i in asset.regions) {
        const region = asset.regions[i];
        const regionItem: RiskNestedItem = {
            level: 0,
            scoreKey: "c" + region.key,
            text: getLocalizedStringCountry(region),
            expanded: false,
            code: region.key,
            showCode: false,
            codeWidth: 0,
            items: [],
        };
        items.push(regionItem);
    }

    for (const i in asset.subregions) {
        const subregion = asset.subregions[i];

        let region = null;
        for (const j in items) {
            if (items[j].code === subregion.mainKey) region = items[j];
        }
        if (!region) continue;

        const subregionItem: RiskNestedItem = {
            level: 1,
            scoreKey: "c" + subregion.mainKey + subregion.key,
            text: getLocalizedStringCountry(subregion),
            expanded: false,
            code: subregion.key,
            showCode: false,
            codeWidth: 0,
            items: [],
        };
        region.items?.push(subregionItem);
    }

    for (const alpha2 in asset.countries) {
        const country = asset.countries[alpha2];

        let region = null;
        for (const j in items) {
            if (items[j].code === country.region) region = items[j];
        }
        if (!region) continue;

        let subregion = null;
        if (region.items) {
            for (const j in region.items) {
                if (region.items[j].code === country.sub) subregion = region.items[j];
            }
        }
        if (!subregion) continue;

        const countryItem: RiskNestedItem = {
            level: 2,
            scoreKey: "c" + country.region + country.sub + alpha2,
            code: alpha2,
            text: getLocalizedStringCountry(country),
            showCode: true,
            codeWidth: 40,
            expanded: false,
        };
        subregion.items?.push(countryItem);
    }

    return {
        items,
        options: {
            limit: 0,
        },
    };
};

export const getCountryDefaultScores = (assets: RiskAssets): RiskScores => {
    const scores: RiskScores = {};

    const asset = assets.countries;

    for (const i in asset.regions) {
        const region = asset.regions[i];
        scores["c" + region.key] = 5;
    }

    return scores;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getLocalizedStringCountry = (code: any): LocalizedString => {
    const text: LocalizedString = {};

    if (code.en_EN) text.en_EN = code.en_EN;
    if (code.nb_NO) text.nb_NO = code.nb_NO;
    if (code.sv_SE) text.sv_SE = code.sv_SE;
    if (code.fi_FI) text.fi_FI = code.fi_FI;

    return text;
};
