<template>
    <Popup
        @closed="closeDeletePersonModal"
        :ignore-click-outside="true"
        width="550px"
        title="Remove Person"
        title-icon="person-blue"
    >
        <div class="w-full border border-light border-solid bg-background rounded-md p-5">
            <label>{{ $t('persons.deletePerson.info') }}:</label>
            <div class="w-full bg-white rounded-md p-4">
                <div class="flex gap-4">
                    <Icon icon="person" class="w-5 h-5" />
                    <label>{{ person.data.person_info.givenName }} {{ person.data.person_info.familyName }}</label>
                    <div v-if="person.data.person_info.dateOfBirth?.year" class="rounded-md border-light border-solid border py-1 px-2">
                        DOB: {{ $d(person.data.person_info.dateOfBirth.year, "long") }}
                    </div>
                </div>
                <div>
                    <span
                        v-for="role in person.roles"
                        class="border-light border rounded-3xl pl-9 text-sm"
                        :key="role.key"
                    >{{
                        $t(role.name ? "core.roles." + role.name : "core.roles." + role.key)
                    }}</span>
                </div>
            </div>
        </div>
        <div class="w-full flex justify-end gap-4 mt-5">
            <ActionButton
                theme="transparent"
                :loading="false"
                :disabled="false"
                @btn-clicked="closeDeletePersonModal"
            >
                {{ $t('common.actions.cancel') }}
            </ActionButton>

            <ActionButton
                theme="primary"
                :loading="false"
                :disabled="false"
                @btn-clicked="removePerson"
            >
                {{ $t('persons.deletePerson.remove') }}
            </ActionButton>

        </div>
    </Popup>
</template>

<script lang="ts">
import CustomButton from "@/components/CustomButton.vue";
import Icon from "@/components/Icon.vue";
import Popup from "@/components/Popup.vue";
import { deletePerson } from "@/lib/persons/delete_person";
import { PersonWithRole } from "@/lib/persons/person";
import { defineComponent, PropType } from "vue";
import ActionButton from "../ActionButton.vue";

export default defineComponent({
    name: "DeletePersonPopup",
    components: {
        Icon,
        Popup,
        CustomButton,
        ActionButton,
    },
    emits: ["refreshPersons", "deletePersonModalClosed"],
    props: {
        person: {
            type: Object as PropType<PersonWithRole>,
            required: true,
        },
    },
    methods: {
        closeDeletePersonModal() {
            this.$emit("deletePersonModalClosed");
        },
        async removePerson() {
            const deleted = await deletePerson(this.person.id);
            if (deleted) {
                this.$emit("refreshPersons");
            }
            this.$emit("deletePersonModalClosed");
        },
    },
});
</script>
