<template>
    <div
        class="fbav3-input-radio"
        :class="[
            {'flex': isInline },
        ]"
    >
        <div
            class="flex items-center mb-2 mx-2"
            v-for="(item, i) in items"
            :key="uniqueId + i"
        >

            <input
                :id="uniqueId + item.key"
                type="radio"
                :value="item.key"
                v-model="picked"
                :name="uniqueId"
                class="w-4 h-4 text-primary-default bg-gray-100 border-light-gray focus:ring-blue-500"
                @input="onChange(item)"
            >

            <label
                :for="uniqueId + item.key"
                class="ms-2 text-sm font-medium text-gray-900"
                style="padding-top: 10px; padding-left: 3px;"
            >
                {{ item.textKey ? $t(item.textKey) : $ct(item.text) }}
            </label>

        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { CheckboxItem } from "./input_helper";

export default defineComponent({
    name: "InputRadio",
    props: {
        modelValue: { type: String as PropType<string>, required: true },
        items: { type: Array as PropType<CheckboxItem[]>, default: [] },
        uniqueId: { type: String as PropType<string>, required: true },
        isInline: { type: Boolean as PropType<boolean>, default: false },
    },
    emits: ["update:modelValue"],
    data() {
        return {
            picked: "",
        };
    },
    watch: {
        modelValue: {
            deep: false,
            handler() {
                this.picked = this.modelValue;
            },
        },
    },
    methods: {

        onChange(item: CheckboxItem) {
            this.$emit("update:modelValue", item.key);
        },

    },
    mounted() {
        this.picked = this.modelValue;
    },
});
</script>
<style scoped>
.fbav3-input-radio {
    /* padding-top: 3px; */
}

.fbav3-input-radio input[type='radio'] {
    transform: scale(1.4);
}
</style>
