<template>
    <ExpandableListLayout :expanded="expanded" @expand="expanded = $event">
        <template #top>

            <div>
                {{ $t('assessment.requirements.title') }}
            </div>

            <div class="amlv3-flowreqs-count">
                {{ completedCount + ' ' + $t('assessment.requirements.of') + ' ' + requirements.length }}
            </div>

        </template>
        <template #expanded>

            <div style="padding-bottom: 10px;">

                <div
                    v-for="(requirement, i) in requirements"
                    :key="'r' + i"
                    class="amlv3-flowreqs-item"
                    :class="[
                        {'amlv3-flowreqs-item-completed': requirement.completed },
                    ]"
                >

                    <div class="amlv3-flowreqs-item-icon">
                        <FontIcon :icon="requirement.completed ? 'check' : 'triangle-exclamation'" :size="10" />
                    </div>

                    <div class="amlv3-flowreqs-item-text">
                        <div class="amlv3-flowreqs-item-name">
                            {{ $ct(requirement.text) }}
                        </div>
                    </div>

                </div>

            </div>

            <div v-if="requirements.length === 0" class="amlv3-flowreqs-empty">
                {{ $t('assessment.requirements.none') }}
            </div>

        </template>
        <template #collapsed>

            <div style="padding-bottom: 10px;">

                <div v-if="nextRequirement" class="amlv3-flowreqs-item">

                    <div class="amlv3-flowreqs-item-icon">
                        <FontIcon :icon="'triangle-exclamation'" :size="10" />
                    </div>

                    <div class="amlv3-flowreqs-item-text">
                        <div class="amlv3-flowreqs-item-name">
                            {{ $ct(nextRequirement.text) }}
                        </div>
                    </div>

                </div>

            </div>

            <div v-if="!nextRequirement" class="amlv3-flowreqs-empty">
                {{ $t('assessment.requirements.ready') }}
            </div>

        </template>
    </ExpandableListLayout>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

import { FlowRenderRequirement } from "@/types/flow_types";

import FontIcon from "@/ui/FontIcon.vue";
import ExpandableListLayout from "../customer/menu/ExpandableListLayout.vue";

export default defineComponent({
    name: "FlowRequirements",
    components: {
        FontIcon,
        ExpandableListLayout,
    },
    props: {
        requirements: { type: Array as PropType<FlowRenderRequirement[]>, required: true },
    },
    data() {
        return {
            expanded: false,
            completedCount: 0,
            nextRequirement: null as FlowRenderRequirement | null,
        };
    },
    watch: {
        requirements: {
            deep: true,
            handler() {
                this.onLoad();
            },
        },
    },
    methods: {

        onLoad() {
            this.completedCount = 0;
            this.nextRequirement = null;
            this.requirements.forEach((requirement) => {
                if (requirement.completed) this.completedCount += 1;
                if (!requirement.completed && !this.nextRequirement) this.nextRequirement = requirement;
            });
        },

    },
    mounted() {
        /* console.log('requirements', this.requirements); */
        this.onLoad();
    },
});
</script>
<style scoped>

.amlv3-flowreqs-count {
    flex-grow: 1;
    margin-left: 10px;
}

.amlv3-flowreqs-item {
    display: flex;
    padding: 0px 18px;
    padding-top: 8px;
}

.amlv3-flowreqs-item-icon {
    width: 24px;
    height: 24px;
    margin-top: 0px;
    padding-top: 4px;
    text-align: center;
    color: white;
    border-radius: 50%;
    background-color: #B62537;
}

.amlv3-flowreqs-item-text {
    margin-left: 10px;
}

.amlv3-flowreqs-item-name {
    color: #B62537;
}

.amlv3-flowreqs-item-completed .amlv3-flowreqs-item-icon {
    padding-top: 6px;
    background-color: #218BCB;
}
.amlv3-flowreqs-item-completed .amlv3-flowreqs-item-name {
    color: #218BCB;
}

.amlv3-flowreqs-empty {
    padding: 10px 30px;
    font-size: 14px;
    color: gray;
}

</style>
