import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-36025b16"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PopupDialog = _resolveComponent("PopupDialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_PopupDialog, {
      "is-visible": _ctx.dialog.visible,
      header: _ctx.$t('common.selectContact'),
      icon: 'address-book',
      "show-cancel-button": true,
      progress: _ctx.dialog,
      onCloseModal: _ctx.onClose
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contacts, (contact, i) => {
            return (_openBlock(), _createElementBlock("div", {
              key: 'con' + i,
              class: "amlv3-contactlist-item",
              onClick: ($event: any) => (_ctx.onSelect(contact))
            }, [
              _createElementVNode("div", null, _toDisplayString(contact.givenName + ' ' + contact.familyName), 1),
              _createElementVNode("div", null, _toDisplayString(contact.email), 1)
            ], 8, _hoisted_1))
          }), 128))
        ])
      ]),
      _: 1
    }, 8, ["is-visible", "header", "progress", "onCloseModal"])
  ]))
}