<template>
    <Line :data="chartData" :options="options" />
</template>

<script lang="ts">
import { Line } from "vue-chartjs";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { options } from "floating-vue";
import { defineComponent } from "vue";

ChartJS.register(Title, Tooltip, Legend, PointElement, CategoryScale, LinearScale, LineElement);

export default defineComponent({
    name: "LineChart",
    components: {
        Line,
    },
    props: {
        chartData: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            options: {
                responsive: true,
                maintainAspectRatio: false,
            },
        };
    },
    created() {

    },
});
</script>
