<template>
    <div>

        <div
            v-for="(source, i) in sources"
            :key="'rsc' + i"
            class="riskv3-srcctrl-item"
            :class="[
                {'riskv3-srcctrl-item-first': i === 0 },
                {'riskv3-srcctrl-item-last': i === sources.length - 1 },
            ]"
        >

            <div class="riskv3-srcctrl-source">

                <div class="riskv3-srcctrl-icon">
                    <FontIcon :icon="getIcon(source)" :size="14" />
                </div>

                <div class="riskv3-srcctrl-text">
                    {{ getSourceName(source) }}
                </div>

                <div class="riskv3-srcctrl-arrow">
                    <FontIcon :icon="'right-long'" :size="14" />
                </div>

                <div v-if="source.source === 'role'" class="riskv3-srcctrl-text">
                    {{ getRoleSourceName(source) }}
                </div>

                <div v-if="source.source === 'role'" class="riskv3-srcctrl-arrow">
                    <FontIcon :icon="'right-long'" :size="14" />
                </div>

                <div class="riskv3-srcctrl-text2">
                    {{ getSourceKeyName(source) }}
                </div>

            </div>

            <div class="riskv3-srcctrl-delete" @click="showDeleteDialog(source)">
                <FontIcon :icon="'trash-can'" :size="14" />
            </div>

        </div>

        <PopupDialog
            :is-visible="deleteDialog.visible"
            :header="$t('common.actions.delete')"
            :icon="'trash-can'"
            :prompt="$t('common.actions.areYouSure')"
            :show-action-button="true"
            :show-cancel-button="true"
            :action-text="$t('common.actions.delete')"
            :is-warning="true"
            @close-modal="deleteDialog.visible = false"
            @action="onDelete"
        />

    </div>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";

import { RiskSource, RiskValueSource, RiskValueType } from "@/lib/risk/risk_matrix";
import { getSourceName, getValueSourceKeyName } from "../risk_helper";

import FontIcon from "@/ui/FontIcon.vue";
import PopupDialog from "@/ui/PopupDialog.vue";

export default defineComponent({
    name: "RiskSourceControl",
    components: {
        PopupDialog,
        FontIcon,
    },
    emits: ["delete"],
    props: {
        sources: { type: Array as PropType<RiskSource[]>, required: true },
        valueType: { type: String as PropType<RiskValueType>, required: true },
    },
    data() {
        return {
            deleteDialog: { visible: false, key: "" },
        };
    },
    methods: {

        getIcon(source: RiskSource) {
            switch (source.source) {
            case RiskValueSource.CompanyInfo: return "building-circle-check";
            case RiskValueSource.Role: return "user-tie";
            case RiskValueSource.Form: return "table-list";
            }
            return "circle-question";
        },

        getSourceName(source: RiskSource) {
            if (source.source === RiskValueSource.Role) {
                const parts = source.key.split(".");
                return parts[0] === "key" ? "Key Roles" : "All Roles";
            }
            return getSourceName(source, this.$config.settings);
        },

        getRoleSourceName(source: RiskSource) {
            const parts = source.key.split(".");
            if (parts[1] === "any") return "Any Role ID";
            return parts[1];
        },

        getSourceKeyName(source: RiskSource) {
            if (source.source === RiskValueSource.Role) {
                const parts = source.key.split(".");
                return parts[2];
            }
            return getValueSourceKeyName(source.key);
        },

        showDeleteDialog(source: RiskSource) {
            this.deleteDialog.key = source.key;
            this.deleteDialog.visible = true;
        },

        onDelete() {
            this.$emit("delete", this.deleteDialog.key);
            this.deleteDialog.visible = false;
        },

    },
});
</script>
<style scoped>
.riskv3-srcctrl-item {
    display: flex;
    width: 100%;
}

.riskv3-srcctrl-source {
    flex-grow: 1;
    display: flex;
    padding: 2px 6px;
    border: solid 1px lightgray;
    border-top: none;
    border-right: none;
    background-color: whitesmoke;
}

.riskv3-srcctrl-item-first .riskv3-srcctrl-source {
    border-top: solid 1px lightgray;
    border-top-left-radius: 4px;
}
.riskv3-srcctrl-item-last .riskv3-srcctrl-source {
    border-bottom-left-radius: 4px;
}

.riskv3-srcctrl-icon {
    width: 16px;
    text-align: center;
    padding-top: 2px;
    margin-right: 8px;
}

.riskv3-srcctrl-text {
    max-width: 150px;
    padding-top: 1px;
    font-size: 15px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.riskv3-srcctrl-text2 {
    max-width: 250px;
    padding-top: 1px;
    font-size: 15px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.riskv3-srcctrl-arrow {
    padding-top: 2px;
    margin: 0px 8px;
}

.riskv3-srcctrl-delete {
    width: 28px;
    padding-top: 4px;
    text-align: center;
    border: solid 1px lightgray;
    border-top: none;
    background-color: whitesmoke;
    cursor: pointer;
}

.riskv3-srcctrl-item-first .riskv3-srcctrl-delete {
    border-top: solid 1px lightgray;
    border-top-right-radius: 4px;
}
.riskv3-srcctrl-item-last .riskv3-srcctrl-delete {
    border-bottom-right-radius: 4px;
}

.riskv3-srcctrl-delete:hover {
    background-color: lightgray;
}
</style>
