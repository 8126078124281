import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputDropdown = _resolveComponent("InputDropdown")!
  const _component_InputGroup = _resolveComponent("InputGroup")!
  const _component_InputRow = _resolveComponent("InputRow")!
  const _component_RiskSourceItem = _resolveComponent("RiskSourceItem")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_InputRow, null, {
      default: _withCtx(() => [
        _createVNode(_component_InputGroup, { width: 6 }, {
          default: _withCtx(() => [
            _createVNode(_component_InputDropdown, {
              modelValue: _ctx.roleSourceModel,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.roleSourceModel) = $event)),
              items: _ctx.roleSourceOptions,
              "unique-id": 'role-source'
            }, null, 8, ["modelValue", "items"])
          ]),
          _: 1
        }),
        _createVNode(_component_InputGroup, { width: 6 }, {
          default: _withCtx(() => [
            _createVNode(_component_InputDropdown, {
              modelValue: _ctx.roleTypeModel,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.roleTypeModel) = $event)),
              items: _ctx.roleTypeOptions,
              "unique-id": 'role-type'
            }, null, 8, ["modelValue", "items"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
      return (_openBlock(), _createBlock(_component_RiskSourceItem, {
        key: 'rsi' + i,
        item: item,
        "is-selected": item.key === _ctx.selectedKey,
        "is-first": i === 0,
        "is-last": i === _ctx.items.length - 1,
        "value-type": _ctx.valueType,
        onClicked: ($event: any) => (_ctx.selectItem(item.key))
      }, null, 8, ["item", "is-selected", "is-first", "is-last", "value-type", "onClicked"]))
    }), 128))
  ]))
}