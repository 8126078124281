<template>
    <div
        class="amlv3-personcard"
        :class="[
            {'amlv3-personcard-selected': selected },
        ]"
    >

        <div class="amlv3-personcard-top">

            <!-- <div class="amlv3-personcard-top-check">
                <slot name="check" />
            </div> -->

            <div class="amlv3-personcard-top-slot">
                <slot name="top" />
            </div>

        </div>

        <div class="amlv3-personcard-slots">
            <!-- ID-control and Screening -->
            <slot name="controls" />
        </div>

    </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
    name: "PersonCardLayout",
    emits: [],
    props: {
        selected: { type: Boolean as PropType<boolean>, default: false },
    },
});
</script>
<style scoped>
.amlv3-personcard {
    border-radius: 8px;
    background-color: white;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
}

.amlv3-personcard-selected {
    border: solid 1px rgb(33, 139, 203);
    background-color: rgb(229, 245, 255);
}

.amlv3-personcard-top {
    display: flex;
}

.amlv3-personcard-top-check {
    flex-shrink: 0;
    width: 30px;
    padding-top: 18px;
    padding-left: 20px;
}

.amlv3-personcard-top-slot {
    flex-grow: 1;
}

.amlv3-personcard-slots {
    padding: 20px;
    padding-top: 10px;
    display: flex;
    flex-grow: 1;
    gap: 20px;
    min-height: 170px;
}
</style>
