import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-475542b4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "py-5" }
const _hoisted_2 = { class: "rounded-lg p-2 bg-background flex items-center flex-wrap gap-2" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tags, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "bg-transparent text-body px-2 py-1 rounded-md flex items-center border border-muted"
        }, [
          _createElementVNode("span", null, _toDisplayString(item), 1),
          _createElementVNode("button", {
            onClick: ($event: any) => (_ctx.removeItem(item)),
            class: "ml-2"
          }, [
            _createVNode(_component_Icon, {
              icon: "cross",
              class: "w-2 h-2"
            })
          ], 8, _hoisted_3)
        ]))
      }), 128)),
      _withDirectives(_createElementVNode("input", {
        ref: "inputField",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputText) = $event)),
        onKeyup: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.addItem && _ctx.addItem(...args)), ["enter"])),
        onKeydown: _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => (_ctx.handleBackspace && _ctx.handleBackspace(...args)), ["backspace"])),
        placeholder: _ctx.$t('common.addTag'),
        class: "flex-grow border-none outline-none text-base p-2 bg-transparent"
      }, null, 40, _hoisted_4), [
        [_vModelText, _ctx.inputText]
      ])
    ])
  ]))
}