import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a1450dec"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "amlv3-tabc" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
      return (_openBlock(), _createElementBlock("div", {
        key: 'tct' + tab.key,
        class: _normalizeClass(["amlv3-tabc-tab", [
                { 'amlv3-tabc-tab-active': tab.key === _ctx.selectedTabKey },
            ]]),
        onClick: ($event: any) => (_ctx.$emit('tab-changed', tab.key))
      }, _toDisplayString(tab.text ? _ctx.$t(tab.text) : tab.rawText || ''), 11, _hoisted_2))
    }), 128))
  ]))
}