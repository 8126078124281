import { Verified } from "../verified_plugin";
import { Person } from "./person";

export async function getPerson(id: string): Promise<Person> {
    try {
        const response = await fetch(`/api/aml/v1/persons/${id}`, {
            method: "GET",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
        });

        const body = await response.json();

        if (!response.ok) {
            throw new Error(body);
        }

        return body as Person;
    } catch (err) {
        console.error(`Failed to get project with id ${id}`, err);
        throw err;
    }
}
