import i18n from "@/i18n";

export enum EntityType {
    LimitedCompanies = "limited_companies",
    SoleProprietorship = "sole_proprietorship",
    Partnerships = "partnerships",
    LimitedPartnerships = "limited_partnerships",
    TenantOwnerAssociations = "tenant_owner_associations",
    NonProfitAssociations = "non_profit_associations",
    JointOwnershipAssociations = "joint_ownership_associations",
    FoundationsAndFunds = "foundations_and_funds",
    ReligiousSocieties = "religious_societies",
    CentralGovernmentEntities = "central_government_entities",
    PublicCorporateBodiesAndInstitutions = "public_corporate_bodies_and_institutions",
    Municipalities = "municipalities",
    OtherCentralGovernment = "other_central_government",
    OtherLocalGovernment = "other_local_government",
    Other = "other",
    Unknown = "unknown",
}

export const getEntityTypeName = (entityType: EntityType): string => {
    const { t } = i18n.global;
    return t("core.entityTypes." + entityType);
};
