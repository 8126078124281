<template>
    <div class="fbav3-input-row">

        <slot />

    </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    name: "InputRow",
});
</script>
<style scoped>
.fbav3-input-row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
</style>
