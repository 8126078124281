import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full border border-light border-solid bg-background rounded-md p-5" }
const _hoisted_2 = { class: "w-full bg-white rounded-md p-4" }
const _hoisted_3 = { class: "flex gap-4" }
const _hoisted_4 = {
  key: 0,
  class: "rounded-md border-light border-solid border py-1 px-2"
}
const _hoisted_5 = { class: "w-full flex justify-end gap-4 mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_ActionButton = _resolveComponent("ActionButton")!
  const _component_Popup = _resolveComponent("Popup")!

  return (_openBlock(), _createBlock(_component_Popup, {
    onClosed: _ctx.closeDeletePersonModal,
    "ignore-click-outside": true,
    width: "550px",
    title: "Remove Person",
    "title-icon": "person-blue"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("label", null, _toDisplayString(_ctx.$t('persons.deletePerson.info')) + ":", 1),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_Icon, {
              icon: "person",
              class: "w-5 h-5"
            }),
            _createElementVNode("label", null, _toDisplayString(_ctx.person.data.person_info.givenName) + " " + _toDisplayString(_ctx.person.data.person_info.familyName), 1),
            (_ctx.person.data.person_info.dateOfBirth?.year)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, " DOB: " + _toDisplayString(_ctx.$d(_ctx.person.data.person_info.dateOfBirth.year, "long")), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.person.roles, (role) => {
              return (_openBlock(), _createElementBlock("span", {
                class: "border-light border rounded-3xl pl-9 text-sm",
                key: role.key
              }, _toDisplayString(_ctx.$t(role.name ? "common.role." + role.name : "common.role." + role.key)), 1))
            }), 128))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_ActionButton, {
          theme: "transparent",
          loading: false,
          disabled: false,
          onBtnClicked: _ctx.closeDeletePersonModal
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('common.actions.cancel')), 1)
          ]),
          _: 1
        }, 8, ["onBtnClicked"]),
        _createVNode(_component_ActionButton, {
          theme: "primary",
          loading: false,
          disabled: false,
          onBtnClicked: _ctx.removePerson
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('persons.deletePerson.remove')), 1)
          ]),
          _: 1
        }, 8, ["onBtnClicked"])
      ])
    ]),
    _: 1
  }, 8, ["onClosed"]))
}