<template>
    <PopupDialog
        :is-visible="true"
        :header="'Approval Configuration'"
        :icon="'wand-magic-sparkles'"
        :show-cancel-button="true"
        :show-action-button="true"
        :action-text="'Save'"
        :action-icon="'floppy-disk'"
        :is-action-disabled="!dialog.valid"
        @close-modal="onClose"
        @action="onSave"
    >
        <PopupDialogSplit
            is-form
            :prompt-text="promptText"
            :prompt-hint="promptHint"
        >

            <InputGroup :width="12">
                <InputLabel
                    :text="'Key'"
                    :unique-id="'end-key'"
                    required
                />
                <InputText
                    v-model="model.key"
                    :unique-id="'end-key'"
                    :disabled="isEditMode"
                    @input="validateDialog"
                />
            </InputGroup>

            <InputGroup :width="12">
                <InputLabel
                    :text="'Name'"
                    :unique-id="'end-name'"
                    required
                />
                <InputTextLocalized
                    v-model="model.name"
                    :unique-id="'end-name'"
                    @input="validateDialog"
                    @enter="onSave"
                />
            </InputGroup>

        </PopupDialogSplit>
    </PopupDialog>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

import PopupDialog from "@/ui/PopupDialog.vue";

import { LocalizedString, LocalizedStringValidation, validateLocalizedString } from "@/lib/common/common";
import PopupDialogSplit from "@/ui/dialog/PopupDialogSplit.vue";
import { DropdownItem } from "@/ui/inputs/input_helper";
import InputGroup from "@/ui/inputs/InputGroup.vue";
import InputLabel from "@/ui/inputs/InputLabel.vue";
import InputText from "@/ui/inputs/InputText.vue";
import InputTextLocalized from "@/ui/inputs/InputTextLocalized.vue";
import { ApprovalConfig } from "@/lib/settings/settings";

export default defineComponent({
    name: "ApprovalSettingsDialog",
    components: {
        PopupDialog,
        InputGroup,
        InputLabel,
        InputText,
        InputTextLocalized,
        PopupDialogSplit,
    },
    emits: ["close-modal", "add", "edit"],
    props: {
        config: { type: Object as PropType<ApprovalConfig | null>, default: null },
    },
    data() {
        return {
            isEditMode: false,
            dialog: { valid: false, isWorking: false, statusText: "", isError: false, errorText: "" },
            model: {
                key: "",
                name: {} as LocalizedString,
            },
            configs: new Array<DropdownItem>(),
            promptText: "",
            promptHint: "",
        };
    },
    methods: {

        validateDialog() {
            let valid = true;

            if (!this.model.key) {
                valid = false;
            }
            if (!validateLocalizedString(this.model.name, LocalizedStringValidation.CurrentOnly)) {
                valid = false;
            }

            this.dialog.valid = valid;
        },

        onSave() {
            if (this.isEditMode) {
                this.$emit("edit", this.model.key, this.model.name);
            } else {
                this.$emit("add", this.model.key, this.model.name);
            }
            this.onClose();
        },

        onClose() {
            this.$emit("close-modal");
        },

    },
    mounted() {
        this.$config.settings.riskConfigs?.forEach((config) => {
            this.configs.push({
                value: config.key,
                text: config.name,
            });
        });
        if (this.config) {
            this.model.key = this.config.key;
            this.model.name = this.config.name;
            this.promptText = "Edit Approval:";
            this.promptHint = "Change the name of the approval configuration.";
            this.isEditMode = true;
        } else {
            this.promptText = "Create a new Approval:";
            this.promptHint = "A new approval configuration will be created.";
            this.isEditMode = false;
        }
    },
});
</script>
