<template>
    <div>
        <PopupDialog
            :is-visible="dialog.visible"
            :header="'Form Templates'"
            :icon="'wand-magic-sparkles'"
            :show-cancel-button="true"
            :show-action-button="true"
            :action-text="'Save'"
            :action-icon="'floppy-disk'"
            @close-modal="onClose"
            @action="onSave"
        >
            <PopupDialogSplit>
                <template #top>
                    <InputRow>
                        <InputGroup :width="12">
                            <InputLabel
                                :text="'Name'"
                                :unique-id="'temp-name'"
                            />
                            <InputLocked
                                :model-value="template.name"
                                :unique-id="'temp-name'"
                            />
                        </InputGroup>
                        <InputGroup :width="6">
                            <InputLabel
                                :text="'Slug'"
                                :unique-id="'temp-slug'"
                            />
                            <InputLocked
                                :model-value="template.slug"
                                :unique-id="'temp-slug'"
                            />
                        </InputGroup>
                        <InputGroup :width="6">
                            <InputLabel
                                :text="'Key'"
                                :unique-id="'temp-key'"
                            />
                            <InputLocked
                                :model-value="template.key"
                                :unique-id="'temp-key'"
                            />
                        </InputGroup>
                    </InputRow>
                </template>
                <div>
                    <div class="amlv3-setformtemp-dialog-scopes-title">
                        {{ 'Scopes:' }}
                    </div>
                    <div v-if="scopes.length === 0" class="amlv3-setformtemp-dialog-scopes-empty">
                        {{ 'No scopes for this template' }}
                    </div>
                    <div
                        v-for="(scope, i) in scopes"
                        :key="'sc' + i"
                        class="amlv3-setformtemp-dialog-scope"
                    >
                        <div class="amlv3-setformtemp-dialog-scope-name">
                            {{ scope }}
                        </div>
                        <div class="amlv3-setformtemp-dialog-scope-delete" @click="deleteScope(i)">
                            <FontIcon :icon="'trash-can'" :size="14" />
                        </div>
                    </div>
                </div>
                <ButtonRow :alignment="ButtonAlignment.Right" style="margin-top: 10px;">
                    <ButtonSingle
                        is-link
                        :text="'Add Scope'"
                        @clicked="showScopeDialog"
                    />
                </ButtonRow>
            </PopupDialogSplit>
        </PopupDialog>

        <!-- Scope Dialog -->
        <PopupDialog
            :is-visible="scopeDialog.visible"
            :header="'Add Scope'"
            :icon="'plus'"
            :show-cancel-button="true"
            @close-modal="closeScopeDialog"
        >
            <div class="mt-3">
                <SimpleListItem
                    v-for="(scope, i) in availableScopes"
                    :key="'scp' + i"
                    :text="scope"
                    :is-first="i === 0"
                    :is-last="i === availableScopes.length - 1"
                    :is-disabled="isScopeDisabled(scope)"
                    @clicked="selectScope(scope)"
                />
            </div>
        </PopupDialog>
    </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

import PopupDialog from "@/ui/PopupDialog.vue";

import PopupDialogSplit from "@/ui/dialog/PopupDialogSplit.vue";
import InputGroup from "@/ui/inputs/InputGroup.vue";
import InputLabel from "@/ui/inputs/InputLabel.vue";
import InputRow from "@/ui/inputs/InputRow.vue";
import InputLocked from "@/ui/inputs/InputLocked.vue";
import { FormScope, FormTemplate } from "@/lib/forms/forms";
import SimpleListItem from "@/ui/SimpleListItem.vue";
import ButtonRow from "@/ui/ButtonRow.vue";
import ButtonSingle from "@/ui/ButtonSingle.vue";
import { ButtonAlignment } from "@/types/ui_types";
import FontIcon from "@/ui/FontIcon.vue";

export default defineComponent({
    name: "FormTemplateDialog",
    components: {
        PopupDialog,
        InputRow,
        InputGroup,
        InputLabel,
        InputLocked,
        PopupDialogSplit,
        SimpleListItem,
        ButtonRow,
        ButtonSingle,
        FontIcon,
    },
    emits: ["save", "close-modal"],
    props: {
        template: { type: Object as PropType<FormTemplate>, required: true },
    },
    data() {
        return {
            ButtonAlignment,

            dialog: { visible: true },
            scopeDialog: { visible: false },
            availableScopes: new Array<string>(),

            scopes: new Array<string>(),
        };
    },
    methods: {

        showScopeDialog() {
            this.dialog.visible = false;
            this.$nextTick(() => {
                this.scopeDialog.visible = true;
            });
        },

        closeScopeDialog() {
            this.scopeDialog.visible = false;
            this.$nextTick(() => {
                this.dialog.visible = true;
            });
        },

        isScopeDisabled(scope: string): boolean {
            if (this.scopes.includes(scope)) return true;

            return false;
        },

        selectScope(scope: string) {
            // eslint-disable-next-line vue/no-mutating-props
            this.scopes.push(scope as FormScope);
            this.closeScopeDialog();
        },

        deleteScope(index: number) {
            this.scopes.splice(index, 1);
        },

        onSave() {
            this.$emit("save", this.scopes);
            this.onClose();
        },

        onClose() {
            this.$emit("close-modal");
        },

    },
    mounted() {
        this.scopes = JSON.parse(JSON.stringify(this.template.scopes));

        this.availableScopes.push("kyc");
        this.availableScopes.push("idcontrol");
    },
});
</script>
<style scoped>
.amlv3-setformtemp-dialog-scopes-title {
    font-size: 15px;
}

.amlv3-setformtemp-dialog-scopes-empty {
    font-size: 14px;
    color: gray;
}

.amlv3-setformtemp-dialog-scope {
    margin-top: 4px;
    display: flex;
}

.amlv3-setformtemp-dialog-scope-name {
    flex-grow: 1;
    padding: 3px 10px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border: solid 1px lightgray;
    border-right: none;
}

.amlv3-setformtemp-dialog-scope-delete {
    width: 26px;
    padding-top: 3px;
    text-align: center;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border: solid 1px lightgray;
    cursor: pointer;
}
.amlv3-setformtemp-dialog-scope-delete:hover {
    background-color: whitesmoke;
}
</style>
