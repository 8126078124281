<template>
    <div
        :class="['flex gap-2 rounded-full pr-1 items-center', 'border', `border-${borderColor}`, `bg-${backgroundColor}`]">
        <div v-if="!showIcon" :class="`bg-${statusColor} w-2 h-2 rounded-full ml-2 `"></div>
        <div v-else>
            <Icon class="pl-2" :icon="icon"></Icon>
        </div>
        <div>
            <p class="text-[11px] py-1 mr-2 text-gray-10">{{ status }}</p>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Icon from "@/components/Icon.vue";

export default defineComponent({
    name: 'StatusTag',
    components: {
        Icon,
    },
    props: {
        status: {
            type: String,
            required: true,
        },
        statusColor: {
            type: String,
            required: true,
        },
        showIcon: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String,
            required: false
        },
        backgroundColor: {
            type: String,
            default: "white"
        },
        borderColor: {
            type: String,
            required: true
        }
    },
});
</script>


<style>
.bg-gray-10 {
    background-color: #F4F4F4;
}

.text-gray-15 {
    color: #999999
}

.bg-gray-16 {
    background-color: #444444;

}

.text-gray-16 {
    color: #444444;
}

.text-small-xm {
    font-size: 11px;
}

.bg-green-dark {
    background-color: #009721;
}

.bg-orange-10 {
    background-color: #FAE1E4;
}

.bg-blue-light-primary {
    background-color: #E6F5FF;
    ;
}

.accessment-card {
    font-size: 22px;
    line-height: 28.6px;
    font-weight: 400;
    font-family: Roboto;
}

.bg-light-gray-12 {
    background-color: #D5D5D5;
}
</style>
