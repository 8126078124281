import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "lg:flex lg:flex-col justify-between p-6 bg-white w-1/5 border-r-2 border-gray-200 hidden font-roboto overflow-y-auto" }
const _hoisted_2 = { class: "flex gap-4 flex-col" }
const _hoisted_3 = { class: "flex gap-3 items-center mb-4" }
const _hoisted_4 = { class: "text-3xl" }
const _hoisted_5 = { class: "p-5 border border-gray-200 rounded-lg font-thin mb-4" }
const _hoisted_6 = { class: "text-light-gray text-center whitespace-pre-line" }
const _hoisted_7 = { class: "flex flex-col gap-3 text-xs pt-10" }
const _hoisted_8 = { class: "border border-gray-300 p-4 pb-1 rounded-lg" }
const _hoisted_9 = { class: "pb-2" }
const _hoisted_10 = {
  role: "list",
  class: ""
}
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { class: "truncate" }
const _hoisted_13 = { class: "text-center mt-2 text-gray-500" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_NavRoutes = _resolveComponent("NavRoutes")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("nav", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Icon, {
          class: "w-8 fill-primary-default",
          icon: "aml:logo"
        }),
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t("core.portalName")), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t("core.navBar.msgLabel")), 1),
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ path: '/aml/customers/new' }))),
          class: "text-primary-dark p-3 border border-gray-200 rounded-full w-full font-medium text-lg mt-5 hover:bg-blue-100 transition delay-20"
        }, _toDisplayString(_ctx.$t("core.navBar.btnNewCustomer")), 1)
      ]),
      _createVNode(_component_NavRoutes, { routes: _ctx.routes }, null, 8, ["routes"])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t('core.navBar.lastAccessedProjects')), 1),
        _createElementVNode("ul", _hoisted_10, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lastAccessedCustomers, (customer) => {
            return (_openBlock(), _createElementBlock("li", {
              key: customer.id
            }, [
              _createElementVNode("a", {
                class: "flex gap-4 items-center rounded-md text-xs pb-4",
                onClick: ($event: any) => (_ctx.$router.push({ path: `/aml/customers/${customer.id}/overview` }))
              }, [
                _createVNode(_component_Icon, {
                  class: "w-4 h-4",
                  icon: customer.type
                }, null, 8, ["icon"]),
                _createElementVNode("span", _hoisted_12, _toDisplayString(customer.name), 1)
              ], 8, _hoisted_11)
            ]))
          }), 128))
        ])
      ]),
      _createElementVNode("div", _hoisted_13, _toDisplayString('Release version 3.' + _ctx.$config.versionText), 1)
    ])
  ]))
}