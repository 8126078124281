import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-18c13876"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "riskv3-vc-params"
}
const _hoisted_2 = { class: "riskv3-vc-value select-none" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontIcon = _resolveComponent("FontIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "riskv3-vc",
    onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
  }, [
    (!_ctx.hideParams)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_FontIcon, {
            icon: 'gear',
            size: 12
          })
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["riskv3-vc-score", [
                { 'riskv3-vc-score-indirect': _ctx.score.isIndirect },
            ]])
    }, [
      _createElementVNode("div", {
        class: "riskv3-vc-btn riskv3-vc-l select-none",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.subScore && _ctx.subScore(...args)))
      }, _toDisplayString('-')),
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.score.score), 1),
      _createElementVNode("div", {
        class: "riskv3-vc-btn riskv3-vc-r select-none",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.addScore && _ctx.addScore(...args)))
      }, _toDisplayString('+'))
    ], 2)
  ]))
}