<template>
    <TransitionRoot as="template" :show="isVisible">
        <Dialog class="relative z-10" @close="$emit('close-modal')">
            <TransitionChild
                as="template"
                enter="ease-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in duration-200"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <div class="fixed inset-0 z-10">
                <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <TransitionChild
                        as="template"
                        enter="ease-out duration-300"
                        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leave-from="opacity-100 translate-y-0 sm:scale-100"
                        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <DialogPanel
                            class="amlv3-dialog-box relative min-w-[480px] min-h-full transform rounded-lg bg-white text-left shadow-xl transition-all"
                        >

                            <div class="amlv3-dialog-header">
                                <div
                                    class="amlv3-dialog-icon"
                                    :class="[
                                        { 'amlv3-dialog-icon-warning': isWarning },
                                    ]"
                                >
                                    <FontIcon :icon="icon" :size="24" />
                                </div>
                                <div class="amlv3-dialog-title">
                                    {{ header }}
                                </div>
                                <div class="amlv3-dialog-x" @click="$emit('close-modal')">
                                    <FontIcon :icon="'xmark'" :size="24" />
                                </div>
                            </div>

                            <div class="amlv3-dialog-content">

                                <div v-if="prompt">
                                    <!--
                                        If the dialog is just a prompt with cancel/action buttons,
                                        it can be provided through this simple prop
                                    -->
                                    {{ prompt }}
                                </div>

                                <!--
                                    The main slot for injecting the dialog content,
                                    if a simple prompt is not enough
                                -->
                                <slot />

                            </div>
                            <div class="amlv3-dialog-bottom">

                                <div>
                                    <!-- AsyncStatus provides an easy way to handle progress and error handling -->
                                    <AsyncStatus :progress="progress" />
                                </div>

                                <ButtonRow :alignment="ButtonAlignment.Right">

                                    <!-- The most common buttons can be displayed through props -->
                                    <ButtonSingle
                                        outline
                                        is-rounded
                                        :text="cancelButtonText || $t('common.actions.cancel')"
                                        @clicked="$emit('close-modal')"
                                        v-if="showCancelButton"
                                    />

                                    <ButtonSingle
                                        is-rounded
                                        :text="actionText"
                                        :icon-right="actionIcon"
                                        :is-working="progress.isWorking"
                                        :is-warning="isWarning"
                                        :disabled="isActionDisabled"
                                        @clicked="$emit('action')"
                                        v-if="showActionButton"
                                    />

                                    <!-- This slot can be used to inject buttons that require special logic/styling -->
                                    <slot name="buttons" />

                                </ButtonRow>

                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>
<script lang="ts">
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { PropType, defineComponent } from "vue";

import { AsyncProgress, ButtonAlignment } from "@/types/ui_types";
import AsyncStatus from "@/ui/AsyncStatus.vue";
import ButtonRow from "@/ui/ButtonRow.vue";
import ButtonSingle from "@/ui/ButtonSingle.vue";
import FontIcon from "./FontIcon.vue";

export default defineComponent({
    name: "PopupDialog",
    components: {
        Dialog,
        DialogPanel,
        TransitionChild,
        TransitionRoot,
        ButtonRow,
        ButtonSingle,
        AsyncStatus,
        FontIcon,
    },
    emits: ["close-modal", "action"],
    props: {
        isVisible: { type: Boolean as PropType<boolean>, required: true },
        header: { type: String as PropType<string>, required: true },
        icon: { type: String as PropType<string>, default: "" },
        progress: { type: Object as PropType<AsyncProgress>, default: { isWorking: false } },
        showCancelButton: { type: Boolean as PropType<boolean>, default: false },
        cancelButtonText: { type: String, default: "" },
        showActionButton: { type: Boolean as PropType<boolean>, default: false },
        prompt: { type: String as PropType<string>, default: "" },
        actionText: { type: String as PropType<string>, default: "" },
        actionIcon: { type: String as PropType<string>, default: "" },
        isActionDisabled: { type: Boolean as PropType<boolean>, default: false },
        isWarning: { type: Boolean as PropType<boolean>, default: false },
    },
    data() {
        return {
            ButtonAlignment,
        };
    },
});
</script>
<style scoped>
.amlv3-dialog-box {
    padding: 24px;
    max-height: calc(100vh - 20px);
}

.amlv3-dialog-header {
    display: flex;
}

.amlv3-dialog-icon {
    width: 48px;
    height: 48px;
    padding-top: 6px;
    text-align: center;
    color: #218BCB;
    border-radius: 8px;
    background-color: #C8E7FA;
}

.amlv3-dialog-icon-warning {
    color: #B62537;
    background-color: #FAE1E4;
}

.amlv3-dialog-title {
    flex-grow: 1;
    margin-left: 15px;
    padding-top: 3px;
    font-family: "Roboto Slab", serif;
    font-size: 28px;
    font-weight: 400;
}

.amlv3-dialog-x {
    margin-right: 10px;
    color: #218BCB;
    cursor: pointer;
}

.amlv3-dialog-x:hover {
    opacity: 0.7;
}

.amlv3-dialog-content {
    margin-top: 20px;
    max-height: calc(100vh - 195px);
    overflow: auto;
}

.amlv3-dialog-bottom {
    padding-top: 14px;
}
</style>
