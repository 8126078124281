<template>
    <div class="rskv3-slider">

        <div
            class="rskv3-slider-bg"
            :class="[
                {'rskv3-slider-bg-grayscale': grayscale },
            ]"
        />

        <div class="rskv3-slider-control">
            <VueSlider
                v-model="value"
                :dot-size="28"
                :rail-style="railStyle"
                :process-style="processStyle"
                :step-style="stepStyle"
                :marks="marks"
                :included="true"
                :disabled="isDisabled"
                tooltip="none"
                @click="onClickSlider"
                @dragging="onDrag"
                @drag-end="onDragEnd"
            />
        </div>

    </div>
</template>
<script lang="ts">
import { RiskConfig } from "@/lib/settings/settings";
import { getThresholdFromScore } from "@/views/risk/risk_helper";
import { defineComponent, PropType } from "vue";

// Docs: https://nightcatsama.github.io/vue-slider-component/#/
import VueSlider from "vue-3-slider-component";

interface SliderMark {
    label: string;
}

interface SliderMarkObject {
    [key: string]: SliderMark;
}

export default defineComponent({
    name: "RiskSlider",
    components: {
        VueSlider,
    },
    emits: ["slide-change", "final-change"],
    props: {
        score: { type: Number as PropType<number | undefined>, default: undefined },
        riskConfig: { type: Object as PropType<RiskConfig>, required: true },
        isDisabled: { type: Boolean as PropType<boolean>, default: false },
    },
    data() {
        return {
            value: 0,
            marks: {} as SliderMarkObject,
            lastDragThresholdKey: "",
            grayscale: false,

            // Alpha 0 to hide this
            processStyle: {
                backgroundColor: "rgba(255, 255, 255, 0)",
            },

            // Alpha 0 to hide this
            railStyle: {
                backgroundColor: "rgba(255, 255, 255, 0)",
            },

            // Style of the dots/marks, could not figure out how to make
            // them bigger without messing up the position
            stepStyle: {
                backgroundColor: "rgba(255, 255, 255)",
            },
        };
    },
    watch: {
        score: {
            deep: false,
            handler() {
                this.calculateValue();
            },
        },
    },
    methods: {

        onDrag() {
            const threshold = this.getSlideThreshold();
            // Cache the threshold.key so we don't trigger this every nanosecond
            if (threshold.key !== this.lastDragThresholdKey) this.$emit("slide-change", threshold);
            this.lastDragThresholdKey = threshold.key;
            this.grayscale = false;
        },

        onDragEnd() {
            this.correctEndValues();
            this.$emit("final-change", this.getSlideThreshold());
        },

        correctEndValues() {
            // Because of limitations with "vue-3-slider-component" we can not
            // use 0 and 100 value, it will not be rendered, so we need to use
            // the next best values wich are 1 and 99
            if (Number(this.value) === 0) this.value = 1;
            if (Number(this.value) === 100) this.value = 99;
        },

        getSlideThreshold() {
            let correctedValue = Number(this.value);
            // Convert back from 1-99 (see above comment) as we need the
            // full 0-100 values when overriding the risk
            if (Number(this.value) === 1) correctedValue = 0;
            if (Number(this.value) === 99) correctedValue = 100;
            // Convert 0-100 back to whatever the risk-config uses
            const diff = this.riskConfig.maxScore - this.riskConfig.minScore;
            const fac = diff / 100;
            const score = Number(((correctedValue * fac) + this.riskConfig.minScore).toFixed(1));
            const threshold = getThresholdFromScore(score, this.riskConfig);
            return threshold;
        },
        onClickSlider() {
            this.grayscale = false;
            this.correctEndValues();
            this.$emit("final-change", this.getSlideThreshold());
        },

        calculateDots() {
            // "vue-3-slider-component" can show "marks" on the slider that are
            // used for the "snap" action. This function will look at the risk-config
            // and calculate the position (0-100) for these snap-points, but the
            // first and last snap-point will not be rendered, so instead we use 1-99
            // and add "dummy-points" at 0-100.
            this.marks = {};
            // (See above comments) we always need 0 so add that
            this.marks["0"] = { label: "" };
            const diff = this.riskConfig.maxScore - this.riskConfig.minScore;
            const fac = 100 / diff;
            for (const i in this.riskConfig.thresholds) {
                const threshold = this.riskConfig.thresholds[i];
                let value = 0;
                if (Number(i) === 0) {
                    value = threshold.from;
                    // This is the first threshold so we add a mark at 1, since 0
                    // will not be rendered by "vue-3-slider-component"
                    this.marks["1"] = { label: "" };
                } else if (Number(i) === this.riskConfig.thresholds.length - 1) {
                    value = threshold.to;
                    // This is the last threshold so we add a mark at 99, since 100
                    // will not be rendered by "vue-3-slider-component"
                    this.marks["99"] = { label: "" };
                } else {
                    const range = threshold.to - threshold.from;
                    value = threshold.from + (range / 2);
                    const percentage = ((value - this.riskConfig.minScore) * fac).toFixed(0);
                    this.marks[percentage.toString()] = { label: "" };
                }
            }
            // (See above comments) we always need 100 so add that
            this.marks["100"] = { label: "" };
        },

        calculateValue() {
            // Convert whatever range the risk-config has into 0-100
            const diff = this.riskConfig.maxScore - this.riskConfig.minScore;
            const fac = 100 / diff;
            if (this.score !== undefined) {
                this.value = Number(((this.score - this.riskConfig.minScore) * fac).toFixed(0));
            } else {
                // If no score is provided, put the slider in the middle
                this.value = 50;
                this.grayscale = true;
            }
            this.correctEndValues();
        },

    },
    mounted() {
        this.calculateDots();
        this.calculateValue();
    },
});
</script>
<style>
/*
    Can't be scoped since it needs to override some things in
    the external slider.
*/

.rskv3-slider {
    position: relative;
    height: 28px;
}

.rskv3-slider-bg {
    position: absolute;
    top: 8px;
    left: 0px;
    width: 100%;
    height: 16px;
    border-radius: 8px;
    background: linear-gradient(
        90deg,
        #218BCB 0%,
        #C8E7FA 26.85%,
        #E5D391 42.56%,
        #F2CA61 57.72%,
        #E8AA61 73.6%,
        #CC5260 87.3%,
        #991F2E 100%
    );
}

.rskv3-slider-bg-grayscale {
    background: linear-gradient(
        90deg,
        #9c9c9c 0%,
        #e3e3e3 26.85%,
        #e0e0e0 42.56%,
        #e0e0e0 57.72%,
        #ababab 73.6%,
        #808080 87.3%,
        #676767 100%
    );
}

.rskv3-slider-control {
    padding: 0px 5px;
}

/*
    Override some stuff in the external slider.
    Prefix with an internal class since the slider is used in other
    components, and we don't want to mess them up.
*/

.rskv3-slider-control .vue-slider-dot-handle {
    border: solid 7px white;
    background-color: transparent;
}

.rskv3-slider-control .vue-slider-disabled {
    opacity: 1;
}

</style>
