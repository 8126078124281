<template>
    <PopupDialog
        :is-visible="dialog.visible"
        :header="$t('assessment.approval.approvers.label')"
        :icon="'user'"
        :show-cancel-button="true"
        :progress="dialog"
        @close-modal="onClose"
    >
        <PopupDialogSplit style="min-width: 500px; max-width: 560px;">
            <template #top>
                <InputRow>
                    <InputGroup :width="6">
                        <InputLabel
                            :text="$t('common.person_info.firstName')"
                            :unique-id="'approver-givenname'"
                        />
                        <InputLocked
                            :model-value="approver.givenName"
                            :unique-id="'approver-givenname'"
                        />
                    </InputGroup>
                    <InputGroup :width="6">
                        <InputLabel
                            :text="$t('common.person_info.lastName')"
                            :unique-id="'approver-familyname'"
                        />
                        <InputLocked
                            :model-value="approver.familyName"
                            :unique-id="'approver-familyname'"
                        />
                    </InputGroup>
                    <InputGroup :width="12">
                        <InputLabel
                            :text="$t('common.person_info.email')"
                            :unique-id="'approver-email'"
                        />
                        <InputLocked
                            :model-value="approver.email"
                            :unique-id="'approver-email'"
                        />
                    </InputGroup>
                </InputRow>
            </template>
            <div>

                <div>
                    <span>{{ $t('assessment.approval.decision.decisionPrompt') + ': ' }}</span>
                    <span style="font-weight: bold;">
                        {{ approver.decision === 'approved' ? $t('assessment.approval.decision.approved') : $t('assessment.approval.decision.denied') }}
                    </span>
                </div>

                <div>
                    <span>{{ $t('assessment.approval.decision.comment') + ': ' }}</span>
                </div>

                <div v-if="!approver.comment" style="font-style: italic; color: dimgray;">
                    {{ $t('assessment.approval.decision.noComment') }}
                </div>

                <div v-if="approver.comment">
                    {{ approver.comment }}
                </div>

            </div>
        </PopupDialogSplit>
    </PopupDialog>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

import { Approver } from "@/lib/assessments/approval_model";

import PopupDialog from "@/ui/PopupDialog.vue";
import PopupDialogSplit from "@/ui/dialog/PopupDialogSplit.vue";

import InputRow from "@/ui/inputs/InputRow.vue";
import InputGroup from "@/ui/inputs/InputGroup.vue";
import InputLabel from "@/ui/inputs/InputLabel.vue";
import InputLocked from "@/ui/inputs/InputLocked.vue";

export default defineComponent({
    name: "ApproverInfoDialog",
    components: {
        PopupDialog,
        PopupDialogSplit,
        InputRow,
        InputGroup,
        InputLabel,
        InputLocked,
    },
    emits: ["request-project-refresh", "close-modal"],
    props: {
        approver: { type: Object as PropType<Approver>, required: true },
    },
    data() {
        return {
            dialog: { visible: true, valid: false, isWorking: false, statusText: "", isError: false, errorText: "" },
        };
    },
    methods: {

        onClose() {
            this.$emit("close-modal");
        },

    },
});
</script>
