<template>
    <div class="rskv3-sliderbox">

        <div class="rskv3-sliderbox-top">
            <div class="rskv3-sliderbox-title">
                <div class="rskv3-sliderbox-title-text">
                    {{ $t('assessment.title') }}
                </div>
                <!-- <div
                    class="rskv3-sliderbox-help"
                    :class="[
                        { 'rskv3-sliderbox-help-open': helpOpen },
                    ]"
                    @click="helpOpen = !helpOpen"
                >
                    <FontIcon :icon="'circle-question'" />
                </div> -->
            </div>
            <div class="rskv3-sliderbox-risk">
                {{ getRiskText() }}
            </div>
        </div>

        <div class="rskv3-sliderbox-help-message" v-if="helpOpen">
            {{ $t('assessment.hint') }}
        </div>

        <div class="rskv3-sliderbox-slider">
            <RiskSlider
                :score="assessment.score"
                :risk-config="riskConfig"
                :is-disabled="assessment.locked"
                @slide-change="onRiskSlideChange"
                @final-change="onRiskFinalChange"
            />
        </div>

        <div style="padding: 0px 14px;">

            <div class="rskv3-sliderbox-override" v-if="assessment.manualScore && !isOverriding">
                <ButtonRow :alignment="ButtonAlignment.Right">
                    <template #left>
                        <div v-if="assessment.manualScore.isOverride" class="rskv3-sliderbox-override-text">
                            {{ $t('assessment.manualValue.overriddenBy') + ' ' + assessment.manualScore.user.name }}
                        </div>
                        <div v-if="!assessment.manualScore.isOverride" class="rskv3-sliderbox-override-text">
                            {{ $t('assessment.manualValue.setBy') + ' ' + assessment.manualScore.user.name }}
                        </div>
                    </template>
                    <ButtonSingle
                        v-if="!assessment.locked"
                        is-link
                        :text="$t('assessment.manualValue.reset')"
                        @clicked="onResetOverride"
                    />
                </ButtonRow>
            </div>

            <div class="rskv3-sliderbox-override-working" v-if="isOverriding">
                <div class="rskv3-sliderbox-override-working-spinner">
                    <SpinnerVerified :size="14" :hide-icon="true" />
                </div>
                <div class="rskv3-sliderbox-override-working-text">
                    {{ $t('assessment.manualValue.updating') + '...' }}
                </div>
            </div>

            <div v-if="matrixDefinition.options.manualScore && !assessment.manualScore && !isOverriding">
                <MissingIconAndText :text="$t('assessment.manualValue.manualHint')" />
            </div>

            <div v-if="assessment.locked || assessment.sealed" class="rskv3-sliderbox-finalized">
                <div v-if="assessment.locked && !assessment.sealed" class="rskv3-sliderbox-finalized-bg">
                    <div class="rskv3-sliderbox-finalized-icon">
                        <FontIcon :icon="'lock'" :size="16" />
                    </div>
                    <div class="rskv3-sliderbox-finalized-text">
                        {{ $t('assessment.lock.lockedMessage') }}
                    </div>
                    <div class="rskv3-sliderbox-finalized-button" @click="unlockDialog.visible = true">
                        {{ $t('assessment.lock.unlockActionShort') }}
                    </div>
                </div>
                <div v-if="assessment.sealed" class="rskv3-sliderbox-finalized-bg">
                    <div class="rskv3-sliderbox-finalized-icon">
                        <FontIcon :icon="'file-zipper'" :size="16" />
                    </div>
                    <div class="rskv3-sliderbox-finalized-text">
                        {{ $t('assessment.seal.sealedMessage') }}
                    </div>
                    <div class="rskv3-sliderbox-finalized-button" @click="newDialog.visible = true">
                        {{ $t('assessment.seal.newButton') }}
                    </div>
                </div>
            </div>

        </div>

        <!-- <div class="rskv3-sliderbox-link" v-if="showLink">
            <ButtonRow :alignment="ButtonAlignment.Right">
                <ButtonSingle
                    is-link
                    :text="'Show full risk assessment'"
                />
            </ButtonRow>
        </div>

        <div class="rskv3-sliderbox-link" v-if="showSettings">
            <ButtonRow :alignment="ButtonAlignment.Right">
                <ButtonSingle
                    is-link
                    :text="'Settings'"
                    @clicked="goToSettings"
                />
            </ButtonRow>
        </div> -->

        <NewAssessmentDialog
            v-if="newDialog.visible"
            :project="project"
            @close-modal="newDialog.visible = false"
            @request-project-refresh="$emit('request-project-refresh', $event)"
        />

        <LockAssessmentDialog
            v-if="unlockDialog.visible"
            :project="project"
            :assessment="assessment"
            :is-unlocking="true"
            @close-modal="unlockDialog.visible = false"
            @request-project-refresh="$emit('request-project-refresh', $event)"
        />

    </div>
</template>
<script lang="ts">
import RiskSlider from "@/components/assessment/RiskSlider.vue";
import { Assessment } from "@/lib/assessments/assessments";
import { RiskConfig, RiskThreshold } from "@/lib/settings/settings";
import ButtonRow from "@/ui/ButtonRow.vue";
import ButtonSingle from "@/ui/ButtonSingle.vue";
import FontIcon from "@/ui/FontIcon.vue";
import { defineComponent, type PropType } from "vue";
import { ButtonAlignment } from "@/types/ui_types";
import SpinnerVerified from "@/ui/SpinnerVerified.vue";
import MissingIconAndText from "@/ui/MissingIconAndText.vue";
import { RiskMatrixDefinition } from "@/lib/risk/risk_matrix";
import { removeManualScore, setManualScore } from "@/lib/assessments/override_assessment";
import { getProjectProcess } from "@/lib/projects/get_project";
import NewAssessmentDialog from "./NewAssessmentDialog.vue";
import { Project } from "@/lib/projects/projects";
import LockAssessmentDialog from "./LockAssessmentDialog.vue";

export default defineComponent({
    name: "RiskSliderBox",
    components: {
        RiskSlider,
        FontIcon,
        ButtonRow,
        ButtonSingle,
        SpinnerVerified,
        MissingIconAndText,
        NewAssessmentDialog,
        LockAssessmentDialog,
    },
    emits: ["request-project-refresh", "new-assessment"],
    props: {
        project: { type: Object as PropType<Project>, required: true },
        assessment: { type: Object as PropType<Assessment>, required: true },

        riskConfig: { type: Object as PropType<RiskConfig>, required: true },
        matrixDefinition: { type: Object as PropType<RiskMatrixDefinition>, required: true },

        showLink: { type: Boolean as PropType<boolean>, default: false },
        showSettings: { type: Boolean as PropType<boolean>, default: false },
    },
    data() {
        return {
            ButtonAlignment,

            isOverriding: false,

            thresholdKey: "",
            helpOpen: false,

            newDialog: { visible: false },
            unlockDialog: { visible: false },
        };
    },
    watch: {
        assessment: {
            deep: true,
            handler() {
                this.thresholdKey = this.assessment.risk || "";
            },
        },
    },
    methods: {

        onRiskSlideChange(threshold: RiskThreshold) {
            this.thresholdKey = threshold.key;
        },

        onRiskFinalChange(threshold: RiskThreshold) {
            this.thresholdKey = threshold.key;
            this.onOverrideRisk(threshold);
        },

        goToSettings() {
            this.$router.push({ path: "/aml/matrix", query: { key: this.assessment.matrixKey } });
        },

        getRiskText() {
            const riskConfigs = this.$config.settings.riskConfigs || [];
            for (const i in riskConfigs) {
                for (const j in riskConfigs[i].thresholds) {
                    const threshold = riskConfigs[i].thresholds[j];
                    if (threshold.key === this.thresholdKey) return this.$ct(threshold.text);
                }
            }
            return this.$t("assessment.notAssessed");
        },

        async onOverrideRisk(threshold: RiskThreshold) {
            if (!this.matrixDefinition || !this.riskConfig) {
                return;
            }
            this.isOverriding = true;

            const isOverride = !this.matrixDefinition.options.manualScore;

            let score = 0;
            if (threshold.from === this.riskConfig.minScore) {
                score = this.riskConfig.minScore;
            } else if (threshold.to === this.riskConfig.maxScore) {
                score = this.riskConfig.maxScore;
            } else {
                const mid = (threshold.to - threshold.from) / 2;
                score = threshold.from + mid;
            }

            const response = await setManualScore(
                this.project.id,
                this.assessment.id,
                isOverride,
                score,
            );
            if (response.responseInfo.isError) {
                // todo: error handling
            } else {
                const projectResponse = await getProjectProcess(this.project.id);
                this.$emit("request-project-refresh", projectResponse);
            }

            this.isOverriding = false;
        },

        async onResetOverride() {
            this.isOverriding = true;

            const response = await removeManualScore(this.project.id, this.assessment.id);
            if (response.responseInfo.isError) {
                // todo: error handling
            } else {
                const projectResponse = await getProjectProcess(this.project.id);
                this.$emit("request-project-refresh", projectResponse);
            }

            this.isOverriding = false;
        },

    },
    mounted() {
        this.thresholdKey = this.assessment.risk || "";
    },
});
</script>
<style scoped>
.rskv3-sliderbox {
    background-color: #F4F4F4;
    padding-bottom: 20px;
}

.rskv3-sliderbox-top {
    display: flex;
    padding: 15px 15px;
}

.rskv3-sliderbox-title {
    flex-grow: 1;
    display: flex;
}

.rskv3-sliderbox-title-text {
    margin-top: 2px;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.9px;
    color: #444444;
}

.rskv3-sliderbox-help {
    margin-left: 10px;
    color: #666666;
}

.rskv3-sliderbox-help:hover {
    color: #218BCB;
}

.rskv3-sliderbox-help-open {
    color: #218BCB;
}

.rskv3-sliderbox-risk {
    flex-shrink: 0;
    height: 28px;
    padding: 5px 12px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 14px;
    border: solid 1px #E9E9E9;
    background-color: white;
}

.rskv3-sliderbox-slider {
    padding: 14px 15px;
    padding-top: 0px;
}

.rskv3-sliderbox-link {
    margin-top: 10px;
    padding-right: 18px;
    padding-bottom: 15px;
}

.rskv3-sliderbox-override {
    height: 28px;
    padding: 3px 12px;
    border-radius: 14px;
    border: solid 1px #E9E9E9;
    background-color: white;
}

.rskv3-sliderbox-override-text {
    padding-top: 1px;
    padding-bottom: 6px;
    font-size: 13px;
}

.rskv3-sliderbox-override-working {
    display: flex;
}

.rskv3-sliderbox-override-working-spinner {
    width: 28px;
}

.rskv3-sliderbox-override-working-text {
    margin-left: 4px;
    font-size: 15px;
    color: dimgray;
}

.rskv3-sliderbox-finalized {
    margin-top: 15px;
}

.rskv3-sliderbox-finalized-bg {
    height: 40px;
    display: flex;
    padding: 8px 20px;
    border-radius: 20px;
    background-color: rgb(213, 237, 228);
}

.rskv3-sliderbox-finalized-icon {
    color: rgb(35, 111, 82);
}

.rskv3-sliderbox-finalized-text {
    flex-grow: 1;
    padding-top: 2px;
    padding-left: 10px;
    font-size: 14px;
    color: rgb(35, 111, 82);
}

.rskv3-sliderbox-finalized-button {
    padding-top: 2px;
    font-size: 14px;
    font-weight: 600;
    color: rgb(35, 111, 82);
    cursor: pointer;
}
.rskv3-sliderbox-finalized-button:hover {
    opacity: 0.7;
}
</style>
