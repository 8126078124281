<template>
    <div v-click-away="hideMenu">

        <div class="amlv3-customermenu-title">
            {{ title }}
        </div>

        <div class="amlv3-customermenu-tabs">

            <div
                v-for="(tab, i) in visibleTabs"
                :key="'tab' + i"
                class="amlv3-customermenu-tab"
                :class="[
                    { 'amlv3-customermenu-tab-active': selectedTab == tab.key },
                ]"
                @click="selectTab(tab)"
            >
                {{ $t(tab.textKey) }}
            </div>

            <div
                v-if="showMenu"
                class="amlv3-customermenu-tab amlv3-customermenu-tab-m"
                @click="onMenuClicked"
            >
                {{ $t('project.overviewMenu.tabs.menu') }}
                <div style="margin-top: -2px; margin-left: 6px;">
                    <FontIcon :icon="'ellipsis'" />
                </div>
            </div>

            <div
                v-if="tabMenuOpen"
                class="amlv3-customermenu-tab-menu-bg"
                @click="hideMenu"
            />

            <div v-if="tabMenuOpen" class="amlv3-customermenu-tab-menu">

                <div
                    v-for="(tab, i) in menuTabs"
                    :key="'mt' + i"
                    class="amlv3-customermenu-tab-menuitem"
                    @click="selectTab(tab)"
                >
                    {{ $t(tab.textKey) }}
                </div>

            </div>

        </div>

    </div>
</template>
<script lang="ts">
import FontIcon from "@/ui/FontIcon.vue";
import { defineComponent, type PropType } from "vue";
import { mixin as VueClickAway } from "vue3-click-away";

export interface MenuTab {
    key: string;
    textKey: string;
    number: number;
}

export default defineComponent({
    name: "MenuTabs",
    mixins: [VueClickAway],
    components: {
        FontIcon,
    },
    emits: ["tab"],
    props: {
        title: { type: String as PropType<string>, required: true },
        tabs: { type: Array as PropType<MenuTab[]>, required: true },
        selectedTab: { type: String as PropType<string>, required: true },
        maxVisibleTabs: { type: Number as PropType<number>, default: 4 },
    },
    data() {
        return {
            showMenu: false,
            tabMenuOpen: false,

            visibleTabs: new Array<MenuTab>(),
            menuTabs: new Array<MenuTab>(),
        };
    },
    watch: {
        selectedTab: {
            deep: false,
            handler() {
                this.loadTabs();
            },
        },
    },
    methods: {

        selectTab(tab: MenuTab) {
            this.$emit("tab", tab.key);
            this.hideMenu();
        },

        onMenuClicked() {
            this.tabMenuOpen = !this.tabMenuOpen;
        },

        hideMenu() {
            this.tabMenuOpen = false;
        },

        loadTabs() {
            this.visibleTabs = [];
            this.menuTabs = [];

            let count = 0;
            let roomForOneMore = true;

            this.tabs.forEach((tab) => {
                if (count < this.maxVisibleTabs - 2) {
                    this.visibleTabs.push(tab);
                } else if (tab.key === this.selectedTab && this.tabs.length > this.maxVisibleTabs) {
                    // If the selected tab is usually hidden in the menu
                    // it should be moved to the visible tabs
                    this.visibleTabs.push(tab);
                    roomForOneMore = false;
                } else {
                    this.menuTabs.push(tab);
                }
                count += 1;
            });

            if (roomForOneMore && this.menuTabs.length > 0) {
                // The selected tab is already visible, so we can add one more
                // to the visible tabs
                const tab = this.menuTabs.splice(0, 1)[0];
                this.visibleTabs.push(tab);
            }

            if (this.tabs.length === this.maxVisibleTabs && this.menuTabs.length > 0) {
                // All tabs can fit, so hide the menu and move the last
                // tab into visible tabs
                const tab = this.menuTabs.splice(0, 1)[0];
                this.visibleTabs.push(tab);
            }

            this.showMenu = (this.menuTabs.length > 0);
        },

    },
    mounted() {
        this.loadTabs();
    },
});
</script>
<style scoped>
.amlv3-customermenu-title {
    font-size: 28px;
    font-weight: 400;
}

.amlv3-customermenu-tabs {
    position: relative;
    margin-top: 10px;
    display: flex;
}

.amlv3-customermenu-tab {
    flex-grow: 1;
    text-align: center;
    color: #444444;
    font-weight: 500;
    border-bottom: solid 3px #E1E1E1;
    cursor: pointer;
}

.amlv3-customermenu-tab:hover {
    border-bottom: solid 3px gray;
}

.amlv3-customermenu-tab-active {
    border-bottom: solid 3px #218bcb;
}

.amlv3-customermenu-tab-active:hover {
    border-bottom: solid 3px #218bcb;
}

.amlv3-customermenu-tab-m {
    display: flex;
    justify-content: center;
}

.amlv3-customermenu-tab-menu-bg {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background-color: gray;
    opacity: 0.5;
    z-index: 10;
}

.amlv3-customermenu-tab-menu {
    position: absolute;
    top: 30px;
    right: 0px;
    width: 100%;
    border: solid 1px #E1E1E1;
    border-bottom: none;
    background-color: white;
    z-index: 10;
}

.amlv3-customermenu-tab-menuitem {
    padding: 5px 10px;
    border-bottom: solid 1px #E1E1E1;
    cursor: pointer;
}

.amlv3-customermenu-tab-menuitem:hover {
    background-color: whitesmoke;
}
</style>
