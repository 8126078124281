<template>
    <div class="font-roboto w-full bg-white">
        <div class="">
            <nav class="-mb-px flex justify-around text-body" aria-label="Tabs">
                <div
                    v-for="tab in tabs"
                    @click="changeTab(tab)"
                    :key="tab.key"
                    class="text-lg"
                    :class="[
                        tab.key === selectedTabKey ? 'border-primary-default font-extrabold' : 'border-light  ',
                        'flex items-center justify-center whitespace-nowrap border-b-4 py-4 px-1 font-medium hover:cursor-pointer w-full',
                    ]"
                    :aria-current="tab.key === selectedTabKey ? 'page' : undefined"
                >
                    {{ $t(tab.text) }}
                    <span
                        v-if="tab.itemCount"
                        class="bg-background w-5 h-5 ml-2 hidden rounded-full py-0.5 px-1 text-xs font-medium md:inline-block text-center"
                        >{{ tab.itemCount }}</span
                    >
                </div>
            </nav>
        </div>
    </div>
</template>

<script lang="ts">
import { TabItem } from "@/lib/common/tabItem";
import { defineComponent, PropType } from "vue";
export default defineComponent({
    name: "Tabs",
    props: {
        tabs: Array as PropType<TabItem[]>,
        selectedTabKey: {
            type: String,
            default: "",
        },
    },
    data() {
        return {};
    },
    emits: ["changeTab"],
    methods: {
        changeTab(tab: TabItem) {
            this.$emit("changeTab", tab.key);
        },
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
});
</script>
