/* eslint-disable @typescript-eslint/no-explicit-any */
import { LocalizedString } from "../common/common";

export enum RiskValueSource {
    Empty = "empty",
    Manual = "manual",
    CompanyInfo = "company-info",
    Form = "form",
    Role = "role",
}

export interface RiskSource {
    source: RiskValueSource;
    key: string;
    formKey?: string;
}

export enum RiskValueType {
    FreeText = "string",
    Number = "number",
    DateAge = "date-age",
    YesNo = "yes-no",
    MultipleChoice = "multiple-choice",
    Country = "country",
    NaceCode = "nace-code",
    HighRiskCountry = "high-risk-country",
    PepHit = "pep-hit",
}

export enum RiskValueMode {
    Match = "match",
    Option = "option",
    Range = "range",
}

export enum RiskValueMatchType {
    Matches = "matches",
    Includes = "includes",
}

export interface RiskScores {
    [key: string]: number;
}

export interface RiskHints {
    [key: string]: LocalizedString;
}

export enum RiskLogicType {
    Logic = "logic",
    Flag = "flag",
}

export interface RiskLogic {
    type: RiskLogicType;
    key: string;
    name: string;
}

export interface RiskCommentDefinition {
    key: string;
    prompt: LocalizedString;
    required: boolean;
    indicatorKey?: string;
    simpleTrigger: string;
}

export interface RiskValue {
    key: string;
    mode: RiskValueMode;
    name?: LocalizedString;
    from?: number;
    fromEqual?: boolean;
    to?: number;
    toEqual?: boolean;
    matchType?: RiskValueMatchType;
    matchList?: string[];
}

export interface RiskIndicatorDefinition {
    key: string;
    name: LocalizedString;

    weight: number;
    hrm: number;
    lrm: number;

    valueType: RiskValueType;
    sources: RiskSource[];

    // Just used for custom indicators ???
    values: RiskValue[];
    isFixed: boolean;

    logic: RiskLogic[];

    // Possible score values for this indicator
    scores: RiskScores;

    unknownScore: number;

    hints: RiskHints;

    params: any;
}

export interface RiskMatrixOptions {
    manualScore: boolean;
}

export interface RiskMatrixDefinition {
    namespace: string;
    key: string;
    name: LocalizedString;

    version: number;
    prodVersion: number;
    devVersion: number;

    configKey: string;
    config: any;

    options: RiskMatrixOptions;

    indicators: RiskIndicatorDefinition[];
    comments: RiskCommentDefinition[];
}
