<template>
    <div class="p-5 border border-grey-200 rounded-tl-md rounded-tr-md">
        <InputRow>
            <InputGroup :width="6">
                <InputLabel
                    unique-id="first-name"
                    :text="$t('common.person_info.firstName')"
                    :required="true"
                />
                <InputText
                    v-model="person.firstName"
                    unique-id="first-name-input"
                    :placeholder="$t('common.placeholders.firstName')"
                    :required="true"
                />
            </InputGroup>
            <InputGroup :width="6">
                <InputLabel
                    unique-id="last-name"
                    :text="$t('common.person_info.lastName')"
                    :required="true"
                />
                <InputText
                    v-model="person.lastName"
                    unique-id="last-name-input"
                    :placeholder="$t('common.placeholders.lastName')"
                    :required="true"
                />
            </InputGroup>
        </InputRow>
        <InputRow>
            <InputGroup :width="6">
                <CountryDropdown
                    v-model="person.country"
                    label="Country"
                    :required="true"
                    :placeholder="$t('common.placeholders.country')"
                />
            </InputGroup>
            <InputGroup :width="6">
                <InputLabel
                    unique-id="date-of-birth"
                    :text="$t('common.person_info.dateOfBirth')"
                    :required="true"
                />
                <div>
                    <DatePicker v-model="person.dateOfBirth" />
                </div>

            </InputGroup>
        </InputRow>
        <InputRow>
            <InputGroup :width="6">
                <InputLabel
                    unique-id="ssn"
                    :text="$t('common.person_info.ssn')"
                />
                <InputText
                    v-model="person.ssn"
                    unique-id="ssn-input"
                    :placeholder="$t('common.placeholders.ssn')"
                />
            </InputGroup>
            <InputGroup :width="6">
                <InputLabel unique-id="email" :text="$t('common.person_info.email')" />
                <InputText
                    v-model="person.email"
                    unique-id="email-input"
                    :placeholder="$t('common.placeholders.email')"
                />
            </InputGroup>
        </InputRow>
    </div>

    <div>
        <WorkflowSelector v-model="flowModel" :conditions="flowConditions" />
    </div>

    <div>
        <AsyncStatus :progress="progress" />
    </div>

    <!-- <div class="mt-1 border bg-yellow-5 p-3 " >
        <div class="flex gap-5 ">
            <div class="">
                <Icon class="w-2 h-2" icon="aml:foundPerson" />
            </div>
            <div class="text-lg">
                <h1>Similar Person found in your database!</h1>
            </div>
        </div>

        <div class="my-4">
            <div class="font-bold text-sm">
                <h3>Avoiding duplicates:</h3>
            </div>
            <div>
                <p class="text-sm">Verify if any similar persons is not the actual person you are adding</p>
            </div>
        </div>
    </div> -->
    <div class="flex items-center justify-between">
        <div class="my-5">
            <CustomButton
                :text="$t('newCustomer.btnReset')"
                bg-color="white"
                text-color="primary-default"
                round-style="full"
                border-color="muted"
                :on-click="resetInformation"
            />
        </div>
        <div class="my-5">
            <ActionButton
                @btn-clicked="handleAddPerson"
                :disabled="!canSubmit"
                :loading="progress.isWorking"
                class="ml-auto mr-0 block"
            >
                {{ $t('newCustomer.btnAddPerson') }}
            </ActionButton>
        </div>
    </div>
</template>

<script lang="ts">
import { createProject } from "@/lib/projects/create_project";
import { Project, ProjectType } from "@/lib/projects/projects";
import { FlowConditions } from "@/types/flow_types";
import { AsyncProgress } from "@/types/ui_types";
import AsyncStatus from "@/ui/AsyncStatus.vue";
import { defineComponent } from "vue";
import CustomButton from "../CustomButton.vue";
import WorkflowSelector from "./WorkflowSelector.vue";
import { createPerson } from "@/lib/persons/create_person";
import { createRole } from "@/lib/role/create_role";
import { PersonInfoBlock } from "@/lib/persons/person";
import { RoleCode } from "@/lib/role/Role";
import { updateAsKeyRole } from "@/lib/role/update_role";
import InputRow from "@/ui/inputs/InputRow.vue";
import InputGroup from "@/ui/inputs/InputGroup.vue";
import InputText from "@/ui/inputs/InputText.vue";
import InputLabel from "@/ui/inputs/InputLabel.vue";
import CountryDropdown from "@/ui/inputs/CountryDropdown.vue";
import DatePicker from "@/components/DatePicker.vue";
import ActionButton from "@/components/ActionButton.vue";

export default defineComponent({
    name: "PersonCustomerCreator",
    components: {
        CustomButton,
        WorkflowSelector,
        AsyncStatus,
        InputGroup,
        InputText,
        InputLabel,
        CountryDropdown,
        InputRow,
        DatePicker,
        ActionButton,
    },
    data() {
        return {
            person: {
                firstName: "",
                lastName: "",
                dateOfBirth: "",
                country: "",
                ssn: "",
                phoneNumber: "",
                email: "",
            },
            flowModel: "",
            flowConditions: {
                type: ProjectType.Person,
                countries: [],
                flags: [],
            } as FlowConditions,
            progress: {
                isWorking: false,
                statusText: "",
                isError: false,
                errorText: "",
            } as AsyncProgress,
        };
    },

    computed: {
        canSubmit(): boolean {
            return !!this.person.firstName.trim() && !!this.person.lastName.trim() && !!this.person.dateOfBirth.trim() && !!this.person.country.trim();
        },
    },
    watch: {
    },

    methods: {

        async handleAddPerson() {
            this.progress.isError = false;
            this.progress.isWorking = true;
            this.progress.statusText = "Creating project";

            // Create project
            let project: Project;
            const response = await createProject({
                name: this.person.firstName + " " + this.person.lastName,
                type: ProjectType.Person,
                workflow: this.flowModel,
            });
            if (response.responseInfo.isError || !response.project) {
                this.progress.errorText = response.responseInfo.parsedError?.message || "Unknown Error";
                this.progress.isError = true;
                this.progress.isWorking = false;
            } else {
                project = response.project;
                const dob = new Date(this.person.dateOfBirth);
                const dateObject = {
                    year: dob.getFullYear(),
                    month: dob.getMonth() + 1,
                    day: dob.getDate(),
                };
                const options: PersonInfoBlock = {
                    givenName: this.person.firstName.trim(),
                    familyName: this.person.lastName.trim(),
                    dateOfBirth: dateObject,
                    email: this.person.email.trim(),
                    nationality: {
                        country: this.person.country,
                        nationalIdentificationNumber: this.person.ssn.trim(),
                    },
                };
                const person = await createPerson(options);
                await createRole(project.id, person.id, [{ key: RoleCode.Owner }]);
                await updateAsKeyRole(project.id, person.id, true);
                this.$router.push({ path: `/aml/customers/${project.id}/overview` });
            }
        },

        resetInformation() {
            this.person.firstName = "";
            this.person.lastName = "";
            this.person.dateOfBirth = "";
            this.person.country = "";
            this.person.ssn = "";
            this.person.phoneNumber = "";
            this.person.email = "";
        },

    },
});
</script>
