import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomerTopAction = _resolveComponent("CustomerTopAction")!
  const _component_SimpleListItem = _resolveComponent("SimpleListItem")!
  const _component_PopupDialog = _resolveComponent("PopupDialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_CustomerTopAction, {
      icon: 'user-tie',
      text: _ctx.managerName,
      tooltip: _ctx.$t('project.manager.title'),
      "is-clickable": true,
      onClicked: _ctx.showDialog
    }, null, 8, ["text", "tooltip", "onClicked"]),
    _createVNode(_component_PopupDialog, {
      "is-visible": _ctx.dialog.visible,
      header: _ctx.$t('project.manager.changeAction'),
      icon: 'user-tie',
      progress: _ctx.dialog,
      "show-cancel-button": true,
      "show-action-button": true,
      "action-icon": 'repeat',
      "is-action-disabled": _ctx.selectedManager == _ctx.project.manager,
      "action-text": _ctx.$t('project.manager.changeAction'),
      "is-warning": false,
      onCloseModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dialog.visible = false)),
      onAction: _ctx.updateManager
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.employees, (employee, i) => {
            return (_openBlock(), _createBlock(_component_SimpleListItem, {
              key: employee.uid,
              text: employee.name,
              "sub-text": employee.email,
              "is-selected": employee.uid === _ctx.selectedManager,
              "is-first": i === 0,
              "is-last": i === _ctx.employees.length - 1,
              onClicked: ($event: any) => (_ctx.selectManager(employee.uid))
            }, null, 8, ["text", "sub-text", "is-selected", "is-first", "is-last", "onClicked"]))
          }), 128))
        ])
      ]),
      _: 1
    }, 8, ["is-visible", "header", "progress", "is-action-disabled", "action-text", "onAction"])
  ]))
}