import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-42db8de4"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = {
  key: 0,
  class: "riskv3-nestl-exp"
}
const _hoisted_3 = { class: "riskv3-nestl-text" }
const _hoisted_4 = {
  key: 2,
  class: "riskv3-nestl-score"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  key: 0,
  class: "riskv3-nestl-actions"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RiskScoreControl = _resolveComponent("RiskScoreControl")!
  const _component_RiskNestedList = _resolveComponent("RiskNestedList", true)!
  const _component_RiskTextButton = _resolveComponent("RiskTextButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["riskv3-nestl", [
            { 'riskv3-nestl-root': _ctx.isRoot },
            { 'riskv3-nestl-second': !_ctx.isRoot && _ctx.isSecond },
        ]])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items.slice(0, _ctx.limit), (item, i) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["riskv3-nestl-items", [
                { 'riskv3-nestl-items-root': _ctx.isRoot },
            ]]),
        key: 'nl' + item.level + i
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["riskv3-nestl-item", [
                    { 'riskv3-nestl-item-root': _ctx.isRoot },
                    { 'riskv3-nestl-item-expanded': item.expanded && item.items && item.items.length > 0 },
                    { 'riskv3-nestl-first': i === 0 },
                    { 'riskv3-nestl-selected': item.code === _ctx.selectedCode },
                ]]),
          onClick: ($event: any) => (_ctx.onItemClicked(item))
        }, [
          (item.items && item.items.length > 0 && (_ctx.selectableLevel !== -1 && item.level < _ctx.selectableLevel))
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(item.expanded ? '-' : '+'), 1))
            : _createCommentVNode("", true),
          (item.showCode)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "riskv3-nestl-code",
                style: _normalizeStyle('width: ' + item.codeWidth + 'px;')
              }, _toDisplayString(item.code), 5))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$ct(item.text)), 1),
          (_ctx.isDesigner)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_RiskScoreControl, {
                  score: _ctx.getScore(item),
                  "score-key": item.scoreKey,
                  onScoreChanged: _ctx.onScoreChanged
                }, null, 8, ["score", "score-key", "onScoreChanged"])
              ]))
            : _createCommentVNode("", true)
        ], 10, _hoisted_1),
        (item.expanded && item.items && (_ctx.selectableLevel === -1 || item.level < _ctx.selectableLevel))
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_RiskNestedList, {
                items: item.items,
                scores: _ctx.scores,
                "score-map": _ctx.scoreMap,
                "is-designer": _ctx.isDesigner,
                "is-root": false,
                "is-second": _ctx.isRoot,
                "selectable-level": _ctx.selectableLevel,
                "selected-code": _ctx.selectedCode,
                onClicked: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onItemClicked($event))),
                onScoreChanged: _ctx.onScoreChanged,
                onItemSelected: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('item-selected', $event)))
              }, null, 8, ["items", "scores", "score-map", "is-designer", "is-second", "selectable-level", "selected-code", "onScoreChanged"])
            ]))
          : _createCommentVNode("", true)
      ], 2))
    }), 128)),
    (_ctx.isRoot)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          (_ctx.showCollapse)
            ? (_openBlock(), _createBlock(_component_RiskTextButton, {
                key: 0,
                text: _ctx.limit === 9999 ? 'Collapse' : 'Expand',
                onClicked: _ctx.onExpandCollapseClicked
              }, null, 8, ["text", "onClicked"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}