<template>
    <ExpandableListLayout
        :can-expand="false"
        :expanded="sealedAssessments.length === 0"
        :button-text="$t('assessment.latest.showAll')"
        @clicked="$emit('menu-tab', 'assessments')"
    >
        <template #top>

            {{ $t('assessment.latest.title') }}

        </template>
        <template #expanded>
            <div style="padding: 10px 30px; font-size: 14px; color: gray;">
                {{ $t('assessment.latest.empty') }}
            </div>
        </template>
        <template #collapsed>
            <MenuAssessmentList
                :project="project"
                :assessments="sealedAssessments"
                :max-count="3"
                @file-preview="$emit('file-preview', $event)"
            />
        </template>
    </ExpandableListLayout>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

import { Assessment } from "@/lib/assessments/assessments";

import ExpandableListLayout from "../customer/menu/ExpandableListLayout.vue";
import { loadAssessments } from "@/lib/assessments/assessment_helper";
import { Project } from "@/lib/projects/projects";
import MenuAssessmentList from "./MenuAssessmentList.vue";

export default defineComponent({
    name: "PreviousAssessments",
    components: {
        ExpandableListLayout,
        MenuAssessmentList,
    },
    emits: ["menu-tab", "file-preview"],
    props: {
        assessments: { type: Array as PropType<Assessment[]>, required: true },
        project: { type: Object as PropType<Project>, required: false, default: ({}) },
    },
    data() {
        return {
            sealedAssessments: new Array<Assessment>(),
        };
    },
    watch: {
        assessments: {
            deep: true,
            handler() {
                this.loadAssessments();
            },
        },
    },
    methods: {

        loadAssessments() {
            const loadResult = loadAssessments(this.assessments);
            this.sealedAssessments = loadResult.sealedAssessments;
        },

    },
    mounted() {
        this.loadAssessments();
    },
});
</script>
