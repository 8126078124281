import { LocalizedString } from "../common/common";
import { ApiResponse, parseLocalError, parseRemoteError } from "../error_parser";
import { Verified } from "../verified_plugin";
import { RiskMatrixDefinition } from "./risk_matrix";

export type CreateRiskMatrixOptions = {
    key: string;
    name: LocalizedString;
    configKey: string;
};

export const createRiskMatrix = async (options: CreateRiskMatrixOptions): Promise<{ matrix: RiskMatrixDefinition | null, responseInfo: ApiResponse }> => {
    try {
        const response = await fetch(`/api/aml/v1/matrices`, {
            method: "POST",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
            body: JSON.stringify({
                ...options,
            }),
        });

        const body = await response.json();

        if (!response.ok) {
            return {
                matrix: null,
                responseInfo: { isError: true, parsedError: parseRemoteError(body) },
            };
        }

        return {
            matrix: body.matrix as RiskMatrixDefinition,
            responseInfo: { isError: false, parsedError: null },
        };
    } catch (error) {
        return {
            matrix: null,
            responseInfo: { isError: true, parsedError: parseLocalError(error) },
        };
    }
};
