import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = {
  key: 0,
  class: "ml-1 text-danger-default"
}
const _hoisted_3 = {
  key: 1,
  class: "block truncate flex-1"
}
const _hoisted_4 = {
  key: 2,
  class: "block truncate text-light-gray flex-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ListboxLabel = _resolveComponent("ListboxLabel")!
  const _component_FlagIcon = _resolveComponent("FlagIcon")!
  const _component_FontIcon = _resolveComponent("FontIcon")!
  const _component_ListboxButton = _resolveComponent("ListboxButton")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_ListboxOption = _resolveComponent("ListboxOption")!
  const _component_ListboxOptions = _resolveComponent("ListboxOptions")!
  const _component_Listbox = _resolveComponent("Listbox")!

  return (_openBlock(), _createBlock(_component_Listbox, {
    "model-value": _ctx.modelValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = (value) => _ctx.updateModelValue(value)),
    disabled: _ctx.disabled,
    multiple: _ctx.multiple
  }, {
    default: _withCtx(({ open }) => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.label)
          ? (_openBlock(), _createBlock(_component_ListboxLabel, {
              key: 0,
              class: "block"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
                (_ctx.required)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString("*")))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_ListboxButton, {
          as: "div",
          class: _normalizeClass([[_ctx.bgColor, _ctx.borderRadius], "relative w-full flex gap-3 items-center border border-light border-solid cursor-default h-[42px] py-0 pl-3 pr-2 text-left"])
        }, {
          default: _withCtx(() => [
            (_ctx.showFlag && _ctx.selectedOptionValue)
              ? (_openBlock(), _createBlock(_component_FlagIcon, {
                  key: 0,
                  country: _ctx.selectedOptionValue
                }, null, 8, ["country"]))
              : _createCommentVNode("", true),
            (_ctx.selectedOptionLabel)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.selectedOptionLabel), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.placeholder), 1)),
            _createVNode(_component_FontIcon, {
              class: "",
              icon: open ? 'angle-up' : 'angle-down'
            }, null, 8, ["icon"])
          ]),
          _: 2
        }, 1032, ["class"]),
        _createVNode(_Transition, { name: "fade" }, {
          default: _withCtx(() => [
            _createVNode(_component_ListboxOptions, { class: "absolute list-none pl-3 z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 border border-light border-solid" }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
                  return (_openBlock(), _createBlock(_component_ListboxOption, {
                    key: option.value,
                    value: option.value
                  }, {
                    default: _withCtx(({ active, selected }) => [
                      _createElementVNode("li", {
                        class: _normalizeClass([active ? 'bg-gray-100' : '', 'relative flex items-center gap-3 cursor-default select-none py-2 pr-4'])
                      }, [
                        (_ctx.showFlag)
                          ? (_openBlock(), _createBlock(_component_FlagIcon, {
                              key: 0,
                              country: option.value
                            }, null, 8, ["country"]))
                          : _createCommentVNode("", true),
                        _createElementVNode("span", {
                          class: _normalizeClass([selected ? 'font-medium' : 'font-normal', 'block truncate'])
                        }, _toDisplayString(option.label), 3),
                        selected
                          ? (_openBlock(), _createBlock(_component_Icon, {
                              key: 1,
                              icon: "blue-tick-mark",
                              class: "ml-auto w-3 h-3 mr-3 inset-y-0"
                            }))
                          : _createCommentVNode("", true)
                      ], 2)
                    ]),
                    _: 2
                  }, 1032, ["value"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["model-value", "disabled", "multiple"]))
}