<template>
    <div class="amlv3-async-status">

        <div class="amlv3-async-status-text" v-if="progress.isWorking">
            {{ `${progress.statusText}...` }}
        </div>

        <div class="amlv3-async-status-error" v-if="progress.isError">
            {{ progress.errorText }}
        </div>

    </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

import { AsyncProgress } from "@/types/ui_types";

export default defineComponent({
    name: "AsyncStatus",
    props: {
        progress: { type: Object as PropType<AsyncProgress>, required: true },
    },
});
</script>
<style scoped>
.amlv3-async-status {
    width: 100%;
    padding-bottom: 10px;
    text-align: right;
    font-size: 14px;
}

.amlv3-async-status-text {
    color: #444444;
}

.amlv3-async-status-error {
    color: #B62537;
}
</style>
