<template>
    <div class="group flex items-center min-h-[5.5rem] py-5 pr-5 pl-3 gap-2 box-border cursor-pointer bg-white hover:bg-background">
        <div class="h-full border-r border-background pr-1">
            <Icon class="w-12 h-12 sm:w-12 sm:h-12 rounded-full p-2" icon="blueBacgroundPerson" />
        </div>
        <div class="min-w-0 pl-1 sm:pl-2 w-full">
            <div class="flex items-center truncate">
                <div class="min-w-0 space-y-3">
                    <div class="leading-5 text-gray-700 text-base">
                        {{ details?.givenName + " " + details?.familyName }}
                    </div>
                    <div
                        class="flex items-center gap-x-1 text-xs truncate min-w-0 leading-4 text-light-gray group-hover:text-gray-700 whitespace-nowrap"
                    >
                        <span class="truncate min-w-0">{{ details?.name }}</span>
                        <div v-for="statusPill in statusPills" :key="statusPill.title">
                            <StatusTag
                                :status="statusPill.title"
                                :status-color="statusPill.statusColor"
                                :show-icon="statusPill.showIcon"
                                :icon="statusPill.icon"
                                :border-color="statusPill.borderColor"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { format, isEqual } from "date-fns";
import { PropType, computed, defineComponent, ref } from "vue";
import Icon from "../components/Icon.vue";
import StatusTag from "@/components/StatusTag.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
    name: "PersonCard",
    components: {
        Icon,
        StatusTag,
    },
    props: {
        details: {} as PropType<any>,
    },
    setup(props) {
        const { t } = useI18n();
        const isCurrentMonth = computed(() => {
            if (props.details?.createdAt.getMonth() === new Date().getMonth()) {
                return true;
            }
            return false;
        });

        const isToday = computed(() => {
            return props.details?.createdAt
                ? isEqual(format(props.details?.createdAt, "yyyy-MM-dd"), format(new Date(), "yyyy-MM-dd"))
                : false;
        });

        const statusPills = computed(() => {
            const statusPills = [];
            const screeningStatus = props.details?.screeningStatus;
            if (screeningStatus && screeningStatus?.monitored) {
                statusPills.push({
                    title: t("persons.personCard.monitorStatus.on"),
                    statusColor: "primary",
                    borderColor: "primary",
                });
            } else {
                statusPills.push({
                    title: t("persons.personCard.monitorStatus.off"),
                    statusColor: "gray-500",
                    borderColor: "gray-500",
                });
            }
            if (screeningStatus && screeningStatus?.pep === "hitfound") {
                statusPills.push({
                    title: t("screening.pep"),
                    statusColor: "red-700",
                    borderColor: "red-700",
                });
            }
            if (screeningStatus && screeningStatus?.sip === "hitfound") {
                statusPills.push({
                    title: t("screening.sip"),
                    statusColor: "red-700",
                    borderColor: "red-700",
                });
            }

            if (screeningStatus && screeningStatus?.rca === "hitfound") {
                statusPills.push({
                    title: t("screening.rca"),
                    statusColor: "red-700",
                    borderColor: "red-700",
                });
            }
            if (screeningStatus && screeningStatus?.newHit) {
                statusPills.push({
                    title: t("common.actions.new"),
                    icon: "new-hit",
                    showIcon: true,
                    statusColor: "red-700",
                    borderColor: "red-700",
                });
            }
            return statusPills;
        });
        return {
            isCurrentMonth,
            isToday,
            statusPills,
        };
    },
});
</script>
<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease;
}
.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
