import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PopupDialogSplit = _resolveComponent("PopupDialogSplit")!
  const _component_PopupDialog = _resolveComponent("PopupDialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_PopupDialog, {
      "is-visible": _ctx.dialog.visible,
      header: _ctx.$t('project.forms.actor.title'),
      icon: 'clipboard-list',
      "show-cancel-button": true,
      progress: _ctx.dialog,
      onCloseModal: _ctx.onClose,
      onAction: _ctx.copyPastedName
    }, {
      default: _withCtx(() => [
        _createVNode(_component_PopupDialogSplit)
      ]),
      _: 1
    }, 8, ["is-visible", "header", "progress", "onCloseModal", "onAction"])
  ]))
}