import { ApiResponse, parseLocalError, parseRemoteError } from "../error_parser";
import { Verified } from "../verified_plugin";
import { PossibleInput } from "./forms";

export const getPossibleInputs = async (templateId: string): Promise<{ possibleInputs: PossibleInput[], responseInfo: ApiResponse }> => {
    try {
        const response = await fetch(`/api/aml/v1/formtemplates/${templateId}/possiblevalues`, {
            method: "GET",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
        });

        const body = await response.json();

        if (!response.ok) {
            return {
                possibleInputs: [],
                responseInfo: { isError: true, parsedError: parseRemoteError(body) },
            };
        }

        return {
            possibleInputs: body,
            responseInfo: { isError: false, parsedError: null },
        };
    } catch (error) {
        return {
            possibleInputs: [],
            responseInfo: { isError: true, parsedError: parseLocalError(error) },
        };
    }
};