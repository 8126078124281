<template>
    <PopupDialog
        :is-visible="dialog.visible"
        :header="name"
        :icon="'clipboard-list'"
        :progress="dialog"
        :show-cancel-button="true"
        :cancel-button-text="$t('project.tabs.persons.screeningHitsDialog.closeBtn')"
        :show-action-button="false"
        :action-icon="'repeat'"
        :is-action-disabled="true"
        :action-text="$t('project.tabs.persons.screeningHitsDialog.updateFalsePositivesBtn')"
        :is-warning="false"
        @close-modal="close"
        @action="updateAssignee"
    >
        <div class="overflow-y-scroll w-[700px] h-[70vh] break-all pr-1">
            <div v-if="loading">
                Loading...
            </div>

            <div v-if="!loading">

                <div class="border rounded border-light border-solid text-sm">
                    <div class="m-2">
                        <div class="text-sm w-full font-bold mb-2">
                            {{ $t('project.tabs.persons.screeningHitsDialog.summaryTitle') }}
                        </div>
                        <div class="flex gap-1 justify-start items-center text-[12px]">
                            <!-- <div>{{ $t('screening.result') }}</div> -->
                            <div v-if="person.screeningStatus?.pep == 'hitfound'" class="border rounded-full p-1 px-3 bg-[#F2CA61]">
                                {{ $t('project.tabs.persons.screeningHitsDialog.pepLong') }}
                            </div>
                            <div v-if="person.screeningStatus?.sanction == 'hitfound'" class="border rounded-full p-1 px-3 bg-[#F2CA61]">
                                {{ $t('project.tabs.persons.screeningHitsDialog.sanctionsLong') }}
                            </div>
                            <div v-if="person.screeningStatus?.rca == 'hitfound'" class="border rounded-full p-1 px-3 bg-[#F2CA61]">
                                {{ $t('project.tabs.persons.screeningHitsDialog.rcaLong') }}
                            </div>
                            <div v-if="person.screeningStatus?.sip == 'hitfound'" class="border rounded-full p-1 px-3 bg-[#F2CA61]">
                                {{ $t('project.tabs.persons.screeningHitsDialog.sipLong') }}
                            </div>
                            <div v-if="person.screeningStatus?.pep != 'hitfound' && person.screeningStatus?.sanction != 'hitfound' && person.screeningStatus?.rca != 'hitfound' && person.screeningStatus?.sip != 'hitfound'" class="border rounded-full p-1 px-3 bg-green-300">
                                {{ $t('project.tabs.persons.screeningHitsDialog.noFlags') }}
                            </div>
                        </div>

                    </div>
                </div>
                <div class="border rounded border-light border-solid text-sm mt-2">
                    <div class="m-2">
                        <div class="border-t border-gray-300">
                            <div class="text-sm font-bold w-full mb-2">
                                {{ $t('project.tabs.persons.screeningHitsDialog.hitsTitle') }}
                            </div>
                            <div v-if="hits.length" class="flex flex-col gap-2">
                                <ScreeningHit
                                    v-for="(hit, index) in hits"
                                    :key="index"
                                    :hit="hit"
                                    :false-positive="hit.falsePositive"
                                />
                            </div>
                            <div v-if="!hits.length" class="flex flex-col gap-2">
                                {{ $t('project.tabs.persons.screeningHitsDialog.noHits') }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </PopupDialog>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

import { Project } from "@/lib/projects/projects";
import { UpdateProjectOptions, updateProject } from "@/lib/projects/update_project";
import CustomerTopAction from "./CustomerTopAction.vue";
import PopupDialog from "@/ui/PopupDialog.vue";
import PopupDialogPrompt from "@/ui/dialog/PopupDialogPrompt.vue";
import SimpleListItem from "@/ui/SimpleListItem.vue";
import ScreeningHit from "@/components/customer/ScreeningHit.vue";
import { getProjectProcess } from "@/lib/projects/get_project";
import { getPersonScreeningHits } from "@/lib/screening/screen_person";
import { Person } from "@/lib/persons/person";

export default defineComponent({
    name: "PersonScreeningHitsDialog",
    components: {
        CustomerTopAction,
        PopupDialog,
        PopupDialogPrompt,
        SimpleListItem,
        ScreeningHit,
    },
    emits: ["on-close"],
    props: {
        person: { type: Object as PropType<Person>, required: true },
    },
    computed: {
        name(): string {
            return this.person?.data?.person_info?.givenName + " " + this.person?.data?.person_info?.familyName;
        },
    },
    data() {
        return {
            loading: false,
            dialog: {
                visible: true,
                isWorking: false,
                statusText: "",
                isError: false,
                errorText: "wefw",
            },
            hits: [] as any[],
        };
    },
    methods: {
        showDialog() {
            this.dialog.visible = true;
        },

        close() {
            this.dialog.visible = false;
            this.$emit("on-close");
        },

        selectAssignee(assignee: string) {

        },

        async updateAssignee() {

        },
    },
    async mounted() {
        try {
            this.loading = true;
            const result = await getPersonScreeningHits(this.person.id);
            this.hits = result.hits;
        } catch (err) {
            this.dialog.isError = true;
            this.dialog.errorText = this.$t("project.tabs.persons.screeningHitsDialog.fetchError");
        } finally {
            this.loading = false;
        }
    },
});
</script>
