<template>
    <PopupDialogSplit>
        <template #top>

            <div class="amlv3-pop-warning-prompt" v-if="!isLoading && canProceed">
                {{ promptText }}
            </div>

            <div class="amlv3-pop-warning-prompt" v-if="!isLoading && !canProceed">
                {{ errorText }}
            </div>

            <LoadAnimationBar :height="20" v-if="isLoading" />

        </template>

        <LoadAnimationBar :height="20" v-if="isLoading" />

        <div class="amlv3-pop-warning-hint" v-if="!isLoading && canProceed">
            <div
                class="amlv3-pop-warning-hint-icon"
                :class="[
                    { 'amlv3-pop-warning-hint-icon-warning': isWarning },
                ]"
            >
                {{ '!' }}
            </div>
            <div class="amlv3-pop-warning-hint-text">
                {{ infoText || $t('common.actions.notReversableHint') }}
            </div>
        </div>

        <div class="amlv3-pop-warning-hint" v-if="!isLoading && !canProceed">
            <div
                class="amlv3-pop-warning-hint-icon"
                :class="[
                    { 'amlv3-pop-warning-hint-icon-warning': isWarning },
                ]"
            >
                {{ '!' }}
            </div>
            <div class="amlv3-pop-warning-hint-text">
                {{ errorReason }}
            </div>
        </div>

    </PopupDialogSplit>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import LoadAnimationBar from "../LoadAnimationBar.vue";
import PopupDialogSplit from "./PopupDialogSplit.vue";

export default defineComponent({
    name: "PopupDialogPrompt",
    components: {
        PopupDialogSplit,
        LoadAnimationBar,
    },
    props: {
        promptText: { type: String as PropType<string>, required: true },
        isWarning: { type: Boolean as PropType<boolean>, default: false },
        isLoading: { type: Boolean as PropType<boolean>, default: false },
        canProceed: { type: Boolean as PropType<boolean>, default: true },
        errorText: { type: String as PropType<string>, default: "" },
        errorReason: { type: String as PropType<string>, default: "" },
        infoText: { type: String as PropType<string>, default: "" },
    },
});
</script>
<style>
.amlv3-pop-warning-prompt {
    font-size: 14px;
    font-weight: 600;
}

.amlv3-pop-warning-hint {
    display: flex;
}

.amlv3-pop-warning-hint-icon {
    width: 16px;
    height: 16px;
    margin-top: 2px;
    color: white;
    text-align: center;
    font-size: 11px;
    font-weight: bold;
    border-radius: 50%;
    background-color: #218BCB;
}

.amlv3-pop-warning-hint-icon-warning {
    background-color: #B62537;
}

.amlv3-pop-warning-hint-text {
    padding-left: 10px;
    font-size: 14px;
    font-weight: 400;
}
</style>
