import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-04ce23f8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "amlv3-async-status" }
const _hoisted_2 = {
  key: 0,
  class: "amlv3-async-status-text"
}
const _hoisted_3 = {
  key: 1,
  class: "amlv3-async-status-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.progress.isWorking && _ctx.progress.statusText)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(`${_ctx.progress.statusText}...`), 1))
      : _createCommentVNode("", true),
    (_ctx.progress.isError)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.progress.errorText), 1))
      : _createCommentVNode("", true)
  ]))
}