import { Form } from "@/lib/forms/forms";
import { getFormFile } from "@/lib/forms/get_file";

export enum PdfViewType {
    Form = "form",
}

export enum PdfViewFileType {
    Form = "form",
}

export interface PdfViewFile {
    id: string;
    type: PdfViewFileType;
    name: string;
    param1?: string;
    param2?: string;
    file?: File | Blob;
    attachments: PdfViewFile[];
}

export interface PdfViewParams {
    type: PdfViewType;
    files: PdfViewFile[];
    initialId?: string;
}

export const getFormFileViewParams = (form: Form, fileId: string): PdfViewParams => {
    const params: PdfViewParams = {
        type: PdfViewType.Form,
        files: [],
        initialId: fileId,
    };

    for (const document of form.documents) {
        const documentFile: PdfViewFile = {
            id: document.id,
            type: PdfViewFileType.Form,
            name: document.name,
            param1: form.id,
            param2: document.id,
            attachments: [],
        };

        for (const attachment of document.attachments) {
            const attachmentFile: PdfViewFile = {
                id: attachment.id,
                type: PdfViewFileType.Form,
                name: attachment.name,
                param1: form.id,
                param2: attachment.id,
                attachments: [],
            };
            documentFile.attachments.push(attachmentFile);
        }

        params.files.push(documentFile);
    }

    return params;
};

export const getFile = async (file: PdfViewFile): Promise<Blob | null> => {
    const formId = file.param1;
    const fileId = file.param2;

    if (!formId || !fileId) return null;

    const response = await getFormFile(formId, fileId);

    console.log("response", response);

    if (response.file) return response.file;
    return null;
};
