<template>
    <div>

        <PopupDialog
            :is-visible="dialog.visible"
            :header="$t('project.forms.actor.title')"
            :icon="'clipboard-list'"
            :show-cancel-button="true"
            :progress="dialog"
            @close-modal="onClose"
            @action="copyPastedName"
        >
            <PopupDialogSplit />
        </PopupDialog>

    </div>
</template>
<script lang="ts">
import { PropType, defineComponent } from "vue";

import { Form, FormActor } from "@/lib/forms/forms";

import PopupDialog from "@/ui/PopupDialog.vue";
import PopupDialogSplit from "@/ui/dialog/PopupDialogSplit.vue";

export default defineComponent({
    name: "FormActorInfoDialog",
    components: {
        PopupDialog,
        PopupDialogSplit,
    },
    emits: ["close-modal", "promoted"],
    props: {
        form: { type: Object as PropType<Form>, required: true },
        actor: { type: Object as PropType<FormActor>, required: true },
    },
    data() {
        return {
            dialog: {
                visible: true,
                valid: true,
                isWorking: false,
                statusText: "",
                isError: false,
                errorText: "",
            },
        };
    },
    methods: {

        onClose() {
            this.$emit("close-modal");
        },

        async copyPastedName() {
            this.dialog.isWorking = true;
            this.dialog.statusText = "Doing something";

            await new Promise((r) => setTimeout(r, 2000));

            this.dialog.isWorking = false;
        },

    },
});
</script>
