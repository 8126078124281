import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-[386px]" }
const _hoisted_2 = {
  class: "bg-yellow-100 mb-3 border border-yellow-400 text-yellow-700 text-xs px-4 py-3 rounded relative max-w-[500px]",
  role: "alert"
}
const _hoisted_3 = { class: "font-bold mr-2" }
const _hoisted_4 = { class: "block sm:inline" }
const _hoisted_5 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputLabel = _resolveComponent("InputLabel")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_InputGroup = _resolveComponent("InputGroup")!
  const _component_CountryDropdown = _resolveComponent("CountryDropdown")!
  const _component_PopupDialog = _resolveComponent("PopupDialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showDialog && _ctx.showDialog(...args))),
      class: "amlv3-customer-header-info hover:cursor-pointer"
    }, _toDisplayString('ORG # ' + _ctx.project.identity), 1),
    _createVNode(_component_PopupDialog, {
      "is-visible": _ctx.dialog.visible,
      header: _ctx.$t('project.identity.title'),
      icon: 'building',
      progress: _ctx.dialog,
      "show-cancel-button": true,
      "show-action-button": true,
      "action-icon": 'repeat',
      "is-action-disabled": _ctx.identity == _ctx.project.identity && _ctx.country == _ctx.project.country,
      "action-text": _ctx.$t('project.identity.update'),
      "is-warning": false,
      onCloseModal: _cache[3] || (_cache[3] = ($event: any) => (_ctx.dialog.visible = false)),
      onAction: _ctx.updateIdentity
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("strong", _hoisted_3, _toDisplayString(_ctx.$t('project.identity.warningTitle')), 1),
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('project.identity.warningText')), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_InputGroup, { width: 6 }, {
              default: _withCtx(() => [
                _createVNode(_component_InputLabel, {
                  text: _ctx.$t('project.identity.orgNr'),
                  "unique-id": 'project-identity'
                }, null, 8, ["text"]),
                _createVNode(_component_InputText, {
                  modelValue: _ctx.identity,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.identity) = $event)),
                  "unique-id": 'project-identity'
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_InputGroup, { width: 6 }, {
              default: _withCtx(() => [
                _createVNode(_component_InputLabel, {
                  text: _ctx.$t('project.identity.country'),
                  "unique-id": 'project-country'
                }, null, 8, ["text"]),
                _createVNode(_component_CountryDropdown, {
                  modelValue: _ctx.country,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.country) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ])
        ])
      ]),
      _: 1
    }, 8, ["is-visible", "header", "progress", "is-action-disabled", "action-text", "onAction"])
  ]))
}