<template>
    <div class="amlv3-pop-warning-hint">
        <div
            class="amlv3-pop-warning-hint-icon"
            :class="[
                { 'amlv3-pop-warning-hint-icon-warning': isWarning },
            ]"
        >
            {{ '!' }}
        </div>
        <div class="amlv3-pop-warning-hint-text">
            {{ text }}
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
    name: "PopupDialogInfoIconAndText",
    props: {
        text: { type: String as PropType<string>, required: true },
        isWarning: { type: Boolean as PropType<boolean>, default: false },
    },
});
</script>
<style>
.amlv3-pop-warning-prompt {
    font-size: 14px;
    font-weight: 600;
}

.amlv3-pop-warning-hint {
    display: flex;
}

.amlv3-pop-warning-hint-icon {
    width: 16px;
    height: 16px;
    margin-top: 2px;
    color: white;
    text-align: center;
    font-size: 11px;
    font-weight: bold;
    border-radius: 50%;
    background-color: #218BCB;
}

.amlv3-pop-warning-hint-icon-warning {
    background-color: #B62537;
}

.amlv3-pop-warning-hint-text {
    padding-left: 10px;
    font-size: 14px;
    font-weight: 400;
}
</style>
