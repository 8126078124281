<template>
    <ExpandableListLayout
        :can-expand="false"
        :expanded="sealedAssessments.length === 0"
        :button-text="$t('assessment.latest.showAll')"
        @clicked="$emit('menu-tab', 'assessments')"
    >
        <template #top>

            {{ $t('assessment.latest.title') }}

        </template>
        <template #expanded>
            <div style="padding: 10px 30px; font-size: 14px; color: gray;">
                {{ $t('assessment.latest.empty') }}
            </div>
        </template>
        <template #collapsed>
            <div class="amlv3-asslist-items">
                <div
                    v-for="(assessment, i) in sealedAssessments"
                    :key="'pa' + i"
                    class="amlv3-asslist-item"
                >
                    <div class="amlv3-asslist-item-icon">
                        {{ getRiskInitial(assessment) }}
                    </div>

                    <div class="amlv3-asslist-item-text">

                        <div class="amlv3-asslist-item-name">
                            {{ getAssessmentName(assessment) }}
                        </div>

                        <MenuInfoLine
                            :info1="getRiskText(assessment)"
                            :info2="assessment.sealedBy?.name"
                        />

                    </div>

                    <div class="amlv3-asslist-item-actions">
                        <div
                            v-if="assessment.sealed"
                            class="amlv3-asslist-item-action"
                            @click="() => handleDownloadClick(project?.id || '', assessment.id)"
                        >
                            <FontIcon :icon="isLoading ? 'fa-spinner' : 'file-pdf'" :size="18" />
                        </div>
                    </div>

                </div>
            </div>
        </template>
    </ExpandableListLayout>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

import { Assessment } from "@/lib/assessments/assessments";

import FontIcon from "@/ui/FontIcon.vue";
import ExpandableListLayout from "../customer/menu/ExpandableListLayout.vue";
import { loadAssessments } from "@/lib/assessments/assessment_helper";
import { getPossibleRiskThresholds } from "@/views/risk/risk_helper";
import { RiskThreshold } from "@/lib/settings/settings";
import MenuInfoLine from "../customer/menu/MenuInfoLine.vue";
import { Project } from "@/lib/projects/projects";
import { getAssesmentReport } from "@/lib/assessments/seal_assessment";

export default defineComponent({
    name: "PreviousAssessments",
    components: {
        FontIcon,
        ExpandableListLayout,
        MenuInfoLine,
    },
    emits: ["menu-tab"],
    props: {
        assessments: { type: Array as PropType<Assessment[]>, required: true },
        project: { type: Object as PropType<Project>, required: false, default: ({}) },
    },
    data() {
        return {
            sealedAssessments: new Array<Assessment>(),
            thresholds: new Array<RiskThreshold>(),
            isLoading: false,
        };
    },
    watch: {
        assessments: {
            deep: true,
            handler() {
                this.loadAssessments();
            },
        },
    },
    methods: {

        getAssessmentName(assessment: Assessment): string {
            if (assessment.lockedAt) {
                return new Date(assessment.lockedAt).toLocaleDateString();
            }
            return "not locked yet";
        },

        getRiskText(assessment: Assessment): string {
            for (const threshold of this.thresholds) {
                if (threshold.key === assessment.risk) return this.$ct(threshold.text);
            }
            return "Unknown Risk";
        },

        getRiskInitial(assessment: Assessment): string {
            let str = "";
            for (const threshold of this.thresholds) {
                if (threshold.key === assessment.risk) str = this.$ct(threshold.text);
            }
            return str ? str.substring(0, 1).toUpperCase() : "X";
        },

        loadAssessments() {
            const loadResult = loadAssessments(this.assessments);
            this.sealedAssessments = loadResult.sealedAssessments;
        },
        handleDownloadClick(projectId: string, assesmentId: string) {
            this.isLoading = true;
            this.downloadReport(projectId, assesmentId).finally(() => {
                this.isLoading = false;
            });
        },

        async downloadReport(projectId: string, assessmentId: string) {
            const { url, responseInfo } = await getAssesmentReport(projectId, assessmentId);
            if (!responseInfo.isError && url) {
                const link = document.createElement("a");
                link.href = url;
                link.download = `Assessment_${assessmentId}.pdf`;
                link.setAttribute("target", "_blank");
                link.setAttribute("rel", "noopener noreferrer");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        },

    },
    mounted() {
        this.thresholds = getPossibleRiskThresholds(this.$config.settings);
        this.loadAssessments();
    },
});
</script>
<style scoped>
.amlv3-asslist-items {
    padding: 0px 20px;
    padding-bottom: 5px;
}

.amlv3-asslist-item {
    display: flex;
    border-bottom: solid 1px lightgray;
}

.amlv3-asslist-item-icon {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    margin-top: 10px;
    padding-top: 3px;
    text-align: center;
    font-family: "Roboto Slab";
    font-size: 12px;
    font-weight: 600;
    border-radius: 50%;
    background-color: lightgray;
}

.amlv3-asslist-item-text {
    flex-grow: 1;
    padding: 4px 10px;
}

.amlv3-asslist-item-name {
    font-size: 15px;
    font-weight: 500;
}

.amlv3-asslist-item-actions {
    flex-shrink: 0;
    padding-top: 11px;
    display: flex;
}

.amlv3-asslist-item-action {
    color: dimgray;
    cursor: pointer;
}
.amlv3-asslist-item-action:hover {
    opacity: 0.7;
}
</style>
