import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withModifiers as _withModifiers, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-270cee74"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"position":"relative"} }
const _hoisted_2 = ["id"]
const _hoisted_3 = {
  key: 0,
  class: "amlv3-formitem-active-icon"
}
const _hoisted_4 = { class: "amlv3-formitem-top-left" }
const _hoisted_5 = { class: "amlv3-formitem-name" }
const _hoisted_6 = {
  key: 0,
  class: "amlv3-formitem-menu"
}
const _hoisted_7 = {
  key: 0,
  class: "amlv3-formitem-content"
}
const _hoisted_8 = { class: "amlv3-formitem-info" }
const _hoisted_9 = { class: "amlv3-formitem-actors-header" }
const _hoisted_10 = { class: "amlv3-formitem-actors" }
const _hoisted_11 = { class: "amlv3-formitem-actor-name" }
const _hoisted_12 = { class: "amlv3-formitem-actor-status" }
const _hoisted_13 = { class: "amlv3-formitem-actor-status-text" }
const _hoisted_14 = { class: "amlv3-formitem-actor-role" }
const _hoisted_15 = { class: "amlv3-formitem-actor-actions" }
const _hoisted_16 = ["onClick"]
const _hoisted_17 = ["onClick"]
const _hoisted_18 = ["onClick"]
const _hoisted_19 = { class: "amlv3-formitem-actors-header" }
const _hoisted_20 = { class: "amlv3-formitem-document" }
const _hoisted_21 = { class: "amlv3-formitem-document-name" }
const _hoisted_22 = ["onClick"]
const _hoisted_23 = { class: "amlv3-formitem-attachments" }
const _hoisted_24 = { class: "amlv3-formitem-attachment-icon" }
const _hoisted_25 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontIcon = _resolveComponent("FontIcon")!
  const _component_StatusAndText = _resolveComponent("StatusAndText")!
  const _component_FormLinkDialog = _resolveComponent("FormLinkDialog")!
  const _component_FormArchiveDialog = _resolveComponent("FormArchiveDialog")!
  const _component_FormReminderDialog = _resolveComponent("FormReminderDialog")!
  const _component_FormActorInfoDialog = _resolveComponent("FormActorInfoDialog")!
  const _component_FormSelectDialog = _resolveComponent("FormSelectDialog")!
  const _component_PdfViewPopup = _resolveComponent("PdfViewPopup")!
  const _directive_click_away = _resolveDirective("click-away")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      id: 'form-' + _ctx.form.id,
      class: _normalizeClass(["amlv3-formitem-top", [
                {'amlv3-formitem-top-collapsed': !_ctx.expanded },
            ]]),
      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.expanded = !_ctx.expanded))
    }, [
      (_ctx.form.isSelected)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_FontIcon, {
              icon: 'check-double',
              size: 18
            })
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.form.name), 1),
        _createVNode(_component_StatusAndText, {
          text: _ctx.getStatusText(),
          "is-completed": _ctx.form.formStatus === 'completed'
        }, null, 8, ["text", "is-completed"])
      ]),
      _createElementVNode("div", {
        class: "amlv3-formitem-top-menu-btn",
        onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.menuOpen = !_ctx.menuOpen), ["stop"]))
      }, [
        _createVNode(_component_FontIcon, {
          icon: 'ellipsis',
          style: {"margin-top":"-4px"}
        }),
        (_ctx.menuOpen)
          ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_6, [
              (!_ctx.form.isSelected || true == true)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "amlv3-formitem-menu-item",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectDialog.visible = true))
                  }, _toDisplayString(_ctx.$t('project.forms.actions.makeActive')), 1))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                class: "amlv3-formitem-menu-item",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.showArchiveDialog && _ctx.showArchiveDialog(...args)))
              }, _toDisplayString(_ctx.$t('project.forms.actions.archive')), 1),
              _createElementVNode("div", {
                class: "amlv3-formitem-menu-item",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('delete')))
              }, _toDisplayString(_ctx.$t('project.forms.actions.delete')), 1)
            ])), [
              [_directive_click_away, _ctx.hideMenu]
            ])
          : _createCommentVNode("", true)
      ])
    ], 10, _hoisted_2),
    (_ctx.expanded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('project.forms.details.date') + ' ' + new Date(_ctx.form.createdAt).toLocaleDateString()), 1),
          _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('project.forms.details.formActors')), 1),
          _createElementVNode("div", _hoisted_10, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.form.actors, (actor, i) => {
              return (_openBlock(), _createElementBlock("div", {
                key: 'fae' + i,
                class: _normalizeClass(["amlv3-formitem-actor", [
                        {'amlv3-formitem-actor-first': i === 0 },
                        {'amlv3-formitem-actor-last': i === _ctx.form.actors.length - 1 },
                    ]])
              }, [
                _createElementVNode("div", _hoisted_11, _toDisplayString(actor.firstName + ' ' + actor.lastName), 1),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["amlv3-formitem-actor-status-icon", [
                                {'amlv3-formitem-actor-status-icon-completed': actor.completed },
                            ]])
                  }, [
                    _createVNode(_component_FontIcon, {
                      icon: actor.completed ? 'check' : 'hourglass-half',
                      size: 14
                    }, null, 8, ["icon"])
                  ], 2),
                  _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.getActorStatusText(actor)), 1)
                ]),
                _createElementVNode("div", _hoisted_14, _toDisplayString(actor.role), 1),
                _createElementVNode("div", _hoisted_15, [
                  (_ctx.iAmTheCurrentActor())
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: "amlv3-formitem-actor-action",
                        onClick: ($event: any) => (_ctx.showLinkDialog(actor))
                      }, [
                        _createVNode(_component_FontIcon, {
                          icon: 'file-import',
                          size: 14
                        })
                      ], 8, _hoisted_16))
                    : _createCommentVNode("", true),
                  (!actor.completed && !_ctx.iAmTheCurrentActor())
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: "amlv3-formitem-actor-action",
                        onClick: ($event: any) => (_ctx.showReminderDialog(actor))
                      }, [
                        _createVNode(_component_FontIcon, {
                          icon: 'bell',
                          size: 14
                        })
                      ], 8, _hoisted_17))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", {
                    class: "amlv3-formitem-actor-action",
                    onClick: ($event: any) => (_ctx.showInfoDialog(actor))
                  }, [
                    _createVNode(_component_FontIcon, {
                      icon: actor.completed ? 'circle-info' : 'pen',
                      size: 14
                    }, null, 8, ["icon"])
                  ], 8, _hoisted_18)
                ])
              ], 2))
            }), 128))
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.form.documents, (document, i) => {
            return (_openBlock(), _createElementBlock("div", {
              key: 'flid' + i
            }, [
              _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$t('project.forms.details.document')), 1),
              _createElementVNode("div", _hoisted_20, [
                _createElementVNode("div", _hoisted_21, _toDisplayString(document.name), 1),
                _createElementVNode("div", {
                  class: "amlv3-formitem-document-action amlv3-formitem-document-action-end",
                  onClick: ($event: any) => (_ctx.showPdfPopup(document.id))
                }, [
                  _createVNode(_component_FontIcon, {
                    icon: 'arrow-up-right-from-square',
                    size: 14
                  })
                ], 8, _hoisted_22)
              ]),
              _createElementVNode("div", _hoisted_23, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(document.attachments, (attachment, j) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: 'flid' + i + j,
                    class: "amlv3-formitem-attachment"
                  }, [
                    _createElementVNode("div", _hoisted_24, [
                      _createVNode(_component_FontIcon, {
                        icon: 'paperclip',
                        size: 14
                      })
                    ]),
                    _createElementVNode("div", {
                      class: _normalizeClass(["amlv3-formitem-attachment-name", [
                                {'amlv3-formitem-attachment-name-end': j === document.attachments.length - 1 },
                            ]])
                    }, _toDisplayString(attachment.name), 3),
                    _createElementVNode("div", {
                      class: _normalizeClass(["amlv3-formitem-attachment-action", [
                                {'amlv3-formitem-attachment-action-end': j === document.attachments.length - 1 },
                            ]]),
                      onClick: ($event: any) => (_ctx.showPdfPopup(attachment.id))
                    }, [
                      _createVNode(_component_FontIcon, {
                        icon: 'arrow-up-right-from-square',
                        size: 14
                      })
                    ], 10, _hoisted_25)
                  ]))
                }), 128))
              ])
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.initiallyOpen)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "amlv3-formitem-highlight",
          style: _normalizeStyle(`opacity: ${_ctx.highlightOpacity ? '1' : '0'};`)
        }, null, 4))
      : _createCommentVNode("", true),
    (_ctx.linkDialog.visible)
      ? (_openBlock(), _createBlock(_component_FormLinkDialog, {
          key: 2,
          action: _ctx.linkDialog.action,
          project: _ctx.project,
          forms: [_ctx.form],
          "form-id": _ctx.form.id,
          "actor-id": _ctx.linkDialog.actor.id,
          onCloseModal: _cache[5] || (_cache[5] = ($event: any) => (_ctx.linkDialog.visible = false)),
          onRequestProjectRefresh: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('request-project-refresh', $event))),
          onOpen: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('open', $event)))
        }, null, 8, ["action", "project", "forms", "form-id", "actor-id"]))
      : _createCommentVNode("", true),
    (_ctx.archiveDialog.visible)
      ? (_openBlock(), _createBlock(_component_FormArchiveDialog, {
          key: 3,
          action: _ctx.archiveDialog.action,
          project: _ctx.project,
          forms: [_ctx.form],
          "form-id": _ctx.form.id,
          onCloseModal: _cache[8] || (_cache[8] = ($event: any) => (_ctx.archiveDialog.visible = false)),
          onRequestProjectRefresh: _cache[9] || (_cache[9] = ($event: any) => (_ctx.$emit('request-project-refresh', $event)))
        }, null, 8, ["action", "project", "forms", "form-id"]))
      : _createCommentVNode("", true),
    (_ctx.reminderDialog.visible)
      ? (_openBlock(), _createBlock(_component_FormReminderDialog, {
          key: 4,
          form: _ctx.form,
          actor: _ctx.reminderDialog.actor,
          onCloseModal: _cache[10] || (_cache[10] = ($event: any) => (_ctx.reminderDialog.visible = false)),
          onRequestProjectRefresh: _cache[11] || (_cache[11] = ($event: any) => (_ctx.$emit('request-project-refresh', $event)))
        }, null, 8, ["form", "actor"]))
      : _createCommentVNode("", true),
    (_ctx.infoDialog.visible)
      ? (_openBlock(), _createBlock(_component_FormActorInfoDialog, {
          key: 5,
          form: _ctx.form,
          actor: _ctx.infoDialog.actor,
          onCloseModal: _cache[12] || (_cache[12] = ($event: any) => (_ctx.infoDialog.visible = false)),
          onRequestProjectRefresh: _cache[13] || (_cache[13] = ($event: any) => (_ctx.$emit('request-project-refresh', $event)))
        }, null, 8, ["form", "actor"]))
      : _createCommentVNode("", true),
    (_ctx.selectDialog.visible)
      ? (_openBlock(), _createBlock(_component_FormSelectDialog, {
          key: 6,
          form: _ctx.form,
          onCloseModal: _cache[14] || (_cache[14] = ($event: any) => (_ctx.selectDialog.visible = false)),
          onRequestProjectRefresh: _cache[15] || (_cache[15] = ($event: any) => (_ctx.$emit('request-project-refresh', $event)))
        }, null, 8, ["form"]))
      : _createCommentVNode("", true),
    (_ctx.pdfPopup.visible)
      ? (_openBlock(), _createBlock(_component_PdfViewPopup, {
          key: 7,
          params: _ctx.pdfPopup.params,
          onCloseModal: _cache[16] || (_cache[16] = ($event: any) => (_ctx.pdfPopup.visible = false))
        }, null, 8, ["params"]))
      : _createCommentVNode("", true)
  ]))
}