<template>
    <div class="flex items-center gap-8">
        <p class="text-body">
            <span v-if="isVisible">{{ text }}</span>
            <span v-else>{{ hiddenText }}</span>
        </p>
        <Icon class="w-4 h-4" @click.stop="toggleVisibility" icon="eye"></Icon>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Icon from "./Icon.vue";

export default defineComponent({
    name: "SecretText",
    components: {
        Icon,
    },
    props: {
        text: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isVisible: false,
        };
    },
    computed: {
        hiddenText(): string {
            return "*".repeat(this.text.length);
        },
    },
    methods: {
        toggleVisibility(): void {
            this.isVisible = !this.isVisible;
        },
    },
});
</script>

<style scoped>
/* Additional styling can be added here if needed */
</style>
