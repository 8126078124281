import { Verified } from "../verified_plugin";
import { Person } from "./person";

export const updateNote = async (personId: string, noteId: string, dataToUpdate: { note?: string; isArchived?: boolean }) => {
    try {
        console.log(JSON.stringify({ noteId: noteId, note: dataToUpdate.note, isArchived: dataToUpdate.isArchived }));
        const response = await fetch(`/api/aml/v1/persons/${personId}/notes/${noteId}`, {
            method: "put",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
            body: JSON.stringify({
                note: dataToUpdate.note,
                isArchived: dataToUpdate.isArchived,
            }),
        });

        const body = await response.json();

        if (!response.ok) {
            throw new Error(body);
        }

        return body as Person;
    } catch (error) {
        console.log(error);
        throw error;
    }
};
