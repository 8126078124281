import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "fbl-button-row-left"
}
const _hoisted_2 = { class: "fbl-button-row-con" }
const _hoisted_3 = {
  key: 1,
  class: "fbl-button-row-right"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["fbl-button-row", [
            { 'fbl-button-row-l': _ctx.alignment === _ctx.ButtonAlignment.Left },
            { 'fbl-button-row-r': _ctx.alignment === _ctx.ButtonAlignment.Right },
            { 'fbl-button-row-np': _ctx.noPadding },
        ]])
  }, [
    (_ctx.alignment !== _ctx.ButtonAlignment.Left)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "left")
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    (_ctx.alignment !== _ctx.ButtonAlignment.Right)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "right")
        ]))
      : _createCommentVNode("", true)
  ], 2))
}