import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "min-h-[100px] border-slate-300 border rounded-lg p-2 flex flex-col justify-between" }
const _hoisted_2 = { class: "text-xl font-medium" }
const _hoisted_3 = { class: "text-sm text-slate-500" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.value), 1),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.label), 1)
  ]))
}