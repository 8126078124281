import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4f326d00"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "amlv3-customermenu-title" }
const _hoisted_2 = { class: "amlv3-customermenu-tabs" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { style: {"margin-top":"-2px","margin-left":"6px"} }
const _hoisted_5 = {
  key: 2,
  class: "amlv3-customermenu-tab-menu"
}
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontIcon = _resolveComponent("FontIcon")!
  const _directive_click_away = _resolveDirective("click-away")!

  return _withDirectives((_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.title), 1),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visibleTabs, (tab, i) => {
        return (_openBlock(), _createElementBlock("div", {
          key: 'tab' + i,
          class: _normalizeClass(["amlv3-customermenu-tab", [
                    { 'amlv3-customermenu-tab-active': _ctx.selectedTab == tab.key },
                ]]),
          onClick: ($event: any) => (_ctx.selectTab(tab))
        }, _toDisplayString(_ctx.$t(tab.textKey)), 11, _hoisted_3))
      }), 128)),
      (_ctx.showMenu)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "amlv3-customermenu-tab amlv3-customermenu-tab-m",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onMenuClicked && _ctx.onMenuClicked(...args)))
          }, [
            _createTextVNode(_toDisplayString(_ctx.$t('project.overviewMenu.tabs.menu')) + " ", 1),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_FontIcon, { icon: 'ellipsis' })
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.tabMenuOpen)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "amlv3-customermenu-tab-menu-bg",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.hideMenu && _ctx.hideMenu(...args)))
          }))
        : _createCommentVNode("", true),
      (_ctx.tabMenuOpen)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuTabs, (tab, i) => {
              return (_openBlock(), _createElementBlock("div", {
                key: 'mt' + i,
                class: "amlv3-customermenu-tab-menuitem",
                onClick: ($event: any) => (_ctx.selectTab(tab))
              }, _toDisplayString(_ctx.$t(tab.textKey)), 9, _hoisted_6))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ])
  ])), [
    [_directive_click_away, _ctx.hideMenu]
  ])
}