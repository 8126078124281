import { ApiResponse, parseLocalError, parseRemoteError } from "../error_parser";
import { Verified } from "../verified_plugin";
import { RiskMatrixDefinition } from "./risk_matrix";

export const getRiskMatrices = async (): Promise<{ matrices: RiskMatrixDefinition[] | null, responseInfo: ApiResponse }> => {
    try {
        const response = await fetch("/api/aml/v1/matrices", {
            method: "GET",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
        });
        const body = await response.json();
        if (!response.ok) {
            return {
                matrices: null,
                responseInfo: { isError: true, parsedError: parseRemoteError(body) },
            };
        }
        return {
            matrices: body.matrices as RiskMatrixDefinition[],
            responseInfo: { isError: false, parsedError: null },
        };
    } catch (error) {
        return {
            matrices: null,
            responseInfo: { isError: true, parsedError: parseLocalError(error) },
        };
    }
};

export const getRiskMatrix = async (matrixId: string): Promise<{ matrix: RiskMatrixDefinition | null, responseInfo: ApiResponse }> => {
    try {
        const response = await fetch(`/api/aml/v1/matrices/${matrixId}`, {
            method: "GET",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
        });
        const body = await response.json();
        if (!response.ok) {
            return {
                matrix: null,
                responseInfo: { isError: true, parsedError: parseRemoteError(body) },
            };
        }
        return {
            matrix: body as RiskMatrixDefinition,
            responseInfo: { isError: false, parsedError: null },
        };
    } catch (error) {
        return {
            matrix: null,
            responseInfo: { isError: true, parsedError: parseLocalError(error) },
        };
    }
};
