<template>
    <div class="amlv3-tabc">

        <div
            v-for="(tab) in tabs"
            :key="'tct' + tab.key"
            class="amlv3-tabc-tab"
            :class="[
                { 'amlv3-tabc-tab-active': tab.key === selectedTabKey },
            ]"
            @click="$emit('tab-changed', tab.key)"
        >

            {{ tab.text ? $t(tab.text) : tab.rawText || '' }}

        </div>

    </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

import { TabControlTab } from "@/types/ui_types";

export default defineComponent({
    name: "TabControl",
    emits: ["tab-changed"],
    props: {
        tabs: { type: Array as PropType<TabControlTab[]>, required: true },
        selectedTabKey: { type: String as PropType<string>, required: true },
    },
});
</script>
<style scoped lang="scss">
.amlv3-tabc {
    display: flex;
    margin-bottom: 10px;
}

.amlv3-tabc-tab {
    margin-right: 20px;
    font-size: 18px;
    font-weight: 400;
    color: theme('colors.primary.default');
    cursor: pointer;
}

.amlv3-tabc-tab-active {
    font-weight: 500;
    color: #222222;
}
</style>
