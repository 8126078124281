<template>
    <PopupDialog
        :is-visible="dialog.visible"
        :header="$t('assessment.new.title')"
        :icon="'rotate'"
        :show-cancel-button="true"
        :show-action-button="true"
        :action-text="$t('assessment.new.title')"
        :action-icon="'rotate'"
        :progress="dialog"
        @action="addNewAssessment"
        @close-modal="onClose"
    >

        <PopupDialogSplit
            style="min-width: 500px; max-width: 560px;"
            :prompt-text="$t('assessment.new.hint1')"
            :prompt-hint="$t('assessment.new.hint2')"
        >
            <div style="padding: 0px 5px; padding-bottom: 10px;">
                <!-- <InputCheckbox2
                    v-model="checkModel"
                    :items="checkItems"
                    :unique-id="'check'"
                /> -->
            </div>

        </PopupDialogSplit>

    </PopupDialog>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

import { addAssessment } from "@/lib/assessments/add_assessment";
import { Project } from "@/lib/projects/projects";
import PopupDialog from "@/ui/PopupDialog.vue";
import PopupDialogSplit from "@/ui/dialog/PopupDialogSplit.vue";
import InputCheckbox2 from "@/ui/inputs/InputCheckbox2.vue";
import { CheckboxItem, CheckboxModel } from "@/ui/inputs/input_helper";
import { getProjectProcess } from "@/lib/projects/get_project";

export default defineComponent({
    name: "NewAssessmentDialog",
    components: {
        PopupDialog,
        PopupDialogSplit,
        InputCheckbox2,
    },
    emits: ["request-project-refresh", "close-modal"],
    props: {
        project: { type: Object as PropType<Project>, required: true },
    },
    data() {
        return {
            dialog: { visible: true, isWorking: false, statusText: "", isError: false, errorText: "" },

            checkItems: [
                {
                    key: "keep",
                    text: { en_EN: "Keep comments and manual values from the current assessment" },
                },
            ] as CheckboxItem[],
            checkModel: { keep: false } as CheckboxModel,
        };
    },
    methods: {

        async addNewAssessment() {
            this.dialog.isError = false;
            this.dialog.isWorking = true;
            this.dialog.statusText = "Resetting report";

            const response = await addAssessment(this.project.id);
            if (response.responseInfo.isError) {
                this.dialog.errorText = response.responseInfo.parsedError?.message || "Unknown error";
                this.dialog.isError = true;
            } else {
                const projectResponse = await getProjectProcess(this.project.id);
                this.$emit("request-project-refresh", projectResponse);
                this.onClose();
            }

            this.dialog.isWorking = false;
        },

        onClose() {
            this.$emit("close-modal");
        },

    },
});
</script>
<style scoped></style>
