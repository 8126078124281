<template>
    <div>
        <PopupDialog
            :is-visible="dialog.visible"
            :progress="dialog"
            :header="title"
            :icon="'list-check'"
            :show-cancel-button="true"
            :show-action-button="true"
            :is-action-disabled="loading || state.isLoading"
            :action-text="$t('common.actions.save')"
            :action-icon="'floppy-disk'"
            @action="onSave"
            @close-modal="onClose"
        >
            <FormFrame
                v-if="!loading"
                :ref="'formFrame'"
                :form-url="formLink"
                @state-changed="onStateChanged"
                @saved="onSaved"
                @submitted="onSubmitted"
            />

            <div v-if="loading" style="padding: 50px 0px;">
                <SpinnerVerified :size="45" />
            </div>
        </PopupDialog>
    </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

import { Form, FormFlowStatus, FormTemplate } from "@/lib/forms/forms";
import { Project } from "@/lib/projects/projects";
import { FlowAction } from "@/types/flow_types";
import PopupDialog from "@/ui/PopupDialog.vue";
import FormFrame, { FormFrameInterface, FormFrameState } from "../forms/FormFrame.vue";
import { CreateFormOptions, createProjectForm, FormStartMode } from "@/lib/forms/create_form";
import { getFormLink } from "@/lib/forms/get_form_link";
import { getProjectProcess } from "@/lib/projects/get_project";
import { getProjectForm } from "@/lib/forms/get_forms";
import SpinnerVerified from "@/ui/SpinnerVerified.vue";

export default defineComponent({
    name: "DirectFormDialog",
    components: {
        PopupDialog,
        FormFrame,
        SpinnerVerified,
    },
    emits: ["request-project-refresh", "close-modal"],
    props: {
        action: { type: Object as PropType<FlowAction>, required: true },
        project: { type: Object as PropType<Project>, required: true },
        forms: { type: Array as PropType<Form[]>, required: true },
    },
    data() {
        return {
            dialog: { visible: true, isWorking: false, statusText: "", isError: false, errorText: "" },
            title: "Form",

            template: null as FormTemplate | null,

            loading: true,
            formLink: "",

            state: {
                isLoading: true,
                isValid: false,
                isSubmitting: false,
            } as FormFrameState,

            formFrame: {} as FormFrameInterface,

            formId: "",
        };
    },
    methods: {

        onClose() {
            this.$emit("close-modal");
        },

        onStateChanged(state: FormFrameState) {
            this.state = state;
            this.title = state.title;
            console.log("state changed", this.state);
        },

        async onSaved() {
            this.onClose();
        },

        async onSubmitted() {
            this.pollForWebhook();
        },

        onSave() {
            if (this.state.isValid) {
                this.formFrame.submitForm();
            } else {
                this.formFrame.saveForm();
            }
        },

        async getActorLink(formId: string, actorId: string) {
            if (!this.template) return;
            this.dialog.isWorking = true;
            this.dialog.statusText = "Getting form link";

            const response = await getFormLink(formId, actorId);
            console.log("response", response);

            if (response.responseInfo.isError) {
                this.dialog.isError = true;
                this.dialog.errorText = response.responseInfo.parsedError?.message || "Unknown error";
            } else {
                this.formId = formId;

                this.formLink = response.formLink.replace("&token=", "&frameMode=dialog&token=");

                if (this.formLink.includes("lang=")) {
                    const parts = this.formLink.split("lang=");
                    let newLink = parts[0] + "lang=";
                    newLink += "sv_SE";
                    newLink += parts[1].substring(5);
                    this.formLink = newLink;
                } else {
                    this.formLink = this.formLink.replace("&token=", "&lang=sv_SE&token=");
                }

                this.loading = false;
                this.initializeFormFrame();
            }
            this.dialog.isWorking = false;
        },

        async startForm() {
            if (!this.template) return;
            this.dialog.isWorking = true;
            this.dialog.statusText = "Creating form";
            console.log("Start Form:", this.template);

            const createOptions: CreateFormOptions = {
                startMode: FormStartMode.Create,
                contextId: this.project.id,
                templateSlug: this.template.slug,
                templateKey: this.template.key,
                scopes: this.template.scopes,
                model: {},
            };

            console.log("createOptions", createOptions);

            const response = await createProjectForm(createOptions);
            console.log("response", response);

            if (response.responseInfo.isError || !response.form) {
                this.dialog.isError = true;
                this.dialog.errorText = response.responseInfo.parsedError?.message || "Unknown error";
            } else {
                this.formId = response.form.id;

                const projectResponse = await getProjectProcess(this.project.id);
                this.$emit("request-project-refresh", projectResponse);

                this.formLink = response.formLink.replace("&token=", "&frameMode=dialog&token=");
                if (this.formLink.includes("lang=")) {
                    const parts = this.formLink.split("lang=");
                    let newLink = parts[0] + "lang=";
                    newLink += "sv_SE";
                    newLink += parts[1].substring(5);
                    this.formLink = newLink;
                } else {
                    this.formLink = this.formLink.replace("&token=", "&lang=sv_SE&token=");
                }

                this.loading = false;
                this.initializeFormFrame();
            }
            this.dialog.isWorking = false;
        },

        async pollForWebhook() {
            this.loading = true;

            const maxAttempts = 5;
            let attempts = 0;
            let wasCompleted = false;
            while (!wasCompleted && attempts < maxAttempts) {
                // eslint-disable-next-line promise/param-names
                await new Promise((r) => setTimeout(r, 1000));
                const response = await getProjectForm(this.project.id, this.formId);
                if (response.responseInfo.isError || !response.form) {
                    // todo: error handling
                } else {
                    console.log("form", response.form);
                    if (response.form.formStatus === FormFlowStatus.Completed) {
                        wasCompleted = true;
                    }
                }
                attempts += 1;
            }
            if (wasCompleted) {
                const projectResponse = await getProjectProcess(this.project.id);
                this.$emit("request-project-refresh", projectResponse);
                this.onClose();
            }
        },

        initializeFormFrame() {
            this.$nextTick(() => {
                this.formFrame = this.$refs.formFrame as FormFrameInterface;
            });
        },

    },
    async created() {
        console.log("accc", this.action);

        const kycKey = this.action.params.formKey;
        if (!kycKey) {
            this.dialog.errorText = `Invalid form key "${kycKey}"`;
            this.dialog.isError = true;
            return;
        }
        console.log("kycKey", kycKey);

        const formTemplates = this.$config.settings.formTemplates || [];
        formTemplates.forEach((template) => {
            if (template.key === kycKey) {
                this.template = template;
            }
        });
        if (!this.template) {
            this.dialog.errorText = this.$t("project.forms.formTemplateNotExists", { templateKey: kycKey });
            this.dialog.isError = true;
            return;
        }
        console.log("template", this.template);

        if (this.action.action === "direct-form-append") {
            let formId = "";
            let actorId = "";

            for (const i in this.forms) {
                if (this.forms[i].templateKey === this.template?.key && this.forms[i].isSelected) {
                    console.log("found it", this.forms[i]);

                    formId = this.forms[i].id;

                    this.forms[i].actors.forEach((actor) => {
                        if (actor.id === this.forms[i].currentActorId) {
                            actorId = actor.id;
                        }
                    });

                    break;
                }
            }

            if (formId && actorId) this.getActorLink(formId, actorId);
        } else {
            this.startForm();
        }
    },
});
</script>
<style scoped></style>
