<template>
    <div
        class="wfv3-configitem"
        v-click-away="hideDropdown"
    >

        <div class="wfv3-configitem-top">

            <div class="wfv3-configitem-text">
                <div>
                    <span>{{ $ct(flowConfig.name) }}</span>
                </div>
                <div class="wfv3-configitem-info">
                    <span style="color: dimgray;">{{ 'Order: ' }}</span>
                    <span style="font-weight: bold;">{{ flowConfig.order }}</span>
                    <span style="margin-left: 10px; color: dimgray;">{{ flowConfig.key }}</span>
                </div>
            </div>

            <div class="wfv3-configitem-actions">
                <div
                    class="wfv3-configitem-action"
                    @click="$emit('edit')"
                >
                    <FontIcon :icon="'pen'" />
                </div>
                <div
                    class="wfv3-configitem-action wfv3-configitem-action-end"
                    @click="open = !open"
                >
                    <FontIcon :icon="'ellipsis-vertical'" />
                </div>
            </div>

            <div
                class="wfv3-configitem-menu"
                v-if="open"
            >

                <div
                    class="wfv3-configitem-menu-item"
                    v-if="flowConfig.enabled"
                    @click="onDisableClicked"
                >
                    {{ 'Disable Workflow' }}
                </div>

                <div
                    class="wfv3-configitem-menu-item"
                    v-if="!flowConfig.enabled"
                    @click="onEnableClicked"
                >
                    {{ 'Enable Workflow' }}
                </div>

                <div
                    class="wfv3-configitem-menu-item"
                    @click="onDeleteClicked"
                >
                    {{ 'Delete Workflow' }}
                </div>

            </div>

        </div>

    </div>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { mixin as VueClickAway } from "vue3-click-away";

import { WorkflowConfig } from "@/types/flow_types";
import FontIcon from "@/ui/FontIcon.vue";
import { DropdownItem } from "@/ui/inputs/input_helper";

export default defineComponent({
    name: "WorkflowSettingsItem",
    mixins: [VueClickAway],
    components: {
        FontIcon,
    },
    emits: ["edit", "delete", "enable", "disable"],
    props: {
        flowConfig: { type: Object as PropType<WorkflowConfig>, required: true },
        matrices: { type: Array as PropType<DropdownItem[]>, required: true },
    },
    data() {
        return {
            open: false,
        };
    },
    methods: {

        getMatrixName() {
            for (const i in this.matrices) {
                if (this.matrices[i].value === this.flowConfig.params.matrixKey) {
                    return this.matrices[i].text;
                }
            }
            return this.flowConfig.params.matrixKey;
        },

        hideDropdown() {
            this.open = false;
        },

        onDisableClicked() {
            this.hideDropdown();
            this.$emit("disable");
        },

        onEnableClicked() {
            this.hideDropdown();
            this.$emit("enable");
        },

        onDeleteClicked() {
            this.hideDropdown();
            this.$emit("delete");
        },

    },
});
</script>
<style scoped>
.wfv3-configitem {
    margin-top: 5px;
    margin-bottom: 10px;
}

.wfv3-configitem-top {
    display: flex;
    position: relative;
}

.wfv3-configitem-text {
    flex-grow: 1;
    padding: 5px 15px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border: solid 1px lightgray;
    border-right: none;
    background-color: white;
}

.wfv3-configitem-info {
    font-size: 14px;
}

.wfv3-configitem-actions {
    flex-shrink: 0;
    display: flex;
}

.wfv3-configitem-action {
    width: 40px;
    padding-top: 12px;
    text-align: center;
    border: solid 1px lightgray;
    border-right: none;
    background-color: white;
    cursor: pointer;
}

.wfv3-configitem-action:hover {
    background-color: whitesmoke;
}

.wfv3-configitem-action-end {
    width: 24px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-right: solid 1px lightgray;
}

.wfv3-configitem-menu {
    position: absolute;
    top: 46px;
    right: 0px;
    border: solid 1px lightgray;
    border-bottom: none;
    background-color: white;
    z-index: 9;
}

.wfv3-configitem-menu-item {
    min-width: 120px;
    padding: 5px 10px;
    color: black;
    border-bottom: solid 1px lightgray;
    cursor: pointer;
}

.wfv3-configitem-menu-item:hover {
    background-color: whitesmoke;
}
</style>
