import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a63721d2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "border rounded-md border-light border-solid p-2 break-all relative" }
const _hoisted_2 = { class: "flex gap-3" }
const _hoisted_3 = { class: "flex-1 text-sm" }
const _hoisted_4 = {
  key: 1,
  class: "border rounded-full px-1 bg-green-300"
}
const _hoisted_5 = {
  key: 0,
  class: "mt-2 text-xs flex flex-col gap-2"
}
const _hoisted_6 = { class: "font-bold" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "font-bold" }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "font-bold" }
const _hoisted_11 = { key: 2 }
const _hoisted_12 = { class: "font-bold" }
const _hoisted_13 = { key: 3 }
const _hoisted_14 = { class: "font-bold" }
const _hoisted_15 = { key: 4 }
const _hoisted_16 = { class: "bg-background rounded-md mb-2 p-2" }
const _hoisted_17 = { class: "font-bold" }
const _hoisted_18 = { class: "font-bold" }
const _hoisted_19 = { class: "font-bold" }
const _hoisted_20 = { class: "font-bold" }
const _hoisted_21 = { key: 5 }
const _hoisted_22 = { class: "font-bold" }
const _hoisted_23 = { class: "flex flex-col gap-1" }
const _hoisted_24 = { class: "" }
const _hoisted_25 = { key: 6 }
const _hoisted_26 = { class: "font-bold" }
const _hoisted_27 = { class: "flex flex-col gap-1" }
const _hoisted_28 = { class: "" }
const _hoisted_29 = { key: 7 }
const _hoisted_30 = { class: "font-bold" }
const _hoisted_31 = { class: "flex flex-col gap-1" }
const _hoisted_32 = { class: "" }
const _hoisted_33 = { class: "flex items-start text-xs pt-[12px] gap-2 px-2 absolute right-[50px]" }
const _hoisted_34 = ["id"]
const _hoisted_35 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontIcon = _resolveComponent("FontIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass('flex-0 w-[4px] rounded-full ' + (_ctx.falsePositive ? 'bg-gray-300' : 'bg-yellow-500'))
      }, null, 2),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: _normalizeClass('font-bold hover:cursor-pointer' + (_ctx.falsePositive ? ' opacity-50' : '')),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.collapsed = !_ctx.collapsed))
        }, _toDisplayString(_ctx.matchName), 3),
        _createElementVNode("div", {
          class: "flex text-[10px] gap-1 justify-start items-center hover:cursor-pointer",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.collapsed = !_ctx.collapsed))
        }, [
          (_ctx.matchFlags.length)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.matchFlags, (flag) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: flag,
                  class: _normalizeClass('border rounded-full px-2 bg-background' + (_ctx.falsePositive ? ' opacity-50' : ''))
                }, _toDisplayString(flag), 3))
              }), 128))
            : _createCommentVNode("", true),
          (!_ctx.matchFlags.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.$t('project.tabs.persons.screeningHitsDialog.noFlags')), 1))
            : _createCommentVNode("", true)
        ]),
        (!_ctx.collapsed)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", null, [
                _createElementVNode("div", null, _toDisplayString(_ctx.$t('project.tabs.persons.screeningHitsDialog.matchScore')), 1),
                _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.hit?.match?.score || _ctx.$t('project.tabs.persons.screeningHitsDialog.unknown')), 1)
              ]),
              (_ctx.hit?.hit?.birthdates?.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t('project.tabs.persons.screeningHitsDialog.birthdate')), 1),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hit?.hit?.birthdates, (dob) => {
                      return (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(dob?.year || "----") + "/" + _toDisplayString(dob?.month || "--") + "/" + _toDisplayString(dob?.day || "--"), 1))
                    }), 256))
                  ]))
                : _createCommentVNode("", true),
              (_ctx.hit?.hit?.gender)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t('project.tabs.persons.screeningHitsDialog.gender')), 1),
                    _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.hit?.hit?.gender || _ctx.$t('project.tabs.persons.screeningHitsDialog.unknown')), 1)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.hit?.hit?.nationalities?.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t('project.tabs.persons.screeningHitsDialog.nationalities')), 1),
                    _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.hit?.hit?.nationalities?.map((x) => _ctx.$t("countries." + x.toUpperCase())).join(", ")), 1)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.hit?.hit?.lists?.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t('project.tabs.persons.screeningHitsDialog.sanctionLists')), 1),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hit?.hit?.lists, (list) => {
                      return (_openBlock(), _createElementBlock("div", _hoisted_14, _toDisplayString(list), 1))
                    }), 256))
                  ]))
                : _createCommentVNode("", true),
              (_ctx.hit?.hit?.roles?.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t('project.tabs.persons.screeningHitsDialog.roles')), 1),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hit?.hit?.roles?.filter(x => x.description), (role) => {
                      return (_openBlock(), _createElementBlock("div", _hoisted_16, [
                        _createElementVNode("div", _hoisted_17, _toDisplayString(role?.description), 1),
                        _createElementVNode("div", null, [
                          _createTextVNode(_toDisplayString(_ctx.$t('project.tabs.persons.screeningHitsDialog.roleActive')) + " ", 1),
                          _createElementVNode("span", _hoisted_18, _toDisplayString(role?.isActive === true ? _ctx.$t('project.tabs.persons.screeningHitsDialog.yes') : role?.isActive === 'false' ? _ctx.$t('project.tabs.persons.screeningHitsDialog.no') : _ctx.$t('project.tabs.persons.screeningHitsDialog.unknown')), 1)
                        ]),
                        _createElementVNode("div", null, [
                          _createTextVNode(_toDisplayString(_ctx.$t('project.tabs.persons.screeningHitsDialog.from')) + " ", 1),
                          _createElementVNode("span", _hoisted_19, _toDisplayString(role?.sinceYear || '----') + "/" + _toDisplayString(role?.sinceMonth || '--') + "/" + _toDisplayString(role?.sinceDay || '--'), 1),
                          _createTextVNode(" " + _toDisplayString(_ctx.$t('project.tabs.persons.screeningHitsDialog.to')) + " ", 1),
                          _createElementVNode("span", _hoisted_20, _toDisplayString(role?.toYear || '----') + "/" + _toDisplayString(role?.toMonth || '--') + "/" + _toDisplayString(role?.toDay || '--'), 1)
                        ])
                      ]))
                    }), 256))
                  ]))
                : _createCommentVNode("", true),
              (_ctx.hit?.hit?.sources?.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                    _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.$t('project.tabs.persons.screeningHitsDialog.sources')), 1),
                    _createElementVNode("div", _hoisted_23, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hit?.hit?.sources, (source) => {
                        return (_openBlock(), _createElementBlock("div", _hoisted_24, _toDisplayString(source), 1))
                      }), 256))
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.hit?.hit?.notes?.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                    _createElementVNode("div", _hoisted_26, _toDisplayString(_ctx.$t('project.tabs.persons.screeningHitsDialog.notes')), 1),
                    _createElementVNode("div", _hoisted_27, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hit?.hit?.notes, (note) => {
                        return (_openBlock(), _createElementBlock("div", _hoisted_28, _toDisplayString(note), 1))
                      }), 256))
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.hit?.hit?.images?.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                    _createElementVNode("div", _hoisted_30, _toDisplayString(_ctx.$t('project.tabs.persons.screeningHitsDialog.images')), 1),
                    _createElementVNode("div", _hoisted_31, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hit?.hit?.images, (image) => {
                        return (_openBlock(), _createElementBlock("div", _hoisted_32, _toDisplayString(image), 1))
                      }), 256))
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_33, [
        _withDirectives(_createElementVNode("input", {
          type: "checkbox",
          id: _ctx.hit.id,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.falsePositive) = $event))
        }, null, 8, _hoisted_34), [
          [_vModelCheckbox, _ctx.falsePositive]
        ]),
        _createElementVNode("label", {
          class: "mt-[-2px]",
          for: _ctx.hit.id
        }, "False positive", 8, _hoisted_35)
      ]),
      _createElementVNode("div", {
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.collapsed = !_ctx.collapsed)),
        class: "flex p-2 mr-2 text-gray-400 hover:cursor-pointer absolute right-[5px]"
      }, [
        (_ctx.collapsed)
          ? (_openBlock(), _createBlock(_component_FontIcon, {
              key: 0,
              icon: "caret-up",
              size: 24
            }))
          : _createCommentVNode("", true),
        (!_ctx.collapsed)
          ? (_openBlock(), _createBlock(_component_FontIcon, {
              key: 1,
              icon: "caret-down",
              size: 24
            }))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}