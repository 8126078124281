import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bbf2664a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"padding":"5px 30px","padding-bottom":"10px","font-size":"14px","color":"gray"} }
const _hoisted_2 = { class: "amlv3-levents-items" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProjectEventList = _resolveComponent("ProjectEventList")!
  const _component_ExpandableListLayout = _resolveComponent("ExpandableListLayout")!

  return (_openBlock(), _createBlock(_component_ExpandableListLayout, {
    "can-expand": false,
    expanded: _ctx.events.length === 0,
    "button-text": _ctx.$t('project.overviewMenu.lastEvents.showAll'),
    onClicked: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('menu-tab', 'events')))
  }, {
    top: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('project.overviewMenu.lastEvents.title')), 1)
    ]),
    expanded: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('project.overviewMenu.lastEvents.empty')), 1)
    ]),
    collapsed: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        (!_ctx.loading)
          ? (_openBlock(), _createBlock(_component_ProjectEventList, {
              key: 0,
              project: _ctx.project,
              events: _ctx.events
            }, null, 8, ["project", "events"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["expanded", "button-text"]))
}