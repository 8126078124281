export const idControlMethodToName = (method: string) => {
    switch (method) {
    case "bankid-no":
        return "Norwegian BankID";

    case "bankid-se":
        return "Swedish BankID";

    default:
        return method;
    }
};
