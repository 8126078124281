import { parseRemoteError } from "../error_parser";
import { Verified } from "../verified_plugin";

export const deleteAttachment = async (attachmentId: string, projectId: string) => {
    try {
        const response = await fetch(`/api/aml/v1/projects/${projectId}/attachments/${attachmentId}`, {
            method: "delete",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
        });

        const body = await response.json();

        if (!response.ok) {
            return {
                deleted: false,
                responseInfo: { isError: true, parsedError: parseRemoteError(body) },
            };
        }

        return { deleted: true };
    } catch (err) {
        console.error("Failed to delete attachment from project", err);
        return {
            deleted: false,
            responseInfo: { isError: true, parsedError: parseRemoteError(err) },
        };
    }
};
