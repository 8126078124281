<template>

    <div class="fbav3-input-color">

        <input
            class="fbav3-input-color-text"
            :id="uniqueId"
            v-model="localValue"
            @input="onHexInput"
            @blur="onHexBlur"
        />

        <div class="fbav3-input-color-c">
            <input
                type="color"
                class="fbav3-input-color-color"
                :style="'background-color: #' + modelValue + ';'"
                :value="modelValue"
                @input="onPickerInput"
            />
        </div>

    </div>

</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";

export default defineComponent({
    name: "InputColor",
    props: {
        modelValue: { type: String as PropType<string>, required: true },
        uniqueId: { type: String as PropType<string>, required: true },
    },
    emits: ["update:modelValue", "enter", "picker"],
    data() {
        return {
            localValue: "",
            isError: false,
        };
    },
    watch: {
        modelValue: {
            deep: true,
            handler() {
                this.localValue = this.modelValue.replace("#", "");
            },
        },
    },
    methods: {

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onHexInput(event: any) {
            let value = event.target.value;

            // Remove common chars if value was pasted
            if (event.inputType === "insertFromPaste") {
                value = value.replace("#", "");
                value = value.replace(";", "");
            }

            this.isError = this.isValidHex(value);
            if (!this.isError) this.$emit("update:modelValue", "#" + value.toUpperCase());
        },

        onHexBlur() {
            const isError = this.isValidHex(this.localValue);
            if (isError) this.localValue = this.modelValue;
            this.isError = false;
        },

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onPickerInput(event: any) {
            this.$emit("update:modelValue", event.target.value.toUpperCase());
        },

        isValidHex(value: string) {
            let isError = false;
            const allowedChars = "0123456789ABCDEFabcdefABCDEF";

            if (value.length !== 6) isError = true;
            for (let i = 0; i < value.length; i += 1) {
                if (!allowedChars.includes(value.charAt(i))) isError = true;
            }

            return isError;
        },

    },
    mounted() {
        this.localValue = this.modelValue.replace("#", "");
    },
});
</script>
<style scoped>
.fbav3-input-color {
    display: flex;
}

.fbav3-input-color-text {
    width: 100%;
    height: 42px;
    padding: 0px 8px;
    font-size: 14px;
    font-weight: 500;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    color: #444444;
    border: solid 1px #D5D5D5;
    box-sizing: border-box;
    text-transform: uppercase;
}

.fbav3-input-color-c {
    height: 42px;
    padding: 0px 2px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border: solid 1px #D5D5D5;
    border-left: none;
}

.fbav3-input-color-color {
    appearance: none;
    border: none;
    background: none;
    outline: none;
    padding: 0px;
    margin: 0px;
    flex-shrink: 0;
    width: 60px;
    height: 40px;
}

.fbav3-input-color-color::-webkit-color-swatch {
    border: none;
}

.fbav3-input-color-color::-moz-color-swatch {
    border: none;
}
</style>
