import { Verified } from "../verified_plugin";

export interface CompanyNameSearchResult {
    companies: { name: string; registrationNumber: string; country: string }[];
    total: number;
}

export const companyNameSearch = async (country: string, name: string, orgNumber: string) => {
    try {
        const response = await fetch(`/api/aml/v1/lookup/company-search?country=${country.toLowerCase()}&name=${name}&orgNumber=${orgNumber}&limit=${10}`, {
            method: "GET",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
        });

        const body = await response.json();

        if (!response.ok) {
            throw body;
        }

        return body as CompanyNameSearchResult;
    } catch (err: any) {
        console.error("Failed to attach company data to project", err);
        throw err;
    }
};
