<template>
    <div class="py-5 bg-white">
        <div
            class="mb-3"
        >
            <CustomButton
                v-if="!addFile"
                :text="$t('common.addAttachment')"
                bg-color="transparent"
                text-color="primary-default"
                border-color="primary-default"
                icon="file-circle-plus"
                round-style="2xl"
                icon-position="left"
                class="ml-auto"
                :on-click="() => (addFile = true)"
            />
        </div>

        <div v-if="addFile" class="w-full mb-3">
            <div v-if="showSuccess" class=" text-center bg-success-light z-10 rounded-lg p-2">
                <FontIcon
                    icon="circle-check"
                    :size="40"
                    class="text-success-default mb-2"
                />
                <label class="">{{ $t("common.uploadSuccess") }}</label>
            </div>
            <div v-else class="w-full">
                <FileUploadInput @file-uploaded="uploadAttachment" :progress="progress" />
                <CustomButton
                    text="Cancel"
                    bg-color="primary-default"
                    text-color="white"
                    border-color="primary-default"
                    round-style="2xl"
                    icon-position="left"
                    class="ml-auto mb-3 w-full"
                    :on-click="() => (addFile = false)"
                />
            </div>
        </div>
        <ul class="space-y-2">
            <li
                v-for="(a, i) in attachments"
                :key="'attachment_' + i"
                class="group bg-background rounded-lg hover:bg-primary-hover-blue"
            >
            </li><li
                v-for="(a, i) in attachments"
                :key="'attachment_' + i"
                class="group bg-background rounded-lg hover:bg-primary-hover-blue"
            >
                <div class="flex items-center p-4">
                    <Icon class="w-8 h-8 flex-none" icon="attachment" />
                    <div class="ml-3 flex-shrink">
                        <OverflowText :text="a.name" :max-length="20" />
                        <div class="text-xs text-light-gray group-hover:text-body">
                            {{ a.createdBy }} - {{ a.createdAt ? $d(a.createdAt, "long") : "" }}
                        </div>
                    </div>
                    <DropdownMenu
                        :options="menuOptions"
                        class="ml-auto"
                        @menu-item-selected="(option: DropdownMenuOption) => menuItemSelected(option, a.id ?? '')"
                    >
                        <Icon class="w-4 h-4" icon="hamburger-menu" />
                    </DropdownMenu>
                </div>
            </li>
        </ul>
    </div>
</template>

<script lang="ts">
import DropdownMenu from "@/components/DropdownMenu.vue";
import Icon from "@/components/Icon.vue";
import { DropdownMenuOption } from "@/lib/common/MenuOption";
import OverflowText from "@/ui/inputs/OverflowText.vue";
import { defineComponent, PropType } from "vue";
import FileUploadInput from "@/ui/inputs/FileUploadInput.vue";
import CustomButton from "@/components/CustomButton.vue";
import { addAttachment } from "@/lib/persons/add_attachment";
import { Attachment } from "@/lib/persons/person";
import { uploadAttachment } from "@/lib/persons/upload_attachment";
import { getAttachment } from "@/lib/persons/get_attachment";
import FontIcon from "@/ui/FontIcon.vue";
import { deleteAttachment } from "@/lib/persons/delete_attachment";

export default defineComponent({
    name: "PersonAttachments",
    components: {
        Icon,
        OverflowText,
        DropdownMenu,
        FileUploadInput,
        CustomButton,
        FontIcon,
    },
    props: {
        attachments: {
            type: Array as PropType<Attachment[]>,
            required: true,
            default: [],
        },
        personId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            addFile: false,
            progress: 0,
            showSuccess: false,
            menuOptions: [
                { value: "view", label: this.$t("common.actions.view"), icon: "eye" },
                { value: "delete", label: this.$t("common.actions.delete"), icon: "delete" },
            ] as DropdownMenuOption[],
        };
    },
    emits: ["refresh"],
    methods: {
        async menuItemSelected(option: DropdownMenuOption, attachmentId: string) {
            switch (option.value) {
            case "view":
            {
                const { url } = await getAttachment(attachmentId, this.personId);
                console.log("url", url);
                window.open(url, "_blank");
                break;
            }

            case "delete":
                await deleteAttachment(attachmentId, this.personId);
                this.$emit("refresh");
                break;

            default:
                break;
            }
        },
        async uploadAttachment(data: {file: File, done: ()=>void}) {
            console.log("file in attachment: ", data.file);
            const attachment : Attachment = {
                name: data.file.name,
                contentType: data.file.type,
                attachmentType: "document",
            };
            const { url, fileId } = await addAttachment(attachment, this.personId);
            this.progress = 50;
            console.log("returned data", url, fileId);
            await uploadAttachment(data.file, url);
            this.progress = 100;
            data.done();
            this.showSuccess = true;
            setTimeout(() => {
                this.showSuccess = false;
                this.addFile = false;
                this.progress = 0;
            }, 3000);

            this.$emit("refresh");
        },
    },
});
</script>
