import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6e095dfe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"padding":"10px 30px","font-size":"14px","color":"gray"} }
const _hoisted_2 = { class: "amlv3-asslist-items" }
const _hoisted_3 = { class: "amlv3-asslist-item-icon" }
const _hoisted_4 = { class: "amlv3-asslist-item-text" }
const _hoisted_5 = { class: "amlv3-asslist-item-name" }
const _hoisted_6 = { class: "amlv3-asslist-item-actions" }
const _hoisted_7 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuInfoLine = _resolveComponent("MenuInfoLine")!
  const _component_FontIcon = _resolveComponent("FontIcon")!
  const _component_ExpandableListLayout = _resolveComponent("ExpandableListLayout")!

  return (_openBlock(), _createBlock(_component_ExpandableListLayout, {
    "can-expand": false,
    expanded: _ctx.sealedAssessments.length === 0,
    "button-text": _ctx.$t('assessment.latest.showAll'),
    onClicked: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('menu-tab', 'assessments')))
  }, {
    top: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('assessment.latest.title')), 1)
    ]),
    expanded: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('assessment.latest.empty')), 1)
    ]),
    collapsed: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sealedAssessments, (assessment, i) => {
          return (_openBlock(), _createElementBlock("div", {
            key: 'pa' + i,
            class: "amlv3-asslist-item"
          }, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.getRiskInitial(assessment)), 1),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.getAssessmentName(assessment)), 1),
              _createVNode(_component_MenuInfoLine, {
                info1: _ctx.getRiskText(assessment),
                info2: assessment.sealedBy?.name
              }, null, 8, ["info1", "info2"])
            ]),
            _createElementVNode("div", _hoisted_6, [
              (assessment.sealed)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "amlv3-asslist-item-action",
                    onClick: () => _ctx.handleDownloadClick(_ctx.project?.id || '', assessment.id)
                  }, [
                    _createVNode(_component_FontIcon, {
                      icon: _ctx.isLoading ? 'fa-spinner' : 'file-pdf',
                      size: 18
                    }, null, 8, ["icon"])
                  ], 8, _hoisted_7))
                : _createCommentVNode("", true)
            ])
          ]))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["expanded", "button-text"]))
}