<template>
    <div class="amlv3-rsk-id">

        <div class="amlv3-rsk-id-top" @click="onClose">
            <div class="amlv3-rsk-id-top-text">
                {{ $t('common.actions.close') }}
            </div>
            <div>
                <FontIcon :icon="'xmark'" />
            </div>
        </div>

        <div class="amlv3-rsk-id-content">

            <div class="amlv3-rsk-id-header">
                {{ $t('assessment.indicators.allMatchedValues') }}
            </div>
            <div v-if="matchedValues.length === 0" class="amlv3-rsk-id-empty">
                {{ $t('assessment.indicators.noMatchedValues') }}
            </div>

            <div
                class="amlv3-rsk-id-value"
                v-for="(value, i) in matchedValues"
                :key="indicator.key + i"
                :class="[
                    { 'amlv3-rsk-id-value-first': i === 0 },
                    { 'amlv3-rsk-id-value-last': i === matchedValues.length - 1 },
                    { 'amlv3-rsk-id-value-active': value.value === indicator.activeValue.value },
                ]"
            >
                <div class="amlv3-rsk-id-value-text">
                    <div class="amlv3-rsk-id-value-value">
                        {{ $ct(value.text) }}
                    </div>
                    <div class="amlv3-rsk-id-value-source">
                        {{ getSourceText(value) }}
                    </div>
                </div>
                <div class="amlv3-rsk-id-value-score" :style="{ backgroundColor: value.value === indicator.activeValue.value ? activeIndicatorScoreBgColor : '' }">
                    {{ value.score }}
                </div>
            </div>

            <div class="amlv3-rsk-id-header" style="margin-top: 20px;">
                {{ $t('assessment.indicators.manualValues') }}
            </div>
            <div v-if="existingValues.length === 0" class="amlv3-rsk-id-empty">
                {{ $t('assessment.indicators.noManualValues') }}
            </div>

            <div
                v-for="(manualValue, i) in existingValues"
                :key="indicator.key + 'm' + i"
                class="amlv3-rsk-id-manval"
                :class="[
                    { 'amlv3-rsk-id-value-first': i === 0 },
                    { 'amlv3-rsk-id-value-last': i === existingValues.length - 1 },
                ]"
            >
                <div class="amlv3-rsk-id-manval-value">
                    {{ getDisplayValue(manualValue) }}
                </div>
                <div
                    v-if="!assessment.locked"
                    class="amlv3-rsk-id-manval-delete"
                    @click="showDeleteDialog(manualValue)"
                >
                    <FontIcon :icon="'trash-can'" :size="16" />
                </div>
            </div>

            <ButtonRow :alignment="ButtonAlignment.Right" style="margin-top: 5px; padding-right: 15px;">
                <ButtonSingle
                    v-if="!assessment.locked"
                    is-link
                    :text="$t('assessment.indicators.addManualValue')"
                    @clicked="showEditDialog"
                />
            </ButtonRow>

            <div v-if="allowNotApplicable || indicator.notApplicable" class="amlv3-rsk-id-na">
                <div
                    v-if="!isUpdatingNotApplicable && !indicator.notApplicable"
                    class="amlv3-rsk-id-na-base"
                >
                    <div class="amlv3-rsk-id-na-text-hint">
                        {{ $t('risk.notApplicable.hint') }}
                    </div>
                    <div class="amlv3-rsk-id-na-btn" @click="markAsNotApplicable(true)">
                        {{ $t('risk.notApplicable.action') }}
                    </div>
                </div>
                <div
                    v-if="!isUpdatingNotApplicable && indicator.notApplicable"
                    class="amlv3-rsk-id-na-base amlv3-rsk-id-na-base-blue"
                >
                    <div class="amlv3-rsk-id-na-text">
                        {{ $t('risk.notApplicable.marked') }}
                    </div>
                    <div
                        v-if="allowNotApplicable && notApplicableWasManual"
                        class="amlv3-rsk-id-na-btn"
                        @click="markAsNotApplicable(false)"
                    >
                        {{ $t('risk.notApplicable.reset') }}
                    </div>
                </div>
                <div
                    v-if="isUpdatingNotApplicable"
                    class="amlv3-rsk-id-na-base"
                >
                    <div class="amlv3-rsk-id-na-spinner">
                        <SpinnerVerified :size="14" :hide-icon="true" />
                    </div>
                    <div class="amlv3-rsk-id-na-status">
                        {{ $t('risk.notApplicable.working') }}
                    </div>
                </div>
                <div v-if="notApplicableErrorText" class="amlv3-rsk-id-na-error">
                    {{ notApplicableErrorText }}
                </div>
            </div>

            <div class="amlv3-rsk-id-header" style="margin-top: 20px;">
                {{ $t('assessment.indicators.comments') }}
            </div>

            <RiskComments
                :project="project"
                :assessment="assessment"
                :matrix-definition="matrix"
                :indicator-key="indicator.key"
                @request-project-refresh="$emit('request-project-refresh', $event)"
            />

        </div>

        <ButtonRow :alignment="ButtonAlignment.Right">
            <ButtonSingle
                outline
                is-rounded
                :text="$t('common.actions.close')"
                @clicked="onClose"
            />
        </ButtonRow>

        <RiskManualValueDialog
            v-if="editDialog.visible"
            :assets="assets"
            :indicator="indicator"
            :definition="definition"
            :matrix="matrix"
            :project="project"
            :assessment="assessment"
            @close-modal="editDialog.visible = false"
            @request-project-refresh="$emit('request-project-refresh', $event)"
        />

        <!-- Delete Dialog -->
        <PopupDialog
            :is-visible="deleteDialog.visible"
            :header="$t('common.actions.delete')"
            :icon="'trash-can'"
            :show-action-button="true"
            :show-cancel-button="true"
            :action-text="$t('common.actions.delete')"
            :action-icon="'trash-can'"
            :is-warning="true"
            :progress="deleteDialog"
            @close-modal="deleteDialog.visible = false"
            @action="onDeleteManualValue"
        >
            <PopupDialogPrompt
                :prompt-text="$t('common.actions.delete')"
                :info-text="$t('common.actions.notReversableHint')"
            />
        </PopupDialog>

    </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

import { Assessment, AssessmentValue, IndicatorValue, RiskIndicator } from "@/lib/assessments/assessments";
import { Project } from "@/lib/projects/projects";
import { RiskIndicatorDefinition, RiskMatrixDefinition, RiskValueSource } from "@/lib/risk/risk_matrix";
import { ButtonAlignment } from "@/types/ui_types";
import ButtonRow from "@/ui/ButtonRow.vue";
import ButtonSingle from "@/ui/ButtonSingle.vue";
import FontIcon from "@/ui/FontIcon.vue";
import { RiskAssets } from "@/views/risk/risk_assets";
import { getManualDisplayValue, getRiskValueSourceText, getIndicatorActiveValueBgColor } from "@/views/risk/risk_helper";
import RiskComments from "./RiskComments.vue";
import RiskManualValueDialog from "./RiskManualValueDialog.vue";
import PopupDialog from "@/ui/PopupDialog.vue";
import PopupDialogPrompt from "@/ui/dialog/PopupDialogPrompt.vue";
import { deleteAssessmentValue, markIndicatorAsNotApplicable } from "@/lib/assessments/update_assessment";
import { getProjectProcess } from "@/lib/projects/get_project";
import SpinnerVerified from "@/ui/SpinnerVerified.vue";
import { FlagKey, indicatorHasFlag } from "@/views/risk/risk_flag_helper";

export default defineComponent({
    name: "IndicatorDetails",
    components: {
        ButtonRow,
        ButtonSingle,
        FontIcon,
        RiskComments,
        RiskManualValueDialog,
        PopupDialog,
        PopupDialogPrompt,
        SpinnerVerified,
    },
    emits: ["request-project-refresh", "close-modal"],
    props: {
        assets: { type: Object as PropType<RiskAssets>, required: true },
        indicator: { type: Object as PropType<RiskIndicator>, required: true },
        definition: { type: Object as PropType<RiskIndicatorDefinition>, required: true },
        matrix: { type: Object as PropType<RiskMatrixDefinition>, required: true },
        project: { type: Object as PropType<Project>, required: true },
        assessment: { type: Object as PropType<Assessment>, required: true },
    },
    data() {
        return {
            ButtonAlignment,

            allowNotApplicable: false,
            notApplicableWasManual: false,
            isUpdatingNotApplicable: false,
            notApplicableErrorText: "",

            editDialog: { visible: false },
            deleteDialog: { visible: false, isWorking: false, statusText: "", isError: false, errorText: "", value: {} as AssessmentValue },
            existingValues: new Array<AssessmentValue>(),

            matchedValues: new Array<IndicatorValue>(),
            activeIndicatorScoreBgColor: "",
        };
    },
    watch: {
        assessment: {
            deep: true,
            handler() {
                this.loadDetails();
            },
        },
    },
    methods: {

        onClose() {
            this.$emit("close-modal");
        },

        getDisplayValue(value: AssessmentValue) {
            return getManualDisplayValue(value, this.definition.valueType, this.assets);
        },

        getSourceText(indicatorValue: IndicatorValue) {
            return getRiskValueSourceText(indicatorValue, this.$config.settings);
        },

        showEditDialog() {
            this.editDialog.visible = true;
        },

        showDeleteDialog(value: AssessmentValue) {
            this.deleteDialog.value = value;
            this.deleteDialog.visible = true;
        },

        async onDeleteManualValue() {
            this.deleteDialog.isWorking = true;
            const response = await deleteAssessmentValue(
                this.project.id,
                this.assessment.id,
                this.deleteDialog.value.id,
            );
            if (response.responseInfo.isError || !response.assessment) {
                this.deleteDialog.errorText = response.responseInfo.parsedError?.message || "Unknown Error";
                this.deleteDialog.isError = true;
            } else {
                const projectResponse = await getProjectProcess(this.project.id);
                this.$emit("request-project-refresh", projectResponse);
                this.deleteDialog.visible = false;
            }
            this.deleteDialog.isWorking = false;
        },

        loadDetails() {
            this.existingValues = [];
            this.matchedValues = [];
            this.notApplicableWasManual = false;

            this.indicator.values.forEach((value) => {
                if (value.source.source !== RiskValueSource.Empty && value.source.source !== RiskValueSource.NotApplicable) {
                    this.matchedValues.push(value);
                }
            });
            this.assessment.values.forEach((value) => {
                if (value.indicatorKey === this.indicator.key) {
                    this.existingValues.push(value);
                }
            });
            if (this.assessment.notApplicableIndicators) {
                this.assessment.notApplicableIndicators.forEach((indicatorKey) => {
                    if (indicatorKey === this.indicator.key) {
                        this.notApplicableWasManual = true;
                    }
                });
            }
        },

        async markAsNotApplicable(notApplicable: boolean) {
            this.notApplicableErrorText = "";
            this.isUpdatingNotApplicable = true;

            const response = await markIndicatorAsNotApplicable(
                this.project.id,
                this.assessment.id,
                this.indicator.key,
                notApplicable,
            );
            if (response.responseInfo.isError || !response.assessment) {
                this.notApplicableErrorText = response.responseInfo.parsedError?.message || "Unknown Error";
            } else {
                const projectResponse = await getProjectProcess(this.project.id);
                this.$emit("request-project-refresh", projectResponse);
            }

            this.isUpdatingNotApplicable = false;
        },

    },
    mounted() {
        if (indicatorHasFlag(FlagKey.AllowManualNotApplicable, this.definition)) this.allowNotApplicable = true;
        this.loadDetails();
        this.activeIndicatorScoreBgColor = this.$config.settings.riskConfigs ? getIndicatorActiveValueBgColor(this.indicator.activeValue.score, this.$config.settings.riskConfigs[0]) : "";
    },
});
</script>
<style scoped>
.amlv3-rsk-id {
    height: 100%;
}

.amlv3-rsk-id-top {
    display: flex;
    margin-bottom: 10px;
    padding-top: 1px;
    padding-right: 10px;
    border-radius: 4px;
    border: solid 1px lightgray;
    cursor: pointer;
}
.amlv3-rsk-id-top:hover {
    background-color: whitesmoke;
}

.amlv3-rsk-id-top-text {
    flex-grow: 1;
    padding-top: 2px;
    padding-right: 8px;
    text-align: right;
}

.amlv3-rsk-id-content {
    height: calc(100% - 80px);
    overflow: auto;
}

.amlv3-rsk-id-header {
    margin-bottom: 5px;
    font-family: "Roboto Slab";
    font-size: 15px;
    font-weight: 600;
    color: dimgray;
}

.amlv3-rsk-id-empty {
    padding: 5px 10px;
    font-size: 14px;
    color: gray;
}

.amlv3-rsk-id-value {
    display: flex;
    border: solid 1px lightgray;
    border-bottom: none;
    opacity: 0.5;
}

.amlv3-rsk-id-value-text {
    flex-grow: 1;
    padding: 5px 15px;
}

.amlv3-rsk-id-value-value {
    font-family: "Roboto Slab";
    font-weight: 600;
}

.amlv3-rsk-id-value-source {
    font-size: 14px;
    font-style: italic;
    color: gray;
}

.amlv3-rsk-id-value-score {
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    margin-top: 12px;
    margin-right: 10px;
    padding-top: 4px;
    text-align: center;
    font-family: "Roboto Slab";
    font-size: 16px;
    font-weight: 500;
    color: black;
    border-radius: 50%;
    background-color: lightgrey;
}

.amlv3-rsk-id-value-active {
    opacity: 1;
}

.amlv3-rsk-id-manval {
    display: flex;
    border: solid 1px lightgray;
    border-bottom: none;
}

.amlv3-rsk-id-manval-value {
    flex-grow: 1;
    padding: 5px 15px;
    font-family: "Roboto Slab";
    font-weight: 600;
}

.amlv3-rsk-id-manval-delete {
    padding: 5px 10px;
    cursor: pointer;
}
.amlv3-rsk-id-manval-delete:hover {
    opacity: 0.7;
}

.amlv3-rsk-id-value-first {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.amlv3-rsk-id-value-last {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom: solid 1px lightgray;
}

/* Mark as N/A */

.amlv3-rsk-id-na {
    margin-top: 20px;
}

.amlv3-rsk-id-na-base {
    display: flex;
    padding: 5px 10px;
    padding-right: 14px;
    border-radius: 4px;
    border: solid 1px lightgray;
}

.amlv3-rsk-id-na-base-blue {
    border: solid 1px #218BCB;
    background-color: #d4efff;
}

.amlv3-rsk-id-na-btn {
    flex-shrink: 0;
    font-size: 15px;
    font-weight: 500;
    color: #218BCB;
    cursor: pointer;
}
.amlv3-rsk-id-na-btn:hover {
    opacity: 0.7;
}

.amlv3-rsk-id-na-text {
    flex-grow: 1;
    font-size: 14px;
    font-weight: 500;
}

.amlv3-rsk-id-na-text-hint {
    flex-grow: 1;
    font-size: 14px;
}

.amlv3-rsk-id-na-spinner {
    width: 28px;
}

.amlv3-rsk-id-na-status {
    padding-left: 6px;
    font-size: 14px;
}

.amlv3-rsk-id-na-error {
    font-size: 14px;
    color: #B62537;
}
</style>
