import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { style: {"max-width":"500px"} }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabControl = _resolveComponent("TabControl")!
  const _component_SimpleListItem = _resolveComponent("SimpleListItem")!
  const _component_RiskSourceFormWizard = _resolveComponent("RiskSourceFormWizard")!
  const _component_PopupDialog = _resolveComponent("PopupDialog")!

  return (_openBlock(), _createBlock(_component_PopupDialog, {
    "is-visible": _ctx.dialog.visible,
    header: _ctx.$t('risk.indicators.add'),
    icon: 'wand-magic-sparkles',
    progress: _ctx.dialog,
    "show-cancel-button": true,
    "show-action-button": true,
    "action-text": _ctx.$t('risk.indicators.add'),
    onCloseModal: _ctx.onClose,
    onAction: _ctx.addIndicator
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_TabControl, {
          tabs: _ctx.tabs,
          "selected-tab-key": _ctx.selectedTabKey,
          onTabChanged: _ctx.onTabChanged
        }, null, 8, ["tabs", "selected-tab-key", "onTabChanged"]),
        (_ctx.selectedTabKey === 'common')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.commonIndicators, (indicator, i) => {
                return (_openBlock(), _createBlock(_component_SimpleListItem, {
                  key: 'ci' + i,
                  text: _ctx.$ct(indicator.name),
                  "sub-text": _ctx.$ct(indicator.hint),
                  "is-selected": indicator.key === _ctx.selectedKey,
                  "is-first": i === 0,
                  "is-last": i === _ctx.commonIndicators.length - 1,
                  onClicked: ($event: any) => (_ctx.selectIndicator(indicator))
                }, null, 8, ["text", "sub-text", "is-selected", "is-first", "is-last", "onClicked"]))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (_ctx.selectedTabKey === 'types')
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.typeIndicators, (indicator, i) => {
                return (_openBlock(), _createBlock(_component_SimpleListItem, {
                  key: 'ci' + i,
                  text: _ctx.$ct(indicator.name),
                  "sub-text": _ctx.$ct(indicator.hint),
                  "is-selected": indicator.key === _ctx.selectedKey,
                  "is-first": i === 0,
                  "is-last": i === _ctx.typeIndicators.length - 1,
                  onClicked: ($event: any) => (_ctx.selectIndicator(indicator))
                }, null, 8, ["text", "sub-text", "is-selected", "is-first", "is-last", "onClicked"]))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (_ctx.selectedTabKey === 'form')
          ? (_openBlock(), _createBlock(_component_RiskSourceFormWizard, {
              key: 2,
              onSelected: _ctx.onSelectFormIndicator
            }, null, 8, ["onSelected"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["is-visible", "header", "progress", "action-text", "onCloseModal", "onAction"]))
}