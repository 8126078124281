import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5adc8205"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fbav3-input-checkbox font-roboto" }
const _hoisted_2 = ["id", "value", "checked", "onChange"]
const _hoisted_3 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "flex items-center mb-1 text-sm",
        key: item.key
      }, [
        _createElementVNode("input", {
          type: "checkbox",
          id: `${_ctx.uniqueId}-${item.key}`,
          value: item.key,
          checked: _ctx.isPicked(item.key),
          onChange: ($event: any) => (_ctx.onchange(item))
        }, null, 40, _hoisted_2),
        _createElementVNode("label", {
          for: `${_ctx.uniqueId}-${item.key}`,
          class: "ml-2 mb-0 text-sm"
        }, _toDisplayString(item.text), 9, _hoisted_3)
      ]))
    }), 128))
  ]))
}