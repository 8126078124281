<template>
    <div>

        <div
            class="amlv3-expll-top"
            :class="[
                {'amlv3-expll-top-expandable': canExpand },
            ]"
            @click="$emit('expand', !expanded)"
        >

            <div class="amlv3-expll-top-left">

                <div class="amlv3-expll-top-content">

                    <slot name="top" />

                </div>

                <div class="amlv3-expll-chevron" v-if="canExpand">
                    <FontIcon :icon="expanded ? 'chevron-up' : 'chevron-down'" :size="16" />
                </div>

            </div>

            <div class="amlv3-expll-top-border" />

        </div>

        <div class="amlv3-expll-content">

            <div class="amlv3-expll-items" v-if="expanded">

                <slot name="expanded" />

            </div>

            <div class="amlv3-expll-single" v-if="!expanded">

                <slot name="collapsed" />

            </div>

            <div class="amlv3-expll-buttons" v-if="buttonText && !expanded">
                <ButtonRow :alignment="ButtonAlignment.Right">
                    <ButtonSingle
                        is-link
                        :text="buttonText"
                        @clicked="$emit('clicked')"
                    />
                </ButtonRow>
            </div>

        </div>

    </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

import FontIcon from "@/ui/FontIcon.vue";
import { ButtonAlignment } from "@/types/ui_types";
import ButtonRow from "@/ui/ButtonRow.vue";
import ButtonSingle from "@/ui/ButtonSingle.vue";

export default defineComponent({
    name: "ExpandableListLayout",
    components: {
        FontIcon,
        ButtonRow,
        ButtonSingle,
    },
    emits: ["expand", "clicked"],
    props: {
        expanded: { type: Boolean as PropType<boolean>, default: false },
        canExpand: { type: Boolean as PropType<boolean>, default: true },
        buttonText: { type: String as PropType<string>, default: "" },
    },
    data() {
        return {
            ButtonAlignment,
        };
    },
});
</script>
<style scoped>
.amlv3-expll-top {
    padding: 0px 20px;
    padding-top: 10px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border: solid 1px lightgray;
    border-bottom: none;
}

.amlv3-expll-top-expandable {
    cursor: pointer;
}
.amlv3-expll-top-expandable:hover {
    background-color: whitesmoke;
}

.amlv3-expll-top-left {
    display: flex;
    padding-bottom: 7px;
}

.amlv3-expll-top-content {
    flex-grow: 1;
    display: flex;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.9px;
}

.amlv3-expll-chevron {
    color: gray;
}

.amlv3-expll-top-border {
    border-bottom: solid 1px lightgray;
}

.amlv3-expll-content {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border: solid 1px lightgray;
    border-top: none;
}

.amlv3-expll-items {

}

.amlv3-expll-single {

}

.amlv3-expll-buttons {
    padding-right: 20px;
    padding-bottom: 15px;
}

</style>
