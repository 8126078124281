<template>
    <ExpandableListLayout
        :can-expand="false"
        :expanded="links.length === 0"
        :button-text="$t('project.otherProjects.showAll')"
        @clicked="$emit('menu-tab', 'events')"
    >
        <template #top>

            {{ $t('project.otherProjects.title') }}

        </template>
        <template #expanded>

            <div style="padding: 5px 30px; padding-bottom: 10px; font-size: 14px; color: gray;">
                {{ $t('project.otherProjects.empty') }}
            </div>

        </template>
        <template #collapsed>
            <div class="amlv3-otherprojects-links">
                <div
                    v-for="(link, i) in links"
                    :key="'opp' + i"
                    class="amlv3-otherprojects-link"
                >
                    <div class="amlv3-otherprojects-link-left">

                        <div class="amlv3-otherprojects-link-top">
                            {{ $ct(link.workflow) }}
                        </div>

                        <MenuInfoLine
                            :info1="link.name"
                            :info2="link.assignee"
                        />

                    </div>

                    <div class="amlv3-otherprojects-link-arrow" @click="$emit('project-link', link.id)">
                        <FontIcon :icon="'right-long'" />
                    </div>

                </div>
            </div>

        </template>
    </ExpandableListLayout>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

import { Project } from "@/lib/projects/projects";
import { queryProjects } from "@/lib/projects/get_project";
import ExpandableListLayout from "./menu/ExpandableListLayout.vue";
import FontIcon from "@/ui/FontIcon.vue";
import { LocalizedString } from "@/lib/common/common";
import MenuInfoLine from "./menu/MenuInfoLine.vue";

interface ProjectLink {
    id: string;
    name: string;
    workflow: LocalizedString;
    assignee: string;
}

export default defineComponent({
    name: "OtherProjects",
    components: {
        ExpandableListLayout,
        FontIcon,
        MenuInfoLine,
    },
    emits: ["menu-tab", "project-link"],
    props: {
        project: { type: Object as PropType<Project>, required: true },
    },
    data() {
        return {
            expanded: false,
            loading: false,
            links: new Array<ProjectLink>(),
        };
    },
    methods: {

        async fetchProjects() {
            this.loading = true;

            const { projects } = await queryProjects(
                0,
                3,
                "",
                undefined,
                undefined,
                undefined,
                this.project.identity,
                undefined,
                undefined,
                undefined,
                undefined,
            );

            const workflows = this.$config.settings.workflows || [];
            const employees = await this.$assets.getEmployees();

            for (const project of projects) {
                if (project.id === this.project.id) continue;

                const link: ProjectLink = {
                    id: project.id,
                    name: project.name,
                    workflow: { value: project.workflow },
                    assignee: project.assignee,
                };

                for (const workflow of workflows) {
                    if (workflow.key === project.workflow) link.workflow = workflow.name;
                }

                for (const employee of employees) {
                    if (employee.uid === project.assignee) {
                        link.assignee = employee.givenName + " " + employee.familyName;
                    }
                }

                this.links.push(link);
            }

            /* console.log("links", this.links); */
            this.loading = false;
        },

    },
    created() {
        this.fetchProjects();
    },
});
</script>
<style scoped>
.amlv3-otherprojects-links {
    padding: 0px 20px;
    padding-bottom: 5px;
}

.amlv3-otherprojects-link {
    display: flex;
    padding: 3px 0px;
    border-bottom: solid 1px lightgray;
}

.amlv3-otherprojects-link-left {
    flex-grow: 1;
}

.amlv3-otherprojects-link-top {
    font-size: 15px;
}

.amlv3-otherprojects-link-arrow {
    flex-shrink: 0;
    padding-top: 6px;
    color: dimgray;
    cursor: pointer;
}
.amlv3-otherprojects-link-arrow:hover {
    opacity: 0.7;
}
</style>
