<template>
    <div class="group/name flex relative">
        <div
            ref="overflowText"
            class="text-xl font-semibold group-hover:text-primary-default truncate"
            :style="{ 'max-width': `${maxLength}ch` }"
        >
            {{ text }}
        </div>
        <span
            v-if="overflow"
            class="group-hover/name:opacity-100 transition-opacity bg-body p-1 text-sm text-white rounded-md absolute opacity-0 m-4 mx-auto"
            style="inset: auto auto 0px 0px; margin: 0px; transform: translate3d(0px, -25px, 0px)"
        >{{ text }}</span>
    </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    name: "OverflowText",
    props: {
        text: {
            type: String,
            default: "",
        },
        maxLength: {
            type: Number,
            default: 20,
        },
    },
    data() {
        return {};
    },
    computed: {
        overflow(): boolean {
            return this.text.length > this.maxLength;
        },
    },
    created() {},
    mounted() {},
});
</script>
