import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PopupDialogInfoIconAndText = _resolveComponent("PopupDialogInfoIconAndText")!
  const _component_InputLabel = _resolveComponent("InputLabel")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_InputGroup = _resolveComponent("InputGroup")!
  const _component_InputRow = _resolveComponent("InputRow")!
  const _component_ButtonSingle = _resolveComponent("ButtonSingle")!
  const _component_ButtonRow = _resolveComponent("ButtonRow")!
  const _component_PopupDialogSplit = _resolveComponent("PopupDialogSplit")!
  const _component_PopupDialog = _resolveComponent("PopupDialog")!
  const _component_ContactListDialog = _resolveComponent("ContactListDialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_PopupDialog, {
      "is-visible": _ctx.dialog.visible,
      progress: _ctx.dialog,
      header: _ctx.$t('project.forms.kyc.title'),
      icon: 'list-check',
      "show-cancel-button": true,
      "show-action-button": true,
      "action-text": _ctx.checkModel.send ? _ctx.$t('project.forms.kyc.sendAction') : _ctx.$t('project.forms.kyc.openAction'),
      "action-icon": _ctx.checkModel.send ? 'share' : 'arrow-up-right-from-square',
      onAction: _ctx.startForm,
      onCloseModal: _ctx.onClose
    }, {
      default: _withCtx(() => [
        _createVNode(_component_PopupDialogSplit, {
          "limit-width": "",
          "prompt-text": _ctx.$t('project.forms.kyc.promptText'),
          "prompt-hint": _ctx.$t('project.forms.kyc.promptHint')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_PopupDialogInfoIconAndText, {
              text: _ctx.$t('project.forms.kyc.hint')
            }, null, 8, ["text"]),
            (_ctx.checkModel.send)
              ? (_openBlock(), _createBlock(_component_InputRow, { key: 0 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_InputGroup, { width: 6 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_InputLabel, {
                          text: _ctx.$t('common.person_info.firstName'),
                          "unique-id": 'kyc-first-name',
                          required: ""
                        }, null, 8, ["text"]),
                        _createVNode(_component_InputText, {
                          modelValue: _ctx.editorModel.firstName,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editorModel.firstName) = $event)),
                          "unique-id": 'kyc-first-name',
                          onInput: _ctx.validateDialog
                        }, null, 8, ["modelValue", "onInput"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_InputGroup, { width: 6 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_InputLabel, {
                          text: _ctx.$t('common.person_info.lastName'),
                          "unique-id": 'kyc-last-name',
                          required: ""
                        }, null, 8, ["text"]),
                        _createVNode(_component_InputText, {
                          modelValue: _ctx.editorModel.lastName,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editorModel.lastName) = $event)),
                          "unique-id": 'kyc-last-name',
                          onInput: _ctx.validateDialog
                        }, null, 8, ["modelValue", "onInput"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_InputGroup, { width: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_InputLabel, {
                          text: _ctx.$t('common.person_info.email'),
                          "unique-id": 'kyc-email',
                          required: ""
                        }, null, 8, ["text"]),
                        _createVNode(_component_InputText, {
                          modelValue: _ctx.editorModel.email,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editorModel.email) = $event)),
                          "unique-id": 'kyc-email',
                          onInput: _ctx.validateDialog
                        }, null, 8, ["modelValue", "onInput"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.checkModel.send)
              ? (_openBlock(), _createBlock(_component_ButtonRow, {
                  key: 1,
                  style: {"padding-top":"5px","padding-right":"10px"},
                  alignment: _ctx.ButtonAlignment.Right
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ButtonSingle, {
                      "is-link": "",
                      text: _ctx.$t('project.forms.kyc.instruction'),
                      onClicked: _ctx.onShowContactDialog
                    }, null, 8, ["text", "onClicked"])
                  ]),
                  _: 1
                }, 8, ["alignment"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["prompt-text", "prompt-hint"])
      ]),
      _: 1
    }, 8, ["is-visible", "progress", "header", "action-text", "action-icon", "onAction", "onCloseModal"]),
    (_ctx.contactDialog.visible)
      ? (_openBlock(), _createBlock(_component_ContactListDialog, {
          key: 0,
          contacts: _ctx.contacts,
          onCloseModal: _ctx.onCloseContactDialog,
          onSelected: _ctx.onSelectContact
        }, null, 8, ["contacts", "onCloseModal", "onSelected"]))
      : _createCommentVNode("", true)
  ]))
}