import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CompanySearchInput = _resolveComponent("CompanySearchInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CompanySearchInput, {
      onCompanySelected: _ctx.onCompanySelected,
      country: _ctx.country,
      onSetManual: _ctx.setManual
    }, null, 8, ["onCompanySelected", "country", "onSetManual"])
  ]))
}