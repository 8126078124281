<template>
    <div>

        <div
            v-for="(source, i) in logicList"
            :key="'rsc' + i"
            class="riskv3-logicctrl-item"
            :class="[
                {'riskv3-logicctrl-item-first': i === 0 },
                {'riskv3-logicctrl-item-last': i === logicList.length - 1 },
            ]"
        >

            <div class="riskv3-logicctrl-source">

                <div class="riskv3-logicctrl-icon">
                    <FontIcon :icon="getIcon(source)" :size="14" />
                </div>

                <div class="riskv3-logicctrl-text">
                    {{ getSourceName(source) }}
                </div>

            </div>

            <div class="riskv3-logicctrl-delete" @click="showDeleteDialog(source)">
                <FontIcon :icon="'trash-can'" :size="14" />
            </div>

        </div>

        <PopupDialog
            :is-visible="deleteDialog.visible"
            :header="$t('common.actions.delete')"
            :icon="'trash-can'"
            :prompt="$t('common.actions.areYouSure')"
            :show-action-button="true"
            :show-cancel-button="true"
            :action-text="$t('common.actions.delete')"
            :is-warning="true"
            @close-modal="deleteDialog.visible = false"
            @action="onDelete"
        />

    </div>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";

import FontIcon from "@/ui/FontIcon.vue";
import PopupDialog from "@/ui/PopupDialog.vue";
import { RiskLogic, RiskLogicType } from "@/lib/risk/risk_matrix";

export default defineComponent({
    name: "RiskLogicControl",
    components: {
        PopupDialog,
        FontIcon,
    },
    emits: ["delete"],
    props: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        logicList: { type: Array as PropType<any[]>, required: true },
    },
    data() {
        return {
            deleteDialog: { visible: false, key: "" },
        };
    },
    methods: {

        getIcon(logic: RiskLogic) {
            switch (logic.type) {
            case RiskLogicType.Flag: return "flag";
            case RiskLogicType.Logic: return "square-root-variable";
            }
            return "circle-question";
        },

        getSourceName(logic: RiskLogic) {
            return logic.key;
        },

        showDeleteDialog(logic: RiskLogic) {
            this.deleteDialog.key = logic.key;
            this.deleteDialog.visible = true;
        },

        onDelete() {
            this.$emit("delete", this.deleteDialog.key);
            this.deleteDialog.visible = false;
        },

    },
});
</script>
<style scoped>
.riskv3-logicctrl-item {
    display: flex;
    width: 100%;
}

.riskv3-logicctrl-source {
    flex-grow: 1;
    display: flex;
    padding: 2px 6px;
    border: solid 1px lightgray;
    border-top: none;
    border-right: none;
    background-color: whitesmoke;
}

.riskv3-logicctrl-item-first .riskv3-logicctrl-source {
    border-top: solid 1px lightgray;
    border-top-left-radius: 4px;
}
.riskv3-logicctrl-item-last .riskv3-logicctrl-source {
    border-bottom-left-radius: 4px;
}

.riskv3-logicctrl-icon {
    width: 16px;
    text-align: center;
    padding-top: 2px;
    margin-right: 8px;
}

.riskv3-logicctrl-text {
    max-width: 150px;
    padding-top: 1px;
    font-size: 15px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.riskv3-logicctrl-text2 {
    max-width: 250px;
    padding-top: 1px;
    font-size: 15px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.riskv3-logicctrl-arrow {
    padding-top: 2px;
    margin: 0px 8px;
}

.riskv3-logicctrl-delete {
    width: 28px;
    padding-top: 4px;
    text-align: center;
    border: solid 1px lightgray;
    border-top: none;
    background-color: whitesmoke;
    cursor: pointer;
}

.riskv3-logicctrl-item-first .riskv3-logicctrl-delete {
    border-top: solid 1px lightgray;
    border-top-right-radius: 4px;
}
.riskv3-logicctrl-item-last .riskv3-logicctrl-delete {
    border-bottom-right-radius: 4px;
}

.riskv3-logicctrl-delete:hover {
    background-color: lightgray;
}
</style>
