<template>
    <button
        :class="[
            `bg-${bgColor}`,
            `text-${textColor}`,
            `rounded-${roundStyle}`,
            borderColor ? `border-${borderColor} border ` : '',
            applyWidth ? 'button-width-10' : '',
        ]"
        class="flex items-center justify-center py-2 px-3 font-medium"
        @click="onClick"
    >
        <Icon
            v-if="icon && iconPosition == 'left'"
            :icon="icon"
            :class="extraMargin ? 'mr-5' : 'mr-2'"
            class="ml-1 w-4 h-4"
        />
        {{ text }}
        <Icon
            v-if="icon && iconPosition == 'right'"
            :icon="icon"
            :class="extraMargin ? 'ml-5' : 'ml-2'"
            class="mr-1 w-4 h-4"
        />
    </button>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import Icon from "./Icon.vue";

export default defineComponent({
    name: "CustomButton",
    components: {
        Icon,
    },
    props: {
        text: {
            type: String,
            required: true,
        },
        bgColor: {
            type: String,
            required: true,
        },
        textColor: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            default: "",
        },
        roundStyle: {
            type: String,
            default: "none", // Assuming 'none' means no specific rounding style applied
        },
        extraMargin: {
            type: Boolean,
            default: false,
        },
        applyWidth: {
            type: Boolean,
            default: false,
        },
        onClick: {
            type: Function as PropType<() => void>,
            required: true,
        },
        borderColor: {
            type: String,
            default: "",
        },
        iconPosition: {
            type: String,
            default: "right",
        },
    },
});
</script>

<style>
.button-width-10 {
    width: 182px;
    height: 40px;
}
</style>
