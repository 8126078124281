<template>
    <div class="flex items-center min-h-[72px] py-3 px-1 sm:px-3 gap-2 box-border">
        <div class="w-12 h-12 bg-sky-100 rounded-full flex-none flex items-center justify-center">
            <!-- <Icon class="w-8 h-8 rounded-full p-2" :icon="icon"></Icon> -->
            <FontIcon
                :icon="design.icon"
                :size="20"
                class="text-primary-default"
            />
        </div>

        <div class="min-w-0 pl-1 sm:pl-2 w-full" :class="[expanded ? 'border-l border-light' : '']">
            <div class="flex items-center justify-between truncate">
                <div class="min-w-0">
                    <div class="leading-5 space-x-3">
                        <span class="font-medium">{{ design.title }}</span>
                    </div>
                    <div
                        class="flex items-center gap-x-2 text-sm truncate min-w-0 rounded-md leading-4 text-light-gray whitespace-nowrap mt-1"
                    >
                        <div class="flex place-self-start items-center gap-x-2">
                            <span class="">{{ $d(date, isToday ? "short" : "long") }}</span>
                            <div class="w-1 h-1 flex-shrink-0 rounded-full bg-light-gray"></div>
                        </div>
                        <span class="truncate min-w-0 whitespace-pre-line">{{ design.description }}</span>
                    </div>
                </div>
            </div>
            <!-- <Transition
                enter-active-class="transition duration-100 ease-out overflow-hidden"
                leave-active-class="transition duration-100 ease-in overflow-hidden"
                leave-from-class="transform scale-100 opacity-100"
                leave-to-class="transform scale-95 opacity-0"
                enter-from-class="transform scale-95 opacity-0"
                enter-to-class="transform scale-100 opacity-100"
            >
                <div class="flex flex-col mt-2 text-sm font-" v-if="expanded === true">
                    <div class="flex bg-gray-50 rounded-lg mb-4">
                        <div class="basis-1/2 leading-6 border-r border-light p-2">
                            <div class="flex items-center gap-3">
                                <Icon class="w-3 h-3" :icon="'info'"></Icon>
                                <span>Details:</span>
                            </div>

                            <div class="text-gray-400 font-light text-xs pt-3">
                                {{ description }}
                            </div>
                        </div>
                    </div>
                </div>
            </Transition> -->
        </div>
    </div>
</template>
<script lang="ts">
import Badge from "@/components/Badge.vue";
import FontIcon from "@/ui/FontIcon.vue";
import { format, isEqual } from "date-fns";
import { PropType, computed, defineComponent, ref } from "vue";
import Icon from "../components/Icon.vue";
import { Event } from "../lib/events/events";
import { useI18n } from "vue-i18n";

export default defineComponent({
    name: "EventCard",
    components: {
        Icon,
        Badge,
        FontIcon,
    },
    props: {
        event: {
            type: {} as PropType<Event>,
            required: true,
        },
    },
    setup(props, context) {
        const date = new Date(props.event.timestamp);
        const expanded = ref(false);
        const { t } = useI18n();

        const handleClick = () => {
            expanded.value = !expanded.value;
        };

        const iconBgColor = computed(() => {
            switch (props.event?.type) {
            case "final-report":
            case "screening":
                return "bg-red-100";
            case "self-declaration":
            case "id-control":
                return "bg-sky-100";
            case "add-person":
                return "bg-gray-100";
            default:
                return "bg-gray-100";
            }
        });

        const isCurrentMonth = computed(() => {
            if (date.getMonth() === new Date().getMonth()) {
                return true;
            }
            return false;
        });

        const isToday = computed(() => {
            return date ? isEqual(format(date, "yyyy-MM-dd"), format(new Date(), "yyyy-MM-dd")) : false;
        });

        const data = props.event.data;
        const style = (icon: string, title: string, description: string) => {
            return { icon, title, description };
        };

        const design = computed(() => {
            const data = props.event.data;
            switch (props.event.type) {
            case "project_created":
                return style("briefcase", data?.resource?.name, t("eventText.customerCreated"));
            case "project_updated": {
                return style("briefcase", data?.resource?.name, t("eventText.customerUpdated"));
            }
            case "person_created":
                return style("user-plus", data?.resource?.name, t("eventText.newPersonAdded"));
            case "project_company_lookup":
                return style(
                    "magnifying-glass",
                    data?.resource?.name,
                    t("eventText.companyLookupWitBlocks", { blocks: data?.lookup?.blocks?.map((block: string) => t("project.tabs.company.blocks.refereshTab." + block)).join(", ") }),
                );
            case "link_created":
                return style(
                    "people-arrows",
                    `${data?.project?.name} ↔ ${data?.person?.name}`,
                    t("eventText.personLinkedToProjectWithRoles",
                        { personName: data?.person?.name, projectName: data?.project?.name, roles: data?.roles?.map((role: string) => t("common.role." + role)).join(", ") }),
                );
            case "link_updated":
                let msg = "";
                if (data?.roles?.added?.length) {
                    msg += t("eventText.rolesAdded", { rolesAdded: data?.roles?.added?.map((role: string) => t("common.role." + role)).join(", "), issuerName: data?.issuer?.name });
                }
                if (data?.roles?.removed?.length) {
                    if (msg.length) {
                        msg += "\n";
                    }
                    msg += t("eventText.rolesRemoved", { rolesRemoved: data?.roles?.removed?.map((role: string) => t("common.role." + role)).join(", "), issuerName: data?.issuer?.name });
                }
                return style("people-arrows", `${data?.project?.name} ↔ ${data?.person?.name}`, msg);
            case "person_monitor_toggled":
                return style(data?.monitor ? "magnifying-glass-plus" : "magnifying-glass-minus", t("screening.title"),
                    data?.monitor ? t("eventText.personMonitoringStarted", { resourceName: data?.resource?.name }) : t("eventText.personMonitoringStopped", { resourceName: data?.resource?.name }));
            default:
                return style("envelope", props.event.type, "");
            }
        });

        return {
            expanded,
            handleClick,
            iconBgColor,
            isCurrentMonth,
            isToday,
            date,
            design,
        };

        const showDetails = () => {};
    },
});
</script>
<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease;
}
.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
