<template>
    <div
        class="amlv3-rsksourceitm"
        @click="onClick"
        :class="[
            { 'amlv3-rsksourceitm-first': isFirst },
            { 'amlv3-rsksourceitm-last': isLast },
            { 'amlv3-rsksourceitm-selected': isSelected },
            { 'amlv3-rsksourceitm-disabled': isDisabled() },
        ]"
    >

        <div class="amlv3-rsksourceitm-row">
            <div class="amlv3-rsksourceitm-row-left">
                {{ getName() }}
            </div>
            <div class="amlv3-rsksourceitm-row-right">
                {{ getTypeName() }}
            </div>
        </div>

        <div class="amlv3-rsksourceitm-row">
            <div class="amlv3-rsksourceitm-row-info">
                {{ item.hint }}
            </div>
            <div v-if="isDisabled()" class="amlv3-rsksourceitm-row-icon">
                <FontIcon :icon="'triangle-exclamation'" :size="15" />
            </div>
        </div>

    </div>
</template>
<script lang="ts">
import FontIcon from "@/ui/FontIcon.vue";
import { defineComponent, type PropType } from "vue";
import { getValueTypeName } from "../risk_helper";
import { isValidSource, RiskSourceKeyItem } from "./risk_sources";
import { RiskValueType } from "@/lib/risk/risk_matrix";

export default defineComponent({
    name: "RiskSourceItem",
    components: {
        FontIcon,
    },
    emits: ["clicked"],
    props: {
        item: { type: Object as PropType<RiskSourceKeyItem>, required: true },
        isSelected: { type: Boolean as PropType<boolean>, default: false },
        isFirst: { type: Boolean as PropType<boolean>, default: false },
        isLast: { type: Boolean as PropType<boolean>, default: false },
        valueType: { type: String as PropType<RiskValueType>, default: "" },
    },
    data() {
        return {
            loading: true,
        };
    },
    methods: {

        isDisabled() {
            return !isValidSource(this.item, this.valueType);
        },

        getName() {
            if (this.item.name.length > 150) {
                return this.item.name.substring(0, 147) + "...";
            }
            return this.item.name;
        },

        getTypeName() {
            return getValueTypeName(this.item.type);
        },

        onClick() {
            if (this.isDisabled()) return;
            this.$emit("clicked");
        },

    },
});
</script>
<style scoped>
.amlv3-rsksourceitm {
    padding: 5px 15px;
    border: solid 1px lightgray;
    border-bottom: none;
    background-color: white;
    cursor: pointer;
}

.amlv3-rsksourceitm:hover {
    background-color: whitesmoke;
}

.amlv3-rsksourceitm-first {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.amlv3-rsksourceitm-last {
    border-bottom: solid 1px lightgray;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.amlv3-rsksourceitm-selected {
    background-color: lightgray;
}

.amlv3-rsksourceitm-selected:hover {
    background-color: lightgray;
}

.amlv3-rsksourceitm-row {
    display: flex;
}

.amlv3-rsksourceitm-row-left {
    flex-grow: 1;
    padding-right: 20px;
    font-size: 15px;
    font-weight: 500;
}

.amlv3-rsksourceitm-row-right {
    flex-shrink: 0;
}

.amlv3-rsksourceitm-row-info {
    flex-grow: 1;
    padding-right: 20px;
    font-size: 14px;
    /* font-weight: 500; */
    color: dimgray;
}

.amlv3-rsksourceitm-row-icon {
    flex-shrink: 0;
    color: dimgray;
}

/* Disabled */

.amlv3-rsksourceitm-disabled {
    cursor: not-allowed;
}

.amlv3-rsksourceitm-disabled:hover {
    background-color: transparent;
}

.amlv3-rsksourceitm-disabled .amlv3-rsksourceitm-row-left {
    color: lightgray;
}

.amlv3-rsksourceitm-disabled .amlv3-rsksourceitm-row-info {
    color: lightgray;
}

.amlv3-rsksourceitm-disabled .amlv3-rsksourceitm-row-right {
    color: lightgray;
}
</style>
