import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-530faabe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "amlv3-rsksourceitm-row" }
const _hoisted_2 = { class: "amlv3-rsksourceitm-row-left" }
const _hoisted_3 = { class: "amlv3-rsksourceitm-row-right" }
const _hoisted_4 = { class: "amlv3-rsksourceitm-row" }
const _hoisted_5 = { class: "amlv3-rsksourceitm-row-info" }
const _hoisted_6 = {
  key: 0,
  class: "amlv3-rsksourceitm-row-icon"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontIcon = _resolveComponent("FontIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["amlv3-rsksourceitm", [
            { 'amlv3-rsksourceitm-first': _ctx.isFirst },
            { 'amlv3-rsksourceitm-last': _ctx.isLast },
            { 'amlv3-rsksourceitm-selected': _ctx.isSelected },
            { 'amlv3-rsksourceitm-disabled': _ctx.isDisabled() },
        ]]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.getName()), 1),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.getTypeName()), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.item.hint), 1),
      (_ctx.isDisabled())
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_FontIcon, {
              icon: 'triangle-exclamation',
              size: 15
            })
          ]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}