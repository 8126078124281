import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputLabel = _resolveComponent("InputLabel")!
  const _component_InputTextLocalized = _resolveComponent("InputTextLocalized")!
  const _component_InputGroup = _resolveComponent("InputGroup")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_InputDropdown = _resolveComponent("InputDropdown")!
  const _component_PopupDialogSplit = _resolveComponent("PopupDialogSplit")!
  const _component_PopupDialog = _resolveComponent("PopupDialog")!

  return (_openBlock(), _createBlock(_component_PopupDialog, {
    "is-visible": _ctx.dialog.visible,
    header: _ctx.$t('risk.comment.add'),
    icon: 'comment',
    "show-action-button": true,
    "show-cancel-button": true,
    "is-action-disabled": !_ctx.dialog.valid,
    "action-text": _ctx.$t('risk.comment.add'),
    "action-icon": 'plus',
    progress: _ctx.dialog,
    onCloseModal: _ctx.onClose,
    onAction: _ctx.addComment
  }, {
    default: _withCtx(() => [
      (_ctx.isLoaded)
        ? (_openBlock(), _createBlock(_component_PopupDialogSplit, {
            key: 0,
            "is-form": "",
            "prompt-text": _ctx.promptText,
            "prompt-hint": _ctx.promptHint
          }, {
            default: _withCtx(() => [
              _createVNode(_component_InputGroup, { width: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_InputLabel, {
                    text: 'Prompt',
                    "unique-id": 'end-name',
                    required: ""
                  }),
                  _createVNode(_component_InputTextLocalized, {
                    modelValue: _ctx.model.prompt,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.prompt) = $event)),
                    "unique-id": 'end-prompt',
                    onInput: _ctx.validateDialog
                  }, null, 8, ["modelValue", "onInput"])
                ]),
                _: 1
              }),
              _createVNode(_component_InputGroup, { width: 4 }, {
                default: _withCtx(() => [
                  _createVNode(_component_InputLabel, {
                    text: 'Key',
                    "unique-id": 'end-key',
                    required: ""
                  }),
                  _createVNode(_component_InputText, {
                    modelValue: _ctx.model.key,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.key) = $event)),
                    "unique-id": 'end-key',
                    onInput: _ctx.validateDialog
                  }, null, 8, ["modelValue", "onInput"])
                ]),
                _: 1
              }),
              _createVNode(_component_InputGroup, { width: 8 }, {
                default: _withCtx(() => [
                  _createVNode(_component_InputLabel, {
                    text: 'Trigger',
                    "unique-id": 'end-config',
                    required: ""
                  }),
                  _createVNode(_component_InputDropdown, {
                    modelValue: _ctx.model.simpleTrigger,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.simpleTrigger) = $event)),
                    items: _ctx.simpleTriggers,
                    "unique-id": 'end-trigger',
                    onInput: _ctx.validateDialog
                  }, null, 8, ["modelValue", "items", "onInput"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["prompt-text", "prompt-hint"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["is-visible", "header", "is-action-disabled", "action-text", "progress", "onCloseModal", "onAction"]))
}