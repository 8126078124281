import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "amlv3-pop-split-top" }
const _hoisted_2 = {
  key: 0,
  class: "amlv3-pop-split-text"
}
const _hoisted_3 = {
  key: 1,
  class: "amlv3-pop-split-hint"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
            { 'amlv3-pop-split-limit': _ctx.limitWidth },
        ])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.promptText)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.promptText), 1))
        : _createCommentVNode("", true),
      (_ctx.promptHint)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.promptHint), 1))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "top")
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["amlv3-pop-split-content", [
                { 'amlv3-pop-split-form': _ctx.isForm },
            ]])
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2)
  ], 2))
}