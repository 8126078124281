<template>
    <div class="relative border-2 border-dashed rounded-lg p-6 max-w-sm mx-auto my-3">
        <div class="text-center">
            <FontIcon icon="cloud-arrow-up" :size="40" />
            <h3 class="mt-4 text-lg font-medium text-gray-900">
                {{ isUploading ? 'Uploading...' : 'Drag & Drop or Click to Upload' }}
            </h3>
            <p class="mt-1 text-sm text-gray-500">
                {{ isUploading ? 'File uploading...' : 'Upload your file here' }}
            </p>
        </div>
        <input
            type="file"
            class="absolute w-full h-full inset-0 opacity-0 cursor-pointer"
            @change="handleFileChange"
            ref="fileInput"
        />
        <div
            v-if="isUploading"
            class="absolute bottom-4 left-4 right-4 bg-gray-100 rounded-lg p-2"
        >
            <div class="flex items-center justify-between">
                <span class="text-sm font-medium text-gray-700">
                    {{ progress }}%
                </span>
                <div class="w-full bg-gray-200 rounded-full h-2">
                    <div
                        class="bg-blue-500 rounded-full h-2"
                        :style="{ width: `${progress}%` }"
                    ></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import FontIcon from "../FontIcon.vue";

export default defineComponent({
    name: "FileUpload",
    components: {
        FontIcon,
    },
    props: {
        progress: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            isUploading: false,
            selectedFile: null as File | null,
        };
    },
    emits: ["file-uploaded"],
    methods: {
        handleFileChange(event: Event) {
            const file = (event.target as HTMLInputElement).files?.[0];
            if (file) {
                this.selectedFile = file;
                this.uploadFile();
            }
        },
        uploadFile() {
            if (this.selectedFile) {
                console.log("Uploading: ", this.selectedFile);
                this.isUploading = true;
                this.$emit("file-uploaded", {
                    file: this.selectedFile,
                    done: () => {
                        this.isUploading = false;
                        this.selectedFile = null;
                    },
                });
            }
        },
        handleDragOver(event: DragEvent) {
            event.preventDefault();
            event.dataTransfer!.dropEffect = "copy";
        },
        handleDrop(event: DragEvent) {
            event.preventDefault();
            const file = event.dataTransfer?.files?.[0];
            if (file) {
                this.selectedFile = file;
                this.uploadFile();
            }
        },
        handleFileInputChange() {
            (this.$refs.fileInput as HTMLInputElement).click();
        },
    },
    mounted() {
        window.addEventListener("dragover", this.handleDragOver);
        window.addEventListener("drop", this.handleDrop);
    },
    beforeUnmount() {
        window.removeEventListener("dragover", this.handleDragOver);
        window.removeEventListener("drop", this.handleDrop);
    },
});
</script>

  <style scoped>
  .border-dashed {
    border-style: dashed;
  }
  </style>
