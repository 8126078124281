import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass([[
            `bg-${_ctx.bgColor}`,
            `text-${_ctx.textColor}`,
            `rounded-${_ctx.roundStyle}`,
            _ctx.borderColor ? `border-${_ctx.borderColor} border ` : '',
            _ctx.applyWidth ? 'button-width-10' : '',
        ], "flex items-center justify-center py-2 px-3 font-medium"]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
  }, [
    (_ctx.icon && _ctx.iconPosition == 'left')
      ? (_openBlock(), _createBlock(_component_Icon, {
          key: 0,
          icon: _ctx.icon,
          class: _normalizeClass([_ctx.extraMargin ? 'mr-5' : 'mr-2', "ml-1 w-4 h-4"])
        }, null, 8, ["icon", "class"]))
      : _createCommentVNode("", true),
    _createTextVNode(" " + _toDisplayString(_ctx.text) + " ", 1),
    (_ctx.icon && _ctx.iconPosition == 'right')
      ? (_openBlock(), _createBlock(_component_Icon, {
          key: 1,
          icon: _ctx.icon,
          class: _normalizeClass([_ctx.extraMargin ? 'ml-5' : 'ml-2', "mr-1 w-4 h-4"])
        }, null, 8, ["icon", "class"]))
      : _createCommentVNode("", true)
  ], 2))
}