import { ApiResponse, parseLocalError, parseRemoteError } from "../error_parser";
import { Verified } from "../verified_plugin";
import { FormTemplate } from "./forms";

export const getFormTemplates = async (): Promise<
    { templates: FormTemplate[] | null, responseInfo: ApiResponse }
> => {
    try {
        const response = await fetch("/api/aml/v1/formtemplates", {
            method: "GET",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
            signal: AbortSignal.timeout(5000),
        });

        const body = await response.json();

        if (!response.ok) {
            return {
                templates: null,
                responseInfo: { isError: true, parsedError: parseRemoteError(body) },
            };
        }

        return {
            templates: body.templates as FormTemplate[],
            responseInfo: { isError: false, parsedError: null },
        };
    } catch (error) {
        return {
            templates: null,
            responseInfo: { isError: true, parsedError: parseLocalError(error) },
        };
    }
};
