import { ApiResponse, parseLocalError, parseRemoteError } from "../error_parser";
import { Verified } from "../verified_plugin";

export interface ApproverRequestModel {
    uid: string;
    givenName: string;
    familyName: string;
    email: string;
}

export interface StartApprovalRequest {
    key: string;
    approvers: ApproverRequestModel[];
}

export interface MakeDecisionOptions {
    approve: boolean;
    comment?: string;
}

export const startApproval = async (
    projectId: string,
    assessmentId: string,
    options: StartApprovalRequest,
): Promise<{ wasStarted: boolean, responseInfo: ApiResponse }> => {
    try {
        const response = await fetch(`/api/aml/v1/projects/${projectId}/assessments/${assessmentId}/approvals`, {
            method: "POST",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
            body: JSON.stringify(options),
        });

        const body = await response.json();

        if (!response.ok) {
            return {
                wasStarted: false,
                responseInfo: { isError: true, parsedError: parseRemoteError(body) },
            };
        }

        return {
            wasStarted: true,
            responseInfo: { isError: false, parsedError: null },
        };
    } catch (error) {
        return {
            wasStarted: false,
            responseInfo: { isError: true, parsedError: parseLocalError(error) },
        };
    }
};

export const makeApprovalDecision = async (
    projectId: string,
    assessmentId: string,
    approvalId: string,
    options: MakeDecisionOptions,
): Promise<{ wasSuccess: boolean, responseInfo: ApiResponse }> => {
    try {
        const response = await fetch(`/api/aml/v1/projects/${projectId}/assessments/${assessmentId}/approvals/${approvalId}/decisions`, {
            method: "POST",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
            body: JSON.stringify(options),
        });

        const body = await response.json();

        if (!response.ok) {
            return {
                wasSuccess: false,
                responseInfo: { isError: true, parsedError: parseRemoteError(body) },
            };
        }

        return {
            wasSuccess: true,
            responseInfo: { isError: false, parsedError: null },
        };
    } catch (error) {
        return {
            wasSuccess: false,
            responseInfo: { isError: true, parsedError: parseLocalError(error) },
        };
    }
};

export const abortApproval = async (
    projectId: string,
    assessmentId: string,
    approvalId: string,
): Promise<{ wasSuccess: boolean, responseInfo: ApiResponse }> => {
    try {
        const response = await fetch(`/api/aml/v1/projects/${projectId}/assessments/${assessmentId}/approvals/${approvalId}/abort`, {
            method: "POST",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
            body: JSON.stringify({}),
        });

        const body = await response.json();

        if (!response.ok) {
            return {
                wasSuccess: false,
                responseInfo: { isError: true, parsedError: parseRemoteError(body) },
            };
        }

        return {
            wasSuccess: true,
            responseInfo: { isError: false, parsedError: null },
        };
    } catch (error) {
        return {
            wasSuccess: false,
            responseInfo: { isError: true, parsedError: parseLocalError(error) },
        };
    }
};

export const deleteApproval = async (
    projectId: string,
    assessmentId: string,
    approvalId: string,
): Promise<{ wasSuccess: boolean, responseInfo: ApiResponse }> => {
    try {
        const response = await fetch(`/api/aml/v1/projects/${projectId}/assessments/${assessmentId}/approvals/${approvalId}`, {
            method: "DELETE",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
        });

        const body = await response.json();

        if (!response.ok) {
            return {
                wasSuccess: false,
                responseInfo: { isError: true, parsedError: parseRemoteError(body) },
            };
        }

        return {
            wasSuccess: true,
            responseInfo: { isError: false, parsedError: null },
        };
    } catch (error) {
        return {
            wasSuccess: false,
            responseInfo: { isError: true, parsedError: parseLocalError(error) },
        };
    }
};
