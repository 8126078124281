<template>
    <div class="amlsetv3-approval-item">

        <div class="amlsetv3-approval-item-text">
            <div class="amlsetv3-approval-item-name">
                {{ $ct(config.name) }}
            </div>
            <div class="amlsetv3-approval-item-key">
                {{ config.key }}
            </div>
        </div>

        <div class="amlsetv3-approval-item-actions">
            <div class="amlsetv3-approval-item-action" @click="$emit('edit')">
                <FontIcon :icon="'pen'" />
            </div>
            <div class="amlsetv3-approval-item-action amlsetv3-approval-item-action-end" @click="$emit('delete')">
                <FontIcon :icon="'trash-can'" />
            </div>
        </div>

    </div>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { mixin as VueClickAway } from "vue3-click-away";

import { ApprovalConfig } from "@/lib/settings/settings";

import FontIcon from "@/ui/FontIcon.vue";

export default defineComponent({
    name: "ApprovalSettingsItem",
    mixins: [VueClickAway],
    components: {
        FontIcon,
    },
    emits: ["edit", "delete"],
    props: {
        config: { type: Object as PropType<ApprovalConfig>, required: true },
    },
    data() {
        return {
            open: false,
        };
    },
    methods: {

        hideDropdown() {
            this.open = false;
        },

        onDeleteClicked() {
            this.hideDropdown();
            this.$emit("delete");
        },

    },
});
</script>
<style scoped>
.amlsetv3-approval-item {
    display: flex;
    margin-top: 10px;
}

.amlsetv3-approval-item-text {
    flex-grow: 1;
    padding: 5px 15px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border: solid 1px lightgray;
    background-color: white;
}

.amlsetv3-approval-item-name {
    font-weight: 500;
}

.amlsetv3-approval-item-key {
    font-size: 14px;
    color: gray;
}

.amlsetv3-approval-item-actions {
    display: flex;
}

.amlsetv3-approval-item-action {
    width: 50px;
    padding-top: 12px;
    text-align: center;
    border: solid 1px lightgray;
    border-left: none;
    background-color: white;
    cursor: pointer;
}
.amlsetv3-approval-item-action:hover {
    background-color: whitesmoke;
}

.amlsetv3-approval-item-action-end {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
</style>
