import { ApiResponse, parseLocalError, parseRemoteError } from "../error_parser";
import { Verified } from "../verified_plugin";

export const addDependency = async (
    projectId: string,
    dependencyId: string,
): Promise<{ wasAdded: boolean, responseInfo: ApiResponse }> => {
    try {
        const response = await fetch(`/api/aml/v1/projects/${projectId}/dependency`, {
            method: "PUT",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
            body: JSON.stringify({
                dependencyId,
            }),
        });

        const body = await response.json();

        if (!response.ok) {
            return {
                wasAdded: false,
                responseInfo: { isError: true, parsedError: parseRemoteError(body) },
            };
        }

        return {
            wasAdded: true,
            responseInfo: { isError: false, parsedError: null },
        };
    } catch (error) {
        return {
            wasAdded: false,
            responseInfo: { isError: true, parsedError: parseLocalError(error) },
        };
    }
};

export const removeDependency = async (
    projectId: string,
): Promise<{ wasRemoved: boolean, responseInfo: ApiResponse }> => {
    try {
        const response = await fetch(`/api/aml/v1/projects/${projectId}/dependency`, {
            method: "DELETE",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
        });

        const body = await response.json();

        if (!response.ok) {
            return {
                wasRemoved: false,
                responseInfo: { isError: true, parsedError: parseRemoteError(body) },
            };
        }

        return {
            wasRemoved: true,
            responseInfo: { isError: false, parsedError: null },
        };
    } catch (error) {
        return {
            wasRemoved: false,
            responseInfo: { isError: true, parsedError: parseLocalError(error) },
        };
    }
};
