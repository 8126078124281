<template>
    <Layout>
        <Nav />
        <Main>
            <!-- Error loading project -->
            <div class="m-10" v-if="projectLoadError">
                <div class="rounded-md p-5 bg-danger-light text-danger-dark font-bold mb-5">
                    {{ projectLoadError }}
                </div>
                <router-link to="/aml/customers">
                    <button class="p-3 bg-primary-default text-white rounded-md hover:bg-primary-dark">
                        {{ $t("project.backToCustomersBtn") }}
                    </button>
                </router-link>
            </div>

            <!-- Project loading -->
            <div class="m-10" v-if="!project && !projectLoadError">
                <div class="w-[400px] h-[60px] mb-3 bg-gray-300 rounded-md animate-pulse"></div>
                <div class="flex gap-8">
                    <div class="mt-2 w-[100px] h-[30px] mb-3 bg-gray-300 rounded-md animate-pulse"></div>
                    <div class="mt-2 w-[100px] h-[30px] mb-3 bg-gray-300 rounded-md animate-pulse"></div>
                    <div class="mt-2 w-[100px] h-[30px] mb-3 bg-gray-300 rounded-md animate-pulse"></div>
                    <div class="mt-2 w-[100px] h-[30px] mb-3 bg-gray-300 rounded-md animate-pulse"></div>
                </div>

                <div class="flex gap-10">
                    <div class="mt-7 w-2/3 h-screen bg-gray-300 rounded-md animate-pulse"></div>
                    <div class="mt-7 w-1/3 h-screen bg-gray-300 rounded-md animate-pulse"></div>
                </div>
            </div>

            <CustomerLayout
                v-if="project && flowRenderData"
                :project="project"
                :show-menu="selectedTab == 'overview'"
                @request-project-refresh="refreshProjectData"
                @delete="deletePrompt.visible = true"
            >
                <template #tabs>

                    <TabControl
                        :tabs="tabs"
                        :selected-tab-key="selectedTab"
                        @tab-changed="onTabChanged"
                    />

                </template>
                <template #content>

                    <OverviewTab
                        v-if="selectedTab == 'overview'"
                        :project="project"
                        :assessments="assessments"
                        :current-assessment="currentAssessment"
                        :forms="forms"
                        :flow-render-data="flowRenderData"
                        :workflow-texts="workflowTexts"
                        @action="handleFlowAction"
                        @request-project-refresh="refreshProjectData"
                    />

                    <CompanyTab
                        v-if="selectedTab == 'company' && project.type === ProjectType.Company"
                        :project="project"
                        @request-project-refresh="refreshProjectData"
                    />

                    <PersonsTab
                        v-if="selectedTab == 'persons'"
                        :persons="persons.persons"
                    />

                    <AssessmentTab
                        v-if="selectedTab == 'risk'"
                        :project="project"
                        :assessments="assessments"
                        :forms="forms"
                        :flow-render-data="flowRenderData"
                        @request-project-refresh="refreshProjectData"
                    />

                </template>
                <template #menu>

                    <OverviewMenu
                        v-if="selectedTab == 'overview'"
                        ref="overviewMenu"
                        :project="project"
                        :forms="forms"
                        :assessments="assessments"
                        :render-data="flowRenderData"
                        :dependency="dependency"
                        @request-project-refresh="refreshProjectData($event)"
                        @project-link="goToProject"
                    />

                </template>
            </CustomerLayout>

            <div v-if="project">
                <!-- Dialogs initiated by the flow -->
                <StartKycFormDialog
                    v-if="startKycDialog.visible"
                    :action="startKycDialog.action"
                    :project="project"
                    @close-modal="startKycDialog.visible = false"
                    @request-project-refresh="refreshProjectData($event)"
                />
                <LockAssessmentDialog
                    v-if="lockDialog.visible"
                    :action="lockDialog.action"
                    :project="project"
                    :assessment="currentAssessment"
                    @close-modal="lockDialog.visible = false"
                    @request-project-refresh="refreshProjectData($event)"
                />
                <SealAssessmentDialog
                    v-if="sealDialog.visible"
                    :action="sealDialog.action"
                    :project="project"
                    :assessment="currentAssessment"
                    @close-modal="sealDialog.visible = false"
                    @request-project-refresh="refreshProjectData($event)"
                />
                <ApprovalSelectDialog
                    v-if="selectApprovalDialog.visible"
                    :action="selectApprovalDialog.action"
                    :project="project"
                    :assessment="currentAssessment"
                    @close-modal="selectApprovalDialog.visible = false"
                    @request-project-refresh="refreshProjectData($event)"
                />
                <ApprovalDecideDialog
                    v-if="decideApprovalDialog.visible"
                    :action="decideApprovalDialog.action"
                    :project="project"
                    :assessment="currentAssessment"
                    @close-modal="decideApprovalDialog.visible = false"
                    @request-project-refresh="refreshProjectData($event)"
                />
                <BatchScreeningDialog
                    v-if="batchScreeningDialog.visible"
                    :action="batchScreeningDialog.action"
                    :project="project"
                    @close-modal="batchScreeningDialog.visible = false"
                    @request-project-refresh="refreshProjectData($event)"
                />
                <DirectFormDialog
                    v-if="directFormDialog.visible"
                    :action="directFormDialog.action"
                    :project="project"
                    :forms="forms"
                    @close-modal="directFormDialog.visible = false"
                    @request-project-refresh="refreshProjectData($event)"
                />
                <LinkDependencyDialog
                    v-if="linkDependencyDialog.visible"
                    :action="linkDependencyDialog.action"
                    :project="project"
                    @close-modal="linkDependencyDialog.visible = false"
                    @request-project-refresh="refreshProjectData($event)"
                />
                <FormLinkDialog
                    v-if="formLinkDialog.visible"
                    :action="formLinkDialog.action"
                    :project="project"
                    :forms="forms"
                    @close-modal="formLinkDialog.visible = false"
                    @request-project-refresh="refreshProjectData($event)"
                />
            </div>

        </Main>

        <!-- Delete Prompt -->
        <PopupDialog
            :is-visible="deletePrompt.visible"
            :header="$t('common.actions.delete')"
            :icon="'trash-can'"
            :show-action-button="true"
            :show-cancel-button="true"
            :action-text="$t('common.actions.delete')"
            :action-icon="'trash-can'"
            :is-warning="true"
            @close-modal="deletePrompt.visible = false"
            @action="deleteProject"
        >
            <PopupDialogPrompt
                :is-warning="true"
                :prompt-text="$t('project.deleteCustomerPrompt')"
            />
        </PopupDialog>

        <PdfViewPopup
            v-if="pdfPopup.visible"
            :params="pdfPopup.params"
            @close-modal="pdfPopup.visible = false"
        />

    </Layout>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { Layout, Main, Nav } from "../../components";

import { Person } from "@/lib/persons/person";

import OverviewMenu from "@/components/customer/menu/OverviewMenu.vue";
import StartKycFormDialog from "@/components/customer/StartKycFormDialog.vue";
import { Assessment } from "@/lib/assessments/assessments";
import { Form } from "@/lib/forms/forms";
import { deleteProject } from "@/lib/projects/delete_project";
import { getProjectProcess, GetProjectResponse } from "@/lib/projects/get_project";
import { Project, ProjectType } from "@/lib/projects/projects";
import { FlowAction, FlowRenderData } from "@/types/flow_types";
import { TabControlTab } from "@/types/ui_types";
import PopupDialog from "@/ui/PopupDialog.vue";
import TabControl from "@/ui/TabControl.vue";
import AssessmentTab from "./AssessmentTab.vue";
import CompanyTab from "./CompanyTab.vue";
import CustomerLayout from "./CustomerLayout.vue";
import OverviewTab from "./OverviewTab.vue";
import PersonsTab from "./PersonsTab.vue";
import ApprovalSelectDialog from "@/components/approvals/ApprovalSelectDialog.vue";
import { loadAssessments } from "@/lib/assessments/assessment_helper";
import BatchScreeningDialog from "@/components/screening/BatchScreeningDialog.vue";
import LockAssessmentDialog from "@/components/assessment/LockAssessmentDialog.vue";
import ApprovalDecideDialog from "@/components/approvals/ApprovalDecideDialog.vue";
import SealAssessmentDialog from "@/components/assessment/SealAssessmentDialog.vue";
import { LocalizedStringMap } from "@/lib/common/common";
import DirectFormDialog from "@/components/customer/DirectFormDialog.vue";
import LinkDependencyDialog from "@/components/customer/LinkDependencyDialog.vue";
import PopupDialogPrompt from "@/ui/dialog/PopupDialogPrompt.vue";
import FormLinkDialog from "@/components/forms/FormLinkDialog.vue";
import PdfViewPopup from "@/components/pdf_viewer/PdfViewPopup.vue";
import { getFormFileViewParams, PdfViewParams } from "@/components/pdf_viewer/pdf_view_helper";
import { getFormFromAction, getFormMainPdfFileId } from "@/lib/forms/form_helper";

export default defineComponent({
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Customer",
    components: {
        Nav,
        Layout,
        Main,
        OverviewTab,
        CompanyTab,
        PersonsTab,
        PopupDialog,
        TabControl,
        CustomerLayout,
        OverviewMenu,
        AssessmentTab,
        StartKycFormDialog,
        ApprovalSelectDialog,
        ApprovalDecideDialog,
        BatchScreeningDialog,
        LockAssessmentDialog,
        SealAssessmentDialog,
        DirectFormDialog,
        LinkDependencyDialog,
        PopupDialogPrompt,
        FormLinkDialog,
        PdfViewPopup,
    },
    data() {
        return {
            projectLoadError: "",
            project: undefined as Project | undefined,
            assessments: new Array<Assessment>(),
            forms: new Array<Form>(),

            flowRenderData: undefined as FlowRenderData | undefined,
            workflowTexts: {} as LocalizedStringMap,
            dependency: {} as any,

            currentAssessment: {} as Assessment,
            previousAssessments: new Array<Assessment>(),

            persons: {
                total: 0,
                persons: [] as Person[],
            },

            deletePrompt: { visible: false, isWorking: false, statusText: "", isError: false, errorText: "" },

            startKycDialog: { visible: false, action: {} as FlowAction },
            formLinkDialog: { visible: false, action: {} as FlowAction },
            lockDialog: { visible: false, action: {} as FlowAction },
            sealDialog: { visible: false, action: {} as FlowAction },
            selectApprovalDialog: { visible: false, action: {} as FlowAction },
            decideApprovalDialog: { visible: false, action: {} as FlowAction },
            batchScreeningDialog: { visible: false, action: {} as FlowAction },
            directFormDialog: { visible: false, action: {} as FlowAction },
            linkDependencyDialog: { visible: false, action: {} as FlowAction },

            pdfPopup: { visible: false, params: {} as PdfViewParams },

            tabs: [
                { key: "overview", text: "project.tabNavigation.overview" },
                { key: "company", text: "project.tabNavigation.company" },
                { key: "persons", text: "project.tabNavigation.persons" },
                { key: "risk", text: "project.tabNavigation.risk" },
            ] as TabControlTab[],
            selectedTab: "overview",
            ProjectType,
        };
    },
    watch: {
        $route(to, from) {
            this.onTabLoaded();
            if (to.params.id !== from.params.id) {
                this.project = undefined;
                this.$nextTick(() => {
                    this.refreshProjectData();
                });
            }
        },
    },
    methods: {

        onTabLoaded() {
            this.onTabChanged(this.$route.params.tab as string);
        },

        onTabChanged(key: string) {
            this.selectedTab = key;
            this.$router.push({ path: `/aml/customers/${this.$route.params.id}/${this.selectedTab}` });
        },

        handleFlowAction(action: FlowAction) {
            console.log("flow action", action);

            switch (action.action) {
            case "kyc-start":
                this.startKycDialog.action = action;
                this.startKycDialog.visible = true;
                break;
            case "kyc-append":
                this.formLinkDialog.action = action;
                this.formLinkDialog.visible = true;
                break;
            case "assessment-show":
                this.onTabChanged("risk");
                break;
            case "assessment-lock":
                this.lockDialog.action = action;
                this.lockDialog.visible = true;
                break;
            case "assessment-seal":
                this.sealDialog.action = action;
                this.sealDialog.visible = true;
                break;
            case "approval-select":
                this.selectApprovalDialog.action = action;
                this.selectApprovalDialog.visible = true;
                break;
            case "approval-decide":
                this.decideApprovalDialog.action = action;
                this.decideApprovalDialog.visible = true;
                break;
            case "persons-screen-all":
                this.batchScreeningDialog.action = action;
                this.batchScreeningDialog.visible = true;
                break;
            case "direct-form-start":
                this.directFormDialog.action = action;
                this.directFormDialog.visible = true;
                break;
            case "direct-form-append":
                this.directFormDialog.action = action;
                this.directFormDialog.visible = true;
                break;
            case "dependency-link":
                this.linkDependencyDialog.action = action;
                this.linkDependencyDialog.visible = true;
                break;
            case "dependency-open":
                if (this.project && this.project.dependency) {
                    this.goToProject(this.project.dependency.id);
                }
                break;
            case "form-pdf":
                this.showFormPdf(action);
                break;
            case "approval-tab-open":
                this.showApprovalTab(action);
                break;
            }
        },

        showFormPdf(action: FlowAction) {
            const form = getFormFromAction(this.forms, action);
            if (form) {
                const fileId = getFormMainPdfFileId(form);
                this.pdfPopup.params = getFormFileViewParams(form, fileId);
                this.pdfPopup.visible = true;
            }
        },

        showApprovalTab(action: FlowAction) {
            if (!this.$refs.overviewMenu) return;
            const approvalKey = action.params.approvalKey || "";
            (this.$refs.overviewMenu as any).goToTabAndItem("approvals", approvalKey);
        },

        async goToProject(projectId: string) {
            this.$router.push({ path: `/aml/customers/${projectId}/overview` });
        },

        async deleteProject() {
            if (!this.project) return;
            this.deletePrompt.isWorking = true;
            this.deletePrompt.statusText = this.$t("project.deletingForm");
            const response = await deleteProject(this.project.id);
            if (response.responseInfo.isError) {
                this.deletePrompt.isError = true;
                this.deletePrompt.errorText = response.responseInfo.parsedError?.message || "Unknown error";
            } else {
                this.$router.push({ path: "/aml/customers" });
                this.deletePrompt.visible = false;
            }
            this.deletePrompt.isWorking = false;
        },

        async refreshProjectData(existingResponse?: GetProjectResponse) {
            /* console.log("Refreshing project data", existingResponse); */
            const projectId = this.$route.params.id as string;
            try {
                // todo: These texts are not needed here as we can use the flowRenderData??
                // This would help with load times when entering through deep linking
                this.workflowTexts = await this.$assets.getWorkflowTexts();

                const projectResponse = existingResponse || await getProjectProcess(projectId);
                this.project = projectResponse.project;
                if (this.project.type === ProjectType.Person) {
                    this.tabs.splice(1, 1);
                }
                this.assessments = projectResponse.assessments;
                this.forms = projectResponse.forms;
                this.flowRenderData = projectResponse.renderData;
                this.dependency = projectResponse.dependency;
                this.loadAssessments();
            } catch (err) {
                console.error(err);
                this.projectLoadError = this.$t("project.failedToLoadError");
            }
        },

        loadAssessments() {
            const loadResult = loadAssessments(this.assessments);
            this.currentAssessment = loadResult.currentAssessment;
            this.previousAssessments = loadResult.previousAssessments;
        },

    },
    created() {
        this.refreshProjectData();
        const tabName = this.$route.params.tab as string;
        this.selectedTab = tabName;
    },
});
</script>
