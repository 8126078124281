<template>
    <span class="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 font-medium ring-1 ring-inset ring-gray-500/10">
        <svg v-if="fillColor" class="h-1.5 w-1.5" :class="[fillColor]" viewBox="0 0 6 6" aria-hidden="true">
            <circle cx="3" cy="3" r="3" />
        </svg>
        {{ text }}
    </span>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    name: "Badge",
    props: {
        text: String,
        fillColor: String,
    },
});
</script>
