<template>
    <ExpandableListLayout
        :can-expand="false"
        :expanded="events.length === 0"
        :button-text="$t('project.overviewMenu.lastEvents.showAll')"
        @clicked="$emit('menu-tab', 'events')"
    >
        <template #top>

            {{ $t('project.overviewMenu.lastEvents.title') }}

        </template>
        <template #expanded>

            <div style="padding: 5px 30px; padding-bottom: 10px; font-size: 14px; color: gray;">
                {{ $t('project.overviewMenu.lastEvents.empty') }}
            </div>

        </template>
        <template #collapsed>
            <div class="amlv3-levents-items">
                <ProjectEventList
                    v-if="!loading"
                    :project="project"
                    :events="events"
                />
            </div>
        </template>
    </ExpandableListLayout>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

import { Project } from "@/lib/projects/projects";

import ExpandableListLayout from "./ExpandableListLayout.vue";
import { EventScope, queryEvents, Event } from "@/lib/events/events";
import { EventDisplayData, getEventDisplayData } from "@/lib/events/event_helper";
import ProjectEventList from "../ProjectEventList.vue";

export default defineComponent({
    name: "MenuLastEvents",
    components: {
        ExpandableListLayout,
        ProjectEventList,
    },
    emits: ["menu-tab"],
    props: {
        project: { type: Object as PropType<Project>, required: true },
    },
    data() {
        return {
            expanded: false,
            loading: false,
            events: new Array<Event>(),
        };
    },
    watch: {
        project: {
            deep: true,
            handler() {
                this.fetchEvents();
            },
        },
    },
    methods: {

        async fetchEvents() {
            this.loading = true;

            const events = await queryEvents({
                limit: 3,
                resource: this.project.id,
                eventScope: EventScope.Project,
            });

            this.events = [];
            events.events.forEach((event) => {
                this.events.push(event);
            });

            console.log("events", this.events);
            this.loading = false;
        },

    },
    created() {
        this.fetchEvents();
    },
});
</script>
<style scoped>
.amlv3-levents-items {
    padding: 0px 20px;
    padding-top: 5px;
}
</style>
