<template>
    <div class="fbv3-spinner" :style="`padding: ${padding}px 0px;`">
        <div
            class="fbv3-spinner-circle"
            :style="`--spin-padding: -${padding}px;` + `--spin-thickness: ${thickness}px;` + `width: ${width}px; height: ${width}px;`"
        >
            <svg
                class="fill-primary-default"
                width="100%"
                height="100%"
                viewBox="0 0 30 25"
                v-if="!hideIcon"
            >
                <g transform="matrix(1,0,0,1,0.931373,-0.0653595)">
                    <path d="M17.5,22L3,22L3,7L7,7L7,3L17,3L17,11.3L20,6.5L20,0L5.9,0L0.3,5L0,5L0,25L16.2,25L17.5,22Z" />
                </g>
                <g transform="matrix(1,0,0,1,0.931373,-0.0653595)">
                    <path d="M24.8,0L16.8,16.1L14.3,9L10.6,9L14.4,21L18.3,21L28.4,0L24.8,0Z" />
                </g>
            </svg>
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";

export default defineComponent({
    name: "SpinnerVerified",
    props: {
        size: { type: Number as PropType<number>, default: 100 },
        hideIcon: { type: Boolean as PropType<boolean>, default: false },
    },
    data() {
        return {
            width: 120,
            padding: 40,
            thickness: 12,
        };
    },
    created() {
        this.width = (60 * Number(this.size)) / 100;
        this.thickness = Math.max((12 * Number(this.size)) / 150, 1.5);
        this.padding = (40 * Number(this.size)) / 100;
    },
});
</script>
<style lang="scss" scoped>
.fbv3-spinner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fbv3-spinner-circle {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    fill: var(--fb-accent);
}

.fbv3-spinner-circle::after {
    content: "";
    position: absolute;
    top: var(--spin-padding);
    left: var(--spin-padding);
    right: var(--spin-padding);
    bottom: var(--spin-padding);
    border-radius: 50%;
    border-top: var(--spin-thickness) solid;
    border-right: var(--spin-thickness) solid;
    border-bottom: var(--spin-thickness) solid;
    border-left: var(--spin-thickness) solid;
    @apply border-primary-default border-l-gray-200;
    transform: translateZ(0);
    animation: load 1.2s infinite linear;
}

@keyframes load {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
