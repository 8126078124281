import { Verified } from "../verified_plugin";

export async function updateAsKeyRole(projectId: string, personId: string, isKeyRole: boolean): Promise<boolean> {
    try {
        const response = await fetch("/api/aml/v1/roles/key-role", {
            method: "PUT",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
            body: JSON.stringify({
                projectId: projectId,
                personId: personId,
                isKeyRole: isKeyRole,
            }),
        });

        const body = await response.json();

        if (!response.ok) {
            throw new Error(body);
        }

        return body as boolean;
    } catch (err) {
        console.error("Failed to update person role as key role", err);
        throw err;
    }
}
