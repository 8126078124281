<template>
    <div>

        <PopupDialog
            :is-visible="dialog.visible"
            :header="$t('project.forms.activeForm.title')"
            :icon="'check-double'"
            :show-action-button="true"
            :show-cancel-button="true"
            :is-action-disabled="!dialog.valid"
            :action-text="$t('project.forms.activeForm.action')"
            :action-icon="'check-double'"
            :progress="dialog"
            @close-modal="onClose"
            @action="onSetActiveForm"
        >
            <PopupDialogPrompt
                :prompt-text="$t('project.forms.activeForm.promptText')"
                :info-text="$t('project.forms.activeForm.hintText')"
            />
        </PopupDialog>

    </div>
</template>
<script lang="ts">
import { PropType, defineComponent } from "vue";

import { Form } from "@/lib/forms/forms";

import PopupDialog from "@/ui/PopupDialog.vue";
import PopupDialogPrompt from "@/ui/dialog/PopupDialogPrompt.vue";
import { getProjectProcess } from "@/lib/projects/get_project";
import { setSelectedForm } from "@/lib/forms/set_selected";

export default defineComponent({
    name: "FormSelectDialog",
    components: {
        PopupDialog,
        PopupDialogPrompt,
    },
    emits: ["close-modal", "request-project-refresh"],
    props: {
        form: { type: Object as PropType<Form>, required: true },
    },
    data() {
        return {
            dialog: {
                visible: true,
                valid: true,
                isWorking: false,
                statusText: "",
                isError: false,
                errorText: "",
            },
        };
    },
    methods: {

        onClose() {
            this.$emit("close-modal");
        },

        async onSetActiveForm() {
            this.dialog.isError = false;
            this.dialog.isWorking = true;
            this.dialog.statusText = "Setting active form";

            const response = await setSelectedForm(this.form.contextId, this.form.id, true);

            if (response.responseInfo.isError) {
                this.dialog.errorText = response.responseInfo.parsedError?.message || "Unknown error";
                this.dialog.isError = true;
                this.dialog.isWorking = false;
                return;
            } else {
                console.log("response.wasSet", response.wasSet);
            }

            const projectResponse = await getProjectProcess(this.form.contextId);
            this.$emit("request-project-refresh", projectResponse);

            this.dialog.isWorking = false;
            this.onClose();
        },

    },
});
</script>
