import { Verified } from "../verified_plugin";

export const getAvailablePolicies = async (): Promise<string[]> => {
    try {
        const response = await fetch("/api/aml/v1/policy", {
            method: "GET",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
        });

        const body = await response.json();

        if (!response.ok) {
            throw new Error(body);
        }

        return body as string[];
    } catch (err) {
        console.error(`Failed to fetch available policies`, err);
        throw err;
    }
};
