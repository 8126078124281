import { Verified } from "../verified_plugin";

export const deleteAttachment = async (attachmentId: string, personId: string) => {
    try {
        const response = await fetch(`/api/aml/v1/persons/${personId}/attachments/${attachmentId}`, {
            method: "delete",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
        });

        const body = await response.json();

        if (!response.ok) {
            throw new Error(body);
        }

        return body;
    } catch (err) {
        console.error("Failed to query projects", err);
        throw err;
    }
};
