import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex mb-2" }
const _hoisted_2 = { class: "flex-1 flex items-center gap-1 uppercase text-xs font-bold" }
const _hoisted_3 = {
  key: 0,
  class: "bg-background w-5 h-5 ml-2 hidden rounded-full py-0.5 px-1 text-xs font-medium md:inline-block text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('openSidebarDialog'))),
    class: _normalizeClass('group outline-1  text-body rounded-lg p-3 pb-2 flex flex-col justify-between cursor-pointer min-h-[6rem]' + _ctx.editableClass)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", null, _toDisplayString(_ctx.title), 1),
        (_ctx.sources?.length && _ctx.sources?.length > 1)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.sources.length), 1))
          : _createCommentVNode("", true)
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sources, (src, i) => {
        return (_openBlock(), _createBlock(_component_Icon, {
          class: "w-4 h-4 ml-2",
          key: `src-${i}`,
          icon: `aml:src-${src.toLowerCase()}`
        }, null, 8, ["icon"]))
      }), 128))
    ]),
    _createElementVNode("div", null, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ], 2))
}