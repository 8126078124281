<template>

    <div class="fbav3-input-text-loc">

        <input
            :id="uniqueId"
            :value="activeValue"
            @input="onInput"
            @keyup.enter="onEnterClicked"
        />

        <div class="fbav3-input-text-loc-locales">

            <div
                class="fbav3-input-text-loc-locale"
                v-for="(locale, i) in locales"
                :key="uniqueId + locale.alpha2"
                @click="onLocaleClicked(locale.key)"
                :class="[
                    { 'fbav3-input-text-loc-last': i === locales.length - 1 },
                    { 'fbav3-input-text-loc-active': locale.key === localeKey },
                ]"
            >
                <Flag :country="locale.alpha2" class="w-[22px]" />
            </div>

        </div>

    </div>

</template>
<script lang="ts">
import Flag from "@/components/Flag.vue";
import { LocalizedString } from "@/lib/common/common";
import { defineComponent, type PropType } from "vue";

export default defineComponent({
    name: "InputTextLocalized",
    components: {
        Flag,
    },
    props: {
        modelValue: { type: Object as PropType<LocalizedString>, required: true },
        uniqueId: { type: String as PropType<string>, required: true },
    },
    emits: ["update:modelValue", "enter"],
    data() {
        return {
            localeKey: "en_EN",
            activeValue: "",
            internalValue: {} as LocalizedString,
            locales: [
                { key: "en_EN", alpha2: "gb" },
                { key: "sv_SE", alpha2: "se" },
                { key: "nb_NO", alpha2: "no" },
                { key: "fi_FI", alpha2: "fi" },
            ],
        };
    },
    watch: {
        modelValue: {
            deep: true,
            handler() {
                this.onChangedExternal();
            },
        },
    },
    methods: {

        onInput(event: Event) {
            const value = (event.target as HTMLInputElement).value;
            this.internalValue[this.localeKey as keyof LocalizedString] = value;
            this.$emit("update:modelValue", JSON.parse(JSON.stringify(this.internalValue)));
        },

        onEnterClicked() {
            this.$emit("enter");
        },

        onLocaleClicked(localeKey: string) {
            this.localeKey = localeKey;
            this.activeValue = this.internalValue[this.localeKey as keyof LocalizedString] || "";
        },

        onChangedExternal() {
            this.internalValue = JSON.parse(JSON.stringify(this.modelValue));
            this.activeValue = this.internalValue[this.localeKey as keyof LocalizedString] || "";
        },

    },
    mounted() {
        this.onChangedExternal();
    },
});
</script>
<style scoped>
.fbav3-input-text-loc {
    display: flex;
}

.fbav3-input-text-loc input {
    width: 100%;
    height: 42px;
    padding: 0px 8px;
    font-size: 14px;
    font-weight: 500;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    color: #444444;
    border: solid 1px #D5D5D5;
    box-sizing: border-box;
}

.fbav3-input-text-loc-locales {
    display: flex;
}

.fbav3-input-text-loc-locale {
    width: 40px;
    padding-top: 7px;
    text-align: center;
    border: solid 1px #D5D5D5;
    border-left: none;
    background-color: #F4F4F4;
    cursor: pointer;
}

.fbav3-input-text-loc-locale:hover {
    background-color: lightgray;
}

.fbav3-input-text-loc-last {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.fbav3-input-text-loc-active {
    background-color: lightgray;
}
</style>
