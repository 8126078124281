<template>
    <!-- <div class="flex-1 flex flex-col">
        <label class="mb-[2px]" for="company-org-nr">Search for a company by its name</label>
        <input
            v-model="query"
            id="company-org-nr"
            placeholder="Start typing to search.."
            class="p-3 border border-gray-300 rounded-md"
            type="text"
        />
    </div> -->
    <div>
        <label class="mb-2 text-md block">{{ $t("newCustomer.searchInstruction") }}</label>
        <div class="relative flex items-center">
            <input
                type="text"
                v-model="query"
                :placeholder="$t('common.placeholders.startSearch') + '..'"
                class="pr-14 pl-14 p-4 rounded-md bg-white border border-gray-300 w-full"
                @keydown.down="onArrowDown"
                @keydown.up="onArrowUp"
                @keydown.enter="onEnter"
                @keydown.tab="onTab"
            />

            <div class="absolute left-4">
                <Icon class="w-6 h-6 text-gray-400" icon="ion:business" />
            </div>

            <div class="absolute right-4">
                <Icon
                    v-if="loading"
                    class="w-6 h-6 opacity-70"
                    icon="tabler:loader-2"
                />
            </div>

            <div
                v-if="result"
                class="absolute w-full rounded-b-md top-[53px] bg-white border border-gray-300 z-10"
            >
                <div v-if="!result.companies.length" class="text-sm font-bold p-5">
                    <label class="block">{{ $t("newCustomer.noResults") }} "{{ query }}"</label>
                    <ActionButton
                        @btn-clicked="setManual"
                        class="mt-2 mr-auto block"
                    >
                        {{ $t("newCustomer.manualFillHint") }}
                    </ActionButton>
                </div>
                <div
                    v-if="result.companies.length"
                    class="max-h-[200px] overflow-y-scroll"
                    ref="resultsContainer"
                >
                    <div
                        v-for="(company, index) in result.companies"
                        :key="company.name"
                        @click="select(company)"
                        @keydown.enter="select(company)"
                        :class="{
                            'p-2  cursor-pointer hover:bg-primary-default hover:text-white text-sm': true,
                            'bg-primary-default text-white': index === selectedIndex,
                        }"
                    >
                        {{ company.name }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { companyNameSearch, CompanyNameSearchResult } from "../../lib/lookup/company_name_search";
import Icon from "../Icon.vue";
import ActionButton from "@/components/ActionButton.vue";

export interface CompanySearchResult {
    name: string;
    registrationNumber: string;
    country: string;
}

export default defineComponent({
    components: {
        Icon,
        ActionButton,
    },
    emits: ["companySelected", "setManual"],
    props: {
        country: {
            type: String,
            required: true,
        },
    },
    computed: {
        loading(): boolean {
            return this.inFlightRequests.length > 0 || this.timeoutId > -1;
        },
    },
    data() {
        return {
            query: "", // the company name we are searching for
            inFlightRequests: [] as Promise<CompanyNameSearchResult>[], // in flight requests, used for the computed loading value
            lastRequestTimestamp: 0, // Date.now() from when the last request was made, used for checking if we should wait before doing a new req
            timeoutId: -1, // the id of the setTimeout used to schedule a request for later
            result: undefined as CompanyNameSearchResult | undefined, // the latest/current result
            selectedCompany: undefined as CompanySearchResult | undefined,
            selectedIndex: -1,
        };
    },
    methods: {
        setManual() {
            this.$emit("setManual");
            this.result = undefined;
        },
        select(company: CompanySearchResult) {
            this.selectedCompany = company;
            this.result = undefined;
            this.query = company.name;
            this.$emit("companySelected", company);
        },
        async search(name: string) {
            // missing prop value
            let orgNumber = "";
            if (!this.country) {
                console.error("Missing country prop value in CompanySearchInput component");
                return;
            }

            if (!isNaN(Number(name)) && name.length >= 3) {
                orgNumber = name;
                name = "";
            }

            const req = companyNameSearch(this.country, name?.toUpperCase(), orgNumber);
            this.inFlightRequests.push(req);
            this.lastRequestTimestamp = Date.now();

            req.then((res) => {
                // we only care about the result if it was for the name currently in the input field
                if (name === this.query || orgNumber === this.query) {
                    this.result = res;
                }
            }).finally(() => {
                this.inFlightRequests.splice(this.inFlightRequests.indexOf(req), 1);
            });
        },
        clearTimeout() {
            clearTimeout(this.timeoutId);
            this.timeoutId = -1;
        },
        onArrowDown() {
            if (this.result && this.selectedIndex < this.result.companies.length - 1) {
                this.selectedIndex++;
                this.scrollToSelectedItem();
            }
        },
        onArrowUp() {
            if (this.selectedIndex > 0) {
                this.selectedIndex--;
                this.scrollToSelectedItem();
            }
        },
        onEnter() {
            if (this.selectedIndex !== -1 && this.result) {
                this.select(this.result.companies[this.selectedIndex]);
            }
        },
        onTab() {
            if (this.selectedIndex !== -1 && this.result) {
                this.select(this.result.companies[this.selectedIndex]);
            }
        },
        scrollToSelectedItem() {
            if (this.selectedIndex !== -1 && this.$refs.resultsContainer) {
                const selectedItem = (this.$refs.resultsContainer as HTMLElement).children[this.selectedIndex] as HTMLElement;
                if (selectedItem) {
                    selectedItem.scrollIntoView({
                        behavior: "smooth",
                        block: "nearest",
                    });
                }
            }
        },
    },
    watch: {
        query(val) {
            if (val === this.selectedCompany?.name) {
                return;
            }

            this.$emit("companySelected", { name: "" });

            if (val.length < 3) {
                this.result = undefined;
                this.clearTimeout();
                return;
            }

            if (!isNaN(val) && val.length < 3) {
                this.result = undefined;
                this.clearTimeout();
                return;
            }

            const now = Date.now();
            // if it's been less than 500ms since the last request, schedule this one for later in case the user is typing quickly
            if (now - this.lastRequestTimestamp < 500) {
                // clear the existing one first, if it exists
                if (this.timeoutId > -1) {
                    this.clearTimeout();
                }

                this.timeoutId = setTimeout(() => {
                    this.search(val);
                    this.clearTimeout();
                }, now - this.lastRequestTimestamp);
            } else {
                this.search(val);
            }
        },
    },
});
</script>
