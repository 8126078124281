import { LocalizedString } from "@/lib/common/common";
import { nextTick } from "vue";

export type DropdownItem = {
    value: string;
    text: string;
};

export type CheckboxItem = {
    key: string;
    text: LocalizedString;
    textKey?: string;
    color?: string;
};

export interface CheckboxModel {
    [key: string]: boolean;
}

export const getCheckboxModel = (items: CheckboxItem[]): CheckboxModel => {
    const values: CheckboxModel = {};
    items.forEach(item => {
        values[item.key] = false;
    });
    return values;
};

export const focusInput = (uniqueId: string) => {
    nextTick(() => {
        const element = document.getElementById(uniqueId);
        if (element) element.focus();
    });
};
