<template>
    <PopupDialog
        :is-visible="dialog.visible"
        :header="'Flags/Logic'"
        :icon="'plus'"
        :progress="dialog"
        :show-cancel-button="true"
        :show-action-button="true"
        :is-action-disabled="!isValid"
        :action-text="'Add'"
        @close-modal="onClose"
        @action="addLogic"
    >
        <div>
            <TabControl
                :tabs="tabs"
                :selected-tab-key="selectedTabKey"
                @tab-changed="onTabChanged"
            />

            <div v-if="selectedTabKey === 'flags'">

                <SimpleListItem
                    v-for="(flag, i) in possibleFlags"
                    :key="'rmpf' + i"
                    :text="getFlagName(flag.key)"
                    :is-first="i === 0"
                    :is-last="i === possibleFlags.length - 1"
                    :is-selected="selectedKey === flag.key"
                    @clicked="onSelectItem(flag.key)"
                />

            </div>

            <div v-if="selectedTabKey === 'logic'">
                {{ 'Coming soon...' }}
            </div>

        </div>
    </PopupDialog>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

import { RiskIndicatorDefinition, RiskLogicType, RiskValueSource } from "@/lib/risk/risk_matrix";

import { TabControlTab } from "@/types/ui_types";
import PopupDialog from "@/ui/PopupDialog.vue";
import TabControl from "@/ui/TabControl.vue";
import { RiskAssets } from "./risk_assets";
import SimpleListItem from "@/ui/SimpleListItem.vue";
import { getFlagName, getPossibleFlags } from "./risk_flag_helper";

interface PossibleLogic {
    type: RiskLogicType;
    key: string;
}

export default defineComponent({
    name: "AddRiskLogicDialog",
    components: {
        PopupDialog,
        TabControl,
        SimpleListItem,
    },
    emits: ["add-logic", "close-modal"],
    props: {
        indicator: { type: Object as PropType<RiskIndicatorDefinition>, required: true },
        assets: { type: Object as PropType<RiskAssets>, required: true },
    },
    data() {
        return {
            RiskValueSource,
            dialog: { visible: true, isWorking: false, statusText: "", isError: false, errorText: "" },
            tabs: [
                { key: "flags", text: "Flags" },
                /* { key: "logic", text: "Logic" }, */
            ] as TabControlTab[],
            selectedTabKey: "flags",
            possibleFlags: new Array<PossibleLogic>(),
            selectedKey: "",
            isValid: false,
        };
    },
    methods: {
        getFlagName,

        onTabChanged(key: string) {
            this.selectedTabKey = key;
        },

        onSelectItem(key: string) {
            if (this.selectedKey === key) {
                this.selectedKey = "";
            } else {
                this.selectedKey = key;
            }
            this.isValid = this.selectedKey !== "";
        },

        addLogic() {
            let possibleLogic: PossibleLogic | null = null;
            for (const flag of this.possibleFlags) {
                if (flag.key === this.selectedKey) possibleLogic = flag;
            }
            if (!possibleLogic) return;

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const logic: any = {
                type: possibleLogic.type,
                key: possibleLogic.key,
            };

            this.$emit("add-logic", logic);
            this.onClose();
        },

        onClose() {
            this.$emit("close-modal");
        },

    },
    mounted() {
        const possibleFlagKeys = getPossibleFlags();
        for (const key of possibleFlagKeys) {
            this.possibleFlags.push({
                type: RiskLogicType.Flag,
                key: key,
            });
        }
    },
});
</script>
