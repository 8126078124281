import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabControl = _resolveComponent("TabControl")!
  const _component_SimpleListItem = _resolveComponent("SimpleListItem")!
  const _component_PopupDialog = _resolveComponent("PopupDialog")!

  return (_openBlock(), _createBlock(_component_PopupDialog, {
    "is-visible": _ctx.dialog.visible,
    header: 'Flags/Logic',
    icon: 'plus',
    progress: _ctx.dialog,
    "show-cancel-button": true,
    "show-action-button": true,
    "is-action-disabled": !_ctx.isValid,
    "action-text": 'Add',
    onCloseModal: _ctx.onClose,
    onAction: _ctx.addLogic
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_TabControl, {
          tabs: _ctx.tabs,
          "selected-tab-key": _ctx.selectedTabKey,
          onTabChanged: _ctx.onTabChanged
        }, null, 8, ["tabs", "selected-tab-key", "onTabChanged"]),
        (_ctx.selectedTabKey === 'flags')
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.possibleFlags, (flag, i) => {
                return (_openBlock(), _createBlock(_component_SimpleListItem, {
                  key: 'rmpf' + i,
                  text: flag.name,
                  "is-first": i === 0,
                  "is-last": i === _ctx.possibleFlags.length - 1,
                  "is-selected": _ctx.selectedKey === flag.key,
                  onClicked: ($event: any) => (_ctx.onSelectItem(flag.key))
                }, null, 8, ["text", "is-first", "is-last", "is-selected", "onClicked"]))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (_ctx.selectedTabKey === 'logic')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString('Coming soon...')))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["is-visible", "progress", "is-action-disabled", "onCloseModal", "onAction"]))
}