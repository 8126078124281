<template>
    <div
        :class="[
            { 'amlv3-pop-split-limit': limitWidth },
        ]"
    >

        <div class="amlv3-pop-split-top">

            <div class="amlv3-pop-split-text" v-if="promptText">
                {{ promptText }}
            </div>
            <div class="amlv3-pop-split-hint" v-if="promptHint">
                {{ promptHint }}
            </div>

            <slot name="top" />

        </div>

        <div
            class="amlv3-pop-split-content"
            :class="[
                { 'amlv3-pop-split-form': isForm },
            ]"
        >
            <slot />
        </div>

    </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
    name: "PopupDialogSplit",
    props: {
        isForm: { type: Boolean as PropType<boolean>, default: false },
        limitWidth: { type: Boolean as PropType<boolean>, default: true },
        promptText: { type: String as PropType<string>, default: "" },
        promptHint: { type: String as PropType<string>, default: "" },
    },
});
</script>
<style>
.amlv3-pop-split-limit {
    min-width: 500px;
    max-width: 560px;
}

.amlv3-pop-split-top {
    padding: 20px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border: solid 1px #E1E1E1;
    border-bottom: none;
    background-color: #F4F4F4;
}

.amlv3-pop-split-text {
    font-size: 14px;
    font-weight: 600;
    color: #444444;
}

.amlv3-pop-split-hint {
    font-size: 13px;
    font-weight: 400;
    color: #444444;
}

.amlv3-pop-split-content {
    padding: 20px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border: solid 1px #E1E1E1;
    border-top: none;
}

.amlv3-pop-split-form {
    width: 100%;
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
}
</style>
