<template>
    <div>
        <div class="p-5 border border-gray-200 rounded-xl">
            <div class="flex gap-3">
                <div class="flex-1 flex flex-col">
                    <p class="font-bold">
                        <InputLabel
                            :text="$t('newCustomer.selectCustomer')"
                            :required="true"
                            unique-id="company-country"
                        />
                    </p>
                    <p class="text-sm text-light-gray px-1">
                        {{ $t("newCustomer.info") }}
                    </p>
                </div>
                <InputGroup :width="4">
                    <CountryDropdown v-model="config.country" />
                </InputGroup>

            </div>

            <div class="my-2 border-b border-light-gray"></div>

            <div class="flex flex-col gap-4">
                <CountryCompanySearch
                    v-if="config.country == 'SE' && !company.manual"
                    country="SE"
                    @company-data-updated="updateCompany"
                    @set-manual="company.manual = true"
                />
                <CountryCompanySearch
                    v-if="config.country == 'NO' && !company.manual"
                    country="NO"
                    @company-data-updated="updateCompany"
                    @set-manual="company.manual = true"
                />
                <CountryCompanySearch
                    v-if="config.country == 'FI' && !company.manual"
                    country="FI"
                    @company-data-updated="updateCompany"
                    @set-manual="company.manual = true"
                />
                <OtherCompanySearch
                    v-if="!['SE', 'NO', 'FI'].includes(config.country) || company.manual"
                    :country="config.country"
                    @company-data-updated="updateCompany"
                />

                <div v-if="!company.manual && company.name" class="flex text-sm">
                    <div class="flex-1">
                        <div class="text-gray-500 mb-1">
                            {{ $t("newCustomer.labelCompanyName") }}:
                        </div>
                        <div class="font-bold text-gray-700">
                            {{ company.name }}
                        </div>
                    </div>
                    <div class="flex-1">
                        <div class="text-gray-500 mb-1">
                            {{ $t("newCustomer.labelOrgNumber") }}:
                        </div>
                        <div class="font-bold text-gray-700">
                            {{ company.registrationNumber }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <WorkflowSelector v-model="flowModel" :conditions="flowConditions" />
        </div>

        <div>
            <AsyncStatus :progress="progress" />
        </div>

        <ActionButton
            @btn-clicked="submit"
            :disabled="!canSubmit"
            :loading="progress.isWorking"
            class="mt-8 ml-auto mr-0 block"
        >
            {{ $t("newCustomer.btnAddCompany") }}
        </ActionButton>
    </div>
</template>
<script lang="ts">
import { attachCompanyData, enabledBlocks } from "@/lib/projects/attach_company_data";
import { createProject } from "@/lib/projects/create_project";
import { updateProjectDataBlocks } from "@/lib/projects/data";
import { Project, ProjectType } from "@/lib/projects/projects";
import { FlowConditions } from "@/types/flow_types";
import { AsyncProgress } from "@/types/ui_types";
import AsyncStatus from "@/ui/AsyncStatus.vue";
import { defineComponent } from "vue";
import ActionButton from "../ActionButton.vue";
import Flag from "../Flag.vue";
import CountryCompanySearch from "./CountryCompanySearch.vue";
import OtherCompanySearch from "./OtherCompanySearch.vue";
import WorkflowSelector from "./WorkflowSelector.vue";
import CountryDropdown from "@/ui/inputs/CountryDropdown.vue";
import InputGroup from "@/ui/inputs/InputGroup.vue";
import InputLabel from "@/ui/inputs/InputLabel.vue";

enum CompanyCountry {
    Sweden = "SE",
    Norway = "NO",
    Finland = "FI",
    Other = "other",
}

export type CompanyData = {
    name: string;
    registrationNumber: string;
    country: string;
    underConstruction: boolean;
    manual: boolean;
};

export default defineComponent({
    name: "CompanyCustomerCreator",
    components: {
        CountryCompanySearch,
        OtherCompanySearch,
        Flag,
        ActionButton,
        WorkflowSelector,
        AsyncStatus,
        CountryDropdown,
        InputGroup,
        InputLabel,
    },
    computed: {
        canSubmit(): boolean {
            if (this.config.country === CompanyCountry.Other) {
                return !!this.company.name;
            } else {
                return !!this.company.name && !!this.company.registrationNumber;
            }
        },
    },
    data() {
        return {
            CompanyCountry,
            config: {
                country: "NO",
            },
            company: {
                name: "",
                registrationNumber: "",
                country: "",
                manual: false,
            } as CompanyData,
            errorMsg: "",
            flowModel: "",
            flowConditions: {
                type: ProjectType.Company,
                countries: [CompanyCountry.Norway],
                flags: [],
            } as FlowConditions,
            progress: {
                isWorking: false,
                statusText: "",
                isError: false,
                errorText: "",
            } as AsyncProgress,
        };
    },
    watch: {
        "config.country"(newValue: string) {
            console.log(newValue);
            this.changeCountry(newValue);
            this.company.manual = false;
        },
        "company.manual"(newValue: boolean) {

        },
    },
    methods: {

        changeCountry(country:string) {
            if (["SE", "NO", "FI"].includes(country)) {
                this.flowConditions.countries = [this.config.country];
            } else {
                this.flowConditions.countries = [];
            }
        },

        updateCompany(val: CompanyData) {
            this.company.name = val.name;
            this.company.registrationNumber = val.registrationNumber;
            this.company.country = val.country ?? this.config.country;
            this.company.manual = val.manual;
        },
        async submit() {
            // 1. Create a new project with the company name as project name
            // 2. If customerType is "other", manually set project company data for name, orgNr and country
            // 2. If customerType is not "other", make request to attach project company data from a provider lookup
            // 3. Forward user to the overview of the created project

            // If attaching company data through provider lookup fails (i.e. if the provider is down) we still
            // forward the user to the created project, they will see a warning telling them to retry the
            // company data lookup later

            this.progress.isError = false;
            this.progress.isWorking = true;
            this.progress.statusText = "Creating project";

            // Create project
            let project: Project;
            const response = await createProject({
                name: this.company.name,
                type: ProjectType.Company,
                identity: this.company.registrationNumber,
                workflow: this.flowModel,
            });
            if (response.responseInfo.isError || !response.project) {
                this.progress.errorText = response.responseInfo.parsedError?.message || "Unknown Error";
                this.progress.isError = true;
                this.progress.isWorking = false;
                return;
            } else {
                project = response.project;
            }

            console.log("COMPANY", this.company);

            // Manual company data
            if (this.company.manual) {
                try {
                    const res = await updateProjectDataBlocks(project.id, {
                        company_info: {
                            name: this.company.name,
                            registrationNumber: this.company.registrationNumber,
                            country: this.company.country,
                        },
                    });

                    console.log(res);
                } catch (err) {
                    console.error(err);
                }
            }
            // Attach company data with lookup
            else if (this.company.registrationNumber && this.company.country) {
                try {
                    console.log("doing company lookup");
                    const result = await attachCompanyData({
                        projectId: project.id,
                        orgnr: this.company.registrationNumber,
                        country: this.company.country.toLowerCase(),
                        dataBlocks: enabledBlocks,
                    });
                    console.log(result);
                } catch (err) {
                    // The API is responsible for adding warnings to the project if a company lookup fails so we do nothing here
                    console.error(err);
                }
            } else {
                console.log("no orgnr or country specified, skipping company lookup");
            }

            this.progress.isWorking = false;
            this.$router.push({ path: `/aml/customers/${project.id}/overview` });
        },
    },
});
</script>

<style scoped lang="scss">
.company-country-btn {
    @apply py-1 px-3 flex gap-2 items-center rounded-full text-primary-default border border-gray-200 hover:cursor-pointer hover:border-primary-default text-xs font-bold;

    span {
        @apply mr-1;
    }
}

.company-country-btn-active {
    @apply bg-primary-default text-white border-primary-default;
}
</style>
