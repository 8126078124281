import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "text-[11px] py-1 mr-2 text-gray-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['flex gap-2 rounded-full pr-1 items-center', 'border', `border-${_ctx.borderColor}`, `bg-${_ctx.backgroundColor}`])
  }, [
    (!_ctx.showIcon)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(`bg-${_ctx.statusColor} w-2 h-2 rounded-full ml-2 `)
        }, null, 2))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_Icon, {
            class: "pl-2",
            icon: _ctx.icon
          }, null, 8, ["icon"])
        ])),
    _createElementVNode("div", null, [
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.status), 1)
    ])
  ], 2))
}