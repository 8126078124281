import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-665589f0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "rskv3-sliderbox" }
const _hoisted_2 = { class: "rskv3-sliderbox-top" }
const _hoisted_3 = { class: "rskv3-sliderbox-title" }
const _hoisted_4 = { class: "rskv3-sliderbox-title-text" }
const _hoisted_5 = { class: "rskv3-sliderbox-risk" }
const _hoisted_6 = {
  key: 0,
  class: "rskv3-sliderbox-help-message"
}
const _hoisted_7 = { class: "rskv3-sliderbox-slider" }
const _hoisted_8 = { style: {"padding":"0px 14px"} }
const _hoisted_9 = {
  key: 0,
  class: "rskv3-sliderbox-override"
}
const _hoisted_10 = {
  key: 0,
  class: "rskv3-sliderbox-override-text"
}
const _hoisted_11 = {
  key: 1,
  class: "rskv3-sliderbox-override-text"
}
const _hoisted_12 = {
  key: 1,
  class: "rskv3-sliderbox-override-working"
}
const _hoisted_13 = { class: "rskv3-sliderbox-override-working-spinner" }
const _hoisted_14 = { class: "rskv3-sliderbox-override-working-text" }
const _hoisted_15 = { key: 2 }
const _hoisted_16 = {
  key: 3,
  class: "rskv3-sliderbox-finalized"
}
const _hoisted_17 = {
  key: 0,
  class: "rskv3-sliderbox-finalized-bg"
}
const _hoisted_18 = { class: "rskv3-sliderbox-finalized-icon" }
const _hoisted_19 = { class: "rskv3-sliderbox-finalized-text" }
const _hoisted_20 = {
  key: 1,
  class: "rskv3-sliderbox-finalized-bg"
}
const _hoisted_21 = { class: "rskv3-sliderbox-finalized-icon" }
const _hoisted_22 = { class: "rskv3-sliderbox-finalized-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RiskSlider = _resolveComponent("RiskSlider")!
  const _component_ButtonSingle = _resolveComponent("ButtonSingle")!
  const _component_ButtonRow = _resolveComponent("ButtonRow")!
  const _component_SpinnerVerified = _resolveComponent("SpinnerVerified")!
  const _component_MissingIconAndText = _resolveComponent("MissingIconAndText")!
  const _component_FontIcon = _resolveComponent("FontIcon")!
  const _component_NewAssessmentDialog = _resolveComponent("NewAssessmentDialog")!
  const _component_LockAssessmentDialog = _resolveComponent("LockAssessmentDialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('assessment.title')), 1)
      ]),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.getRiskText()), 1)
    ]),
    (_ctx.helpOpen)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$t('assessment.hint')), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_RiskSlider, {
        score: _ctx.assessment.score,
        "risk-config": _ctx.riskConfig,
        "is-disabled": _ctx.assessment.locked,
        onSlideChange: _ctx.onRiskSlideChange,
        onFinalChange: _ctx.onRiskFinalChange
      }, null, 8, ["score", "risk-config", "is-disabled", "onSlideChange", "onFinalChange"])
    ]),
    _createElementVNode("div", _hoisted_8, [
      (_ctx.assessment.manualScore && !_ctx.isOverriding)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createVNode(_component_ButtonRow, {
              alignment: _ctx.ButtonAlignment.Right
            }, {
              left: _withCtx(() => [
                (_ctx.assessment.manualScore.isOverride)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.$t('assessment.manualValue.overriddenBy') + ' ' + _ctx.assessment.manualScore.user.name), 1))
                  : _createCommentVNode("", true),
                (!_ctx.assessment.manualScore.isOverride)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.$t('assessment.manualValue.setBy') + ' ' + _ctx.assessment.manualScore.user.name), 1))
                  : _createCommentVNode("", true)
              ]),
              default: _withCtx(() => [
                (!_ctx.assessment.locked)
                  ? (_openBlock(), _createBlock(_component_ButtonSingle, {
                      key: 0,
                      "is-link": "",
                      text: _ctx.$t('assessment.manualValue.reset'),
                      onClicked: _ctx.onResetOverride
                    }, null, 8, ["text", "onClicked"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["alignment"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.isOverriding)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_component_SpinnerVerified, {
                size: 14,
                "hide-icon": true
              })
            ]),
            _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t('assessment.manualValue.updating') + '...'), 1)
          ]))
        : _createCommentVNode("", true),
      (_ctx.matrixDefinition.options.manualScore && !_ctx.assessment.manualScore && !_ctx.isOverriding)
        ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
            _createVNode(_component_MissingIconAndText, {
              text: _ctx.$t('assessment.manualValue.manualHint')
            }, null, 8, ["text"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.assessment.locked || _ctx.assessment.sealed)
        ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
            (_ctx.assessment.locked && !_ctx.assessment.sealed)
              ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                  _createElementVNode("div", _hoisted_18, [
                    _createVNode(_component_FontIcon, {
                      icon: 'lock',
                      size: 16
                    })
                  ]),
                  _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$t('assessment.lock.lockedMessage')), 1),
                  _createElementVNode("div", {
                    class: "rskv3-sliderbox-finalized-button",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.unlockDialog.visible = true))
                  }, _toDisplayString(_ctx.$t('assessment.lock.unlockActionShort')), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.assessment.sealed)
              ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                  _createElementVNode("div", _hoisted_21, [
                    _createVNode(_component_FontIcon, {
                      icon: 'file-zipper',
                      size: 16
                    })
                  ]),
                  _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.$t('assessment.seal.sealedMessage')), 1),
                  _createElementVNode("div", {
                    class: "rskv3-sliderbox-finalized-button",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.newDialog.visible = true))
                  }, _toDisplayString(_ctx.$t('assessment.seal.newButton')), 1)
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.newDialog.visible)
      ? (_openBlock(), _createBlock(_component_NewAssessmentDialog, {
          key: 1,
          project: _ctx.project,
          onCloseModal: _cache[2] || (_cache[2] = ($event: any) => (_ctx.newDialog.visible = false)),
          onRequestProjectRefresh: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('request-project-refresh', $event)))
        }, null, 8, ["project"]))
      : _createCommentVNode("", true),
    (_ctx.unlockDialog.visible)
      ? (_openBlock(), _createBlock(_component_LockAssessmentDialog, {
          key: 2,
          project: _ctx.project,
          assessment: _ctx.assessment,
          "is-unlocking": true,
          onCloseModal: _cache[4] || (_cache[4] = ($event: any) => (_ctx.unlockDialog.visible = false)),
          onRequestProjectRefresh: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('request-project-refresh', $event)))
        }, null, 8, ["project", "assessment"]))
      : _createCommentVNode("", true)
  ]))
}