import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3ea65ea4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"position":"relative"} }
const _hoisted_2 = ["title", "src"]
const _hoisted_3 = {
  key: 0,
  style: {"position":"absolute","top":"0px","left":"0px","width":"100%","padding":"20px 0px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SpinnerVerified = _resolveComponent("SpinnerVerified")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("iframe", {
      id: "formframe",
      title: _ctx.$t('project.forms.title'),
      scrolling: "auto",
      src: _ctx.formUrl
    }, null, 8, _hoisted_2),
    (_ctx.serviceLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_SpinnerVerified, { size: 45 })
        ]))
      : _createCommentVNode("", true)
  ]))
}