import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4ca2cb47"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "amlv3-approvallist" }
const _hoisted_2 = { style: {"margin-top":"20px"} }
const _hoisted_3 = {
  key: 0,
  class: "amlv3-approvallist-spinner"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuSwitch = _resolveComponent("MenuSwitch")!
  const _component_SpinnerVerified = _resolveComponent("SpinnerVerified")!
  const _component_ApprovalListItem = _resolveComponent("ApprovalListItem")!
  const _component_ApprovalSelectDialog = _resolveComponent("ApprovalSelectDialog")!
  const _component_ApprovalDecideDialog = _resolveComponent("ApprovalDecideDialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_MenuSwitch, {
        modelValue: _ctx.filterMode,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filterMode) = $event)),
        items: _ctx.filterItems,
        "has-button": !_ctx.project.assessment.locked,
        "button-icon": 'plus',
        "button-text": _ctx.$t('assessment.approval.newTitle'),
        onClicked: _cache[1] || (_cache[1] = ($event: any) => (_ctx.newDialog.visible = true))
      }, null, 8, ["modelValue", "items", "has-button", "button-text"]),
      _createElementVNode("div", _hoisted_2, [
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_SpinnerVerified, { size: 50 })
            ]))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.approvals, (approval, i) => {
          return (_openBlock(), _createElementBlock("div", {
            key: `ala${i}`,
            style: {"margin-bottom":"10px"}
          }, [
            _createVNode(_component_ApprovalListItem, {
              approval: approval,
              "project-id": _ctx.project.id,
              "assessment-id": _ctx.assessment.id,
              sealed: _ctx.assessment.sealed,
              "user-uid": _ctx.userUid,
              "initially-open": approval.id === _ctx.initiallyOpenId,
              onDecide: _ctx.showDecideDialog,
              onRequestProjectRefresh: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('request-project-refresh', $event)))
            }, null, 8, ["approval", "project-id", "assessment-id", "sealed", "user-uid", "initially-open", "onDecide"])
          ]))
        }), 128))
      ])
    ]),
    (_ctx.newDialog.visible)
      ? (_openBlock(), _createBlock(_component_ApprovalSelectDialog, {
          key: 0,
          action: _ctx.newDialog.action,
          project: _ctx.project,
          assessment: _ctx.assessment,
          onCloseModal: _cache[3] || (_cache[3] = ($event: any) => (_ctx.newDialog.visible = false)),
          onRequestProjectRefresh: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('request-project-refresh', $event)))
        }, null, 8, ["action", "project", "assessment"]))
      : _createCommentVNode("", true),
    (_ctx.decideDialog.visible)
      ? (_openBlock(), _createBlock(_component_ApprovalDecideDialog, {
          key: 1,
          "approval-id": _ctx.decideDialog.approvalId,
          action: _ctx.decideDialog.action,
          project: _ctx.project,
          assessment: _ctx.assessment,
          onCloseModal: _cache[5] || (_cache[5] = ($event: any) => (_ctx.decideDialog.visible = false)),
          onRequestProjectRefresh: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('request-project-refresh', $event)))
        }, null, 8, ["approval-id", "action", "project", "assessment"]))
      : _createCommentVNode("", true)
  ]))
}