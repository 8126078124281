<template>
    <div
        class="amlv3-indicator"
        :class="[
            { 'amlv3-indicator-first': isFirst },
            { 'amlv3-indicator-last': isLast },
            { 'amlv3-indicator-selected': isSelected },
            { 'amlv3-indicator-error': indicator.activeValue.source.source === 'empty' },
        ]"
        @click="$emit('clicked')"
    >

        <div class="amlv3-indicator-left">
            <div class="amlv3-indicator-name">
                {{ $ct(indicator.name) }}
            </div>
            <div class="amlv3-indicator-value">
                {{ $ct(indicator.activeValue.text) }}
            </div>
            <div
                class="amlv3-indicator-source"
                v-if="indicator.activeValue.source.source !== 'empty'"
            >
                {{ getSourceText(indicator.activeValue) }}
            </div>
            <MissingIconAndText
                v-if="indicator.activeValue.source.source === 'empty'"
                :text="$t('assessment.indicators.missingValue')"
            />
        </div>

        <div class="amlv3-indicator-right">
            {{ indicator.activeValue.score }}
        </div>

    </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

import { IndicatorValue, RiskIndicator } from "@/lib/assessments/assessments";
import { getRiskValueSourceText } from "@/views/risk/risk_helper";
import MissingIconAndText from "@/ui/MissingIconAndText.vue";

export default defineComponent({
    name: "IndicatorItem",
    components: {
        MissingIconAndText,
    },
    emits: ["clicked"],
    props: {
        indicator: { type: Object as PropType<RiskIndicator>, required: true },
        isFirst: { type: Boolean as PropType<boolean>, default: false },
        isLast: { type: Boolean as PropType<boolean>, default: false },
        isSelected: { type: Boolean as PropType<boolean>, default: false },
    },
    data() {
        return {
            selectedKey: "",
        };
    },
    methods: {

        getSourceText(indicatorValue: IndicatorValue) {
            return getRiskValueSourceText(indicatorValue, this.$config.settings);
        },

    },
});
</script>
<style scoped>
.amlv3-indicator {
    display: flex;
    padding: 5px 10px;
    border: solid 1px lightgray;
    border-bottom: none;
}

.amlv3-indicator-error {
    /* border: solid 2px #B62537; */
}

.amlv3-indicator-first {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.amlv3-indicator-last {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom: solid 1px lightgray;
}

.amlv3-indicator-selected {
    border: solid 2px #218BCB;
}

.amlv3-indicator-left {
    flex-grow: 1;
}

.amlv3-indicator-name {
    font-family: "Roboto Slab";
    font-size: 15px;
    color: dimgray;
}

.amlv3-indicator-value {
    font-family: "Roboto Slab";
    font-weight: 600;
}

.amlv3-indicator-source {
    font-size: 14px;
    font-style: italic;
    color: gray;
}

.amlv3-indicator-right {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    margin-top: 10px;
    padding-top: 4px;
    text-align: center;
    font-family: "Roboto Slab";
    font-size: 20px;
    font-weight: 500;
    color: black;
    border-radius: 50%;
    background-color: #e1acb3;
}
</style>
