import { Verified } from "./verified_plugin";
import { ApiResponse, parseLocalError, parseRemoteError } from "./error_parser";
import { Employee } from "./common/identity";

export enum Policy {
    //                              "${category}:${action}"

    // Settings policies
    SettingsRead = "settings.read", // read settings
    SettingsWrite = "settings.write", // manage settings

    // User groups policies
    PolicyGroupsRead = "policy_groups.read", // list available policies and groups
    PolicyGroupsWrite = "policy_groups.write", // manage policy groups

    // Projects
    ProjectsWrite = "projects.write", // create
    ProjectsReadAny = "projects.read_any", // read any
    ProjectsWriteAny = "projects.write_any", // write any
    ProjectsRemoveDefaultAclEntries = "projects.remove_default_acl_entries", // remove default entries such as privileged and admin roles from project acl

    // Persons
    PersonsWrite = "persons.write", // create
    PersonsReadAny = "persons.read_any", // read any
    PersonsWriteAny = "persons.write_any", // write any

    // Departmants
    DepartmentsRead = "departments.read", // read departments
    DepartmentsWrite = "departments.write", // write departments

    // Events
    EventsReadAny = "events.read_any", // read any events

    // Misc
    WebhooksWrite = "webhooks.write", // manage webhooks
    RiskMatrixWrite = "risk_matrix.write", // manage risk matrixes
}

export const getEmployees = async (): Promise<{ employees: Employee[] | null, responseInfo: ApiResponse }> => {
    try {
        const response = await fetch("/api/aml/v1/employees", {
            method: "GET",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
        });
        const body = await response.json();
        if (!response.ok) {
            return {
                employees: null,
                responseInfo: { isError: true, parsedError: parseRemoteError(body) },
            };
        }
        return {
            employees: body as Employee[],
            responseInfo: { isError: false, parsedError: null },
        };
    } catch (error) {
        return {
            employees: null,
            responseInfo: { isError: true, parsedError: parseLocalError(error) },
        };
    }
};
