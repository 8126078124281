<template>
    <div class="py-5">
        <div class="rounded-lg p-2 bg-background flex items-center flex-wrap gap-2">
            <!-- Display the search items as tags -->
            <template v-for="(item, index) in tags" :key="index">
                <div class="bg-transparent text-body px-2 py-1 rounded-md flex items-center border border-muted">
                    <span>{{ item }}</span>
                    <button @click="removeItem(item)" class="ml-2">
                        <Icon icon="cross" class="w-2 h-2" />
                    </button>
                </div>
            </template>

            <!-- Input for adding new search terms -->
            <input
                ref="inputField"
                v-model="inputText"
                @keyup.enter="addItem"
                @keydown.backspace="handleBackspace"
                :placeholder="$t('common.addTag')"
                class="flex-grow border-none outline-none text-base p-2 bg-transparent"
            />
        </div>
    </div>
</template>

<script lang="ts">
import Icon from "@/components/Icon.vue";
import { addPersonTag } from "@/lib/persons/add_tag";
import { deleteTag } from "@/lib/persons/delete_tag";
import { defineComponent, PropType } from "vue";

export default defineComponent({
    name: "SearchInput",
    props: {
        tags: {
            type: [] as PropType<string[]>,
            required: true,
        },
        personId: {
            type: String,
            required: true,
        },
    },
    emits: ["refresh"],
    components: {
        Icon,
    },
    data() {
        return {
            inputText: "" as string, // Model for the input field
        };
    },
    methods: {
        async addItem() {
            const trimmedText = this.inputText.trim();
            if (trimmedText !== "" && !this.tags.includes(trimmedText)) {
                await addPersonTag(this.personId, this.inputText); // Add the new search term to the array
            }
            this.inputText = ""; // Clear the input field
            this.$emit("refresh");
        },
        async removeItem(tag: string) {
            await deleteTag(this.personId, tag);
            this.$emit("refresh");
        },
        async handleBackspace(event: KeyboardEvent) {
            // Remove the last search item if the input field is empty and backspace is pressed
            if (this.inputText === "" && this.tags.length > 0) {
                await deleteTag(this.personId, this.tags[this.tags.length - 1]);
                this.$emit("refresh");
            }
        },
    },
});
</script>

<style scoped>
input::placeholder {
    color: #aaa;
}
</style>
