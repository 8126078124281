<template>
    <TransitionRoot as="template" :show="true">
        <Dialog
            class="relative z-10"
            @close="closeModal"
            static
        >
            <!-- Backdrop -->
            <TransitionChild
                as="template"
                enter="ease-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in duration-200"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div
                    class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                    @click="closeModal"
                />
            </TransitionChild>

            <!-- Modal Content -->
            <div class="fixed inset-0 z-20 flex items-center justify-center">
                <TransitionChild
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100 translate-y-0 sm:scale-100"
                    leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <DialogPanel class="amlv3-pdfpopup transition-all">
                        <!-- Content -->
                        <PdfViewer
                            v-if="!isLoading && base64"
                            :file="base64"
                            @close="closeModal"
                        />
                        <div
                            v-if="isLoading"
                            class="amlv3-pdfpopup-loading-center"
                        >
                            <div class="amlv3-pdfpopup-loading">
                                <SpinnerVerified :size="50" />
                                <div class="amlv3-pdfpopup-loading-text">
                                    {{ 'Loading preview...' }}
                                </div>
                            </div>
                        </div>
                        <div v-if="isError" class="amlv3-pdfpopup-error-center">
                            <div class="amlv3-pdfpopup-error">
                                <div class="amlv3-pdfpopup-error-icon">
                                    ⚠️
                                </div>
                                <div class="amlv3-pdfpopup-error-text">
                                    {{ errorMessage }}
                                </div>
                                <button
                                    class="amlv3-pdfpopup-error-button"
                                    :disabled="retryDisabled"
                                    @click="retry"
                                >
                                    {{ retryDisabled ? 'Retrying...' : 'Retry' }}
                                </button>
                            </div>
                        </div>
                    </DialogPanel>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>


<script lang="ts">
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { PropType, defineComponent } from "vue";
import { getFile, PdfViewFile, PdfViewParams } from "./pdf_view_helper";
import PdfViewer from "./PdfViewer.vue";
import SpinnerVerified from "@/ui/SpinnerVerified.vue";

export default defineComponent({
    name: "PdfViewPopup",
    components: {
        Dialog,
        DialogPanel,
        TransitionChild,
        TransitionRoot,
        PdfViewer,
        SpinnerVerified,
    },
    emits: ["close-modal"],
    props: {
        params: { type: Object as PropType<PdfViewParams>, required: true },
    },
    data() {
        return {
            isLoading: true,
            base64: null as Blob | null,
            isError: false,
            errorMessage: "",
            retryDisabled: false,
        };
    },
    methods: {
        closeModal() {
            this.isLoading = false;
            this.isError = false;
            this.$emit("close-modal");
        },
        async getFile(file: PdfViewFile) {
            this.isLoading = true;
            console.log("GET FILE", file);
            this.isError = false;
            this.errorMessage = "";

            try {
                this.base64 = await getFile(file);
                if (!this.base64) {
                    throw new Error("Failed to load the file. Please try again");
                }
            } catch (error) {
                console.error("Error fetching file", error);
                this.isError = true;
                this.errorMessage = "Error fetching file";
            } finally {
                this.isLoading = false;
            }

            this.isLoading = false;
        },
        retry() {
            if (this.retryDisabled) return;
            this.retryDisabled = true;
            setTimeout(() => {
                this.retryDisabled = false;
                if (this.params.initialId) {
                    for (const file of this.params.files) {
                        if (file.id === this.params.initialId) {
                            return this.getFile(file);
                        }
                        for (const attachment of file.attachments) {
                            if (attachment.id === this.params.initialId) {
                                return this.getFile(attachment);
                            }
                        }
                    }
                } else {
                    this.getFile(this.params.files[0]);
                }
            }, 15000);
        },

    },
    mounted() {
        console.log("viewParams", this.params);

        if (this.params.initialId) {
            for (const file of this.params.files) {
                if (file.id === this.params.initialId) {
                    this.getFile(file);
                }
                for (const attachment of file.attachments) {
                    if (attachment.id === this.params.initialId) {
                        this.getFile(attachment);
                    }
                }
            }
        } else {
            this.getFile(this.params.files[0]);
        }
    },
});
</script>
<style scoped>

.amlv3-pdfpopup {
    height: 100vh;
    overflow: auto;
}

.amlv3-pdfpopup-loading-center {
    width: 100%;
}

.amlv3-pdfpopup-loading {
    width: 400px;
    margin: 0 auto;
    margin-top: 200px;
    padding: 20px 0px;
    border-radius: 12px;
    background-color: white;
    -webkit-box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.75);
}

.amlv3-pdfpopup-loading-text {
    margin-top: 20px;
    text-align: center;
}
.amlv3-pdfpopup-error-center {
    width: 100%;
}

.amlv3-pdfpopup-error {
    width: 400px;
    margin: 0 auto;
    margin-top: 200px;
    padding: 20px;
    border-radius: 12px;
    background-color: white;
    box-shadow: 0px 0px 9px 0px rgba(255, 0, 0, 0.5);
    text-align: center;
}

.amlv3-pdfpopup-error-icon {
    font-size: 2rem;
    color: red;
    margin-bottom: 10px;
}

.amlv3-pdfpopup-error-text {
    font-size: 1.2rem;
    margin-bottom: 20px;
}

.amlv3-pdfpopup-error-button {
    padding: 10px 20px;
    font-size: 1rem;
    color: white;
    background-color: red;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.amlv3-pdfpopup-error-button:hover {
    background-color: darkred;
}

.amlv3-pdfpopup-error-button:disabled {
    background-color: gray;
    cursor: not-allowed;
}

</style>
