<template>
    <div>

        <InputText
            v-model="value"
            :unique-id="'test'"
            @update:model-value="handleUpdate"
        />

    </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

import { AssessmentValue, RiskIndicator } from "@/lib/assessments/assessments";
import { RiskIndicatorDefinition } from "@/lib/risk/risk_matrix";
import InputText from "@/ui/inputs/InputText.vue";

export default defineComponent({
    name: "IndicatorNumberInput",
    components: {
        InputText,
    },
    emits: ["updated"],
    props: {
        indicator: { type: Object as PropType<RiskIndicator>, required: true },
        definition: { type: Object as PropType<RiskIndicatorDefinition>, required: true },
        existingValue: { type: Object as PropType<AssessmentValue | null>, default: null },
    },
    data() {
        return {
            value: 0,
            isValid: true,
        };
    },
    methods: {

        handleUpdate() {
            this.$emit("updated", Number(this.value), this.isValid);
        },

    },
    mounted() {
        if (this.existingValue !== null) this.value = this.existingValue.value;
    },
});
</script>
<style scoped></style>
