import assessment from "./assessment.json";
import common from "./common.json";
import core from "./core.json";
import countries from "./countries.json";
import customers from "./customers.json";
import defaults from "./defaults.json";
import eventText from "./eventText.json";
import idcontrol from "./idcontrol.json";
import newCustomer from "./newCustomer.json";
import project from "./project.json";
import risk from "./risk.json";
import screening from "./screening.json";
import persons from "./persons.json";
import settings from "./settings.json";
import workflows from "./workflows.json";

export default {
    common,
    core,
    project,
    countries,
    customers,
    defaults,
    settings,
    assessment,
    workflows,
    idcontrol,
    newCustomer,
    eventText,
    risk,
    screening,
    persons,
};
