import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createSlots as _createSlots } from "vue"

const _hoisted_1 = { style: {"font-size":"14px","color":"dimgray"} }
const _hoisted_2 = { style: {"margin-top":"5px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_ButtonSingle = _resolveComponent("ButtonSingle")!
  const _component_ButtonRow = _resolveComponent("ButtonRow")!
  const _component_SimpleListItem = _resolveComponent("SimpleListItem")!
  const _component_PopupDialogSplit = _resolveComponent("PopupDialogSplit")!
  const _component_PopupDialog = _resolveComponent("PopupDialog")!

  return (_openBlock(), _createBlock(_component_PopupDialog, {
    "is-visible": _ctx.dialog.visible,
    header: _ctx.$t('project.dependency.title'),
    icon: 'link',
    "show-cancel-button": true,
    "show-action-button": true,
    "action-text": _ctx.$t('project.dependency.title'),
    "action-icon": 'link',
    "is-action-disabled": !_ctx.selectedId,
    progress: _ctx.dialog,
    onAction: _ctx.addDependency,
    onCloseModal: _ctx.onClose
  }, {
    default: _withCtx(() => [
      _createVNode(_component_PopupDialogSplit, {
        "prompt-text": _ctx.$t('project.dependency.title'),
        "prompt-hint": _ctx.$t('project.dependency.hint') + ': '
      }, _createSlots({
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t("project.dependency.totalSearchResult", _ctx.total)), 1),
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projects, (item, i) => {
              return (_openBlock(), _createBlock(_component_SimpleListItem, {
                key: 'lddp' + i,
                text: item.name,
                "is-first": i === 0,
                "is-last": i === _ctx.projects.length - 1,
                "is-selected": item.id === _ctx.selectedId,
                onClicked: ($event: any) => (_ctx.onSelect(item))
              }, null, 8, ["text", "is-first", "is-last", "is-selected", "onClicked"]))
            }), 128))
          ])
        ]),
        _: 2
      }, [
        (!_ctx.identityFilter)
          ? {
              name: "top",
              fn: _withCtx(() => [
                _createVNode(_component_ButtonRow, {
                  style: {"margin-top":"10px"},
                  alignment: _ctx.ButtonAlignment.Right
                }, {
                  left: _withCtx(() => [
                    _createVNode(_component_InputText, {
                      modelValue: _ctx.searchModel,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchModel) = $event)),
                      "unique-id": 'dep-search',
                      onEnter: _ctx.onSearch
                    }, null, 8, ["modelValue", "onEnter"])
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_ButtonSingle, {
                      text: _ctx.$t('common.actions.search'),
                      "icon-right": 'magnifying-glass',
                      "is-working": _ctx.isSearching,
                      onClicked: _ctx.onSearch
                    }, null, 8, ["text", "is-working", "onClicked"])
                  ]),
                  _: 1
                }, 8, ["alignment"])
              ]),
              key: "0"
            }
          : undefined
      ]), 1032, ["prompt-text", "prompt-hint"])
    ]),
    _: 1
  }, 8, ["is-visible", "header", "action-text", "is-action-disabled", "progress", "onAction", "onCloseModal"]))
}