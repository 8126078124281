import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "font-roboto w-full bg-white" }
const _hoisted_2 = { class: "" }
const _hoisted_3 = {
  class: "-mb-px flex justify-around text-body",
  "aria-label": "Tabs"
}
const _hoisted_4 = ["onClick", "aria-current"]
const _hoisted_5 = {
  key: 0,
  class: "bg-background w-5 h-5 ml-2 hidden rounded-full py-0.5 px-1 text-xs font-medium md:inline-block text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("nav", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
          return (_openBlock(), _createElementBlock("div", {
            onClick: ($event: any) => (_ctx.changeTab(tab)),
            key: tab.key,
            class: _normalizeClass(["text-lg", [
                        tab.key === _ctx.selectedTabKey ? 'border-primary-default font-extrabold' : 'border-light  ',
                        'flex items-center justify-center whitespace-nowrap border-b-4 py-4 px-1 font-medium hover:cursor-pointer w-full',
                    ]]),
            "aria-current": tab.key === _ctx.selectedTabKey ? 'page' : undefined
          }, [
            _createTextVNode(_toDisplayString(_ctx.$t(tab.text)) + " ", 1),
            (tab.itemCount)
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(tab.itemCount), 1))
              : _createCommentVNode("", true)
          ], 10, _hoisted_4))
        }), 128))
      ])
    ])
  ]))
}