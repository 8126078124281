<template>
    <PopupDialog
        :is-visible="dialog.visible"
        :header="$t('common.person_info.name')"
        :icon="'language'"
        :show-cancel-button="true"
        :show-action-button="true"
        :action-text="'Save'"
        :action-icon="'floppy-disk'"
        :is-action-disabled="!dialog.valid"
        @close-modal="onClose"
        @action="onSave"
    >
        <PopupDialogSplit>

            <InputGroup :width="12" v-if="hasKey">
                <InputLabel :text="$t('common.person_info.key')" :unique-id="'end-key'" />
                <InputText
                    v-model="keyModel"
                    :unique-id="'end-key'"
                    @input="validateDialog"
                />
            </InputGroup>

            <InputGroup :width="12">
                <InputLabel :text="$t('common.person_info.name')" :unique-id="'end-name'" />
                <InputTextLocalized
                    v-model="nameModel"
                    :unique-id="'end-name'"
                    @input="validateDialog"
                    @enter="onSave"
                />
            </InputGroup>

        </PopupDialogSplit>
    </PopupDialog>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

import PopupDialogSplit from "@/ui/dialog/PopupDialogSplit.vue";
import PopupDialog from "@/ui/PopupDialog.vue";

import { LocalizedString, LocalizedStringValidation, validateLocalizedString } from "@/lib/common/common";
import { focusInput } from "@/ui/inputs/input_helper";
import InputGroup from "@/ui/inputs/InputGroup.vue";
import InputLabel from "@/ui/inputs/InputLabel.vue";
import InputText from "@/ui/inputs/InputText.vue";
import InputTextLocalized from "@/ui/inputs/InputTextLocalized.vue";

export type EditNameResult = {
    key: string;
    name: LocalizedString;
};

export default defineComponent({
    name: "EditNameDialog",
    components: {
        PopupDialog,
        PopupDialogSplit,
        InputGroup,
        InputLabel,
        InputText,
        InputTextLocalized,
    },
    emits: ["save-modal", "close-modal"],
    props: {
        hasKey: { type: Boolean as PropType<boolean>, default: false },
        existingKey: { type: String as PropType<string>, default: "" },
        existingName: { type: Object as PropType<LocalizedString | null>, default: "" },
    },
    data() {
        return {
            keyModel: "",
            nameModel: {} as LocalizedString,
            dialog: { visible: true, valid: false },
        };
    },
    methods: {

        validateDialog() {
            let valid = true;

            if (this.hasKey && !this.keyModel) valid = false;

            if (!validateLocalizedString(this.nameModel, LocalizedStringValidation.CurrentOnly)) {
                valid = false;
            }

            this.dialog.valid = valid;
        },

        onSave() {
            const result: EditNameResult = {
                key: this.keyModel,
                name: this.nameModel,
            };
            this.$emit("save-modal", result);
            this.onClose();
        },

        onClose() {
            this.$emit("close-modal");
        },

    },
    mounted() {
        if (this.existingKey) this.keyModel = this.existingKey;
        if (this.existingName) this.nameModel = this.existingName;
        this.validateDialog();
        focusInput("end-name");
    },
});
</script>
