<template>
    <div>
        <div @click="showDialog" class="amlv3-customer-header-info hover:cursor-pointer">
            {{ 'ORG # ' + project.identity }}
        </div>

        <PopupDialog
            :is-visible="dialog.visible"
            :header="$t('project.identity.title')"
            :icon="'building'"
            :progress="dialog"
            :show-cancel-button="true"
            :show-action-button="true"
            :action-icon="'repeat'"
            :is-action-disabled="identity == project.identity && country == project.country"
            :action-text="$t('project.identity.update')"
            :is-warning="false"
            @close-modal="dialog.visible = false"
            @action="updateIdentity"
        >

            <div class="h-[386px]">
                <div class="bg-yellow-100 mb-3 border border-yellow-400 text-yellow-700 text-xs px-4 py-3 rounded relative max-w-[500px]" role="alert">
                    <strong class="font-bold mr-2">{{ $t('project.identity.warningTitle') }}</strong>
                    <span class="block sm:inline">{{ $t('project.identity.warningText') }}</span>
                </div>

                <div class="flex">

                    <InputGroup
                        :width="6"
                    >
                        <InputLabel
                            :text="$t('project.identity.orgNr')"
                            :unique-id="'project-identity'"
                        />
                        <InputText
                            v-model="identity"
                            :unique-id="'project-identity'"
                        />

                    </InputGroup>

                    <InputGroup :width="6">
                        <InputLabel
                            :text="$t('project.identity.country')"
                            :unique-id="'project-country'"
                        />
                        <CountryDropdown v-model="country" />
                    </InputGroup>
                </div>
            </div>

        </PopupDialog>

    </div>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";

import { Project } from "@/lib/projects/projects";
import { UpdateProjectOptions, updateProject } from "@/lib/projects/update_project";
import CustomerTopAction from "./CustomerTopAction.vue";
import PopupDialog from "@/ui/PopupDialog.vue";
import PopupDialogPrompt from "@/ui/dialog/PopupDialogPrompt.vue";
import SimpleListItem from "@/ui/SimpleListItem.vue";
import { getProjectProcess } from "@/lib/projects/get_project";
import InputLabel from "@/ui/inputs/InputLabel.vue";
import InputText from "@/ui/inputs/InputText.vue";
import InputGroup from "@/ui/inputs/InputGroup.vue";
import CountryDropdown from "@/ui/inputs/CountryDropdown.vue";

export default defineComponent({
    name: "AssigneeSelector",
    components: {
        CustomerTopAction,
        PopupDialog,
        PopupDialogPrompt,
        SimpleListItem,
        InputLabel,
        InputText,
        InputGroup,
        CountryDropdown,
    },
    emits: ["request-project-refresh"],
    props: {
        project: { type: Object as PropType<Project>, required: true },
    },
    computed: {
    },
    data() {
        return {
            dialog: { visible: false, isWorking: false, statusText: "", isError: false, errorText: "" },
            identity: "",
            country: "",
        };
    },
    methods: {
        showDialog() {
            this.dialog.visible = true;
        },

        async updateIdentity() {
            this.dialog.isError = false;
            this.dialog.isWorking = true;
            this.dialog.statusText = this.$t("project.identity.updateInProgress");
            const options: UpdateProjectOptions = {
                identity: this.identity,
                country: this.country,
            };
            const response = await updateProject(this.project.id, options);
            if (response.responseInfo.isError) {
                this.dialog.errorText = response.responseInfo.parsedError?.message || "Unknown error";
                this.dialog.isError = true;
            } else {
                const projectResponse = await getProjectProcess(this.project.id);
                this.$emit("request-project-refresh", projectResponse);
                this.dialog.visible = false;
            }
            this.dialog.isWorking = false;
        },
    },
    async mounted() {
        this.identity = this.project.identity || "";
        this.country = this.project.country?.toUpperCase() || "";
    },
});
</script>
