export const uploadAttachment = async (file: File, url: string) => {
    try {
        const response = await fetch(`${url}`, {
            method: "put",
            headers: {
                "content-type": file.type,
            },
            body: file,
        });
        console.log(response);

        if (!response.ok) {
            throw new Error("Could not upload file");
        }

        return true;
    } catch (err) {
        console.error("Failed to upload file", err);
        throw err;
    }
};
