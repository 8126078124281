import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectBox = _resolveComponent("SelectBox")!

  return (_openBlock(), _createBlock(_component_SelectBox, {
    options: _ctx.countryList,
    "show-flag": true,
    modelValue: _ctx.selectedValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedValue) = $event)),
    label: _ctx.label,
    placeholder: _ctx.placeholder,
    "bg-color": "bg-white",
    "border-color": "border-light",
    "border-radius": "rounded-[4px]",
    required: _ctx.required
  }, null, 8, ["options", "modelValue", "label", "placeholder", "required"]))
}