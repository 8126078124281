import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "amlv3-pop-warning-prompt"
}
const _hoisted_2 = {
  key: 1,
  class: "amlv3-pop-warning-prompt"
}
const _hoisted_3 = {
  key: 1,
  class: "amlv3-pop-warning-hint"
}
const _hoisted_4 = { class: "amlv3-pop-warning-hint-text" }
const _hoisted_5 = {
  key: 2,
  class: "amlv3-pop-warning-hint"
}
const _hoisted_6 = { class: "amlv3-pop-warning-hint-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadAnimationBar = _resolveComponent("LoadAnimationBar")!
  const _component_PopupDialogSplit = _resolveComponent("PopupDialogSplit")!

  return (_openBlock(), _createBlock(_component_PopupDialogSplit, null, {
    top: _withCtx(() => [
      (!_ctx.isLoading && _ctx.canProceed)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.promptText), 1))
        : _createCommentVNode("", true),
      (!_ctx.isLoading && !_ctx.canProceed)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.errorText), 1))
        : _createCommentVNode("", true),
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_LoadAnimationBar, {
            key: 2,
            height: 20
          }))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_LoadAnimationBar, {
            key: 0,
            height: 20
          }))
        : _createCommentVNode("", true),
      (!_ctx.isLoading && _ctx.canProceed)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", {
              class: _normalizeClass(["amlv3-pop-warning-hint-icon", [
                    { 'amlv3-pop-warning-hint-icon-warning': _ctx.isWarning },
                ]])
            }, _toDisplayString('!'), 2),
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.infoText || _ctx.$t('common.actions.notReversableHint')), 1)
          ]))
        : _createCommentVNode("", true),
      (!_ctx.isLoading && !_ctx.canProceed)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", {
              class: _normalizeClass(["amlv3-pop-warning-hint-icon", [
                    { 'amlv3-pop-warning-hint-icon-warning': _ctx.isWarning },
                ]])
            }, _toDisplayString('!'), 2),
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.errorReason), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}