import { PdfViewFile, PdfViewFileType, PdfViewParams, PdfViewType } from "../pdf_view_helper";
import { downloadAssessmentReport, getAssesmentReport } from "@/lib/assessments/seal_assessment";

export const getAssessmentFileViewParams = (projectId: string, assessmentId: string): PdfViewParams => {
    const params: PdfViewParams = {
        type: PdfViewType.Assessment,
        files: [
            {
                id: assessmentId,
                type: PdfViewFileType.Assessment,
                name: "Assessment",
                param1: projectId,
                param2: assessmentId,
                attachments: [],
            },
        ],
        initialId: "",
    };
    return params;
};

export const downloadAssessmentFile = async (file: PdfViewFile): Promise<Blob | null> => {
    const projectId = file.param1;
    const assessmentId = file.param2;

    if (!projectId || !assessmentId) return null;

    const { url, responseInfo } = await getAssesmentReport(projectId, assessmentId);
    if (!responseInfo.isError && url) {
        const response = await downloadAssessmentReport(url);
        console.log("response", response);
        if (response.file) return response.file;
    }
    return null;
};
