<template>
    <div class="riskv3-btn" @click="$emit('clicked')">

        <FontIcon :icon="icon" :size="18" />

    </div>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";

import FontIcon from "@/ui/FontIcon.vue";

export default defineComponent({
    name: "RiskIconButton",
    components: {
        FontIcon,
    },
    emits: ["clicked"],
    props: {
        icon: { type: String as PropType<string>, required: true },
    },
});
</script>
<style scoped>
.riskv3-btn {
    width: 34px;
    height: 30px;
    margin: 0px 2px;
    padding-top: 2px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    border-radius: 4px;
    border: solid 1px lightgray;
    background-color: whitesmoke;
    cursor: pointer;
}

.riskv3-btn:hover {
    background-color: lightgray;
}
</style>
