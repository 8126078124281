<template>

    <div
        class="amlv3-ctopaction"
        @click="onClick"
        :tooltip="tooltip"
    >

        <div class="amlv3-ctopaction-icon">
            <FontIcon :icon="icon" :size="14" />
        </div>

        <div class="amlv3-ctopaction-text">
            {{ text }}
        </div>

    </div>

</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";

import FontIcon from "@/ui/FontIcon.vue";

export default defineComponent({
    name: "CustomerTopAction",
    components: {
        FontIcon,
    },
    emits: ["clicked"],
    props: {
        icon: { type: String as PropType<string>, required: true },
        text: { type: String as PropType<string>, required: true },
        tooltip: { type: String as PropType<string>, default: "" },
        isClickable: { type: Boolean as PropType<boolean>, required: true },
    },
    data() {
        return {

        };
    },
    methods: {

        onClick() {
            if (this.isClickable) this.$emit("clicked");
        },

    },
});
</script>
<style scoped>
.amlv3-ctopaction {
    height: 28px;
    display: flex;
    padding: 3px 15px;
    border-radius: 18px;
    border: solid 1px lightgray;
    background-color: white;
    cursor: pointer;
}
.amlv3-ctopaction:hover {
    /* border: solid 1px #218BCB; */
    background-color: whitesmoke;
}

.amlv3-ctopaction-icon {
    color: dimgray;
}

.amlv3-ctopaction-text {
    padding-left: 10px;
    font-size: 14px;
    color: dimgray;
}
</style>
