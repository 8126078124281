import common from "./common.json";
import core from "./core.json";
import project from "./project.json";
import defaults from "./defaults.json";
import workflows from "./workflows.json";
import assessment from "./assessment.json";

export default {
    common,
    core,
    project,
    defaults,
    workflows,
    assessment,
};
