import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d9788da8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "amlv3-indicator-left" }
const _hoisted_2 = { class: "amlv3-indicator-name" }
const _hoisted_3 = { class: "amlv3-indicator-value" }
const _hoisted_4 = {
  key: 0,
  class: "amlv3-indicator-source"
}
const _hoisted_5 = { class: "amlv3-indicator-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MissingIconAndText = _resolveComponent("MissingIconAndText")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["amlv3-indicator", [
            { 'amlv3-indicator-first': _ctx.isFirst },
            { 'amlv3-indicator-last': _ctx.isLast },
            { 'amlv3-indicator-selected': _ctx.isSelected },
            { 'amlv3-indicator-error': _ctx.indicator.activeValue.source.source === 'empty' },
        ]]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('clicked')))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$ct(_ctx.indicator.name)), 1),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$ct(_ctx.indicator.activeValue.text)), 1),
      (_ctx.indicator.activeValue.source.source !== 'empty')
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.getSourceText(_ctx.indicator.activeValue)), 1))
        : _createCommentVNode("", true),
      (_ctx.indicator.activeValue.source.source === 'empty')
        ? (_openBlock(), _createBlock(_component_MissingIconAndText, {
            key: 1,
            text: _ctx.$t('assessment.indicators.missingValue')
          }, null, 8, ["text"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.indicator.activeValue.score), 1)
  ], 2))
}