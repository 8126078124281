<template>
    <div>

        <PopupDialog
            :is-visible="dialog.visible"
            :header="$t('project.screenAll.title')"
            :icon="'magnifying-glass-plus'"
            :show-action-button="true"
            :show-cancel-button="true"
            :is-action-disabled="!dialog.valid"
            :action-text="$t('project.screenAll.title')"
            :action-icon="'magnifying-glass-plus'"
            :progress="dialog"
            @close-modal="onClose"
            @action="startScreening"
        >
            <PopupDialogPrompt
                :prompt-text="$t('project.screenAll.promptText')"
                :info-text="$t('project.screenAll.count', persons.length)"
            />
        </PopupDialog>

    </div>
</template>
<script lang="ts">
import { PropType, defineComponent } from "vue";

import PopupDialog from "@/ui/PopupDialog.vue";
import { Project } from "@/lib/projects/projects";
import { queryPersonWithRoleForCompany } from "@/lib/persons/query_persons";
import { PersonWithRole } from "@/lib/persons/person";
import PopupDialogPrompt from "@/ui/dialog/PopupDialogPrompt.vue";
import { screenPersonWithId, updateMonitoringStatus } from "@/lib/screening/screen_person";
import { getProjectProcess } from "@/lib/projects/get_project";

export default defineComponent({
    name: "BatchScreeningDialog",
    components: {
        PopupDialog,
        PopupDialogPrompt,
    },
    emits: ["close-modal", "request-project-refresh"],
    props: {
        project: { type: Object as PropType<Project>, required: true },
    },
    data() {
        return {
            dialog: {
                visible: true,
                valid: true,
                isWorking: false,
                statusText: "",
                isError: false,
                errorText: "",
            },
            persons: new Array<PersonWithRole>(),
        };
    },
    methods: {

        onClose() {
            this.$emit("close-modal");
        },

        async startScreening() {
            this.dialog.isWorking = true;
            this.dialog.statusText = this.$t("project.screenAll.working");

            for (const person of this.persons) {
                await updateMonitoringStatus(
                    true,
                    person.id,
                );
            }

            const projectResponse = await getProjectProcess(this.project.id);
            this.$emit("request-project-refresh", projectResponse);
            this.onClose();

            this.dialog.isWorking = false;
        },

    },
    async created() {
        const persons = await queryPersonWithRoleForCompany(this.project.id);
        persons?.forEach((person) => {
            if (person.isKeyRole && (!person.screeningStatus || !person.screeningStatus.monitored)) {
                this.persons.push(person);
            }
        });

        console.log("this.persons", this.persons);
    },
});
</script>
