<template>
    <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 py-6">
        <!--Header-->
        <div class="flex justify-between mb-5">
            <label class="text-3xl">{{ $t('common.person_info.country') }}</label>
            <Icon
                class="h-10 w-10 hover:bg-background p-3 cursor-pointer rounded-full "
                icon="cross"
                @click="$emit('closed')"
                aria-hidden="true"
            />
        </div>
        <!--Body-->
        <div class="flex flex-col gap-y-5">
            <ul class="p-0 space-y-3">
                <li
                    v-for="nationality in nationalityList"
                    :key="nationality.value"
                    class="flex gap-x-2 items-center min-h-[6rem] border border-light border-solid p-4 rounded-lg hover:bg-primary-hover-blue"
                >
                    <div>
                        <input
                            name="notification-method"
                            type="radio"
                            :value="nationality.value"
                            v-model="currentValue"
                            @input="onChange(nationality.value)"
                            class="h-6 w-6 border-light"
                        />
                    </div>
                    <div>
                        <div>
                            <div class="flex gap-2 items-center">
                                <FlagIcon :country="nationality.value.toUpperCase()" />
                                <label>{{ $ct(Object.entries(countryDetails.countries).find(([key, value]) => key === nationality.value.toUpperCase())?.[1]) }}</label>
                            </div>

                            <div
                                v-for="src in nationality.sources"
                                class="text-sm text-light-gray flex gap-2"
                                :key="src.source"
                            >
                                <Icon class="w-4 h-4" :icon="`aml:src-${src.source.toLowerCase()}`" />
                                <Icon icon="dot-single" aria-hidden="true" />
                                <span>{{ $d(new Date(src.updatedAt), "long") }}</span>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>

            <!--start Add new nationality-->
            <div v-if="addNationality" class="space-y-3 bg-background rounded-lg p-4">
                <div class="w-full relative">
                    <label class="block">{{ addOrUpdateText }}</label>
                    <div class="absolute right-1 top-0">
                        <Icon
                            icon="cross"
                            class=" w-3 h-3 cursor-pointer"
                            @click="addNationality = false"
                            aria-hidden="true"
                        />
                    </div>
                </div>
                <Icon
                    icon="horizontal-line"
                    class=""
                    aria-hidden="true"
                />
                <div class="space-y-2">
                    <CountryDropdown
                        :label="$t('common.person_info.nationality')"
                        placeholder="Select Nationality"
                        v-model="selectedCountry"
                    />
                </div>
                <CustomButton
                    :text="addOrUpdateText"
                    @click="addNewNationality"
                    round-style="3xl"
                    class="border border-primary-default font-medium text-base box-border ml-auto px-2"
                    bg-color="white"
                />
            </div>
            <!--End Add new nationality-->
            <div class="fflag fflag-US ff-lg"></div>
            <div>
                <a
                    class="text-primary-default"
                    v-if="!addNationality"
                    @click="addNationality = !addNationality"
                >{{ addOrUpdateText }}</a>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import CustomButton from "@/components/CustomButton.vue";
import FlagIcon from "@/components/FlagIcon.vue";
import Icon from "@/components/Icon.vue";
import SelectBox from "@/components/SelectBox.vue";
import lists from "@/i18n/en/index";
import { valueWithsources } from "@/lib/common/common";
import { Person } from "@/lib/persons/person";
import { updatePerson, updatePersonInfo } from "@/lib/persons/update_person";
import { defineComponent, PropType } from "vue";
import CountryDropdown from "@/ui/inputs/CountryDropdown.vue";
import countryDetails from "../../../public/assets/geography/countries.json";

export default defineComponent({
    name: "EditNationality",
    components: {
        Icon,
        CustomButton,
        SelectBox,
        FlagIcon,
        CountryDropdown,
    },
    data() {
        return {
            selectedCountry: "",
            addNationality: false,
            countryDetails,
        };
    },
    props: {
        person: {
            type: Object as PropType<Person>,
            required: true,
        },
    },
    emits: ["closed", "refresh"],
    computed: {
        countryList(): { value: string; label: string }[] {
            const entries = Object.entries(lists.countries);
            return entries.map(([key, value]) => ({
                value: key.toUpperCase(),
                label: value,
            }));
        },
        nationalityList(): any[] | null {
            const personInfoDataBlocks = this.person.blocks.filter(
                (b: any) => b._block === "person_info" && b.nationality?.country && b.nationality?.country !== "",
            );
            if (personInfoDataBlocks && personInfoDataBlocks.length > 0) {
                const countries = personInfoDataBlocks.reduce((acc, block) => {
                    const existingCountry = acc.find((c: any) => c.value === block.nationality?.country);
                    if (existingCountry) {
                        existingCountry.sources.push({ source: block._source, updatedAt: block._updatedAt });
                    } else {
                        acc.push({
                            value: block.nationality?.country ?? {},
                            sources: [{ source: block._source, updatedAt: block._updatedAt }],
                        });
                    }
                    return acc;
                }, [] as valueWithsources[]);

                countries.sort((a: any, b: any) => {
                    // Prioritize the name matching person.data.person_info.givenName
                    if (a.value === this.person.data.person_info.nationality?.country) {
                        return -1; // a comes before b
                    } else if (b.value === this.person.data.person_info.nationality?.country) {
                        return 1; // b comes before a
                    }

                    // Get the latest 'updatedAt' value for each name
                    const latestUpdateA = Math.max(...a.sources.map((source: any) => new Date(source.updatedAt)));
                    const latestUpdateB = Math.max(...b.sources.map((source: any) => new Date(source.updatedAt)));

                    // Compare the latest update dates
                    return latestUpdateB - latestUpdateA;
                });

                console.log("names", countries);
                return countries;
            }

            return null;
        },
        addOrUpdateText(): string {
            return (
                (this.nationalityList && this.nationalityList?.length > 0
                    ? this.$t("common.actions.update")
                    : this.$t("common.actions.add")) +
                " " +
                this.$t("common.person_info.country")
            );
        },
        currentValue(): string {
            return this.person.data.person_info.nationality?.country ?? "";
        },
    },
    methods: {
        addNewNationality() {
            console.log("selectedCountry", this.selectedCountry);
            updatePerson(this.person.id, {
                nationality: {
                    nationalIdentificationNumber: this.person.data.person_info.nationality?.nationalIdentificationNumber ?? "",
                    country: this.selectedCountry.toUpperCase(),
                },
            });
            this.$emit("refresh");
            this.addNationality = false;
            this.selectedCountry = "";
        },
        onChange(country: string) {
            updatePersonInfo(this.person.id, {
                nationality: {
                    nationalIdentificationNumber: this.person.data.person_info.nationality?.nationalIdentificationNumber ?? "",
                    country: country.toUpperCase(),
                },
            });
            this.$emit("refresh");
        },
    },
    async created() {},
});
</script>
