import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0bb148a1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "amlv3-formlist" }
const _hoisted_2 = { style: {"margin-top":"20px"} }
const _hoisted_3 = {
  key: 0,
  class: "amlv3-formlist-spinner"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuSwitch = _resolveComponent("MenuSwitch")!
  const _component_SpinnerVerified = _resolveComponent("SpinnerVerified")!
  const _component_FormListItem = _resolveComponent("FormListItem")!
  const _component_SimpleListItem = _resolveComponent("SimpleListItem")!
  const _component_PopupDialog = _resolveComponent("PopupDialog")!
  const _component_FormDialog = _resolveComponent("FormDialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_MenuSwitch, {
        modelValue: _ctx.filterMode,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filterMode) = $event)),
        items: _ctx.filterItems,
        "has-button": !_ctx.project.assessment.locked,
        "button-icon": 'plus',
        "button-text": _ctx.$t('project.forms.actions.new'),
        onClicked: _cache[1] || (_cache[1] = ($event: any) => (_ctx.newDialog.visible = true))
      }, null, 8, ["modelValue", "items", "has-button", "button-text"]),
      _createElementVNode("div", _hoisted_2, [
        (_ctx.loadingForms)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_SpinnerVerified, { size: 50 })
            ]))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.loadedForms, (form, i) => {
          return (_openBlock(), _createElementBlock("div", {
            key: `flf${i}`,
            style: {"margin-bottom":"10px"}
          }, [
            _createVNode(_component_FormListItem, {
              project: _ctx.project,
              form: form,
              "user-uid": _ctx.userUid,
              "initially-open": form.id === _ctx.initiallyOpenId,
              onDelete: ($event: any) => (_ctx.onDeleteClicked(i)),
              onRequestProjectRefresh: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('request-project-refresh', $event)))
            }, null, 8, ["project", "form", "user-uid", "initially-open", "onDelete"])
          ]))
        }), 128))
      ])
    ]),
    _createVNode(_component_PopupDialog, {
      "is-visible": _ctx.newDialog.visible,
      header: _ctx.$t('project.forms.actions.start'),
      icon: 'clipboard-list',
      progress: _ctx.newDialog,
      "show-cancel-button": true,
      "show-action-button": true,
      "action-text": _ctx.$t('project.forms.actions.start'),
      "is-action-disabled": !_ctx.selectedTemplate.key,
      onCloseModal: _cache[3] || (_cache[3] = ($event: any) => (_ctx.newDialog.visible = false)),
      onAction: _ctx.startForm
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableTemplates, (template, i) => {
          return (_openBlock(), _createBlock(_component_SimpleListItem, {
            key: 'flt' + i,
            text: template.name,
            "sub-text": template.name,
            "is-first": i === 0,
            "is-last": i === _ctx.availableTemplates.length - 1,
            "is-selected": _ctx.selectedTemplate && _ctx.selectedTemplate.key == template.key,
            onClicked: ($event: any) => (_ctx.selectedTemplate = template)
          }, null, 8, ["text", "sub-text", "is-first", "is-last", "is-selected", "onClicked"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["is-visible", "header", "progress", "action-text", "is-action-disabled", "onAction"]),
    _createVNode(_component_PopupDialog, {
      "is-visible": _ctx.deleteDialog.visible,
      header: _ctx.$t('common.actions.delete'),
      icon: 'trash-can',
      progress: _ctx.deleteDialog,
      prompt: _ctx.$t('common.actions.areYouSure'),
      "show-action-button": true,
      "show-cancel-button": true,
      "action-text": _ctx.$t('common.actions.delete'),
      onCloseModal: _cache[4] || (_cache[4] = ($event: any) => (_ctx.deleteDialog.visible = false)),
      onAction: _ctx.onDeleteForm
    }, null, 8, ["is-visible", "header", "progress", "prompt", "action-text", "onAction"]),
    (_ctx.isFormDialogVisible)
      ? (_openBlock(), _createBlock(_component_FormDialog, {
          key: 0,
          onCloseModal: _ctx.onFormDialogClosed
        }, null, 8, ["onCloseModal"]))
      : _createCommentVNode("", true)
  ]))
}