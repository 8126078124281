import { Verified } from "../verified_plugin";

// TODO: remove this and instead fetch from settings
export const enabledBlocks = ["company_info", "company_finance", "company_roles", "company_beneficials"];

export interface AttachCompanyDataResponse {
    updated: string[];
    warnings: string[];
}

export const attachCompanyData = async (options: { projectId: string; orgnr: string; country: string; dataBlocks: string[] }) => {
    try {
        const response = await fetch(`/api/aml/v1/projects/${options.projectId}/company`, {
            method: "POST",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
            body: JSON.stringify({
                country: options.country?.toLowerCase(),
                orgnr: options.orgnr,
                blocks: options.dataBlocks,
            }),
        });

        const body = await response.json();

        if (!response.ok) {
            throw body;
        }

        return body as AttachCompanyDataResponse;
    } catch (err: any) {
        console.error("Failed to attach company data to project", err);
        throw err;
    }
};
