import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-09359712"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "amlv3-flowreqs-count" }
const _hoisted_2 = { style: {"padding-bottom":"10px"} }
const _hoisted_3 = { class: "amlv3-flowreqs-item-icon" }
const _hoisted_4 = { class: "amlv3-flowreqs-item-text" }
const _hoisted_5 = { class: "amlv3-flowreqs-item-name" }
const _hoisted_6 = {
  key: 0,
  class: "amlv3-flowreqs-empty"
}
const _hoisted_7 = { style: {"padding-bottom":"10px"} }
const _hoisted_8 = {
  key: 0,
  class: "amlv3-flowreqs-item"
}
const _hoisted_9 = { class: "amlv3-flowreqs-item-icon" }
const _hoisted_10 = { class: "amlv3-flowreqs-item-text" }
const _hoisted_11 = { class: "amlv3-flowreqs-item-name" }
const _hoisted_12 = {
  key: 0,
  class: "amlv3-flowreqs-empty"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontIcon = _resolveComponent("FontIcon")!
  const _component_ExpandableListLayout = _resolveComponent("ExpandableListLayout")!

  return (_openBlock(), _createBlock(_component_ExpandableListLayout, {
    expanded: _ctx.expanded,
    onExpand: _cache[0] || (_cache[0] = ($event: any) => (_ctx.expanded = $event))
  }, {
    top: _withCtx(() => [
      _createElementVNode("div", null, _toDisplayString(_ctx.$t('assessment.requirements.title')), 1),
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.completedCount + ' ' + _ctx.$t('assessment.requirements.of') + ' ' + _ctx.requirements.length), 1)
    ]),
    expanded: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.requirements, (requirement, i) => {
          return (_openBlock(), _createElementBlock("div", {
            key: 'r' + i,
            class: _normalizeClass(["amlv3-flowreqs-item", [
                        {'amlv3-flowreqs-item-completed': requirement.completed },
                    ]])
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_FontIcon, {
                icon: requirement.completed ? 'check' : 'triangle-exclamation',
                size: 10
              }, null, 8, ["icon"])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$ct(requirement.text)), 1)
            ])
          ], 2))
        }), 128))
      ]),
      (_ctx.requirements.length === 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$t('assessment.requirements.none')), 1))
        : _createCommentVNode("", true)
    ]),
    collapsed: _withCtx(() => [
      _createElementVNode("div", _hoisted_7, [
        (_ctx.nextRequirement)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_FontIcon, {
                  icon: 'triangle-exclamation',
                  size: 10
                })
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$ct(_ctx.nextRequirement.text)), 1)
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      (!_ctx.nextRequirement)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.$t('assessment.requirements.ready')), 1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["expanded"]))
}