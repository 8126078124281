import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withModifiers as _withModifiers, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, normalizeStyle as _normalizeStyle, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-44cc4550"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"position":"relative"} }
const _hoisted_2 = ["id"]
const _hoisted_3 = {
  key: 0,
  class: "amlv3-approvalitem-active-icon"
}
const _hoisted_4 = { class: "amlv3-approvalitem-top-left" }
const _hoisted_5 = { class: "amlv3-approvalitem-name" }
const _hoisted_6 = {
  key: 0,
  class: "amlv3-approvalitem-menu"
}
const _hoisted_7 = {
  key: 0,
  class: "amlv3-approvalitem-content"
}
const _hoisted_8 = { class: "amlv3-approvalitem-actors-header" }
const _hoisted_9 = { class: "amlv3-approvalitem-actors" }
const _hoisted_10 = { class: "amlv3-approvalitem-actor-name" }
const _hoisted_11 = { class: "amlv3-approvalitem-actor-status" }
const _hoisted_12 = { class: "amlv3-approvalitem-actor-status-text" }
const _hoisted_13 = { class: "amlv3-approvalitem-actor-role" }
const _hoisted_14 = { class: "amlv3-approvalitem-actor-actions" }
const _hoisted_15 = ["onClick"]
const _hoisted_16 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontIcon = _resolveComponent("FontIcon")!
  const _component_StatusAndText = _resolveComponent("StatusAndText")!
  const _component_ApproverInfoDialog = _resolveComponent("ApproverInfoDialog")!
  const _component_PopupDialogPrompt = _resolveComponent("PopupDialogPrompt")!
  const _component_PopupDialog = _resolveComponent("PopupDialog")!
  const _directive_click_away = _resolveDirective("click-away")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      id: 'approval-' + _ctx.approval.id,
      class: _normalizeClass(["amlv3-approvalitem-top", [
                {'amlv3-approvalitem-top-collapsed': !_ctx.expanded },
            ]]),
      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.expanded = !_ctx.expanded))
    }, [
      (_ctx.approval.selected)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_FontIcon, {
              icon: 'check-double',
              size: 18
            })
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.getNameText()), 1),
        _createVNode(_component_StatusAndText, {
          text: _ctx.getStatusText(),
          "is-completed": _ctx.approval.status === _ctx.ApprovalStatus.Approved,
          "is-warning": _ctx.approval.status === _ctx.ApprovalStatus.Aborted,
          "is-error": _ctx.approval.status === _ctx.ApprovalStatus.Denied
        }, null, 8, ["text", "is-completed", "is-warning", "is-error"])
      ]),
      (!_ctx.sealed)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "amlv3-approvalitem-top-menu-btn",
            onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.menuOpen = !_ctx.menuOpen), ["stop"]))
          }, [
            _createVNode(_component_FontIcon, {
              icon: 'ellipsis',
              style: {"margin-top":"-4px"}
            }),
            (_ctx.menuOpen)
              ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_6, [
                  (_ctx.approval.status === _ctx.ApprovalStatus.Open)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: "amlv3-approvalitem-menu-item",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.abortDialog.visible = true))
                      }, _toDisplayString(_ctx.$t('assessment.approval.abortAction')), 1))
                    : _createCommentVNode("", true),
                  (_ctx.approval.status !== _ctx.ApprovalStatus.Open)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: "amlv3-approvalitem-menu-item",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.deleteDialog.visible = true))
                      }, _toDisplayString(_ctx.$t('assessment.approval.deleteAction')), 1))
                    : _createCommentVNode("", true)
                ])), [
                  [_directive_click_away, _ctx.hideMenu]
                ])
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ], 10, _hoisted_2),
    (_ctx.expanded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('assessment.approval.approvers.label')), 1),
          _createElementVNode("div", _hoisted_9, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.approval.approvers, (approver, i) => {
              return (_openBlock(), _createElementBlock("div", {
                key: 'fae' + i,
                class: _normalizeClass(["amlv3-approvalitem-actor", [
                        {'amlv3-approvalitem-actor-first': i === 0 },
                        {'amlv3-approvalitem-actor-last': i === _ctx.approval.approvers.length - 1 },
                    ]])
              }, [
                _createElementVNode("div", _hoisted_10, _toDisplayString(approver.givenName + ' ' + approver.familyName), 1),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["amlv3-approvalitem-actor-status-icon", [
                                {'amlv3-approvalitem-actor-status-icon-completed': approver.decision === _ctx.ApprovalStatus.Approved },
                                {'amlv3-approvalitem-actor-status-icon-error': approver.decision === _ctx.ApprovalStatus.Denied },
                            ]])
                  }, [
                    (approver.decision === _ctx.ApprovalStatus.Open)
                      ? (_openBlock(), _createBlock(_component_FontIcon, {
                          key: 0,
                          icon: 'hourglass-half',
                          size: 14
                        }))
                      : _createCommentVNode("", true),
                    (approver.decision === _ctx.ApprovalStatus.Approved)
                      ? (_openBlock(), _createBlock(_component_FontIcon, {
                          key: 1,
                          icon: 'check',
                          size: 14
                        }))
                      : _createCommentVNode("", true),
                    (approver.decision === _ctx.ApprovalStatus.Denied)
                      ? (_openBlock(), _createBlock(_component_FontIcon, {
                          key: 2,
                          icon: 'triangle-exclamation',
                          size: 14
                        }))
                      : _createCommentVNode("", true)
                  ], 2),
                  _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.getApproverStatusText(approver)), 1)
                ]),
                _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.getApproverDateText(approver)), 1),
                _createElementVNode("div", _hoisted_14, [
                  (approver.uid === _ctx.userUid && approver.decision === _ctx.ApprovalStatus.Open)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: "amlv3-approvalitem-actor-action",
                        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showDecisionDialog()))
                      }, [
                        _createVNode(_component_FontIcon, {
                          icon: 'file-import',
                          size: 14
                        })
                      ]))
                    : _createCommentVNode("", true),
                  (approver.decision === _ctx.ApprovalStatus.Open)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: "amlv3-approvalitem-actor-action",
                        onClick: ($event: any) => (_ctx.showReminderDialog(approver))
                      }, [
                        _createVNode(_component_FontIcon, {
                          icon: 'bell',
                          size: 14
                        })
                      ], 8, _hoisted_15))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", {
                    class: "amlv3-approvalitem-actor-action",
                    onClick: ($event: any) => (_ctx.showInfoDialog(approver))
                  }, [
                    _createVNode(_component_FontIcon, {
                      icon: 'circle-info',
                      size: 14
                    })
                  ], 8, _hoisted_16)
                ])
              ], 2))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.initiallyOpen)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "amlv3-approvalitem-highlight",
          style: _normalizeStyle(`opacity: ${_ctx.highlightOpacity ? '1' : '0'};`)
        }, null, 4))
      : _createCommentVNode("", true),
    (_ctx.infoDialog.visible)
      ? (_openBlock(), _createBlock(_component_ApproverInfoDialog, {
          key: 2,
          approver: _ctx.infoDialog.approver,
          onCloseModal: _cache[5] || (_cache[5] = ($event: any) => (_ctx.infoDialog.visible = false))
        }, null, 8, ["approver"]))
      : _createCommentVNode("", true),
    _createVNode(_component_PopupDialog, {
      "is-visible": _ctx.abortDialog.visible,
      header: _ctx.$t('assessment.approval.abortAction'),
      icon: 'xmark',
      "show-action-button": true,
      "show-cancel-button": true,
      "action-text": _ctx.$t('assessment.approval.abortAction'),
      "action-icon": 'xmark',
      "is-warning": true,
      progress: _ctx.abortDialog,
      onCloseModal: _cache[6] || (_cache[6] = ($event: any) => (_ctx.abortDialog.visible = false)),
      onAction: _ctx.onAbort
    }, {
      default: _withCtx(() => [
        _createVNode(_component_PopupDialogPrompt, {
          "prompt-text": _ctx.$t('assessment.approval.abortAction'),
          "info-text": _ctx.$t('common.actions.notReversableHint')
        }, null, 8, ["prompt-text", "info-text"])
      ]),
      _: 1
    }, 8, ["is-visible", "header", "action-text", "progress", "onAction"]),
    _createVNode(_component_PopupDialog, {
      "is-visible": _ctx.deleteDialog.visible,
      header: _ctx.$t('assessment.approval.deleteAction'),
      icon: 'trash-can',
      "show-action-button": true,
      "show-cancel-button": true,
      "action-text": _ctx.$t('assessment.approval.deleteAction'),
      "action-icon": 'trash-can',
      "is-warning": true,
      progress: _ctx.deleteDialog,
      onCloseModal: _cache[7] || (_cache[7] = ($event: any) => (_ctx.deleteDialog.visible = false)),
      onAction: _ctx.onDelete
    }, {
      default: _withCtx(() => [
        _createVNode(_component_PopupDialogPrompt, {
          "prompt-text": _ctx.$t('assessment.approval.deleteAction'),
          "info-text": _ctx.$t('common.actions.notReversableHint')
        }, null, 8, ["prompt-text", "info-text"])
      ]),
      _: 1
    }, 8, ["is-visible", "header", "action-text", "progress", "onAction"])
  ]))
}