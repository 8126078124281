<template>
    <div
        class="riskv3-nestl"
        :class="[
            { 'riskv3-nestl-root': isRoot },
            { 'riskv3-nestl-second': !isRoot && isSecond },
        ]"
    >

        <div
            class="riskv3-nestl-items"
            v-for="(item, i) in items.slice(0, limit)"
            :key="'nl' + item.level + i"
            :class="[
                { 'riskv3-nestl-items-root': isRoot },
            ]"
        >

            <div
                class="riskv3-nestl-item"
                @click="onItemClicked(item)"
                :class="[
                    { 'riskv3-nestl-item-root': isRoot },
                    { 'riskv3-nestl-item-expanded': item.expanded && item.items && item.items.length > 0 },
                    { 'riskv3-nestl-first': i === 0 },
                    { 'riskv3-nestl-selected': item.code === selectedCode },
                ]"
            >

                <div
                    class="riskv3-nestl-exp"
                    v-if="item.items && item.items.length > 0 && (selectableLevel !== -1 && item.level < selectableLevel)"
                >
                    {{ item.expanded ? '-' : '+' }}
                </div>

                <div
                    class="riskv3-nestl-code"
                    v-if="item.showCode"
                    :style="'width: ' + item.codeWidth + 'px;'"
                >
                    {{ item.code }}
                </div>

                <div class="riskv3-nestl-text">
                    {{ $ct(item.text) }}
                </div>

                <div
                    class="riskv3-nestl-score"
                    v-if="isDesigner"
                >
                    <RiskScoreControl
                        :score="getScore(item)"
                        :score-key="item.scoreKey"
                        @score-changed="onScoreChanged"
                    />
                </div>

            </div>

            <div v-if="item.expanded && item.items && (selectableLevel === -1 || item.level < selectableLevel)">
                <RiskNestedList
                    :items="item.items"
                    :scores="scores"
                    :score-map="scoreMap"
                    :is-designer="isDesigner"
                    :is-root="false"
                    :is-second="isRoot"
                    :selectable-level="selectableLevel"
                    :selected-code="selectedCode"
                    @clicked="onItemClicked($event)"
                    @score-changed="onScoreChanged"
                    @item-selected="$emit('item-selected', $event)"
                />
            </div>

        </div>

        <div
            class="riskv3-nestl-actions"
            v-if="isRoot"
        >
            <RiskTextButton
                v-if="showCollapse"
                :text="limit === 9999 ? 'Collapse' : 'Expand'"
                @clicked="onExpandCollapseClicked"
            />
        </div>

    </div>
</template>
<script lang="ts">
import { PropType, defineComponent } from "vue";

import { RiskScores } from "@/lib/risk/risk_matrix";
import { RiskNestedItem, RiskNestedOptions, RiskScoreMap } from "../risk_nested_list";

import { RiskScoreResult } from "../risk_helper";
import RiskScoreControl from "./RiskScoreControl.vue";
import RiskTextButton from "./RiskTextButton.vue";

export default defineComponent({
    name: "RiskNestedList",
    components: {
        RiskScoreControl,
        RiskTextButton,
    },
    emits: ["score-changed", "item-selected"],
    props: {
        items: { type: Array as PropType<RiskNestedItem[]>, required: true },
        options: { type: Object as PropType<RiskNestedOptions>, default: {} },
        scores: { type: Object as PropType<RiskScores | null>, default: null },
        scoreMap: { type: Object as PropType<RiskScoreMap | null>, default: null },
        isRoot: { type: Boolean as PropType<boolean>, default: true },
        isSecond: { type: Boolean as PropType<boolean>, default: true },
        isDesigner: { type: Boolean as PropType<boolean>, default: false },
        selectableLevel: { type: Number as PropType<number>, default: -1 },
        selectedCode: { type: String as PropType<string>, default: "" },
    },
    data() {
        return {
            limit: 9999,
            collapsedLimit: 9999,
            showCollapse: false,
        };
    },
    methods: {

        onItemClicked(item: RiskNestedItem) {
            if (!this.isDesigner && item.level === this.selectableLevel) {
                this.$emit("item-selected", item);
            } else {
                item.expanded = !item.expanded;
            }
        },

        onExpandCollapseClicked() {
            if (this.limit === 9999) {
                this.limit = this.collapsedLimit;
            } else {
                this.limit = 9999;
            }
        },

        onScoreChanged(scoreKey: string, newScore: number) {
            this.$emit("score-changed", scoreKey, newScore);
        },

        getScore(item: RiskNestedItem): RiskScoreResult {
            if (!this.scoreMap || !this.scores) {
                return {
                    score: -99999,
                    isIndirect: true,
                };
            }

            if (this.scores[item.scoreKey] !== undefined) {
                return {
                    score: this.scores[item.scoreKey],
                    isIndirect: false,
                };
            } else {
                return {
                    score: this.getScoreRecursive(this.scoreMap[item.scoreKey]),
                    isIndirect: true,
                };
            }
        },

        getScoreRecursive(scoreKey: string): number {
            if (!this.scoreMap || !this.scores) return -99999;

            if (scoreKey === "") return -1;
            if (this.scores[scoreKey] !== undefined) {
                return this.scores[scoreKey];
            } else {
                return this.getScoreRecursive(this.scoreMap[scoreKey]);
            }
        },

    },
    mounted() {
        if (this.options && this.options.limit) {
            this.limit = this.options.limit;
            this.collapsedLimit = this.options.limit;
            this.showCollapse = true;
        }
    },
});
</script>
<style scoped>
.riskv3-nestl {
    border-left: solid 1px whitesmoke;
}

.riskv3-nestl-root {
    border-left: none;
}

.riskv3-nestl-second {
    border-left: none;
}

.riskv3-nestl-items {
    margin-left: 25px;
}

.riskv3-nestl-items-root {
    margin-left: 0px;
}

.riskv3-nestl-item {
    display: flex;
    border-left: solid 1px lightgray;
    border-top: solid 1px lightgray;
}

.riskv3-nestl-item-root {
    border-left: none;
}

.riskv3-nestl-item:hover {
    background-color: whitesmoke;
}

.riskv3-nestl-item-expanded {
    border-bottom: solid 1px lightgray;
}

.riskv3-nestl-first {
    border-top: none;
}

.riskv3-nestl-selected {
    background-color: lightgray;
}

.riskv3-nestl-exp {
    flex-shrink: 0;
    padding-top: 2px;
    padding-left: 8px;
    width: 22px;
    font-size: 18px;
    font-weight: bold;
}

.riskv3-nestl-code {
    flex-shrink: 0;
    padding-top: 2px;
    padding-left: 8px;
    font-family: monospace;
    font-size: 16px;
    font-weight: bold;
}

.riskv3-nestl-text {
    flex-grow: 1;
    padding: 7px 5px;
    font-size: 15px;
    line-height: 17px;
}

.riskv3-nestl-score {
    padding-top: 4px;
    padding-right: 8px;
}

.riskv3-nestl-actions {
    padding: 8px 8px;
    border-top: solid 1px lightgray;
}
</style>
