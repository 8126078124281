<template>
    <PopupDialog
        :is-visible="dialog.visible"
        :header="$t('project.dependency.title')"
        :icon="'link'"
        :show-cancel-button="true"
        :show-action-button="true"
        :action-text="$t('project.dependency.title')"
        :action-icon="'link'"
        :is-action-disabled="!selectedId"
        :progress="dialog"
        @action="addDependency"
        @close-modal="onClose"
    >
        <PopupDialogSplit
            :prompt-text="$t('project.dependency.title')"
            :prompt-hint="$t('project.dependency.hint') + ': '"
        >
            <template #top v-if="!identityFilter">
                <ButtonRow style="margin-top: 10px;" :alignment="ButtonAlignment.Right">
                    <template #left>
                        <InputText
                            v-model="searchModel"
                            :unique-id="'dep-search'"
                            @enter="onSearch"
                        />
                    </template>
                    <ButtonSingle
                        :text="$t('common.actions.search')"
                        :icon-right="'magnifying-glass'"
                        :is-working="isSearching"
                        @clicked="onSearch"
                    />
                </ButtonRow>
            </template>

            <div style="font-size: 14px; color: dimgray;">
                {{ $t("project.dependency.totalSearchResult", total) }}
            </div>

            <div style="margin-top: 5px;">
                <SimpleListItem
                    v-for="(item, i) in projects"
                    :key="'lddp' + i"
                    :text="item.name"
                    :is-first="i === 0"
                    :is-last="i === projects.length - 1"
                    :is-selected="item.id === selectedId"
                    @clicked="onSelect(item)"
                />
            </div>

        </PopupDialogSplit>
    </PopupDialog>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

import { Project } from "@/lib/projects/projects";
import PopupDialog from "@/ui/PopupDialog.vue";
import PopupDialogSplit from "@/ui/dialog/PopupDialogSplit.vue";
import { getProjectProcess, queryProjects } from "@/lib/projects/get_project";
import InputText from "@/ui/inputs/InputText.vue";
import { FlowAction } from "@/types/flow_types";
import ButtonRow from "@/ui/ButtonRow.vue";
import ButtonSingle from "@/ui/ButtonSingle.vue";
import { ButtonAlignment } from "@/types/ui_types";
import SimpleListItem from "@/ui/SimpleListItem.vue";
import { addDependency } from "@/lib/projects/add_dependency";

export default defineComponent({
    name: "LinkDependencyDialog",
    components: {
        PopupDialog,
        PopupDialogSplit,
        InputText,
        ButtonRow,
        ButtonSingle,
        SimpleListItem,
    },
    emits: ["request-project-refresh", "close-modal"],
    props: {
        action: { type: Object as PropType<FlowAction>, required: true },
        project: { type: Object as PropType<Project>, required: true },
    },
    data() {
        return {
            ButtonAlignment,
            dialog: { visible: true, isWorking: false, statusText: "", isError: false, errorText: "" },

            identityFilter: undefined as string | undefined,
            workflowFilter: undefined as string | undefined,

            searchModel: "",
            isSearching: false,
            total: 0,
            projects: new Array<Project>(),

            selectedId: "",
        };
    },
    methods: {

        async onSearch() {
            this.selectedId = "";
            this.projects = [];
            this.total = 0;
            this.isSearching = true;

            const { total, projects } = await queryProjects(
                0,
                5,
                this.searchModel,
                undefined,
                undefined,
                undefined,
                this.identityFilter,
                this.workflowFilter,
                undefined,
                undefined,
                undefined,
            );
            this.total = total;
            this.projects = projects;

            this.isSearching = false;
        },

        onSelect(project: Project) {
            if (this.selectedId === project.id) {
                this.selectedId = "";
            } else {
                this.selectedId = project.id;
            }
        },

        async addDependency() {
            this.dialog.isError = false;
            this.dialog.isWorking = true;
            this.dialog.statusText = "Adding dependency";

            const response = await addDependency(this.project.id, this.selectedId);
            if (response.responseInfo.isError) {
                this.dialog.errorText = response.responseInfo.parsedError?.message || "Unknown error";
                this.dialog.isError = true;
            } else {
                const projectResponse = await getProjectProcess(this.project.id);
                this.$emit("request-project-refresh", projectResponse);
                this.onClose();
            }

            this.dialog.isWorking = false;
        },

        onClose() {
            this.$emit("close-modal");
        },

    },
    mounted() {
        console.log("action", this.action);

        if (this.action && this.action.params) {
            const params = this.action.params;
            if (params.sameIdentity) {
                this.identityFilter = this.project.identity;
            }
            if (params.workflow) {
                this.workflowFilter = params.workflow as string;
            }
        }

        this.onSearch();
    },
});
</script>
