<template>
    <div
        class="rskv3-comm"
        :class="[
            {'rskv3-comm-locked': assessment.locked },
        ]"
    >

        <div v-if="!assessment.locked" style="padding-top: 3px; padding-bottom: 12px;">
            <ButtonRow :alignment="ButtonAlignment.Right">
                <ButtonSingle
                    is-link
                    :text="$t('assessment.comments.new')"
                    @clicked="addNewComment"
                />
            </ButtonRow>
        </div>

        <div v-if="comments.length === 0" class="rskv3-comm-empty">
            {{ $t('assessment.comments.empty') }}
        </div>

        <div
            v-for="(comment, i) in comments"
            :key="'c' + i"
            class="rskv3-comm-item-base"
        >

            <div class="rskv3-comm-item" v-if="!comment.isEditing">
                <div class="rskv3-comm-item-top">
                    <div class="rskv3-comm-item-user" v-if="comment.user && comment.date">
                        <span>{{ comment.user?.name }}</span>
                        <span>{{ ' - ' }}</span>
                        <span>{{ getFormattedDate(comment.date) }}</span>
                    </div>
                    <div
                        v-if="!assessment.locked"
                        class="rskv3-comm-item-action"
                        @click="onEditComment(comment)"
                    >
                        <FontIcon :icon="'pen'" :size="15" />
                    </div>
                    <div
                        v-if="!assessment.locked"
                        class="rskv3-comm-item-action"
                        @click="onDeleteClicked(comment)"
                    >
                        <FontIcon :icon="'trash-can'" :size="15" />
                    </div>
                </div>
                <div v-if="comment.prompt" class="rskv3-comm-item-prompt">
                    {{ $ct(comment.prompt) }}
                </div>
                <div class="rskv3-comm-item-comment">
                    {{ comment.comment }}
                </div>
            </div>

            <div
                v-if="comment.isEditing"
                class="rskv3-comm-input"
                :class="[
                    {'rskv3-comm-input-required': comment.required },
                ]"
            >
                <div v-if="comment.prompt">
                    {{ $ct(comment.prompt) }}
                </div>
                <textarea
                    v-model="comment.comment"
                    :placeholder="$t('assessment.comments.typeHint')"
                    @input="onTextChanged(comment)"
                />
                <ButtonRow :alignment="ButtonAlignment.Right">
                    <template #left>
                        <MissingIconAndText v-if="comment.required" :text="$t('assessment.comments.required')" />
                    </template>
                    <ButtonSingle
                        v-if="!comment.required"
                        is-link
                        :text="$t('common.actions.cancel')"
                        @clicked="onCancelComment(i, comment)"
                    />
                    <ButtonSingle
                        is-link
                        :text="$t('common.actions.save')"
                        :disabled="!comment.needsSaving"
                        @clicked="saveComments"
                    />
                </ButtonRow>
            </div>

        </div>

        <AsyncStatus :progress="progress" />

        <PopupDialog
            :is-visible="deleteDialog.visible"
            :header="$t('common.actions.delete')"
            :icon="'trash-can'"
            :show-cancel-button="true"
            :show-action-button="true"
            :action-text="$t('common.actions.delete')"
            :action-icon="'trash-can'"
            :is-warning="true"
            :progress="deleteDialog"
            @action="onDeleteComment"
            @close-modal="deleteDialog.visible = false"
        >

            <PopupDialogPrompt
                style="min-width: 500px; max-width: 560px;"
                :is-warning="true"
                :prompt-text="$t('common.actions.areYouSure')"
            />

        </PopupDialog>

    </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

import { Assessment } from "@/lib/assessments/assessments";
import { addAssessmentComments, deleteAssessmentComment, LocalAssessmentComment, updateAssessmentComment } from "@/lib/assessments/update_assessment";
import { IdentityModel } from "@/lib/common/identity";
import { Project } from "@/lib/projects/projects";
import { AsyncProgress, ButtonAlignment } from "@/types/ui_types";
import AsyncStatus from "@/ui/AsyncStatus.vue";
import ButtonRow from "@/ui/ButtonRow.vue";
import ButtonSingle from "@/ui/ButtonSingle.vue";
import FontIcon from "@/ui/FontIcon.vue";
import { RiskCommentDefinition, RiskMatrixDefinition } from "@/lib/risk/risk_matrix";
import { LocalizedString } from "@/lib/common/common";
import MissingIconAndText from "@/ui/MissingIconAndText.vue";
import PopupDialog from "@/ui/PopupDialog.vue";
import PopupDialogPrompt from "@/ui/dialog/PopupDialogPrompt.vue";
import { getProjectProcess } from "@/lib/projects/get_project";

interface LocalComment {
    id: string;
    isEditing: boolean;
    needsSaving: boolean;
    required: boolean;
    prompt?: LocalizedString;
    promptKey?: string;
    comment: string;
    user?: IdentityModel;
    date?: number;
}

export default defineComponent({
    name: "RiskComments",
    components: {
        ButtonRow,
        ButtonSingle,
        FontIcon,
        AsyncStatus,
        MissingIconAndText,
        PopupDialog,
        PopupDialogPrompt,
    },
    emits: ["request-project-refresh"],
    props: {
        project: { type: Object as PropType<Project>, required: true },
        assessment: { type: Object as PropType<Assessment>, required: true },
        matrixDefinition: { type: Object as PropType<RiskMatrixDefinition>, required: true },
        indicatorKey: { type: String as PropType<string | undefined>, default: undefined },
    },
    data() {
        return {
            ButtonAlignment,

            progress: {} as AsyncProgress,
            comments: new Array<LocalComment>(),

            deleteDialog: { visible: false, isWorking: false, statusText: "", isError: false, errorText: "", id: "" },
        };
    },
    watch: {
        assessment: {
            deep: true,
            handler() {
                this.loadComments();
            },
        },
    },
    methods: {

        getFormattedDate(date: number) {
            return new Date(date).toLocaleDateString();
        },

        addNewComment() {
            this.comments.push({
                id: "",
                isEditing: true,
                needsSaving: false,
                required: false,
                comment: "",
            });
        },

        onEditComment(comment: LocalComment) {
            comment.isEditing = true;
        },

        onCancelComment(index: number, comment: LocalComment) {
            comment.isEditing = false;
            if (!comment.id) {
                this.comments.splice(index, 1);
            }
        },

        onTextChanged(comment: LocalComment) {
            comment.needsSaving = true;
        },

        onDeleteClicked(comment: LocalComment) {
            this.deleteDialog.id = comment.id;
            this.deleteDialog.isError = false;
            this.deleteDialog.visible = true;
        },

        async onDeleteComment() {
            this.deleteDialog.isWorking = true;
            this.deleteDialog.statusText = "Deleting comment";

            const response = await deleteAssessmentComment(
                this.project.id,
                this.assessment.id,
                this.deleteDialog.id,
            );
            if (response.responseInfo.isError || !response.assessment) {
                this.deleteDialog.errorText = response.responseInfo.parsedError?.message || "Unknown Error";
                this.deleteDialog.isError = true;
            } else {
                const projectResponse = await getProjectProcess(this.project.id);
                this.$emit("request-project-refresh", projectResponse);
                this.deleteDialog.visible = false;
            }
            this.deleteDialog.isWorking = false;
        },

        async saveComments() {
            this.progress.isError = false;
            this.progress.isWorking = true;
            for (const i in this.comments) {
                const comment = this.comments[i];
                if (comment.needsSaving) {
                    if (comment.id) {
                        this.progress.statusText = this.$t("assessment.comments.updating");
                        const response = await updateAssessmentComment(
                            this.project.id,
                            this.assessment.id,
                            comment.id,
                            comment.comment,
                        );
                        if (response.responseInfo.isError || !response.assessment) {
                            this.progress.errorText = response.responseInfo.parsedError?.message || "Unknown Error";
                            this.progress.isError = true;
                            this.progress.isWorking = false;
                            return;
                        } else {
                            console.log("saved", response.assessment);
                        }
                    } else {
                        this.progress.statusText = this.$t("assessment.comments.adding");
                        const specComment: LocalAssessmentComment = {
                            comment: comment.comment,
                        };
                        if (this.indicatorKey) {
                            specComment.indicatorKey = this.indicatorKey;
                        }
                        if (comment.promptKey) {
                            specComment.prompt = comment.prompt;
                            specComment.promptKey = comment.promptKey;
                        }
                        console.log("spec", specComment);
                        const response = await addAssessmentComments(
                            this.project.id,
                            this.assessment.id,
                            [specComment],
                        );
                        if (response.responseInfo.isError || !response.assessment) {
                            this.progress.errorText = response.responseInfo.parsedError?.message || "Unknown Error";
                            this.progress.isError = true;
                            this.progress.isWorking = false;
                            return;
                        } else {
                            console.log("saved", response.assessment);
                        }
                    }
                }
            }
            const projectResponse = await getProjectProcess(this.project.id);
            this.$emit("request-project-refresh", projectResponse);
            this.progress.isWorking = false;
        },

        isRequiredComment(definition: RiskCommentDefinition): boolean {
            if (definition.simpleTrigger === "always") {
                return true;
            }
            if (definition.simpleTrigger === "override") {
                if (this.assessment.manualScore && this.assessment.manualScore.isOverride) {
                    return true;
                }
            }
            return false;
        },

        loadComments() {
            this.comments = [];
            this.assessment.comments.forEach((comment) => {
                if (comment.indicatorKey === this.indicatorKey) {
                    const localComment: LocalComment = {
                        id: comment.id,
                        isEditing: false,
                        needsSaving: false,
                        required: false,
                        comment: comment.comment,
                        user: comment.user,
                        date: comment.date,
                    };
                    if (comment.promptKey) {
                        localComment.prompt = comment.prompt;
                        localComment.promptKey = comment.promptKey;
                    }
                    this.comments.push(localComment);
                }
            });
            this.matrixDefinition.comments.forEach((commentDefinition) => {
                const isRequired = this.isRequiredComment(commentDefinition);
                if (isRequired && commentDefinition.indicatorKey === this.indicatorKey) {
                    let match = false;
                    this.comments.forEach((existingComment) => {
                        if (existingComment.promptKey && existingComment.promptKey === commentDefinition.key) {
                            match = true;
                        }
                    });
                    if (!match) {
                        const localComment: LocalComment = {
                            id: "",
                            isEditing: true,
                            needsSaving: false,
                            required: true,
                            prompt: commentDefinition.prompt,
                            promptKey: commentDefinition.key,
                            comment: "",
                        };
                        this.comments.push(localComment);
                    }
                }
            });
        },

    },
    mounted() {
        this.loadComments();
    },
});
</script>
<style>
.rskv3-comm {
    padding: 5px 20px;
    border-radius: 4px;
    border: solid 1px lightgray;
}

.rskv3-comm-locked {
    padding: 0px;
    border-radius: 0px;
    border: none;
}

.rskv3-comm-empty {
    font-size: 14px;
    font-style: italic;
    color: gray;
}

.rskv3-comm-item-base {
    margin-bottom: 5px;
}

.rskv3-comm-item {
    padding: 5px 10px;
    border-radius: 4px;
    border: solid 1px lightgray;
}

.rskv3-comm-item-top {
    display: flex;
}

.rskv3-comm-item-user {
    flex-grow: 1;
    font-size: 12px;
    color: gray;
}

.rskv3-comm-item-action {
    flex-shrink: 0;
    width: 20px;
    text-align: center;
    cursor: pointer;
}

.rskv3-comm-item-action:hover {
    opacity: 0.7;
}

.rskv3-comm-item-prompt {
    font-size: 13px;
    font-weight: 600;
    font-style: italic;
}

.rskv3-comm-item-comment {
    font-size: 15px;
}

.rskv3-comm-input {

}

.rskv3-comm-input textarea {
    padding: 8px 8px;
    font-size: 15px;
    font-weight: 400;
    border-radius: 4px;
    border: solid 1px lightgray;
}

.rskv3-comm-input-required textarea {
    border: solid 1px #B62537;
}

</style>
