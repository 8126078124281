import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PopupDialogInfoIconAndText = _resolveComponent("PopupDialogInfoIconAndText")!
  const _component_PopupDialogSplit = _resolveComponent("PopupDialogSplit")!
  const _component_PopupDialog = _resolveComponent("PopupDialog")!

  return (_openBlock(), _createBlock(_component_PopupDialog, {
    "is-visible": _ctx.dialog.visible,
    header: _ctx.$t('assessment.seal.sealAction'),
    icon: 'file-zipper',
    "show-cancel-button": true,
    "show-action-button": true,
    "action-text": _ctx.$t('assessment.seal.sealAction'),
    "action-icon": 'file-zipper',
    progress: _ctx.dialog,
    onAction: _ctx.lockCurrentAssessment,
    onCloseModal: _ctx.onClose
  }, {
    default: _withCtx(() => [
      _createVNode(_component_PopupDialogSplit, {
        style: {"min-width":"500px","max-width":"560px"},
        "prompt-text": _ctx.$t('assessment.seal.sealAction'),
        "prompt-hint": _ctx.$t('assessment.seal.hint1')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_PopupDialogInfoIconAndText, {
            text: _ctx.$t('assessment.seal.hint2')
          }, null, 8, ["text"])
        ]),
        _: 1
      }, 8, ["prompt-text", "prompt-hint"])
    ]),
    _: 1
  }, 8, ["is-visible", "header", "action-text", "progress", "onAction", "onCloseModal"]))
}