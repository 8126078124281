import { Verified } from "../verified_plugin";
import { Person } from "./person";

export const deleteNote = async (personId: string, noteId: string) => {
    try {
        const response = await fetch(`/api/aml/v1/persons/${personId}/notes/${noteId}`, {
            method: "delete",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
        });

        const body = await response.json();

        if (!response.ok) {
            throw new Error(body);
        }

        return body as Person;
    } catch (error) {}
};
