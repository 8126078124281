import { DataBlock } from "../projects/projects";
import { RoleDescription } from "../role/Role";

export enum HitStatus {
    HitFound = "hitfound",
    NoHit = "nohit",
    NotStarted = "notStarted",
}
export interface ScreeningStatus {
    monitored: boolean;
    sip: HitStatus | undefined; // if not present then "undefined"
    pep: HitStatus | undefined;
    sanction: HitStatus | undefined;
    rca: HitStatus | undefined;
    summary: string; // text summary of the most relevant hits
    updatedAt: number;
}

export enum IDControlState {
    NotStarted = "not_started",
    InProgress = "in_progress",
    Completed = "completed",
    Failed = "failed",
}
export interface IDControlDetails {
    status: IDControlState;
    updatedAt: number;
    error?: string;
    matchIndicator: number;
    givenName: string;
    familyName: string;
    dateOfBirth: string;
    email?: string;
    phone?: string;
    ssn?: string;
    source: string;
    method: string;
    formId?: string;
}

export interface Nationality {
    country: string;
    nationalIdentificationNumber: string;
}

export interface DateObject {
    year: number;
    month: number;
    day: number;
}

export interface Address {
    type?: string;
    street: string;
    zip: string;
    town: string;
    country: string;
}

export interface PersonInfoBlock {
    givenName?: string;
    familyName?: string;
    nationality?: Nationality;
    secondaryNationality?: Nationality;
    dateOfBirth?: DateObject;
    email?: string; // we could add `.email()` here but might be better to allow freetext and deal with emails in frontend validation only
    addresses?: any[];
    phone?: string;
}

export interface PersonData {
    person_info: PersonInfoBlock;
}

export enum PersonEventType {
    Screening = "screening",
    IDControl = "id-control",
    NewAddress = "address",
    NewCompany = "company",
    ProfileInfo = "person",
}

export interface Note {
    id: string;
    createdAt: number;
    createdBy: number;
    content: string;
    isArchived?: boolean;
}

export interface Person {
    // The Verified namespace this person belongs to
    // E.g. /companies/Sknk4Fnyv
    namespace: string;
    id: string;

    // `data` has the selected fields from all the available `blocks` below. E.g. we might have two of the same data block
    // with different sources (1 from lookup and 1 from kyc form), both of them are stored in `blocks` below but
    // here in `data` we store a merge of both with fields selected by priority and end user, so person name could be
    // from the lookup block and email address from kyc form as an example.
    // There can also be multiple lookups or multiple kyc forms blocks generated at different point in time that can have
    // different values for the same field.
    data: PersonData;
    blocks: DataBlock<PersonInfoBlock>[];

    // Any additional information related to the person in text form
    // can be added by the service or by end user when they see fit
    notes: Note[];
    tags: string[];

    // Uri's of attachments related to the person
    // can be s3 filestore references or external links.
    // E.g. a picture of the persons passport if obtained
    // as part of id control in a separate system
    attachments: any[];

    statuses: any[];
    screeningStatus?: ScreeningStatus;
    idControlDetails?: IDControlDetails;
}

export type PersonWithRole = Person & {
    roles: RoleDescription[];
    isKeyRole: boolean;
};

export type PersonCompaniesData = {
    projectId: string;
    risk: string;
    projectName: string;
    roles: RoleDescription[];
};

export type PersonInfoPropertyVM = {
    value: any;
    count: number;
    hasValue: boolean;
    sources: string[];
};

export interface Attachment {
    id?: string,
    name: string,
    contentType: string,
    attachmentType: string,
    comment?: string,
    updatedAt?: number,
    createdAt?: number,
    createdBy?: string,
}
