import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5a22990a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "relative border-2 border-dashed rounded-lg p-6 max-w-sm mx-auto my-3" }
const _hoisted_2 = { class: "text-center" }
const _hoisted_3 = { class: "mt-4 text-lg font-medium text-gray-900" }
const _hoisted_4 = { class: "mt-1 text-sm text-gray-500" }
const _hoisted_5 = {
  key: 0,
  class: "absolute bottom-4 left-4 right-4 bg-gray-100 rounded-lg p-2"
}
const _hoisted_6 = { class: "flex items-center justify-between" }
const _hoisted_7 = { class: "text-sm font-medium text-gray-700" }
const _hoisted_8 = { class: "w-full bg-gray-200 rounded-full h-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontIcon = _resolveComponent("FontIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_FontIcon, {
        icon: "cloud-arrow-up",
        size: 40
      }),
      _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.isUploading ? 'Uploading...' : 'Drag & Drop or Click to Upload'), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.isUploading ? 'File uploading...' : 'Upload your file here'), 1)
    ]),
    _createElementVNode("input", {
      type: "file",
      class: "absolute w-full h-full inset-0 opacity-0 cursor-pointer",
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleFileChange && _ctx.handleFileChange(...args))),
      ref: "fileInput"
    }, null, 544),
    (_ctx.isUploading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.progress) + "% ", 1),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", {
                class: "bg-blue-500 rounded-full h-2",
                style: _normalizeStyle({ width: `${_ctx.progress}%` })
              }, null, 4)
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}