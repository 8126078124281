<template>
    <div class="fbv3-fonticon" :style="`font-size: ${size}px;`">
        <font-awesome-icon :icon="`fa-solid fa-${icon}`" />
    </div>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";

export default defineComponent({
    name: "FontIcon",
    props: {
        icon: { type: String as PropType<string>, required: true },
        size: { type: Number as PropType<number>, required: false, default: 20 },
    },
});
</script>
<style scoped>
.fbv3-fonticon {
    font-size: 15px;
}
</style>
