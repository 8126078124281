import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a7906d10"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["tooltip"]
const _hoisted_2 = {
  key: 0,
  class: "fbl-button-left"
}
const _hoisted_3 = {
  key: 1,
  class: "fbl-button-text"
}
const _hoisted_4 = {
  key: 2,
  class: "fbl-button-right"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontIcon = _resolveComponent("FontIcon")!
  const _component_SpinnerVerified = _resolveComponent("SpinnerVerified")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["fbl-button", [
            { 'fbl-button-outline': _ctx.outline || _ctx.isWorking },
            { 'fbl-button-disabled': _ctx.disabled },
            { 'fbl-button-warning': _ctx.isWarning },
            { 'fbl-button-working': _ctx.isWorking },
            { 'fbl-button-rounded': _ctx.isRounded },
            { 'fbl-button-link': _ctx.isLink },
        ]]),
    style: _normalizeStyle(_ctx.minWidth !== 0 ? `min-width: ${_ctx.minWidth}px;` : ''),
    tooltip: _ctx.tooltip,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleClick()))
  }, [
    (_ctx.iconLeft || (!_ctx.iconRight && _ctx.isWorking))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.iconLeft && !_ctx.isWorking)
            ? (_openBlock(), _createBlock(_component_FontIcon, {
                key: 0,
                icon: _ctx.iconLeft,
                size: 15,
                style: {"margin-top":"5px"}
              }, null, 8, ["icon"]))
            : _createCommentVNode("", true),
          (_ctx.isWorking)
            ? (_openBlock(), _createBlock(_component_SpinnerVerified, {
                key: 1,
                size: 18,
                style: {"margin-top":"4px"}
              }))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.text)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.text), 1))
      : _createCommentVNode("", true),
    (_ctx.iconRight)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_ctx.iconRight && !_ctx.isWorking)
            ? (_openBlock(), _createBlock(_component_FontIcon, {
                key: 0,
                icon: _ctx.iconRight,
                size: 15,
                style: {"margin-top":"5px"}
              }, null, 8, ["icon"]))
            : _createCommentVNode("", true),
          (_ctx.isWorking)
            ? (_openBlock(), _createBlock(_component_SpinnerVerified, {
                key: 1,
                size: 18,
                style: {"margin-top":"4px"}
              }))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 14, _hoisted_1))
}