<template>
    <div>

        <PopupDialog
            :is-visible="dialog.visible"
            :header="$t('common.selectContact')"
            :icon="'address-book'"
            :show-cancel-button="true"
            :progress="dialog"
            @close-modal="onClose"
        >
            <div>
                <div
                    v-for="(contact, i) in contacts"
                    :key="'con' + i"
                    class="amlv3-contactlist-item"
                    @click="onSelect(contact)"
                >
                    <div>
                        {{ contact.givenName + ' ' + contact.familyName }}
                    </div>
                    <div>
                        {{ contact.email }}
                    </div>
                </div>
            </div>
        </PopupDialog>

    </div>
</template>
<script lang="ts">
import { PropType, defineComponent } from "vue";

import PopupDialog from "@/ui/PopupDialog.vue";
import { Contact } from "./contact_list";

export default defineComponent({
    name: "ContactListDialog",
    components: {
        PopupDialog,
    },
    emits: ["close-modal", "selected"],
    props: {
        contacts: { type: Array as PropType<Contact[]>, required: true },
    },
    data() {
        return {
            dialog: {
                visible: true,
                valid: true,
                isWorking: false,
                statusText: "",
                isError: false,
                errorText: "",
            },
        };
    },
    methods: {

        onClose() {
            this.$emit("close-modal");
        },

        onSelect(contact: Contact) {
            this.$emit("selected", contact);
            this.onClose();
        },

    },
    mounted() {
        console.log("contacts", this.contacts);
    },
});
</script>
<style scoped>
.amlv3-contactlist-item {
    border: solid 1px lightgray;
    cursor: pointer;
}

.amlv3-contactlist-item:hover {
    background-color: whitesmoke;
}
</style>
