import { Verified } from "../verified_plugin";

// TODO: data block typings when it's more defined in the API
export const updateProjectDataBlocks = async (projectId: string, data: any) => {
    try {
        const response = await fetch(`/api/aml/v1/projects/${projectId}/data`, {
            method: "PATCH",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
            body: JSON.stringify(data),
        });

        const body = await response.json();

        if (!response.ok) {
            throw body;
        }

        return body as { updated: string[]; warnings: string[] };
    } catch (err: any) {
        console.error("Failed to update data blocks for project " + projectId, err);
        throw err;
    }
};
