import { parseRemoteError } from "../error_parser";
import { Verified } from "../verified_plugin";

export const getAttachment = async (attachmentId: string, projectId: string) => {
    try {
        const response = await fetch(`/api/aml/v1/projects/${projectId}/attachments/${attachmentId}/url`, {
            method: "get",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
        });

        const body = await response.json();

        if (!response.ok) {
            return {
                url: "",
                responseInfo: { isError: true, parsedError: parseRemoteError(body) },
            };
        }

        return body as {url:string};
    } catch (err) {
        console.error("Failed to query projects", err);
        return {
            url: "",
            responseInfo: { isError: true, parsedError: parseRemoteError(err) },
        };
    }
};
