<template>
    <Layout>
        <Nav />
        <Main class="px-16 py-5">

            <div class="amlv3-settings-header">
                <div class="amlv3-settings-header-icon">
                    <FontIcon :icon="'gear'" :size="24" />
                </div>
                <div class="amlv3-settings-header-text">
                    <span>{{ 'Settings: ' }}</span>
                    <span>{{ sectionName }}</span>
                </div>
            </div>

            <TabControl
                :tabs="tabs"
                :selected-tab-key="selectedTab"
                @tab-changed="onTabChanged"
            />
            <div class="border-b border-gray-300" />

            <div v-if="section == 'organization'" class="mt-8">

                <Branches v-if="selectedTab === 'departments'" />

                <Partners v-if="selectedTab === 'partners'" />

                <PolicyGroups v-if="selectedTab === 'policyGroups'" />

            </div>
            <div v-if="section == 'flows'" class="mt-8">

                <WorkflowSettings v-if="selectedTab === 'workflows'" />

                <RiskMatrices v-if="selectedTab === 'matrices'" />

                <FormTemplates v-if="selectedTab === 'formTemplates'" />

                <ApprovalSettings v-if="selectedTab === 'approvals'" />

            </div>
            <div v-if="section == 'screening'" class="mt-8">

                <!-- Nothing Here ??? -->

            </div>
        </Main>
    </Layout>
</template>
<script lang="ts">
import { Layout, Main, Nav } from "@/components/index";
import Branches from "@/components/settings/company/Branches.vue";
import Partners from "@/components/settings/company/Partners.vue";
import { defineComponent } from "vue";
import { Setting } from "@/lib/settings/settings";
import WorkflowSettings from "@/components/settings/WorkflowSettings.vue";
import FormTemplates from "@/components/settings/FormTemplates.vue";
import RiskMatrices from "@/components/settings/RiskMatrices.vue";
import PolicyGroups from "@/components/settings/PolicyGroups.vue";
import FontIcon from "@/ui/FontIcon.vue";
import { TabControlTab } from "@/types/ui_types";
import TabControl from "@/ui/TabControl.vue";
import ApprovalSettings from "@/components/settings/approvals/ApprovalSettings.vue";

export default defineComponent({
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Settings",
    components: {
        Layout,
        Main,
        Nav,
        Branches,
        Partners,
        PolicyGroups,
        WorkflowSettings,
        RiskMatrices,
        FormTemplates,
        FontIcon,
        TabControl,
        ApprovalSettings,
    },
    data() {
        return {
            section: "",
            sectionName: "",

            tabs: new Array<TabControlTab>(),
            selectedTab: "overview",

            settings: {} as Setting,
        };
    },
    watch: {
        $route() {
            this.loadHeaderAndTabs();
        },
    },
    methods: {

        onTabChanged(key: string) {
            this.selectedTab = key;
            this.$router.push({ params: { tab: this.selectedTab } });
        },

        loadHeaderAndTabs() {
            this.tabs = [];
            this.selectedTab = this.$route.params.tab as string;
            this.section = this.$route.params.section as string;

            // Load tabs from $config.navRoutes
            const allRoutes = this.$config.getNavRoutes([], true);
            for (const i in allRoutes) {
                for (const j in allRoutes[i].children) {
                    const level1 = allRoutes[i].children[j];
                    if (level1.name === this.section) {
                        level1.children.forEach((category) => {
                            this.tabs.push({
                                key: category.name,
                                rawText: this.$t(category.text),
                            });
                        });
                        this.sectionName = this.$t(level1.text);
                    }
                }
            }
        },

    },
    mounted() {
        this.settings = this.$config.rawSettings;
        this.loadHeaderAndTabs();
    },
});
</script>
<style>
.amlv3-settings-header {
    display: flex;
    margin-bottom: 15px;
}

.amlv3-settings-header-icon {
    margin-top: 4px;
    color: #218BCB;
}

.amlv3-settings-header-text {
    margin-left: 15px;
    font-family: "Roboto Slab";
    font-size: 28px;
    font-weight: 400;
    color: #444444;
}
</style>
