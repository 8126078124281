import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-57382b30"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "amlv3-rsk-id" }
const _hoisted_2 = { class: "amlv3-rsk-id-top-text" }
const _hoisted_3 = { class: "amlv3-rsk-id-content" }
const _hoisted_4 = { class: "amlv3-rsk-id-header" }
const _hoisted_5 = {
  key: 0,
  class: "amlv3-rsk-id-empty"
}
const _hoisted_6 = { class: "amlv3-rsk-id-value-text" }
const _hoisted_7 = { class: "amlv3-rsk-id-value-value" }
const _hoisted_8 = { class: "amlv3-rsk-id-value-source" }
const _hoisted_9 = {
  class: "amlv3-rsk-id-header",
  style: {"margin-top":"20px"}
}
const _hoisted_10 = {
  key: 1,
  class: "amlv3-rsk-id-empty"
}
const _hoisted_11 = { class: "amlv3-rsk-id-manval-value" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = {
  key: 2,
  class: "amlv3-rsk-id-na"
}
const _hoisted_14 = {
  key: 0,
  class: "amlv3-rsk-id-na-base"
}
const _hoisted_15 = { class: "amlv3-rsk-id-na-text-hint" }
const _hoisted_16 = {
  key: 1,
  class: "amlv3-rsk-id-na-base amlv3-rsk-id-na-base-blue"
}
const _hoisted_17 = { class: "amlv3-rsk-id-na-text" }
const _hoisted_18 = {
  key: 2,
  class: "amlv3-rsk-id-na-base"
}
const _hoisted_19 = { class: "amlv3-rsk-id-na-spinner" }
const _hoisted_20 = { class: "amlv3-rsk-id-na-status" }
const _hoisted_21 = {
  key: 3,
  class: "amlv3-rsk-id-na-error"
}
const _hoisted_22 = {
  class: "amlv3-rsk-id-header",
  style: {"margin-top":"20px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontIcon = _resolveComponent("FontIcon")!
  const _component_ButtonSingle = _resolveComponent("ButtonSingle")!
  const _component_ButtonRow = _resolveComponent("ButtonRow")!
  const _component_SpinnerVerified = _resolveComponent("SpinnerVerified")!
  const _component_RiskComments = _resolveComponent("RiskComments")!
  const _component_RiskManualValueDialog = _resolveComponent("RiskManualValueDialog")!
  const _component_PopupDialogPrompt = _resolveComponent("PopupDialogPrompt")!
  const _component_PopupDialog = _resolveComponent("PopupDialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "amlv3-rsk-id-top",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClose && _ctx.onClose(...args)))
    }, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('common.actions.close')), 1),
      _createElementVNode("div", null, [
        _createVNode(_component_FontIcon, { icon: 'xmark' })
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('assessment.indicators.allMatchedValues')), 1),
      (_ctx.matchedValues.length === 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t('assessment.indicators.noMatchedValues')), 1))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.matchedValues, (value, i) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["amlv3-rsk-id-value", [
                    { 'amlv3-rsk-id-value-first': i === 0 },
                    { 'amlv3-rsk-id-value-last': i === _ctx.matchedValues.length - 1 },
                    { 'amlv3-rsk-id-value-active': value.value === _ctx.indicator.activeValue.value },
                ]]),
          key: _ctx.indicator.key + i
        }, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$ct(value.text)), 1),
            _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.getSourceText(value)), 1)
          ]),
          _createElementVNode("div", {
            class: "amlv3-rsk-id-value-score",
            style: _normalizeStyle({ backgroundColor: value.value === _ctx.indicator.activeValue.value ? _ctx.activeIndicatorScoreBgColor : '' })
          }, _toDisplayString(value.score), 5)
        ], 2))
      }), 128)),
      _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('assessment.indicators.manualValues')), 1),
      (_ctx.existingValues.length === 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.$t('assessment.indicators.noManualValues')), 1))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.existingValues, (manualValue, i) => {
        return (_openBlock(), _createElementBlock("div", {
          key: _ctx.indicator.key + 'm' + i,
          class: _normalizeClass(["amlv3-rsk-id-manval", [
                    { 'amlv3-rsk-id-value-first': i === 0 },
                    { 'amlv3-rsk-id-value-last': i === _ctx.existingValues.length - 1 },
                ]])
        }, [
          _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.getDisplayValue(manualValue)), 1),
          (!_ctx.assessment.locked)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "amlv3-rsk-id-manval-delete",
                onClick: ($event: any) => (_ctx.showDeleteDialog(manualValue))
              }, [
                _createVNode(_component_FontIcon, {
                  icon: 'trash-can',
                  size: 16
                })
              ], 8, _hoisted_12))
            : _createCommentVNode("", true)
        ], 2))
      }), 128)),
      _createVNode(_component_ButtonRow, {
        alignment: _ctx.ButtonAlignment.Right,
        style: {"margin-top":"5px","padding-right":"15px"}
      }, {
        default: _withCtx(() => [
          (!_ctx.assessment.locked)
            ? (_openBlock(), _createBlock(_component_ButtonSingle, {
                key: 0,
                "is-link": "",
                text: _ctx.$t('assessment.indicators.addManualValue'),
                onClicked: _ctx.showEditDialog
              }, null, 8, ["text", "onClicked"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["alignment"]),
      (_ctx.allowNotApplicable || _ctx.indicator.notApplicable)
        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
            (!_ctx.isUpdatingNotApplicable && !_ctx.indicator.notApplicable)
              ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                  _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t('risk.notApplicable.hint')), 1),
                  _createElementVNode("div", {
                    class: "amlv3-rsk-id-na-btn",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.markAsNotApplicable(true)))
                  }, _toDisplayString(_ctx.$t('risk.notApplicable.action')), 1)
                ]))
              : _createCommentVNode("", true),
            (!_ctx.isUpdatingNotApplicable && _ctx.indicator.notApplicable)
              ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                  _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.$t('risk.notApplicable.marked')), 1),
                  (_ctx.allowNotApplicable && _ctx.notApplicableWasManual)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: "amlv3-rsk-id-na-btn",
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.markAsNotApplicable(false)))
                      }, _toDisplayString(_ctx.$t('risk.notApplicable.reset')), 1))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (_ctx.isUpdatingNotApplicable)
              ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                  _createElementVNode("div", _hoisted_19, [
                    _createVNode(_component_SpinnerVerified, {
                      size: 14,
                      "hide-icon": true
                    })
                  ]),
                  _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.$t('risk.notApplicable.working')), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.notApplicableErrorText)
              ? (_openBlock(), _createElementBlock("div", _hoisted_21, _toDisplayString(_ctx.notApplicableErrorText), 1))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.$t('assessment.indicators.comments')), 1),
      _createVNode(_component_RiskComments, {
        project: _ctx.project,
        assessment: _ctx.assessment,
        "matrix-definition": _ctx.matrix,
        "indicator-key": _ctx.indicator.key,
        onRequestProjectRefresh: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('request-project-refresh', $event)))
      }, null, 8, ["project", "assessment", "matrix-definition", "indicator-key"])
    ]),
    _createVNode(_component_ButtonRow, {
      alignment: _ctx.ButtonAlignment.Right
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ButtonSingle, {
          outline: "",
          "is-rounded": "",
          text: _ctx.$t('common.actions.close'),
          onClicked: _ctx.onClose
        }, null, 8, ["text", "onClicked"])
      ]),
      _: 1
    }, 8, ["alignment"]),
    (_ctx.editDialog.visible)
      ? (_openBlock(), _createBlock(_component_RiskManualValueDialog, {
          key: 0,
          assets: _ctx.assets,
          indicator: _ctx.indicator,
          definition: _ctx.definition,
          matrix: _ctx.matrix,
          project: _ctx.project,
          assessment: _ctx.assessment,
          onCloseModal: _cache[4] || (_cache[4] = ($event: any) => (_ctx.editDialog.visible = false)),
          onRequestProjectRefresh: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('request-project-refresh', $event)))
        }, null, 8, ["assets", "indicator", "definition", "matrix", "project", "assessment"]))
      : _createCommentVNode("", true),
    _createVNode(_component_PopupDialog, {
      "is-visible": _ctx.deleteDialog.visible,
      header: _ctx.$t('common.actions.delete'),
      icon: 'trash-can',
      "show-action-button": true,
      "show-cancel-button": true,
      "action-text": _ctx.$t('common.actions.delete'),
      "action-icon": 'trash-can',
      "is-warning": true,
      progress: _ctx.deleteDialog,
      onCloseModal: _cache[6] || (_cache[6] = ($event: any) => (_ctx.deleteDialog.visible = false)),
      onAction: _ctx.onDeleteManualValue
    }, {
      default: _withCtx(() => [
        _createVNode(_component_PopupDialogPrompt, {
          "prompt-text": _ctx.$t('common.actions.delete'),
          "info-text": _ctx.$t('common.actions.notReversableHint')
        }, null, 8, ["prompt-text", "info-text"])
      ]),
      _: 1
    }, 8, ["is-visible", "header", "action-text", "progress", "onAction"])
  ]))
}