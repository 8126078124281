<template>
    <div v-if="!loading" class="amlv3-nav-routes">

        <div
            v-for="(level0, i) in routes"
            :key="'nav' + i"
        >

            <router-link
                :to="level0.path"
                class="amlv3-nav-route"
                :class="[
                    {'amlv3-nav-route-active': isRouteActive(level0) },
                    {'amlv3-nav-route-expanded': level0.expanded },
                ]"
            >

                <div v-if="isRouteActive(level0)" class="amlv3-nav-route-bullet">
                    {{ '•' }}
                </div>

                <div class="amlv3-nav-route-text">
                    {{ $t(level0.text) }}
                </div>

                <div class="amlv3-nav-route-arrow">
                    <FontIcon :icon="level0.expanded ? 'chevron-down' : 'chevron-right'" :size="14" />
                </div>

            </router-link>

            <div v-if="level0.expanded">

                <div
                    v-for="(level1, j) in level0.children"
                    :key="'nav' + i + j"
                    class="amlv3-nav-route-level1"
                >

                    <router-link
                        :to="level1.path"
                        class="amlv3-nav-route"
                        :class="[
                            {'amlv3-nav-route-active': isRouteActive(level1) },
                            {'amlv3-nav-route-expanded': level1.expanded },
                        ]"
                    >

                        <div v-if="isRouteActive(level1)" class="amlv3-nav-route-bullet">
                            {{ '•' }}
                        </div>

                        <div class="amlv3-nav-route-text">
                            {{ $t(level1.text) }}
                        </div>

                        <div class="amlv3-nav-route-arrow">
                            <FontIcon :icon="level1.expanded ? 'chevron-down' : 'chevron-right'" :size="14" />
                        </div>

                    </router-link>

                    <div v-if="level1.expanded">

                        <div
                            v-for="(level2, k) in level1.children"
                            :key="'nav' + i + j + k"
                            class="amlv3-nav-route-level2"
                        >

                            <router-link
                                :to="level2.path"
                                class="amlv3-nav-route"
                                :class="[
                                    {'amlv3-nav-route-active': isRouteActive(level2) },
                                    {'amlv3-nav-route-expanded': level2.expanded },
                                ]"
                            >

                                <div v-if="isRouteActive(level2)" class="amlv3-nav-route-bullet">
                                    {{ '•' }}
                                </div>

                                <div class="amlv3-nav-route-text">
                                    {{ $t(level2.text) }}
                                </div>

                                <div class="amlv3-nav-route-arrow">
                                    <FontIcon :icon="level2.expanded ? 'chevron-down' : 'chevron-right'" :size="14" />
                                </div>

                            </router-link>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import { RouteDefinition } from "../Nav.vue";
import FontIcon from "@/ui/FontIcon.vue";

export default defineComponent({
    name: "NavRoutes",
    components: {
        FontIcon,
    },
    props: {
        routes: { type: Array as PropType<RouteDefinition[]>, required: true },
    },
    data() {
        return {
            loading: true,
        };
    },
    watch: {
        $route() {
            this.expandRoutes();
        },
    },
    methods: {

        isRouteActive(route: RouteDefinition) {
            if (route.childActive) return true;
            if (route.active && route.active.length > 0) {
                if (route.active.includes(this.$route.path)) {
                    return true;
                }
                for (const i in route.active) {
                    const activeRoute = route.active[i];
                    if (activeRoute.includes("*")) {
                        if (this.$route.path.includes(activeRoute.replace("*", ""))) return true;
                    }
                }
            }
            return false;
        },

        onRouteClicked(route: RouteDefinition) {
            if (route.children && route.children.length > 0) {
                route.expanded = !route.expanded;
            }
            this.$router.push({ path: route.path });
        },

        expandRoutes() {
            const path = this.$route.path;

            for (const i in this.routes) {
                const level0 = this.routes[i];
                level0.expanded = false;
                level0.childActive = false;

                for (const j in level0.children) {
                    const level1 = level0.children[j];
                    level1.expanded = false;
                    level1.childActive = false;

                    if (level1.path === path) {
                        level0.expanded = true;
                    }

                    if (this.isRouteActive(level1)) {
                        level0.childActive = true;
                    }

                    for (const k in level1.children) {
                        const level2 = level1.children[k];

                        if (level2.path === path) {
                            level1.expanded = true;
                            level0.expanded = true;
                        }

                        if (this.isRouteActive(level2)) {
                            level1.childActive = true;
                            level0.childActive = true;
                        }
                    }
                }
            }
        },

    },
    async mounted() {
        await this.$router.isReady();
        this.expandRoutes();
        this.loading = false;
    },
});
</script>
<style scoped>

.amlv3-nav-route {
    display: flex;
    height: 48px;
    padding-top: 12px;
    padding-left: 10px;
    padding-right: 10px;
}

.amlv3-nav-route:hover {
    background-color: rgb(212, 238, 255);
}

.amlv3-nav-route-level1 .amlv3-nav-route {
    padding-left: 24px;
}
.amlv3-nav-route-level2 .amlv3-nav-route {
    padding-left: 48px;
}

.amlv3-nav-route-active {
    background-color: #E6F5FF;
}
.amlv3-nav-route-level2 .amlv3-nav-route-active {
    border-top: solid 1px #E1E1E1;
}

.amlv3-nav-route-expanded {
    border-top: solid 1px #E1E1E1;
    background-color: #F4F4F4;
}

.amlv3-nav-route-bullet {
    padding-right: 10px;
    font-size: 28px;
    line-height: 26px;
    color: #218BCB;
}
.amlv3-nav-route-expanded .amlv3-nav-route-bullet {
    color: #C4C4C4;
}

.amlv3-nav-route-text {
    flex-grow: 1;
    font-size: 16px;
    font-weight: 500;
    color: #444444;
}
.amlv3-nav-route-level2 .amlv3-nav-route-text {
    font-weight: 400;
}
.amlv3-nav-route-active .amlv3-nav-route-text {
    font-weight: 700;
    color: #218BCB;
}
.amlv3-nav-route-expanded .amlv3-nav-route-text {
    font-weight: 500;
    color: #000000;
}

.amlv3-nav-route-arrow {
    padding-top: 1px;
    color: #444444;
}
.amlv3-nav-route-active .amlv3-nav-route-arrow {
    color: #218BCB;
}

</style>
