import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4cb1b1f0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "rskv3-il-switch"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SwitchInput = _resolveComponent("SwitchInput")!
  const _component_IndicatorItem = _resolveComponent("IndicatorItem")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.assessment.locked)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", null, [
            _createVNode(_component_SwitchInput, {
              modelValue: _ctx.showMissingOnTop,
              "onUpdate:modelValue": [
                _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showMissingOnTop) = $event)),
                _ctx.onSwitchChanged
              ]
            }, null, 8, ["modelValue", "onUpdate:modelValue"])
          ]),
          _createElementVNode("div", {
            class: "rskv3-il-switch-text",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onSwitchChanged(!_ctx.showMissingOnTop)))
          }, _toDisplayString(_ctx.$t('assessment.indicators.list.moveToTop')), 1)
        ]))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedList, (indicator, i) => {
      return (_openBlock(), _createBlock(_component_IndicatorItem, {
        key: indicator.key,
        indicator: indicator,
        "is-first": i === 0,
        "is-last": i === _ctx.sortedList.length - 1,
        "is-selected": _ctx.selectedKey === indicator.key,
        onClicked: ($event: any) => (_ctx.onSelectIndicator(indicator))
      }, null, 8, ["indicator", "is-first", "is-last", "is-selected", "onClicked"]))
    }), 128))
  ]))
}