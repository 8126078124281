<template>
    <div class="w-full mx-auto bg-white rounded-lg py-5">
        <div v-if="showHeader" class="flex items-center text-blue-500 mb-4">
            <Icon class="w-4 h-4" icon="events" />
            <span class="ml-2 font-semibold text-lg">{{ $t("project.overviewMenu.lastEvents.title") }}</span>
        </div>
        <div class="text-body">
            <div
                v-for="event in events"
                :key="event.id"
                class="mb-2 flex gap-2"
            >
                <span class="text-light-gray text-nowrap">{{ $d(event.timestamp, "longDateTime") }} -</span>
                <span class=" font-normal text-wrap">{{ getDescription(event) }}</span>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Icon from "@/components/Icon.vue";
import { defineComponent, PropType } from "vue";
import { Event } from "@/lib/events/events";

export default defineComponent({
    name: "LastEvents",
    components: {
        Icon,
    },
    props: {
        showHeader: {
            type: Boolean,
            default: false,
        },
        events: {
            type: Array as PropType<Event[]>,
            default: () => [],
        },
    },
    methods: {
        getDescription(event: Event) {
            const data = event.data;
            switch (event.type) {
            case "person_created":
                // return `${data?.resource?.name} added to the system by ${data?.issuer?.name}`;
                return this.$t("eventText.personCreated", { resourceName: data?.resource?.name, issuerName: data?.issuer?.name });
            case "link_created":
                // return `${data?.person?.name} was linked to ${data?.project?.name} with role(s) ${data?.roles?.join(", ")} by ${data?.issuer?.name}`;
                return this.$t("eventText.personRoleLinked", {
                    personName: data?.person?.name,
                    projectName: data?.project?.name,
                    roles: data?.roles?.map((role: string) => this.$t("core.roles." + role)).join(", "),
                    issuerName: data?.issuer?.name,
                });
            case "link_updated":
                let msg = "";
                if (data?.roles?.added?.length) {
                    // msg += `Roles added ${data?.roles?.added?.join(", ")} by ${data?.issuer?.name}`;
                    msg += this.$t("eventText.rolesAdded", { rolesAdded: data?.roles?.added?.map((role: string) => this.$t("core.roles." + role)).join(", "), issuerName: data?.issuer?.name });
                }
                if (data?.roles?.removed?.length) {
                    if (msg.length) {
                        msg += "\n";
                    }
                    // msg += `Roles removed ${data?.roles?.removed?.join(", ")} by ${data?.issuer?.name}`;
                    msg += this.$t("eventText.rolesRemoved", { rolesRemoved: data?.roles?.removed?.map((role: string) => this.$t("core.roles." + role)).join(", "), issuerName: data?.issuer?.name });
                }
                return msg;
            case "person_monitor_toggled":
                return data.monitor
                    ? this.$t("eventText.personMonitoringStarted",
                        { resourceName: data?.resource?.name })
                    : this.$t("eventText.personMonitoringStopped", { resourceName: data?.resource?.name });

            default:
                return "";
            }
        },
    },
});
</script>

<style scoped>
/* Add any additional styling here if needed */
</style>
