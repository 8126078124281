import { Verified } from "../verified_plugin";
import { Person } from "./person";

export const addPersonTag = async (personId: string, tag: string) => {
    try {
        const response = await fetch(`/api/aml/v1/persons/${personId}/tags`, {
            method: "post",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
            body: JSON.stringify({
                tag: tag,
            }),
        });

        const body = await response.json();

        if (!response.ok) {
            throw new Error(body);
        }

        return body as Person;
    } catch (err) {
        console.error(`Failed to query projects`, err);
        throw err;
    }
};
