import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c7ec65fe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "amlv3-appselectd-approver-form" }
const _hoisted_3 = { class: "amlv3-appselectd-approver-actions" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  key: 2,
  class: "mt-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ComboBox = _resolveComponent("ComboBox")!
  const _component_InputGroup = _resolveComponent("InputGroup")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_FontIcon = _resolveComponent("FontIcon")!
  const _component_ButtonSingle = _resolveComponent("ButtonSingle")!
  const _component_ButtonRow = _resolveComponent("ButtonRow")!
  const _component_PopupDialogInfoIconAndText = _resolveComponent("PopupDialogInfoIconAndText")!
  const _component_PopupDialogSplit = _resolveComponent("PopupDialogSplit")!
  const _component_PopupDialog = _resolveComponent("PopupDialog")!

  return (_openBlock(), _createBlock(_component_PopupDialog, {
    "is-visible": _ctx.dialog.visible,
    header: _ctx.$t('assessment.approval.newTitle'),
    icon: 'thumbs-up',
    "show-cancel-button": true,
    "show-action-button": true,
    "action-text": _ctx.$t('assessment.approval.startAction'),
    "action-icon": 'share',
    "is-action-disabled": !_ctx.dialog.valid,
    progress: _ctx.dialog,
    onAction: _ctx.addNewApproval,
    onCloseModal: _ctx.onClose
  }, {
    default: _withCtx(() => [
      _createVNode(_component_PopupDialogSplit, {
        style: {"min-width":"500px","max-width":"560px"},
        "prompt-text": _ctx.$t('assessment.approval.promptText'),
        "prompt-hint": _ctx.$t('assessment.approval.promptHint')
      }, {
        default: _withCtx(() => [
          (!_ctx.dialog.isWorking && !(_ctx.needsLock && !_ctx.canLock))
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.approvers, (approver, i) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: 'asda' + i,
                    class: "amlv3-appselectd-approver"
                  }, [
                    _createElementVNode("div", _hoisted_2, [
                      _createVNode(_component_InputGroup, { width: 6 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ComboBox, {
                            modelValue: approver.value,
                            "onUpdate:modelValue": [($event: any) => ((approver.value) = $event), ($event: any) => (_ctx.onSelectApprover(approver))],
                            items: _ctx.dropdownItems,
                            placeholder: _ctx.$t('common.searchEmployee')
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "items", "placeholder"])
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_InputGroup, { width: 6 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_InputText, {
                            modelValue: approver.email,
                            "onUpdate:modelValue": ($event: any) => ((approver.email) = $event),
                            "unique-id": 'approver-email',
                            onInput: _ctx.validateDialog,
                            readonly: true
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "onInput"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("div", {
                        class: "amlv3-appselectd-approver-action",
                        onClick: ($event: any) => (_ctx.onDeleteApprover(i))
                      }, [
                        _createVNode(_component_FontIcon, { icon: 'trash-can' })
                      ], 8, _hoisted_4)
                    ])
                  ]))
                }), 128)),
                _createVNode(_component_ButtonRow, {
                  class: "mt-4",
                  alignment: _ctx.ButtonAlignment.Right
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ButtonSingle, {
                      "is-link": "",
                      text: _ctx.$t('assessment.approval.approvers.addAction'),
                      onClicked: _ctx.onAddApprover
                    }, null, 8, ["text", "onClicked"])
                  ]),
                  _: 1
                }, 8, ["alignment"])
              ]))
            : _createCommentVNode("", true),
          (!_ctx.canLock)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_PopupDialogInfoIconAndText, {
                  text: _ctx.$t('assessment.approval.completeHint'),
                  "is-warning": true
                }, null, 8, ["text"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.needsLock && _ctx.canLock)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_PopupDialogInfoIconAndText, {
                  text: _ctx.$t('assessment.approval.lockHint')
                }, null, 8, ["text"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["prompt-text", "prompt-hint"])
    ]),
    _: 1
  }, 8, ["is-visible", "header", "action-text", "is-action-disabled", "progress", "onAction", "onCloseModal"]))
}