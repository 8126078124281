import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-71f06d92"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "amlv3-dependency-item"
}
const _hoisted_2 = { class: "amlv3-dependency-item-name" }
const _hoisted_3 = { class: "amlv3-dependency-item-action amlv3-dependency-item-action-last" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuSwitch = _resolveComponent("MenuSwitch")!
  const _component_FontIcon = _resolveComponent("FontIcon")!
  const _component_LinkDependencyDialog = _resolveComponent("LinkDependencyDialog")!
  const _component_PopupDialogPrompt = _resolveComponent("PopupDialogPrompt")!
  const _component_PopupDialog = _resolveComponent("PopupDialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_MenuSwitch, {
      modelValue: _ctx.filterMode,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filterMode) = $event)),
      items: _ctx.filterItems,
      "has-button": !_ctx.project.assessment.locked && !(_ctx.dependency && _ctx.dependency.hasDependency),
      "button-icon": 'link',
      "button-text": 'Link dependency',
      onClicked: _cache[1] || (_cache[1] = ($event: any) => (_ctx.newDialog.visible = true))
    }, null, 8, ["modelValue", "items", "has-button"]),
    (_ctx.dependency && _ctx.dependency.hasDependency)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.dependency.data.name), 1),
          _createElementVNode("div", {
            class: "amlv3-dependency-item-action",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.deleteDialog.visible = true))
          }, [
            _createVNode(_component_FontIcon, {
              icon: 'trash-can',
              size: 18
            })
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_FontIcon, {
              icon: 'arrow-up-right-from-square',
              size: 18
            })
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.newDialog.visible)
      ? (_openBlock(), _createBlock(_component_LinkDependencyDialog, {
          key: 1,
          action: _ctx.action,
          project: _ctx.project,
          onCloseModal: _cache[3] || (_cache[3] = ($event: any) => (_ctx.newDialog.visible = false)),
          onRequestProjectRefresh: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('request-project-refresh', $event)))
        }, null, 8, ["action", "project"]))
      : _createCommentVNode("", true),
    _createVNode(_component_PopupDialog, {
      "is-visible": _ctx.deleteDialog.visible,
      header: _ctx.$t('common.actions.delete'),
      icon: 'trash-can',
      "show-action-button": true,
      "show-cancel-button": true,
      "action-text": _ctx.$t('common.actions.delete'),
      "action-icon": 'trash-can',
      progress: _ctx.deleteDialog,
      "is-warning": true,
      onCloseModal: _cache[5] || (_cache[5] = ($event: any) => (_ctx.deleteDialog.visible = false)),
      onAction: _ctx.deleteDependency
    }, {
      default: _withCtx(() => [
        _createVNode(_component_PopupDialogPrompt, {
          "is-warning": true,
          "prompt-text": _ctx.$t('common.actions.areYouSure')
        }, null, 8, ["prompt-text"])
      ]),
      _: 1
    }, 8, ["is-visible", "header", "action-text", "progress", "onAction"])
  ]))
}