<template>
    <div class="border rounded-md border-light border-solid p-2 break-all">
        <div class="flex gap-3">
            <div class="flex-0 w-[4px] bg-yellow-500 rounded-full">
            </div>
            <div class="flex-1 text-sm">
                <div class="font-bold hover:cursor-pointer" @click="collapsed = !collapsed">
                    {{ matchName }}
                </div>
                <div class="flex text-[10px] gap-1 justify-start items-center hover:cursor-pointer" @click="collapsed = !collapsed">
                    <div
                        v-if="matchFlags.length"
                        v-for="flag in matchFlags"
                        :key="flag"
                        class="border rounded-full px-2 bg-background"
                    >
                        {{ flag }}
                    </div>
                    <div v-if="!matchFlags.length" class="border rounded-full px-1 bg-green-300">
                        {{ $t('project.tabs.persons.screeningHitsDialog.noFlags') }}
                    </div>
                </div>
                <div v-if="!collapsed" class="mt-2 text-xs flex flex-col gap-2">
                    <div>
                        <div>{{ $t('project.tabs.persons.screeningHitsDialog.matchScore') }}</div>
                        <div class="font-bold">
                            {{ hit?.match?.score || $t('project.tabs.persons.screeningHitsDialog.unknown') }}
                        </div>
                    </div>

                    <div v-if="hit?.hit?.birthdates?.length">
                        <div>{{ $t('project.tabs.persons.screeningHitsDialog.birthdate') }}</div>
                        <div v-for="dob in hit?.hit?.birthdates" class="font-bold">
                            {{ dob?.year || "----" }}/{{ dob?.month || "--" }}/{{ dob?.day || "--" }}
                        </div>
                    </div>

                    <div v-if="hit?.hit?.gender">
                        <div>{{ $t('project.tabs.persons.screeningHitsDialog.gender') }}</div>
                        <div class="font-bold">
                            {{ hit?.hit?.gender || $t('project.tabs.persons.screeningHitsDialog.unknown') }}
                        </div>
                    </div>

                    <div v-if="hit?.hit?.nationalities?.length">
                        <div>{{ $t('project.tabs.persons.screeningHitsDialog.nationalities') }}</div>
                        <div class="font-bold">
                            {{ hit?.hit?.nationalities?.join(", ") }}
                        </div>
                    </div>

                    <div v-if="hit?.hit?.lists?.length">
                        <div>{{ $t('project.tabs.persons.screeningHitsDialog.sanctionLists') }}</div>
                        <div v-for="list in hit?.hit?.lists" class="font-bold">
                            {{ list }}
                        </div>
                    </div>

                    <div v-if="hit?.hit?.sources?.length">
                        <div>{{ $t('project.tabs.persons.screeningHitsDialog.sources') }}</div>
                        <div v-for="source in hit?.hit?.sources" class="font-bold">
                            {{ source }}
                        </div>
                    </div>

                    <div v-if="hit?.hit?.notes?.length">
                        <div>{{ $t('project.tabs.persons.screeningHitsDialog.notes') }}</div>
                        <div v-for="note in hit?.hit?.notes" class="font-bold">
                            {{ note }}
                        </div>
                    </div>

                    <div v-if="hit?.hit?.images?.length">
                        <div>{{ $t('project.tabs.persons.screeningHitsDialog.images') }}</div>
                        <div v-for="image in hit?.hit?.images" class="font-bold">
                            {{ image }}
                        </div>
                    </div>

                    <div v-if="hit?.hit?.roles?.length">
                        <div>{{ $t('project.tabs.persons.screeningHitsDialog.roles') }}</div>
                        <div v-for="role in hit?.hit?.roles?.filter(x => x.description)" class="bg-background rounded-md mt-2 p-2">
                            <div class="font-bold">
                                {{ role?.description }}
                            </div>
                            <div>{{ $t('project.tabs.persons.screeningHitsDialog.roleActive') }} <span class="font-bold">{{ role?.isActive === true ? $t('project.tabs.persons.screeningHitsDialog.yes') : role?.isActive === 'false' ? $t('project.tabs.persons.screeningHitsDialog.no') : $t('project.tabs.persons.screeningHitsDialog.unknown') }}</span></div>
                            <div>{{ $t('project.tabs.persons.screeningHitsDialog.from') }} <span class="font-bold">{{ role?.sinceYear || '----' }}/{{ role?.sinceMonth || '--' }}/{{ role?.sinceDay || '--' }}</span> {{ $t('project.tabs.persons.screeningHitsDialog.to') }} <span class="font-bold">{{ role?.toYear || '----' }}/{{ role?.toMonth || '--' }}/{{ role?.toDay || '--' }}</span></div>
                        </div>
                    </div>
                </div>
            </div>
            <div @click="collapsed = !collapsed" class="flex p-2 mr-2 text-gray-400 hover:cursor-pointer">
                <FontIcon
                    v-if="collapsed"
                    icon="caret-up"
                    :size="24"
                />
                <FontIcon
                    v-if="!collapsed"
                    icon="caret-down"
                    :size="24"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import FontIcon from "@/ui/FontIcon.vue";

export default defineComponent({
    name: "ScreeningHit",
    components: {
        FontIcon,
    },
    props: {
        hit: {
            type: Object,
            required: true,
        },
    },
    computed: {
        matchName(): string {
            return this.hit?.match?.firstName + " " + this.hit?.match?.lastName;
        },
        matchFlags(): string[] {
            const flags: string[] = [];

            if (this.hit?.match?.pep) {
                flags.push("PEP");
            }

            if (this.hit?.match?.sanction) {
                flags.push("Sanction");
            }

            if (this.hit?.match?.sip) {
                flags.push("SIP");
            }

            if (this.hit?.match?.rca) {
                flags.push("RCA");
            }

            return flags;
        },
    },
    data() {
        return {
            collapsed: true,
            falsePositive: false,
        };
    },
    emits: ["updateFalsePositive"],
    watch: {
        falsePositive(value) {
            this.$emit("updateFalsePositive", value);
        },
    },
});
</script>

<style scoped>
.border-yellow-200 {
    border-color: #FDE68A;
}
.bg-yellow-50 {
    background-color: #FFFBEB;
}
</style>import { match } from "assert";
