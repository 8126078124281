import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c0bd83e8"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "value", "name", "onInput"]
const _hoisted_2 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["fbav3-input-radio", [
            {'flex': _ctx.isInline },
        ]])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "flex items-center mb-2 mx-2",
        key: _ctx.uniqueId + i
      }, [
        _withDirectives(_createElementVNode("input", {
          id: _ctx.uniqueId + item.key,
          type: "radio",
          value: item.key,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.picked) = $event)),
          name: _ctx.uniqueId,
          class: "w-4 h-4 text-primary-default bg-gray-100 border-light-gray focus:ring-blue-500",
          onInput: ($event: any) => (_ctx.onChange(item))
        }, null, 40, _hoisted_1), [
          [_vModelRadio, _ctx.picked]
        ]),
        _createElementVNode("label", {
          for: _ctx.uniqueId + item.key,
          class: "ms-2 text-sm font-medium text-gray-900",
          style: {"padding-top":"10px","padding-left":"3px"}
        }, _toDisplayString(item.textKey ? _ctx.$t(item.textKey) : _ctx.$ct(item.text)), 9, _hoisted_2)
      ]))
    }), 128))
  ], 2))
}