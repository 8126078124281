import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PopupDialogInfoIconAndText = _resolveComponent("PopupDialogInfoIconAndText")!
  const _component_PopupDialogSplit = _resolveComponent("PopupDialogSplit")!
  const _component_PopupDialog = _resolveComponent("PopupDialog")!

  return (_openBlock(), _createBlock(_component_PopupDialog, {
    "is-visible": _ctx.dialog.visible,
    header: _ctx.isUnlocking ? _ctx.$t('assessment.lock.unlockAction') : _ctx.$t('assessment.lock.lockAction'),
    icon: _ctx.isUnlocking ? 'lock-open' : 'lock',
    "show-cancel-button": true,
    "show-action-button": true,
    "action-text": _ctx.isUnlocking ? _ctx.$t('assessment.lock.unlockActionShort') : _ctx.$t('assessment.lock.lockAction'),
    "action-icon": _ctx.isUnlocking ? 'lock-open' : 'lock',
    progress: _ctx.dialog,
    onAction: _ctx.lockCurrentAssessment,
    onCloseModal: _ctx.onClose
  }, {
    default: _withCtx(() => [
      _createVNode(_component_PopupDialogSplit, {
        "prompt-text": _ctx.isUnlocking ? _ctx.$t('assessment.lock.unlockAction') : _ctx.$t('assessment.lock.lockAction'),
        "prompt-hint": _ctx.isUnlocking ? _ctx.$t('assessment.lock.unlockHint1') : _ctx.$t('assessment.lock.lockHint1')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_PopupDialogInfoIconAndText, {
            text: _ctx.isUnlocking ? _ctx.$t('assessment.lock.unlockHint2') : _ctx.$t('assessment.lock.lockHint2')
          }, null, 8, ["text"])
        ]),
        _: 1
      }, 8, ["prompt-text", "prompt-hint"])
    ]),
    _: 1
  }, 8, ["is-visible", "header", "icon", "action-text", "action-icon", "progress", "onAction", "onCloseModal"]))
}