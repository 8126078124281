<template>
    <div v-if="project">

        <MenuTabs
            v-if="isLoaded"
            :title="$t('project.overviewMenu.title')"
            :tabs="tabs"
            :selected-tab="selectedTab"
            @tab="onTabChanged"
        />

        <div class="mt-5" v-if="selectedTab == 'overview'">
            <div v-if="matrixDefinition">
                <RiskSliderBox
                    v-if="riskConfig"
                    :project="project"
                    :assessment="currentAssessment"
                    :risk-config="riskConfig"
                    :matrix-definition="matrixDefinition"
                    @request-project-refresh="$emit('request-project-refresh', $event)"
                    @new-assessment="newDialog.visible = true"
                />
            </div>
            <div class="mt-4" v-if="matrixDefinition && matrixDefinition.options.manualScore">
                <RiskComments
                    :project="project"
                    :assessment="currentAssessment"
                    :matrix-definition="matrixDefinition"
                    @request-project-refresh="$emit('request-project-refresh', $event)"
                />
            </div>
            <div class="mt-4" v-if="!currentAssessment.sealed">
                <FlowRequirements :requirements="renderData.requirements" />
            </div>
            <div class="mt-4">
                <OtherProjects
                    :project="project"
                    @menu-tab="onTabChanged"
                    @project-link="$emit('project-link', $event)"
                />
            </div>
            <div class="mt-4">
                <PreviousAssessments
                    :assessments="assessments"
                    :project="project"
                    @menu-tab="onTabChanged"
                />
            </div>
            <div class="mt-4">
                <MenuLastEvents :project="project" @menu-tab="onTabChanged" />
            </div>
        </div>

        <div class="mt-5" v-if="selectedTab == 'forms'">
            <FormList
                :context-type="FormContextType.Project"
                :context-id="project.id"
                :forms="forms"
                :project="project"
                @request-project-refresh="$emit('request-project-refresh', $event)"
            />
        </div>

        <div class="mt-5" v-if="selectedTab == 'approvals'">
            <ApprovalList
                :project="project"
                :assessment="currentAssessment"
                :initial-key="initialKeyApproval"
                @request-project-refresh="$emit('request-project-refresh', $event)"
            />
        </div>

        <div class="mt-5" v-if="selectedTab == 'events'">
            <MenuTabEvents
                :project="project"
                @request-project-refresh="$emit('request-project-refresh', $event)"
            />
        </div>

        <div class="mt-5" v-if="selectedTab == 'attachments'">
            <MenuAttachments
                :attachments="project.attachments"
                :project-id="project.id"
                @request-project-refresh="$emit('request-project-refresh', $event)"
            />
        </div>

        <div class="mt-5" v-if="selectedTab == 'dependency'">
            <DependencyControl
                :project="project"
                :dependency="dependency"
                @request-project-refresh="$emit('request-project-refresh', $event)"
            />
        </div>

        <!-- <div>
            <pre>
                {{ project.assessment }}
            </pre>
        </div> -->

        <NewAssessmentDialog
            v-if="newDialog.visible"
            :project="project"
            @close-modal="newDialog.visible = false"
            @request-project-refresh="$emit('request-project-refresh', $event)"
        />

    </div>
</template>
<script lang="ts">
import RiskSliderBox from "@/components/assessment/RiskSliderBox.vue";
import FormList from "@/components/forms/FormList.vue";
import { Assessment } from "@/lib/assessments/assessments";
import { Form, FormContextType } from "@/lib/forms/forms";
import { Project } from "@/lib/projects/projects";
import { RiskConfig } from "@/lib/settings/settings";
import { FlowRenderData } from "@/types/flow_types";
import { defineComponent, type PropType } from "vue";
import MenuTabs, { MenuTab } from "./MenuTabs.vue";
import { RiskMatrixDefinition } from "@/lib/risk/risk_matrix";
import { getRiskMatrix } from "@/lib/risk/get_matrices";
import FlowRequirements from "@/components/assessment/FlowRequirements.vue";
import PreviousAssessments from "@/components/assessment/PreviousAssessments.vue";
import MenuLastEvents from "./MenuLastEvents.vue";
import { loadAssessments } from "@/lib/assessments/assessment_helper";
import ApprovalList from "@/components/approvals/ApprovalList.vue";
import RiskComments from "@/components/assessment/RiskComments.vue";
import NewAssessmentDialog from "@/components/assessment/NewAssessmentDialog.vue";
import MenuTabEvents from "./MenuTabEvents.vue";
import OtherProjects from "../OtherProjects.vue";
import MenuAttachments from "./MenuAttachments.vue";
import DependencyControl from "../DependencyControl.vue";

export default defineComponent({
    name: "OverviewMenu",
    components: {
        FormList,
        RiskSliderBox,
        MenuTabs,
        FlowRequirements,
        PreviousAssessments,
        MenuLastEvents,
        ApprovalList,
        RiskComments,
        NewAssessmentDialog,
        MenuTabEvents,
        OtherProjects,
        MenuAttachments,
        DependencyControl,
    },
    emits: ["request-project-refresh", "project-link"],
    props: {
        project: { type: Object as PropType<Project>, required: true },
        forms: { type: Array as PropType<Form[]>, required: true },
        assessments: { type: Array as PropType<Assessment[]>, required: true },
        renderData: { type: Object as PropType<FlowRenderData>, required: true },
        dependency: { type: Object as PropType<any>, required: true },
    },
    data() {
        return {
            FormContextType,

            isLoaded: false,
            isOverriding: false,

            tabs: new Array<MenuTab>(),
            selectedTab: "overview",

            currentAssessment: {} as Assessment,
            previousAssessments: new Array<Assessment>(),

            riskConfig: null as RiskConfig | null,
            matrixDefinition: null as RiskMatrixDefinition | null,

            newDialog: { visible: false },

            initialKeyApproval: "",
        };
    },
    watch: {
        assessments: {
            deep: true,
            handler() {
                this.loadAssessments();
            },
        },
    },
    methods: {

        goToTabAndItem(tabKey: string, itemKey: string) {
            if (tabKey === "approvals") {
                this.initialKeyApproval = itemKey;
            }

            this.onTabChanged(tabKey);

            this.$nextTick(() => {
                this.removeInitalKeys();
            });
        },

        removeInitalKeys() {
            this.initialKeyApproval = "";
        },

        onTabChanged(tabKey: string) {
            this.selectedTab = tabKey;
        },

        loadAssessments() {
            const loadResult = loadAssessments(this.assessments);
            this.currentAssessment = loadResult.currentAssessment;
            this.previousAssessments = loadResult.previousAssessments;
        },

        async fetchRiskMatrix() {
            const response = await getRiskMatrix(this.currentAssessment.matrixKey);
            if (response.responseInfo.isError || !response.matrix) {
                // todo: error handling
            } else {
                this.matrixDefinition = response.matrix;
            }
        },

    },
    created() {
        this.loadAssessments();
        this.fetchRiskMatrix();
    },
    mounted() {
        this.tabs.push({
            key: "overview",
            textKey: "project.overviewMenu.tabs.overview",
            number: 0,
        });
        this.tabs.push({
            key: "forms",
            textKey: "project.overviewMenu.tabs.forms",
            number: 0,
        });
        this.tabs.push({
            key: "approvals",
            textKey: "project.overviewMenu.tabs.approvals",
            number: 0,
        });
        this.tabs.push({
            key: "attachments",
            textKey: "project.overviewMenu.tabs.attachments",
            number: 0,
        });
        this.tabs.push({
            key: "events",
            textKey: "project.overviewMenu.tabs.events",
            number: 0,
        });
        // todo: not for MVP
        /* this.tabs.push({
            key: "notes",
            textKey: "project.overviewMenu.tabs.notes",
            number: 0,
        });
        this.tabs.push({
            key: "tags",
            textKey: "project.overviewMenu.tabs.tags",
            number: 0,
        }); */
        this.tabs.push({
            key: "dependency",
            textKey: "project.overviewMenu.tabs.dependency",
            number: 0,
        });
        this.tabs.push({
            key: "assessments",
            textKey: "project.overviewMenu.tabs.assessments",
            number: 0,
        });
        if (this.$config.settings.riskConfigs) {
            this.riskConfig = this.$config.settings.riskConfigs[0];
        }
        this.isLoaded = true;
    },
});
</script>
<style scoped></style>
