<template>
    <div class="border border-slate-300 flex flex-col rounded-lg mb-8">
        <div class="relative">
            <HalfDoughnutChart :chart-data="chartData" :chart-options="chartOptions" />
            <div class="absolute top-2/3 left-1/2 transform -translate-x-1/2 pb-4 -translate-y-1/2 z-10 text-center">
                <p class="text-3xl font-roboto-slab">
                    152
                </p>
                <p>Total clients</p>
            </div>
        </div>
        <div class="-mt-10 px-5">
            <div class="flex sm:flex-col justify-around gap-1 w-full pb-5">
                <div class="flex justify-between text-sm">
                    <div class="hidden sm:block">
                        Not Assessed
                    </div>
                    <div class="flex justify-between gap-3 items-center">
                        <label class="w-2 h-2 font-medium rounded-full bg-gray-500"></label><label>33%</label>
                    </div>
                </div>
                <div class="flex justify-between text-sm">
                    <div class="hidden sm:block">
                        Low
                    </div>
                    <div class="flex justify-between gap-3 items-center">
                        <label class="w-2 h-2 rounded-full bg-green-500"></label><label>20%</label>
                    </div>
                </div>
                <div class="flex justify-between text-sm">
                    <div class="hidden sm:block">
                        Medium
                    </div>
                    <div class="flex justify-between gap-3 items-center">
                        <label class="w-2 h-2 rounded-full font-medium bg-orange-500"></label><label>13%</label>
                    </div>
                </div>
                <div class="flex justify-between text-sm">
                    <div class="hidden sm:block">
                        High
                    </div>
                    <div class="flex justify-between gap-3 items-center">
                        <label class="w-2 h-2 font-medium rounded-full bg-purple-500"></label><label>32%</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="grid grid-cols-1 sm:grid-cols-2 shrink-0 gap-4 mb-8">
        <StatBox :value="25" label="Ongoing assesments" />
        <StatBox :value="152" label="Total Customers" />
    </div>
</template>

<script>
import HalfDoughnutChart from "@/components/HalfDoughnutChart.vue";
import { defineComponent } from "vue";
import StatBox from "./StatBox.vue";

export default defineComponent({
    components: {
        HalfDoughnutChart,
        StatBox,
    },
    data() {
        return {
            chartData: {
                type: "doughnut",
                labels: ["Not Assessed", "High", "Medium", "Low"],
                datasets: [
                    {
                        // label: ["High", "Medium", "Low"],
                        data: [20, 300, 50, 100],
                        backgroundColor: ["#909294", "rgb(255, 99, 132)", "rgb(54, 162, 235)", "rgb(255, 205, 86)"],
                    },
                ],
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: true,
                cutout: "80%",
                rotation: -90,
                circumference: 180,
                layout: {
                    padding: {
                        left: 20,
                        right: 20,
                        top: 5,
                        bottom: 0,
                    },
                },
                plugins: {
                    title: {
                        display: true,
                        text: "Risk distribution:",
                        position: "top",
                        align: "start",
                        padding: {
                            top: 20, // Reduced top padding
                            bottom: -10,
                            left: 0,
                            right: 0,
                            // bottom: -100,
                        },
                        font: {
                            size: 18,
                            weight: "normal",
                        },
                    },
                    legend: {
                        display: false,
                    },
                    subtitle: {
                        display: false,
                        text: "Custom Chart Subtitle",
                    },
                },
            },
        };
    },
});
</script>

<style scoped>
/* You can add some additional styling if needed */
</style>
