import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "overflow-y-scroll w-[700px] h-[70vh] break-all pr-1" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "border rounded border-light border-solid text-sm" }
const _hoisted_5 = { class: "m-2" }
const _hoisted_6 = { class: "text-sm w-full font-bold mb-2" }
const _hoisted_7 = { class: "flex gap-1 justify-start items-center text-[12px]" }
const _hoisted_8 = {
  key: 0,
  class: "border rounded-full p-1 px-3 bg-[#F2CA61]"
}
const _hoisted_9 = {
  key: 1,
  class: "border rounded-full p-1 px-3 bg-[#F2CA61]"
}
const _hoisted_10 = {
  key: 2,
  class: "border rounded-full p-1 px-3 bg-[#F2CA61]"
}
const _hoisted_11 = {
  key: 3,
  class: "border rounded-full p-1 px-3 bg-[#F2CA61]"
}
const _hoisted_12 = {
  key: 4,
  class: "border rounded-full p-1 px-3 bg-green-300"
}
const _hoisted_13 = { class: "border rounded border-light border-solid text-sm mt-2" }
const _hoisted_14 = { class: "m-2" }
const _hoisted_15 = { class: "border-t border-gray-300" }
const _hoisted_16 = { class: "text-sm font-bold w-full mb-2" }
const _hoisted_17 = {
  key: 0,
  class: "flex flex-col gap-2"
}
const _hoisted_18 = {
  key: 1,
  class: "flex flex-col gap-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ScreeningHit = _resolveComponent("ScreeningHit")!
  const _component_PopupDialog = _resolveComponent("PopupDialog")!

  return (_openBlock(), _createBlock(_component_PopupDialog, {
    "is-visible": _ctx.dialog.visible,
    header: _ctx.name,
    icon: 'clipboard-list',
    progress: _ctx.dialog,
    "show-cancel-button": true,
    "cancel-button-text": _ctx.$t('project.tabs.persons.screeningHitsDialog.closeBtn'),
    "show-action-button": false,
    "action-icon": 'repeat',
    "is-action-disabled": true,
    "action-text": _ctx.$t('project.tabs.persons.screeningHitsDialog.updateFalsePositivesBtn'),
    "is-warning": false,
    onCloseModal: _ctx.close,
    onAction: _ctx.updateAssignee
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, " Loading... "))
          : _createCommentVNode("", true),
        (!_ctx.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('project.tabs.persons.screeningHitsDialog.summaryTitle')), 1),
                  _createElementVNode("div", _hoisted_7, [
                    (_ctx.person.screeningStatus?.pep == 'hitfound')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.$t('project.tabs.persons.screeningHitsDialog.pepLong')), 1))
                      : _createCommentVNode("", true),
                    (_ctx.person.screeningStatus?.sanction == 'hitfound')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.$t('project.tabs.persons.screeningHitsDialog.sanctionsLong')), 1))
                      : _createCommentVNode("", true),
                    (_ctx.person.screeningStatus?.rca == 'hitfound')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.$t('project.tabs.persons.screeningHitsDialog.rcaLong')), 1))
                      : _createCommentVNode("", true),
                    (_ctx.person.screeningStatus?.sip == 'hitfound')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.$t('project.tabs.persons.screeningHitsDialog.sipLong')), 1))
                      : _createCommentVNode("", true),
                    (_ctx.person.screeningStatus?.pep != 'hitfound' && _ctx.person.screeningStatus?.sanction != 'hitfound' && _ctx.person.screeningStatus?.rca != 'hitfound' && _ctx.person.screeningStatus?.sip != 'hitfound')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.$t('project.tabs.persons.screeningHitsDialog.noFlags')), 1))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t('project.tabs.persons.screeningHitsDialog.hitsTitle')), 1),
                    (_ctx.hits.length)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hits, (hit, index) => {
                            return (_openBlock(), _createBlock(_component_ScreeningHit, {
                              key: index,
                              hit: hit,
                              "false-positive": hit.falsePositive
                            }, null, 8, ["hit", "false-positive"]))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true),
                    (!_ctx.hits.length)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_18, _toDisplayString(_ctx.$t('project.tabs.persons.screeningHitsDialog.noHits')), 1))
                      : _createCommentVNode("", true)
                  ])
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["is-visible", "header", "progress", "cancel-button-text", "action-text", "onCloseModal", "onAction"]))
}