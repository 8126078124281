<template>
    <div>

        <PopupDialog
            :is-visible="promoteDialog.visible"
            :header="$t('risk.matrix.promote')"
            :icon="'code-pull-request'"
            :show-action-button="true"
            :show-cancel-button="true"
            :is-action-disabled="!promoteDialog.valid"
            :action-text="$t('risk.matrix.promote')"
            :action-icon="'code-pull-request'"
            :progress="promoteDialog"
            @close-modal="onClose"
            @action="promoteMatrix"
        >
            <PopupDialogPrompt
                :prompt-text="$t('risk.matrix.promotePrompttext')"
                :info-text="$t('risk.matrix.promoteHintText', promoteDialog.count)"
                :is-loading="promoteDialog.isWorking"
            />
        </PopupDialog>

    </div>
</template>
<script lang="ts">
import { PropType, defineComponent } from "vue";

import { RiskMatrixDefinition } from "@/lib/risk/risk_matrix";

import PopupDialog from "@/ui/PopupDialog.vue";

import { ApiResponse } from "@/lib/error_parser";
import { promoteRiskMatrix } from "@/lib/risk/promote_matrix";
import { getWorkflowProjectCount } from "@/lib/workflows/count_workflow_projects";
import PopupDialogPrompt from "@/ui/dialog/PopupDialogPrompt.vue";

export default defineComponent({
    name: "PromoteMatrixDialog",
    components: {
        PopupDialog,
        PopupDialogPrompt,
    },
    emits: ["close-modal", "promoted"],
    props: {
        matrix: { type: Object as PropType<RiskMatrixDefinition>, required: true },
    },
    data() {
        return {
            promoteDialog: { visible: true, valid: false, isWorking: false, statusText: "", isError: false, errorText: "", key: "", count: -1 },
        };
    },
    methods: {

        onClose() {
            this.$emit("close-modal");
        },

        async showPromoteDialog() {
            this.promoteDialog.count = -1;
            this.promoteDialog.isError = false;

            this.promoteDialog.isWorking = true;
            this.promoteDialog.statusText = "Checking workflows";

            this.promoteDialog.key = this.matrix.key;
            this.promoteDialog.visible = true;

            const workflows = this.$config.settings.workflows || [];
            const promises: Promise<{ count: number | null, responseInfo: ApiResponse }>[] = [];
            workflows.forEach((workflow) => {
                if (workflow.params.matrixKey === this.promoteDialog.key) {
                    promises.push(getWorkflowProjectCount(workflow.key));
                }
            });
            const responses = await Promise.allSettled(promises);

            let count = 0;
            responses.forEach((response) => {
                if (response.status === "fulfilled") {
                    if (response.value.responseInfo.isError || response.value.count == null) {
                        this.promoteDialog.isError = true;
                        this.promoteDialog.errorText = response.value.responseInfo.parsedError?.message || "Unknown error";
                    } else {
                        count += response.value.count;
                    }
                } else {
                    this.promoteDialog.isError = true;
                    this.promoteDialog.errorText = "Unable to check workflows!";
                }
            });

            this.promoteDialog.valid = true;
            this.promoteDialog.count = count;
            this.promoteDialog.isWorking = false;
        },

        async promoteMatrix() {
            this.promoteDialog.isWorking = true;
            this.promoteDialog.statusText = "Promoting risk matrix";

            const response = await promoteRiskMatrix(this.promoteDialog.key);

            if (response.responseInfo.isError) {
                this.promoteDialog.isError = true;
                this.promoteDialog.errorText = response.responseInfo.parsedError?.message || "Unknown error";
            } else {
                this.$emit("promoted");
                this.onClose();
            }
            this.promoteDialog.isWorking = false;
        },

    },
    created() {
        this.showPromoteDialog();
    },
});
</script>
