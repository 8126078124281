import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputLabel = _resolveComponent("InputLabel")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_InputGroup = _resolveComponent("InputGroup")!
  const _component_InputTextLocalized = _resolveComponent("InputTextLocalized")!
  const _component_PopupDialogSplit = _resolveComponent("PopupDialogSplit")!
  const _component_PopupDialog = _resolveComponent("PopupDialog")!

  return (_openBlock(), _createBlock(_component_PopupDialog, {
    "is-visible": _ctx.dialog.visible,
    header: _ctx.$t('common.person_info.name'),
    icon: 'language',
    "show-cancel-button": true,
    "show-action-button": true,
    "action-text": 'Save',
    "action-icon": 'floppy-disk',
    "is-action-disabled": !_ctx.dialog.valid,
    onCloseModal: _ctx.onClose,
    onAction: _ctx.onSave
  }, {
    default: _withCtx(() => [
      _createVNode(_component_PopupDialogSplit, null, {
        default: _withCtx(() => [
          (_ctx.hasKey)
            ? (_openBlock(), _createBlock(_component_InputGroup, {
                key: 0,
                width: 12
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_InputLabel, {
                    text: _ctx.$t('common.person_info.key'),
                    "unique-id": 'end-key'
                  }, null, 8, ["text"]),
                  _createVNode(_component_InputText, {
                    modelValue: _ctx.keyModel,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.keyModel) = $event)),
                    "unique-id": 'end-key',
                    onInput: _ctx.validateDialog
                  }, null, 8, ["modelValue", "onInput"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_InputGroup, { width: 12 }, {
            default: _withCtx(() => [
              _createVNode(_component_InputLabel, {
                text: _ctx.$t('common.person_info.name'),
                "unique-id": 'end-name'
              }, null, 8, ["text"]),
              _createVNode(_component_InputTextLocalized, {
                modelValue: _ctx.nameModel,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.nameModel) = $event)),
                "unique-id": 'end-name',
                onInput: _ctx.validateDialog,
                onEnter: _ctx.onSave
              }, null, 8, ["modelValue", "onInput", "onEnter"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["is-visible", "header", "is-action-disabled", "onCloseModal", "onAction"]))
}