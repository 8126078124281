<template>
    <div class="flex">
        <label :for="uniqueId" class="py-0 px-1 text-body">
            {{ text }}
        </label>

        <span class="ml-1 text-danger-default" v-if="required">
            {{ "*" }}
        </span>
    </div>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";

export default defineComponent({
    name: "InputLabel",
    props: {
        text: { type: String as PropType<string>, required: true },
        uniqueId: { type: String as PropType<string>, required: true },
        required: { type: Boolean as PropType<boolean>, default: false },
    },
});
</script>
