<template>
    <div>

        <HeaderWithAddIcon :text="$t('settings.formTemplates.title')" @clicked="showAddDialog" />
        <div class="mt-2">

            <div
                v-for="(template, i) in templates"
                :key="'ft' + i"
                class="amlv3-setformtemp-item"
            >
                <div class="amlv3-setformtemp-item-left" @click="showEditDialog(template)">

                    <div class="amlv3-setformtemp-item-text">
                        <div class="amlv3-setformtemp-item-name">
                            {{ $ct(template.name) }}
                        </div>
                        <div class="amlv3-setformtemp-item-key">
                            {{ template.slug + '/' + template.key }}
                        </div>
                    </div>

                    <div v-if="template.scopes.length > 0" class="amlv3-setformtemp-item-scopes">
                        <div>
                            {{ 'Scopes:' }}
                        </div>
                        <div
                            v-for="(scope) in template.scopes"
                            :key="'ft' + i + scope"
                            class="amlv3-setformtemp-item-scope"
                        >
                            {{ scope }}
                        </div>
                    </div>

                </div>
                <div class="amlv3-setformtemp-item-actions">
                    <div class="amlv3-setformtemp-item-action amlv3-setformtemp-item-action-end" @click="showDeleteDialog(template)">
                        <FontIcon :icon="'trash-can'" />
                    </div>
                </div>
            </div>

        </div>

        <div class="mt-8">
            <ButtonSingle
                is-link
                :text="'Flow Builder'"
                @clicked="openFlowBuilder"
            />
        </div>

        <!-- Add Dialog -->
        <PopupDialog
            :is-visible="addDialog.visible"
            :header="'Add Template'"
            :icon="'plus'"
            :progress="addDialog"
            :show-cancel-button="true"
            :show-action-button="true"
            :action-text="'Add Template'"
            :is-action-disabled="!selectedTemplate.key"
            @close-modal="addDialog.visible = false"
            @action="addTemplate"
        >
            <div v-if="addDialog.isWorking">
                <LoadAnimationBar />
                <LoadAnimationBar />
            </div>
            <div v-if="!addDialog.isWorking" class="mt-3">
                <SimpleListItem
                    v-for="(template, i) in availableTemplates"
                    :key="'aft' + i"
                    :text="$ct(template.name)"
                    :sub-text="template.slug + '/' + template.key"
                    :is-selected="template.id === selectedTemplate.id"
                    :is-first="i === 0"
                    :is-last="i === availableTemplates.length - 1"
                    :is-disabled="isTemplateDisabled(template)"
                    @clicked="selectTemplate(template)"
                />
            </div>
        </PopupDialog>

        <!-- Delete Dialog -->
        <PopupDialog
            :is-visible="deleteDialog.visible"
            :is-warning="true"
            :header="'Delete'"
            :icon="'trash-can'"
            :show-action-button="true"
            :show-cancel-button="true"
            :action-text="'Delete'"
            :prompt="'Are you sure?'"
            @close-modal="deleteDialog.visible = false"
            @action="onDelete"
        />

        <FormTemplateDialog
            v-if="editDialog.visible"
            :template="editDialog.template"
            @close-modal="editDialog.visible = false"
            @save="onTemplateEdited"
        />

    </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

import { FormScope, FormTemplate } from "@/lib/forms/forms";
import { getFormTemplates } from "@/lib/forms/get_templates";
import { Verified } from "@/lib/verified_plugin";

import LoadAnimationBar from "@/ui/LoadAnimationBar.vue";
import PopupDialog from "@/ui/PopupDialog.vue";
import SimpleListItem from "@/ui/SimpleListItem.vue";

import ButtonSingle from "@/ui/ButtonSingle.vue";
import HeaderWithAddIcon from "./HeaderWithAddIcon.vue";
import { querySettings } from "@/lib/settings/get_settings";
import { updateSettings } from "@/lib/settings/update_settings";
import FontIcon from "@/ui/FontIcon.vue";
import FormTemplateDialog from "./FormTemplateDialog.vue";

export default defineComponent({
    name: "FormTemplates",
    components: {
        PopupDialog,
        SimpleListItem,
        LoadAnimationBar,
        HeaderWithAddIcon,
        ButtonSingle,
        FontIcon,
        FormTemplateDialog,
    },
    data() {
        return {
            templates: new Array<FormTemplate>(),
            availableTemplates: new Array<FormTemplate>(),
            selectedTemplate: {
                key: "",
                slug: "",
            } as FormTemplate,
            addDialog: { visible: false, isWorking: false, statusText: "", isError: false, errorText: "" },
            editDialog: { visible: false, template: {} as FormTemplate },
            deleteDialog: { visible: false, isWorking: false, statusText: "", isError: false, errorText: "", id: "" },
        };
    },
    methods: {

        async onSomethingChanged() {
            const data = {
                formTemplates: this.templates,
            };
            await updateSettings(data);
            const settings = await querySettings();
            this.$config.update(settings);
            this.loadTemplates();
        },

        openFlowBuilder() {
            let link = "https://app.verified.eu/form-service/#/";
            if (window.location.host.includes("localhost")) {
                link = "http://localhost:5173/form-service/#/";
            } else if (window.location.host.includes("dev-verified")) {
                link = "https://dev-verified.eu/form-service/#/";
            }

            link += "?provider=verified";
            link += "&companyId=" + Verified.instance.companyId;
            link += "&token=" + Verified.instance.accessToken;

            const windowProxy = window.open(link, "_blank");
            if (windowProxy) windowProxy.focus();
        },

        showAddDialog() {
            this.loadAvailableTemplates();
            this.addDialog.visible = true;
        },

        showEditDialog(template: FormTemplate) {
            this.editDialog.template = template;
            this.editDialog.visible = true;
        },

        onTemplateEdited(scopes: FormScope[]) {
            this.editDialog.template.scopes = scopes;
            this.onSomethingChanged();
        },

        isTemplateDisabled(template: FormTemplate) {
            for (const i in this.templates) {
                if (this.templates[i].slug === template.slug && this.templates[i].key === template.key) {
                    return true;
                }
            }
            return false;
        },

        async loadAvailableTemplates() {
            this.addDialog.isError = true;
            this.addDialog.isWorking = true;

            const response = await getFormTemplates();
            if (response.responseInfo.isError || !response.templates) {
                this.addDialog.isError = true;
                this.addDialog.errorText = response.responseInfo.parsedError?.message || "Unknown Error";
            } else {
                this.availableTemplates = response.templates;
            }
            this.addDialog.isWorking = false;
        },

        selectTemplate(template: FormTemplate) {
            if (this.selectedTemplate.id === template.id) {
                this.selectedTemplate = {
                    id: "",
                    namespace: "",
                    key: "",
                    slug: "",
                    name: { en_EN: "" },
                    scopes: [],
                };
            } else {
                this.selectedTemplate = template;
            }
        },

        showDeleteDialog(template: FormTemplate) {
            this.deleteDialog.id = template.id;
            this.deleteDialog.visible = true;
        },

        onDelete() {
            this.deleteDialog.visible = false;
            const list = this.templates;
            for (const i in list) {
                if (list[i].id === this.deleteDialog.id) {
                    list.splice(Number(i), 1);
                    break;
                }
            }
            this.onSomethingChanged();
        },

        addTemplate() {
            this.templates.push(this.selectedTemplate);
            this.addDialog.visible = false;
            this.onSomethingChanged();
        },

        loadTemplates() {
            this.templates = this.$config.settings.formTemplates || [];
        },

    },
    mounted() {
        this.loadTemplates();
    },
});
</script>
<style scoped>
.amlv3-setformtemp-item {
    display: flex;
    margin-bottom: 10px;
}

.amlv3-setformtemp-item-left {
    flex-grow: 100;
    display: flex;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border: solid 1px lightgray;
    border-right: none;
    background-color: white;
    cursor: pointer;
}
.amlv3-setformtemp-item-left:hover {
    background-color: whitesmoke;
}

.amlv3-setformtemp-item-text {
    flex-grow: 1;
    padding: 5px 15px;
}

.amlv3-setformtemp-item-name {
    font-weight: 500;
}

.amlv3-setformtemp-item-key {
    font-size: 14px;
    color: dimgray;
}

.amlv3-setformtemp-item-scopes {
    display: flex;
    padding: 15px 50px;
    font-size: 15px;
    color: gray;
}

.amlv3-setformtemp-item-scope {
    margin-left: 10px;
    color: #444444;
    font-weight: 500;
}

.amlv3-setformtemp-item-actions {
    display: flex;
}

.amlv3-setformtemp-item-action {
    width: 50px;
    padding-top: 12px;
    text-align: center;
    background-color: white;
    cursor: pointer;
}
.amlv3-setformtemp-item-action:hover {
    background-color: whitesmoke;
}

.amlv3-setformtemp-item-action-end {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border: solid 1px lightgray;
}
</style>
