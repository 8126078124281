<template>
    <Popup
        @closed="$emit('closed')"
        :ignore-click-outside="true"
        :title="$t('project.tabs.company.refreshData.title')"
    >
        <div class="bg-background p-4 rounded-t-md mb-[1px] text-sm">
            <span>{{ $t('project.tabs.company.refreshData.refreshInfo') }}</span>
        </div>
        <div class="bg-background p-4 pb-2">
            <div class="flex items-center text-sm">
                <div class="flex-1 flex gap-2 items-center">
                    {{ $t('common.section') }}
                </div>
                <div>{{ $t('common.lastupdate') }}</div>
            </div>

            <div class="h-[1px] bg-gray-300 my-3"></div>

            <div class="flex gap-1 flex-col">
                <div
                    v-for="block in config?.blocks"
                    :key="block.name"
                    class="flex items-center"
                >
                    <label class="flex items-center justify-center gap-3 " :for="block.name">
                        <div class=" font-roboto">
                            <input
                                class="fbav3-input-checkbox"
                                :disabled="loading"
                                type="checkbox"
                                :id="block.name"
                                :value="block.name"
                                v-model="selectedBlocks"
                            />
                        </div>
                        <span class="">{{ $t(`project.tabs.company.blocks.refereshTab.${block.name}`) }}</span>
                    </label>
                    <div class="flex-1 -mt-[4px] text-right text-sm text-[#999] flex items-center justify-end gap-2">
                        <Icon
                            v-if="block.warning"
                            class="w-4 h-4 -mt-[1px] text-danger-default"
                            icon="aml:notification"
                        />
                        <span> {{ block.lastUpdated ? dateString(block.lastUpdated) : $t('common.n/a') }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="bg-background p-4 rounded-b-md text-sm mt-[1px] text-[#999]">
            {{ $t('project.tabs.company.refreshData.providerInfo') }}
        </div>

        <div v-if="error" class="text-danger-dark p-2 bg-danger-light mt-6 rounded-md flex items-center gap-3">
            <Icon class="w-5 h-5 shrink-0" icon="aml:notification" />
            <span>{{ error }}</span>
        </div>

        <div v-if="success" class="text-green-600 p-2 bg-green-100 mt-6 rounded-md flex items-center gap-3">
            <Icon class="w-5 h-5 shrink-0" icon="ep:success-filled" />
            <span>{{ success }}</span>
        </div>

        <div class="flex gap-4 mt-6">
            <ActionButton
                theme="primary"
                :loading="loading"
                :disabled="!selectedBlocks.length"
                @btn-clicked="submit"
                class="ml-auto mr-0 block shrink-0 self-start"
            >
                {{ $t('project.tabs.company.refreshData.refreshSection', selectedBlocks.length) }}
            </ActionButton>
        </div>
    </Popup>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { attachCompanyData } from "../../lib/projects/attach_company_data";
import ActionButton from "../ActionButton.vue";
import Icon from "../Icon.vue";
import Popup from "../Popup.vue";
import SelectBox from "../SelectBox.vue";
import InputCheckbox from "@/ui/inputs/InputCheckBox.vue";

export interface RefreshCompanyDataConfig {
    projectId: string;
    orgnr: string;
    country: string;
    blocks: {
        name: string;
        lastUpdated?: number;
        warning?: string;
    }[];
}

export default defineComponent({
    components: { Popup, ActionButton, SelectBox, Icon, InputCheckbox },
    emits: ["closed", "requestProjectRefresh"],
    props: {
        config: {} as PropType<RefreshCompanyDataConfig>,
        defaultSelection: {
            type: Array as PropType<string[] | null>,
            default: () => ([]),
        },
    },
    data() {
        return {
            error: "",
            success: "",
            loading: false,
            selectedBlocks: [] as string[],
        };
    },
    methods: {
        async submit() {
            this.error = "";

            if (!this.config) {
                this.error = "Missing component properties";
                return;
            }

            this.loading = true;

            try {
                await attachCompanyData({
                    projectId: this.config.projectId,
                    orgnr: this.config.orgnr,
                    country: this.config.country,
                    dataBlocks: this.selectedBlocks,
                });

                this.$emit("requestProjectRefresh");

                // small artificial delay to allow the request-project-refresh event to do it's thing
                await new Promise<void>((resolve) => {
                    setTimeout(() => {
                        resolve();
                    }, 500);
                });

                this.success = this.$t("project.tabs.company.refreshSuccess");
                this.selectedBlocks.splice(0, this.selectedBlocks.length);
            } catch (err: any) {
                console.error(err);
                this.error = err?.error?.message || "Something unexpected happened";
            } finally {
                this.loading = false;
            }
        },
        dateString(unixTimestamp: number): string {
            return new Date(unixTimestamp).toISOString().split("T")[0];
        },
    },
    watch: {
        selectedBlocks() {
            this.success = "";
        },
    },
    mounted() {
        this.selectedBlocks = this.defaultSelection ? this.defaultSelection : [];
    },
});
</script>
<style scoped>
.fbav3-input-checkbox input[type="checkbox"] {
    transform: scale(1);
}
</style>
