<template>
    <PopupDialog
        :is-visible="dialog.visible"
        :header="$t('assessment.approval.decideTitle')"
        :icon="'thumbs-up'"
        :show-cancel-button="true"
        :show-action-button="true"
        :action-text="actionText"
        :action-icon="actionIcon"
        :is-action-disabled="!dialog.valid"
        :is-warning="checkModel === 'deny'"
        :progress="dialog"
        @action="onMakeDecision"
        @close-modal="onClose"
    >
        <PopupDialogSplit
            style="min-width: 500px; max-width: 560px;"
            :prompt-text="$t('assessment.approval.decision.promptText')"
            :prompt-hint="$t('assessment.approval.decision.promptHint')"
            :is-form="true"
        >
            <InputGroup :width="12">
                <InputLabel
                    :text="$t('assessment.approval.decision.decisionPrompt')"
                    :unique-id="'approve-decision'"
                    required
                />
                <InputRadio
                    v-model="checkModel"
                    :items="checkItems"
                    :unique-id="'approve-decision'"
                    :is-inline="true"
                    @input="validateDialog"
                />
            </InputGroup>

            <InputGroup :width="12">
                <InputLabel
                    :text="$t('assessment.approval.decision.comment')"
                    :unique-id="'approve-comment'"
                    :required="checkModel === 'deny'"
                />
                <InputText
                    v-model="commentModel"
                    :unique-id="'approve-comment'"
                    @input="validateDialog"
                />
            </InputGroup>

        </PopupDialogSplit>
    </PopupDialog>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

import { Project } from "@/lib/projects/projects";
import PopupDialog from "@/ui/PopupDialog.vue";
import PopupDialogSplit from "@/ui/dialog/PopupDialogSplit.vue";
import { FlowAction } from "@/types/flow_types";
import { Assessment } from "@/lib/assessments/assessments";
import { CheckboxItem } from "@/ui/inputs/input_helper";
import InputGroup from "@/ui/inputs/InputGroup.vue";
import InputText from "@/ui/inputs/InputText.vue";
import { ButtonAlignment } from "@/types/ui_types";
import { makeApprovalDecision, MakeDecisionOptions } from "@/lib/assessments/add_approval";
import { getProjectProcess } from "@/lib/projects/get_project";
import InputRadio from "@/ui/inputs/InputRadio.vue";
import InputLabel from "@/ui/inputs/InputLabel.vue";

export default defineComponent({
    name: "ApprovalDecideDialog",
    components: {
        PopupDialog,
        PopupDialogSplit,
        InputGroup,
        InputText,
        InputLabel,
        InputRadio,
    },
    emits: ["request-project-refresh", "close-modal"],
    props: {
        action: { type: Object as PropType<FlowAction>, required: true },
        approvalId: { type: String as PropType<string>, default: "" },
        project: { type: Object as PropType<Project>, required: true },
        assessment: { type: Object as PropType<Assessment>, required: true },
    },
    data() {
        return {
            ButtonAlignment,

            loadedApprovalId: "",

            dialog: { visible: true, valid: false, isWorking: false, statusText: "", isError: false, errorText: "" },
            actionText: "",
            actionIcon: "check",
            checkItems: [
                {
                    key: "approve",
                    textKey: "assessment.approval.decision.approve",
                },
                {
                    key: "deny",
                    textKey: "assessment.approval.decision.deny",
                },
            ] as CheckboxItem[],
            checkModel: "",
            commentModel: "",
        };
    },
    methods: {

        onClose() {
            this.$emit("close-modal");
        },

        validateDialog() {
            let valid = true;
            if (this.checkModel === "approve") {
                this.actionText = this.$t("assessment.approval.decision.approve");
                this.actionIcon = "check";
            } else if (this.checkModel === "deny") {
                this.actionText = this.$t("assessment.approval.decision.deny");
                this.actionIcon = "xmark";
                if (!this.commentModel) {
                    valid = false;
                }
            } else {
                this.actionText = this.$t("assessment.approval.decision.approve");
                this.actionIcon = "check";
                valid = false;
            }
            this.dialog.valid = valid;
        },

        async onMakeDecision() {
            this.dialog.isError = false;
            this.dialog.isWorking = true;
            this.dialog.statusText = this.$t("assessment.approval.decision.working");

            const decision: MakeDecisionOptions = {
                approve: (this.checkModel === "approve"),
                comment: this.commentModel,
            };

            const response = await makeApprovalDecision(
                this.project.id,
                this.assessment.id,
                this.loadedApprovalId,
                decision,
            );
            if (response.responseInfo.isError) {
                this.dialog.isError = true;
                this.dialog.errorText = response.responseInfo.parsedError?.message || "Unknown Error";
            } else {
                const projectResponse = await getProjectProcess(this.project.id);
                this.$emit("request-project-refresh", projectResponse);
                this.onClose();
            }
            this.dialog.isWorking = false;
        },

    },
    mounted() {
        if (!this.approvalId) {
            console.log("action", this.action);
            let approvalKey = "";
            if (this.action.params && this.action.params.approvalKey) {
                approvalKey = this.action.params.approvalKey as string;
            }
            console.log("approvalKey", approvalKey);

            if (!approvalKey) approvalKey = "default";

            this.assessment.approvals.forEach((approval) => {
                if (approval.selected && approval.key === approvalKey) this.loadedApprovalId = approval.id;
            });
        } else {
            this.loadedApprovalId = this.approvalId;
        }
        this.validateDialog();
    },
});
</script>
