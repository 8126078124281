import { Project } from "../projects/projects";

const STORAGE_KEY = "lastViewedCustomers";
const MAX_LENGTH = 3;

export type UpdatedProjectData = {
    id: string;
    name: string;
    type: string;
};

export const addAccessedProjectToLocalStorage = (project: Project | undefined): void => {
    const storedData = localStorage.getItem(STORAGE_KEY);
    try {
        const lastViewedCustomers: UpdatedProjectData[] = storedData ? JSON.parse(storedData) : [];

        const updatedCustomers = [
            { id: project?.id, name: project?.name, type: project?.type },
            ...lastViewedCustomers.filter(proj => proj.id !== project?.id),
        ].slice(0, MAX_LENGTH);

        localStorage.setItem(STORAGE_KEY, JSON.stringify(updatedCustomers));
    } catch (error) {
        console.warn(error);
    }
};

export const getLastViewedCustomers = (): UpdatedProjectData[] => {
    const storedData = localStorage.getItem(STORAGE_KEY);
    return storedData ? JSON.parse(storedData) : [];
};

export const removeDeletedCustomers = (projectId: string) => {
    const storedData = localStorage.getItem(STORAGE_KEY);
    const parsedData : UpdatedProjectData[] = storedData ? JSON.parse(storedData) : [];
    if (parsedData.length) {
        const updatedCusomer = parsedData.filter(p => p.id !== projectId);
        localStorage.setItem(STORAGE_KEY, JSON.stringify(updatedCusomer));
    }
};
