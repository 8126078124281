<template>
    <div
        class="border basis-0 grow shrink-0 border-light p-4 rounded-lg bg-background"
    >

        <PersonScreeningHitsDialog
            v-if="personHitsDialogVisible"
            :person="person"
            @on-close="personHitsDialogVisible = false"
        />

        <div
            class="flex flex-col pl-3 h-full"
            :class="[
                screeningState === screeningStatus.notStarted
                    ? ''
                    : screeningState === HitStatus.HitFound
                        ? 'border-l-2  border-danger-default'
                        : ' border-l-2  border-success-default',
            ]"
        >
            <div class="flex justify-between mb-1">
                <label>{{ $t("screening.title") }}</label>
                <div v-if="showMenu" class="flex gap-2">
                    <Icon
                        class="w-4 h-4"
                        icon="open_eye"
                        @click="personHitsDialogVisible = true"
                    />

                    <Icon
                        class="w-4 h-4"
                        icon="red_bin"
                        @click="deleteScreening"
                    />
                </div>
            </div>
            <div v-if="screeningState !== screeningStatus.notStarted" class="">
                <div class="px-3 py-2 rounded-lg" :class="[screeningState === HitStatus.HitFound ? 'bg-danger-light' : 'bg-success-light']">
                    <div class="flex items-center">
                        <span class="mr-4 text-sm">{{ $t("screening.screened") }}</span>
                        <Icon
                            v-if="screeningState === HitStatus.HitFound"
                            icon="error"
                            class="w-5 h-5 p-1"
                        />
                        <Icon
                            v-if="screeningState === HitStatus.NoHit"
                            icon="success"
                            class="w-2 h-2"
                        />
                        <span
                            v-if="screeningState === HitStatus.HitFound"
                            class="ml-2 text-sm"
                        >{{ person?.screeningStatus?.pep === HitStatus.HitFound ? $t("screening.pep") : "" }}
                            {{ person?.screeningStatus?.sanction === HitStatus.HitFound ? $t('screening.sanction') : "" }} {{ $t("screening.found") }} </span>
                        <span v-if="screeningState === HitStatus.NoHit" class="ml-2 text-sm">{{ $t("screening.noHit") }}</span>
                    </div>
                    <div class="flex items-center text-xs text-light-gray mt-1 gap-2">
                        <span v-if="person?.screeningStatus?.updatedAt">{{
                            $d(new Date(person?.screeningStatus?.updatedAt), "long")
                        }}</span>
                        <span>|</span>
                        <span>{{ "Pliance" }}</span>
                        <!-- <Icon
                            icon="downArrow"
                            class="w-3 h-3 ml-auto self-end"
                            @click="expandedInfo = !expandedInfo"
                        /> -->
                    </div>
                </div>
            </div>
            <!--Expanded info start-->
            <div v-if="expandedInfo" class="mt-2">
                <ScreeningInfo :screening-data="person" />
            </div>
            <!--Expanded info end-->
            <div v-if="screeningState !== screeningStatus.notStarted" class="flex gap-1 mt-2 items-center">
                <SwitchInput v-model="monitored" @update:model-value="toggleMonotoring($event)" />
                <span class="text-sm">{{ $t("screening.continiousScreening") }}</span>
            </div>
            <div v-else class="flex gap-1 mt-auto self-end">
                <CustomButton
                    :text="$t('screening.startScreening')"
                    round-style="full"
                    bg-color="primary-default"
                    text-color="white"
                    :on-click="toggleScreeningModal"
                />
            </div>
        </div>
    </div>
    <!--Popup start-->
    <ScreenPersonPopup
        :togglescreeningmodal="openModal"
        :person="person"
        @close-modal="closeScreeningModal"
    />
    <!--Popup end-->

    <!-- <ScreeningDetailPopup
        :open-screening-details="openScreeningDetails"
        :person="person"
        @closed="shouldCloseScreeningDetails"
    /> -->
</template>
<script lang="ts">
import CustomButton from "@/components/CustomButton.vue";
import ScreeningInfo from "@/components/customer/ScreeningInfo.vue";
import PersonScreeningHitsDialog from "@/components/customer/PersonScreeningHitsDialog.vue";
import Icon from "@/components/Icon.vue";
import Toggle from "@/components/Toggle.vue";
import { DropdownMenuOption } from "@/lib/common/MenuOption";
import { HitStatus, Person, PersonWithRole } from "@/lib/persons/person";
import { screeningStatus } from "@/lib/screening/id_control";
import { deletePersonScreening, updateMonitoringStatus } from "@/lib/screening/screen_person";
import { defineComponent, PropType } from "vue";
import ScreenPersonPopup from "./ScreenPersonPopup.vue";
import ScreeningDetailPopup from "./ScreeningDetailPopup.vue";
import SwitchInput from "../SwitchInput.vue";

const ScreeningMenuOptions = [
    {
        label: "View Screen Details",
        value: "info",
        icon: "info-transparent",
    },
    {
        label: "Download Screen Report",
        value: "download",
        icon: "download",
    },

    {
        label: "New Screening",
        value: "resend",
        icon: "reset",
    },
    {
        label: "Remove Screen Result",
        value: "archive",
        icon: "delete",
    },
] as DropdownMenuOption[];

export default defineComponent({
    name: "Screening",
    components: {
        Icon,
        CustomButton,
        Toggle,
        ScreeningInfo,
        ScreenPersonPopup,
        ScreeningDetailPopup,
        SwitchInput,
        PersonScreeningHitsDialog,
    },
    props: {
        person: {
            type: Object as PropType<PersonWithRole | Person>,
            required: true,
        },
        showMenu: {
            type: Boolean,
            default: true,
        },
    },
    emits: ["refreshPersons"],
    data() {
        return {
            openModal: false,
            screeningStatus,
            HitStatus,
            ScreeningMenuOptions,
            expandedInfo: false,
            isMonitored: this.person.screeningStatus?.monitored ?? false,
            openScreeningDetails: false,
            screening: Object,

            // Person hits dialog
            personHitsDialogVisible: false,
        };
    },
    computed: {
        screeningState() {
            const screeningResult = this.person.screeningStatus;
            if (!screeningResult) return screeningStatus.notStarted;

            if (
                screeningResult.pep === HitStatus.HitFound ||
                screeningResult.sanction === HitStatus.HitFound ||
                screeningResult.sip === HitStatus.HitFound
            ) {
                return HitStatus.HitFound;
            }
            return HitStatus.NoHit;
        },
        monitored(): boolean {
            return this.person.screeningStatus?.monitored ? this.person.screeningStatus?.monitored : false;
        },
    },
    methods: {
        toggleScreeningModal() {
            this.openModal = !this.openModal;
            console.log(this.openModal, "Heree Pat");
        },
        closeScreeningModal() {
            this.openModal = false;
            this.$emit("refreshPersons");
        },

        shouldOpenScreeningDetails() {
            this.openScreeningDetails = !this.openScreeningDetails;
        },
        shouldCloseScreeningDetails() {
            this.openScreeningDetails = false;
        },
        async toggleMonotoring(newValue: boolean) {
            console.log("event", newValue);
            this.isMonitored = newValue;

            await updateMonitoringStatus(this.isMonitored, this.person.id);
            this.$emit("refreshPersons");
        },

        async deleteScreening() {
            await deletePersonScreening(this.person.id);
            this.$emit("refreshPersons");
        },

        async getScreening() {
            // this.screening = await getScreenings(this.person.id);
        },
    },
    created() {
        // this.isMonitored = this.person.screeningStatus?.monitored ? this.person.screeningStatus?.monitored : false;
    },
    mounted() {
        this.getScreening();
    },
});
</script>
