<template>
    <div style="position: relative;">
        <iframe
            id="formframe"
            :title="$t('project.forms.title')"
            scrolling="auto"
            :src="formUrl"
        />
        <div v-if="serviceLoading" style="position: absolute; top: 0px; left: 0px; width: 100%; padding: 20px 0px;">
            <SpinnerVerified :size="45" />
        </div>
    </div>
</template>
<script lang="ts">
import SpinnerVerified from "@/ui/SpinnerVerified.vue";
import { defineComponent, PropType } from "vue";

export interface FormFrameState {
    isLoading: boolean;
    isValid: boolean;
    isSubmitting: boolean;
    title: string;
}

export interface FormFrameInterface {

    saveForm(): void;

    submitForm(): void;

}

export default defineComponent({
    name: "FormFrame",
    components: {
        SpinnerVerified,
    },
    emits: ["state-changed", "saved", "submitted"],
    props: {
        formUrl: { type: String as PropType<string>, required: true },
    },
    data() {
        return {
            dialog: { visible: true, isWorking: false, statusText: "", isError: false, errorText: "" },
            serviceLoading: true,
            state: {
                isLoading: true,
                isValid: false,
                isSubmitting: false,
                title: "Form",
            } as FormFrameState,
        };
    },
    methods: {

        saveForm() {
            console.log("on save");
            const message = {
                action: "save",
            };
            this.postMessage(message);
        },

        submitForm() {
            console.log("submit clicked");
            const message = {
                action: "submit",
            };
            this.postMessage(message);
        },

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        postMessage(message: any) {
            const formFrame = document.getElementById("formframe");
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if (formFrame) (formFrame as any).contentWindow.postMessage(JSON.stringify(message), "*");
        },

        onMessageReceived(event: MessageEvent) {
            try {
                const message = JSON.parse(event.data);
                /* console.log("Message received:", message); */

                if (message.action === "formLoaded") this.onFormLoaded(message);
                if (message.action === "validated") this.onValidated(message);
                if (message.action === "saved") this.onSaved(message);
                if (message.action === "submitted") this.onSubmitted(message);
            } catch (ex) {
                console.error("Failed to parse message", ex);
            }
        },

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onFormLoaded(message: any) {
            console.log("Form onFormLoaded", message);
            this.serviceLoading = false;

            this.state.title = message.data.title;
            this.state.isLoading = false;
            this.$emit("state-changed", this.state);
        },

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onValidated(message: any) {
            /* console.log("Form onValidated", message); */

            let shouldEmit = false;
            if (message.data.formValid && !this.state.isValid) {
                shouldEmit = true;
            } else if (!message.data.formValid && this.state.isValid) {
                shouldEmit = true;
            }

            this.state.isValid = message.data.formValid;
            if (shouldEmit) this.$emit("state-changed", this.state);
        },

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onSubmitted(message: any) {
            console.log("Form submitted", message);
            this.$emit("submitted");
        },

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onSaved(message: any) {
            console.log("Form saved", message);
            this.$emit("saved");
        },

    },
    mounted() {
        window.addEventListener("message", this.onMessageReceived);
    },
    unmounted() {
        window.removeEventListener("message", this.onMessageReceived);
    },
});
</script>
<style scoped>
#formframe {
    width: 1200px;
    height: 450px;
    border: none;
}
</style>
