<template>
    <div class="py-5 bg-white">
        <!-- Header Section -->
        <div class="flex justify-between items-center mb-6">
            <div class="flex bg-light rounded-2xl p-0.5">
                <button
                    :class="[
                        'px-5 py-1 rounded-2xl focus:outline-none',
                        activeTab === 'active' ? 'bg-background text-primary-default' : 'text-body',
                    ]"
                    @click="activeTab = 'active'"
                >
                    {{ $t('common.states.active') }}
                </button>
                <button
                    :class="[
                        'px-5 py-1 rounded-2xl focus:outline-none',
                        activeTab === 'archived' ? 'bg-background text-primary-default' : 'text-body',
                    ]"
                    @click="activeTab = 'archived'"
                >
                    {{ $t('common.states.archived') }}
                </button>
                <div class="border-b-2 border-blue-500 w-full transform translate-y-4"></div>
            </div>
            <CustomButton
                :text="$t('common.newNote')"
                bg-color="transparent"
                text-color="primary-default"
                border-color="primary-default"
                icon="edit-note"
                round-style="2xl"
                icon-position="left"
                :on-click="() => (addNote = true)"
            />
        </div>

        <!-- Notes List -->
        <div class="space-y-4">
            <div v-if="addNote">
                <InputText
                    v-model="note"
                    @enter="addNewNote"
                    :placeholder="$t('common.enterNote')"
                    unique-id="addnote"
                    :is-multiline="true"
                />
                <div class="flex gap-2 justify-between">
                    <CustomButton
                        :text="$t('common.addNote')"
                        bg-color="primary-default"
                        text-color="white"
                        border-color="primary-default"
                        round-style="2xl"
                        icon-position="left"
                        class="mr-auto mb-3 w-full"
                        :on-click="addNewNote"
                    />
                    <CustomButton
                        :text="$t('common.actions.cancel')"
                        bg-color="primary-default"
                        text-color="white"
                        border-color="primary-default"
                        round-style="2xl"
                        icon-position="left"
                        class="ml-auto mb-3 w-full"
                        :on-click="() => (addNote = false)"
                    />
                </div>
            </div>

            <div
                class="bg-background p-4 rounded-lg"
                v-for="n in noteList"
                :key="n.id"
            >
                <div class="flex justify-between items-center mb-2">
                    <div class="flex items-center space-x-2">
                        <Icon class="w-3 h-4" icon="note" />
                        <span class="text-light-gray text-xs">{{ $d(n.createdAt, "long") }} - {{ n.createdBy }}</span>
                    </div>
                    <DropdownMenu
                        @menu-item-selected="(option: DropdownMenuOption) => onActionSelected(option, n.id)"
                        class="ml-auto"
                        :options="
                            activeTab === 'active'
                                ? [
                                    { label: $t('common.actions.edit'), value: 'edit', icon: '' },
                                    { label: $t('common.actions.archive'), value: 'archive', icon: '' },
                                    { label: $t('common.actions.delete'), value: 'delete', icon: '' },
                                ]
                                : [{ label: $t('common.actions.delete'), value: 'delete', icon: '' }]
                        "
                    >
                        <Icon class="w-5 h-5 fill-body stroke-body" icon="dots-horizontal" />
                    </DropdownMenu>
                </div>
                <article class="text-pretty break-words hyphens-auto">
                    <p class="">
                        {{ n.content }}
                    </p>
                </article>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { DropdownMenuOption } from "@/lib/common/MenuOption";
import { addNote } from "@/lib/persons/add_note";
import { deleteNote } from "@/lib/persons/delete_note";
import { Note } from "@/lib/persons/person";
import { updateNote } from "@/lib/persons/update_note";
import InputText from "@/ui/inputs/InputText.vue";
import { defineComponent, PropType } from "vue";
import CustomButton from "../CustomButton.vue";
import DropdownMenu from "../DropdownMenu.vue";
import Icon from "../Icon.vue";

export default defineComponent({
    components: {
        DropdownMenu,
        Icon,
        CustomButton,
        InputText,
    },
    props: {
        notes: {
            type: Array as PropType<Note[]>,
            required: true,
        },
        personId: {
            type: String,
            required: true,
        },
    },
    emits: ["refresh"],
    data() {
        return {
            addNote: false,
            note: "",
            activeTab: "active",
            noteToEdit: "",
        };
    },
    methods: {
        async addNewNote() {
            if (this.note.trim() === "") {
                return;
            }

            if (this.noteToEdit) {
                await updateNote(this.personId, this.noteToEdit, { note: this.note });
            } else {
                await addNote(this.personId, this.note);
            }

            this.$emit("refresh");
            this.addNote = false;
            this.note = "";
            this.noteToEdit = "";
        },
        async onActionSelected(option: DropdownMenuOption, noteId: string) {
            switch (option.value) {
            case "archive":
                await updateNote(this.personId, noteId, { isArchived: true });
                this.$emit("refresh");
                break;
            case "delete":
                await deleteNote(this.personId, noteId);
                this.$emit("refresh");
                break;
            case "edit": {
                const note = this.notes.find((n) => n.id === noteId);
                if (note) {
                    this.note = note.content;
                }
                this.noteToEdit = noteId;
                this.addNote = true;
            }
            }
        },
    },
    computed: {
        noteList(): Note[] {
            return this.notes
                .filter((note: Note) => (this.activeTab === "active" ? !note.isArchived : note.isArchived))
                .sort((a, b) => b.createdAt - a.createdAt);
        },
    },
});
</script>

<style scoped>
/* Add any custom styles here if needed */
</style>
