<template>
    <div class="riskv3-ind">

        <div class="riskv3-ind-top">

            <div class="riskv3-ind-top-actions">
                <RiskIconButton
                    :icon="'pen'"
                    @clicked="showEditNameDialog"
                />
            </div>

            <div class="riskv3-ind-name">
                {{ $ct(indicator.name) }}
            </div>

            <RiskWeightControl
                :indicator="indicator"
                :percentage="percentage"
                @weight-changed="onWeightChanged"
            />

        </div>

        <div class="riskv3-ind-content">
            <div class="riskv3-ind-left">

                <RiskValueList
                    v-if="nestedData.items.length == 0"
                    :indicator="indicator"
                    @changed="onSomethingChanged"
                    @score-changed="onScoreChanged"
                />

                <RiskNestedList
                    v-if="nestedData.items.length > 0"
                    :items="nestedData.items"
                    :options="nestedData.options"
                    :scores="indicator.scores"
                    :score-map="scoreMap"
                    :is-designer="true"
                    @score-changed="onScoreChanged"
                />

            </div>
            <div class="riskv3-ind-right">
                <div class="riskv3-ind-right-top">

                    <RiskMultiplierControl
                        :indicator="indicator"
                        @change-hrm="onHrmChanged"
                        @change-lrm="onLrmChanged"
                    />

                    <div class="riskv3-ind-actions">
                        <RiskIconButton :icon="'circle-arrow-up'" @clicked="$emit('move', false)" />
                        <RiskIconButton :icon="'circle-arrow-down'" @clicked="$emit('move', true)" />
                        <RiskIconButton
                            :icon="'trash-can'"
                            @clicked="deleteDialog.visible = true"
                        />
                    </div>

                </div>

                <div class="riskv3-ind-valuetype">
                    <div class="riskv3-ind-valuetype-center">
                        <span class="riskv3-ind-valuetype-desc">
                            {{ 'Value type:' }}
                        </span>
                        <span class="riskv3-ind-valuetype-value">
                            {{ getTypeName() }}
                        </span>
                    </div>
                </div>

                <div class="riskv3-ind-list">
                    <div class="riskv3-ind-list-header">
                        <div class="riskv3-ind-list-title">
                            {{ 'Sources:' }}
                        </div>
                        <div
                            class="riskv3-ind-list-addbtn"
                            @click="showAddSourceDialog"
                        >
                            {{ 'ADD SOURCE' }}
                        </div>
                    </div>
                    <div
                        class="riskv3-ind-list-empty"
                        v-if="indicator.sources.length === 0"
                    >
                        {{ 'No sources, manual input' }}
                    </div>
                    <RiskSourceControl
                        :value-type="indicator.valueType"
                        :sources="indicator.sources"
                        @delete="onDeleteSource"
                    />
                </div>

                <div class="riskv3-ind-unknown">
                    <span>
                        {{ 'Empty value score:' }}
                    </span>
                    <div class="riskv3-ind-unknown-score">
                        <RiskScoreControl
                            v-if="!flags.naIfEmpty"
                            :score="unknownScore"
                            :score-key="'unknown'"
                            :hide-params="true"
                            @score-changed="onUnknownScoreChanged"
                        />
                        <div v-if="flags.naIfEmpty" class="riskv3-ind-unknown-na">
                            {{ 'Not Applicable if empty' }}
                        </div>
                        <div>
                            <div class="riskv3-ind-unknown-settings" @click="addRemoveFlag('na_if_empty')">
                                <FontIcon :icon="'repeat'" :size="12" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="riskv3-ind-list" style="padding-bottom: 6px;">
                    <div class="riskv3-ind-list-header">
                        <div class="riskv3-ind-list-title">
                            {{ 'Flags/logic:' }}
                        </div>
                        <div
                            class="riskv3-ind-list-addbtn"
                            @click="showAddLogicDialog"
                        >
                            {{ 'ADD FLAG/LOGIC' }}
                        </div>
                    </div>
                    <div
                        class="riskv3-ind-list-empty"
                        v-if="indicator.logic.length === 0"
                    >
                        {{ 'No flags/logic added for this indicator' }}
                    </div>
                    <RiskLogicControl
                        :logic-list="indicator.logic"
                        @delete="onDeleteLogic"
                    />
                </div>

            </div>
        </div>

        <EditNameDialog
            v-if="nameDialog.visible"
            :has-key="false"
            :existing-name="nameDialog.name"
            @save-modal="onNameChanged"
            @close-modal="nameDialog.visible = false"
        />

        <AddRiskSourceDialog
            v-if="sourceDialog.visible"
            :indicator="indicator"
            :assets="assets"
            @close-modal="sourceDialog.visible = false"
            @add-source="onAddSource"
        />

        <AddRiskLogicDialog
            v-if="logicDialog.visible"
            :indicator="indicator"
            :assets="assets"
            @close-modal="logicDialog.visible = false"
            @add-logic="onAddLogic"
        />

        <PopupDialog
            :is-visible="deleteDialog.visible"
            :header="$t('common.actions.delete')"
            :icon="'trash-can'"
            :prompt="$t('common.actions.areYouSure')"
            :show-action-button="true"
            :show-cancel-button="true"
            :action-text="$t('common.actions.delete')"
            :is-warning="true"
            @close-modal="deleteDialog.visible = false"
            @action="onDelete"
        />

    </div>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";

import { RiskIndicatorDefinition, RiskLogicType, RiskSource } from "@/lib/risk/risk_matrix";

import PopupDialog from "@/ui/PopupDialog.vue";

import RiskIconButton from "./controls/RiskIconButton.vue";
import RiskMultiplierControl from "./controls/RiskMultiplierControl.vue";
import RiskNestedList from "./controls/RiskNestedList.vue";
import RiskSourceControl from "./controls/RiskSourceControl.vue";
import RiskValueList from "./controls/RiskValueList.vue";

import { LocalizedString } from "@/lib/common/common";
import AddRiskSourceDialog from "./AddRiskSourceDialog.vue";
import EditNameDialog, { EditNameResult } from "./EditNameDialog.vue";
import RiskLogicControl from "./controls/RiskLogicControl.vue";
import RiskScoreControl from "./controls/RiskScoreControl.vue";
import RiskWeightControl from "./controls/RiskWeightControl.vue";
import { RiskAssets } from "./risk_assets";
import { RiskScoreResult, getValueTypeName } from "./risk_helper";
import { RiskNestedListData, RiskScoreMap, createScoreMapRecursive, getNestedData } from "./risk_nested_list";
import { isSpecialType } from "./special_types/special_types";
import AddRiskLogicDialog from "./AddRiskLogicDialog.vue";
import FontIcon from "@/ui/FontIcon.vue";

export default defineComponent({
    name: "RiskIndicatorComponent",
    components: {
        PopupDialog,
        RiskIconButton,
        RiskValueList,
        RiskNestedList,
        RiskWeightControl,
        RiskMultiplierControl,
        RiskSourceControl,
        RiskLogicControl,
        RiskScoreControl,
        EditNameDialog,
        AddRiskSourceDialog,
        AddRiskLogicDialog,
        FontIcon,
    },
    emits: ["changed", "delete", "move"],
    props: {
        indicator: { type: Object as PropType<RiskIndicatorDefinition>, required: true },
        percentage: { type: String as PropType<string>, required: true },
        assets: { type: Object as PropType<RiskAssets>, required: true },
    },
    data() {
        return {
            nameDialog: { visible: false, key: "", name: {} as LocalizedString },
            sourceDialog: { visible: false },
            logicDialog: { visible: false },
            deleteDialog: { visible: false, key: "", name: "" },
            nestedData: { items: [], options: { limit: 0 } } as RiskNestedListData,
            scoreMap: {} as RiskScoreMap,
            unknownScore: { score: 9, isIndirect: false } as RiskScoreResult,
            flags: {
                naIfEmpty: false,
            },
        };
    },
    methods: {

        getTypeName() {
            return getValueTypeName(this.indicator.valueType);
        },

        showEditNameDialog() {
            this.nameDialog.name = this.indicator.name;
            this.nameDialog.visible = true;
        },

        onNameChanged(result: EditNameResult) {
            // eslint-disable-next-line vue/no-mutating-props
            this.indicator.name = result.name;
            this.onSomethingChanged();
        },

        showAddSourceDialog() {
            this.sourceDialog.visible = true;
        },

        onAddSource(source: RiskSource) {
            // eslint-disable-next-line vue/no-mutating-props
            this.indicator.sources.push(source);
            this.onSomethingChanged();
        },

        onDeleteSource(key: string) {
            for (const i in this.indicator.sources) {
                if (this.indicator.sources[i].key === key) {
                    // eslint-disable-next-line vue/no-mutating-props
                    this.indicator.sources.splice(Number(i), 1);
                    break;
                }
            }
            this.onSomethingChanged();
        },

        showAddLogicDialog() {
            this.logicDialog.visible = true;
        },

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onAddLogic(logic: any) {
            // eslint-disable-next-line vue/no-mutating-props
            this.indicator.logic.push(logic);
            this.onSomethingChanged();
        },

        onDeleteLogic(key: string) {
            for (const i in this.indicator.logic) {
                if (this.indicator.logic[i].key === key) {
                    // eslint-disable-next-line vue/no-mutating-props
                    this.indicator.logic.splice(Number(i), 1);
                    break;
                }
            }
            this.onSomethingChanged();
        },

        onScoreChanged(scoreKey: string, newScore: number) {
            // eslint-disable-next-line vue/no-mutating-props
            this.indicator.scores[scoreKey] = newScore;
            this.onSomethingChanged();
        },

        onUnknownScoreChanged(scoreKey: string, newScore: number) {
            this.unknownScore.score = newScore;
            // eslint-disable-next-line vue/no-mutating-props
            this.indicator.unknownScore = newScore;
            this.onSomethingChanged();
        },

        onHrmChanged(newValue: number) {
            // eslint-disable-next-line vue/no-mutating-props
            this.indicator.hrm = newValue;
            this.onSomethingChanged();
        },

        onLrmChanged(newValue: number) {
            // eslint-disable-next-line vue/no-mutating-props
            this.indicator.lrm = newValue;
            this.onSomethingChanged();
        },

        onWeightChanged(newValue: number) {
            // eslint-disable-next-line vue/no-mutating-props
            this.indicator.weight = newValue;
            this.onSomethingChanged();
        },

        onSomethingChanged() {
            this.loadFlags();
            this.$emit("changed");
        },

        onDelete() {
            this.deleteDialog.visible = false;
            this.$emit("delete");
        },

        addRemoveFlag(key: string) {
            let match = false;
            for (const i in this.indicator.logic) {
                if (this.indicator.logic[i].type === RiskLogicType.Flag && this.indicator.logic[i].key === key) {
                    match = true;
                    // eslint-disable-next-line vue/no-mutating-props
                    this.indicator.logic.splice(Number(i), 1);
                    break;
                }
            }
            if (!match) {
                this.onAddLogic({
                    type: RiskLogicType.Flag,
                    key: key,
                    name: key,
                });
            } else {
                this.onSomethingChanged();
            }
        },

        loadFlags() {
            this.flags.naIfEmpty = false;

            for (const flag of this.indicator.logic) {
                if (flag.type === RiskLogicType.Flag) {
                    if (flag.key === "na_if_empty") this.flags.naIfEmpty = true;
                }
            }
        },

    },
    created() {
        this.loadFlags();
        this.unknownScore.score = this.indicator.unknownScore;

        if (isSpecialType(this.indicator.valueType)) {
            this.nestedData = getNestedData(this.indicator.valueType, this.assets);
            createScoreMapRecursive(this.nestedData.items, this.scoreMap, "");
        }
    },
});
</script>
<style scoped>
.riskv3-ind {
    margin-top: 10px;
    background-color: white;
}

/* Top */

.riskv3-ind-top {
    display: flex;
    height: 45px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: solid 1px lightgray;
}

.riskv3-ind-top-actions {
    display: flex;
    padding: 6px 5px;
}

.riskv3-ind-name {
    flex-grow: 1;
    padding-top: 9px;
    padding-left: 5px;
    font-size: 18px;
    font-weight: 500;
}

/* Content */

.riskv3-ind-content {
    display: flex;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border: solid 1px lightgray;
    border-top: none;
}

.riskv3-ind-left {
    width: 50%;
}

.riskv3-ind-right {
    width: 50%;
    border-left: solid 1px lightgray;
}

.riskv3-ind-right-top {
    display: flex;
    padding: 4px 4px;
}

.riskv3-ind-actions {
    display: flex;
}

.riskv3-ind-valuetype {
    margin-top: 3px;
    margin-bottom: -11px;
}

.riskv3-ind-valuetype-center {
    width: fit-content;
    margin: 0 auto;
    font-size: 14px;
}

.riskv3-ind-valuetype-desc {
    color: dimgray;
}

.riskv3-ind-valuetype-value {
    margin-left: 5px;
    font-weight: 500;
}

.riskv3-ind-unknown {
    margin-top: 10px;
    margin-left: 6px;
    margin-bottom: 8px;
    display: flex;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border: solid 1px lightgray;
    border-right: none;
}

.riskv3-ind-unknown span {
    flex-grow: 1;
    padding: 4px 8px;
    font-size: 15px;
}

.riskv3-ind-unknown-score {
    display: flex;
    padding-top: 4px;
    padding-right: 6px;
}

.riskv3-ind-unknown-na {
    font-size: 15px;
}

.riskv3-ind-unknown-settings {
    width: 24px;
    height: 22px;
    margin-left: 10px;
    padding-top: 2px;
    text-align: center;
    /* color: lightgray; */
    border-radius: 4px;
    border: solid 1px lightgray;
    background-color: whitesmoke;
    cursor: pointer;
}
.riskv3-ind-unknown-settings:hover {
    background-color: lightgray;
}

/* Lists */

.riskv3-ind-list {
    padding: 0px 6px;
}

.riskv3-ind-list-header {
    display: flex
}

.riskv3-ind-list-title {
    flex-grow: 1;
    font-size: 14px;
    color: dimgray;
}

.riskv3-ind-list-addbtn {
    flex-shrink: 0;
    font-size: 13px;
    font-weight: 500;
    color: #89a6b8;
    cursor: pointer;
}

.riskv3-ind-list-addbtn:hover {
    opacity: 0.7;
}

.riskv3-ind-list-empty {
    padding: 3px 10px;
    font-size: 13px;
    color: dimgray;
}
</style>
