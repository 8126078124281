import { ApiResponse, parseLocalError, parseRemoteError } from "../error_parser";
import { Verified } from "../verified_plugin";

export const deleteForm = async (formId: string): Promise<{ wasDeleted: boolean, responseInfo: ApiResponse }> => {
    try {
        const response = await fetch(`/api/aml/v1/forms/${formId}`, {
            method: "DELETE",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
        });

        const body = await response.json();

        if (!response.ok) {
            return {
                wasDeleted: false,
                responseInfo: { isError: true, parsedError: parseRemoteError(body) },
            };
        }

        return {
            wasDeleted: true,
            responseInfo: { isError: false, parsedError: null },
        };
    } catch (error) {
        return {
            wasDeleted: false,
            responseInfo: { isError: true, parsedError: parseLocalError(error) },
        };
    }
};
