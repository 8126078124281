<template>
    <Layout>
        <Nav />
        <Main>
            <section>
                <div class="mx-auto my-10 w-2/3 bg-white border-2 border-gray-200 rounded-2xl p-6">
                    <div class="flex items-center gap-6">
                        <div class="bg-[#e5f5ff] rounded-lg p-3">
                            <Icon class="w-7 h-7 text-primary-default" icon="aml:round" />
                        </div>
                        <h1 class="text-3xl">
                            {{ $t("newCustomer.title") }}
                        </h1>
                    </div>
                    <div class="my-3 border-b border-gray-200"></div>
                    <div class="mb-2">
                        <p class="text-xxl">
                            {{ $t("newCustomer.selectCustomerType") }}
                        </p>
                    </div>
                    <div class="flex gap-4">
                        <div
                            @click="customerType = 'company' as CustomerType"
                            :class="{
                                'customer-type-btn': true,
                                'customer-type-btn-active': customerType == 'company',
                            }"
                        >
                            <Icon class="w-8" icon="ion:business" />
                            <p>{{ $t("newCustomer.company") }}</p>
                        </div>
                        <div
                            @click="customerType = 'person' as CustomerType"
                            :class="{
                                'customer-type-btn': true,
                                'customer-type-btn-active': customerType == 'person',
                            }"
                        >
                            <Icon class="w-8" icon="ion:person" />
                            <p>{{ $t("newCustomer.physicalPerson") }}</p>
                        </div>
                    </div>

                    <div class="my-3"></div>

                    <div class="mb-3 flex items-center">
                        <p class="text-xl flex-1">
                            {{ $t("common.configure") }}
                        </p>
                        <p class="text-xs">
                            <span class="text-red-600">*</span> {{ $t("common.required_fields") }}
                        </p>
                    </div>

                    <CompanyCustomerCreator v-if="customerType == 'company'" />
                    <PersonCustomerCreator v-if="customerType == 'person'" />
                </div>
            </section>
        </Main>
    </Layout>
</template>

<script lang="ts">
import { CompanyCustomerCreator, Flag, Icon, Layout, Main, Nav, PersonCustomerCreator } from "@/components";
import { defineComponent } from "vue";

enum CustomerType {
    Company = "company",
    Person = "person",
}

export default defineComponent({
    name: "NewCustomer",
    components: {
        Nav,
        Layout,
        Main,
        Icon,
        Flag,
        CompanyCustomerCreator,
        PersonCustomerCreator,
    },
    data() {
        return {
            customerType: CustomerType.Company,
        };
    },
});
</script>

<style scoped lang="scss">
.customer-type-btn {
    @apply flex-1 p-3 flex justify-center rounded-lg text-gray-300 border border-gray-200 items-center gap-4 hover:cursor-pointer hover:border-primary-default ;
    p {
        @apply text-primary-default font-bold;
    }
}

.customer-type-btn-active {
    @apply bg-primary-default border-primary-default text-gray-100;
    p {
        @apply text-white;
    }
}
</style>
