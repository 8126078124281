<template>
    <div class="mt-4">
        <CompanySearchInput
            @company-selected="onCompanySelected"
            :country="country"
            @set-manual="(searchedValue: string) => setManual(searchedValue)"
        />
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CompanySearchInput, { CompanySearchResult } from "../forms/CompanySearchInput.vue";

export default defineComponent({
    components: {
        CompanySearchInput,
    },
    emits: ["companyDataUpdated", "setManual"],
    props: {
        country: {
            type: String,
            required: true,
        },
    },
    data() {
        return {};
    },
    methods: {
        onCompanySelected(company: CompanySearchResult) {
            this.$emit("companyDataUpdated", company);
        },
        setManual(searchedValue: string) {
            console.log("setManual", searchedValue);
            this.$emit("setManual", searchedValue);
        },
    },
    mounted() {
        if (!this.country) {
            console.error("Missing country prop in CountryCompanySearch component");
        }
        this.$emit("companyDataUpdated", {
            name: "",
            registrationNumber: "",
            country: "",
        });
    },
});
</script>
