import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputLabel = _resolveComponent("InputLabel")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_InputGroup = _resolveComponent("InputGroup")!
  const _component_PopupDialogSplit = _resolveComponent("PopupDialogSplit")!
  const _component_PopupDialog = _resolveComponent("PopupDialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_PopupDialog, {
      "is-visible": _ctx.dialog.visible,
      header: _ctx.$t('project.forms.reminder.send'),
      icon: 'bell',
      "show-action-button": true,
      "show-cancel-button": true,
      "is-action-disabled": !_ctx.dialog.valid,
      "action-text": _ctx.$t('project.forms.reminder.send'),
      "action-icon": 'bell',
      progress: _ctx.dialog,
      onCloseModal: _ctx.onClose,
      onAction: _ctx.onSendReminder
    }, {
      default: _withCtx(() => [
        _createVNode(_component_PopupDialogSplit, {
          "is-form": "",
          "prompt-text": _ctx.$t('project.forms.reminder.promptText'),
          "prompt-hint": _ctx.$t('project.forms.reminder.hintText')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputGroup, { width: 6 }, {
              default: _withCtx(() => [
                _createVNode(_component_InputLabel, {
                  text: _ctx.$t('common.person_info.firstName'),
                  "unique-id": 'end-given',
                  required: ""
                }, null, 8, ["text"]),
                _createVNode(_component_InputText, {
                  modelValue: _ctx.model.givenName,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.givenName) = $event)),
                  "unique-id": 'end-given',
                  disabled: true,
                  onInput: _ctx.validateDialog
                }, null, 8, ["modelValue", "onInput"])
              ]),
              _: 1
            }),
            _createVNode(_component_InputGroup, { width: 6 }, {
              default: _withCtx(() => [
                _createVNode(_component_InputLabel, {
                  text: _ctx.$t('common.person_info.lastName'),
                  "unique-id": 'end-family',
                  required: ""
                }, null, 8, ["text"]),
                _createVNode(_component_InputText, {
                  modelValue: _ctx.model.familyName,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.familyName) = $event)),
                  "unique-id": 'end-family',
                  disabled: true,
                  onInput: _ctx.validateDialog
                }, null, 8, ["modelValue", "onInput"])
              ]),
              _: 1
            }),
            _createVNode(_component_InputGroup, { width: 12 }, {
              default: _withCtx(() => [
                _createVNode(_component_InputLabel, {
                  text: _ctx.$t('common.person_info.email'),
                  "unique-id": 'end-email',
                  required: ""
                }, null, 8, ["text"]),
                _createVNode(_component_InputText, {
                  modelValue: _ctx.model.familyName,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.familyName) = $event)),
                  "unique-id": 'end-email',
                  disabled: true,
                  onInput: _ctx.validateDialog
                }, null, 8, ["modelValue", "onInput"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["prompt-text", "prompt-hint"])
      ]),
      _: 1
    }, 8, ["is-visible", "header", "is-action-disabled", "action-text", "progress", "onCloseModal", "onAction"])
  ]))
}