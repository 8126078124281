import { Project } from "@/lib/projects/projects";
import { Verified } from "../../lib/verified_plugin";

export interface Contact {
    givenName: string;
    familyName: string;
    email: string;
}

export const getContactsFromProject = (project: Project): Contact[] => {
    const list: Contact[] = [];

    // todo: add contacts from project

    const identity = Verified.instance.identity;

    list.push({
        givenName: identity.givenName,
        familyName: identity.familyName,
        email: identity.email,
    });

    return list;
};
