<template>
    <div>

        <InputRadio
            v-model="value"
            :items="items"
            :unique-id="'test'"
            :is-inline="true"
            @update:model-value="handleUpdate"
        />

    </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

import { AssessmentValue, RiskIndicator } from "@/lib/assessments/assessments";
import { RiskIndicatorDefinition } from "@/lib/risk/risk_matrix";
import { CheckboxItem } from "@/ui/inputs/input_helper";
import InputRadio from "@/ui/inputs/InputRadio.vue";

export default defineComponent({
    name: "IndicatorYesNo",
    components: {
        InputRadio,
    },
    emits: ["updated"],
    props: {
        indicator: { type: Object as PropType<RiskIndicator>, required: true },
        definition: { type: Object as PropType<RiskIndicatorDefinition>, required: true },
        existingValue: { type: Object as PropType<AssessmentValue | null>, default: null },
    },
    data() {
        return {
            value: "",
            isValid: true,

            items: new Array<CheckboxItem>(),
        };
    },
    methods: {

        handleUpdate() {
            let value = null;
            if (this.value === "no") value = false;
            if (this.value === "yes") value = true;
            this.$emit("updated", value, this.isValid);
        },

    },
    mounted() {
        this.items.push({
            key: "yes",
            text: { key: "common.yes" },
        });
        this.items.push({
            key: "no",
            text: { key: "common.no" },
        });
    },
});
</script>
<style scoped></style>
