import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SimpleListItem = _resolveComponent("SimpleListItem")!
  const _component_PopupDialogSplit = _resolveComponent("PopupDialogSplit")!
  const _component_InputLabel = _resolveComponent("InputLabel")!
  const _component_InputTextLocalized = _resolveComponent("InputTextLocalized")!
  const _component_InputGroup = _resolveComponent("InputGroup")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_InputDropdown = _resolveComponent("InputDropdown")!
  const _component_PopupDialog = _resolveComponent("PopupDialog")!

  return (_openBlock(), _createBlock(_component_PopupDialog, {
    "is-visible": true,
    header: _ctx.title,
    icon: 'list-check',
    "show-cancel-button": true,
    "show-action-button": true,
    "action-text": 'Save',
    "action-icon": 'floppy-disk',
    "is-action-disabled": !_ctx.dialog.valid,
    onCloseModal: _ctx.onClose,
    onAction: _ctx.onSave
  }, {
    default: _withCtx(() => [
      (!_ctx.selectedFlowKey)
        ? (_openBlock(), _createBlock(_component_PopupDialogSplit, {
            key: 0,
            "prompt-text": 'Select base workflow:',
            "prompt-hint": 'promptHint'
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableFlows, (flow, i) => {
                return (_openBlock(), _createBlock(_component_SimpleListItem, {
                  key: 'aft' + i,
                  text: _ctx.$ct(flow.name),
                  "sub-text": flow.key,
                  "is-first": i === 0,
                  "is-last": i === _ctx.availableFlows.length - 1,
                  onClicked: ($event: any) => (_ctx.selectFlow(flow))
                }, null, 8, ["text", "sub-text", "is-first", "is-last", "onClicked"]))
              }), 128))
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.selectedFlowKey)
        ? (_openBlock(), _createBlock(_component_PopupDialogSplit, {
            key: 1,
            "is-form": "",
            "prompt-text": _ctx.promptText,
            "prompt-hint": _ctx.promptHint
          }, {
            default: _withCtx(() => [
              _createVNode(_component_InputGroup, { width: 9 }, {
                default: _withCtx(() => [
                  _createVNode(_component_InputLabel, {
                    text: 'Name',
                    "unique-id": 'end-name'
                  }),
                  _createVNode(_component_InputTextLocalized, {
                    modelValue: _ctx.addModel.name,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.addModel.name) = $event)),
                    "unique-id": 'end-name',
                    onInput: _ctx.validateDialog
                  }, null, 8, ["modelValue", "onInput"])
                ]),
                _: 1
              }),
              _createVNode(_component_InputGroup, { width: 3 }, {
                default: _withCtx(() => [
                  _createVNode(_component_InputLabel, {
                    text: 'Order',
                    "unique-id": 'end-order'
                  }),
                  _createVNode(_component_InputText, {
                    modelValue: _ctx.addModel.order,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.addModel.order) = $event)),
                    "unique-id": 'end-name',
                    onInput: _ctx.validateDialog
                  }, null, 8, ["modelValue", "onInput"])
                ]),
                _: 1
              }),
              _createVNode(_component_InputGroup, { width: 6 }, {
                default: _withCtx(() => [
                  _createVNode(_component_InputLabel, {
                    text: 'Key',
                    "unique-id": 'end-key'
                  }),
                  _createVNode(_component_InputText, {
                    modelValue: _ctx.addModel.key,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.addModel.key) = $event)),
                    disabled: _ctx.isEditMode,
                    "unique-id": 'end-key',
                    onInput: _ctx.validateDialog
                  }, null, 8, ["modelValue", "disabled", "onInput"])
                ]),
                _: 1
              }),
              _createVNode(_component_InputGroup, { width: 6 }, {
                default: _withCtx(() => [
                  _createVNode(_component_InputLabel, {
                    text: 'Risk Matrix',
                    "unique-id": 'end-matrix'
                  }),
                  _createVNode(_component_InputDropdown, {
                    modelValue: _ctx.addModel.matrixKey,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.addModel.matrixKey) = $event)),
                    items: _ctx.availableMatrices,
                    "unique-id": 'end-key',
                    onInput: _ctx.validateDialog
                  }, null, 8, ["modelValue", "items", "onInput"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["prompt-text", "prompt-hint"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["header", "is-action-disabled", "onCloseModal", "onAction"]))
}