import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "fbv3-fonticon",
    style: _normalizeStyle(`font-size: ${_ctx.size}px;`)
  }, [
    _createVNode(_component_font_awesome_icon, {
      icon: `fa-solid fa-${_ctx.icon}`
    }, null, 8, ["icon"])
  ], 4))
}