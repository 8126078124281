<template>
    <div class="amlv3-menuswitch">

        <!-- <div class="amlv3-menuswitch-items">

            <div
                v-for="(item, i) in items"
                :key="'i' + i"
                class="amlv3-menuswitch-item"
                :class="[
                    {'amlv3-menuswitch-item-active': item.key === modelValue },
                ]"
                @click="$emit('update:modelValue', item.key)"
            >

                {{ item.textKey ? $t(item.textKey) : $ct(item.text) }}

            </div>

        </div> -->

        <div style="flex-grow: 1;" />

        <div
            v-if="hasButton"
            class="amlv3-menuswitch-button"
            @click="$emit('clicked')"
        >
            <div v-if="buttonIcon" class="amlv3-menuswitch-button-icon">
                <FontIcon :icon="buttonIcon" :size="16" />
            </div>
            <div v-if="buttonText" class="amlv3-menuswitch-button-text">
                {{ buttonText }}
            </div>
        </div>

    </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

import FontIcon from "@/ui/FontIcon.vue";
import { CheckboxItem } from "@/ui/inputs/input_helper";

export default defineComponent({
    name: "MenuSwitch",
    components: {
        FontIcon,
    },
    emits: ["update:modelValue", "clicked"],
    props: {
        modelValue: { type: String as PropType<string>, required: true },
        items: { type: Array as PropType<CheckboxItem[]>, required: true },
        hasButton: { type: Boolean as PropType<boolean>, default: false },
        buttonText: { type: String as PropType<string>, default: "" },
        buttonIcon: { type: String as PropType<string>, default: "" },
    },
});
</script>
<style scoped>
.amlv3-menuswitch {
    display: flex;
}

.amlv3-menuswitch-items {
    width: fit-content;
    height: 32px;
    display: flex;
    gap: 2px;
    padding: 2px;
    border-radius: 14px;
    background-color: #E1E1E1;
}

.amlv3-menuswitch-item {
    height: 28px;
    padding: 2px 15px;
    border-radius: 12px;
    cursor: pointer;
}

.amlv3-menuswitch-item-active {
    color: #218bcb;
    background-color: #F4F4F4;
}

.amlv3-menuswitch-button {
    height: 32px;
    display: flex;
    padding: 4px 10px;
    border-radius: 16px;
    border: solid 1px #218bcb;
    cursor: pointer;
}
.amlv3-menuswitch-button:hover {
    opacity: 0.7;
}

.amlv3-menuswitch-button-icon {
    color: #218bcb;
}

.amlv3-menuswitch-button-text {
    padding-left: 8px;
    font-weight: 500;
    color: #218bcb;
}
</style>
