export enum RoleCode {
    Owner = "owner", // company role
    Boardmember = "boardmember", // MEDL
    DeputyBoardmember = "deputy_boardmember", // VARA
    Ceo = "ceo", // DAGL
    DeputyCeo = "deputy_ceo",
    Proprietor = "proprietor", // INNH
    Contact = "contact", // KONT
    Chairman = "chairman", // LEDE
    DeputyChairman = "deputy_chairman", // NEST
    Accountant = "accountant", // REGN
    Auditor = "auditor", // REVI
    Shareholder = "shareholder",
    BeneficialOwner = "beneficial_owner",
    External = "external",
    Custom = "custom",
    Other = "other",
}

export enum RoleType {
    Person = "person",
    Company = "company",
    Unknown = "unknown",
}

export interface RoleDescription {
    key: RoleCode;
    name?: string; // for when key is other/custom
    metadata?: any; // any additional data, e.g. a custom role with extra info added from kyc
}

export interface Role {
    namespace: string; //index
    personId: string; // index
    projectId: string; // index
    roles: RoleDescription[]; // roles.key index
}
