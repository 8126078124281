import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bea578a4"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "value"]
const _hoisted_2 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("select", {
    class: "fbav3-input-select",
    id: _ctx.uniqueId,
    value: _ctx.modelValue,
    onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args)))
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
      return (_openBlock(), _createElementBlock("option", {
        key: _ctx.uniqueId + item.value,
        value: item.value
      }, _toDisplayString(item.text), 9, _hoisted_2))
    }), 128))
  ], 40, _hoisted_1))
}