import { Verified } from "../verified_plugin";

export interface Event {
    id: string;
    namespace: string;
    type: string;
    timestamp: number;
    data: any;
    resources: string[];
}

export enum EventScope {
    Person = "person",
    Project = "project",
    Overview = "overview",
}

export const queryEvents = async (options: {
    limit?: number;
    skip?: number;
    resource?: string;
    from?: Date;
    to?: Date,
    eventScope?: EventScope,
}) => {
    try {
        let baseUrl = "";

        switch (options.eventScope) {
        case EventScope.Overview:
            baseUrl = "/api/aml/v1/events";
            break;
        case EventScope.Person:
            baseUrl = `/api/aml/v1/persons/${options.resource}/events`;
            break;
        case EventScope.Project:
            baseUrl = `/api/aml/v1/projects/${options.resource}/events`;
            break;
        default:
            baseUrl = "/api/aml/v1/events";
            break;
        }

        const params = new URLSearchParams();
        if (options.limit) {
            params.set("limit", options.limit.toString());
        }
        if (options.skip) {
            params.set("skip", options.skip.toString());
        }
        if (options.resource) {
            params.set("resource", options.resource);
        }
        if (options.from) {
            params.set("from", options.from.toISOString());
        }
        if (options.to) {
            params.set("to", options.to.toISOString());
        }

        const url = `${baseUrl}?${params.toString()}`;

        const response = await fetch(url, {
            method: "GET",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
        });

        const body = await response.json();

        if (!response.ok) {
            throw new Error(body);
        }

        return body as {
            total: number;
            events: Event[];
        };
    } catch (err) {
        console.error("Failed to query for events", err);
        throw err;
    }
};
