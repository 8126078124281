<!--
    This should be merged with DataBlockField.vue but changes made there for the person blocks broke the company tab
    of customers so just separating them before the upcoming demo in two files..
-->

<template>
    <div
        @click="$emit('openSidebarDialog')"
        :class="'group outline-1  text-body rounded-lg p-3 pb-2 flex flex-col justify-between cursor-pointer min-h-[6rem]' + editableClass"
    >
        <div class="flex mb-2">
            <div class="flex-1 flex items-center gap-1 uppercase text-xs font-bold">
                <span>{{ title }}</span>
                <span
                    v-if="sources?.length && sources?.length > 1"
                    class="bg-background w-5 h-5 ml-2 hidden rounded-full py-0.5 px-1 text-xs font-medium md:inline-block text-center"
                >{{ sources.length }}</span>
            </div>
            <Icon
                v-for="(src, i) in sources"
                class="w-4 h-4 ml-2"
                :key="`src-${i}`"
                :icon="`aml:src-${src.toLowerCase()}`"
            />
            <!-- <div v-if="data?.count && data?.count > 1" class="text-xs">
                <a @click="$emit('openSidebarDialog', title)" class="group-hover:text-primary-default">{{ data?.count }} more</a>
            </div>
            <div v-else class="text-xs">
                <a @click="$emit('openSidebarDialog', title)" class="group-hover:text-primary-default">Add new</a>
            </div>-->
        </div>
        <div>
            <slot></slot>
        </div>
    </div>
</template>

<script lang="ts">
import Badge from "@/components/Badge.vue";
import { PersonInfoPropertyVM } from "@/lib/persons/person";
import { PropType, defineComponent } from "vue";
import Icon from "../Icon.vue";

export default defineComponent({
    components: { Icon, Badge },
    props: {
        title: String,
        icons: Array as PropType<string[]>,
        editable: Boolean,
        data: {} as PropType<PersonInfoPropertyVM | null>,
        sources: Array as PropType<string[]>,
    },
    emits: ["openSidebarDialog"],
    computed: {
        editableClass(): string {
            return this.editable ? " hover:bg-transparent hover:outline hover:text-[#444] cursor-pointer" : "";
        },
    },
});
</script>
